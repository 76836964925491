(function(){
        'use strict';
        angular.module('echo')
            .controller('ShareReportsCtrl', ShareReportsCtrl);

        ShareReportsCtrl.$inject = ['$scope',
            '$modalInstance',
            'SurveyCreateService',
            'applicationService',
            'userService'];

        function ShareReportsCtrl($scope,
                                  $modalInstance,
                                  SurveyCreateService,
                                  applicationService,
                                  userService){

            $scope.allInstitutionsByInstitutionId = [];
            $scope.allLicencedInstitutionIds = [];

            $scope.institutions = [];
            var customerIds = [];
            $scope.institution = {};

            $scope.getLicensedInstitutionIds = function(){
                //var sharingCustomerId = '3';
                //var sharingCustomerId = $scope.liveSurvey.institutionId;
                var sharingCustomerId = applicationService.getInstitutionId();

                SurveyCreateService.getAllInstitutionsForSharingByInstitutionId({sharingCustomerId : sharingCustomerId})
                    .$promise
                    .then(function (institutions) {

                        var reportId = applicationService.getReportId();
                        var customerId = applicationService.getInstitutionId();
                        return SurveyCreateService.getInstitutionIds({customerId: customerId, objectId: reportId, objectTypeId: '5'}).$promise
                    })
                    .then(function (institutionIds) {
                        // success handler
                        if (institutionIds.length > 0) {
                            $scope.updateInstitutionOptions();
                        }

                    });
            };

            //Elastic search for institution Names
            $scope.updateInstitutionOptions = function(){

                if($scope.institution.name) {

                    $scope.disableSubmit = true;

                    var namePhrase = $scope.institution.name;
                    var searchParams = {
                        "likeName" : namePhrase,
                        "parentIds" : $scope.allLicencedInstitutionIds,
                        "customerId": applicationService.getInstitutionId(),
                        "from": 0,
                        "size": 15
                    }

                    applicationService.getInstitutionsForSharing(searchParams)
                        .$promise.then(function (institutions) {
                            if ($scope.institutions.length > 0) {
                                var diff = _.difference(_.pluck(institutions, "id"), _.pluck($scope.institutions, "id"));
                                var result = _.filter(institutions, function(obj) { return diff.indexOf(obj.id) >= 0; });
                                $scope.institutionOptions = result;
                            }else {
                                $scope.institutionOptions = institutions;
                            }
                        }).catch(function error(msg) {

                        });
                }
            }

            $scope.selectInstitution = function(institution){
                $scope.selectedInstitution = institution;
                $scope.institution.name = institution.name;
                $scope.disableSubmit = false;
            }

            $scope.resetInstitutionName = function(){
                $scope.institution.name = "";
            }

            //Called on-click of 'Add' button to share survey
            $scope.saveInstitution = function(){
                var customerId = $scope.selectedInstitution.id;
                var reportId = $scope.reportPreferences.reportId;
                var userId = userService.getUserId();

                // using institutionId in the below object to carry current(owner) customerId
                var currentCustomerId = applicationService.getInstitutionId();

                var object = [{
                    objectType: "report",
                    objectInstanceId: reportId,
                    ownerUserId: userId,
                    customerId: customerId,
                    userId: '',
                    roles: ["ROLE_VIEWER"],
                    institutionId: currentCustomerId,
                    applicationId: 2}]

                SurveyCreateService.saveInstitution(object).$promise.then(function () {


                    var index = "";
                    angular.forEach( $scope.allLicencedInstitutionIds, function(institutionId) {
                        if(customerId == institutionId){
                            index = $scope.allLicencedInstitutionIds.indexOf(institutionId);
                            $scope.allLicencedInstitutionIds.splice(index, 1);
                            //$scope.updateInstitutionOptions();
                        }
                    });

                    var institutionIds = [customerId];
                    $scope.getInstitutionDetails(0, institutionIds);
                    $scope.institution.name = "";
                    $scope.institutionOptions = [];
                    $scope.disableSubmit = true;

                }).catch(function error(error) {

                });
            };

            //Called when permission for a shared institution is changed
            $scope.savePermission = function(role, institutionId){

                var customerId = institutionId;
                var reportId = $scope.reportPreferences.reportId;
                var ownerUserId = userService.getUserId();
                var applicationId = '2';

                var data = {applicationId: applicationId,
                    objectTypeId: '5',
                    customerId: customerId,
                    surveyId: reportId,
                    role: role
                }

                SurveyCreateService.updatePermission(data).$promise.then(function () {

                    //$scope.getAllInstitutionsBySurveyId();
                }).catch(function error(error) {

                });

            }

            //Called on-click of 'X' button to remove from sharing
            $scope.deleteInstitution = function(institutionId){
                var institutionId = institutionId;
                var reportId = $scope.reportPreferences.reportId;
                var index = "";
                angular.forEach($scope.institutions, function(institution) {
                    if(institution.id == institutionId){
                        index = $scope.institutions.indexOf(institution);
                    }
                });

                SurveyCreateService.deleteInstitution({objectId:reportId, institutionId:institutionId, objectTypeId: '5'}).$promise.then(function () {
                    // success handler
                    $scope.institutions.splice(index, 1);
                    $scope.allLicencedInstitutionIds.push(institutionId);
                    //$scope.updateInstitutionOptions();
                    $scope.institutionOptions = [];

                }, function (error) {
                    // error handler

                });
            };

            //Called on-load of survey to load all institutions the survey has been shared with
            $scope.getAllInstitutionsByReportId = function(){
                var reportId = applicationService.getReportId();
                var institutionId = applicationService.getInstitutionId();

                SurveyCreateService.getInstitutionIds({customerId:institutionId, objectId:reportId, objectTypeId: '5'})
                    .$promise
                    .then(function (institutionIds) {
                        // success handler
                        $scope.institutionIds = institutionIds;
                        if ($scope.institutionIds.length > 0) {
                            $scope.getInstitutionDetails(0, institutionIds);
                        }



                    }, function (error) {
                        // error handler

                    });
            };

            $scope.getInstitutionDetails = function(i, institutionIds){

                if (i < institutionIds.length) {

                    var institutionId = institutionIds[i];
                    var reportId = applicationService.getReportId();
                    var userId = userService.getUserId();

                    var params = {objectId:reportId,
                        objectType: 'report',
                        customerId:institutionId,
                        userId:userId}

                    SurveyCreateService.getUserRole(params)// TODO: Roles Permissions - SurveyCreateService.getUserRole is called here to evaluate the user's role. This should be reviewed and moved into userService if possible.
                        .$promise
                        .then(function (roleIdForSharedInstitution) {

                            $scope.roleIdForSharedInstitution = roleIdForSharedInstitution.id;
                            $scope.ownerCustomerId = roleIdForSharedInstitution.ownerCustomerId;

                            return SurveyCreateService.getInstitutionDetails({institutionId: institutionId}).$promise
                        })
                        .then(function (institution) {
                            var index = $scope.institutions.map(function (e) { return e.institution; }).indexOf(institution.name);
                            // is currently selected
                            if (index > -1) {
                                $scope.institutions.splice(index, 1);

                            }
                            // is newly selected
                            else {
                                institution.permissions = [
                                    {name:"View Only"},
                                    {name:"Edit"}
                                ];
                                var permission = "View Only";
                                if ($scope.roleIdForSharedInstitution == '4') {
                                    permission = "Edit";
                                }
                                institution.permissions.name = permission;
                                institution.id = institution.id + "";
                                $scope.institutions.push(institution);

                                // Remove Owner/Self institution from shared survey display list
                                //if(institutionId == $scope.ownerCustomerId || institutionId == applicationService.getInstitutionId()){
                                if(institutionId == applicationService.getInstitutionId()){ // Replace this line with commented one for report sharing
                                    for (var count = 0; count <= $scope.institutions.length; count++){
                                        if(institutionId == $scope.institutions[count].id){
                                            $scope.institutions.splice(count, 1)
                                        }
                                    }
                                }


                            }

                            i++;
                            $scope.getInstitutionDetails(i, institutionIds);
                        });

                }

            };


            $scope.cancel = function () {
                $modalInstance.dismiss('cancel');
            };

            $scope.close = function(){
                $modalInstance.close();
            }

            $scope.getLicensedInstitutionIds();
            $scope.getAllInstitutionsByReportId();

        }

    }
)();
