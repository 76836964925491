app.controller(
    'SigninCtrl',
    [
        '$routeParams',
        '$scope',
        '$location',
        '$rootScope',
        'applicationService',
        'userService',
        'signInService',
        'customerService',
        'salesService',
    function(
        $routeParams,
        $scope,
        $location,
        $rootScope,
        applicationService,
        userService,
        signInService,
        customerService,
        salesService
    ) {

        $scope.$root.headerAction = 'hidden';
        $scope.showLoginUI = false;
        $scope.myJourneyUrl = my_journey_url_login;

        //as per the existing logic when customer id is undefined, this page is called from the /signin path
        if ($routeParams.customerId == undefined) {
            window.open(my_journey_url_login,'_self')
            return;
        }

        var IS_DIRECT = true;

        $scope.signUpForm = {};
        $scope.errors = {};

         //Removing the sign-in logic since we are removing individual app logins as part of SSO-7
/*        $scope.signIn = function(signUpForm){
            $scope.errors = {};
            signInService.validateEmail(signUpForm, $scope.errors);
            signInService.validatePassword(signUpForm, $scope.errors);

            if(!$scope.errors.email && !$scope.errors.password){
                applicationService.login(signUpForm.email, signUpForm.password, handleSuccessfulLogin, handleFailLogin);
            }
        }*/

        $scope.deepLinkSignIn = function() {
            console.log("deepLinkSignIn... enter.");
            //params: $routeParams.authToken, $routeParams.customerId, $routeParams.location
            if ($routeParams.authToken != undefined) {
                var token = $routeParams.authToken;
                $rootScope.customerId = $routeParams.customerId;
                $rootScope.location = $routeParams.location;
                $location.search("customerId", null);
                $location.search("location", null);
                $location.search("authToken", null);
                //$scope.$apply();

                console.log("Token detected. Performs login by token...");
                applicationService.loginByToken(token, SURVEY_APP_ID, handleSuccessfulLogin, handleFailLogin);
            }
            console.log("deepLinkSignIn... exit.");
        }

        var handleSuccessfulLogin = function(response){ // All setting of user data is handled in userService.handleSuccessfulLogin. This should be
                                                        // refactored so that much of the business logic here can be moved out of the controller and into services.
                                                        // Currently, the user object is returned to the controller to provide data for if/else logic and decisions.
            if ($routeParams.csv != null) {
                $scope.showLoginUI = false;
                //user is opening a csv report file.
                //check for response.user.id
                var _index = $routeParams.csv.indexOf('_');
                var userId = $routeParams.csv.substr(0, _index);
                if (response.user.id == userId) {
                    var csvFile = $routeParams.csv.substr(_index + 1);
                    window.open(reports_shared_drive_uri + csvFile, "_self");
                }

                return;
            }
            var user = userService.handleSuccessfulLogin(response);
            if (user) {
                if(!userService.isUserEnabled(user)){
                    if(userService.isUserDisabled(user)){
                        $scope.errors.login = "User profile is disabled";
                    }
                    else if(userService.isUserInvited(user)){
                        $scope.errors.login = "User is invited. Please follow instruction in invitation email";
                    }
                    return;
                }
                // If all other conditions fail, this logic runs.
                var userAssociations = user.associations;
                if (userAssociations.length === 0) {
                    $scope.errors.login = "User profile has no institution associated";
                } else {
                    var originalPath = applicationService.getOriginalPath();
                    if ((originalPath === "/preview-report" || originalPath == "/view-report") && $routeParams.report) {
                        applicationService.goToSigninPageWithRedirect(originalPath);
                        return;
                    }
                    // Note: The following code is crucial to the institution selection workflow for any user that is not a super admin
                    var products = salesService.getProductNames();
                    var customerId = userService.getUserCustomerId();
                    salesService.getCustodians(customerId, products, getCustodiansSuccess, null);
                }
            }
        }



        var handleFailLogin = function(errorMessage) {
            $scope.showLoginUI = false;
            //90,91- Remove the following 2 statements to support deep link login intention location on errors and successful re-login.
            $rootScope.customerId = undefined;
            $rootScope.location = undefined;
            applicationService.logout();
        }

        var getCustodiansSuccess = function(custodians){
            applicationService.getNonEssentialDataCache().custodianIds = custodians;

            var userAssociationsIds = userService.getUserAssociationIds();
            customerService.getCustomersWithChildStatus(userAssociationsIds, getCustomersSuccess, null, $scope);

        }

        var getCustomersSuccess = function(customersInfo){
            $scope.institutions = customersInfo;
            if(customersInfo.length === 1 && customersInfo[0].childCount == 0){
                var institutionId = customersInfo[0].id;
                signInService.getCustomerAndNext(institutionId, institutionId, IS_DIRECT);
            }
            else{
              applicationService.goToSigninSelectInstitutionPage();
            }
        }

        $scope.deepLinkSignIn();
    }
]);
