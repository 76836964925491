/**
 * Created by pvo on 6/12/15.
 */
/**
 * Created by pvo on 3/27/15.
 */

app.factory('SurveyTakingService', ['$resource','$sessionStorage','$routeParams', function ($resource, $sessionStorage,$routeParams) {
    return $resource(hostname + '/echo-api/public-api/survey/taking/:surveyId/:languageId/:parentInstitutionId/:institutionId',
        { surveyId: '@surveyId', languageId: '@languageId' },
        {
            'get': {
                method: 'GET',
                headers: { 'Authorization': getToken($routeParams,$sessionStorage) }
            },
            'response': {
                url: queuing_api_hostname + '/public-api/response/queue',
                method: 'POST',
                //answerString like '[{elementId,optionId,text},{elementId,optionId,text},...]'
                isArray: false,
                headers: { 'Authorization': getToken($routeParams,$sessionStorage) }

            },
            'getSupportedLanguagesForSurvey': {
                url: hostname + '/echo-api/public-api/survey/supportLanguages/:surveyId',
                method: 'GET',
                params: { surveyId: '@surveyId' },
                isArray: true,
                headers: { 'Authorization': getToken($routeParams,$sessionStorage) }
            },
            'getSupportedLanguagesForTemplate': {
                url: hostname + '/echo-api/public-api/survey/templateSupportLanguages/:templateId',
                method: 'GET',
                params: { templateId: '@templateId' },
                isArray: true
            },
            'getSurveyTakingForCertifiedContent': {
                url: hostname + '/echo-api/public-api/survey/previewCertifiedContent/:templateId/:languageId',
                method: 'GET',
                isArray: false
            },
            'getSurveyStatus': {
                url: hostname + '/echo-api/public-api/survey/status/:surveyId/:institutionId/:isAdminSurvey',
                method: 'GET',
                isArray: false,
                headers: { 'Authorization': getToken($routeParams,$sessionStorage) }
            },
            'getInstitutionName': {
                url: customer_api_hostname + '/noauth/institutionName/:id',
                method: 'GET',
                isArray: false
            },
            'validateToken': {
                url: hostname + '/echo-api/public-api/survey/tokenValidate/:surveyId/:tokenValue',
                method: 'GET',
                isArray: false
            }
        }
    );
}
]);

function getToken($routeParams,$sessionStorage) {
    // this function will be called every time the "get" action gets called
    // the result will be used as value for the header item
    // if it doesn't return a value, the key will not be present in the header
    // read token from session storage
    if($routeParams.token){
        $sessionStorage.token = $routeParams.token;
    }
    return $sessionStorage.token;
}
