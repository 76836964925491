/**
 * Created by bhadaway on 6/13/17.
 */
app.controller('ManageContentCtrl', ['$scope', '$rootScope', '$filter', '$http', '$routeParams', '$location', '$anchorScroll', '$timeout', '$localStorage', '$sessionStorage', 'ContentTemplateService', 'participantService',
    function ($scope, $rootScope, $filter, $http, $routeParams, $location, $anchorScroll, $timeout, $localStorage, $sessionStorage, ContentTemplateService, participantService) {

        $scope.$root.headerAction = 'active';

        ContentTemplateService.getCertifiedContent({publishedOnly: false}).$promise.then(
            function (data) {
                $scope.certifiedContentData = data;
                _.each($scope.certifiedContentData, function (obj) {

                    obj.lastUpdatedTimestampString = $filter('date')(obj.lastUpdatedTimestamp, "d MMM, y  h:mm a");

                });
            },
            function (error) {
                sweetAlert("There was an error loading the content list. This is most likely a problem connecting to the server. Please try again later.", error, "error");
            }
        );

        //clear the search input when x is pressed
        $scope.browseContentClearSearch = function (){
            $scope.searchToken = "";
        };

        $scope.getEventScope = function() {
            return $scope;
        };

        $scope.$on('showChangeRosterModal', function(event, survey) {
            $scope.changeRosterSurvey = survey;
            $scope.showChangeRosterModal = true;
        });

        $scope.filterByType = function (type) {
            $scope.displayFilterOption = type;
            if(type === 'All'){
                type = undefined;
            }
            $scope.typeFilter = type;
        };

        //return sort string
        var changeSortString = function (sortString) {
            if ($scope.sortString === sortString) {
                $scope.sortString = '-' + sortString;
            } else {
                $scope.sortString = sortString;
            }
        };

        // change arrow up and down
        var changeSortArrow = function (index) {
            if (($scope.contentName === false || $scope.contentName === null) && index === 1) {
                $scope.contentName = true;
                $scope.lastEditedBy = null;
                $scope.lastEditedDate = null;
                $scope.statusCol = null;
            } else if ($scope.contentName === true) {
                $scope.contentName = false;
                $scope.lastEditedBy = null;
                $scope.lastEditedDate = null;
                $scope.statusCol = null;
            }
            if (($scope.lastEditedBy === false || $scope.lastEditedBy === null) && index === 2) {
                $scope.lastEditedBy = true;
                $scope.contentName = null;
                $scope.lastEditedDate = null;
                $scope.statusCol = null;
            } else if ($scope.lastEditedBy === true) {
                $scope.lastEditedBy = false;
                $scope.contentName = null;
                $scope.lastEditedDate = null;
                $scope.statusCol = null;
            }
            if (($scope.lastEditedDate === false || $scope.lastEditedDate === null) && index === 3) {
                $scope.lastEditedDate = true;
                $scope.contentName = null;
                $scope.lastEditedBy = null;
                $scope.statusCol = null;
            } else if ($scope.lastEditedDate === true) {
                $scope.lastEditedDate = false;
                $scope.contentName = null;
                $scope.lastEditedBy = null;
                $scope.statusCol = null;
            }
            if (($scope.statusCol === false || $scope.statusCol === null) && index === 4) {
                $scope.statusCol = true;
                $scope.contentName = null;
                $scope.lastEditedBy = null;
                $scope.lastEditedDate = null;
            } else if ($scope.statusCol === true) {
                $scope.statusCol = false;
                $scope.contentName = null;
                $scope.lastEditedBy = null;
                $scope.lastEditedDate = null;
            }
        };

        var onTableHeaderClick = function (sortString, index) {
            changeSortString(sortString);
            changeSortArrow(index);
        };

        var filterOptions = [
            {name: "Draft"},
            {name: "Published"},
            {name: "Unpublished"}
        ];

        $scope.onTableHeaderClick = onTableHeaderClick;
        $scope.changeSortString = changeSortString;
        $scope.displayFilterOption = 'All';
        $scope.contentName = null;
        $scope.lastEditedBy = null;
        $scope.lastEditedDate = null;
        $scope.statusCol = null;
        $scope.filterOptions = filterOptions;

        if($rootScope.pendingReportDownload) {
            participantService.presentReportDownloadModal($rootScope.pendingReportIdentifier);
            $rootScope.pendingReportDownload = false;
            $rootScope.pendingReportIdentifier = null;
        }
    }
]);

