app.controller('PreviewTemplateCtrl', ['$scope', '$rootScope', '$routeParams', '$location', '$http', '$sessionStorage', 'ContentTemplateService',
  function($scope, $rootScope, $routeParams, $location, $http, $sessionStorage, ContentTemplateService) {
    //hide the default top bar
    $rootScope.headerAction = 'hidden';

    //URL for the terms and conditions
    $scope.termsAndConditions = TERMS_AND_CONDITIONS_PDF_URL;
    $scope.date = new Date();

    //get the template on page load
    getContentTemplate();

    //get authToken out of session storage
    $scope.accessToken = $sessionStorage.accessToken;

    swal({ title: "This content is being previewed.", text: "All responses will be discarded.", timer: 1500, showConfirmButton: false });

    $scope.backToEdit = function() {
      var backUrl = $location.search().back;
      if ( backUrl === 'browse' ) {
        $location.url('/browse-content');
      } else if ( backUrl === 'manage' ) {
        $location.url('/manage-content');
      } else if ( backUrl === 'institution-dashboard') {
          $location.url('/');
      } else {
        $location.url('/edit-template/' + $scope.survey.templateId);
      }
    };

    $scope.pages = [];
    $scope.currPageIndex = 0;

    //go to the next page
    $scope.nextPage = function() {
      validateQuestionAnswers(); //run checks on the questions and flag them if they are not valid
      if ( allRequiredQuestionsAnswered() ) {
        if( $scope.currPageIndex === $scope.pages.length - 1) {
          $scope.backToEdit();
        } else {
          $('.page-container').animate({scrollTop: $('.page-container').scrollTop(0)}, 1);
          $scope.currPageIndex++;
        }
      } else {
        var page = $scope.pages[$scope.currPageIndex];
        var firstInvalidQuestion;
        for ( let x = 0; x < page.length; x++ ) {
          if ( firstInvalidQuestion ) { break; }
          for ( let y = 0; y < page[x].childElementList.length; y++ ) {
            if ( page[x].childElementList[y].flagged ) { 
              firstInvalidQuestion = page[x].childElementList[y];
              break;
            }
          }
        }
        scrollToFirstInvalidQuestion(firstInvalidQuestion);
      }
    };

    //go to the previous page
    $scope.prevPage = function() {
      $scope.currPageIndex--;
    };

    $scope.showPrevBtn = function() {
      return $scope.currPageIndex > 0;
    };

    $scope.answerQuestion = function(element) {
      element.flagged = false;
    };

    $scope.answerPickOrOpinionQuestion = function(element, answer) {
      element.selectedAnswer = answer;
      element.flagged = false;
    };

    //check if the current page has any certified content
    $scope.hasCertifiedContent = function() {
      var page = $scope.pages[$scope.currPageIndex];
      if ( page ) {
        for(var i=0; i < page.length; i++) {
          if (page[i].certifiedSection) {
            return true;
          }
        }
        return false;
      }
    };

    ContentTemplateService.getTemplateSupportedLanguages({templateId: $routeParams.templateId}).$promise.then(
      function(data) {
        var selectedLang = data.filter(function(lang) {
          return lang.id ==  $routeParams.langId;
        });
        $scope.rtlFlag = selectedLang[0].rtl;
      },
      function(error) { }
    );

    function getContentTemplate() {
      var previewCustom = '/echo-api/public-api/survey/previewCustomContent/' + $routeParams.templateId + '/' + $routeParams.langId;
      var previewCertified = '/echo-api/public-api/survey/previewCertifiedContent/' + $routeParams.templateId + '/' + $routeParams.langId;
      var certified = $location.search().type === 'certified';
      var url = certified ? previewCertified : previewCustom;
      $http({
        method: 'GET',
        url: hostname + url,
        headers: {'Content-Type': 'application/json;charset=utf-8', 'authorization': $scope.accessToken}
      }).then(
        function(response) {
          $scope.beganTakingSurvey = true;

          /* modify the data for display */
          $scope.survey = modifyTemplateForPreview(response.data);

          /* build pages */
          var page = [];
          $scope.survey.elementList.forEach(function(section, index, sections) {
            var pageSection = {};
            pageSection.name = section.name;
            pageSection.text = section.text;
            pageSection.certifiedSection = section.certifiedSection;
            pageSection.childElementList = [];

            if (section.elementType === 'PAGE_BREAK') {
              $scope.pages.push(page);
              pageSection.childElementList = [];
              pageSection.name = null;
              pageSection.text = null;
              page = [];
            }

            if (section.childElementList) {
              section.childElementList.forEach(function(element, index, elements) {
                if ( element.elementType === 'PAGE_BREAK' ) {
                  var sectionCopy = angular.copy(pageSection);
                  page.push(sectionCopy);
                  //push whatever is in the current page to pages
                  $scope.pages.push(page);
                  //reset the pageSection
                  pageSection.childElementList = [];
                  pageSection.name = null;
                  pageSection.text = null;
                  //reset the page
                  page = [];
                } else {
                  pageSection.childElementList.push(element);
                }
              });
            }
            page.push(pageSection);
          });
          if ( page.length > 0 ) {
            $scope.pages.push(page);
          }
        },
        function(error) {
          sweetAlert("There was an error loading the content. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
        }
      );
    }

    $scope.pickMultiSpecialCase = function(element) {
      if (element.renderType === "PICK_MULTIPLE") {
        var selectedCount = pickMultiOptionSelectedCount(element);
        return selectedCount !== 0 && element.flagged && !element.required;
      }
    };

    /* helper functions */

      function scrollToFirstInvalidQuestion(element) {
        var id = 'question_' + element.id + '_';
        $('.page-container').animate({scrollTop: $('.page-container').scrollTop() + $('#' + id).offset().top - 50 }, 500);
      }

      function allRequiredQuestionsAnswered() {
        var page = $scope.pages[$scope.currPageIndex];
        var allRequiredAnswered = true;
        page.forEach(function(section) {
          section.childElementList.forEach(function(element) {
            if (element.required && element.flagged || $scope.pickMultiSpecialCase(element)) {
              allRequiredAnswered = false;
            }
          });
        });
        return allRequiredAnswered;
      }

      function validateQuestionAnswers() {
        var page = $scope.pages[$scope.currPageIndex];
        page.forEach(function(section) {
          section.childElementList.forEach(function(element) {
            if (element.renderType === "PICK_ONE" || element.renderType === "DROPDOWN" || element.renderType === "OPINION_SCALE") {
              element.flagged = validatePickOneDropOpinion(element);
            }
            if (element.renderType === "PICK_MULTIPLE") {
              element.flagged = validatePickMulti(element);
            }
            if (element.renderType === "TEXT_FIELD") {
              element.flagged = validateTextField(element);
            }
          });
        });
      }

      function validateTextField(element) {
        return element.textField === "" || element.textField === undefined;
      }

      function validatePickOneDropOpinion(element) {
        return element.selectedAnswer === undefined;
      }

      function validatePickMulti(element) {
        var count = pickMultiOptionSelectedCount(element);
        return count < element.minOptions || count > element.maxOptions;
      }

      function pickMultiOptionSelectedCount(element) {
        return element.optionList.reduce(function(count, answer) {
          if (answer.selected) { return count + 1; }
          return count;
        }, 0);
      }

      function modifyTemplateForPreview(data) {
        setupOptionScaleEmoticons(data);
        fixMultipleAnswerQuestionMinsAndMaxes(data);
        return data;
      }

      function setupOptionScaleEmoticons(data) {
        data.elementList.forEach(function(section){
          section.childElementList.forEach(function(question){
            if( question.renderType === 'OPINION_SCALE' ) { setupOptionListForScale(question); } 
          });
        });
      }

      function fixMultipleAnswerQuestionMinsAndMaxes(data) {
        data.elementList.forEach(function(section) {
          if (section.childElementList) {
            section.childElementList.forEach(function(question) {
              if (question.renderTypeId === 2 && question.minOptions > question.maxOptions) {
                question.minOptions = 1;
                question.maxOptions = question.optionList.length;
              }
            });
          }
        });
      }

      function setupOptionListForScale(question) {
        var lastIndex = question.optionList.length - 1;
        var naOption = false;
        if ( question.optionList[lastIndex].value === 0 ) {
          naOption = question.optionList.pop();
          naOption.icon = "icon-block";
        }
        var optionIcons = getOptionIconList(question.optionList.length);
        question.optionList = question.optionList.map(function(option, index) {
          return {id: option.id, order: option.order, value: option.value, text: option.text, icon: optionIcons[index]};
        });
        if ( naOption ) { question.optionList.push(naOption); }
      }

      function getOptionIconList(count) {
        switch (count) {
          case 3:
            var optionScaleList = [
              "icon-opinion-4-agree",
              "icon-opinion-3-neutral",
              "icon-opinion-2-disagree"
            ];
            break;
          case 4:
            var optionScaleList = [
              "icon-opinion-5-strongly-agree",
              "icon-opinion-4-agree",
              "icon-opinion-2-disagree",
              "icon-opinion-1-strongly-disagree"
            ];
            break;
          default:
            var optionScaleList = [
              "icon-opinion-5-strongly-agree",
              "icon-opinion-4-agree",
              "icon-opinion-3-neutral",
              "icon-opinion-2-disagree",
              "icon-opinion-1-strongly-disagree"
            ];
        }
        return optionScaleList;
      }

  }
]);

