app.factory('SurveyService', 
    ['$resource',
     '$timeout', 
     'applicationService', 
     'customerService', 
     'DashboardService', 
     'userService', 
    function($resource, 
             $timeout, 
             applicationService, 
             customerService, 
             DashboardService, 
             userService){
    return new SurveyService($resource, 
                             $timeout, 
                             applicationService, 
                             customerService, 
                             DashboardService, 
                             userService);
}
])

function SurveyService($resource, $timeout, applicationService, customerService, DashboardService, userService){
    this.remove = function(id, userId){
        var surveyDel =  $resource(
                hostname +'/echo-api/api/surveys/:id', {id: '@id'},
            {
                "remove" : {method : "DELETE", params: {userId: '@userId'}}
            }
        );
        return surveyDel.remove({id: id, userId: userId})
    }

    //this removes the object from the object identity table
    this.removeObject = function(objectType, objectInstanceId, ownerUserId, customerId, userId){
        var obj = {
            'objectType': objectType,
            'objectInstanceId': objectInstanceId,
            'ownerUserId': ownerUserId,
            'customerId':customerId,
            'userId': userId,
            'applicationId' : 2
        }

        var del =  $resource(
                users_api_hostname +'/users/delete-object',
            null,
            {
                'remove': {url: users_api_hostname +'/users/delete-object',
                    method:'POST',
                    isArray:false
                }
            }
        );
        return del.remove(obj);
    }

    this.surveysForUserProfile =  function(scope, successCallback){
        //retrieve the logged in user's details
        var user = userService.getUser(); // TODO: Roles Permissions - user added to service method variables.
        var institution = applicationService.getInstitution();
        var adminFlag = userService.userIsLicenseOwnerAdmin();
        var userAssociation = userService.getUserCurrentAssociation();

        //for convenience, populate the (local) scope of the calling module
        scope.adminMode = adminFlag;
        scope.userId  = user.id;
        scope.institutionId = institution.id;
        scope.licenseUser = (institution.type && institution.type != 'school' ? true : false); //used to drive the 'Schools' tab on the dashboard
        scope.surveyRequest = {};
        scope.surveyRequest.surveyIds = []; //surveys that this user profile is limited to
        scope.surveyIdToSurveyPermissionMap = new Object(); //a Map of surveyId to permission object

        if(typeof userAssociation == "undefined" || typeof userAssociation.isDirectAssociation == "undefined" || userAssociation.isDirectAssociation) {
            if (adminFlag) //if an admin then retrieve surveys based on institutionId directly from the surveys application
            {
                DashboardService.surveyIdsForInstitution({userId: user.id, institutionId: institution.id}).$promise.then(function (data) {
                    for (var ind = 0; ind < data.length; ind++) {
                        scope.surveyRequest.surveyIds.push(data[ind]);
                    }
                    //Once the profile data is ready, now invoke the success callback
                    successCallback();
                }, function (httpResponse) {
                    $timeout(errorPopup, 500);
                })
            }
            else //for non-admin level permissions make a call to retrieve surveys accessible to the user profile
            {
                var viewer = userService.userCurrentAssociationRoleNameIsViewer();
                console.log('user.id = ' + user.id + '. institution.id = ' + institution.id);
                userService.getSurveysForUserProfile(user.id, institution.id, !viewer).$promise.then(function (data) {
                    var dataSize = (data ? data.length : 0);
                    var userPermissions = userAssociation.permissions;  // TODO: Roles Permissions - userAssociation permissions accessed directly in service, then permissions are set on the surveyData objects
                    if (dataSize > 0) {
                        for (var ind = 0; ind < dataSize; ind++) {
                            var surveyData = data[ind];
                            surveyData.userId = user.id;
                            surveyData['permissions'] = userPermissions;
                            scope.surveyRequest.surveyIds.push(surveyData.objectId);
                            scope.surveyIdToSurveyPermissionMap[surveyData.objectId] = surveyData;
                        }
                    }

                    //Once the profile data is ready, now invoke the success callback
                    successCallback();
                }, function (httpResponse) {
                    $timeout(errorPopup, 500);
                })
            }
        }else{
            console.log('calling getAllSurveysForByCustomer ... institution.id = ' + institution.id);
            userService.getAllSurveysForByCustomer(institution.id).$promise.then(function (data) {
                var dataSize = (data ? data.length : 0);
                if (dataSize > 0) {
                    var userPermissions = [];
                    userPermissions = userAssociation.permissions; // TODO: Roles Permissions - permissions are accessed directly from the userAssociation.permissions property, then added to all survey objects.
                    for (var ind = 0; ind < dataSize; ind++) {
                        var surveyData = data[ind];
                        surveyData['permissions'] = userPermissions;
                        scope.surveyRequest.surveyIds.push(surveyData.objectId);
                        scope.surveyIdToSurveyPermissionMap[surveyData.objectId] = surveyData;
                    }
                }
                //Once the profile data is ready, now invoke the success callback
                successCallback();
            }, function (httpResponse) {
                $timeout(errorPopup, 500);
            })
        }
    };

     var institutionForIds = this.institutionForIds =   function(ind, institutions, successCallback, successCallbackParam1, successCallbackParam2 ){
        if (institutions && ind < institutions.length)
        {
            var institution = institutions[ind];
            customerService.getCustomerByIdResource(institution.id).$promise.then(function(customer){
                institution.name = customer.name;
                ++ind;
                institutionForIds(ind, institutions, successCallback, successCallbackParam1, successCallbackParam2);
            })
        }
        else
        {
            if (successCallbackParam1 && successCallbackParam2)
            {
                successCallback(institutions, successCallbackParam1,successCallbackParam2 );
            }
            else successCallback();
        }
    };

    this.getCustomerHierarchy = function(institution, successCallback){
        customerService.getInstitutionsByParentIdResource(institution.id).$promise.then(function(institutions){
            successCallback(institutions);
        });
    }

    this.archiveSurvey = function(surveyId, userId, status){
        var obj = {
            'surveyId': surveyId,
            'userId': userId,
            'status': status
        }

        var archiveResource = $resource(null, null,
            {
                'archive': {url: hostname + '/echo-api/api/survey/archive',
                    method:'POST',
                    isArray:false
                }
            }
        );

        return archiveResource.archive(obj);
    }

    this.geInstitutionsByHierarchy = function(institution, successCallback) {
        if(institution.type == "school"){
            successCallback([institution.id]);
            return;
        }

        customerService.getInstitutionsByParentIdResource(institution.id).$promise.then(function(institutions){
            successCallback(_.pluck(institutions,"id"), institutions);
        });
     }



}
