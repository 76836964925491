app.controller('TemplateCreateController', 
    ['$sessionStorage',
     '$q', 
     '$scope', 
     '$rootScope',
     '$routeParams', 
     'userService', 
     'SurveyCreateService', 
     'ContentTemplateService', 
     '$timeout', 
     '$location',  
     'modalService',
     '$window',
  function($sessionStorage, 
           $q, 
           $scope, 
           $rootScope,
           $routeParams, 
           userService, 
           SurveyCreateService, 
           ContentTemplateService, 
           $timeout, 
           $location,  
           modalService,
           $window
      ) {

      if($routeParams.assignmentsConfirmed === "true") {
          // move flag to session storage so it'll survive the reload
          $sessionStorage.confirmAssignBannerVisible = true;
          $location.search("assignmentsConfirmed", null);
          // set 10 second timeout to auto close
          $timeout(function() {
              if($sessionStorage.confirmAssignBannerVisible){
                  $sessionStorage.confirmAssignBannerVisible = false;
              }
          }, 10000);
      }

      $scope.confirmAssignBannerVisible = function() {
          return $sessionStorage.confirmAssignBannerVisible;
      };

      //load the contentTemplate, defaulting to loading in english with the second argument
      getContentTemplateInitialLoad($routeParams.templateId, 1);

      //hides language dropdown initially
      $scope.showLanguageDropdown = false;

      $scope.backActionLink = "";

      //hides the top bar that's used everywhere, so that the top bar specific to this page is the only one shown
      $scope.$root.headerAction = 'hidden';

      $scope.updateTemplateForDisplay = function() {
        $scope.contentTemplate = modifyTemplateForDisplay($scope.contentTemplate);
      };

    /****
    * UI INTERACTIONS 
    ****/
      $scope.publishBtnClicked = false;

      $scope.$on('openCloseChange', function(event, data) {
        $scope.administrationDates.openDate = data.openTime;
        $scope.administrationDates.closeDate = data.closeTime;
      });

      $rootScope.$on('templateOwnerConfigurationChanged', function(event, isCustomerConfiguredForSes) {
          $scope.selectedInstitutionConfiguredForSes = isCustomerConfiguredForSes;
      });

      $scope.showDateTimeError = function() {
        return $scope.publishBtnClicked && $scope.contentTemplate.administrationTemplate && (!$scope.administrationDates.openDate || !$scope.administrationDates.closeDate);
      };

      $scope.showInstitutionOwnerError = function() {
        return $scope.publishBtnClicked && (!$scope.contentTemplate.institutionId && !$scope.templateOwnership.adEd);
      };

      $scope.showInstitutionConfigurationError = function() {
        return $scope.publishBtnClicked && (!$scope.selectedInstitutionConfiguredForSes && !$scope.templateOwnership.adEd) && !$scope.showInstitutionOwnerError() && $scope.contentTemplate.administrationTemplate;
      };

      $scope.unpublishTemplatePreCheck = function() {
        swal({
          title: "Unpublish Content",
          text: "Are you sure you would like to unpublish this content?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#44b644",
          confirmButtonText: "Unpublish",
          closeOnConfirm: true
        }, function (confirmation) {
            if (confirmation) { unpublishTemplate(); }
          }
        );
      };

      $scope.closeConfirmAssignBanner = function() {
          // $scope.confirmAssignBannerVisible = false;
          $sessionStorage.confirmAssignBannerVisible = false;
      };

      $scope.publishCertifiedCheck = function() {
        $scope.publishBtnClicked = true;
        if ($scope.showDateTimeError() || $scope.showInstitutionOwnerError() || $scope.showInstitutionConfigurationError()) { return; }
        ContentTemplateService.validateTemplateForPublish({templateId: $scope.contentTemplate.templateId}).$promise.then(
          function(data) {
            if ($scope.contentTemplate.administrationTemplate) {
              modalService.presentCustomModal({
                  title: '<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> <span>Publish Template</span>',
                  text: "<p><strong>Are you sure you want to publish this template?</strong></p><p>Publishing this template will place it in the content library of selected institutions, immediately.</p>",
                  showCancelButton: true,
                  confirmButtonColor: "#148900",
                  customClass: "publish-template-modal",
                  confirmButtonText: "Cancel",
                  cancelButtonText: "Publish",
                  closeOnConfirm: true,
                  allowOutsideClick: true,
                  html: true
              }, function() {
                // Buttons are reversed - this is now Cancel
              }, function() {
                // Buttons are reversed - this is now Publish
                  publishCertifiedTemplate();
              })
            } else {
              modalService.presentCustomModal({
                  title: '<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> <span>Publish Template</span>',
                  text: "<p><strong>Are you sure you want to publish this template?</strong></p><p>Publishing this template will place it in the content library of selected institutions, immediately.</p>",
                  showCancelButton: true,
                  confirmButtonColor: "#148900",
                  customClass: "publish-template-modal",
                  confirmButtonText: "Cancel",
                  cancelButtonText: "Publish",
                  closeOnConfirm: true,
                  allowOutsideClick: true,
                  html: true
              }, function() {
                // Buttons are reversed - this is now Cancel button
              }, function() {
                // Buttons are reversed - this is now Publish button
                  publishCertifiedTemplate();
              });
            }
          },
          function(error) {
            swal({
              title: "Missing Language Translations",
              text: "Translations have not been entered for all selected languages",
              type: "warning",
              confirmButtonColor: "#44b644",
              confirmButtonText: "OK",
              closeOnConfirm: true
            });
          }
        );
      };

      $scope.validateForPublish = function() {
          console.log("Executing Validate For Publish");
          ContentTemplateService.validateTemplateForPublish({templateId: $scope.contentTemplate.templateId}).$promise.then(
              function(data) {
                  // redirect to publish page like normal
                  $window.location.href = `/surveys/#/publish-content/${$scope.contentTemplate.templateId}`
              },
              function(error) {
                  swal({
                          title: "Missing Language Translations",
                          text: "Translations have not been entered for all selected languages",
                          type: "warning",
                          showCancelButton: false,
                          confirmButtonColor: "#DD6B55",
                          confirmButtonText: "OK",
                          closeOnConfirm: true
                      },
                      function() {
                          // $window.location.href = '/surveys/#/edit-template/' + $scope.templateId;
                      });
              }
          );
      }

      $scope.changeTemplateLang = function(lang) {
        $scope.contentTemplate.selectedLanguage = lang;
        $scope.refreshContentTemplateNewLang($routeParams.templateId, lang);
        $scope.toggleLanguageDropdown();
      };

      $scope.toggleLanguageDropdown = function() {
        $scope.showLanguageDropdown = !$scope.showLanguageDropdown;
      };

      $scope.goToPreviewTemplatePage = function() {
        var previewCustomUrl = '/preview-content/' + $scope.contentTemplate.templateId + '/language/' + $scope.contentTemplate.selectedLanguage.id + '?type=custom';
        var previewCertifiedUrl = '/preview-content/' + $scope.contentTemplate.templateId + '/language/' + $scope.contentTemplate.selectedLanguage.id + '?type=certified';
        var langSelectUrl = '/select-lang/' + $scope.contentTemplate.templateId;
        var selected = $scope.contentTemplate.templateLanguages.filter(function(lang) { return lang.selected; });
        if ( selected.length > 1 ) {
          $location.url(langSelectUrl);
        } else {
          var url = $scope.contentTemplate.certified ? previewCertifiedUrl : previewCustomUrl; 
          $location.url(url);
        }
      };
    /****
    * (END) UI INTERACTIONS
    ****/

    /****
    * API CALLS - calls to API endpoints
    ****/
      function publishCertifiedTemplate() {
        var body = {
          userId: userService.getUserId(),
          templateId: $scope.contentTemplate.templateId,
          isCertified: true
        };
        if ($scope.contentTemplate.administrationTemplate) { body.administrationSurveyId = $scope.contentTemplate.administrationSurveyId; }
        ContentTemplateService.publishTemplate(body).$promise.then(
          function(data) {
            $location.url('/manage-content');
          },
          function(error) {
            sweetAlert("There was an error publishing the content. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
          }
        );
      }

      $scope.hasContentName = function() {
          if(!$scope.contentTemplate){
              return false;
          }
          return $scope.contentTemplate.templateName && $scope.contentTemplate.templateName !== '';
      };

      function unpublishTemplate() {
        var body = {userId: userService.getUserId(), templateId: $scope.contentTemplate.templateId};
        if ($scope.contentTemplate.administrationTemplate) { body.administrationSurveyId = $scope.contentTemplate.administrationSurveyId; }
        ContentTemplateService.unpublishTemplate(body).$promise.then(
          function(data) {
            $scope.refreshContentTemplateNewLang($scope.contentTemplate.templateId, $scope.contentTemplate.selectedLanguage);
          },
          function(error) {
            sweetAlert("There was an error unpublishing the content. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
          }
        );
      };

      //save template name
      $scope.saveTemplateName = function() {
        if(!$scope.hasContentName()){
          return;
        }
        var body = {
          institutionId: $scope.contentTemplate.institutionId,
          languageId: $scope.contentTemplate.languageId,
          templateId: $scope.contentTemplate.templateId,
          templateName: $scope.contentTemplate.templateName,
          userId: userService.getUserId()
        };
        ContentTemplateService.updateTemplateName(body).$promise.then(
          function(data) {
            $scope.validTemplateName = data.success;
          },
          function(error) {
            if(error.data.message === "Template name already exists for the institution") {
              $scope.validTemplateName = error.data.success;
            } else {
              sweetAlert("There was an error saving the template name. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
            }
          }
        );
      };
      
      //refresh content template
      $scope.refreshContentTemplateNewLang = function(templateId, lang) {
        ContentTemplateService.get({templateId: templateId, languageId: lang.id}).$promise.then(
          function(data) {
            data.languageId = lang.id;
            data.selectedLanguage = lang;
            $scope.contentTemplate = modifyTemplateForDisplay(data);
            findSelectedSurveyType(); // Recalculates the survey type property
            getLanguages();
          },
          function(error) {
            sweetAlert("There was an error getting the content template. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
          }
        );
      }

      $scope.refreshContentTemplate = function(templateId, langId) {
        ContentTemplateService.get({templateId: templateId, languageId: langId}).$promise.then(
          function(data) {
            $scope.contentTemplate = modifyTemplateForDisplay(data);
          },
          function(error) {
            sweetAlert("There was an error getting the content template. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
          }
        );
      };

      //on load, get the template from the routeParams
      //passing 1 (which is english) for the languageId
      function getContentTemplateInitialLoad(templateId, langId) {
        ContentTemplateService.get({templateId: templateId, languageId: langId}).$promise.then(
          function(data) {
            $scope.contentTemplate = modifyTemplateForDisplay(data);
            $scope.certificationId = data.certificationId;
            findSelectedSurveyType();
            setBackActionLink();
            getLastModifiedUser();
            getLanguages();
            getPublishInfo();
            getInstitutionDetails();
            setAdministrationDates(data.administrationOpenDate, data.administrationCloseDate);
            doesAdEdOwnTemplate();
            checkInstitutionSesConfiguration();
          },
          function(error) {
            sweetAlert("There was an error getting the content template. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
          }
        );
      }

      $scope.isPublished = function() {
          return $scope.contentTemplate && $scope.contentTemplate.statusString && $scope.contentTemplate.statusString.toLowerCase() === "published";
      };

      function checkInstitutionSesConfiguration() {
        if ($scope.contentTemplate.institutionId) {
          ContentTemplateService.checkCustomerSesConfiguration({institutionId: $scope.contentTemplate.institutionId}).$promise.then(
            function(data) {
              $scope.selectedInstitutionConfiguredForSes = data.isCustomerConfiguredForSes;
            },
            function(error) {
              sweetAlert("There was an error loading the institution config information. This is most likely a problem connecting to the server. Please try again later.", error, "error");
            }
          );
        }
      }

      function doesAdEdOwnTemplate() {
        $scope.templateOwnership = {adEd: !$scope.contentTemplate.administrationTemplate && !$scope.contentTemplate.institutionId};
      }

      function setAdministrationDates(open, close) {
        $scope.administrationDates = {
          openDate: open,
          closeDate: close
        };
      }

      function findSelectedSurveyType() {
        $scope.contentTemplate.selectedSurveyType = null;

        if ($scope.contentTemplate.surveyTypeList != undefined) {
          for ( let i = 0; i < $scope.contentTemplate.surveyTypeList.length; i++ ) {
            if ($scope.contentTemplate.surveyTypeList[i].id === $scope.contentTemplate.selectedSurveyTypeId) {
              $scope.contentTemplate.selectedSurveyType = $scope.contentTemplate.surveyTypeList[i];
              return;
            }
          }
        }
      }

      //get publishing info if the content template has been published
      function getPublishInfo() {
        if ($scope.contentTemplate.status === 3) {
          var body = {templateId: $scope.contentTemplate.templateId};
          ContentTemplateService.getTemplatePublishedInstitutions(body).$promise.then(
            function(response) {
              $scope.publishInfo = {list: response};
              if ($scope.publishInfo.list.length == 1 && $scope.publishInfo.list[0].publishToChildren && $scope.publishInfo.list[0].id == $sessionStorage.institution.id) {
                $scope.publishInfo.publishedTo = "inst-and-children";
              } else if ($scope.publishInfo.list.length == 1 && !$scope.publishInfo.list[0].publishToChildren && $scope.publishInfo.list[0].id == $sessionStorage.institution.id) {
                $scope.publishInfo.publishedTo = "inst-only";
              } else {
                $scope.publishInfo.publishedTo = "selected";
              }
            },
            function(error) {
              sweetAlert("There was an error loading the template publishing information. This is most likely a problem connecting to the server. Please try again later.", error, "error");
            }
          );
        }
      }

      //retrive the info of the user that last modified the template
      function getLastModifiedUser() {
        // For Old Published Non Admin Templates Last Modified User info will be unavailable
        if(!$scope.contentTemplate.lastUpdatedUserId){
          $scope.contentTemplate.lastModifiedUser = {firstName: "Content", lastName: "Creator"};
        }else{
        userService.getUserById($scope.contentTemplate.lastUpdatedUserId).$promise.then(
          function(data) {
            $scope.contentTemplate.lastModifiedUser = data;
          },
          function(error) {
            sweetAlert("There was an error getting the user that last modified the template. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
          }
        );
        }
      };

      function setBackActionLink() {
        if ( $scope.contentTemplate.certified ) {
          $scope.backActionLink =  '#/manage-content';
        } else {
          $scope.backActionLink = '#/browse-content';
        }
      };

      //get available languages and template languages for language checkboxes
      //set the selected language to whatever the templates specified language is
      function getLanguages() {
        var allLang = ContentTemplateService.getAllSupportedLanguages().$promise;
        var tempLang = ContentTemplateService.getTemplateSupportedLanguages({templateId: $routeParams.templateId}).$promise;
        $q.all([allLang, tempLang]).then(function(responses) {
          var allLangResp = responses[0];
          var tempLangIds = responses[1].map(function(lang) { return lang.id; });
          $scope.contentTemplate.templateLanguages = allLangResp.map(function(lang) {
            lang.selected = tempLangIds.indexOf(lang.id) !== -1 ? true : false;
            return lang;
          }, tempLangIds);
          if (!$scope.contentTemplate.selectedLanguage) {
            $scope.contentTemplate.templateLanguages.forEach(function(lang) {
              if ( lang.id == this.languageId ) {
                $scope.contentTemplate.selectedLanguage = lang;
              }
            }, $scope.contentTemplate);
          }
        }).catch(
          function(error) {
            sweetAlert("There was an error getting the template languages. This is most likely a problem connecting to the server. Please try again later.", error, "error");
          }
        );
      }

      /*
       * Gets the institution owner name if there is an institution owner
       */
      function getInstitutionDetails() {
        var id = $scope.contentTemplate.institutionId;
        if (id) {
          SurveyCreateService.getInstitutionDetails({institutionId: id}).$promise.then(
            function(data) {
              $scope.institutionOwnerName = data.name;
              $scope.institutionOwnerId = data.id;
            },
            function(error) { }
          );
        }
      }

    /****
    * (END) API CALLS 
    ****/

    /****
    * TEMPLATE DATA DISPLAY MODIFICATIONS - The functions below take the content template from the ContenteTemplateService and derive any values needed for display
    ****/
      function modifyTemplateForDisplay (data) {
        data.lastUpdateTimeString = convertTimestampToDate(data.lastUpdatedTimestamp);
        data.statusString =  {1: "Draft", 2: "Edit", 3: "Published", 4: "Archived", 5: "Unpublished"}[data.status];
        //converting all the question tag arrays to ints instead of strings
        //setting up the icons for scale type questions
        data.elementList.forEach(function(section){
          section.childElementList.forEach(function(question){
            question.tags = question.tags.map(function(tag){ return parseInt(tag) });
            if(['PICK_ONE', 'PICK_MULTIPLE', 'DROPDOWN'].indexOf(question.renderType) !== -1 && question.optionList.length > 0) {
              question.optionCategoryId = question.optionList[0].categoryId; 
            };
            if(['PICK_ONE'].indexOf(question.renderType) !== -1 && question.engagementDomains.length > 0) {
              question.engagementDomainId = question.engagementDomains[0]; 
            };
            if(question.renderType === 'OPINION_SCALE') { $scope.setupOptionListForScale(question); } 
          });
        });

        return data;
      }

      $scope.setupOptionListForScale = function(question) {
        var lastIndex = question.optionList.length - 1;
        var naOption = false;
        if ( question.optionList[lastIndex].value === 0 ) {
          naOption = question.optionList.pop();
          naOption.icon = "icon-block";
        }
        var optionIcons = getOptionIconList(question.optionList.length);
        question.optionList = question.optionList.map(function(option, index) {
          return {id: option.id, order: option.order, value: option.value, text: option.text, icon: optionIcons[index]};
        });
        if ( naOption ) {
          question.optionList.push(naOption);
          question.includeNAOption = true;
        }
      }
      
      function getOptionIconList(count) {
        switch (count) {
          case 3:
            var optionScaleList = [
              "icon-opinion-4-agree",
              "icon-opinion-3-neutral",
              "icon-opinion-2-disagree"
            ];
            break;
          case 4:
            var optionScaleList = [
              "icon-opinion-5-strongly-agree",
              "icon-opinion-4-agree",
              "icon-opinion-2-disagree",
              "icon-opinion-1-strongly-disagree"
            ];
            break;
          default:
            var optionScaleList = [
              "icon-opinion-5-strongly-agree",
              "icon-opinion-4-agree",
              "icon-opinion-3-neutral",
              "icon-opinion-2-disagree",
              "icon-opinion-1-strongly-disagree"
            ];
        }
        return optionScaleList;
      }


      function convertTimestampToDate(timestamp){
          var amPm = ' am';
          timestamp = parseInt(timestamp);
          var d = new Date(timestamp);
          var month = d.getUTCMonth();
          var day = d.getDate();
          var year = d.getFullYear();
          var hours = d.getHours();
          if(hours > 12){
              hours = hours - 12;
              amPm = ' pm';
          }
          var minutes = d.getUTCMinutes();
          minutes = minutes < 10 ? "0" + minutes : minutes;
          return (month +1) + '/' + day + '/' + year + ' ' + hours + ':' + minutes + amPm;
      };
    /****
    * (END) TEMPLATE DISPLAY MODIFICATIONS
    ****/

  }
]);
