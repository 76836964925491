app.directive('transformDropdown', function() {
  var OPEN = 1;
  var CLOSED = 2;
  return { restrict: 'A',
    require: 'ngModel',
    link: function(scope, element, attrs, ngModel) {

      if(ngModel) { // Don't do anything unless we have a model
        var primaryItem = null;
        var originalItem = null;
        var params = attrs.transformDropdown.split('|');
        var originalDropdownCount = params[0];
        var status = params[1];

        ngModel.$parsers.push(function (value) {
          if (status == OPEN || status == CLOSED) {
            var currLineCount = (value.match(/\n/g) || []).length;
            if (currLineCount <= originalDropdownCount - 2) {
              for (var i = currLineCount; i <= originalDropdownCount - 2; i++) {
                value = value + '\n';
              }
            }
          }

          originalItem = angular.copy(primaryItem);

          //value = value.trim();
          if (value === '') {
            originalItem = [];
          }
          else {
            var options = value.split('\n');

            if (options.length < originalItem.length) {
              originalItem.splice(options.length, originalItem.length - options.length);
            }

            for (var i = 0; i < options.length; i++) {
              if (i < originalItem.length) {
                originalItem[i].text = options[i];
              }
              else {
                originalItem.push({text: options[i]});
              }
            }
          }

          return originalItem;
          //return value.split('\n').map(function(item){return {text: item}});
        });

        ngModel.$formatters.push(function (value) {
          primaryItem = angular.copy(value);
          return value.map(function(item){return item.text}).join("\n");
        });

      }
    }
  };
});