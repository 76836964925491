app.controller('ChangePasswordCtrl', ['$scope', 'applicationService', 'signInService', '$sce', 'userService', '$timeout',
    function($scope, applicationService, signInService, $sce, userService, $timeout) {

        $scope.$root.headerAction = 'hidden';

        $scope.login = {};
        $scope.errors = {};
        $scope.passwordFormat = {
            beEightChars: BE_EIGHT_CHARS,
            atleastOneNum: AT_LEAST_ONE_NUM,
            atleastOneLowercase: AT_LEAST_ONE_LOWERCASE,
            atleastOneUppercase: AT_LEAST_ONE_UPPERCASE,
            atleastOneSpecialChar: AT_LEAST_ONE_SPECIAL_CHAR,
            eightChar: true,
            oneNumber: true,
            oneLowercase: true,
            oneUppercase: true,
            oneSpecialChar: true
        };

        $scope.reenterPasswordDisabled = true;

        var SUCCESS = 'success';
        var ERROR = 'error';

        $scope.successStatus = SUCCESS;
        $scope.errorStatus = ERROR;

        var initialize = function(){

            var tokenUrl = applicationService.getLocationAbsUrl();
            applicationService.checkResetPasswordLink(tokenUrl, handleCheckLink, handleCheckLinkError);

        }

        var handleCheckLink = function(user){
            userService.setUser(user);
            $scope.status = SUCCESS;
        }
        var handleCheckLinkError = function(message){
            /*$scope.status = ERROR;
            if (message === 'Internal Server Error') {
                $scope.errorMessage = $sce.trustAsHtml('<p class="text-center">This password link is expired.</p><br><p class="text-center"><a href="#/forgot-password">Please request another one.</a></p></div>');
            } else {
                $scope.errorMessage = message;
            }*/
            window.location.href = my_journey_url_login + "/expired-link";
        }

        $scope.onPasswordFocus = function() {
            console.log("--- onPasswordFocus ---");
            $scope.login.confirmPassword = "";
        }

        $scope.onPasswordBlur = function() {
            $scope.reenterPasswordDisabled = false;

            $timeout(function() {
                var reenterPassIdElement = document.getElementById("reenterPassId");
                reenterPassIdElement.focus();
            });

            var passwordDTO = new Object();
            passwordDTO.password = $scope.login.password;
            userService.validatePassword(passwordDTO).$promise.then(function (data) {
                // success handler
                if (data != null) {
                    $scope.passwordFormat.eightChar = data.eightChar;
                    $scope.passwordFormat.oneNumber = data.oneNumber;
                    $scope.passwordFormat.oneLowercase = data.oneLowercase;
                    $scope.passwordFormat.oneUppercase = data.oneUppercase;
                    $scope.passwordFormat.oneSpecialChar = data.oneSpecialChar;

                    if (!data.eightChar || !data.oneNumber || !data.oneLowercase || !data.oneUppercase || !data.oneSpecialChar) {
                        $scope.reenterPasswordDisabled = true;
                    }
                }

            }, function (error) {
                // error handler
                errorPopup("The system encountered a problem, please try again.", error.data.message, "error");
            });
        }

        $scope.changePassword = function(login){
            $scope.errors = {};
            signInService.validatePassword(login, $scope.errors);
            signInService.validateConfirmPassword(login, $scope.errors);

            if(!$scope.errors.password && !$scope.errors.confirmPassword){
                applicationService.updatePassword(login.password, handleUpdatePassword, handleUpdatePasswordError);
            }
        }


        var handleUpdatePassword = function(){
            applicationService.goToSigninPage();
        }

        var handleUpdatePasswordError = function(message){
            $scope.status = ERROR;
            $scope.errors.main = message;
        }


        initialize();
    }
])