
app.factory('TokenService', [ '$resource', function($resource) {
        return $resource(
            "/echo-api/api/surveys",
            null,
            {
                'get': { url: hostname + '/echo-api/api/tokens/:surveyId/:tokenValue', method: 'GET', isArray: false },
                'getAll': { url: hostname + '/echo-api/api/tokens/:surveyId', method: 'GET', isArray: true },

                'add': { url: hostname + '/echo-api/api/tokens/add', method: 'POST', isArray: false },
                'update': { url: hostname + '/echo-api/api/tokens/update', method: 'POST', isArray: false },

                'validate': { url: hostname + '/echo-api/api/tokens/validate/:surveyId/:tokenValue', method: 'GET', isArray: false },

                'search': { url: hostname + '/echo-api/api/tokens/search/:surveyId/:tokenValue', method: 'GET', isArray: true },

                'createCSV': { url: hostname + '/echo-api/api/tokens/:surveyId/raw', method: 'GET', isArray: false }
            }
        );
    }])