app.directive('showOnClick', function() {
  return {
    scope: '=',
    link: function(scope, element, attrs){

      var menu;

      function show(event) {

        var menu = $(attrs.showOnClick)
        var rightOffset = 0;

        event.preventDefault();
          
        if(event.pageX + menu.width() >= $(window).width()){
          rightOffset = menu.width();
          menu.addClass('right');
        } else {
          menu.removeClass('right');
        }

        var offsetElement = element.closest('table');

        
        menu.show().css({
          left: event.pageX - offsetElement.offset().left - rightOffset + 7,
          top: event.pageY - offsetElement.offset().top 
        });
          
        }

        var eventType = attrs.clickType == 'right' ? 'contextmenu' : 'click';

        element.on(eventType, show)
        
        $(document).on(eventType, function(event){
          var menu = $(attrs.showOnClick);
          if(!$(event.target).closest(element).length && !$(event.target).closest(menu).length) {
            menu.hide()
          }        
        });

    }
  }

  
})

