app.factory( 'validationService', ['$resource',  function($resource){
    return new ValidationService($resource);
}]);

function ValidationService(resourceService) {

    var EMAIL_REGEX = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,20}(?:\.[a-z]{2})?)$/i;
    var PHONE_REGEX = /^(\.|\-|\+|\d|\W){10,20}$/;

    this.getRequiredFieldValidation = function(element){
        var error = null;
        if(!element){
            error = 'required';
        }
        return error;
    }


    this.getEqualFieldsValidation = function(element1, element2, fieldName){
        var error = null;
        if(element1 != element2){
            error = fieldName + ' must match';
        }
        return error;
    }

    this.getIdFieldValidation = function(element){
        var error = null;
        if(!element.id || element.id == -1){
            error = ' required';
        }
        return error;
    }

    this.getNumberFieldValidation = function(element){
        var error = null;
        if(!isFinite(element)){
            error = ' invalid';
        }
        return error;
    }

    this.getPhoneFieldValidation = function(element){
        var error = null;
        if(element && !PHONE_REGEX.test(element)){
            error = 'invalid phone number';
        }
        return error;
    }

    this.getEmailAddressValidation = function(element){
        var error = null;
        if(element && !EMAIL_REGEX.test(element)){
            error = 'invalid email address';
        }
        return error;
    }



    //Async Validation

    var USER_EXIST_URL = users_api_hostname + '/users/check-email';

    var EMAIL_TAKEN_ERROR_MESSAGE = 'email already taken';

    this.isEmailExisting = function(email, errorField, callback){

        var url = USER_EXIST_URL;
        var urlResource = resourceService(url);

        urlResource.get({ email: email})
            .$promise.then(function (response) {

                callback(response, errorField, EMAIL_TAKEN_ERROR_MESSAGE);
            }).catch(function error(msg) {

            });
    }

}