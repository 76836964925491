app.directive('metricsGraphicsWrapper', function() {
    return{
        restrict: 'E',
        template: '<metrics ng-if="mData" data="mData" options="mOptions" id="{{id}}"></metrics>',
        link: function(scope,element,attrs){
          scope.id = attrs.id;
          scope.mData = scope.$eval(attrs.data);
          scope.mData[0].value = 5;
          scope.mOptions = scope.$eval(attrs.options);
        }
    }
});