app.directive('selectModal', ['$rootScope', 'PublishHelperService', function($rootScope, PublishHelperService) {
    return {
      restrict: 'E',
      templateUrl: 'partials/templates/publish/select-modal.html',
      scope: { },
      link: function(scope, element, attrs) {

        $rootScope.$on('showModalInst', function(event, data) { 
          scope.showModal = true;
          scope.institution = data.institution;
          scope.content = data.content;
        });

        scope.confirm = function() {
          scope.selectedOption.action();
          scope.selectedOption = null;
          scope.showModal = false;
        };

        scope.cancel = function() {
            scope.selectedOption = null;
            scope.showModal = false;
        };

        scope.isConfirmEnabled = function() {
          return scope.selectedOption && scope.selectedOption.text != '';
        }

      }
    }
  }
]);
