app.factory( 'collectionUtils', [
    function(){
        return new CollectionUtils();
    }]);

function CollectionUtils() {

    this.getAllIdsFromList = function(list){
        var ids = [];
        for(var i in list) {
            ids.push(list[i].id);
        }
        return ids;
    }

    this.getByIdFromList = function(list, id){
        for(var i in list) {
            if(list[i].id === id){
                return list[i];
            }
        }
        return null;
    }

    this.mergeLists = function(listA, listB){
        var mergedList = [].concat(listA);
        for(var i = 0; i < listB.length; i++) {
            if (listA.indexOf(listB[i]) == -1) {
                mergedList.push(listB[i]);
            }
        }
        return mergedList;
    }


    this.mergeListOfLists = function(listOfLists){
        var mergedList = [];
        for(var a = 0; a < listOfLists.length; a++) {
            var currentList = listOfLists[a];

            for(var b = 0; b < currentList.length; b++) {
                if (mergedList.indexOf(currentList[b]) == -1) {
                    mergedList.push(currentList[b]);
                }
            }
        }
        return mergedList;
    }

    this.removeElementFromList = function(element, list){
        if(element && list){
            var index = list.indexOf(element);
            if (index > -1) {
                list.splice(index, 1);
            }
        }
    }

    this.convertToStrings = function(list){
        var result = [];

        if(list){
            for(var i in list){
                result.push(""+list[i]);
            }
        }

        return result;
    }

    this.listIsEmpty = function(list){
        if(list && list.length > 0){
            return false;
        }
        return true;
    }
}