app.factory('ContentTemplateService', ['$resource', function ($resource) {
  return $resource(hostname + '/echo-api/api/templates/:templateId/:languageId',
    null,
    {
      'createSurveyFromTemplate': {
        url: hostname + '/echo-api/api/survey/content/copy',
        method: 'POST',
        isArray: false
      },
      'publishTemplate': {
        url: hostname + '/echo-api/api/publish/templates',
        method: 'POST',
        isArray: false
      },
      'unpublishTemplate': {
        url: hostname + '/echo-api/api/publish/unpublish/templates',
        method: 'POST',
        isArray: false
      },
      'getInstHierarchy': {
        url: hostname + '/echo-api/api/publish/institutions/hierarchy',
        method: 'GET',
        isArray: true
      },
      'deletePublishedInstitution': {
        url: hostname + '/echo-api/api/publish/templates/:templateId/institutions/:institutionId',
        method: 'DELETE',
        isArray: false
      },
      'getTemplatePublishedInstitutions': {
        url: hostname + '/echo-api/api/publish/templates/:templateId',
        method: 'GET',
        isArray: true
      },
      'getCertifiedContent': {
        url: hostname + '/echo-api/api/templates/browse/certified',
        method: 'GET',
        isArray: true
      },
      'saveDistributionSelections': {
        url: hostname + '/echo-api/api/surveys/sharing/saveChangeDelta',
        method: 'POST',
        isArray: false
      },
      'deleteTemplate': {
        url: hostname + '/echo-api/api/templates/:templateId',
        method: 'DELETE',
        isArray: false
      },
      'getTemplatePublishInfo': {
        url: hostname + '/echo-api/api/publish/info/templates/:templateId/institutions/:institutionId',
        method: 'GET',
        isArray: false
      },
      'getAdminDistributionInfo': {
        url: hostname + '/echo-api/api/surveys/:surveyId/sharing/owner-institution/:ownerInstitutionId?populateParentSharingInfo=false',
        method: 'GET',
        isArray: false
      },
      'getAdminSharingInfo': {
        url: hostname + '/echo-api/api/surveys/:surveyId/sharing/owner-institution/:surveyInstitutionId?populateParentSharingInfo=:parentHierarchy',
        params:'@parentHierarchy',  
        method: 'GET',
        isArray: false
      },
      'alterPermission': {
        url: hostname + '/echo-api/api/surveys/sharing/alterPermission',
        method: 'POST',
        isArray: false
      },
      'getParentSharingInfo':{
        url: hostname + '/echo-api/api/surveys/sharing/pagedSharingInfo/:surveyId/:parentInstitutionId/:fromIndex/:pageSize',
        method: 'GET',
        isArray: true
      },
      'checkCustomerSesConfiguration': {
        url: hostname + '/echo-api/api/config/institutions/:institutionId/validate-ses-configuration',
        method: 'GET',
        isArray: false
      },
      'getNonAdminDistributionInfo': {
        url: hostname + '/echo-api/api/publish/templates/:templateId/owner-institution/:ownerInstitutionId',
        method: 'GET',
        isArray: false
      },
      'getNonAdminAdEdDistributionInfo': {
        url: hostname + '/echo-api/api/publish/templates/:templateId/advanc-ed/child-institutions',
        method: 'GET',
        isArray: false
      },
      'getAdEdChildInstitutions': {
        url: hostname + '/echo-api/api/publish/templates/:templateId/institutions/:institutionId/child-institutions',
        method: 'GET',
        isArray: true
      },
      'getTemplateChildInstitutions': {
        url: hostname + '/echo-api/api/publish/childInstitutions/:institutionId',
        method: 'GET',
        isArray: true
      },
      'getSurveyChildInstitutions': {
        url: hostname + '/echo-api/api/surveys/:surveyId/sharing/:parentInstitutionId/child-institutions',
        method: 'GET',
        isArray: true
      },
      'validateTemplateForPublish': {
        url: hostname + '/echo-api/api/publish/validate/templates/:templateId',
        method: 'GET',
        isArray: false
      },
      'createCertifiedTemplate': {
        url: hostname + '/echo-api/api/templates/certified',
        method: 'POST',
        isArray: false
      },
      'createCertifiedTemplateWithAdministrationOption': {
        url: hostname + '/echo-api/api/templates/certified?administration=true',
        method: 'POST',
        isArray: false
      },
      'createCustomTemplate': {
        url: hostname + '/echo-api/api/templates/custom',
        method: 'POST',
        isArray: false
      },
      'getAllSupportedLanguages': {
        url: hostname + '/echo-api/api/config/languages',
        method: 'GET',
        isArray: true
      },
      'getTemplateSupportedLanguages': {
        url: hostname + '/echo-api/api/config/templates/:templateId/languages',
        method: 'GET',
        isArray: true
      },
      'saveTemplateLanguage': {
        url: hostname + '/echo-api/api/config/templates/languages',
        method: 'POST',
        isArray: false
      },
      'deleteTemplateLanguage': {
        url: hostname + '/echo-api/api/config/templates/languages',
        method: 'DELETE',
        isArray: false,
        hasBody: true
      },
      'getAllTargetGroups': {
        url: hostname + '/echo-api/api/config/targetGroups',
        method: 'GET',
        isArray: true
      },
      'getTemplateTargetGroup': {
        url: hostname + '/echo-api/api/config/templates/:templateId/targetGroups',
        method: 'GET',
        isArray: false
      },
      'updateTemplateTargetGroup': {
        url: hostname + '/echo-api/api/config/templates/targetGroups',
        method: 'PUT',
        isArray: false
      },
      'updateTemplateName': {
        url: hostname + '/echo-api/api/templates/name',
        method: 'PUT',
        isArray: false
      },
      'updateTemplateDisplayName': {
        url: hostname + '/echo-api/api/templates/displayName',
        method: 'PUT',
        isArray: false
      },
      'updateTemplateDescription': {
        url: hostname + '/echo-api/api/templates/description',
        method: 'PUT',
        isArray: false
      },
      'updateTemplateEndAction': {
        url: hostname + '/echo-api/api/config/templates/endAction',
        method: 'PUT',
        isArray: false
      },
      'updateTemplateRedirectLink': {
        url: hostname + '/echo-api/api/config/templates/redirectLink',
        method: 'PUT',
        isArray: false
      },
      'updateTemplateShowTitlesFlag': {
        url: hostname + '/echo-api/api/config/templates/showTitles',
        method: 'PUT',
        isArray: false
      },
      'updateTemplateHideScoreFlag': {
        url: hostname + '/echo-api/api/config/templates/hideScore',
        method: 'PUT',
        isArray: false
      },
      'updateTemplateAccreditationStatus': {
            url: hostname + '/echo-api/api/config/templates/accreditation',
            method: 'PUT',
            isArray: false
        },
      'copyTemplate': {
        url: hostname + '/echo-api/api/templates/copy',
        method: 'POST',
        isArray: false
      },
      'updateTemplateElement': {
        url: hostname + '/echo-api/api/templates/update',
        method: 'POST',
        isArray: false
      },
      'updateOpenCloseDates': {
        url: hostname + '/echo-api/api/config/templates/administration-dates',
        method: 'PUT',
        isArray: false
      },
      'updateAdminInstitutionOwner': {
        url: hostname + '/echo-api/api/config/templates/:templateId/institution-owner',
        params: {
          templateId: '@templateId'
        },
        method: 'PUT',
        isArray: false
      },
      'releasePDF': {
        url: hostname + '/echo-api/api/ses/reports/release',
        params: {
          administrationSurveyId: '@administrationSurveyId'
        },
        method: 'POST',
        isArray: false
      },
      'withdrawPDF': {
        url: hostname + '/echo-api/api/ses/reports/withdraw',
        params: {
          administrationSurveyId: '@administrationSurveyId'
        },
        method: 'POST',
        isArray: false
      }
    }
  );
}]);
