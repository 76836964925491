app.directive(
    'leftSideMenu',
    [
        '$rootScope',
        '$location',
        'applicationService',
        'userService',
    function(
        $rootScope,
        $location,
        applicationService,
        userService
    ) {
    return {
        link: "",
        scope: true,
        templateUrl: 'partials/leftMenu.html',
        link: function(scope,element,attrs){
            // eslint-disable-next-line no-undef
            scope.privacyPolicyUrl = privacy_policy_url;
            scope.date = new Date();
            scope.shouldRenderLeftMenu = false;
            scope.shouldRenderRightMenu = false;
            const surveyTakingRegex = /^\/action\/.*$/;
            if(surveyTakingRegex.test($location.$$path)){
                // Exit out early if hitting a survey taking endpoint
                return;
            } else {
                scope.shouldRenderLeftMenu = true;
                scope.shouldRenderRightMenu = true;
            }

            scope.userActionRoles = {};  // TODO: Roles Permissions - userActionRoles may be a $rootScope reference. Refactor into a service instead.
            var hidden = true;

            var getBuildVersionSuccess = function(buildVersion){ scope.build = buildVersion; }

            var getBuildVersionFail = function(error){}

            applicationService.getBuildVersion(getBuildVersionSuccess, getBuildVersionFail);

            $rootScope.$watch('headerAction', function(status) {
              if( status ) {
                updateScopeVariables(status);
              }
            });

            scope.$on('$routeChangeStart', function(event, next, current) { 
              updateScopeVariables($rootScope.headerAction);
            });

            scope.changePath = function(path) {
                $('.off-canvas-wrap').foundation('offcanvas', 'hide', 'move-right');
                $location.path(path);
            }

            scope.goToMyJourney = function() {
                console.log('Go to MyJourney...');
                try {
                    window.location = my_journey_url_login + "/select-institution/" + applicationService.getInstitution().id;
                }
                catch (error) {
                    window.location = my_journey_url_login + "/select-institution/";
                }
            }

            scope.$watch(
                function() { return applicationService.getInstitution(); },
                function(newval, oldval) {
                    //if institution name changes then rebind the institution AND userFullName scoped values
                    if (hidden === false) {
                        scope.institution = newval;
                        scope.userFullName = userService.getUserFullName();
                    }
                }
            );


            function updateScopeVariables(status) {
              if (status === 'hidden') {
                  hidden = true;
              } else if (status === 'active') {
                  hidden = false;
                  scope.userFullName = userService.getUserFullName();
                  scope.institution = applicationService.getInstitution();
                  scope.showCreate = false;
                  scope.showManageUsers = false;
                  scope.showManageLicenses = false;
                  scope.createReport = false;
                  var userAssociation = userService.getUserCurrentAssociation();

                  scope.onManageCertifiedContentPage = $location.url().indexOf('manage-content') !== -1;

                  // var user = userService.getUser();
                  scope.userActionRoles = {  // TODO: Roles Permissions - roles are being handled through $rootScope. This should be handled in services instead.
                    admin: userService.userIsAdmin(),
                    certified: userService.userIsCertifiedCreator(),
                    superUser: userService.userIsSuperUser()
                  };

                  //var adminSuper = scope.userActionRoles.admin || scope.userActionRoles.superUser; // TODO: Roles Permissions - roles are being handled through $rootScope. This should be handled in services instead.
                  scope.showSurveyReportContentLinks = !scope.onManageCertifiedContentPage;

                  if ( scope.userActionRoles.certified && !scope.userActionRoles.superUser ) { // TODO: Roles Permissions - roles are being handled through $rootScope. This should be handled in services instead.
                    scope.linkText = '';
                    scope.hideLinkAddress = true;
                  } else if ( scope.onManageCertifiedContentPage || !scope.institution) {
                    scope.linkText = 'Back to Institution Selector'; 
                    scope.hideLinkAddress = true;
                  } else {
                    scope.linkText = angular.copy(scope.institution.name); 
                    scope.hideLinkAddress = false;
                  }

                  if(userAssociation && (_.where(userAssociation.permissions,{"permissionName" : "create_survey"})).length > 0) { // TODO: Roles Permissions - permissions checked in directive for create_survey, show_userinfo, show_licenseinfo, and create_report directly.
                      scope.showCreate = true;
                  }
                    if(userAssociation && (_.where(userAssociation.permissions,{"permissionName" : "show_userinfo"})).length > 0) {
                      scope.showManageUsers = true;
                  }
                  if(userAssociation && (_.where(userAssociation.permissions,{"permissionName" : "show_licenseinfo"})).length > 0) {
                      scope.showManageLicenses = true;
                  }
                  if(userAssociation && (_.where(userAssociation.permissions,{"permissionName" : "create_report"})).length > 0) {
                      scope.createReport = true;
                  }
              }
            }
        }
    }
}]);
