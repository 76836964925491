app.factory('applicationService', ['$resource', '$location', '$timeout', '$sessionStorage', '$injector',
    function ($resource, $location, $timeout, $sessionStorage, $injector) {
        return new ApplicationService($resource, $location, $timeout, $sessionStorage, $injector);
}]);

function ApplicationService(resourceService, locationService, $timeout, sessionStorage, $injector) {
    var USER_HOME_URL = '/';
    var SIGNIN_URL = '/signin';
    var SIGNIN_SELECT_INSTITUTION_URL = '/signin/select-institution';

    var SIGNUP_INFO_URL = '/signup/information';
    var SIGNUP_SELECT_INSTITUTION_URL = '/signup/select-institution';
    var SIGNUP_CONFIRM_URL = '/signup/confirm';
    var PURCHASE_URL = '/purchase';

    var ADD_INSTITUTION_URL = '/add-institution';

    var APPLICATION_ID = 2;
    var sesReportDownloadPending = false;


    this.getApplicationId = function() {
        return APPLICATION_ID;
    }
    this.getApplicationName = function() {
        return application_name;
    }

    this.setSesReportDownloadStatus = function(isPending) {
        this.sesReportDownloadPending = isPending;
    }
    this.getSesReportDownloadStatus = function() {
        return this.sesReportDownloadPending;
    }

    this.checkAuthWithRedirect = function() {
        // We use $injector here to get the userService after angular bootsraps the project to avoid creating a circular
        // dependency at runtime.
      var userService = $injector.get('userService');
      var user = userService.getUser(true); // Pass true to skip redirect in userService.getUser if user is null/undefined.
      if (!user) {
        this.setOriginAsCurrent();
        console.log("We have just set origin as current", this.getOriginalPath());
        this.goToSigninPage();
      }
    }

    this.setRequestId = function(requestId) {
      sessionStorage.requestId = requestId;
    }

    this.getRequestId = function() {
      return sessionStorage.requestId;
    }

    this.setReportId = function(reportId) {
        sessionStorage.reportId = reportId;
    }

    this.getReportId = function() {
        return sessionStorage.reportId;
    }

    this.getInstitution = function() {
        return sessionStorage.institution;
    }

    this.setInstitution = function(institution) {
        sessionStorage.institution = institution;
    }

    this.setCustomer = function(customer) {

        var institution = {
            'id': customer.id,
            'name': customer.name,
            'type': customer.customerType,
            'customerNumber': customer.customerNumber,
            'locationAddress': getAddress(customer, 'location'),
            'billingAddress': getAddress(customer, 'billing')
        };

        sessionStorage.institution = institution;
    }

    var getAddress = function(customer, type){
        var address = null;

        if(customer.addressDTO) {
            customer.addressDTO.forEach(
                function(addressElement){
                    if(addressElement.addressType == type){
                        address =  addressElement;
                    }
                }
            );
        }

        if(address){
            address = convertAddress(address);
        }

        return address;
    }

    var convertAddress = function(addressDTO){
        var address = {};

        address.address1 = addressDTO.address1;
        address.address2 = addressDTO.address2;
        address.address3 = addressDTO.address3;
        address.city = addressDTO.city;
        address.state = addressDTO.stateDTO;
        address.country = addressDTO.countryDTO;
        address.postalCode = addressDTO.postalCode;

        return address;
    }


    this.getInstitutionType = function() {
        var institutionType = sessionStorage.institutionType;

        var sessionInstitution = this.getInstitution();
        if(sessionInstitution && sessionInstitution.type){
            institutionType = sessionInstitution.type;
        }

        return institutionType;
    }
    this.setInstitutionType = function(institutionType) {
        sessionStorage.institutionType = institutionType;
    }

    this.getInstitutionId = function() {
        var sessionInstitution = this.getInstitution();
        if(sessionInstitution){
            return sessionInstitution.id;
        }
        return null;
    }

    this.getInstitutionName = function() {
        var sessionInstitution = this.getInstitution();
        if(sessionInstitution){
            return sessionInstitution.name;
        }
        return null;
    }

    this.getNonEssentialDataCache = function(){
        if(!sessionStorage.nonEssentialDataCache){
            sessionStorage.nonEssentialDataCache = {};
        }
        return sessionStorage.nonEssentialDataCache;
    }
    this.emptyNonEssentialDataCache = function(){

        if(sessionStorage.nonEssentialDataCache){
            sessionStorage.nonEssentialDataCache = {};
        }
    }


    this.setOriginAsCurrent = function(){
        this.setOriginalPath(locationService.$$path);
    }
    this.setOriginalPath = function(originalPath){
        sessionStorage.originalPath = originalPath;
    }

    this.getOriginalPath = function(){
        var originalPath = sessionStorage.originalPath;

        return originalPath;
    }

    this.setOriginCallback = function(originCallback){
        sessionStorage.originCallback = originCallback;
    }
    this.getOriginCallback = function(){
        return sessionStorage.originCallback;
    }

    this.resetShowParentSelection = function() {
        sessionStorage.showParentSelection = true;
    }

    this.getShowParentSelection = function() {
        return sessionStorage.showParentSelection;
    }

    this.setShowParentSelection = function(flag) {
        sessionStorage.showParentSelection = flag;
    }


    this.getLocationAbsUrl = function(){
        return locationService.$$absUrl;
    }

    this.goToSigninPage = function(){
        var originalPath = this.getOriginalPath();

        if(!originalPath){
            this.setOriginAsCurrent();
        }

        locationService.path(SIGNIN_URL).replace();
    }

    this.goToSigninPageWithRedirect = function(originalUrl) {
      locationService.path(originalUrl).replace();
    }

    this.goToSigninSelectInstitutionPage = function(){
        locationService.path(SIGNIN_SELECT_INSTITUTION_URL).replace();
    }


    this.goToSignupInfoPage = function(){
        locationService.path(SIGNUP_INFO_URL).replace();
    }

    this.goToSignupSelectInstitutionPage = function(){
        locationService.path(SIGNUP_SELECT_INSTITUTION_URL).replace();
    }

    this.goToSignupConfirmPage = function(){
        locationService.path(SIGNUP_CONFIRM_URL).replace();
    }

    this.goToAddInstitution = function(){
        locationService.path(ADD_INSTITUTION_URL).replace();
    };
    this.goToPage = function(url){
        locationService.path(url).replace();
    };
    this.goToOrigin = function(){
        var origin = this.getOriginalPath();
        if(origin){
            locationService.path(origin).replace();
        }
        else {
            this.goToUserHomePage();
        }
    };

    this.goToUserHomePage = function () {
        // We use $injector here to get the userService after angular bootsraps the project to avoid creating a circular
        // dependency at runtime.
        var userService = $injector.get('userService');
        if(userService.getUser()) {
            locationService.path(USER_HOME_URL).replace();
        }
    },

    this.logout = function(){
        sessionStorage.institution = null;
        sessionStorage.accessToken = null;
        // here setting user to null is breaking things in google analytics. So making it undefined.
        sessionStorage.user = 'undefined';
        this.emptyNonEssentialDataCache();
        window.open(my_journey_url_login + '/logout','_self')
    },

    this.resetShowParentSelection(); // TODO: Will - check to see if this should be called on creation

    var APP_BUILD_VERSION_API_URL = '/surveys/v1/unsecured/build-version';

    this.getBuildVersion = function(successCallback, failCallback) {
        var url = APP_BUILD_VERSION_API_URL;
        var urlResource = resourceService(url);
        this.executeAsPromiseWithHandlers(urlResource.get(), successCallback, failCallback);
    }



    var LOGIN_API_URL = '/auth/login';

    this.login = function(email, password, successCallback, failCallback) {
        var url = users_api_hostname + LOGIN_API_URL;
        var urlResource = resourceService(url);
        urlResource.save({
            "email": email,
            "password": password
            }).$promise.then(function (response) {
                
                if (!response.user) {
                    failCallback("Invalid email address/password combination");
                }
                else {
                    handleSuccess(response, successCallback);
                }
            }).catch(function error(error) {
                handleError(error, failCallback);
            });
    }

    this.loginByToken = function(token, applicationId, successCallback, failCallback) {
        var urlResource = resourceService(users_api_hostname + '/auth/loginByToken/:token/:applicationId', {}, {
            loginByToken: {
                method: 'GET',
                isArray: false
            }
        });

        urlResource.loginByToken({token: token, applicationId: applicationId})
            .$promise.then(function (response) {
                console.debug('loginByToken(): check loginByToken response', response);
                if (!response.user) {
                    failCallback("Authorization Token is not valid.");
                }
                else {
                    handleSuccess(response, successCallback);
                }
            }).catch(function error(error) {
                console.error("loginByToken(): Error message", error);
                handleError(error, failCallback);
            });
    }

    var GET_COUNTRIES_API_BASE_URL = customer_api_hostname +'/countries';

    this.getCountries = function(){

        var url = GET_COUNTRIES_API_BASE_URL;
        var urlResource = resourceService(url);

        return urlResource.query({})
    }


    this.getCountryByCode = function(countries, code){

        if(countries){
            for(var index in countries){
                if(countries[index].code == code){
                    return countries[index];
                }
            }
        }
    }

    this.getCountryById = function(countries, id){
        if(countries){
            for(var index in countries){
                if(countries[index].id == id){
                    return countries[index];
                }
            }
        }
    }


    this.getStates = function(id){

        var url = GET_COUNTRIES_API_BASE_URL +  '/' + id + '/states';
        var urlResource = resourceService(url);

        return urlResource.query({})
    }



    this.getStateById = function(states, id){
        if(states){
            for(var index in states){
                if(states[index].id == id){
                    return states[index];
                }
            }
        }
    }

    this.getInstitutionsResource = function(searchParams){
        var url = customer_api_hostname +'/search/customer';
        var urlResource = resourceService(url);

        return urlResource.query(searchParams);
    }

    this.getInstitutionsForSharing = function(searchParams){
        var url = customer_api_hostname +'/search/sharing';
        var urlResource = resourceService(url, {}, {
            save: {
                method: 'POST',
                isArray: true
            }
        });

        searchParams.customerStatus = 'Active';

        return urlResource.save(searchParams);
    }

    this.getCustomerHierarchyForSharing = function(searchParams){
        var url = customer_api_hostname +'/search/check-hierarchy';
        var urlResource = resourceService(url, {}, {
            save: {
                method: 'POST'
            }
        });
        return urlResource.save(searchParams);
    }

    var SAVE_NEW_CUSTOMER = customer_api_hostname + '/customer';

    this.saveNewCustomer = function(customer,successHandler, errorHandler) {
        var url = SAVE_NEW_CUSTOMER;
        var urlResource = resourceService(url, {}, {
            save: {
                method: 'POST'
            }
        });

        urlResource.save(customer)
            .$promise.then(function (response) {
                
                handleSuccess(response, successHandler);
            }).catch(function error(error) {
                handleError(error, errorHandler);
            });
    }

    const GET_INSTITUTION_AVAILABLE_PARTICIPANTS_URL = 'https://localhost:8081/echo-api/api' + '/participants/available';

    //this.getInstitutionAvailableParticipants = function(institutionId) { // TODO - Will - this method is not used. Remove it.
    //    const url = GET_INSTITUTION_AVAILABLE_PARTICIPANTS_URL;
    //    var urlResource = resourceService(url, {}, {
    //        save: {
    //            method: 'GET',
    //            isArray: false
    //        }
    //    });
    //    return urlResource.save();
    //};

    var FORGOT_PASSWORD_URL =  users_api_hostname + '/auth/forgotPassword';

    this.getResetUrl = function(email, successHandler, errorHandler){
        var url = FORGOT_PASSWORD_URL;

        resourceService(url).save({email: email})
            .$promise.then(function (response) {
                if(response.messageType && response.messageType.toLowerCase() == 'failure'){
                    handleError(response, errorHandler);
                }
                else {
                    handleSuccess(response, successHandler);
                }
            }).catch(function error(error) {
                handleError(error, errorHandler);
            });
    }


    var CHECK_RESET_PASSWORD_LINK_ENDPOINT =  users_api_hostname + '/auth/forgotPasswordExpiry';

    this.checkResetPasswordLink = function(tokenUrl, successHandler, errorHandler){
        var url = CHECK_RESET_PASSWORD_LINK_ENDPOINT;

        resourceService(url).save({url: tokenUrl})
            .$promise.then(function (response) {
                if(response.messageType && response.messageType.toLowerCase() == 'failure'){
                    handleError(response, errorHandler);
                }
                else {
                    handleSuccess(response, successHandler);
                }
            }).catch(function error(error) {
                handleError(error, errorHandler);
            });
    }


    this.updatePassword = function(password, successHandler, errorHandler){
        var userService = $injector.get('userService');
        var userId = userService.getUserId();
        var url = users_api_hostname + '/auth/' +userId+ '/password/';

        var urlResource = resourceService(url, {}, {
            update: {
                method: 'PUT'
            }
        });

        urlResource.update(password)
            .$promise.then(function (response) {
                handleSuccess(response, successHandler);
            }).catch(function error(error) {
                handleError(error, errorHandler);
            });
    }

    this.executeAsPromiseWithHandlers = function(resource, successHandler, errorHandler){
        resource.$promise.then(function (response) {
            handleSuccess(response, successHandler);
        }).catch(function error(error) {
            handleError(error, errorHandler);
        });
    }


    var handleError = function(error, errorHandler){


        if(errorHandler){
            if(error.message){
                errorHandler(error.message);
            }
            else if(error.data){
                errorHandler(error.data.error);
            }
            else if(error.statusText){
                errorHandler(error.statusText);
            }
        }
        else {
            $timeout(errorPopup(error.message), 500);
        }
    }


    this.handleAPIError = function(error, errorHandler){
        handleError(error, errorHandler);
    }

    var handleSuccess = function(response, successHandler){
        

        if(response.status){
            if(response.status === 200){
                successHandler(response.data);
            }
        }
        else if(response.data){
            successHandler(response.data);
        }
        else {
            successHandler(response);
        }
    }

    this.handleAPISuccess = function(response, successHandler){
        handleSuccess(response, successHandler);
    }



    var SEND_EMAIL_ENDPOINT = email_api_hostname + '/email/send';

    this.sendEmail = function(emailData){
        getResourceForSendEmail(emailData)
            .$promise.then(function (response) {
                
                //handleSuccess(response, successHandler);
            }).catch(function error(error) {
                
                //handleError(error, errorHandler);
            });
    }


    var SEND_RESET_PWD_EMAIL_ENDPOINT = email_api_hostname + '/email/resetPassword';

    this.sendResetPasswordEmail = function(emailData, successHandler, errorHandler){
        var urlResource = resourceService(SEND_RESET_PWD_EMAIL_ENDPOINT);
        urlResource.save(emailData)
            .$promise.then(function (response) {
                
                handleSuccess(response, successHandler);
            }).catch(function error(error) {
                handleError(error, errorHandler);
            });
    }


    var getResourceForSendEmail = function(emailData){
        var url = SEND_EMAIL_ENDPOINT;

        var urlResource = resourceService(url);
        return urlResource.save(emailData);
    }

}
