(function() {
        'use strict';
        angular.module('echo')
            .controller('CreateReportsWizardStep1Ctrl', CreateReportsWizardStep1Ctrl);

        CreateReportsWizardStep1Ctrl.$inject = ['$scope', '$modalInstance', '$timeout', 'ReportService'];

        function CreateReportsWizardStep1Ctrl($scope, $modalInstance, $timeout, ReportService){
            $scope.surveyContentTypes = {};
            $scope.reportPreferences.prevStepNum = 1;
            var navigationPreference = {};

            $scope.getSurveyContentTypes = function(){
                var body = angular.copy($scope.surveyRequest);
                body.userId = $scope.userId;
                body.institutionId = $scope.institutionId;
                body.languageId = $scope.languageId;
                ReportService.surveyTypes(body).$promise.then(function(surveyContentTypes) {
                    $scope.surveyContentTypes = surveyContentTypes;
                },function(httpResponse){
                    $timeout(errorPopup, 500);
                });
            }

            $scope.populateSelectedSurveyContentName = function(surveyContentTypeId){
                for (var i in $scope.surveyContentTypes){
                    if ($scope.surveyContentTypes[i].id == surveyContentTypeId)
                    {
                        $scope.reportPreferences.surveyContentTypeName = $scope.surveyContentTypes[i].name; //need the name for display purposes
                        $scope.reportPreferences.surveyContentTypeCertified = $scope.surveyContentTypes[i].contentType === "CERTIFIED_CONTENT";
                        $scope.reportPreferences.networkAverage = $scope.surveyContentTypes[i].networkAverage; //need the network average for the actual report
                        break;
                    }
                }
            }


            $scope.next = function() {
                navigationPreference.skipAhead = false;
                navigationPreference.previous = false;
                $scope.populateSelectedSurveyContentName($scope.reportPreferences.surveyContentTypeId);
                $modalInstance.close(navigationPreference);
            }

            $scope.cancel = function () {
                $modalInstance.dismiss('cancel');
            };

            $scope.getSurveyContentTypes(); //retrieve the applicable survey content types
        }
    }
)();



