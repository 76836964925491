/**
 * Created by pvo on 5/6/15.
 */
/**
 * Created by pvo on 3/26/15.
 */
app.controller('HeaderController', ['$http', '$scope', '$rootScope' ,'$location',  '$localStorage', '$sessionStorage', 'CertifiedContentService', 'userService', function($http, $scope, $rootScope ,$location, $localStorage, $sessionStorage, CertifiedContentService, userService) {
    

    $location.hash() !== '' ? $location.hash('') : angular.noop; // remove hash upon reload

    $scope.mainActionButtonText = "New Certified Content";
    $scope.certifiedContentView = BODY_CONTENT.CERTIFIED_CONTENT_DEFAULT_VIEW;
    $scope.termsAndConditions = TERMS_AND_CONDITIONS_PDF_URL;
    $scope.visibleHeader = true;
    $scope.isNormalUser = function(){ return true; };

    $rootScope.$watch('headerAction', function( status )
    {
        if( status )  // onchange of status in controller 1
        {
            if (status === 'hidden') {
                $scope.visibleHeader = false;
                $rootScope.headerAction = null;     //Reset for next consumption
            } else if (status === 'active') {
                $scope.visibleHeader=true;
            }
        }
    });

    $scope.canCreateNewContent = function() {
        return userService.userIsAdmin() || userService.userIsSuperUser();
    };

    $scope.canCreateReport = function() {
        return userService.userIsAdmin() || userService.userIsSuperUser() || userService.userIsEditor();
    };

    $scope.canCreateSurvey = function() {
        return $scope.showCreate && (userService.userIsAdmin() || userService.userIsSuperUser() || userService.userIsEditor());
    };

    $scope.defaultViewClicked = function() {
        

        if ($scope.certifiedContentView === BODY_CONTENT.CERTIFIED_CONTENT_DEFAULT_VIEW) {
            $scope.certifiedContentView = BODY_CONTENT.CERTIFIED_CONTENT_CUSTOMIZING_DEFAULT_VIEW;

            $scope.mainActionButtonText = "Save Default View";
        }

        $rootScope.headerAction = 'defaultViewClicked';
    };

    $scope.mainActionClicked = function() {
        

        if ($scope.certifiedContentView === BODY_CONTENT.CERTIFIED_CONTENT_CUSTOMIZING_DEFAULT_VIEW) {
            //attempt to save the default view
            $rootScope.headerAction = 'saveDefaultViewClicked';
            $scope.certifiedContentView = BODY_CONTENT.CERTIFIED_CONTENT_DEFAULT_VIEW;
            $scope.mainActionButtonText = "New Certified Content";
        }
        else {
            $rootScope.headerAction = 'mainActionClicked';
        }
    };

    $scope.cancelClicked = function() {
        

        $scope.certifiedContentView = BODY_CONTENT.CERTIFIED_CONTENT_DEFAULT_VIEW;
        $scope.mainActionButtonText = "New Certified Content";

        $rootScope.headerAction = 'cancelClicked';
    }

    $scope.cancelVisibility = function() {
        return $scope.certifiedContentView === BODY_CONTENT.CERTIFIED_CONTENT_CUSTOMIZING_DEFAULT_VIEW;
    }

    $rootScope.clickNewSurvey = function(){
        

        var dropDown = $('.new-survey__list');
        if (! dropDown.hasClass('visible')) {
            dropDown.addClass('visible').css({ left: 'auto', right: 10 });
            CertifiedContentService.published().$promise.then(function(publishedContents) {
                //only show first three results returned
                $scope.publishedCertContents = publishedContents.slice(0,3);
            },function(httpResponse){
                if(httpResponse.status == 404){
                    return false;
                }
            });
        }
        else {
            dropDown.removeClass('visible').css({ left: -9999 });
        }
    }

    $('.top-bar').click(function(e){
        if (e.target.id !== 'New-Survey-Button') {
            $('.new-survey__list').hasClass('visible') ?
                $('.new-survey__list').removeClass('visible').css({left: -9999}) :
                angular.noop;
        }
    });

    // For Safari: hide left-menu every time header loads
    $('.new-survey__list').removeClass('visible').css({left: -9999});

    $rootScope.displaySurveyHelp = function(){
        $('.how-eleot-works').addClass('open');
        $('.how-eleot-works').show();
    }
}]);
