app.directive('dashboardSparkLines', ['$timeout', 'ActivityService', function($timeout, ActivityService) {
    return{
        restrict: 'E',
        templateUrl: 'partials/dashboard/dashboardSparkLines.html',
        scope: {
          activeSurvey: "=",
        },
        link: function(scope,element,attrs){
            scope.snapshot = {};
            scope.id = attrs.id;
            scope.activity_spark_line_options = angular.copy(dashboard_activity_spark_line_options);
            scope.showLastResponseDate = (scope.activeSurvey.numDaysSinceLastResponse >= 7); //criteria for showing date rather than activity
            scope.lastResponseDate = scope.activeSurvey.lastResponseTime;
            var activityLineWidthGradient = [10, 18, 25, 32, 40, 48];

            scope.getActivitySnapshot = function()
            {
              var snapshotSurvey = scope.activeSurvey;
              snapshotSurvey.activity = MG.convert.date(snapshotSurvey.activity, 'date');
              scope.snapshot = snapshotSurvey;
              scope.activity_spark_line_options.width = $(".latest-activity__overview").width();
            }
            //retrieve activity if there have been responses and the last response date is within the last 7 days
            if (!scope.showLastResponseDate && scope.lastResponseDate && !(scope.lastResponseDate == 'null')) scope.getActivitySnapshot();

        }
    }
}]);
