app.directive('openActionsEdit',
    [
      '$location',
      '$sessionStorage',
      '$timeout',
      'SurveyService',
      'reportPreferences',
      'ReportService',
      'ContentTemplateService',
      'SurveyCreateService',
      'modalService',
      'userService',
      function(
        $location,
        $sessionStorage,
        $timeout,
        SurveyService,
        reportPreferences,
        ReportService,
        ContentTemplateService,
        SurveyCreateService,
        modalService,
        userService
      ) {
    return {
      restrict: 'E',
      templateUrl: 'partials/templates/open-actions-edit.html',
      scope: {
        rowContent: '=',
        presentCopyModal: '='
      },
      link: function(scope) {

        scope.certified = contentIsCertified(scope.rowContent);
        scope.onManageCertifiedContentPage = $location.url().indexOf('manage-content') !== -1;
        console.log("scope.onManageCertifiedContentPage = " + scope.onManageCertifiedContentPage);

        scope.editAction = function() {
          $location.url('/edit-template/' + scope.rowContent.contentId);
        };

        scope.hasPiiAccess = function() {
            return userService.userHasPIIAccess(false);
        }

        scope.unpublishTemplatePreCheck = function() {
          swal({
            title: "Unpublish Content",
            text: "Are you sure you would like to unpublish this content?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#44b644",
            confirmButtonText: "Unpublish",
            closeOnConfirm: true
          }, function (confirmation) {
              if (confirmation) { unpublishTemplate(); }
            }
          );
        };

        scope.sesReportReleased = function () {
          return scope.rowContent.sesReportReleased;
        }
        scope.sesReportReadyForRelease = function () {
          return scope.rowContent.sesReportReadyForRelease;
        }
        scope.getReleaseDate = function () {
          return moment(scope.rowContent.sesReportReleaseDate).format("(MM/DD/YY) hh:mm A");
        }

        scope.withdrawPDFAction = function () {
          modalService.presentCustomModal({
            title: '<div><i class="fa fa-exclamation-circle"></i> Withdraw PDF</div>',
            text: '<div>Select "confirm" to withdraw the PDF from each of the institutions associated with this administration.</div>',
            html: true,
            cancelButtonText: 'Confirm',
            confirmButtonText: 'Cancel',
            showCancelButton: true,
            customClass: 'manage-users-confirmation-modal',
            allowEscapeKey: true
          }, function () {
            // Nothing to execute upon cancel
          },
            function () {
              var body = {
                "administrationSurveyId": scope.rowContent.administrationSurveyId,
              };
              ContentTemplateService.withdrawPDF(body).$promise.then(
                function (response) {
                  scope.rowContent.sesReportReleased = response.sesReportReleased;
                  scope.rowContent.sesReportReadyForRelease = response.sesReportReadyForRelease;
                },
                function (error) {
                  sweetAlert("There was an error during PDF withdraw. This is most likely a problem connecting to the server. Please try again later.", error, "error");
                }
              );
            });
        }
        scope.releasePDFAction = function () {
          modalService.presentCustomModal({
            title: '<div><i class="fa fa-unlock-alt"></i> Release PDF</div>',
            text: '<div>Select "confirm" to release the summary PDF to each of the institutions associated with this administration.</div>',
            html: true,
            cancelButtonText: 'Confirm',
            confirmButtonText: 'Cancel',
            showCancelButton: true,
            customClass: 'manage-users-confirmation-modal',
            allowEscapeKey: true
          }, function () {
            // Nothing to execute upon cancel
          },
            function () {
              var body = {
                "administrationSurveyId": scope.rowContent.administrationSurveyId,
              };
              ContentTemplateService.releasePDF(body).$promise.then(
                function (response) {
                  scope.rowContent.sesReportReleased = response.sesReportReleased;
                  scope.rowContent.sesReportReadyForRelease = response.sesReportReadyForRelease;
                  scope.rowContent.sesReportReleaseDate = response.sesReportReleaseDate;
                },
                function (error) {
                  sweetAlert("There was an error releasing PDF. This is most likely a problem connecting to the server. Please try again later.", error, "error");
                }
              );
            });
        }

        scope.isAdminSurvey = function() {
            return scope.rowContent.administrationTemplate;
        }

        scope.previewTemplate = function() {
          ContentTemplateService.getTemplateSupportedLanguages({templateId: scope.rowContent.contentId}).$promise.then(
            function(languages) {
              var backUrl = $location.path().indexOf('manage') !== -1 ? 'manage' : 'browse';
              var multipleLanguages = languages.length > 1; 
              var previewCustomUrl = '/preview-content/' + scope.rowContent.contentId + '/language/' + languages[0].id + '?type=custom&back=' + backUrl;
              var previewCertifiedUrl = '/preview-content/' + scope.rowContent.contentId + '/language/' + languages[0].id + '?type=certified&back=' + backUrl;
              var langSelectUrl = '/select-lang/' + scope.rowContent.contentId + '?back=' + backUrl;
              if ( multipleLanguages ) {
                $location.url(langSelectUrl);
              } else {
                var url = scope.certified ? previewCertifiedUrl : previewCustomUrl; 
                $location.url(url);
              }
            },
            function(error) {
              sweetAlert("There was an error previewing the content. This is most likely a problem connecting to the server. Please try again later.", error, "error");
            }
          );
        };

        scope.createSurvey = function() {
          var body = {
            userId: userService.getUserId(),
            copiedTemplateId: scope.rowContent.contentId,
            isCertified: scope.certified,
            institutionId: $sessionStorage.institution.id
          };
          ContentTemplateService.createSurveyFromTemplate(body).$promise.then(
            function(data) {
              associateSurveyWithUser(data);
            },
            function(error) {
              sweetAlert("There was an error creating the survey. This is most likely a problem connecting to the server. Please try again later.", error, "error");
            }
          );
        };

        scope.canEdit = function() {
            var editableStatus = scope.rowContent.status === 'Draft' || scope.rowContent.status === 'Unpublished' || scope.rowContent.status === 'Published';

            return editableStatus && (scope.onManageCertifiedContentPage || userService.userIsAdmin() || userService.userIsSuperUser())
        };

        scope.canCopy = function() {
            return scope.onManageCertifiedContentPage || userService.userIsAdmin() || userService.userIsSuperUser();
        }

        scope.canCreate = function() {
            return scope.rowContent.status === 'Published' && (scope.onManageCertifiedContentPage || userService.userIsAdmin() || userService.userIsSuperUser());
        }

        scope.canDelete = function() {
            return scope.rowContent.status === 'Draft' && (scope.onManageCertifiedContentPage || userService.userIsAdmin() || userService.userIsSuperUser());
        }

        scope.canUnpublish = function() {
            return scope.rowContent.status === 'Published' && (scope.onManageCertifiedContentPage || userService.userIsAdmin() || userService.userIsSuperUser());
        };

        scope.copyAction = function() {
            const body = {
                userId: userService.getUserId(),
                copiedTemplateId: scope.rowContent.contentId,
                isCertified: scope.certified,
                isAdminTemplate: null,
                copyAsNonAdminTemplate: null,
                isCustom: false,
                institutionId: getInstId(scope.certified)
            };
            const executeCopy = function (copyAsNonAdminTemplate, custom, body) {
                body.copyAsNonAdminTemplate = copyAsNonAdminTemplate;
                body.isCustom = custom;
                ContentTemplateService.copyTemplate(body).$promise.then(
                    function (response) {
                        $location.url('/edit-template/' + response.templateId);
                    },
                    function (error) {
                        sweetAlert("There was an error copying the template. This is most likely a problem connecting to the server. Please try again later.", error.data.errorMessage, "error");
                    }
                );
            };
            if(scope.presentCopyModal) {
                body.isAdminTemplate = scope.rowContent.administrationTemplate;
                modalService.presentCustomModal({
                    title: '<i data-icon="l"></i><span>Select Template</span>',
                    text: '<div class="top-text weighted-text">Will this template be used for an administration and require assignment of participants?</div>' +
                    '<div>Selecting “Yes” will allow you to set up and administer this survey to a specific group of participants.</div>',
                    html: true,
                    confirmButtonText: 'No',
                    showCancelButton: true,
                    cancelButtonText: 'Yes',
                    customClass: 'template-administration-selection-modal',
                    allowEscapeKey: false
                }, function () {
                    executeCopy(true, false, body);
                }, function () {
                    executeCopy(false, false, body);
                });
            } else {
                executeCopy(null, true, body);
            }
        };

        scope.deleteAction = function() {
          var userId = userService.getUserId();
          ContentTemplateService.deleteTemplate({templateId: scope.rowContent.contentId, userId: userId}).$promise.then(
            function(response) {
              location.reload();
            },
            function(error) {
              sweetAlert("There was an error deleting the template. This is most likely a problem connecting to the server. Please try again later.", error.data.errorMessage, "error");
            }
          );
        };


        function unpublishTemplate() {
          var body = {userId: userService.getUserId(), templateId: scope.rowContent.contentId};
          ContentTemplateService.unpublishTemplate(body).$promise.then(
            function(data) {
              scope.rowContent.status = 'Unpublished';
            },
            function(error) {
              sweetAlert("There was an error unpublishing the content. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
            }
          );
        };

        function contentIsCertified (content) {
            return content.contentType === 'certified_content';
        };

        function getInstId(certified) {
          return certified ? null : $sessionStorage.institution.id;
        }

        function associateSurveyWithUser(data) {
          var associationObj = new Object();
          associationObj.objectType = "survey";
          userService.admin(associationObj,'roles',true);
          associationObj.applicationId = "2";
          associationObj.objectInstanceId = data.surveyId;
          associationObj.ownerUserId = userService.getUserId();
          associationObj.customerId = $sessionStorage.institution.id;
          associationObj.userId = userService.getUserId();
          SurveyCreateService.associateSurveyWithUser(associationObj).$promise.then(function (data) {
            if (data.messageType === 'Failure') {
              $timeout(errorPopup('We could not verify this survey to you.', data.message, null), 500);
            } else {
              $location.url('/edit/' + associationObj.objectInstanceId);
            }
          }, function (error) {
            $timeout(errorPopup('We could not verify this survey to you.', error.data.message, null), 500);
          });
        }
      }
    }
  }
]);

