app.factory('DashboardService', [ '$resource', function($resource){
    return $resource(
            hostname +'/echo-api/api/dashboard',
        null,
        {
            'surveys' : {method : 'POST', isArray: true},
            'surveyIdsForInstitution' : {url:  hostname + '/echo-api/api/dashboard/institution-surveys', method : 'GET', params: {userId: '@userId', institutionId: '@institutionId'}, isArray: true}
        }
    );
}
]);
