app.directive('rightSideMenu', ['$location',
    function(location) {
        return {
            restrict: 'E',
            templateUrl: 'partials/rightMenu.html',
            replace: true,
            link: function(scope, elem, attrs){
                scope.path = location.path();
                

                

                scope.$on("$routeChangeSuccess", function (event, current, previous) {
                scope.path = location.path();
                    var somePath = scope.path;
                
                });

                $(document).mousedown(function(event) { 
                    if( $('.joyride-content-wrapper').is(':visible') && $(event.target).hasClass('icon-info') === false ) {
                        $('.icon-info').click();
                        $('.joyride-content-wrapper').hide();
                        $('.joyride-nub').hide();
                    } 
                });
            }
    }
}]);
