
app.controller('TargetGroupCtrl', 
    ['$q',
     '$scope', 
     '$routeParams', 
     'ContentTemplateService', 
     'userService',
  function($q,
           $scope, 
           $routeParams, 
           ContentTemplateService, 
           userService
      ) { 
     
      //get template target group and all target groups for select input 
      var allTarget = ContentTemplateService.getAllTargetGroups().$promise;
      var tempTarget = ContentTemplateService.getTemplateTargetGroup({templateId: $routeParams.templateId}).$promise;
      $q.all([allTarget, tempTarget]).then(function(responses) {
        var allTargetGroups = responses[0];
        var currentTargetGroup = responses[1];
        $scope.targetGroups = allTargetGroups.map(function(group) {
          if (group.targetId === currentTargetGroup.targetId) {
            $scope.selectedTargetGroup = group;
          }
          return group;
        }, currentTargetGroup);
      }).catch(
        function(error) {
          sweetAlert("There was an error getting the template target groups. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
        }
      );

      //save template target group
      $scope.saveTemplateTargetGroup = function() {
        var body = {
          templateId: $routeParams.templateId,
          userId: userService.getUserId(),
          targetGroupId: $scope.selectedTargetGroup.targetId 
        };
        ContentTemplateService.updateTemplateTargetGroup(body).$promise.then(
          function(data) {
            // success handler
          },
          function(error) {
            sweetAlert("There was an error saving the template target group. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
          }
        );
      };
  }
]);
