app.controller('ApplicationController', ['$scope', '$routeParams', 'applicationService',
    function($scope, $routeParams, applicationService) {
        $scope.$root.headerAction = 'hidden';

        var initialize = function(){
            

            if($routeParams.pageType == 'coming-soon'){
                $scope.message = "Coming Soon!";
            }
            else if($routeParams.pageType == 'plans'){
                $scope.message = "Re-direct to eProve Landing - Plans page";
            }
            else if($routeParams.pageType == 'logout'){
                applicationService.logout();
            }
        }

        initialize();
    }

])