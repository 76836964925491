app.controller('DashboardCtrl', 
    ['$scope',
     '$rootScope', 
     '$http',
     '$location', 
     '$anchorScroll', 
     '$sessionStorage', 
     '$timeout', 
     'applicationService', 
     'customerService', 
     'DashboardService',
     'ReminderService', 
     'SurveyService', 
     'userService',
     'reportPreferences',
     '$modal', 
     'ReportService', 
     'participantService',
    function($scope,
             $rootScope, 
             $http, 
             $location, 
             $anchorScroll, 
             $sessionStorage, 
             $timeout, 
             applicationService, 
             customerService, 
             DashboardService, 
             ReminderService, 
             SurveyService, 
             userService, 
             reportPreferences, 
             $modal, 
             ReportService, 
             participantService
        ){

        var topHeaderId = "topHeader";
        var previewPath = '/preview-report';

        //user profile related objects
        $scope.surveyIdToSurveyPermissionMap = null;
        $scope.surveyRequest = null;

        $scope.languageId = english_language_id; //default to English for now; this will change IF/WHEN the app has full multi-language support
        $rootScope.headerAction = 'active'
        $scope.reminders = {};
        $scope.activeSurveys = {};
        $scope.selectedActiveSurvey = null;
        $scope.activeSurvey = {};
        $scope.surveys = null;
        $scope.snapshotSurvey = {};
        $scope.dashboardSurveyTypes = dashboardSurveyTypes;
        $scope.dashboardSurveyStatuses = dashboardSurveyStatuses;
        $scope.selectedSurveyType = null;
        $scope.selectedSurveyStatus = null;
        $scope.surveyAllStatus = surveyAllStatus
        $scope.surveyArchivedStatus = surveyArchivedStatus;
        $scope.surveyClosedStatus = surveyClosedStatus;
        $scope.surveyClosingSoonStatus = surveyClosingSoonStatus;
        $scope.surveyDraftStatus = surveyDraftStatus;
        $scope.surveyOpenStatus = surveyOpenStatus;
        $scope.surveyAllType = surveyAllType;
        $scope.predicate = 'createdOn';
        $scope.reverse = true;
        $scope.overallScoreOptions = null;
        $scope.scoreData = null;
        $scope.dashboard_loading = true;
        var orgSurveys = {};
        $scope.showCreate = userService.checkUserCurrentAssociationForPermission('create_survey');
        $scope.institutionInfo =  applicationService.getInstitution();
        $scope.institutionsHierarchy = null;

        //retrieve and load the dashboard data
        $scope.retrieveDashboardData = function(){
            var institutionsHierarchySuccessCallback = function (schoolIds, schools) {
                $scope.surveyRequest.userId = $scope.userId;
                $scope.surveyRequest.institutionId = $scope.institutionId;
                $scope.surveyRequest.languageId = $scope.languageId;
                $scope.surveyRequest.institutionHierarchy = null;
                $scope.institutionsHierarchy = schoolIds;

                // $scope.getActivity();
                $scope.getSurveys(dashboardSurveyTypes[0], dashboardSurveyStatuses[0]);
            }

            $scope.getReminders();
            SurveyService.geInstitutionsByHierarchy($scope.institutionInfo, institutionsHierarchySuccessCallback);
        }

        //survey reminders retrieval
        $scope.getReminders = function()
        {
            var body = $scope.surveyRequest;
            body.userId = $scope.userId;
            body.institutionId = $scope.institutionId;
            body.languageId = $scope.languageId;
            ReminderService.reminder(body).$promise.then(function(reminders) {
                $scope.reminders = reminders;
            },function(httpResponse){
                var msg = "There was an error retrieving reminders.";
                $scope.dashboard_loading = false;
                $timeout(sweetAlert("The system encountered a problem, please try again.", msg ), 500);
            });
        };


        $scope.$on('showChangeRosterModal', function(event, survey) {
            $scope.changeRosterSurvey = survey;
            $scope.showChangeRosterModal = true;
        });

        $scope.closeReminders = function()
        {
            $scope.reminders.splice(0,1);
        }

        //scroll down to surveys
        $scope.navigateToSurveys = function(elementId, status){
            $scope.closeReminders();

            $scope.selectedSurveyStatus = status;
            $scope.getSurveys($scope.selectedSurveyType, $scope.selectedSurveyStatus);

            //reset to old to keep any additional routing logic from kicking in
            var old = $location.hash();
            $location.hash(elementId);
            $anchorScroll();
            $location.hash(old);
            $('.how-eleot-works').hide();
            document.getElementById(topHeaderId).scrollIntoView();
        }

        //activity snapshot retrieval
        // $scope.getActivity = function()
        // {
        //
        //
        //     var surveyRequestDTO = angular.copy($scope.surveyRequest);
        //     surveyRequestDTO.closingCount = 4;
        //     surveyRequestDTO.count = 8;
        //
        //     ActivityService.activity(surveyRequestDTO).$promise.then(function(data) {
        //         var activeSurveys = data.surveyDashboards;
        //         $scope.activeSurveys = activeSurveys;
        //         if (activeSurveys != undefined && activeSurveys.length>0)
        //         {
        //             $scope.selectedActiveSurvey = activeSurveys[0].surveyId;
        //             $scope.getActivityDetails($scope.selectedActiveSurvey); //retrieve the details for the first survey
        //         }
        //     }, function(httpResponse){
        //         var msg = "There was an error retrieving your activity snapshot.";
        //         $scope.dashboard_loading = false;
        //         $timeout(sweetAlert("The system encountered a problem, please try again.", msg ), 500);
        //     });
        //
        // };

        //activity details retrieval
        // $scope.getActivityDetails = function(sId)
        // {
        //
        //
        //     var surveyRequestDTO = angular.copy($scope.surveyRequest);
        //     surveyRequestDTO.surveyId = sId;
        //
        //     ActivityService.activityDetail(surveyRequestDTO).$promise.then(function(data) {
        //         var activeSurvey = data.surveyDashboard;
        //
        //         $scope.activeSurvey = activeSurvey;
        //         if (activeSurvey != null) {
        //             $scope.selectedActiveSurvey = activeSurvey.surveyId;
        //             activeSurvey.overallScore = $filter('number')(activeSurvey.overallScore, 2);
        //             if (activeSurvey.certificationId > 0 && activeSurvey.networkAverage != null)
        //             {
        //                 $scope.overallScoreOptions = angular.copy(dashboard_activity_big_chart_options);
        //                 $scope.scoreData = [
        //                     {'overallScore':activeSurvey.overallScore, 'value': activeSurvey.scale,'networkAverage':activeSurvey.networkAverage}
        //                 ];
        //             }
        //             else
        //             {
        //                 $scope.overallScoreOptions = angular.copy(dashboard_activity_big_chart_options2);
        //                 $scope.scoreData = [
        //                     {'overallScore':activeSurvey.overallScore, 'value': activeSurvey.scale}
        //                 ];
        //             }
        //         }
        //         $scope.activityLoading = false;
        //
        //         $('#activityDetailsLoader').addClass('ng-hide');
        //
        //
        //     },function(httpResponse){
        //         var msg = "There was an error retrieving your activity details.";
        //
        //         $timeout(sweetAlert("The system encountered a problem, please try again.", msg ), 500).then(function(){
        //             $('#activityDetailsLoader').addClass('ng-hide');
        //             $scope.activityLoading = false;
        //             $('#sectionScoresError').removeClass('ng-hide');
        //
        //         });
        //
        //     });
        //
        // };

        //survey retrieval
        $scope.getSurveys = function(type, status,reset)
        {
            $scope.searchToken = "";

            if (typeof reset != "undefined" && reset == false){
                $scope.surveys = angular.copy(orgSurveys);

            }else {
                var surveyRequestDTO = angular.copy($scope.surveyRequest);
                surveyRequestDTO.type = type;
                surveyRequestDTO.status = status;

                //Why this below?
                //$scope.surveys.institutionsByHierarchy = $scope.institutionsByHierarchy;
                DashboardService.surveys(surveyRequestDTO).$promise.then(function (surveys) {
                    $scope.assignSurveysProfileInfo(surveys);
                    $scope.surveys = surveys;

                    $scope.fetchSurveysIdsWithEditPermissions(surveys);
                    $scope.selectedSurveyType = type;
                    $scope.selectedSurveyStatus = status;
                    $scope.dashboard_loading = false;

                    for(var i=0; i<$scope.surveys.length; i++){
                        var date = new Date($scope.surveys[i].createdOn);
                        $scope.surveys[i].createdOnSearch = date.toDateString();
                    }
                    $scope.surveyRetrieval_success = true;

                }, function (httpResponse) {
                    var msg = "There was an error retrieving your surveys.";
                    $scope.dashboard_loading = false;
                    $scope.surveyRetrieval_success = false;
                    $timeout(sweetAlert("The system encountered a problem, please try again.", msg), 500);
                });

            }

        };

        $scope.convertTimestampToDate = function(timestamp){
            var amPm = ' am';
            timestamp = parseInt(timestamp);
            var d = new Date(timestamp);
            var month = d.getUTCMonth();
            var day = d.getDate();
            var year = d.getFullYear();
            var hours = d.getHours();
            if(hours > 12){
                hours = hours - 12;
                amPm = ' pm';
            }
            var minutes = d.getUTCMinutes();
            minutes = minutes < 10 ? "0" + minutes : minutes;
    
    
            return (month +1) + '/' + day + '/' + year + ' ' + hours + ':' + minutes + amPm;
        };
        $scope.fetchSurveysIdsWithEditPermissions = function(){
            var surveys = _.filter($scope.surveyIdToSurveyPermissionMap, function (obj) { return obj.surveyAvailabilityEditorId != null; });
            var surveyIds = surveys.map(function (survey) {
                return survey.objectId;
            });
            $scope.surveyIdsWithSharingPermissions = surveyIds;
            $scope.surveys.map(function(survey){
                if($scope.surveyIdsWithSharingPermissions.indexOf(survey.surveyId) > -1){
                    survey.hasSharingPermissions = true;
                }else{
                    survey.hasSharingPermissions = false;
                }
            });
        }
        //Helper method to parse through list of user profile surveys and derive permissions for the Dashboard activity menu
        $scope.assignSurveysProfileInfo = function(surveys)
        {
            if (surveys)
            {
                var surveySize = surveys.length;
                for (var ind = 0; ind < surveySize; ind++)
                {
                    var survey = surveys[ind];
                    var surveyWithPermission = $scope.surveyIdToSurveyPermissionMap[survey.surveyId];
                    survey.permissions = surveyWithPermission;  // TODO: Roles Permissions - permissions manually set on survey object (possibly ok)
                    //$scope.setSurveyOwnerDisplayName(survey);
                }
                $scope.setSurveyOwnerInformation(0, surveys);
                $sessionStorage.customerIdTocustomerMap = $scope.customerIdTocustomerMap;
            }
        }

        $scope.setSurveyOwnerInformation = function(ind, surveys)
        {
            if (ind < surveys.length)
            {
                var survey = surveys[ind];
                ++ind;
                if (survey.createdUserId == $scope.userId) //if logged in user IS the survey owner/creator
                {
                    var customerName = {};
                    survey.createdUserDisplayName =  "Me";
                    if (!$scope.customerIdTocustomerMap) $scope.customerIdTocustomerMap = {}; //convenience Map to avoid making repeat calls for the same customerID
                    if (typeof $scope.customerIdTocustomerMap[survey.institutionId] != "undefined"){
                        customerName = $scope.customerIdTocustomerMap[survey.institutionId];
                    }else{
                        customerName = null;
                    }
                    if (customerName != null)
                    {
                        survey.customerName = customerName.name;
                        $scope.setSurveyOwnerInformation(ind, surveys);
                    }else {
                        customerService.getCustomerByIdResource(survey.institutionId).$promise.then(function(customer){
                            customerName = customer;
                            $scope.customerIdTocustomerMap[survey.institutionId] = customerName;
                            survey.customerName = customerName.name;
                            $scope.setSurveyOwnerInformation(ind, surveys);
                        }, function (httpResponse) {
                            $scope.setSurveyOwnerInformation(ind, surveys);
                        })
                    }
                }
                else //fetch the owner's details
                {
                    var ownerUser = {};
                    var customerName = {};
                    if (!$scope.userIdToUserMap) $scope.userIdToUserMap = {}; //convenience Map to avoid making repeat calls for the same ownerUserID
                    if (!$scope.customerIdTocustomerMap) $scope.customerIdTocustomerMap = {}; //convenience Map to avoid making repeat calls for the same customerID
                    if (typeof $scope.userIdToUserMap[survey.createdUserId] != "undefined"){
                        ownerUser = $scope.userIdToUserMap[survey.createdUserId];
                    }else{
                        ownerUser = null;
                    }

                    if (ownerUser != null)
                    {
                        survey.createdUserDisplayName = ownerUser.firstName + " " + ownerUser.lastName;
                        if (typeof $scope.customerIdTocustomerMap[survey.institutionId] != "undefined"){
                            ownerUser.customerName = $scope.customerIdTocustomerMap[survey.institutionId].name;
                            survey.customerName = ownerUser.customerName ? ownerUser.customerName : "";
                            $scope.setSurveyOwnerInformation(ind, surveys);
                        }else {
                            customerService.getCustomerByIdResource(survey.institutionId).$promise.then(function (customer) {
                                customerName = customer;
                                $scope.customerIdTocustomerMap[survey.institutionId] = customerName;
                                ownerUser.customerName = customerName.name;
                                survey.customerName = customerName.name;
                                $scope.setSurveyOwnerInformation(ind, surveys);
                            }, function (httpResponse) {
                                $scope.setSurveyOwnerInformation(ind, surveys);
                            })
                        }
                    }
                    else
                    {
                        userService.getUserById(survey.createdUserId).$promise.then(function(user){
                            ownerUser = user;
                            $scope.userIdToUserMap[survey.createdUserId] = ownerUser;
                            survey.createdUserDisplayName = ownerUser ? ownerUser.firstName + " " + ownerUser.lastName : "";
                            if (typeof $scope.customerIdTocustomerMap[survey.institutionId] != "undefined"){
                                customerName = $scope.customerIdTocustomerMap[survey.institutionId];
                            }else{
                                customerName = null;
                            }

                            if (customerName != null){
                                ownerUser.customerName = customerName.name;
                                survey.customerName = customerName.name;
                                $scope.setSurveyOwnerInformation(ind, surveys);
                            }else {
                                customerService.getCustomerByIdResource(survey.institutionId).$promise.then(function (customer) {
                                    customerName = customer;
                                    $scope.customerIdTocustomerMap[survey.institutionId] = customerName;
                                    ownerUser.customerName = customerName.name;
                                    survey.customerName = customerName.name;
                                    $scope.setSurveyOwnerInformation(ind, surveys);
                                }, function (httpResponse) {
                                    $scope.setSurveyOwnerInformation(ind, surveys);
                                })
                            }
                        }, function (httpResponse) {
                            $scope.setSurveyOwnerInformation(ind, surveys);
                        })
                    }
                }
            }else{
                orgSurveys = angular.copy(surveys);
            }
        }

        //sorting on the surveys
        $scope.order = function($event, predicate, reverse)
        {
            $event.stopImmediatePropagation(); //done to avoid the all.js element from being triggered (DS code)
            if ($scope.predicate && ($scope.predicate == predicate))
            {
                $scope.reverse = reverse; //use the parameter value only if predicate is the same as before
            }
            else $scope.reverse = true; //if predicate switches, default to descending sort
            $scope.predicate = predicate;


        }


        $scope.searchKeyDown = function(token) {
            $scope.inputData = token;

            if (token === '' || token === undefined) {
                return;
            }

            return $http.get(hostname + "/echo-api/api/autofill/contents/" + token, {
                params: {
                    //address: tkn,
                    sensor: false
                }
            }).then(
                function(res){
                    $scope.searchResultList = res.data;

                    return $scope.searchResultList;
                },
                function(error) {

                });
        };

        $scope.searchSelect = function(data) {
            $scope.searchToken = data.surveyName;
            $scope.surveys.length = 0;
            $scope.surveys.push(data);
        };

        $scope.searchSurvey = function(keyword) {
            //return $filter('filter')($scope.surveys , {'$': keyword});
            //var x =  $filter('filter')($scope.surveys , {'surveyName': keyword});
            //return x;
            if (keyword == 1)
                return [];
            var str = ""
            //var x =  $filter('filter')($scope.reports , {'name': keyword});
            //return x;
            return _.filter($scope.surveys, function(element){
                if (element.surveyStatus === surveyArchivedStatus) {
                    //ticket 45- do not list archived
                    return;
                }
                var check = false;
                for(let key in element){
                    if (key == "surveyName" || key == "createdUserDisplayName" || key == "customerName" || key == "surveyStatus" || key == "createdOnSearch") {
                        str = (element[key] + "").toLowerCase();
                        if (str.indexOf(keyword.toLowerCase()) != -1) {
                            check = true;
                            break;
                        }
                    }else {continue;}
                }
                if(check) return element;
            });
        }

        $scope.viewReport = function(survey){
            reportPreferences.reset();
            $rootScope.cameFromSurveys = true;
            if ($sessionStorage.reportPreferences)
            {
                $sessionStorage.reportPreferences = null;
                if ($sessionStorage.navDemographics) $sessionStorage.navDemographics = null;
            }
            var surveys = [];
            surveys.push(survey.surveyId);
            reportPreferences.setSurveyContentTypeId(survey.certificationId != null ? survey.surveyContentId : "-1");
            reportPreferences.setSurveyContentTypeName(survey.certificationId != null ? survey.surveyContentName : custom_content);
            reportPreferences.setSurveys(surveys);
            reportPreferences.setSurveyContentTypeNetworkAverage(survey.networkAverage);
            reportPreferences.setHavePreferences(true);
            $sessionStorage.reportPreferences = {};
            $sessionStorage.reportPreferences.surveyContentTypeId = reportPreferences.getSurveyContentTypeId();
            $sessionStorage.reportPreferences.surveyContentTypeName = reportPreferences.getSurveyContentTypeName();
            $sessionStorage.reportPreferences.surveys = reportPreferences.getSurveys();
            $sessionStorage.reportPreferences.networkAverage = reportPreferences.getSurveyContentTypeNetworkAverage();
            $location.path(previewPath);
        }

        $scope.downloadUnstackedCSV = function(survey){
            ReportService.updateCSVCounter({requestId: $scope.requestId});
            var reportPreferencesInputDTO = new Object();
            reportPreferencesInputDTO.reportPreferences = angular.copy(reportPreferences);
            reportPreferencesInputDTO.reportPreferences.reset();
            reportPreferencesInputDTO.reportPreferences.surveyContentTypeId = survey.certificationId != null ? survey.surveyContentId : "-1"
            reportPreferencesInputDTO.reportPreferences.surveyContentTypeName = "Anonymous";
            reportPreferencesInputDTO.reportPreferences.surveys = [survey.surveyId];
            reportPreferencesInputDTO.reportPreferences.networkAverage = survey.networkAverage;
            reportPreferencesInputDTO.reportPreferences.havePreferences = true;

            reportPreferencesInputDTO.userId = $scope.userId;
            reportPreferencesInputDTO.reportId = 0;
            reportPreferencesInputDTO.languageId = $scope.languageId;
            reportPreferencesInputDTO.reportPreferences.institutionsByHierarchy = $scope.institutionsHierarchy;

            reportPreferencesInputDTO.reportPreferences.fromDate = null;
            reportPreferencesInputDTO.reportPreferences.toDate = null;

            angular.extend(reportPreferencesInputDTO.reportPreferences, getReportCustomerInformation());

            return ReportService.createRawDataUnstackedReport(reportPreferencesInputDTO).$promise.then(function(report) {
                window.open(reports_shared_drive_uri + report.rawDataReportFileName,"_self");
            },function(error){
                $timeout(sweetAlert(error.data.message), 500); //error.data.message is the userToSeeMessage sent from api
            });
        }

        $scope.getInstitutionHierarchy = function() {
            return $scope.institutionsHierarchy;
        }

        var checkTermsAndConditions = function(){
            var tcFlag = userService.getTermsAndConditionsFlag();
            if(!tcFlag){
                var modalInstance = $modal.open({
                    templateUrl: 'partials/application/termsAndConditions.html',
                    controller: 'TermsAndConditionsCtrl',
                    scope: $scope,
                    backdrop: 'static',
                    resolve: {
                        items: function () {
                            var attributes = {
                            };
                            return attributes;
                        }
                    },
                    windowClass: 'reveal-modal medium radius report-share-modal'
                });

                modalInstance.result.then(function (selectedItem) {

                }, function () {

                });
            }

        }

        if ($rootScope.location === "reporting") {
            $rootScope.location = undefined;
            applicationService.goToPage("/browse-reports");
        }
        else {
            //invoke the call to retrieve user profile data and load the dashboard
            SurveyService.surveysForUserProfile($scope, $scope.retrieveDashboardData);

            checkTermsAndConditions();
        }

        $('.page-container').click(function(e){
            $('.new-survey__list').hasClass('visible') ?
                $('.new-survey__list').removeClass('visible').css({ left: -9999 }) :
                angular.noop;
        });

        $scope.visibleSurvey = function(survey) {
            if ($scope.selectedSurveyStatus == surveyAllStatus && survey.surveyStatus == surveyArchivedStatus) {
                return false;
            }
            else if ($scope.selectedSurveyStatus == surveyArchivedStatus && survey.surveyStatus == surveyClosedStatus) {
                return false;
            }

            return true;
        }

        $scope.showSummaryData = function(survey, createReport) {
            var modalInstance = $modal.open({
              templateUrl: 'partials/dashboard/dashboardActivitySummary.html',
              controller: 'DashboardActivitySummaryCtrl',
              scope: $scope,
              backdrop: 'static',
              resolve: {
                  items: function () {
                      var attributes = {
                      };
                      return attributes;
                  },
                  survey: function() {
                    return survey;
                  },
                  createReport: function() {
                    return createReport;
                  }
              },
              windowClass: 'activity-summary-modal'
          });
        }


        function getReportCustomerInformation() {
            return {
                surveyCreatorName: userService.getUserFullName()
            };
        }


        if(applicationService.getSesReportDownloadStatus()) {
            participantService.sesPdfIsReady($rootScope.pendingReportIdentifier);
            $rootScope.pendingReportDownload = false;
            applicationService.setSesReportDownloadStatus(false);
            $rootScope.pendingReportIdentifier = null;
        } else if($rootScope.pendingReportDownload) {
            participantService.presentReportDownloadModal($rootScope.pendingReportIdentifier);
            $rootScope.pendingReportDownload = false;
            $rootScope.pendingReportIdentifier = null;
        }

        $scope.hasAdminDates = function(survey) {
            return survey.administrationCloseDate && survey.administrationOpenDate;
        }

    }
]);
