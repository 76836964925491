app.directive('changeRosterModal', ['$rootScope', 'participantService', '$timeout',
    function($rootScope, participantService, $timeout) {
    return {
      restrict: 'E',
      templateUrl: 'partials/roster/change-roster-modal.html',
      scope: {
        showChangeModal: '='
      },
      link: function(scope, element, attrs) {
        /** Config options **/
        scope.title = 'Roster Change Request';
        scope.instructions = 'Select the button below to download the roster template and begin your Roster Change Request.';
        scope.uploadInstruction = 'Once the roster template is completed, upload your new roster file. Please include any comments you have below in the space provided.';
        scope.fileSizeLimit = 104857600;
        scope.maxCommentLength = 2000;
        scope.fileSizeLimitString = '100 MB limit';
        scope.errorMessage = undefined;
        scope.commentsPlaceholder = 'Enter comments here. Please do not include any student information in this comment area.';
        scope.touched = false;
        scope.inSelectionMode = true;
        scope.uploading = false;
        scope.uploadError = false;
        scope.isNTSUpload = false;
        const successMsg = 'Your roster change request has been successfully submitted.';
        const errorMsg = 'There was an issue uploading your file, your file upload has not been completed.';
        scope.uploadResultMessage = errorMsg;
        $rootScope.rosterFile = null;
        scope.showError = false;
        // Regex Expressions
        const fileNameExp = /^([A-Za-z0-9\_\-\ !#$%&\(\)\+,\-.=@_\{\}]+\.(csv|xls|xlsx))$/;
        const fileNameExp_NTS = /^(NTS_[A-Za-z0-9\_\-\ !#$%&\(\)\+,\-.=@_\{\}]+\.(csv|xls|xlsx))$/;

        /** User Input Data */
        scope.fileName = undefined;
        scope.fileSize;
        scope.fileSelected;
        scope.comments = {
            text: ''
        };
        scope.surveyId;
        scope.customerId;
        scope.displayName;

        $rootScope.$on('showChangeRosterModal', function(event, data) {
          $timeout(function(){
              scope.touched = false;
              scope.showChangeModal = true;
              scope.surveyId = data.surveyId;
              scope.customerId = data.customerId;
              scope.displayName = data.displayName;
              scope.isNTSUpload = data.isNTSUpload || false;
          },10);

        });

        /**
         * Resets modal logic
         */
        const clearData = function() {
            scope.fileName = undefined;
            scope.fileSize = undefined;
            scope.fileSelected = undefined;
            scope.comments = {
                text: ''
            };
            scope.inSelectionMode = true;
            scope.displayName = false;
            scope.uploadError = false;
            scope.showError = false;
            $rootScope.rosterFile = null;

            //scope.isNTSUpload = false;
            //scope.surveyId = undefined;
            //scope.customerId = undefined;

        }

        /**
         * Hides the modal
         */
        scope.cancel = function() {
            scope.showChangeModal = false;
            $rootScope.rosterFile = null;
            clearData();
        };

        scope.downloadRosterTemplate = function(){
            window.open(roster_shared_drive_uri , "_self");
        }

        /**
        * Dictates wether the upload button is enabled or not
        * @returns {boolean|*}
        */
        scope.isSubmitDisabled = function() {
            return scope.fileSelected == null || scope.showError;
        };

        /**
         * Presents the attachment dialog
         */
        scope.attach = function() {
          $timeout(function() {
              $("#file").click();
          });
        };

        /**
         * Closes the modal
         */
        scope.close = function() {
            scope.showChangeModal = false;
            clearData();
        };

        scope.cancelFile = function() {
            clearData();
        }

        /**
         * Executes when the user has a valid file input and clicks submit
         */
        scope.upload = function() {
            scope.uploading = true;
            scope.showChangeModal = false;
            participantService.submitChangeRosterRequest(scope.surveyId,
                scope.fileName,
                scope.customerId,
                scope.comments.text,
                scope.displayName,
                scope.fileSelected,
                scope.isNTSUpload,
                uploadSuccess,
                uploadFailure);
        };

          /**
           * File selected event handler
           * @param event
           */
          scope.onFileSelected = function(event) {
              scope.touched = true;
              const files = event.target.files;
              if (files && files.length > 0){
                  $timeout(function() {
                      // replace multiple spaces with single spaces
                      scope.fileName = files[0].name.replace('/\s+/g', ' ');
                      scope.fileSize = files[0].size;
                      scope.fileSelected = files[0];
                      $rootScope.rosterFile = scope.fileSelected;
                      scope.executeValidation();
                  });
              }
          };

          $rootScope.$watch('rosterFile', function( status )
        {
            if (status != null) {
                scope.fileSelected = status;
                scope.fileName = scope.fileSelected.name;
                scope.fileSize = scope.fileSelected.size;

                //We may need this.
                //scope.fileName = scope.fileSelected.name.replace('/\s+/g', ' ');

                scope.executeValidation();

                var oneKb = 1024;
                var oneMb = 1024*oneKb;

                if (scope.fileSize > 100*oneMb) {
                    scope.fileSize = Math.round(10*scope.fileSize/oneMb) / 10 + ' MB';
                    scope.showError = true;
                }
                else if (scope.fileSize >= oneMb /*1MB*/) {
                    scope.fileSize = Math.round(10*scope.fileSize/oneMb) / 10 + ' MB';
                }
                else if (scope.fileSize >= oneKb) {
                    scope.fileSize = Math.round(10*scope.fileSize/oneKb) / 10 + ' KB';
                }
                else {
                    scope.fileSize =  scope.fileSize + ' B';
                }
            }
            else {
                //scope.fileSelected = null;
            }
        });

        /**
        * Runs through basic validation logic
        * @returns {boolean}
        */
        scope.executeValidation = function() {
            scope.showError =  false;
            if(scope.fileName && (!scope.isNTSUpload ? fileNameExp.test(scope.fileName) : fileNameExp_NTS.test(scope.fileName))){
                // fileName is valid
            } else {
              // filename is invalid
                scope.showError = true;
            }

            if (!(scope.fileSize && scope.fileSize <= scope.fileSizeLimit)) {
                scope.showError =  true;
            }

            if (! scope.isCsv()) {
                scope.showError =  true;
            }
        };

        scope.isCsv = function() {
            if (scope.fileName != null && scope.fileName.endsWith('.csv')) {
                return true;
            }

            return false;
        }

          /**
         * Upload success handler
         */
        const uploadSuccess = function() {
            scope.showChangeModal = true;
            scope.title = 'Roster Request Confirmation';
            scope.uploading = false;
            scope.inSelectionMode = false;
            scope.uploadResultMessage = "Please monitor your email inbox for confirmation and any additional steps to complete your Roster Change Request.";
            $rootScope.rosterFile = null;
        };

        /**
         * Upload failure handler
         * @param err
         */
        const uploadFailure = function(err){
            scope.showChangeModal = true;
            scope.uploading = false;
            scope.inSelectionMode = true;
            scope.uploadError = false;
            scope.uploadResultMessage = errorMsg;
            scope.showError = true;
            $rootScope.rosterFile = null;
            //scope.uploadResultMessage = err.data == null ? errorMsg : err.data.message;
        };

          /**
           * Handles the change event on the text area
           * Note: This function enforces the 2k max char limit manually due to Angular JS issues with ng-maxlength
           * @param event
           */
        scope.commentsUpdated = function() {
            if (scope.comments.text.length > scope.maxCommentLength) {
                const oldComment = scope.comments.text.substring(0,2000);
                $timeout(function() {
                    scope.comments.text = oldComment;
                })
            }
        }
      }
    }
  }
]);
