(function(){
     'use strict';
        angular.module('echo')
            .controller('DashboardGenerateNowCtrl', DashboardGenerateNowCtrl);

        DashboardGenerateNowCtrl.$inject = [
            '$scope',
            '$modalInstance',
            '$timeout',
            '$location',
            'ReportService',
            'applicationService',
            '$sessionStorage',
            'closeModal',
            'cameFromViewSummary',
            '$rootScope',
            'userService'
        ];

        function DashboardGenerateNowCtrl ($scope,
                                           $modalInstance,
                                           $timeout,
                                           $location,
                                           ReportService,
                                           applicationService,
                                           $sessionStorage,
                                           closeModal,
                                           cameFromViewSummary,
                                           $rootScope,
                                           userService) {

            var maxHighestAverageItemRowCount = 5;
            var maxLowestAverageItemRowCount = 5;
            var MAXCOUNT = 4;
            var TIMEOUT = 2000;
            var reportPath = $location.path().indexOf("browse-reports") !== -1 ? "view-report" : "preview-report";

            // Cancel Modal
            $scope.cancel = function() {
                closeModal()("browse-reports");
                $modalInstance.dismiss('cancel');
            }

            // Get report data for a given survey Id
            $scope.getReportData = function() {

                var csvReport = cameFromViewSummary === 'csv' ? true : false;
                if (cameFromViewSummary || csvReport) {
                    $scope.reportTimeoutExpired = true;
                }

                $scope.institutionId = applicationService.getInstitution().id;
                $scope.institutionName = applicationService.getInstitution().name;
                $scope.school = applicationService.getInstitution().type === 'school' ? true : false;
                $scope.languageId = english_language_id;
                $scope.reportPreferences = $sessionStorage.reportPreferences;
                var reportPreferencesInputDTO = new Object();
                reportPreferencesInputDTO.userId = userService.getUserId();
                reportPreferencesInputDTO.institutionId = $scope.institutionId;
                reportPreferencesInputDTO.languageId = $scope.languageId;
                reportPreferencesInputDTO.emailUser = userService.getUserEmail();
                reportPreferencesInputDTO.institutionName = $scope.institutionName;
                reportPreferencesInputDTO.school = $scope.school;
                reportPreferencesInputDTO.reportPreferences = angular.copy($scope.reportPreferences);
                reportPreferencesInputDTO.reportPreferences.fromDate = convertDateYYYYmmDD(reportPreferencesInputDTO.reportPreferences.fromDate);
                reportPreferencesInputDTO.reportPreferences.toDate = convertDateYYYYmmDD(reportPreferencesInputDTO.reportPreferences.toDate);
                reportPreferencesInputDTO.reportPreferences.maxHighestItemCount = maxHighestAverageItemRowCount;
                reportPreferencesInputDTO.reportPreferences.maxLowestItemCount = maxLowestAverageItemRowCount;

                if (!csvReport) {
                    getReportData(reportPreferencesInputDTO);
                }

            }

            var convertDateYYYYmmDD = function(date) {
                if (date == null || !date.toString().indexOf('/') !== -1) {
                    return date;
                } else {
                    var dateComponents = date.split('/');
                    return dateComponents[2] + '-' + dateComponents[0] + '-' + dateComponents[1];
                }
            }

            var getReportData = function(reportPreferencesInputDTO) {
                if($rootScope.isDirtyReportData === false && $location.path() === "/preview-report"){
                    var requestId = $sessionStorage.requestId;
                    ReportService.updateReportCache({requestId: requestId, userId: userService.getUserId(), reportId: reportPreferencesInputDTO.reportPreferences.reportId}).$promise.then(function(preferences) {
                    }, handleErrorCallback)
                } else {
                    if (reportPreferencesInputDTO.reportPreferences) {
                        reportPreferencesInputDTO.reportPreferences.fromDate = new Date(reportPreferencesInputDTO.reportPreferences.fromDate).toJSON();
                        reportPreferencesInputDTO.reportPreferences.toDate = new Date(reportPreferencesInputDTO.reportPreferences.toDate).toJSON();
                    }
                    ReportService.getReportRequestId(reportPreferencesInputDTO).$promise.then(function(data) {
                        $scope.requestId = data.requestId;
                        $scope.count = 0;
                        if (!cameFromViewSummary) {
                            makeApiCallForStatus($scope.requestId, $scope.count);
                        }
                    }, handleErrorCallback)
                }

            }

            var makeApiCallForStatus = function(requestId, count) {
                if (count <= MAXCOUNT) {
                    ReportService.pollStatus({requestId: requestId}).$promise.then(function(data) {
                        $scope.status = data.status;
                    }, handleErrorCallback);
                    $timeout(function() { pollStatus(requestId, count)}, TIMEOUT);
                }
            }


            var pollStatus = function(requestId, count) {
                if (count >= MAXCOUNT) {
                    $scope.reportTimeoutExpired = true;
                } else {
                    if (!$scope.status) {
                        count++;
                        $timeout(function() { pollStatus(requestId, count)}, TIMEOUT);
                    } else if ($scope.status !== "COMPLETE") {
                        count++;
                        $scope.status = null;
                        makeApiCallForStatus(requestId, count);
                    } else {
                        applicationService.setRequestId(requestId);
                        $scope.cancel();
                        $location.path(reportPath);
                    }
                }
            }

            var handleErrorCallback = function(httpResponse) {
                if(httpResponse.status === 409 ){
                    $scope.cancel();

                    $timeout(sweetAlert("This report is currently in progress, a new request cannot be submitted at this time."), 500).then(function(){
                        $('#activityDetailsLoader').addClass('ng-hide');
                        $scope.activityLoading = false;
                        $('#sectionScoresError').removeClass('ng-hide');
                    });
                } else{
                    var msg = "There was an error retrieving your activity details.";
                    $scope.cancel();

                    $timeout(sweetAlert("The system encountered a problem, please try again.", msg ), 500).then(function(){
                        $('#activityDetailsLoader').addClass('ng-hide');
                        $scope.activityLoading = false;
                        $('#sectionScoresError').removeClass('ng-hide');
                    });
                }
            }

            $scope.getReportData();

        }
    }
)();