app.factory( 'customerService',
    [
        '$resource',
        'applicationService',
        'collectionUtils',
        'userService',
    function(
        $resource,
        applicationService,
        collectionUtils,
        userService
    ){
        return new CustomerService($resource, applicationService, collectionUtils, userService);
    }]);

function CustomerService(resourceService, applicationService, collectionUtils, userService) {

    var SCHOOL_TYPE = 'school';
    var SYSTEM_TYPE = 'District';
    var customerService = this;


    this.getSchoolType = function(){
        return SCHOOL_TYPE;
    }

    this.isSchoolType = function(institutionType){
        if(equalLowerCase(institutionType, SCHOOL_TYPE)){
            return true;
        }
        return false;
    }

    this.isSystemType = function(institutionType){
        if(equalLowerCase(institutionType, SYSTEM_TYPE)){
            return true;
        }
        return false;
    }


    this.isSchoolInstitution = function(institution){
        if(institution && equalLowerCase(institution.type, SCHOOL_TYPE)){
            return true;
        }
        return false;
    }
    this.isSystemInstitution = function(institution){
        if(institution && equalLowerCase(institution.type, SYSTEM_TYPE)){
            return true;
        }
        return false;
    }
    this.isCustomInstitution = function(institution){
        if(!this.isSchoolInstitution(institution) && !this.isSystemInstitution(institution)) {
            return true;
        }
        return false;
    }


    var equalLowerCase = function(word1, word2){

        if(word1 && word2 && word1.toLowerCase() == word2.toLowerCase()){
            return true;
        }
        return false;
    }





    var GET_CUSTOMER_BY_NUMBER_ENDPOINT = customer_api_hostname + '/customers/customerNumber/';

    this.getCustomerByNumber = function(customerNumber, successHandler, errorHandler){
        var url = GET_CUSTOMER_BY_NUMBER_ENDPOINT +customerNumber;

        resourceService(url).get()
            .$promise.then(function (response) {

                applicationService.handleAPISuccess(response, successHandler);
            }).catch(function error(error) {
                applicationService.handleAPIError(error, errorHandler);
            });
    }



    var GET_CUSTOMER_BY_ID_ENDPOINT = customer_api_hostname + '/customers/id/';

    this.getCustomerById = function(customerId, successHandler, errorHandler){
        var url = GET_CUSTOMER_BY_ID_ENDPOINT +customerId;

        resourceService(url).get()
            .$promise.then(function (response) {

                applicationService.handleAPISuccess(response, successHandler);
            }).catch(function error(error) {
                applicationService.handleAPIError(error, errorHandler);
            });
    }

    this.getCustomerByIdResource = function(customerId){
        var url = GET_CUSTOMER_BY_ID_ENDPOINT +customerId;

        return resourceService(url).get()
    }

    this.getCustomerHandler = function (customer) {
        applicationService.setCustomer(customer);
        applicationService.goToUserHomePage();
    }

    this.setCustomerFromDeepLink = function (customer) {
        applicationService.setCustomer(customer);
    }

    this.updateStatusHandler = function (response) {
        console.debug('User is updated');
    }

    this.checkInstitution = function(institutionId, cb) {
      var institution = sessionStorage.institution;
      if (!institution || institution.id != institutionId) {
          customerService.getCustomerById(institutionId, function(customer) {
          applicationService.setCustomer(customer);
          var superUser = userService.getIsSuperUser();
          if (!superUser) {

              if (userService.isIdInAssociationIds(institutionId)) {
                  // signInService.getCustomerAndNext(selectedInstitutionId, selectedInstitutionId, true);

                  //removed getCustomerAndNext call because both services are dependent on one another
                  //and instead moved the code from signInService to here
                  var association = userService.getAssociationById(institutionId);
                  userService.saveUserAssociation(association, true);
                  customerService.getCustomerById(institutionId, customerService.setCustomerFromDeepLink, null);

                  if(userService.isAssociationInvited(association)){
                      userService.updateStatus(userService.getUserId(), selectedInstitutionId, customerService.updateStatusHandler, null); // TODO: Will - selectedInstitutionId is not defined
                  }
                  //end getCustomerAndNext from signInService

                  cb();
              }
              else {
                  customerService.getOrderedAncestors(institutionId, function(orderedAncestorIds) {

                      if(orderedAncestorIds){
                          var associationFound = false;
                          for(var i in orderedAncestorIds){
                              var ancestorId = orderedAncestorIds[i];

                              if(userService.isIdInAssociationIds(ancestorId)){
                                  // signInService.getCustomerAndNext(institutionId, ancestorId, false);


                                  //removed getCustomerAndNext call because both services are dependent on one another
                                  //and instead moved the code from signInService to here
                                  var association = userService.getAssociationById(ancestorId);
                                  userService.saveUserAssociation(association, false);
                                  customerService.getCustomerById(institutionId, customerService.setCustomerFromDeepLink, null);

                                  //removed because this will always be false because were passing
                                  //false is for the isDirectAssociation variable here
                                  // if(isDirectAssociation && userService.isAssociationInvited(association)){
                                  //     userService.updateStatus(userService.getUserId(), customerId, updateStatusHandler, null);
                                  // }

                                  //end getCustomerAndNext code from signInService



                                  associationFound = true;
                                  break;
                              }
                          }

                          if(!associationFound){
                              var errorMessage = "No Association found in selected institution's hierarchy";
                              // scope.exception = errorMessage;
                              console.error = errorMessage;
                          }
                      }
                      cb();
                  }, null);
              }
          } else {
              userService.getPermissionsForSuperUser(function(permissions) { // adds all super user permissions to the user's association and sets the Customer.
                  var association = {};
                  association.permissions = permissions;
                  userService.admin(association,'roleName');
                  userService.saveUserAssociation(association, false);
                  customerService.getCustomerById(institutionId, function(customer) {
                    applicationService.setCustomer(customer);
                    cb();
                  }, null);
                  // signInService.setCustomerForSuperUser(institutionId);
              }, null);
          }
        }, null)
      }
    }



    var GET_CUSTOMER_BY_PARENT_ID_ENDPOINT = customer_api_hostname + '/parentCustomerId/';

    this.getCustomersByParentId = function(parentCustomerId, successHandler, errorHandler){
        getCustomerByParentIdResource()
            .$promise.then(function (response) {

                applicationService.handleAPISuccess(response, successHandler);
            }).catch(function error(error) {
                applicationService.handleAPIError(error, errorHandler);
            });
    }

    var getCustomerByParentIdResource = function(parentCustomerId){
        var url = GET_CUSTOMER_BY_PARENT_ID_ENDPOINT +parentCustomerId;

        return resourceService(url).query()
    }



    var GET_CUSTOMERS_HIERARCHY = customer_api_hostname + '/customers/withLineage';

    var customersHierarchyResource = function(customerIds){
        var url = GET_CUSTOMERS_HIERARCHY;
        var urlResource = resourceService(url, {}, {
            save: {
                method: 'POST',
                isArray: true
            }
        });
        return urlResource.save(customerIds);
    }

    this.getCustomersHierarchyResource = function(customerIds){
        return customersHierarchyResource(customerIds);
    }

    this.getCustomersHierarchy = function(customerIds, successHandler, errorHandler){
        customersHierarchyResource(customerIds)
            .$promise.then(function (response) {
                applicationService.handleAPISuccess(response, successHandler);
            }).catch(function error(error) {
                applicationService.handleAPIError(error, errorHandler);
            });
    }





    var GET_CUSTOMERS_WITH_CHILD_STATUS = customer_api_hostname + '/noauth/customersWithChildStatus';

    var customersWithChildStatus = function(customerIds){
        var urlResource = resourceService(GET_CUSTOMERS_WITH_CHILD_STATUS, {}, {
            getCustomers: {
                method: 'POST',
                params: {customerStatus: 'Active'},
                isArray: true
            }
        });
        return urlResource.getCustomers(customerIds);
    }

    this.getCustomersWithChildStatus = function(customerIds, successHandler, errorHandler, scope){
        customersWithChildStatus(customerIds)
            .$promise.then(function (response) {
                if (response.length == 0) {
                    if (scope != undefined && scope.errors != undefined) {
                        scope.errors.login = "User Profile is not associated with any institutions";
                    }
                    //applicationService.logout();
                    applicationService.goToSigninPage();

                    return;
                }
                applicationService.handleAPISuccess(response, successHandler);
            }).catch(function error(error) {
                applicationService.handleAPIError(error, errorHandler);
            });
    }

    var GET_MINIMAL_CUSTOMER_CHILDREN_INFO = customer_api_hostname + '/noauth/id/:customerId/childrenWithChildStatus';

    var minCustomerChildrenInfoResource = function(customerId){
        var urlResource = resourceService(GET_MINIMAL_CUSTOMER_CHILDREN_INFO, {}, {
            getCustomers: {
                method: 'GET',
                params: {customerId: '@customerId', customerStatus: 'Active'},
                isArray: true
            }
        });
        return urlResource.getCustomers({customerId: customerId});
    }

    this.getMinCustomerChildrenInfoResource = function(customerIds){
        return minCustomerChildrenInfoResource(customerIds);
    }



    var SEARCH_INSTITUTION_FOR_CUSTOMER = customer_api_hostname + '/search/children';

    var searchInstitutionsForCustomerResource = function(searchParams){

        var urlResource = resourceService(SEARCH_INSTITUTION_FOR_CUSTOMER, {}, {
            searchCustomers: {
                method: 'POST',
                isArray: true
            }
        });
        return urlResource.searchCustomers(searchParams);
    }


    this.searchInstitutionsForCustomer = function(searchParams, successHandler, errorHandler){
        searchInstitutionsForCustomerResource(searchParams)
            .$promise.then(function (response) {
                applicationService.handleAPISuccess(response, successHandler);
            }).catch(function error(error) {
                applicationService.handleAPIError(error, errorHandler);
            });
    }



    var SEARCH_INSTITUTION_FOR_SUPERUSER = customer_api_hostname + '/search/superUserCustomers';

    var searchInstitutionsForSuperUserResource = function(searchParams){

        var urlResource = resourceService(SEARCH_INSTITUTION_FOR_SUPERUSER, {}, {
            searchCustomers: {
                method: 'GET',
                isArray: true
            }
        });
        return urlResource.searchCustomers(searchParams);
    }

    this.searchInstitutionsForSuperUser = function(searchParams, successHandler, errorHandler){
        searchInstitutionsForSuperUserResource(searchParams)
            .$promise.then(function (response) {
                applicationService.handleAPISuccess(response, successHandler);
            }).catch(function error(error) {
                applicationService.handleAPIError(error, errorHandler);
            });
    }



    var GET_ORDERED_ANCESTORS = customer_api_hostname + '/customers/:customerId/orderedAncestors';

    var orderedAncestors = function(customerId){
        var urlResource = resourceService(GET_ORDERED_ANCESTORS, {}, {
            getAncestorIds: {
                method: 'GET',
                params: {customerId: '@customerId'},
                isArray: true
            }
        });
        return urlResource.getAncestorIds({customerId: customerId});
    }

    this.getOrderedAncestors = function(customerId, successHandler, errorHandler){
        var resource = orderedAncestors(customerId);
        applicationService.executeAsPromiseWithHandlers(resource, successHandler, errorHandler);
    }











    this.getCustomersFromHierarchy = function(customerHierarchy, customers) {
        getCustomers(customerHierarchy, customers);
    }

    var getCustomers= function(customerHierarchy, customers) {
        customerHierarchy.forEach(function(customer){
            customers.push(getStrippedCustomer(customer));
            if(customer.children){
                getCustomers(customer.children, customers);
            }
        })

    }

    var getStrippedCustomer = function(customer){
        return {
            'id': customer.id,
            'name': customer.name,
            'customerType': customer.customerType,
            'parentCustomerIds':customer.parentCustomerIds
        }
    }



    this.getAllCustomersIds = function(customers){
        return collectionUtils.getAllIdsFromList(customers);
    }


    var GET_SCHOOLS_BY_PARENT_ENDPOINT = customer_api_hostname + "/customers/:topLevelCustomerId/schools";
    this.getSchoolsByParentIdResource = function(customerId){
        var urlResource = resourceService(GET_SCHOOLS_BY_PARENT_ENDPOINT, {}, {
            getSchoolIds: {
                method: 'GET',
                params: {topLevelCustomerId: '@topLevelCustomerId'},
                isArray: true
            }
        });
        return urlResource.getSchoolIds({topLevelCustomerId: customerId});
    }


    var GET_INSTITUTIONS_BY_PARENT_ENDPOINT = customer_api_hostname + "/customers/:topLevelCustomerId/institutions";
    this.getInstitutionsByParentIdResource = function(customerId) {
        var urlResource = resourceService(GET_INSTITUTIONS_BY_PARENT_ENDPOINT, {}, {
            getInstitutionIds: {
                method: 'GET',
                params: {topLevelCustomerId: '@topLevelCustomerId'},
                isArray: true
            }
        });
        return urlResource.getInstitutionIds({topLevelCustomerId: customerId});
    }
}
