app.factory( 'reportPdfDataService', [ 'collectionUtils',
    function(collectionUtils){
        return new ReportPdfDataService(collectionUtils);
    }]);

function ReportPdfDataService(collectionUtils) {


    var reportId;
    var reportContentType;
    var reportHeader;
    var filteredDemographics;
    var responseInstitutions;
    //var responseLanguages;

    var reportPreferences;
    var overallScore;
    var summarySectionActivity;

    var certifiedSections;
    var customSections;
    var uniqueSurveysInfo;
    var certifiedContent;
    var customSurveys;

    var certifiedItem = 0;
    var customItem = 1;
    var certifiedTemplateWithCustomItem = 2;
    var customTemplateItem = 3;
    var customTemplateWithCustomItem = 4;


    // Contract to PDF Service
    this.getReportData = function(){
        var reportData = {};

        reportData.reportId = reportId;
        reportData.reportContentType = reportContentType;
        reportData.reportHeader = reportHeader;
        reportData.filteredDemographics = filteredDemographics;
        reportData.responseInstitutions = responseInstitutions;
        //reportData.responseLanguages = responseLanguages;

        //certified
        if(reportContentType == certifiedItem || reportContentType == certifiedTemplateWithCustomItem || reportContentType == customTemplateItem || reportContentType == customTemplateWithCustomItem){

             if(certifiedContent){
                 certifiedContent.overallScore = {
                     'score': parseFloat(overallScore.overallScore) || 0,
                     'networkAverage' : reportPreferences.networkAverage || 0,
                     'scale' : overallScore.scale
                 };

                 certifiedContent.sectionActivity = summarySectionActivity;
             }

            reportData.content = certifiedContent;
         }

        //custom
        if(reportContentType == customItem || reportContentType == certifiedTemplateWithCustomItem || reportContentType == customTemplateWithCustomItem){
            reportData.customSurveys = customSurveys;
        }


        return reportData;
    }



    this.parsePdfObject = function(pdfObject){



        reportPreferences = pdfObject.reportPreferences;

        reportId = pdfObject.reportPreferences.reportId;
        reportContentType = pdfObject.reportContentType;
        reportHeader = pdfObject.reportHeader;
        filteredDemographics = pdfObject.filteredDemographics;
        responseInstitutions = pdfObject.responseInstitutions;
        //responseLanguages = pdfObject.responseLanguages;
        overallScore = pdfObject.overallScore;

        parseItemActivity(pdfObject.itemActivity);

        this.parseSectionActivity(pdfObject);


    }

    this.parseSectionActivity  = function(pdfObject){
        summarySectionActivity = [];
        if (pdfObject.sectionActivity.length > 0) {
            for (var i = 0; i < pdfObject.sectionActivity.length; i++) {
                var activity = {};
                activity.sectionName = pdfObject.sectionActivity[i].sectionName;
                activity.sectionScore = pdfObject.sectionActivity[i].sectionAverage;
                activity.sectionNetworkAverage = pdfObject.sectionActivity[i].sectionNetworkAverage;
                activity.scale = pdfObject.overallScore.scale;

                summarySectionActivity.push(activity);
            }
        }

    }

    var parseItemActivity = function(items){
        certifiedSections = searchContentTypeAndElementTypeInItemsHierarchy(items, 'Certified Content', 'Section');

        if (collectionUtils.listIsEmpty(certifiedSections)) {
            certifiedSections = searchContentTypeAndElementTypeInItemsHierarchy(items, 'Custom Template', 'Section');
        }
        //console.log('certifiedSections ', certifiedSections);

        customSections = searchContentTypeAndElementTypeInItemsHierarchy(items, 'Custom Survey', 'Section');

        if (collectionUtils.listIsEmpty(customSections)) {
            customSections = searchContentTypeAndElementTypeInItemsHierarchy(items, 'Custom Content', 'Section');
        }


        uniqueSurveysInfo = getUniqueSurveyInfo(customSections);

        var oldCertifiedContent = certifiedContent;
        certifiedContent = getContent(certifiedSections);

        if (oldCertifiedContent && certifiedContent) {
            if (!certifiedContent.overallScore && oldCertifiedContent.overallScore) {
                certifiedContent.overallScore = oldCertifiedContent.overallScore;
            }
            if (!certifiedContent.sectionActivity && oldCertifiedContent.sectionActivity) {
                certifiedContent.sectionActivity = oldCertifiedContent.sectionActivity;
            }
            if (!certifiedContent.highestScoringItems && oldCertifiedContent.highestScoringItems) {
                certifiedContent.highestScoringItems = oldCertifiedContent.highestScoringItems;
            }
            if (!certifiedContent.lowestScoringItems && oldCertifiedContent.lowestScoringItems) {
                certifiedContent.lowestScoringItems = oldCertifiedContent.lowestScoringItems;
            }
        }

        customSurveys = getSurveyContents(uniqueSurveysInfo, customSections);

    }



    var getSurveyContents = function(surveys, sections){
        var result = [];

        for(var a = 0; a < surveys.length; a++){
            var survey = surveys[a];
            var surveySections = filterSurveySections(sections, survey.surveyId);

            var resultSurvey = {
                'id': survey.surveyId,
                'name': survey.surveyName,
                content: getContent(surveySections)
            }

            result.push(resultSurvey);
        }

        return result;
    }


    var getUniqueSurveyInfo = function(sections){
        var result = [];
        var uniqueIds = [];
        for(var i = 0; i < sections.length; i++) {
            var id = sections[i].surveyId;
            if (uniqueIds.indexOf(id) == -1) {
                uniqueIds.push(id);
                result.push({
                    'surveyId': id,
                    'surveyName': sections[i].surveyName
                });
            }
        }
        return result;
    }



    var getContent = function(sections){
        var demographicSections = filterDemographicItems(sections, true);
        var nonDemographicSections = filterDemographicItems(sections, false);
        return {
            'demographicSections': parseSections(demographicSections),
            'nonDemographicSections': parseSections(nonDemographicSections)
        }
    }


    var parseSections = function(sections){
        var result = [];
        if(sections){
            for(var a = 0; a < sections.length; a++){
                var resultSection = {
                    'id': sections[a].itemId,
                    'parentId': sections[a].parentItemId,
                    'name': sections[a].name,
                    'label': sections[a].label,
                    'useEmoticon': sections[a].useEmoticon,
                    'elementContentType': sections[a].elementContentType,
                    'elementTypeName': sections[a].elementTypeName,
                    'items': parseItems(sections[a].childItems, 0, null, null),
                    'groupingApplied': sections[a].groupingApplied
                }

                if(!collectionUtils.listIsEmpty(sections[a].childItems)){
                    resultSection.allOptions = sections[a].childItems[0].allOptions;
                }

                result.push(resultSection);
            }
        }

        return result;
    }


    var parseItems = function(items, groupLevel, renderType, allOptions){
        var result = [];

        if(items){
            for(var a = 0; a < items.length; a++){

                var itemRenderType = (renderType)? renderType : items[a].renderType;
                var itemAllOptions = (allOptions)? allOptions : items[a].allOptions;

                var resultItem = {
                    'id': items[a].itemId,
                    'parentId': items[a].parentItemId,
                    'name': items[a].name,
                    'surveyId': items[a].surveyId,
                    'surveyName': items[a].surveyName,
                    'text': items[a].itemText,
                    'label': items[a].label,
                    'sort': items[a].sort,
                    'count': items[a].totalItemCount,
                    'useEmoticon': items[a].useEmoticon,
                    'renderType': itemRenderType,
                    'elementContentType': items[a].elementContentType,
                    'elementTypeName': items[a].elementTypeName,
                    'allOptions': itemAllOptions
                };


                if(itemRenderType == 'Opinion Scale'){
                    resultItem.responses = parseOptionsResponses(items[a].responseData);
                }
                else if(itemRenderType == 'Pick One' || itemRenderType == 'Dropdown' || itemRenderType == 'Pick Multiple'){
                    items[a].renderType = 'Dropdown';
                    resultItem.responses = parseOptionsResponses(items[a].responseData);
                }
                else if(itemRenderType == 'Text Field'){
                    resultItem.responses = parseOpenEndedResponses(items[a].responseData);
                }

                if(!collectionUtils.listIsEmpty(items[a].childItems)){
                    resultItem.grouped = true;
                    resultItem.allOptions = [];
                    resultItem.childItems = parseItems(items[a].childItems, groupLevel+1, itemRenderType, itemAllOptions);
                }

                var groupLabel;
                if(groupLevel == 1){
                    groupLabel = getFirstGrouping();
                }
                else if(groupLevel == 2) {
                    groupLabel = getSecondGrouping();
                }

                if(groupLabel){
                    resultItem.groupInfo = {
                        'level': groupLevel,
                        'label': groupLabel
                    }
                }

                result.push(resultItem);
            }
        }

        return result;
    }


    var getFirstGrouping = function(){
        if(reportPreferences){
            return reportPreferences.firstGrouping;
        }
    }
    var getSecondGrouping = function(){
        if(reportPreferences){
            return reportPreferences.secondGrouping;
        }
    }
   /* var getInstitutionName = function(id){
        var institution = collectionUtils.getByIdFromList(responseInstitutions, id);
        if(institution){
            return institution.name;
        }
    }*/

    var parseOptionsResponses = function(responseData){
        var result = [];

        if(responseData){
            for(var a = 0; a < responseData.length; a++){
                var resultResponse = {
                    'name': responseData[a].name,
                    'text': responseData[a].itemText,
                    'itemId': responseData[a].itemId,
                    'sort': responseData[a].sort,
                    'count': responseData[a].responseCount,
                    'percentage': responseData[a].responsePercentage
                }

                result.push(resultResponse);
            }
        }

        return result;
    }




    var parseOpenEndedResponses = function(responses){
        var result = [];

        if(responses){
            for(var a = 0; a < responses.length; a++){
                var resultResponse = {
                    'id': responses[a].responseId,
                    'text': responses[a].answerString,
                    'itemId': responses[a].elementId
                }

                result.push(resultResponse);
            }
        }

        return result;
    }




    this.addOpenEndedAnswers = function(openEndedAnswers){
        for(var a = 0; a < openEndedAnswers.length; a++){
            var answer = openEndedAnswers[a];
            //console.log('answer ', answer);

            var section;
            var sections;
            var answerSectionId;
            var answerItemId;

            if(answer.masterElementId){// certified section with certified items - it is a certified item for sure
                answerItemId = answer.masterElementId;
                answerSectionId = answer.sectionMasterElementId;
                if(certifiedContent){
                    if(answer.demographic){
                        sections = certifiedContent.demographicSections;
                    }
                    else {
                        sections = certifiedContent.nonDemographicSections;
                    }
                    section = collectionUtils.getByIdFromList(sections, answerSectionId)
                }

            }
            else if(answer.sectionMasterElementId){ // it is not a certified item, but a certified section (certified section, custom item)
                answerItemId = answer.elementId;
                answerSectionId = answer.sectionMasterElementId;
                if(certifiedContent){
                    if(answer.demographic){
                        sections = certifiedContent.demographicSections;
                    }
                    else {
                        sections = certifiedContent.nonDemographicSections;
                    }
                    section = collectionUtils.getByIdFromList(sections, answerSectionId)
                }

            } else { // Only custom sections - not certified section, not certified item
                   // Custom section within certified (can have more than 1 surveys)
                    // or purely custom (only 1 survey)
                if(customSurveys){
                    var survey = collectionUtils.getByIdFromList(customSurveys, answer.surveyId);
                    //console.log('survey ', survey);

                    answerItemId = answer.elementId;
                    answerSectionId = answer.sectionElementId;
                    if(survey && survey.content){ // multiple custom surveys for certified surveys
                        if(answer.demographic){
                            sections = survey.content.demographicSections;
                        }
                        else {
                            sections = survey.content.nonDemographicSections;
                        }
                    } else if (customSurveys[0] && customSurveys[0].content) { // Purely custom surveys (will always be 1 per report)
                        if(answer.demographic){
                            sections = customSurveys[0].content.demographicSections;
                        }
                        else {
                            sections = customSurveys[0].content.nonDemographicSections;
                        }
                    }
                    section = collectionUtils.getByIdFromList(sections, answerSectionId)
                }

            }

            if(section){
                var item = collectionUtils.getByIdFromList(section.items, answerItemId);
                //console.log('item ', item);

                if(item){
                    item.responses = parseOpenEndedResponses(answer.responses);
                }
            }
        }
    }




    this.addHighestScoringItems = function(scoringActivity){
        if(certifiedContent){
            certifiedContent.highestScoringItems =
            {   'name' : 'Highest Scoring Items',
                'items': parseScoringItems(scoringActivity)
            };
        }
    }



    this.addLowestScoringItems = function(scoringActivity){
        if(certifiedContent){
            certifiedContent.lowestScoringItems =
            {   'name' : 'Lowest Scoring Items',
                'items': parseScoringItems(scoringActivity)
            };
        }
    }




    var parseScoringItems = function(items){
        var result = [];

        if(items){
            for(var a = 0; a < items.length; a++){

                var resultItem = {
                    'id': items[a].itemId,
                    'parentId': items[a].parentItemId,
                    'name': items[a].name,
                    'text': items[a].itemText,
                    'label': items[a].label,
                    'parentLabel': items[a].parentLabel,
                    'sort': items[a].sort,
                    'count': items[a].totalItemCount,
                    'score': items[a].itemAverage,
                    'networkAverage': items[a].itemAverage,
                    'rank': items[a].rank,
                    'useEmoticon': items[a].useEmoticon,
                    'renderType': items[a].renderType,
                    'allOptions': items[a].allOptions,
                    'responses' : parseOptionsResponses(items[a].responseData)
                };

                result.push(resultItem);
            }
        }

        return result;
    }














    var searchIdInItemsHierarchy = function(items, itemId) {
        for (var i = 0; i < items.length; i++) {
            var found = searchItemIdRecursive(items[i], itemId);
            if (found) {
                return found;
            }
        }
    }

    function searchItemIdRecursive(item, itemId) {
        if (item.itemId == itemId) {
            return item;
        }
        if (item.childItems ) {
            var ch = item.childItems ;
            for (var i = 0; i < ch.length; i++) {
                var found = searchItemIdRecursive(ch[i], itemId);
                if (found) {
                    return found;
                }
            }
        }
        return false;
    }






    var searchContentTypeAndElementTypeInItemsHierarchy = function(items, contentType, elementType) {
        var result = [];
        for (var i = 0; i < items.length; i++) {
            var foundItems = searchContentAndElementRecursive(items[i], contentType, elementType);
            if (!collectionUtils.listIsEmpty(foundItems) ) {
                result.push(foundItems);
            }
        }

        return collectionUtils.mergeListOfLists(result);
    }

    function searchContentAndElementRecursive(item, contentType, elementType) {
        var result = [];
        if (item.elementContentType == contentType && item.elementTypeName == elementType) {
            result.push(item);
        }
        if (item.childItems ) {
            var ch = item.childItems ;
            for (var i = 0; i < ch.length; i++) {
                var foundItems = searchContentAndElementRecursive(ch[i], contentType, elementType);
                if (!collectionUtils.listIsEmpty(foundItems) ) {
                    result.push(foundItems);
                }
            }
        }
        return result;
    }



    var spliceItemsFromSections = function(sections, contentType) {
        var result = [];

        for(var sectionIndex= 0; sectionIndex<sections.length; sectionIndex++){
            var splicedItems = spliceItemsFromSection(sections[sectionIndex], contentType);
            if (!collectionUtils.listIsEmpty(splicedItems) ) {
                result.push(splicedItems);
            }
        }
        return collectionUtils.mergeListOfLists(result);
    }


    var spliceItemsFromSection = function(section, contentType){
        var result = [];

        if(section.childItems){
            for(var itemIndex=0; itemIndex<section.childItems; itemIndex++){

                if(section.childItems[itemIndex].elementContentType == contentType){
                    result.push(section.childItems[itemIndex]);
                    section.childItems.splice(itemIndex, 1);
                }
            }
        }

        return result;
    }



    var filterDemographicItems = function(items, isDemo) {
        var result = [];
        for (var i = 0; i < items.length; i++) {
            var item = items[i];
            if (item.demographic == isDemo) {
                result.push(item);
            }
        }

        return result;
    }



    var filterRenderType = function(items, typeName) {
        var result = [];
        for (var i = 0; i < items.length; i++) {
            var item = items[i];
            if (item.renderType == typeName) {
                result.push(item);
            }
        }

        return result;
    }


    var filterSurveySections = function(sections, surveyId) {
        var result = [];
        for (var i = 0; i < sections.length; i++) {
            if (sections[i].surveyId == surveyId) {
                result.push(sections[i]);
            }
        }

        return result;
    }
}
