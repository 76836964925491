(function () {
        'use strict';
        angular.module('echo')
            .controller('ManageUsersCtrl', ManageUsersCtrl);

        ManageUsersCtrl.$inject = ['$scope',
            '$rootScope',
            '$timeout',
            '$modal',
            'Upload',
            '$location',
            'userService',
            'SurveyCreateService',
            'applicationService',
            '$filter', 'modalService'];

        /**
         * @description ManageUsersCtrl handles the creation, editing, and deleting of users, as well as the assignment of PII permissions.
         * While calls to userService.saveUser, etc. are usually restricted to services, due to the complexity of this
         * controller we make an exception here. Later refactoring should be done to move the business logic out of the
         * controller and into services.
         *
         * */

        function ManageUsersCtrl($scope,
                                 $rootScope,
                                 $timeout,
                                 $modal,
                                 Upload,
                                 $location,
                                 userService,
                                 SurveyCreateService,
                                 applicationService,
                                 $filter,
                                 modalService) {
            //$scope.$root.headerAction = 'hidden';
            $rootScope.headerAction = 'active';
            $scope.users = [];
            $scope.errors = {};
            $scope.users_loading = true;
            $scope.sendEmail = true;
            $scope.sendEditUserEmail = false;
            $scope.disableEmailEdit = false;
            $scope.disableFirstNameEdit = false;
            $scope.disableLastNameEdit = false;
            $scope.selectedAuthority = [];
            $scope.predicate = 'last';
            $scope.reverse = true;
            $scope.masterCheckBox = false;
            $scope.selectedRole;

            $scope.newuser = {
                firstName: '',
                lastName: '',
                email: '',
                authorities: [{name: "Viewer", selected: "selected"},
                    {name: "Editor", selected: "unselected"},
                    {name: "Administrator", selected: "unselected"}],
                isPIIUser: false,
                errors: {}
            }

            $scope.edituser = {
                firstName: '',
                lastName: '',
                email: '',
                authorities: [{name: "Viewer", selected: "selected"},
                    {name: "Editor", selected: "unselected"},
                    {name: "Administrator", selected: "unselected"}],
                isPIIUser: false,
                errors: {}
            }
//----------------------User Management Filter Starts----------------------------------
            var filters = {
                ALL_USERS: 'All Users',
                STATUSES: {
                    STATUS: 'Status', ENABLED: 'Enabled', DISABLED: 'Disabled', INVITED: 'Invited'
                },
                ROLES: {
                    ROLE: 'Role', ADMIN: 'Admin', EDITOR: 'Editor', VIEWER: 'Viewer'
                }
            };

            $scope.FILTER_OPTIONS = filters;

            $scope.filterByOptions = [
                {name: filters.ALL_USERS},
                {
                    name: filters.STATUSES.STATUS, options: [
                    {name: filters.STATUSES.ENABLED}, {name: filters.STATUSES.DISABLED}, {name: filters.STATUSES.INVITED}
                ]
                },
                {
                    name: filters.ROLES.ROLE, options: [
                    {name: filters.ROLES.ADMIN}, {name: filters.ROLES.EDITOR}, {name: filters.ROLES.VIEWER}
                ]
                }
            ]

            $scope.initialize = function () {
                $scope.filterLabel = filters.ALL_USERS;
                $scope.getUserList();
            }

            $scope.filter = function (topFilterOption, secondLevelFilterOption) {
                if (topFilterOption === filters.ALL_USERS) {
                    filterAll();
                }
                else if (topFilterOption === filters.STATUSES.STATUS) {
                    filterByStatus(secondLevelFilterOption);
                }
                else if (topFilterOption === filters.ROLES.ROLE) {
                    filterByRole(secondLevelFilterOption);
                }
            }

            var filterAll = function () {
                $scope.filterLabel = filters.ALL_USERS;
                $scope.getUserList();
            };
            var filterByStatus = function (status) {
                $scope.filterLabel = status;
                $scope.users = filterUserDataByStatus($scope.origUsers, status);
            };
            var filterByRole = function (role) {
                $scope.filterLabel = role;
                $scope.users = filterUserDataByRole($scope.origUsers, role);
            };

            var filterUserDataByStatus = function (userData, status) {
                var filteredUsers = [];
                for (var i in userData) {
                    var data = userData[i];
                    if (data && data.status === status) {
                        filteredUsers.push(data);
                    }
                }
                return filteredUsers;
            };

            var filterUserDataByRole = function (userData, role) {
                var filteredUsers = [];
                for (var i in userData) {
                    var data = userData[i];
                    if (data && compareRoles(data.role, role)) {
                        filteredUsers.push(data);
                    }
                }
                return filteredUsers;
            };

            var compareRoles = function (role, filter) {
                return role.toLowerCase()
                        .indexOf(filter.toLowerCase()) >= 0;
            }
//----------------------User Management Filter Ends----------------------------------

            $scope.getUserList = function () {
                $scope.users = [];
                userService.getUsers(applicationService.getInstitutionId()).$promise.then(function (users) {
                    if (users.length === 0) {
                        $scope.users_loading = false;
                    }


                    var myuser = {
                        "userId": "",
                        "firstName": "",
                        "lastName": "",
                        "email": "",
                        "institution": "",
                        "status": "",
                        "role": ""
                    };

                    angular.forEach(users, function (user) {
                        $scope.user = angular.copy(myuser); // We are iterating over users and adding each one to the scope as part of
                        // the forEach logic. It may be a good idea to refactor this with a function-scoped variable instead.

                        $scope.user.userId = user.id;
                        $scope.user.firstName = user.firstName;
                        $scope.user.lastName = user.lastName;
                        $scope.user.email = user.email;
                        $scope.user.institution = applicationService.getInstitutionName();
                        $scope.user.isPIIUser = false;
                        switch (user.roles[0]) {
                            case userService.ROLES.ROLE_ADMIN :
                                $scope.user.role = userService.DISPLAY_ROLES.ADMIN;
                                break;
                            case userService.ROLES.ROLE_ADMIN_PII :
                                $scope.user.isPIIUser = true;
                                $scope.user.role = userService.ROLES.ROLE_ADMIN_PII;
                                break;
                            case userService.ROLES.ROLE_EDITOR :
                                $scope.user.role = userService.DISPLAY_ROLES.EDITOR;
                                break;
                            case userService.ROLES.ROLE_EDITOR_PII :
                                $scope.user.role = userService.ROLES.ROLE_EDITOR_PII;
                                $scope.user.isPIIUser = true;
                                break;
                            case userService.ROLES.ROLE_VIEWER_PII :
                                $scope.user.role = userService.ROLES.ROLE_VIEWER_PII;
                                $scope.user.isPIIUser = true;
                                break;
                            default                     :
                                $scope.user.role = userService.DISPLAY_ROLES.VIEWER;

                        }


                        if (user.statusId === 1) {
                            $scope.user.status = "Enabled";
                        }
                        if (user.statusId === 2) {
                            $scope.user.status = "Disabled";
                        }
                        if (user.statusId === 3) {
                            $scope.user.status = "Invited";
                        }

                        $scope.users.push($scope.user);

                        // Excluding the logged-in user from the list of manage-users list
                        $scope.users.forEach(function (u, idx, array) {
                            if (u.userId == userService.getUserId()) {
                                var index = $scope.users.indexOf(u);
                                $scope.users.splice(index, 1);
                                $scope.users_loading = false;
                            }
                            if (idx === array.length - 1) {
                                $scope.users_loading = false;
                            }
                        });

                    });
                }).then(function () {
                    $scope.order = function ($event, predicate, reverse) {
                        var users = $scope.users;

                        var translatePredicate = function (p) {
                            switch (p) {
                                case('last'):
                                    return 'lastName';
                                case('first'):
                                    return 'firstName';
                                default:
                                    return p;
                            }
                        }

                        if ($event) {
                            $event.stopImmediatePropagation();
                        }

                        $scope.users.sort(function (a, b) {
                            if (reverse) {
                                if (a[translatePredicate(predicate)] < b[translatePredicate(predicate)]) {
                                    return 1;
                                } else if (a[translatePredicate(predicate)] > b[translatePredicate(predicate)]) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            } else {
                                if (a[translatePredicate(predicate)] > b[translatePredicate(predicate)]) {
                                    return 1;
                                } else if (a[translatePredicate(predicate)] < b[translatePredicate(predicate)]) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            }
                        });

                        $scope.predicate = predicate;
                        $scope.reverse = reverse;
                    };

                    $scope.order(null, 'last', !$scope.reverse);
                });


                $scope.origUsers = $scope.users;

            };

            $scope.getUserRole = function (user) {
                if (user.role.indexOf('_') > 0) {
                    return $filter('camelCase')(user.role.split('_')[1]);
                } else {
                    return user.role;
                }
            }
            $scope.userOptions = [
                {
                    label: 'Enabled',
                    value: 'Change to Enabled'
                },
                {
                    label: 'Disabled',
                    value: 'Change to Disabled'
                },
                {
                    label: 'Delete',
                    value: 'Delete'
                }
            ];

            $scope.userEditOption;

            $scope.defaultSelected = function () {
                var options = $('.filter__select').find('select');
                var firstOption = $(options).children()[0];
                $(options)[0].value = firstOption;
                $scope.userEditOption = undefined;
            };

            $scope.applyToSelected = function () {

                if (Object.keys($scope.checkedUsers).length != 0) { // Do nothing if no users are checked; set option to default


                    var method = $scope.userEditOption.label;
                    if (method === 'Enabled' || method === 'Disabled') {

                        for (var u in $scope.checkedUsers) {
                            for (var user in $scope.users) {
                                if (u === $scope.users[user].userId.toString()) {
                                    $scope.checkedUsers[u].statusTypeId = 3; // Invited
                                    if (method == 'Enabled') {
                                        $scope.checkedUsers[u].statusTypeId = 1; //Enabled
                                        //$scope.users[user].status = method;
                                    }
                                    if (method == 'Disabled') {
                                        $scope.checkedUsers[u].statusTypeId = 2; // Disabled
                                        //$scope.users[user].status = method;
                                    }
                                    $scope.checkedUsers[u].id = $scope.checkedUsers[u].userId;
                                    $scope.checkedUsers[u].customerId = applicationService.getInstitutionId();
                                    $scope.checkedUsers[u].roles = [$scope.checkedUsers[u].role];
                                    for (var i = 0; i < $scope.users.length; i++) {
                                        if ($scope.users[i].userId === u) {
                                            $scope.users[i].status = method;
                                        }
                                    }
                                }
                            }
                        }
                        $scope.checkedUserObjects = [];
                        for (var u in $scope.checkedUsers) {
                            $scope.checkedUserObjects.push($scope.checkedUsers[u]);
                        }
                        userService.changeUserStatus($scope.checkedUserObjects, successSaveHandler, failureSaveHandler);
                        $scope.clearValues();


                    } else if (method === 'Delete') {

                        swal({
                                title: "Are you sure?",
                                text: "Selecting yes will delete the " + Object.keys($scope.checkedUsers).length + " selected user(s)",
                                type: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#DD6B55",
                                confirmButtonText: "Yes, delete it!",
                                closeOnConfirm: true
                            },
                            function (isConfirm) {
                                if (isConfirm) { // User selects 'Yes'

                                    for (var u in $scope.checkedUsers) {
                                        //remove from ui
                                        for (var user in $scope.users) {
                                            if (u === $scope.users[user].userId.toString()) {
                                                $scope.users.splice(user, 1);
                                            }
                                        }
                                        //remove from db
                                        //$scope.deleteUser($scope.checkedUsers[u].userId, null, successSaveHandler, failureSaveHandler);

                                    }
                                    $scope.deleteUserList($scope.checkedUsers, null, successSaveHandler, failureSaveHandler);
                                    $scope.clearValues();

                                } else {  // User selects 'Cancel'

                                    // On sweetAlert Cancel, deselect the checked users
                                    for (var u in $scope.checkedUsers) {
                                        $('#' + u).click();
                                    }

                                    successSaveHandler();

                                    $scope.clearValues();


                                }
                            });

                        //for (var u in $scope.checkedUsers) {
                        //  //remove from ui
                        //  for (var user in $scope.users) {
                        //    if (u === $scope.users[user].userId.toString()) {
                        //      $scope.users.splice(user, 1);
                        //    }
                        //  }
                        //  //remove from db
                        //  //$scope.deleteUser($scope.checkedUsers[u].userId, null, successSaveHandler, failureSaveHandler);
                        //
                        //}
                        //$scope.deleteUserList($scope.checkedUsers, null, successSaveHandler, failureSaveHandler);


                    } else {


                        $scope.clearValues();
                    }

                } else {
                    $scope.clearValues();
                }
            };

            $scope.clearValues = function () {
                $scope.defaultSelected();

                $scope.checkedUsers = {};
                $scope.masterCheckBox = false;
            }

            $scope.checkedUsers = {};

            $scope.masterRetrieveCheckedUsers = function () {
                if ($scope.masterCheckBox === true) {
                    for (var i = 0; i < $scope.users.length; i++) {
                        var id = $scope.users[i].userId.toString();
                        if ($scope.checkedUsers[id] === undefined) {
                            $scope.checkedUsers[id] = $scope.users[i];
                        } else {
                            angular.noop;
                        }
                        // DOM manipulation for checklist
                        var usr = $('#' + $scope.users[i].userId);
                        if ($(usr).is(':checked')) {
                            angular.noop;
                        } else {
                            $(usr).click();
                        }
                    }
                } else {
                    $scope.checkedUsers = {};
                }
                // checked users updated

            };

            $scope.usersToChecked = function () {
                // Error: [$rootScope:inprog] $apply already in progress
                if (Object.keys($scope.users).length == 0) {
                    return false;
                }
                return Object.keys($scope.users).length === Object.keys($scope.checkedUsers).length;
            }

            $scope.checkAllUsers = function () {
                document.getElementsByClassName('userCheckBox').checked = true;

            };

            $scope.retrieveCheckedUsers = function (user) {
                var usr,
                    usrId;

                for (var u in $scope.users) {
                    if ($scope.users[u].userId === user.userId) {
                        usr = $scope.users[u];
                    }
                }

                usrId = usr.userId.toString();

                if ($scope.checkedUsers[usr.userId] === undefined) {
                    $scope.checkedUsers[usr.userId] = usr;
                } else {
                    delete $scope.checkedUsers[usr.userId];
                }


            };

            //$scope.getUserList();
            $scope.initialize();

            $scope.submitInfo = function (user) {



                //check if user(email) already exists for this institution
                var emailExists = false;
                $scope.users.forEach(function (u) {
                    if (u.email == user.email) {
                        emailExists = true;
                    }

                });

                if (emailExists) {
                    $scope.errors = {};
                    $scope.errors.email = 'A user with this email already exists'
                } else if (user.firstName == "") {
                    $scope.newuser.errors = {};
                    $scope.newuser.errors.firstName = 'First Name is required'
                }
                else if (user.lastName == "") {
                    $scope.newuser.errors = {};
                    $scope.newuser.errors.lastName = 'Last Name is required'
                }
                else if (user.email == "") {
                    $scope.newuser.errors.firstName = {};
                    $scope.newuser.errors.lastName = {};
                    $scope.newuser.errors = {};
                    $scope.errors = {};
                    $scope.errors.email = 'Email is required'
                }
                else if (!$scope.selectedItem) {
                    $scope.newuser.errors.firstName = {};
                    $scope.newuser.errors.lastName = {};
                    $scope.newuser.errors = {};
                    $scope.newuser.errors.selectedRole = 'Role is required'
                }
                else {
                  if (user.isPIIUser) {
                    displayConfirmationModal(user, true);
                  } else {
                    addSuccessHandler(user);
                  }
                }
            }
            var addSuccessHandler = function (user) {
                $scope.newuser.errors.firstName = {};
                $scope.newuser.errors.lastName = {};
                $scope.newuser.errors = {};

                //$scope.errors.email = {};
                //$scope.errors = {};

                validateEmailAddress(user.email);

                if ($scope.sendEmail == true) {
                    user.statusTypeId = '3';
                }
                else {
                    user.statusTypeId = '2';
                }
                user.role = "Viewer";

                $scope.selectedAuthority.push("Viewer");
                if ($scope.selectedAuthority.length > 0) {
                    if ($scope.selectedAuthority[0] == "Administrator") {
                        user.role = "Admin";
                    } else {
                        user.role = $scope.selectedAuthority[0];
                    }
                }
                $scope.selectedAuthority = [];

                if ($scope.errors.email == "" || $scope.errors == "" || $scope.errors.email == undefined || Object.keys($scope.errors).length == 0) {

                    $scope.toggle = false;
                    setTimeout(function () {
                        $('#addUserToggle').click();
                    }, 500);
                    userService.saveUser(user, $scope, successSaveHandler, failureSaveHandler);
                }
            }
          var displayConfirmationModal = function(user,isAdd){
            modalService.presentCustomModal({
                  title: '<div><i class="fa fa-check-circle-o"></i> Confirm Granting PII Access</div>',
                  text: '<div>I acknowledge that I am granting access to Personally Identifiable information (PII) to a user and that user will have access to student education records. I accept responsibility to preserve the privacy of all education records to the fullest extent required under any and all Federal and State laws and regulations including FERPA. I further acknowledge that I fully understand that the intentional and/or negligent disclosure by me of any Personally Identifiable Information to any unauthorized person could result in revocation of my access to AdvancED Products and potentially subject me to penalties under any applicable laws and/or regulations.</div>',
                  html: true,
                  cancelButtonText: 'I Accept',
                  confirmButtonText: 'Cancel',
                  showCancelButton: true,

                  customClass: 'manage-users-confirmation-modal',
                  allowEscapeKey: true
                }, function () {
                  // Nothing to execute
                },
                function () {
                  if (isAdd) {
                    addSuccessHandler(user);
                  } else {
                    editSuccessHandler(user);
                  }
                });
          }

          var validateEmailAddress = function validateEmail(fld) {
                //var noError = true;
                var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,20}(?:\.[a-z]{2})?)$/i;

                if (!re.test(fld)) {
                    if ($scope.errors.email != "required") {
                        $scope.errors.email = "invalid";
                    }
                    //noError = false;
                }
                //return noError;
            }

            $scope.editUser = function (user) {

                if (user.firstName == "") {
                    $scope.edituser.errors = {};
                    $scope.edituser.errors.firstName = 'First Name is required'
                }
                else if (user.lastName == "") {
                    $scope.edituser.errors = {};
                    $scope.edituser.errors.lastName = 'Last Name is required'
                }
                else if (user.email == "") {
                    $scope.edituser.errors = {};
                    $scope.edituser.errors.email = 'Email is required'
                }
                else if (!$scope.selectedRole) {
                    $scope.edituser.errors = {};
                    $scope.edituser.errors.selectedRole = 'Role is required'
                }
                else {
                  if(user.isPIIUser){
                    displayConfirmationModal(user,false);
                  }else{
                    editSuccessHandler(user);
                  }
                }
            }
          var editSuccessHandler = function(user){
            $scope.toggle = false;
                    setTimeout(function () {
                        $('#addUserToggle').click();
                    }, 500);


                    var getRole = function (role, isPII) {
                        if (isPII) {
                            if (role == userService.DISPLAY_ROLES.ADMIN || role == userService.DISPLAY_ROLES.ADMINISTRATOR) {
                                return [userService.ROLES.ROLE_ADMIN_PII];
                            } else if (role == userService.DISPLAY_ROLES.EDITOR) {
                                return [userService.ROLES.ROLE_EDITOR_PII];
                            } else if (role == userService.DISPLAY_ROLES.VIEWER) {
                                return [userService.ROLES.ROLE_VIEWER_PII];
                            }
                        } else {
                            if (role == userService.ROLES.ROLE_ADMIN_PII || role == userService.DISPLAY_ROLES.ADMIN || role == userService.DISPLAY_ROLES.ADMINISTRATOR) {
                                return [userService.ROLES.ROLE_ADMIN];
                            } else if (role == userService.ROLES.ROLE_EDITOR_PII || role == userService.DISPLAY_ROLES.EDITOR) {
                                return [userService.ROLES.ROLE_EDITOR];
                            } else if (role == userService.ROLES.ROLE_VIEWER_PII || role == userService.DISPLAY_ROLES.VIEWER) {
                                return [userService.ROLES.ROLE_VIEWER];
                            }
                        }
                    }

                    user.id = user.userId;
                    if ($scope.selectedAuthority.length > 0) {
                        user.roles = getRole($scope.selectedAuthority, user.isPIIUser);
                    } else {
                        user.roles = getRole(user.role, user.isPIIUser);
                    }
                    $scope.selectedAuthority = [];


                    user.customerId = applicationService.getInstitutionId();
                    userService.editUser(user, successSaveHandler, failureSaveHandler);

                    if ($scope.sendEditUserEmail == true) {

                        userService.checkUser(user.userId).$promise
                            .then(function (checkUserResponse) {

                                if (checkUserResponse.data) {
                                    $scope.emailData = [{
                                        "from": "app-noreply@advanc-ed.org",
                                        "to": user.email,
                                        "appName": "surveys",
                                        "institutionName": applicationService.getInstitutionName(),
                                        "templateName": "userInvited",
                                        "invited": user.firstName,
                                        "accountSetupUrl": my_journey_url_login // From constants.js
                                    }]
                                } else {
                                    $scope.emailData = [{
                                        "from": "app-noreply@advanc-ed.org",
                                        "to": user.email,
                                        "appName": "surveys",
                                        "institutionName": applicationService.getInstitutionName(),
                                        "templateName": "userInvited",
                                        "invited": user.firstName,
                                        "accountSetupUrl": checkUserResponse.userData.url
                                    }]
                                }
                                applicationService.sendEmail($scope.emailData)
                            })
                            .then(function (emailResponse) {
                                //successSaveHandler();
                                $scope.edituser = {};
                                $scope.sendEditUserEmail = false;
                                $scope.selectedAuthority = [];

                            });
                    }
                }
            

            var successSaveHandler = function () {

                resetUserDialog();
                $scope.newuser = {
                    firstName: '',
                    lastName: '',
                    email: '',
                    authorities: [{name: "Viewer", selected: "selected"},
                        {name: "Editor", selected: "unselected"},
                        {name: "Administrator", selected: "unselected"}],
                    isPIIUser: false,
                    errors: {}
                };
                $scope.edituser = {
                    firstName: '',
                    lastName: '',
                    email: '',
                    authorities: [{name: "Viewer", selected: "selected"},
                        {name: "Editor", selected: "unselected"},
                        {name: "Administrator", selected: "unselected"}],
                    isPIIUser: false,
                    errors: {}
                };

                $scope.users = [];
                $scope.getUserList();

            }

            var failureSaveHandler = function (error) {
                // error handler
                console.error(error);
            }


            //authorities($scope);

            $scope.sortOrder = 'ASC';
            $scope.sortedBy = 'name';
            $scope.filteredBy = 'All'
            //$scope.selectedAuthorities = {};


            $scope.toggleAuthority = function (authority) {
                $scope.selectedAuthority = [];
                if (authority) {
                    $scope.newuser.authorities.forEach(function (auth) {
                        if (auth.name == authority.name) {
                            auth.selected = "selected";
                            $scope.selectedAuthority.push(auth.name);
                        } else {
                            auth.selected = "unselected";
                        }
                    });
                }
            };

            //$scope.toggleEditAuthority = function(authority) {
            //  $scope.selectedEditAuthority = [];
            //  console.log('authority' + authority);
            //  $scope.edituser.authorities.forEach(function(auth){
            //    if(auth.name == authority.name){
            //      auth.selected = "selected";
            //      $scope.selectedEditAuthority.push(auth.name);
            //    }else{
            //      auth.selected = "unselected";
            //    }
            //  });
            //  console.log('selected authority = ' + $scope.selectedEditAuthority);
            //};

            $scope.deleteUser = function (userId, idx, successHandler, errorHandler) {

                swal({
                        title: "Are you sure?",
                        text: "Selecting yes will delete this user",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Yes, delete it!",
                        closeOnConfirm: true
                    },
                    function (isConfirm) {
                        
                        if (isConfirm) {
                            userService.deleteUser(userId).$promise.then(function (response) {
                                // success handler

                                successSaveHandler(response);
                                //$scope.getUserList();
                            }, function (error) {
                                // error handler
                                failureSaveHandler(error);

                            }, function (httpResponse) {
                                $timeout(errorPopup, 500);
                            });
                        }
                    });
            };


            $scope.deleteUserList = function (userList, idx, successHandler, errorHandler) {

                $scope.userListLength = Object.keys(userList).length;
                $scope.count = 0;

                for (var u in userList) {


                    userService.deleteUser(userList[u].userId).$promise.then(function (response) {
                        // success handler


                        ++$scope.count;

                        if ($scope.count == $scope.userListLength) {
                            successSaveHandler(response);
                        }

                        //$scope.getUserList();
                    }, function (error) {
                        // error handler
                        failureSaveHandler(error);

                    });

                }
                ;

            }

            $scope.toggleSortOrder = function (sortedBy) {
                $scope.sortedBy = sortedBy;
                if (_.isEqual($scope.sortOrder, 'ASC')) {
                    $scope.sortOrder = 'DESC';
                } else if (_.isEqual($scope.sortOrder, 'DESC')) {
                    $scope.sortOrder = 'ASC';
                }
                userService.getUsers($scope);
            };


            $scope.checkAuthority = function (authorities, testValue) {
                for (var authority in authorities) {
                    if (authorities.hasOwnProperty(authority)) {
                        if (authorities[authority] == testValue) {
                            return true;
                        }
                    }
                }
                return false;
            };


            $scope.addUser = function () {
                resetUserDialog();
                $scope.editUserFlag = false;
                $scope.sendEmail = true;
                $("#addUser").click();
            };

            //$scope.editUser = function(id){
            //  resetUserDialog();
            //  userService.getUserById(id, $scope);
            //  $scope.editUserFlag = true;
            //  $scope.sendEmail = false;
            //  $( "#addUser" ).click();
            //};

            $scope.cancelUser = function () {
                //resetUserDialog();
                //$( "#closeMenu" ).click();
                //$scope.setRightPanel(null, null);
                //$('#addUserToggle').click();
                $timeout(function () {
                    $('#addUserToggle').click();
                });
            }

            $scope.cancelUserFlyout = function () {
                //resetUserDialog();
                //$( "#closeMenu" ).click();
                //$scope.setRightPanel(null, null);
                //$('#addUserToggle').click();
                $scope.newuser = {
                    firstName: '',
                    lastName: '',
                    email: '',
                    authorities: [{name: "Viewer", selected: "selected"},
                        {name: "Editor", selected: "unselected"},
                        {name: "Administrator", selected: "unselected"}],
                    errors: {},
                    isPIIUser: false
                }
                $scope.errors = {};
            }


            $scope.getSelectedAuthorities = function () {
                var arr, authority, status, _ref;
                arr = [];
                _ref = $scope.selectedAuthorities;
                for (authority in _ref) {
                    status = _ref[authority];
                    if (status) {
                        arr.push(authority);
                    }
                }
                return arr;
            };


            $scope.createNewUser = function () {
                if ($scope.getSelectedAuthorities().length == 0) {
                    $scope.authorityError = "true";
                } else {

                    var newuser = {
                        'firstName': $scope.user.firstName,
                        'lastName': $scope.user.lastName,
                        'email': $scope.user.email,
                        'role': $scope.getSelectedAuthorities(),
                        'institution': $scope.user.institution,
                        'id': $scope.user.id,
                        'status': $scope.user.status
                    };


                    userService.saveUser(newuser, $scope); // Roles Permissions - this saves a new user. Since this controller appears
                    // to handle much of the user saving/editing/deleting functionality, this may be ok.
                }
            };

            $scope.searchSelect = function (data) {
                $scope.searchToken = data.name;
                $scope.users.length = 0;
                $scope.users.push(data);
            };

            $scope.searchUsers = function (keyword) {
                if (keyword == 1)
                    return [];
                var str = ""
                //var x =  $filter('filter')($scope.reports , {'name': keyword});
                //return x;
                return _.filter($scope.users, function (element) {
                    var check = false;
                    for (var key in element) {
                        if (element.hasOwnProperty(key)) {
                            if (key == "firstName" || key == "lastName" || key == "status" || key == "role") {
                                str = (element[key] + "").toLowerCase();
                                if (str.indexOf(keyword.toLowerCase()) != -1) {
                                    check = true;
                                    break;
                                }
                            }
                        }
                        continue;
                    }
                    if (check) return element;
                });
            }


            function resetUserDialog() {
                if ($scope.user) {
                    $scope.user = null;
                    $scope.blurFirstName = "false";
                    $scope.blurLastName = "false";
                    $scope.blurEmail = "false";
                    $scope.authorityError = "false";
                    $scope.selectedAuthorities = {};
                    $scope.userExistsException = "false";
                    $scope.exception = "false";
                }
            }

            $scope.editGroup = function () {

                var modalInstance = $modal.open({
                    templateUrl: 'partials/edit-group.html',
                    scope: $scope,
                    windowClass: 'reveal-modal small surveys__modal'
                });

                modalInstance.result.then(function (selectedItem) {
                    $scope.selected = selectedItem;
                }, function () {

                });

                $scope.closeEditGroup = function () {
                    modalInstance.close()
                }

            };

            $scope.setRightPanel = function (type, user) {


                if (type == 'add-user') {
                    $scope.sendEmail = true;
                    $scope.newuser.authorities = [{name: "Viewer", selected: "selected"},
                        {name: "Editor", selected: "unselected"},
                        {name: "Administrator", selected: "unselected"}]
                    $scope.newuser.isPIIUser = false;
                }

                if (user != null) {
                    $scope.users.forEach(function (u) {
                        if (u.userId == user.userId) {
                            user.email = u.email;
                            user.role = u.role;
                            user.status = u.status;
                            $scope.newuser.isPIIUser = user.isPIIUser;
                            if (userService.isAdmin(user)) {
                                $scope.newuser.authorities = [{name: "Viewer", selected: "unselected"},
                                    {name: "Editor", selected: "unselected"},
                                    {name: "Administrator", selected: "selected"}]
                                $scope.selectedRole = $scope.newuser.authorities[2];
                            } else if (userService.isEditor(user)) {
                                $scope.newuser.authorities = [{name: "Viewer", selected: "unselected"},
                                    {name: "Editor", selected: "selected"},
                                    {name: "Administrator", selected: "unselected"}]
                                $scope.selectedRole = $scope.newuser.authorities[1];
                            } else {
                                $scope.newuser.authorities = [{name: "Viewer", selected: "selected"},
                                    {name: "Editor", selected: "unselected"},
                                    {name: "Administrator", selected: "unselected"}]
                                $scope.selectedRole = $scope.newuser.authorities[0];
                            }
                        }
                    });

                    //if (user.status == 'Enabled') {
                    $scope.disableEmailEdit = true;
                    $scope.disableFirstNameEdit = true;
                    $scope.disableLastNameEdit = true;
                    //}
                    //else {
                    //$scope.disableEmailEdit = false;
                    //}

                    //$scope.toggleAuthority(user.role);

                    $scope.edituser = Object.assign({}, user);
                }
                ;
                $scope.rightPanel = type
            };

            $scope.files = [];

            $scope.onFileSelect = function ($files) {
                if ($files) { //$files: an array of files selected, each file has name, size, and type.
                    for (var i = 0; i < $files.length; i++) {
                        var file = $files[i];

                        $scope.upload = Upload.upload({
                            url: users_api_hostname + '/users/upload',
                            data: {institutionId: applicationService.getInstitutionId()},
                            file: file

                        }).progress(function (evt) {

                        }).success(function (data, status, headers, config) {
                            // file is uploaded successfully
                            if (data.SUCCESS) {

                                data.SUCCESS.forEach(function (user) {
                                    userService.checkUser(user.id).$promise
                                        .then(function (checkUserUploadResponse) {

                                            if (checkUserUploadResponse.data) {
                                                $scope.emailData = [{
                                                    "from": "app-noreply@advanc-ed.org",
                                                    "to": user.email,
                                                    "appName": "surveys",
                                                    "institutionName": applicationService.getInstitutionName(),
                                                    "templateName": "userInvited",
                                                    "invited": user.firstName,
                                                    "accountSetupUrl": my_journey_url_login // From constants.js
                                                }]
                                            } else {
                                                $scope.emailData = [{
                                                    "from": "app-noreply@advanc-ed.org",
                                                    "to": user.email,
                                                    "appName": "surveys",
                                                    "institutionName": applicationService.getInstitutionName(),
                                                    "templateName": "userInvited",
                                                    "invited": user.firstName,
                                                    "accountSetupUrl": checkUserUploadResponse.userData.url
                                                }]
                                            }
                                            applicationService.sendEmail($scope.emailData)
                                        })
                                        .then(function (emailResponse) {
                                            successSaveHandler();
                                            //$scope.edituser = {};
                                            //$scope.sendEditUserEmail = false;

                                        });
                                });
                                $rootScope.uploadusers = data.SUCCESS;
                                $('.off-canvas-wrap').removeClass('move-left');
                                $location.path('/upload-multiple-success').replace();
                            } else {
                                $rootScope.uploadusers = data.FAILED;
                                $scope.setRightPanel(null);
                                $('.off-canvas-wrap').removeClass('move-left');
                                $location.path('/upload-multiple-failed').replace();
                            }
                        });
                    }
                }
            };

            $scope.$watch('userEditOption', function (newValue, oldValue) {

            });
        }
    }
)();