app.controller('AssignParticipantsCtrl', ['$http', '$scope', '$rootScope', 'CertifiedContentService','applicationService', 'participantService', '$timeout','$interpolate','$routeParams', '$location', 'modalService',
    function($http, $scope, $rootScope, CertifiedContentService, applicationService, participantService,  $timeout, $interpolate, $routeParams, $location, modalService) {
    $scope.loading = false;
    $scope.$root.headerAction = 'hidden';
    $scope.participantCount = 0;
    $scope.qbAvailable = true;
    $scope.applied = false;
    $scope.edited = true;
    $scope.filterResultCount = $scope.participantCount;
    $scope.fetching = true;
    $scope.assigning = false;
    $scope.currentFilters = [];
    $scope.separatedInputModel = [];
    $scope.initialLoad = true;
    $scope.filtersFetched = false;
    $scope.savedFilters = null;
    $scope.previousFilters = null;
    $scope.backendFilterCriteria = null;
    $scope.changesPending = false;
    $scope.surveyId = $routeParams.surveyId;
    $scope.institutionId = $routeParams.institutionId;
    $scope.templateId = $routeParams.templateId;
    $scope.backActionLink = '#/edit-template/' + $scope.templateId;
    $scope.$root.headerAction = 'hidden';
    $scope.customCriteria = null;
    $scope.customRules = null;
    $scope.startedInApplied = false;
    $scope.criteriaFetched = false;
    $scope.applyEnabled = false;
    $scope.isFilteredRosterLocked = false;

    $scope.defaultRule = {
        id: "schoolLevel",
        operator: 'Includes',
        value: "",
        active: true
    };

    $scope.blankRule = {
        id: "-1",
        operator: 'Includes',
        value: "",
        active: true
    };

    $scope.testRule = {
        id: "",
        operator: 'Includes',
        value: "",
        active: true
    };

    /** Hard coded comparison string used for change detection */
    const blankSave = JSON.stringify([{"criterion":"","operator":"includes","valueList":""}]);

    /**
     * This is the API initializations
     */
    $scope.loadForInstitution = function() {
        fetchCriteria();
        fetchAvailableParticipants();
    };

    const commaInject = function(numericalValue){
        const commaPattern = /(\d)(?=(\d{3})+(?!\d))/g;
        const output = numericalValue.toString().replace(commaPattern, "$1,");
        return output;
    };

    $scope.commaInjectedFilteredCount = function(){
        return commaInject($scope.filterResultCount);
    };

    $scope.commaInjectAvailableCount = function() {
        return commaInject($scope.participantCount)
    }

    /**~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~**/

    /**
     * Will validate a list of student ids against an institution
     * @param surveyId
     * @param studentIds
     */
    const validateStudentIds = function(institutionId, studentIds, callbackData) {
        $scope.fetching = true;
        participantService.validateStudentIds(institutionId, studentIds, validateStudentIdsSuccess, validateStudentIdsFailure, callbackData);
    };

    const validateStudentIdsSuccess = function(response, callbackData) {
        $scope.fetching = false;
        // valueList was just a string array before
        const updatedValueList = [];
        if(response && response.students) {
            for (let i = 0; i < response.students.length; i++) {
                let studentX = response.students[i];
                updatedValueList.push({
                    value: studentX.studentId,
                    valid: studentX.valid
                })
            }
        }
        $scope.updateLastKnownValues(callbackData, updatedValueList);
    };

    const validateStudentIdsFailure = function(error) {
        $scope.fetching = false;
    };

    /**
     * Executes the GET criteria call to populate the builder filter options with
     */
    const fetchCriteria = function() {
        participantService.getFilterCriteria($scope.institutionId, fetchCriteriaSuccess, fetchCriteriaFailure);
    };

    /**
     * Handles the successful response from the GET criteria call
     * @param response
     */
    const fetchCriteriaSuccess = function(response) {
        $scope.customCriteria = FilterCriterion.parseCriteria(response.criteria);
        // fetch the existing filters
        $scope.backendFilterCriteria = transformFilterCriteria(response.criteria);
        $scope.builder.options.filters = $scope.backendFilterCriteria;
        $scope.criteriaFetched = true;
        fetchFilters();
    };

    /**
     * Handles the failure response for the GET criteria call
     * @param error
     */
    const fetchCriteriaFailure = function(error){
        $scope.criteriaFetched = true;
    };

    /**
     * Performs a transform on the backend filters model to convert them to the model used by the query buidler
     * @param backendCriteria
     * @returns {Array}
     */
    const transformFilterCriteria = function(backendCriteria) {
        const frontendCriteria = [];
        for( let i = 0; i < backendCriteria.length; i++ ) {
            let criterionX = backendCriteria[i];
            const newCriterionX = {
                id: criterionX.value,
                label: criterionX.label,
                operators: ['Includes', 'Excludes'],
                type: criterionX.valueType,
                placeholder_value: ''
            };

            switch (criterionX.type) {
                case 'multiselect':
                    newCriterionX.input = 'select';
                    newCriterionX.multiple = true;
                    newCriterionX.placeholder = 'Select value(s)';
                    newCriterionX.values = criterionX.valueList;
                    break;
                case 'userinput':
                    // This type of filter doesn't need a values property since the user inputs data directly
                    newCriterionX.input = 'separated-input';
                    newCriterionX.placeholder = 'Enter value(s)';
                    break;
                default:
                    newCriterionX.input = 'select';
                    newCriterionX.multiple = false;
                    newCriterionX.placeholder = 'Select value';
                    newCriterionX.values = criterionX.valueList;
                    break;
            }
            frontendCriteria.push(newCriterionX);
        }

        return frontendCriteria;
    };

    /**
     * Will modify a given set of filter data to match what the backend requires
     * @param filters
     * @returns {Array}
     */
    const transformToBackendFilters = function(filters) {
        const transformInputDependantValue = function(inputType, value, lkv) {
            if(inputType === "separated-input"){
                let tempVal = [];
                lkv.forEach(function(lkvNode){
                    tempVal.push(lkvNode.value);
                });
                return tempVal;
            } else {
                return typeof(value) === "object" ? value : [value]
            }
        };
        const beFilters = [];
        for( let i = 0; i < filters.length; i++){
            let filterX = filters[i];
            const newFilter = {
                criterion: filterX.filter.field,
                operator: filterX.operator.type.toLowerCase(),
                valueList: transformInputDependantValue(filterX.filter.input, filterX.value, filterX.flags.lastKnownValue)
            };
            beFilters.push(newFilter);
        }
        return beFilters;
    };

    const transformedCurrentToComp = function(filters) {
        const transformInputDependantValue = function(inputType, value, lkv) {
            if(inputType === "separated-input"){
                let tempVal = [];
                lkv.forEach(function(lkvNode){
                    tempVal.push(lkvNode.value);
                });
                return tempVal;
            } else {
                return typeof(value) === "object" ? value : [value]
            }
        };

        const beFilters = [];
        for( let i = 0; i < filters.length; i++){
            let filterX = filters[i];
            if(!filterX.filter){
                // exit early if found filter still in processing
                return beFilters;
            }
            const newFilter = {
                criterion: filterX.filter.field,
                operator: filterX.operator.type.toLowerCase(),
                valueList: transformInputDependantValue(filterX.filter.input, filterX.value, filterX.flags.lastKnownValue)
            };
            beFilters.push(newFilter);
        }
        return beFilters;
    };

    const setSavedFromCurrent = function() {
        const output = [];
        const transformForSaved = function(node) {
            return {
                active: false,
                id: node.filter.id,
                operator: node.operator.type,
                value: typeof node.value === "object" ? node.value : [node.value]
            }
        };
        for(let i = 0; i < $scope.currentFilters.length; i++){
            output.push(transformForSaved($scope.currentFilters[i]));
        }
        $scope.savedFilters = output;
    }

    const transformSavedToComp = function() {
        const beFilters = [];

        for( let i = 0; i < $scope.savedFilters.length; i++){
            let filterX = $scope.savedFilters[i];
            const newFilter = {
                criterion: filterX.id,
                operator: filterX.operator.toLowerCase(),
                valueList: filterX.value

            };
            beFilters.push(newFilter);
        }
        return beFilters;
    };
    /**~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~**/
    /**
     * Executes the GET all available participants call to populate the top count
     */
    const fetchAvailableParticipants = function() {
        participantService.getAvailableParticipants($scope.institutionId, fetchAvailableParticpantsSuccess, fetchAvailableParticpantsFailure);
    };

    /**
     * Handles the success response for fetching all available participants
     * @param response
     */
    const fetchAvailableParticpantsSuccess = function(response) {
        $scope.participantCount = response.availableParticipants || 0;
        $scope.initialLoad = false;
    };

    /**
     * Handles the failure response for fetching all available participants
     * @param error
     */
    const fetchAvailableParticpantsFailure = function(error){
        $scope.initialLoad = false;
    };
    /**~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~**/
    /**
     * Executes the GET all available participants call to populate the top count
     */
    const fetchFilteredParticipants = function() {
        $scope.filterResultCount = 0;
        participantService.getFilteredParticipants($scope.surveyId, fetchFilteredParticipantsSuccess, fetchFilteredParticipantsFailure);
    };

    /**
     * Handles the success response for fetching all available participants
     * @param response
     */
    const fetchFilteredParticipantsSuccess = function(response) {
        $scope.filterResultCount = response.filteredParticipants || 0;
    };

    /**
     * Handles the failure response for fetching all available participants
     * @param error
     */
    const fetchFilteredParticipantsFailure = function(error){
        modalService.presentErrorModal("There was an error retrieving filtered participant count. This is most likely a problem connecting to the server. Please try again later.");
    };
    /**~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~**/
    /**
     * Request the filters from the server
     */
    const fetchFilters = function() {
        $timeout(function(){
            $scope.filterResultCount = 0;
            $scope.savedFilters = null;
            participantService.getFilters($scope.surveyId, fetchFiltersSuccess, fetchFiltersFailure);
        });
    };
    /**
     * Success handler for filters after they are fetched
     * @param response
     */
    const fetchFiltersSuccess = function(response) {
        if(response.filters && response.filters.length > 0){
            $scope.startedInApplied = true;
            $scope.customRules = FilterBuilder.parseFilters(response.filters);
            // Go ahead and fetch the count since we have exiting filters we need to display
            fetchFilteredParticipants();
            // Transform filters from backend format to frontend format
            const transformFilters = function(backendFilters) {
                const fnUpper = function(inVal){
                    return inVal.toUpperCase();
                };
                const transformed = [];
                for(let i = 0; i < backendFilters.length; i++) {
                    let backendFilter = backendFilters[i];
                    transformed.push({
                        id: backendFilter.criterion,
                        operator: backendFilter.operator.replace(/^\w/, fnUpper),
                        value: backendFilter.valueList,
                        active: false
                    });
                }
                return transformed;
            };
            const transformed = transformFilters(response.filters);
            $scope.savedFilters = transformed;
            updateStateToApplied();
        } else {
            $scope.savedFilters = [$scope.testRule];
            $scope.edited = true;
            $scope.applied = false;
            $scope.changesPending = false;
            $scope.startedInApplied = false;
        }
        $scope.builder.options.rules.rules = $scope.savedFilters;
        $scope.fetching = false;
        $scope.filtersFetched = true;
    };
    /**
     * Failure handler for the fetch filters call
     * @param error
     */
    const fetchFiltersFailure = function(error){
        $scope.fetching = false;
        $scope.filtersFetched = true;
    };
    /**~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~**/
    /**
     * Execute apply filters
     */
    const applyFilters = function() {
        showProcessingModal();
        const transformedFilters = $scope.builder.builder.model.root && $scope.builder.builder.model.root.rules.length > 0 ? transformToBackendFilters($scope.builder.builder.model.root.rules) : [];
        participantService.applyFilters($scope.institutionId, $scope.surveyId, transformedFilters, applyFiltersSuccess, applyFiltersFailure);
    };
    /**
     * Success handler for apply filters call
     * @param response
     */
    const applyFiltersSuccess = function(response) {
        $scope.fetching = false;
        $scope.filterResultCount = response.filteredCount;
        // $scope.savedFilters = $scope.currentFilters;
        setSavedFromCurrent();
        modalService.close();
    };
    /**
     * Failure handler for apply filters call
     * @param error
     */
    const applyFiltersFailure = function(error) {
        $scope.fetching = false;
        modalService.presentErrorModal("Failed to save filters. Please try again");
    };

    /**~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~**/
    /**
     * Execute refresh filters call
     * Note: this is a scope ref while the other function is not since it is called in multiple places
     */
    $scope.refreshFilters = function() {
        refreshFilters();
    };

    /**
     * Actual execution of the refresh filters cal
     */
    const refreshFilters = function() {
        showProcessingModal();
        participantService.refreshFilters($scope.institutionId, $scope.surveyId, refreshFiltersSuccess, refreshFiltersFailure);
    };

    /**
     * Success handler for executing refresh filters
     * @param response
     */
    const refreshFiltersSuccess = function(response) {
        $scope.fetching = false;
        $scope.filterResultCount = response.filteredCount;
        modalService.close();
    };

    /**
     * Failure handler for executing refresh filters
     * @param error
     */
    const refreshFiltersFailure = function(error) {
        $scope.fetching = false;
    };
    /**~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~**/
    /**
     * Returns the appropiate class for the query builder to change it's display
     *  - based on the status of being edited or applied
     * @returns {string}
     */
    $scope.queryBuilderStatusClass = function() {
        return $scope.applied && !$scope.edited ? "applied" : "editing";
    };

    /**
     * Adding in an extension to the array prototype
     */
    if (!Array.prototype.last){
        Array.prototype.last = function(){
            return this[this.length - 1];
        };
    }

    /**
     * Defines the custom templates used for the QB
     * @returns {{}|templates}
     */

    const customTemplate = function() {
        const QueryBuilder = {
            templates : {}
        };

        /**
         * This partial template generates the container for all the rules as a group
         * @type {string}
         */
        QueryBuilder.templates.group = '\
<div id="{{= it.group_id }}" class="rules-group-container"> \
  <div class=rules-group-body> \
    <div class=rules-list></div> \
  </div> \
</div>';

        /**
         * This partial template generates the container for a rule (shows as a single row)
         * @type {string}
         */
        QueryBuilder.templates.rule = '\
<div id="{{= it.rule_id }}" class="rule-container has-error {{? it.active}}active{{??}}inactive{{?}}"> \
  <div class="rule-header"> \
    <div class="btn-group pull-right rule-actions"> \
      <button type="button" class="btn btn-xs btn-success add-rule" data-add="rule"> \
        <span class="{{= it.icons.add_rule }}"></span> \
      </button> \
      <button type="button" class="btn btn-xs btn-danger delete-rule" data-delete="rule"> \
        <span class="{{= it.icons.remove_rule }}"></span> \
      </button> \
    </div> \
  </div> \
  {{? it.settings.display_errors }} \
    <div class="error-container"><i class="{{= it.icons.error }}"></i></div> \
  {{?}} \
  <div class="rule-filter-container"></div> \
  <div class="rule-operator-container"></div> \
  {{? it.active }} \
    <div class="rule-value-container"></div> \
  {{?}} \
</div>';

        /**
         * This partial template generates the filters select dropdown
         * @type {string}
         */
        QueryBuilder.templates.filterSelect = '\
{{ var optgroup = null; }} \
    {{? it.rule.flags.showCondition }} \
        <h6 class="inactive-answer operator">and</h6> \
    {{?}} \
    <select class="form-control pretty {{? it.rule.flags.active}}active{{??}}inactive{{?}}" name="{{= it.rule.id }}_filter""> \
      <option value="-1">Select a Criterion</option> \
      {{~ it.filters: filter }} \
        <option value="{{= filter.id }}" {{? filter.icon}}data-icon="{{= filter.icon}}"{{?}}>{{= filter.label}}</option> \
      {{~}} \
      {{? optgroup !== null }}</optgroup>{{?}} \
    </select>';

        /**
         * This partial template generates the operator dropdown
         * @type {string}
         */
        QueryBuilder.templates.operatorSelect = '\
{{? it.operators.length === 1 }} \
<span> \
it.operators[0].type \
</span> \
{{?}} \
{{ var optgroup = null; }} \
<select class="form-control pretty {{? it.operators.length === 1 }}hide{{?}} {{? it.rule.flags.active}}active{{??}}inactive{{?}}" name="{{= it.rule.id }}_operator"> \
  {{~ it.operators: operator }} \
    <option value="{{= operator.type }}" {{? operator.icon}}data-icon="{{= operator.icon}}"{{?}}>{{= operator.type}}</option> \
  {{~}} \
</select>';

        /**
         * this partial template generates the value selection dropdown
         * @type {string}
         */
        QueryBuilder.templates.ruleValueSelect = '\
{{? it.rule.flags.active }} \
    <select class="form-control pretty {{? !it.rule.filter.multiple }}single-select{{?}}" name="{{= it.name }}" {{? it.rule.filter.multiple }}multiple{{?}}> \
      {{? !it.rule.filter.multiple }} \
        <option class="option-placeholder" value="PLACEHOLDER" selected>Select Value(s)</option> \
      {{?}} \
      {{~ it.rule.filter.values: entry }} \
        <option value="{{= entry.value }}">{{= entry.label }}</option> \
      {{~}} \
    </select> \
{{?}}';

        /**
         * This partial template generates the delimited user input field
         * @type {string}
         */
        QueryBuilder.templates.ruleValueSeparatedInput = '\
{{? it.rule.flags.active }} \
    <div class="prettydropdown classic arrow"> \
        <input class="form-control value-selector" name="{{= it.name }}" ng-model="separatedInputModel[0]" on-enter="splitSeparatedInput(event)" delimited-value-limit="validateDelimitedValues(event)" type="text" {{? it.rule.filter.placeholder}}placeholder="{{= it.rule.filter.placeholder}}"{{?}}/> \
    </div> \
{{?}}';

        /**
         * This partial template generates the editable value elements used by 'user-input' fields
         * @type {string}
         */
        QueryBuilder.templates.ruleValueEdit = '\
{{? it.rule.flags.lastKnownValue }} \
    {{? it.rule.flags.lastKnownValue.length }} \
        {{~ it.rule.flags.lastKnownValue: prevValue:index }} \
            <h6 class="inactive-answer value {{? it.rule.flags.active}}removable{{?}} {{? !prevValue.valid }}error{{?}}">{{= prevValue.label }} \
                {{? it.rule.flags.active }} \
                    <button type="button" class="btn btn-xs delete-value" data-delete="value" name="{{= prevValue.value}}"> \
                        <i class="{{= it.icons.remove_rule }}"></i> \
                    </button> \
                {{?}} \
            </h6> \
        {{~}} \
    {{?}} \
    {{? it.rule.flags.hasValueError===true && it.rule.filter.id === "studentId" }} \
        <div class="value-error-container"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i><span> One or more Student ID(s) could not be found.</div> \
    {{?}} \
{{?}}';

        return QueryBuilder.templates;
    };

    $scope.getModelIndex = function() {
        return $scope.separatedInputModel.length;
    };

    /**
     * Will remove all invalid student ids from the value list on row that is using the student id criterion
     * @param event
     */
    $scope.removeBadStudentIds = function(event) {
        const targetRuleValueName = event.target.name;
        const newRules = $scope.transformRulesForSet($scope.builder.builder.model.root.rules);
        const targetRules = newRules.rules.filter(function(ruleX){
            return ruleX.prevRuleId === targetRuleValueName;
        });
        if(targetRules.length > 0) {
            targetRules[0].flags.lastKnownValue = targetRules[0].flags.lastKnownValue.filter(function(lkv){
                return lkv.valid === true;
            });
            targetRules[0].flags.hasValueError = false;
        }
        forceUpdateRules(newRules);
        $scope.builder.builder.forceValueValidation();
    };

    /**
     * Will extract the individual value elements using the known delimiters
     * @param event
     */
    $scope.splitSeparatedInput = function(event) {
        const targetRuleValueName = event.target.name;
        const valueString = event.target.value;
        const ruleExtract = /^(qb\_[0-9]+\_rule\_[0-9]+).*$/;
        const targetRuleNameResults = ruleExtract.exec(targetRuleValueName);
        if(targetRuleNameResults && targetRuleNameResults.length > 1) {
            const targetRuleName = targetRuleNameResults[1];
            let valueList = valueString.trim().split(/[\s,;\.]+/);
            valueList = valueList.filter(function(value) {
                return value !== "";
            });
            if(valueList.length === 1 && valueList[0] === ""){

            } else {
                // Call validation API call
                validateStudentIds($scope.institutionId, valueList, targetRuleName);
            }
        }
    };

    $scope.validateDelimitedValues = function(event){
    };


    /**
     * Defines the base options for which the QB will be initialized
     * Note: this will be override once the GET API call returns valid data for an existing
     * rule set.
     * @type {{options: {icons: {add_group: string, add_rule: string, remove_group: string, remove_rule: string, error: string}, operators: *[], rules: {condition: string, rules: *[]}, filters: *[], allow_empty: boolean, allow_groups: boolean, select_placeholder: string, display_empty_filter: boolean, templates: {}|templates}, builder: {}}}
     */
    $scope.builder = {
        options: {
            icons: {
                'add_group' : 'icon-add',
                'add_rule' : 'icon-add',
                'remove_group' : 'icon-close',
                'remove_rule' : 'icon-close',
                'error' : 'fa fa-error'
            },
            operators: [
                {
                    type: 'Includes',
                    optgroup: 'basic',
                    nb_inputs: 1,
                    multiple: true,
                    apply_to: ['string']
                },{
                    type: 'Excludes',
                    optgroup: 'basic',
                    nb_inputs: 1,
                    multiple: true,
                    apply_to: ['string']
                }],
            rules: {
                condition: 'AND',
                rules: [$scope.blankRule]
            },
            filters: [],
            allow_empty: false,
            allow_groups: true,
            select_placeholder: 'Select a Criterion',
            display_empty_filter: true,
            templates: customTemplate()
        },
        builder: {},
        refs: {
            scope: $scope,
            interpolate: $interpolate
        }
    };

    $scope.transformRulesForSet = function(rules) {
        const outRuleSet = {
            rules: [],
            condition: "AND"
        };
        for(let i = 0; i < rules.length; i++) {
            let ruleX = rules[i];
            outRuleSet.rules.push({
                id: ruleX.filter ? ruleX.filter.id : 'schoolLevel',
                operator: ruleX.operator ? ruleX.operator.type : 'Includes',
                value: ruleX.value !== undefined ? ruleX.value : "",
                active: ruleX.active,
                flags: ruleX.flags,
                prevRuleId: ruleX.id
            })
        }
        $scope.activeRuleId = outRuleSet.rules.last().id;
        $scope.builder.builder.activeRuleId = $scope.activeRuleId;
        return outRuleSet;
    };

    $scope.transformRulesToFilters = function(rules) {
        const output = [];
        try {
            for(let i = 0; i < rules.length; i++) {
                let ruleX = rules[i];
                output.push({
                    id: ruleX.id,
                    filter: ruleX.filter,
                    value: ruleX.value,
                    operator: ruleX.operator,
                    flags: ruleX.flags
                });
            }
        } catch(ex) {
            console.error("Caught exception trying to transform rules: " + ex.message);
        }
        return output;
    };

    /**
     * Fetches the full output from QB
     * @returns {Object|{}}
     */
    $scope.getQuery = function() {
        return $scope.builder.getRules();
    }

    /**
     * Determines if the given rule ID matches the controller's active rule ID
     * @param ruleId
     * @returns {boolean}
     */
    $scope.isRuleActive = function(ruleId) {
        return ruleId === $scope.activeRuleId;
    }

    /**
     * Will modify a given list of rules and output them in an object sturcture that
     * can be used with the backend.
     * @param inRules
     * @returns {Array}
     */
    $scope.modifyRulesForBackend = function(inRules) {
        const outRules = [];
        for(let i = 0; i < inRules.length; i++) {
            let ruleX = inRules[i];
            outRules.push({
                order: outRules.length,
                category: ruleX.filter.id,
                type: ruleX.filter.type,
                operator: ruleX.operator.type,
                values: ruleX.flags.lastKnownValue
            })
        }
        return outRules;
    }

    /**
     * Will fetch the current rules and format them in a JSON representation
     * that is compatible with the backend
     * @returns {string}
     */
    $scope.getCurrentRules = function() {
        const results = $scope.modifyRulesForBackend($scope.currentFilters);
        return JSON.stringify(results, undefined, 2);
    }


    $scope.hasRules = function() {
        try {
            return $scope.builder.builder.model.root.rules.length > 1;
        } catch(err){
            return false;
        }
    }

    const updateStoredFilters = function() {
        if($scope.builder.builder.model.root) {
            $scope.currentFilters = $scope.transformRulesToFilters($scope.builder.builder.model.root.rules);
        }else {
            $scope.currentFilters = [];
        }
    }

    /**************************************************
     * API Functions
     **************************************************/
    const executeFilters = function() {
        updateStateToAppliedFetching();
        $timeout(function(){
            $scope.previousFilters = $scope.savedFilters;
            if(!$scope.savedFilters) {
                $scope.savedFilters = $scope.currentFilters;
            }
            applyFilters();
        });
    };

    /**
     * Will show a modal to the user indication that a filter related operation is in progress
     */
    const showProcessingModal = function() {
        $scope.fetching = true;
        modalService.presentCustomModal({
            title: "<i class='fa fa-hourglass-half'></i> Calculating",
            type: 'info',
            html: true,
            text: "<strong>Please allow a moment to calculate the filter total.</strong><br><br>This process can take up to 2 minutes.",
            customClass: 'calculateParticipantsAlert',
            confirmButtonColor: '#148900',
            confirmButtonText: 'OK',
            showCancelButton: false,
            cancelButtonText: "Cancel",
            cancelButtonColor: "#D0D0D0"
        }, null, function(){
            $scope.savedFilters = $scope.previousFilters;
            applyFilters();
        })
    };

    const confirmAssignment = function(participantCount, yesCB, noCB) {
        modalService.presentCustomModal({
            title: "<i class='fa fa-users'></i> Participant Assignment",
            type: 'info',
            html: true,
            text: "<strong>[ "+participantCount+" ]</strong> participants match your applied filters.<br><br>Select \"Yes\" to assign these participants to your survey.",
            customClass: 'confirmAssignAlert',
            confirmButtonColor: '#148900',
            confirmButtonText: 'Yes',
            showCancelButton: true,
            cancelButtonText: "Cancel",
            cancelButtonColor: "#D0D0D0"
        }, yesCB, noCB);
    };

    $scope.assignFilters = function() {
        $scope.assigning = true;
        let assignCount = $scope.commaInjectedFilteredCount();
        if(hasNoSavedFilters()){
            assignCount = $scope.commaInjectAvailableCount();
        }
        confirmAssignment(assignCount, function() {
            participantService.assignFilters($scope.institutionId, $scope.surveyId, assignFiltersSuccess, assignFiltersError);
        }, function() {
            $timeout(function(){
                $scope.assigning = false;
                $scope.$apply();
            });
            $scope.assigning = false;
        });
    };

    const hasNoSavedFilters = function() {
        return !$scope.savedFilters || ($scope.savedFilters.length >= 0 && $scope.savedFilters.length <= 1 &&
            $scope.savedFilters[0] == $scope.testRule);
    }

    const assignFiltersSuccess = function(response) {
        $scope.assigning = false;
        $timeout(function(){
            $scope.$apply();
        });
        $scope.goBackToEdit(true);
    };

    $scope.goBackToEdit = function(assigned){
        if(!assigned) {
            $location.url("/edit-template/" + $scope.templateId);
        } else {
            $location.url("/edit-template/" + $scope.templateId + "?assignmentsConfirmed=true");
        }
    };

    const assignFiltersError = function(error) {
        $scope.assigning = false;
        $timeout(function(){
            $scope.$apply();
        });
    };

    $scope.downloadEntireRoster = function() {
        participantService.downloadAll($scope.institutionId, applicationService.getApplicationId(), $scope.surveyId,
            participantService.presentReportRequestSuccessModal, participantService.presentReportRequestFailureModal);
    };

    $scope.downloadFilteredRoster = function() {
        const transformedFilters = transformToBackendFilters($scope.builder.builder.model.root.rules);
        participantService.downloadFiltered($scope.institutionId, applicationService.getApplicationId(), $scope.surveyId, transformedFilters,
            participantService.presentReportRequestSuccessModal, participantService.presentReportRequestFailureModal);
    };

    /**************************************************
     * State checks
     **************************************************/
    $scope.isStateApplied = function(){
        return $scope.applied && !$scope.edited;
    };

    $scope.isStateAppliedEditing = function() {
        return $scope.applied && $scope.edited;
    };

    $scope.isStateEditing = function() {
        return $scope.edited;
    };

    /**
     * State Change Functions
     */
    const removeActiveRules = function() {
        const newRules = $scope.transformRulesForSet($scope.builder.builder.model.root.rules);
        const numRules = newRules.rules.length;
        const reducedRules = [];
        for(let i = 0; i < numRules; i++){
            if(!newRules.rules[i].flags.active){
                reducedRules.push(newRules.rules[i]);
            }
        }
        newRules.rules = reducedRules;
        forceUpdateRules(newRules);
        updateLoop();
    };

    const addActiveRule = function(tempRules) {
        return tempRules.rules.push($scope.blankRule);
    };

    /**
     * Will update the controller state & apply all the current filters
     */
    $scope.applyFilters = function() {
        $scope.isFilteredRosterLocked = true;
        removeActiveRules();
        executeFilters();
        $timeout(function() {
            $scope.isFilteredRosterLocked = false;
        },5000);
    };

    const updateStateToApplied = function() {
        let updateRules = false;
        if($scope.builder.builder.model) {
            $scope.tempRules = $scope.transformRulesForSet($scope.builder.builder.model.root.rules);
            // Update active states
            for (let i = 0; i < $scope.tempRules.rules.length; i++) {
                let ruleX = $scope.tempRules.rules[i];
                ruleX.flags.active = false;
                ruleX.active = false;
            }
            updateRules = true;
        }

        $scope.applied = true;
        $scope.edited = false;
        $scope.fetching = false;
        if(updateRules) {
            forceReinitRules($scope.tempRules);
            $scope.builder.builder.reApplyOperators($scope.tempRules);
        }

    };

    const updateStateToEditing = function() {
        $scope.applied = false;
        $scope.edited = true;
        $scope.fetching = false;
        $scope.changesPending = false;
        $scope.startedInApplied = false;
        $scope.tempRules = $scope.builder.builder.model.root && $scope.builder.builder.model.root.rules ? $scope.transformRulesForSet($scope.builder.builder.model.root.rules) : {rules: []};
        // Update active states
        for(let i = 0; i < $scope.tempRules.rules.length; i++) {
            let ruleX = $scope.tempRules.rules[i];
            ruleX.flags.active = false;
            ruleX.active = false;
        }
        $scope.tempRules.rules.push($scope.blankRule);
        forceUpdateRules($scope.tempRules);
        updateLoop();

    };

    const cancelStateEditing = function() {
        $scope.applied = true;
        $scope.edited = false;
        $scope.fetching = false;
        if($scope.tempRules && $scope.tempRules.rules && $scope.tempRules.rules.length > 0) {
            console.log("Cancel state editing - 1st case");
            $scope.tempRules.rules = $scope.tempRules.rules.filter(function (ruleX) {
                return ruleX.id !== "-1";
            });
            forceUpdateRules($scope.tempRules);
        } else if(!$scope.tempRules) {
            $scope.savedFilters = [$scope.testRule];
            $scope.edited = true;
            $scope.applied = false;
            $scope.changesPending = false;
            $scope.startedInApplied = false;
            $scope.fetching = false;
            $scope.filtersFetched = true;
            forceUpdateRules($scope.savedFilters);
        } else {
            console.warn("Unknown filter state");
        }
        updateLoop();

    };

    const updateStateToFetching = function() {
        $scope.fetching = true;

    };

    const updateStateToAppliedFetching = function() {
        $scope.fetching = true;
        $scope.edited = false;
        $scope.applied = true;

    };

    /**
     * Will update the controller state to start a new edit
     */
    $scope.editFilters = function() {
        updateStateToEditing();
    };

    /**
     * Will update the controller state to cancel the current edit
     */
    $scope.cancelEditFilters = function() {
        $scope.edited = false;
        // console.warn("Cancel Edit Filters");
        cancelStateEditing();
    };

    /**************************************************
     * Update functions
     **************************************************/

    /**
     * Update loop to ensure angular is getting updates from QB
     * **/
    const updateLoop = function() {
        updateStoredFilters();

    };

    /**
     * Forces updated rules into the QB to re-render
     * @param newRules
     */
    const forceUpdateRules = function(newRules){
        $scope.builder.builder.clear();
        $scope.builder.builder.setRules(newRules);
    };

    const forceReinitRules = function(newRules) {
        $scope.filtersFetched = false;
        $scope.builder.options.rules.rules = newRules;
        $scope.filtersFetched = true;
    };

    /************************************************************************************
     * Query Builder Event Handlers
     ************************************************************************************/

    /**
     * Fires off when nearly anything is modified
     */
    $scope.$on('QueryBuilderValueChanged', function() {
        updateLoop();
        updateAppliedEnablement();
    });

    /**
     * Fires off when a rule is added to the set
     */
    $scope.$on('QueryBuilderAddedRule', function() {
        const previousFilters = $scope.savedFilters;
        const newFilters = $scope.builder.builder.model.root.rules;
        $scope.changesPending = previousFilters !== newFilters;
        $scope.separatedInputModel = [];
        updateLoop();
        updateAppliedEnablement();
    });

    /**
     * Fires off when the QB is alerting parent controller that
     * the state of each rule needs to be updated
     * - Happens after adding a rule
     */
    $scope.$on('QueryBuilderStatesNeedUpdate', function() {
        const existingRules = $scope.transformRulesForSet($scope.builder.builder.model.root.rules);
        const newRules = $scope.transformRulesForSet($scope.builder.builder.model.root.rules);

        const numRules = existingRules.rules.length;
        for(let i = 0; i < numRules; i++){
            if(i < numRules - 1){
                newRules.rules[i].active = false;
                newRules.rules[i].flags.filter_readonly = true;
                newRules.rules[i].flags.operator_readonly = true;
                newRules.rules[i].flags.value_readonly = true;
                // console.debug("Rule["+i+"] Values: " + JSON.stringify(newRules.rules[i].value));
                // console.debug("Existing LKV: " + JSON.stringify(newRules.rules[i]));
                if(!newRules.rules[i].flags.lastKnownValue) {
                    newRules.rules[i].flags.lastKnownValue = convertValuesToLastKnown(newRules.rules[i].id, Array.isArray(newRules.rules[i].value) ? newRules.rules[i].value : [newRules.rules[i].value]);
                    // console.debug("Updated LKV: " + JSON.stringify(newRules.rules[i]));
                }else{
                    // console.debug("NO UPDATE TO LKV: ");
                }
                // console.debug("Rule["+i+"] LKV: " + JSON.stringify(newRules.rules[i].value));
                newRules.rules[i].flags.active = false;

                if(numRules > 1 && i > 0) {
                    newRules.rules[i].flags.showCondition = true;
                }else {
                    newRules.rules[i].flags.showCondition = false;
                }
            }else {
                newRules.rules[i].id = "-1";
                newRules.rules[i].active = true;
                newRules.rules[i].value = [""];
                newRules.rules[i].operator = "Includes";
                if(numRules > 1 && i > 0) {
                    newRules.rules[i].flags.showCondition = true;
                }else {
                    newRules.rules[i].flags.showCondition = false;
                }
            }
        }
        if(!angular.equals(newRules, existingRules)) {
            // console.debug("Needs Update Rules: " + JSON.stringify(newRules));
            forceUpdateRules(newRules);
        }
        updateLoop();
        // console.log("QueryBuilderStatesNeedUpdate - DONE - Apply Disabled: " + !$scope.hasRules());
        updateAppliedEnablement();
    });

    /**
     *
     * @param filterId
     * @param values
     * @returns {Array}
     */
    const convertValuesToLastKnown = function(filterId, values) {
        const srcFilter = $scope.builder.options.filters.find(function(filterX){
            return filterX.id === filterId;
        });
        const actualValues = [];
        if(values && srcFilter) {
            for (let i = 0; i < values.length; i++) {
                let valContainerX = values[i];
                if (typeof valContainerX !== 'object') {
                    const filteredSrcValues = srcFilter.values.filter(function(valX) {
                        return valX.value === valContainerX;
                    });
                    actualValues.push({
                        value: valContainerX,
                        label: filteredSrcValues && filteredSrcValues.length > 0 ? filteredSrcValues[0].label || filteredSrcValues[0].value : valContainerX,
                        valid: true
                    });
                } else {
                    if (!valContainerX.hasOwnProperty('valid')) {
                        valContainerX.valid = true;
                    }
                    actualValues.push({
                        value: valContainerX.value,
                        label: srcFilter.values ? srcFilter.values[valContainerX.value] : valContainerX.value,
                        valid: valContainerX.valid
                    });
                }
            }
        }
        return actualValues;
    }

    /**
     * Fires off when a rule is removed from the set
     */
    $scope.$on('QueryBuilderRemovedRule', function() {
        // check and see if the first item needs to have it's condition hidden
        const newRules = $scope.transformRulesForSet($scope.builder.builder.model.root.rules);
        if(newRules.rules.length > 0){
            if(newRules.rules[0].flags.showCondition) {
                newRules.rules[0].flags.showCondition = false;
                forceUpdateRules(newRules);
            }
        }
        updateLoop();
        updateAppliedEnablement();
    });

    /**
     * Fires off when the user is attempting to delete a specific value from an
     * existing rule
     */
    $scope.$on('QueryBuilderNeedsValueDeletion', function(evt, data) {
        const newRules = $scope.transformRulesForSet($scope.builder.builder.model.root.rules);
        const rules = newRules.rules;
        // Find the rule that needs value deletion
        const targetRule = rules.filter(function(ruleX){
            return ruleX.prevRuleId === data.ruleId;
        });
        if(targetRule && targetRule.length > 0) {
            // check to see if the target rule even has values, or it we should be using last known value
            if (targetRule[0].value && targetRule[0].value.length > 0) {
                // Find the index of the value that needs to be removed
                const valueIndex = targetRule[0].value.indexOf(data.value);
                // Removed the desired value
                targetRule[0].value.splice(valueIndex, 1);

            } else {
                const valueIndex = targetRule[0].flags.lastKnownValue.findIndex(function(valueX){
                    return valueX.value === data.value;
                });

                if(valueIndex >= 0) {
                    targetRule[0].flags.lastKnownValue.splice(valueIndex, 1);
                }
                const errors = targetRule[0].flags.lastKnownValue.filter(function(lkv) {
                    return (lkv.hasOwnProperty("valid") && lkv.valid === false) || false;
                });
                targetRule[0].flags.hasValueError = errors.length > 0 || targetRule[0].flags.lastKnownValue.length === 0;
            }

            if(rules.length === 0) {
                // add back in default filter
                rules.push($scope.blankRule);
            }
            forceUpdateRules(newRules);
            $scope.builder.builder.forceValueValidation();
        }
        updateAppliedEnablement();
    });
    
    $scope.updateLastKnownValues = function (ruleId, values) {
        // console.log("AssignParticipantsCtrl - Update Last Known Values - START");
        const newRules = $scope.transformRulesForSet($scope.builder.builder.model.root.rules);
        const rules = newRules.rules;
        // Find the rule that needs to be updated
        const targetRule = rules.filter(function(ruleX){
            return ruleX.prevRuleId === ruleId;
        });
        // Set current value list to blank so it removes them from the input
        // console.debug("Target Rule Value: " + JSON.stringify(targetRule[0].value));
        targetRule[0].value = [];
        // Update the rule's last known values list

        if(!targetRule[0].flags.lastKnownValue) {
            targetRule[0].flags.lastKnownValue = convertValuesToLastKnown(targetRule[0].id, values);
        }else {
            targetRule[0].flags.lastKnownValue = targetRule[0].flags.lastKnownValue.concat(convertValuesToLastKnown(targetRule[0].id, values));
        }
        // RUN DEDUPLICATION
        targetRule[0].flags.lastKnownValue = targetRule[0].flags.lastKnownValue.filter(function(thing, index, self) {
            return index === self.findIndex(function (t) {
                return t.value === thing.value && t.label === thing.label;
            })
        });

        // console.debug("Target Rule LKV: " + JSON.stringify(targetRule[0].flags.lastKnownValue));

        const errors = targetRule[0].flags.lastKnownValue.filter(function(lkv) {
            return (lkv.hasOwnProperty("valid") && lkv.valid === false) || false;
        });
        targetRule[0].flags.hasValueError = errors.length > 0;

        // console.log("AssignParticipantsCtrl - LAST KNOWN VALUE: " + JSON.stringify(targetRule[0].flags.lastKnownValue));
        // console.log("AssignParticipantsCtrl - Update Last Known Values - END");
        forceUpdateRules(newRules);
    };

    /**
     * Fires off when the QB needs the parent controller to refresh the view
     */
    $scope.$on('QueryBuilderNeedsReRender', function() {
        // Just forces a re-render cycle
        updateLoop();
    });

    $scope.afterLoadDropdowns = function(arg) {
        // console.log("After Load Dropdowns: " + JSON.stringify(arg));
    };

    $scope.initDropdowns = function() {
        $(document).ready(function(){
            const dropdownOptions = {
                classic: true,
                hoverIntent: 30000000000,
                multiVerbosity: 99999,
                height: 38,
                multiDelimiter: ', ',
                afterLoad: $scope.afterLoadDropdowns
            };
            const elements = $('select.pretty');
            elements.prettyDropdown(dropdownOptions);
        })
    }

    $scope.filterBuilderInit = function(e){
        console.log("filterBuilderInit: e = "+ JSON.stringify(e));
    }

    const updateAppliedEnablement = function() {
        const trimmedCurrentActive = $scope.currentFilters.filter(function(item) {
            return item.flags.active !== true;
        });
        const currentCompRules = transformedCurrentToComp(trimmedCurrentActive);
        const savedCompRules = transformSavedToComp($scope.savedFilters);

        const currentStr = JSON.stringify(currentCompRules);
        const savedStr = JSON.stringify(savedCompRules);

        const hasFreshSaveData = savedStr === blankSave && currentStr !== savedStr && $scope.hasRules();
        const hasOldSaveData = savedStr !== blankSave && currentStr !== savedStr;

        // doesn't have any rules and
        $timeout(function() {
            $scope.applyEnabled = hasFreshSaveData || hasOldSaveData;
        })
    }
}
]);


app.directive('onEnter', function () {
    return function (scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
            if(event.which === 13) {
                // console.warn('Caught enter');
                scope.$apply(function (){
                    scope.$eval(attrs.onEnter, {event: event});
                });

                event.preventDefault();
            }
        });
    };
});

app.directive('delimitedValueLimit', function() {
    return function (scope, element, attrs) {
        element.bind("keypress", function (event) {
            // validate with regex
            // if doesn't match, prevent default and exit
            if(event.which === 13){
                return;
            }
            const pendingValue = event.currentTarget.value + event.key;
            if(pendingValue) {
                const idExtract = /([a-zA-Z0-9]{11,}|[^a-zA-Z0-9\s,;])/g;
                const delimitedResults = idExtract.exec(pendingValue);
                if(delimitedResults && delimitedResults.length > 0){
                    // User entered something invalid or is at the limit of
                    // console.log("USER INPUT PREVENTED - BAD CHARACTER")
                    event.preventDefault();
                } else {
                    // User entered valid value, let them continue
                    // Validate pending value
                }
            }
        });
    };
});