app.directive('dashboardActivityTabset', ['$filter', '$timeout', 'SurveyService', function($filter, $timeout, SurveyService) {
    return{
        restrict: 'E',
        templateUrl: 'partials/dashboard/dashboardActivityTabset.html',
        scope: {
          surveySectionActivity: "=",
          surveyDemographicActivities: "=",
          activeSurvey: "=",
        },
        link: function(scope,element,attrs){
          scope.retrievedDemographicNames = [];
          scope.retrievedDemographics = [];
          scope.totalSectionCountExceedsThreshold = false;
          scope.activityLoading = true;

          // Activate Section
          scope.activateSection = function(scale) {
            var sectionActivity = scope.surveySectionActivity;
            scope.sectionActivity = sectionActivity;

            angular.forEach(sectionActivity, function(value, index){
                if (! scope.totalSectionCountExceedsThreshold) scope.totalSectionCountExceedsThreshold = sectionActivity.sectionCountExceedThreshold;
                sectionActivity[index].sectionAverage = $filter('number')(sectionActivity[index].sectionAverage, 2);
                sectionActivity[index].sectionOptions = sectionActivity[index].sectionNetworkAverage != null ?  angular.copy(dashboard_activity_section_chart_options) : angular.copy(dashboard_activity_section_chart_options2);
                sectionActivity[index].sectionScore =
                        sectionActivity[index].sectionNetworkAverage != null ?
                    [
                        {'sectionAverage':sectionActivity[index].sectionAverage, 'value': scale,'sectionNetworkAverage':sectionActivity[index].sectionNetworkAverage}
                    ]
                    :
                    [
                        {'sectionAverage':sectionActivity[index].sectionAverage, 'value': scale}
                    ];
            });

            $('#activityDetailsLoader').addClass('ng-hide');
            scope.activityLoading = false;
          }

          scope.sectionTooltip = function(networkAverage) {
              if (networkAverage && networkAverage > 0)
              {
                  return ("Network Average " + networkAverage);
              }
          }

          scope.activateDemographic = function(demographicType, demographic) {
            scope.demographicActivity = {};
            if (scope.retrievedDemographicNames.length == 0 || scope.retrievedDemographicNames.indexOf(demographic) == -1) {
                $timeout(function() {
                    scope.retrieveDemographics(demographicType, demographic);
                }, 0);
              //scope.retrieveDemographics(demographicType, demographic);
            } else {
              scope.retrieveDemographicsFromScope(demographicType, demographic);
            }
          }

          scope.retrieveDemographics = function(demographicType, demographic) {
            if (demographicType == 'Schools') {
                scope.paintSchoolData(demographicType, demographic);
            } else {
              scope.paintDemographicData(demographicType, demographic);
            }
          }

          scope.paintDemographicData = function(demographic, demographicActivity) {
              scope.applyPaddingToDemographic(demographicActivity);
              scope.demographicActivity = demographicActivity;
              scope.retrievedDemographicNames.push(demographic);
              scope.retrievedDemographics.push({
                      name: demographic,
                      activity: demographicActivity
                  }
              )
          }

          scope.paintSchoolData = function(demographic, demographicActivity) {
              var institutions = [];
              for (var activityInd in demographicActivity.detailActivityList)
              {
                  var institution = {};
                  institution.id = demographicActivity.detailActivityList[activityInd].detailType;
                  institutions.push(institution);
              }
              SurveyService.institutionForIds(0, institutions, scope.paintSchoolDataWithName, demographic, demographicActivity );
          }

          scope.paintSchoolDataWithName = function(institutions, demographic, demographicActivity) {
              for (var activityInd in demographicActivity.detailActivityList) {
                  demographicActivity.detailActivityList[activityInd].detailType = institutions[activityInd].name;
              }
              scope.paintDemographicData(demographic, demographicActivity);
          }

          scope.retrieveDemographicsFromScope = function(demographic, surveyId, responseCount) {

              var keepGoing = true;
              angular.forEach(scope.retrievedDemographics, function (value, index) {
                  if (keepGoing) {
                      if (scope.retrievedDemographics[index].name == demographic) {
                          scope.demographicActivity = scope.retrievedDemographics[index].activity;

                          keepGoing = false;
                      }
                  }
              });
              if (keepGoing) scope.retrieveDemographics(demographic, surveyId, responseCount);
          }

          scope.applyPaddingToDemographic = function(demographicActivity) {
              angular.forEach(demographicActivity.detailActivityList, function (value, index) {
                  //setup for UI manipulation of bar graph, used in the template
                  demographicActivity.detailActivityList[index].rightOffset = 1; //default - maps to CSS selector

                  //tweak the CSS selectors based on response count vis-a-vis response percentage
                  if (demographicActivity.detailActivityList[index].responseCount <= 99) {
                      if (demographicActivity.detailActivityList[index].responsePercentage <= 15)//threshold for < 100 responses
                      {
                          demographicActivity.detailActivityList[index].displayCountOutside = true;
                          demographicActivity.detailActivityList[index].rightOffset = 2;

                      } else demographicActivity.detailActivityList[index].displayCountOutside = false;
                  }
                  else if (demographicActivity.detailActivityList[index].responseCount <= 999)//threshold for < 1000 responses
                  {
                      if (demographicActivity.detailActivityList[index].responsePercentage <= 20) {
                          demographicActivity.detailActivityList[index].displayCountOutside = true;
                          demographicActivity.detailActivityList[index].rightOffset = 3;

                      } else demographicActivity.detailActivityList[index].displayCountOutside = false;//threshold for < 10000 responses
                  }
                  else if (demographicActivity.detailActivityList[index].responseCount <= 9999) {
                      if (demographicActivity.detailActivityList[index].responsePercentage <= 25) {
                          demographicActivity.detailActivityList[index].displayCountOutside = true;
                          demographicActivity.detailActivityList[index].rightOffset = 4;

                      } else demographicActivity.detailActivityList[index].displayCountOutside = false;//threshold for < 100000 responses
                  }
                  else if (demographicActivity.detailActivityList[index].responseCount <= 99999) {
                      if (demographicActivity.detailActivityList[index].responsePercentage <= 30) {
                          demographicActivity.detailActivityList[index].displayCountOutside = true;
                          demographicActivity.detailActivityList[index].rightOffset = 5;

                      } else demographicActivity.detailActivityList[index].displayCountOutside = false;
                  }
              });
          }


          scope.$watch('activeSurvey', function(newValue, oldValue) {
              if (newValue !== oldValue) {

                  scope.retrievedDemographicNames = [];
                  scope.retrievedDemographics = [];
                  console.log("Calling activate Section now");
                  scope.activateSection(newValue.scale);
              }
          });
        }
    }
}]);
