(function(){
        'use strict';
        angular.module('echo')
            .controller('CreateReportsWizardStep2Ctrl', CreateReportsWizardStep2Ctrl);

        CreateReportsWizardStep2Ctrl.$inject = ['$scope', '$modalInstance'];

        function CreateReportsWizardStep2Ctrl($scope, $modalInstance){
            $scope.reportPreferences.prevStepNum = 2;
            if (!$scope.reportPreferences.fromDate && !$scope.reportPreferences.fromDateCleared)$scope.reportPreferences.fromDate = $.datepicker.formatDate("mm/dd/yy", new Date()); //initialize dates if the user preferences not set
            if (!$scope.reportPreferences.toDate && !$scope.reportPreferences.toDateCleared)$scope.reportPreferences.toDate = $.datepicker.formatDate("mm/dd/yy", new Date());
            $scope.dateRangeInvalid = false;
            $scope.formPreferences = {};
            var navigationPreference = {};

            $scope.next = function() {
                navigationPreference.skipAhead = false;
                navigationPreference.previous = false;
                if (($scope.reportPreferences.fromDate != null) && ($scope.reportPreferences.fromDate.length == 0)){
                    //prior to proceeding to the next step, check if user cleared from date manually
                    $scope.reportPreferences.fromDate = null;
                    $scope.reportPreferences.fromDateCleared = true;
                }
                if (($scope.reportPreferences.toDate != null) && ($scope.reportPreferences.toDate.length == 0)) {
                    //prior to proceeding to the next step, check if user cleared to date manually
                    $scope.reportPreferences.toDate = null;
                    $scope.reportPreferences.toDateCleared = true;
                }


                $modalInstance.close(navigationPreference);
            }

            $scope.cancel = function () {
                $modalInstance.dismiss('cancel');
            };

            $scope.prev = function() {
                navigationPreference.skipAhead = false;
                navigationPreference.previous = true;
                $modalInstance.close(navigationPreference);
            }

            $scope.skipAhead = function(){
                navigationPreference.skipAhead = true;
                $modalInstance.close(navigationPreference);
            }

            $scope.dateOptions = {
                inline: true,
                prevText: 'h',
                nextText: 'i'
            };

            $scope.dateOptions2 = {
                inline: true,
                prevText: 'h',
                nextText: 'i'
            };

            $scope.clearFromDate = function(from){
                if (from){
                    $scope.reportPreferences.fromDate = null;
                    $scope.reportPreferences.fromDateCleared = true;
                }
                else {
                    $scope.reportPreferences.toDate = null;
                    $scope.reportPreferences.toDateCleared = true;
                }
            }

            function validateDates() {
                if ($scope.formPreferences.form) {
                    //have dates, reset cleared flags
                    if ($scope.formPreferences.form.date1.$error.pattern || $scope.formPreferences.form.date2.$error.pattern) {
                        //do nothing
                    }
                    else if (($scope.reportPreferences.fromDate != null && $scope.reportPreferences.fromDate.length > 0) && ($scope.reportPreferences.toDate != null && $scope.reportPreferences.toDate.length > 0))
                    {
                        var toDate = new Date($scope.reportPreferences.toDate);
                        var fromDate = new Date($scope.reportPreferences.fromDate);
                        if (toDate < fromDate) {
                            $scope.dateRangeInvalid = true;
                        }
                        else $scope.dateRangeInvalid = false;
                    }
                }
            }

            $scope.$watch('reportPreferences.fromDate', function (newval, oldval) {
                if (newval != null && newval.length > 0) {
                    $scope.reportPreferences.fromDateCleared = false;
                    validateDates();
                }
                else
                {
                    $scope.reportPreferences.fromDateCleared = true;
                    $scope.dateRangeInvalid = false;
                }
            });

            $scope.$watch('reportPreferences.toDate', function (newval, oldval) {
                if (newval != null && newval.length > 0) {
                    $scope.reportPreferences.toDateCleared = false;
                    validateDates();
                }
                else
                {
                    $scope.reportPreferences.toDateCleared = true;
                    $scope.dateRangeInvalid = false;
                }
            });
        }
    }
)();
