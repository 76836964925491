/**
 * Created by pward on 2/2/16.
 */
app.directive('surveyLink', function($rootScope){
    return {
        restrict: 'A',
        scope: false,
        link: function(scope, element, attributes, $rootScope) {
            var actionKeyDown = false;
            var draftWarning = function () {
                swal({
                    title: "This survey is a draft.",
                    text: "All responses will be discarded.",
                    showConfirmButton: true
                });
            };

            ['copy', 'cut', 'paste', 'keydown', 'keyup'].forEach(function(action) {
                element.on(action, function(e){
                    

                    if (action === 'cut' || action === 'paste') {
                        
                        e.preventDefault();
                    }
                    else if (action === 'copy') {
                        if (scope.status() === 'Draft') {
                            draftWarning();
                        }
                    }
                    else if (action === 'keydown') {
                        //command keycode = 91 (chrome, safari), 224 (ff)
                        //tab keycode = 9
                        if (e.keyCode == 9) {

                        }
                        else if (e.keyCode != 17 && e.keyCode != 91 && e.keyCode != 224) {
                            if (actionKeyDown == false) {
                                e.preventDefault();
                            }
                        }
                        else {
                            actionKeyDown = true;
                        }
                    }
                    else if (action === 'keyup') {
                        actionKeyDown = false;
                    }
                })
            })
        }
    }
})