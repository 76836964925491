app.controller('DownloadReportSignInCtrl',
    [
        '$routeParams',
        '$scope',
        '$location',
        '$rootScope',
        'applicationService',
        'userService',
        'signInService',
        'customerService',
        'salesService',
    function(
        $routeParams,
        $scope,
        $location,
        $rootScope,
        applicationService,
        userService,
        signInService,
        customerService,
        salesService
    ) {

        $scope.$root.headerAction = 'hidden';
        $scope.showLoginUI = false;
        $scope.myJourneyUrl = my_journey_url_login;

        if ($routeParams.customerId == undefined) {
            $scope.showLoginUI = true;
        }

        var IS_DIRECT = true;

        $scope.user = {};  // TODO: Roles Permissions - controller places user object on its scope.
        $scope.errors = {};
        $scope.csvIdentifier = null;
        $scope.authorized = false;


        $scope.signIn = function(user){
            $scope.errors = {};
            signInService.validateEmail(user, $scope.errors);
            signInService.validatePassword(user, $scope.errors);

            if(!$scope.errors.email && !$scope.errors.password){
                applicationService.login(user.email, user.password, handleSuccessfulLogin, handleFailLogin);
            }
        }

        $scope.extractIdentifier = function() {
            if ($routeParams.docref) {
                $scope.csvIdentifier = $routeParams.docref;
            }
        };

        const handleSuccessfulLogin = function(response){  // TODO: ES6 - remove const references.

            const user = response.user;  // TODO: Roles Permissions - controller sets user object in method variable from HTTP call.
            const reportId = $location.search().report;

            $rootScope.userActionRoles = {  // TODO: Roles Permissions - controller is interacting with userActionRoles on $rootScope. Services should be used instead of $rootScope.
                admin: userService.userIsAdmin(),
                certified: userService.userIsCertifiedCreator(),
                superUser: userService.userIsSuperUser()
            };
            $rootScope.pendingReportDownload = true;
            $rootScope.pendingReportIdentifier = $scope.csvIdentifier;
            const originalPath = applicationService.getOriginalPath();
            if(originalPath.indexOf("/download-ses-report") >= 0){
                applicationService.setSesReportDownloadStatus(true);
            }
            if(!userService.isUserEnabled(user)){
                if(userService.isUserDisabled(user)){
                    $scope.errors.login = "User profile is disabled";
                }
                else if(userService.isUserInvited(user)){
                    $scope.errors.login = "User is invited. Please follow instruction in invitation email";
                }
                return;
            }

            $rootScope.pendingReportDownload = true;
            $rootScope.pendingReportIdentifier = $scope.csvIdentifier;

            userService.setUser(user);
            userService.setUserAccessToken(response.token);
            $rootScope.userActionRoles = {  // TODO: Roles Permissions - controller is interacting with userActionRoles on $rootScope. Services should be used instead of $rootScope.
                admin: userService.userIsAdmin(),
                certified: userService.userIsCertifiedCreator(),
                superUser: userService.userIsSuperUser()
            };

            if(user.superUser && user.superUser == true){                  //if super user go to institution selector directly
                const originalPath = applicationService.getOriginalPath();
                if ((originalPath === "/preview-report" || originalPath == "/view-report") && reportId) {
                  applicationService.goToSigninPageWithRedirect(originalPath);
                  return;
                }
                applicationService.goToSigninSelectInstitutionPage();

            } else if ( $rootScope.userActionRoles.certified && !$rootScope.userActionRoles.admin) { // TODO: Roles Permissions - services should be used instead of $rootScope
                $location.path('/manage-content');
            } else {
                const userAssociations = user.associations;
                if (userAssociations.length === 0) {
                    $scope.errors.login = "User profile has no institution associated";
                }
                else {
                    const originalPath = applicationService.getOriginalPath();
                    if ((originalPath === "/preview-report" || originalPath == "/view-report") && reportId) {
                      applicationService.goToSigninPageWithRedirect(originalPath);
                      return;
                    }
                    // Note: The following code is crucial to the institution selection workflow for any user that is not a super admin
                    var products = salesService.getProductNames();
                    var customerId = userService.getUserCustomerId();
                    salesService.getCustodians(customerId, products, getCustodiansSuccess, null);
                }
            }
        }


        const handleFailLogin = function(errorMessage) {
            $scope.showLoginUI = true;
            //90,91- Remove the following 2 statements to support deep link login intention location on errors and successful re-login.
            $rootScope.customerId = undefined;
            $rootScope.location = undefined;
            //
            $scope.errors.login = errorMessage;
        }

        const getCustodiansSuccess = function(custodians){
            applicationService.getNonEssentialDataCache().custodianIds = custodians;
            const userAssociationsIds = userService.getUserAssociationIds();
            customerService.getCustomersWithChildStatus(userAssociationsIds, getCustomersSuccess, null, $scope);

        }

        const getCustomersSuccess = function(customersInfo){
            $scope.institutions = customersInfo;
            if(customersInfo.length === 1 && customersInfo[0].childCount == 0){
                const institutionId = customersInfo[0].id;
                signInService.getCustomerAndNext(institutionId, institutionId, IS_DIRECT);
            }
            else{
              applicationService.goToSigninSelectInstitutionPage();
            }
        }

        $scope.extractIdentifier();
    }
]);
