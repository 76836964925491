app.directive("filterBuilderInit", ['$compile', function ($compile) {
    return {
        scope: true,
        link: function (scope,element,attrs) {
            const dropdownOptions = {
                classic: true,
                hoverIntent: 30000000000,
                multiVerbosity: 99999,
                height: 38,
                multiDelimiter: ', '
            };

            $(document).ready(function() {
                // Initiate Pretty Dropdowns
                const dropdowns = $('select.pretty').prettyDropdown(dropdownOptions);
            });
        }
    }
}]);