/**
 * Created by bhadaway on 6/1/17.
 */
app.controller('BrowseContentCtrl', 
    ['$scope',
     '$rootScope', 
     'browseContentService',
    function ($scope, 
              $rootScope, 
              browseContentService) {

        var tabs = [];
        var myCustomContentData = [];
        var isCertifiedContent = false;
        var rightArrowKeyCode = 39;
        var leftArrowKeyCode = 37;
        var cachedData = [];

        $scope.myContentActive = false;

        $rootScope.headerAction = 'active';

        $scope.contentIsCertified = function(content) {
            return content.contentType === 'certified_content';
        };

        // get tabs api call
        var getTabs = function () {
            browseContentService.getTabs(successGetTabsHandler, failureGetTabsHandler);
        };
        var successGetTabsHandler = function (response) {
            $scope.tabs = response;
            tabs = response;
            if(tabs.length === 1){
                $scope.scanOrganizeForFilterBy(getAdvancedCertifiedContent());
            } else {
                $scope.scanOrganizeForFilterBy(getCustomContent());
            }

            if (tabs[0].name === "Certified Content") {
                $scope.myContentActive = false;
                getAdvancedCertifiedContent();
            } else {
                $scope.myContentActive = true;
            }

            //check for firefox or edge and check to see if any tabs are too long and require ellipsis, if so add class
            if ((!!window.MSInputMethodContext && !!document.documentMode || navigator.userAgent.search("Firefox") >= 0) || navigator.appVersion.indexOf('Edge') > -1) {
                $scope.noLongTabs = true;
                tabs.forEach(function(tab){
                    if(tab.name.length >= 27){
                        $scope.noLongTabs = false
                    }
                });
            }
        };

        var failureGetTabsHandler = function (response) {
            // $scope.errors.processing = "Unable to process Get Tabs request";
        };
        getTabs();
        //end get tabs api call

        $scope.browseContentClearSearch = function (){
            $scope.searchToken = "";
        };

        // get My Custom Content api call for initial load
        var getCustomContent = function () {
            browseContentService.getCustomContent(successGetCustomContentHandler, failureGetCustomContentHandler);
        };
        var successGetCustomContentHandler = function (response) {
            $scope.scanOrganizeForFilterBy(response);
            $scope.isCertifiedContent = false;
            $rootScope.isCertifiedContentRoot = false;
            cachedData[$scope.activeTabIndex] = $scope.diagnosticsApiData;
        };
        var failureGetCustomContentHandler = function (response) {
            $scope.scanOrganizeForFilterBy(response.statusText);
        };
        getCustomContent();
        //end get My Custom Content api call for initial load



        // get AdvancED Certified Content
        var getAdvancedCertifiedContent = function () {
            $scope.relevantFilterOption = $scope.getRelevantFilterOptions();
            browseContentService.getAdvancedCertifiedContent(successGetAdvancedCertifiedContentHandler, failureGetAdvancedCertifiedContentHandler);
        };
        var successGetAdvancedCertifiedContentHandler = function (response) {
            $scope.scanOrganizeForFilterBy(response);
            $scope.isCertifiedContent = true;
            $rootScope.isCertifiedContentRoot = true;
            cachedData[$scope.activeTabIndex] = $scope.diagnosticsApiData;
        };
        var failureGetAdvancedCertifiedContentHandler = function (response) {
            $scope.scanOrganizeForFilterBy(response.statusText);
        };
        //end get AdvancED Certified Content



        // get parent tabs content
        var getParentTabCustomContent = function (tabIndex) {
            browseContentService.getParentTabCustomContent(successGetParentTabCustomContentHandler, failureGetParentTabCustomContentHandler, tabIndex, tabs);
        };
        var successGetParentTabCustomContentHandler = function (response) {
            $scope.scanOrganizeForFilterBy(response);
            $scope.isCertifiedContent = false;
            $rootScope.isCertifiedContentRoot = false;
            cachedData[$scope.activeTabIndex] = $scope.diagnosticsApiData;

            _.each($scope.diagnosticsApiData, function (obj) {
                obj.description = "None";
            });

        };
        var failureGetParentTabCustomContentHandler = function (response) {
            $scope.scanOrganizeForFilterBy(response.statusText);
        };
        //end get parent tabs content

        $scope.scanOrganizeForFilterBy = function(data) {
            if (data != undefined) {
                $scope.diagnosticsApiData = data;
            }

            if ($scope.diagnosticsApiData != null) {
                for (let i = 0; i < $scope.diagnosticsApiData.length; i++) {
                    let obj = $scope.diagnosticsApiData[i];
                    if ($scope.typeFilter != TYPE_ACCREDITATION || !obj.accreditation) {
                        obj.targetEx = obj.target;
                    }
                    else {
                        obj.targetEx = TYPE_ACCREDITATION;
                    }
                }
            }
        }

        // retrieve filter options with the default All being hard coded in the html with value of '' to not filter anything
        var filterOptions = [];
        $scope.relevantFilterOption = [];
        var getFilterOptions = function () {
            browseContentService.getFilterOptions(successGetFilterOptionsContentHandler, failureGetFilterOptionsHandler);
        };
        var successGetFilterOptionsContentHandler = function (response) {
            $scope.filterOptions = response;
            $scope.relevantFilterOption = response;
        };
        var failureGetFilterOptionsHandler = function (response) {
            $scope.filterOptions = response.statusText;
        };
        getFilterOptions();
        //end retrieve filter options with the default All being hard coded in the html with value of '' to not filter anything

        $scope.getRelevantFilterOptions = function() {
            var relevantFilterOption = JSON.parse(JSON.stringify($scope.filterOptions))//$scope.filterOptions;

            //Certified Content tab is active.
            relevantFilterOption.splice(0, 0, {targetId: -1, name: TYPE_ACCREDITATION, value: TYPE_ACCREDITATION.toUpperCase()});

            return relevantFilterOption;
        }

        var activeTabIndex = 0;
        //variable to determine if the horizontal scroll of the tabs is scrolled. True if its scrolled to the right and is set false when scrolled back.
        var isScrolled = false;
        //variable to determine if certified icon is present
        var iconPresent = true;

        //function to make arrow keys usable for changing tabs. Must be focused on tabs for it to work
        $scope.key = function ($event) {

            if ($event.keyCode == rightArrowKeyCode)
                tabArrowClick(1);
            else if ($event.keyCode == leftArrowKeyCode)
                tabArrowClick(-1);
        };

        var rowIndex = null;

        window.onclick = function(event) {
            if(!(event.target.localName === "span")){
                $(".f-dropdown").css({left: '-9999', display: 'none'});
                $(".hide-span").css("display", "none");
            }
        };

        var setRowIndex = function (rowindex){
            rowIndex = rowindex;
        };

        //the function to open tabs which is called on each tab button. First tab set to 1 second to 2
        // and so on to add the active class to the correct tab. Also changes the current array to be displayed
        //to the correct tab
        var openTab = function (index) {

            setActiveTabClick(index);
            if(!cachedData[$scope.activeTabIndex]){
                openTabDataClick(index);
            } else{
                $scope.scanOrganizeForFilterBy(cachedData[$scope.activeTabIndex]);
                $scope.isCertifiedContent = true;
                if(cachedData[$scope.activeTabIndex][0].contentType === "certified_content"){
                    $scope.isCertifiedContent = true;
                    $scope.relevantFilterOption = $scope.getRelevantFilterOptions();
                } else {
                    $scope.isCertifiedContent = false;
                    $scope.relevantFilterOption = $scope.filterOptions;
                }
            }
            if(tabs[0].name === "My Content" && $scope.activeTabIndex === 0){
                $scope.myContentActive = true;
            } else {
                $scope.myContentActive = false;
            }
            // $scope.myContentActive = false;

            if (tabs[index].name != 'Certified Content') {
                if ($scope.typeFilter === TYPE_ACCREDITATION) {
                    $scope.filterByType('All');
                }
            }
        };

        //function to horizontally scroll tabs
        var tabScroll = function (direction) {
            if (direction === "left") {
                $('.tab-container').animate({scrollLeft: '+=500'}, 500, 'easeOutQuad');
                isScrolled = true;
            } else if (direction === "right") {
                $('.tab-container').animate({scrollLeft: '-=500'}, 500, 'easeOutQuad');
                isScrolled = false;
            }

        };


        var openTabDataArrow = function (addToIndex) {
            //handle clicking on div barely in vision and then pressing arrow, without this the index gets set to -1 or more than tabs.length -1
            if (($scope.activeTabIndex >= tabs.length - 1) && addToIndex === 1) {
                addToIndex = 0;
            } else if (($scope.activeTabIndex <= 0) && addToIndex === -1) {
                addToIndex = 0;
            }

            $scope.activeTabIndex += addToIndex;

            if ($scope.activeTabIndex === 0) {
                getCustomContent();
            } else if ($scope.activeTabIndex === 1) {
                getAdvancedCertifiedContent();
            } else {
                getParentTabCustomContent($scope.activeTabIndex);
            }
        };

        var openTabDataClick = function (index) {
            $scope.activeTabIndex = index;
            $scope.relevantFilterOption = $scope.filterOptions;
            if (tabs[0].name === "Certified Content") {
                if ($scope.activeTabIndex === 0) {
                    getAdvancedCertifiedContent();
                } else {
                    getParentTabCustomContent($scope.activeTabIndex);
                }
            } else {
                if ($scope.activeTabIndex === 0) {
                    if (tabs.length === 1) {
                        getAdvancedCertifiedContent();
                    } else {
                        getCustomContent();
                    }
                } else if ($scope.activeTabIndex === 1) {
                    getAdvancedCertifiedContent();
                } else {
                    getParentTabCustomContent($scope.activeTabIndex);
                }

                if (($scope.activeTabIndex >= 4) && (isScrolled === false)) {
                    tabScroll("left");
                }
                if (($scope.activeTabIndex <= 2) && (isScrolled === true)) {
                    tabScroll("right");
                }
            }
        };

        var setActiveTabClick = function (clickedIndex) {
            $scope.activeTabIndex = clickedIndex;
            activeTabIndex = clickedIndex;
            console.log($scope.activeTabIndex);
        };

        $scope.filterByType = function (type) {
            $scope.displayFilterOption = type;
            if (type === 'All') {
                type = undefined;
            }
            $scope.typeFilter = type;

            $scope.scanOrganizeForFilterBy();
        };


        //browser specific javascript to fix problems with animations that were not uniform across browsers and impossible to fix with just css
        //check for firefox
        if (navigator.userAgent.search("Firefox") >= 0) {
            $(".tab").on('mouseenter mouseleave', '.too-long-text-tab', function (event) {
                if (event.type == 'mouseenter') {

                    $('.short-text-tab').css('top', '-17px');
                    $('.too-long-text-tab').css('top', '-14px');


                    $(this).css('top', '-10px');

                } else {

                    $('.short-text-tab').css('top', '-4px');
                    $('.too-long-text-tab').css('top', '0px');


                    $(this).css('top', '1px');

                }

            });
            $(".tab").on('mouseenter mouseleave', '.tab-text-longest', function (event) {
                if (event.type == 'mouseenter') {

                    $('.short-text-tab').css('top', '-20px');
                    $('.too-long-text-tab').css('top', '-17px');


                    $(this).css('top', '-10px');

                } else {

                    $('.short-text-tab').css('top', '-4px');
                    $('.too-long-text-tab').css('top', '0px');


                    $(this).css('top', '1px');

                }

            });
        }

        //check for IE11
        if (!!window.MSInputMethodContext && !!document.documentMode) {
            $(".tab").on('mouseenter mouseleave', '.too-long-text-tab', function (event) {
                if (event.type == 'mouseenter') {

                    $('.short-text-tab').css('top', '-17px');
                    $('.too-long-text-tab').css('top', '-2px');


                    $(this).css('top', '-9px');

                } else {

                    $('.short-text-tab').css('top', '-14px');
                    $('.too-long-text-tab').css('top', '0px');


                    $(this).css('top', '1px');

                }

            });
            $(".tab").on('mouseenter mouseleave', '.tab-text-longest', function (event) {
                if (event.type == 'mouseenter') {

                    $('.short-text-tab').css('top', '-22px');
                    $('.too-long-text-tab').css('top', '-7px');


                    $(this).css('top', '-9px');

                } else {

                    $('.short-text-tab').css('top', '-14px');
                    $('.too-long-text-tab').css('top', '0px');


                    $(this).css('top', '0px');

                }

            });
        }
        //check for Edge
        if (navigator.appVersion.indexOf('Edge') > -1) {
            $(".tab").on('mouseenter mouseleave', '.too-long-text-tab', function (event) {
                if (event.type == 'mouseenter') {

                    //$('.short-text-tab').css('top', '-24px');
                    //$('.too-long-text-tab').css('top', '-2px');


                    $(this).css('top', '-14px');

                } else {

                    //$('.short-text-tab').css('top', '-24px');
                    //$('.too-long-text-tab').css('top', '0px');


                    $(this).css('top', '1px');

                }

            });
            $(".tab").on('mouseenter mouseleave', '.tab-text-longest', function (event) {
                if (event.type == 'mouseenter') {

                    $('.short-text-tab').css('top', '-22px');
                    $('.too-long-text-tab').css('top', '-7px');


                    $(this).css('top', '-9px');

                } else {

                    $('.short-text-tab').css('top', '-14px');
                    $('.too-long-text-tab').css('top', '0px');


                    $(this).css('top', '0px');

                }

            });
        }

        //check for Safari
        if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
            $('.inner-wrap').css({
                "-webkit-transition": "-webkit-transform 500ms ease",
                "transition": "transform 500ms ease"
            });
        }

        //end browser specific javascript

        //return sort string
        var changeSortString = function (sortString) {
            if ($scope.sortString === sortString) {
                $scope.sortString = '-' + sortString;
            } else {
                $scope.sortString = sortString;
            }
        };
        // change arrow up and down
        var changeSortArrow = function (index) {
            if (($scope.reverseTypeColumn === false || $scope.reverseTypeColumn === null) && index === 1) {
                $scope.reverseTypeColumn = true;
                $scope.reverseNameColumn = null;
                $scope.reverseDescriptionColumn = null;
                $scope.reverseStatusColumn = null;
            } else if ($scope.reverseTypeColumn === true) {
                $scope.reverseTypeColumn = false;
                $scope.reverseNameColumn = null;
                $scope.reverseDescriptionColumn = null;
                $scope.reverseStatusColumn = null;
            }
            if (($scope.reverseNameColumn === false || $scope.reverseNameColumn === null) && index === 2) {
                $scope.reverseNameColumn = true;
                $scope.reverseTypeColumn = null;
                $scope.reverseDescriptionColumn = null;
                $scope.reverseStatusColumn = null;
            } else if ($scope.reverseNameColumn === true) {
                $scope.reverseNameColumn = false;
                $scope.reverseTypeColumn = null;
                $scope.reverseDescriptionColumn = null;
                $scope.reverseStatusColumn = null;
            }
            if (($scope.reverseDescriptionColumn === false || $scope.reverseDescriptionColumn === null) && index === 3) {
                $scope.reverseDescriptionColumn = true;
                $scope.reverseTypeColumn = null;
                $scope.reverseNameColumn = null;
                $scope.reverseStatusColumn = null;
            } else if ($scope.reverseDescriptionColumn === true) {
                $scope.reverseDescriptionColumn = false;
                $scope.reverseTypeColumn = null;
                $scope.reverseNameColumn = null;
                $scope.reverseStatusColumn = null;
            }
            if (($scope.reverseStatusColumn === false || $scope.reverseStatusColumn === null) && index === 4) {
                $scope.reverseStatusColumn = true;
                $scope.reverseTypeColumn = null;
                $scope.reverseNameColumn = null;
                $scope.reverseDescriptionColumn = null;
            } else if ($scope.reverseStatusColumn === true) {
                $scope.reverseStatusColumn = false;
                $scope.reverseTypeColumn = null;
                $scope.reverseNameColumn = null;
                $scope.reverseDescriptionColumn = null;
            }
        };

        var onTableHeaderClick = function (sortString, index) {
            changeSortString(sortString);
            changeSortArrow(index);
        };

        //set z-index of tabs in descending order using the $index sent in during the angular repeat.
        //the smaller the input the bigger the output so smaller $index inputs will have a higher z-index
        var setZIndex = function (index) {
            return {'z-index': Math.ceil(50 / index + 1)};
        };

        //function to show arrows if there are more than 5 tabs
        var fivePlusTabs = function () {
            if (tabs.length > 4) {
                $scope.underFiveTabs = false;
                return true;
            } else {
                $scope.underFiveTabs = true;
                return false;
            }
        };


        // function to handle arrow clicks
        var tabArrowClick = function tabArrowClick(addToIndex) {

            openTabDataArrow(addToIndex);
            if (($scope.activeTabIndex === 4 || $scope.activeTabIndex === 5) && (addToIndex === 1)) {
                tabScroll("left");
            } else if (($scope.activeTabIndex === tabs.length - 5) && (addToIndex === -1)) {
                tabScroll("right");
            }

        };

        //end Create of Start a Diagnostic

        $scope.tabScroll = tabScroll;
        $scope.setActiveTabClick = setActiveTabClick;
        $scope.fivePlusTabs = fivePlusTabs;
        $scope.tabArrowClick = tabArrowClick;
        $scope.setZIndex = setZIndex;
        $scope.openTab = openTab;
        $scope.onTableHeaderClick = onTableHeaderClick;
        $scope.changeSortString = changeSortString;
        $scope.setRowIndex = setRowIndex;
        $scope.displayFilterOption = 'All';
        $scope.isCertifiedContent = isCertifiedContent;
        $scope.reverseTypeColumn = null;
        $scope.reverseNameColumn = null;
        $scope.reverseDescriptionColumn = null;
        $scope.reverseStatusColumn = null;
        $scope.iconPresent = iconPresent;
        $scope.activeTabIndex = activeTabIndex;
        $scope.tabs = tabs;
        $scope.openTabIndex = 0;
        $scope.scanOrganizeForFilterBy(myCustomContentData);
        $scope.filterOptions = filterOptions;
    }
]);
