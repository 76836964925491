(function(){
    'use strict';
    angular.module('echo')
        .controller('DashboardActivitySummaryCtrl', DashboardActivitySummaryCtrl);

    DashboardActivitySummaryCtrl.$inject = ['$scope', '$modalInstance', 'ActivityService', 'ReportService', '$filter', '$timeout', 'survey', 'createReport', '$sessionStorage', 'userService'];

    function DashboardActivitySummaryCtrl ($scope, $modalInstance, ActivityService, ReportService, $filter, $timeout, survey, createReport, $sessionStorage, userService) {
        const statusTable = {
            2: "Queue",
            3: "In Progress",
            4: "Complete",
            5: "Canceled",
            6: "Error",
        }
        const COUNT = 7;
        const DAYSACTIVE = 7;
        const MAXCOUNT = 4;
        const MAXCYCLES = 2;
        const TIMEOUT = 2000;

        // Cancel Modal
        $scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        }

    // Get Activity Details for Summary
    $scope.getActivityDetails = function(survey) {
      adjustModalSize();
      survey = JSON.parse(survey);
      $scope.surveyName = survey.surveyName.length > 100 ? survey.surveyName.substring(0, 100) + "..." : survey.surveyName;
      $scope.surveyContentId = survey.surveyContentId;
      $scope.surveyId = survey.surveyId;
      $scope.networkAverage = survey.networkAverage;
      $scope.surveyContentName = survey.surveyContentName;
      $scope.activitySummaryLoading = true;

      var surveyRequestDTO = angular.copy($scope.surveyRequest);
      surveyRequestDTO.surveyId = $scope.surveyId;
      surveyRequestDTO.count = COUNT;
      surveyRequestDTO.daysActive = DAYSACTIVE;
      surveyRequestDTO.userEmail = userService.getUserEmail();
      surveyRequestDTO.institutionName = $sessionStorage.institution.name;
      surveyRequestDTO.surveyName = survey.surveyName;

      getActivitySummary(surveyRequestDTO);
    };

    // Continue waiting for activity summary
    $scope.keepWaiting = function(requestId) {
      $scope.summaryTimeoutExpired = false;
      $scope.cycle++;
      $scope.count = 0;
      pollStatus(requestId, $scope.count, $scope.cycle);
    }

        // Request a full generated Report
        $scope.generateReport = function() {
            $scope.cancel();
            $timeout(function () {
                createReport(true)}, 500);
        }

        // Adjust Modal Size
        var adjustModalSize = function() {
            if (!Foundation.utils.is_xxlarge_up()) {
                $(".activity-summary-modal").width("60%");
            }
        }

        // Get summary activity
        var getActivitySummary = function(surveyRequestDTO) {
            ActivityService.activitySummary(surveyRequestDTO).$promise.then(function(data) {
                $scope.requestId = data.requestId;
                $scope.count = 0;
                $scope.cycle = 1;
                makeApiCallForStatus($scope.requestId, $scope.count, $scope.cycle);
            }, handleErrorCallback);
        }

        // Step 1: Poll status
        var pollStatus = function(requestId, count, cycle) {
            if (count >= MAXCOUNT) {
                cycle === MAXCYCLES ? $scope.switchToGenerateReportOptions = true : undefined;
                $scope.summaryTimeoutExpired = true;
            } else {
                if (!$scope.status) {
                    count++;
                    $timeout(function() { pollStatus(requestId, count, cycle)}, TIMEOUT);
                } else if ($scope.status !== "COMPLETE") {
                    count++;
                    makeApiCallForStatus(requestId, count, cycle);
                } else {
                    getDataFromCache(requestId);
                }
            }
        }

        // Step 2: Make api call for status
        var makeApiCallForStatus = function(requestId, count, cycle) {
            ReportService.pollStatus({requestId: requestId}).$promise.then(function(data) {
                $scope.status = data.status;
            }, handleErrorCallback);
            $timeout(function() { pollStatus(requestId, count, cycle)}, TIMEOUT);
        }

        // Step 3: Get cached data
        var getDataFromCache = function(requestId) {
            ReportService.getDataFromCache({requestId: requestId}).$promise.then(function(data) {

                var json = JSON.parse(data.mainPayload);

                $scope.activeSurvey = json.surveyDashboardPayloadDTO.surveyDashboard;
                $scope.activeSurvey.hideScore = json.hideScore;

                $scope.surveySectionActivity = json.surveySectionActivityListPayloadDTO.surveySectionActivity;
                $scope.surveyDemographicActivities = json.surveyDemographicActivityListPayloadDTO.surveyDemographicActivities;

                processActiveSurvey($scope.activeSurvey);
                $(".activity-summary-modal").width("80%");
                $scope.activitySummaryLoading = false;

            }, handleErrorCallback)
        }

        // Process results for overall score and network average
        var processActiveSurvey = function(activeSurvey) {
            if (activeSurvey != null) {
                $scope.selectedActiveSurvey = activeSurvey.surveyId;
                activeSurvey.overallScore = $filter('number')(activeSurvey.overallScore, 2);
                if (activeSurvey.certificationId > 0 && activeSurvey.networkAverage != null) {
                    $scope.overallScoreOptions = angular.copy(dashboard_activity_big_chart_options);
                    $scope.scoreData = [
                        {'overallScore':activeSurvey.overallScore, 'value': activeSurvey.scale,'networkAverage':activeSurvey.networkAverage}
                    ];
                } else {
                    $scope.overallScoreOptions = angular.copy(dashboard_activity_big_chart_options2);
                    $scope.scoreData = [
                        {'overallScore':activeSurvey.overallScore, 'value': activeSurvey.scale}
                    ];
                }
            }
        }

        var handleErrorCallback = function(httpResponse) {
            var msg = "There was an error retrieving your activity details.";
            $scope.cancel();
            $timeout(sweetAlert("The system encountered a problem, please try again.", msg ), 500).then(function(){
                $('#activityDetailsLoader').addClass('ng-hide');
                $scope.activityLoading = false;
                $('#sectionScoresError').removeClass('ng-hide');
            });
        }
        $scope.getActivityDetails(survey);

}})();
