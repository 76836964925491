/**
 * StaticController
 * a controller to add example code for some views
 */
app.controller('StaticController', ['$http', '$scope', '$routeParams', '$rootScope', '$modal', function($http, $scope, $routeParams, $rootScope, $modal) {
    $scope.init = function () {
        $rootScope.headerAction = 'hidden';
        $scope.languageId = 1; //English
    }

    $scope.init();

    // Example code to open modal on add-user-menu.html
    // Shown on /#/user-management
    $scope.openInstitutionAssignModal = function() {
      var modalInstance;
      return modalInstance = $modal.open({
        templateUrl: 'partials/institution-assign-modal.html',
        windowClass: 'mod-wide'
      });
    };

}]);