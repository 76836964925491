app.controller(
    'DownloadReportController',
    [
        '$routeParams',
        '$scope',
        '$location',
        '$rootScope',
        'applicationService',
        'userService',
        'signInService',
        'customerService',
        'salesService',
        function(
            $routeParams,
            $scope,
            $location,
            $rootScope,
            applicationService,
            userService,
            signInService,
            customerService,
            salesService
        ) {

            $scope.$root.headerAction = 'hidden';

            $scope.loginByToken = function() {
                console.log("loginByToken... enter.");
                console.log("window.location = " + window.location);
                //params: $routeParams.authToken, $routeParams.customerId, $routeParams.location
                if ($routeParams.authToken != undefined) {
                    var token = $routeParams.authToken;
                    //TODO: uncomment
                    //$location.search("authToken", null);

                    console.log("Token detected. Performs login by token...");
                    applicationService.loginByToken(token, SURVEY_APP_ID, handleSuccessfulLogin, handleFailLogin);
                }
                console.log("loginByToken... exit.");
            }

            //URL = {{appSpecificBaseUrl}}/downloadReport?reportName=<reportName>&fileName=<fileName>
            var handleSuccessfulLogin = function(response) { // All setting of user data is handled in userService.handleSuccessfulLogin. This should be
                // refactored so that much of the business logic here can be moved out of the controller and into services.
                // Currently, the user object is returned to the controller to provide data for if/else logic and decisions.
                userService.setUser(response.user);
                userService.setUserAccessToken($routeParams.authToken);
                $scope.csvIdentifier = $routeParams.fileName;
                if ($routeParams.reportName == unstacked_csv) {
                    $scope.showLoginUI = false;
                    //user is opening a csv report file.
                    //check for response.user.id
                    var _index = $routeParams.fileName.indexOf('_');
                    var userId = $routeParams.fileName.substr(0, _index);
                    var csvFile = $routeParams.fileName.substr(_index + 1);

                    if (userId == response.user.id) {
                        window.open(reports_shared_drive_uri + csvFile, "_self");
                    }

                    return;
                }
                else if ($routeParams.reportName == preview_report || $routeParams.reportName == view_report) {
                    //build preview-report URL
                    var previewReportUrl = account_setup_url_login + "/#/" + $routeParams.reportName + "?report=" + $routeParams.fileName;
                    console.log("previewReportUrl/viewReportUrl = " + previewReportUrl);
                    window.location = previewReportUrl;
                }
                else if ($routeParams.reportName == SES_STATUS_MONITORING ||
                    $routeParams.reportName == SES_STUDENT_ROSTER ||
                    $routeParams.reportName == SES_TOTAL_PARTICIPANTS ||
                    $routeParams.reportName == SES_FILTERED_PARTICIPANTS ||
                    $routeParams.reportName == download_ses_report) {
                    handleSesReport(response);
                }
                else if ($routeParams.reportName == change_roster_error) {
                    let data = atob($routeParams.fileName);
                    let sepIndex = data.indexOf('_');
                    let crUserId = data.substring(0, sepIndex);
                    let filePath = data.substring(sepIndex + 1);//encodeURIComponent(data.substring(sepIndex + 1));
                    let piiErrorIndex = filePath.indexOf('pii-error/');
                    let httpDomainPiiError = filePath.substring(0, piiErrorIndex);
                    let encodedFilePath = httpDomainPiiError + encodeURIComponent(filePath.substring(piiErrorIndex));

                    if (crUserId == response.user.id) {
                        //Allow downloading file only if user id is matched.
                        $scope.csvIdentifier = encodedFilePath;

                        $rootScope.reportName = $routeParams.reportName;
                        handleSesReport(response);
                    }
                }
            }

            var handleFailLogin = function(errorMessage) {
                //Login by token should always succeeded.
                //Network maybe an issue.  Logout.
                applicationService.logout();
            }

            //ses report
            const handleSesReport = function(response) {  // TODO: ES6 - remove const references.

                const user = response.user;  // TODO: Roles Permissions - controller sets user object in method variable from HTTP call.
                const reportId = $location.search().report;

                $rootScope.userActionRoles = {  // TODO: Roles Permissions - controller is interacting with userActionRoles on $rootScope. Services should be used instead of $rootScope.
                    admin: userService.userIsAdmin(),
                    certified: userService.userIsCertifiedCreator(),
                    superUser: userService.userIsSuperUser()
                };
                $rootScope.pendingReportDownload = true;
                $rootScope.pendingReportIdentifier = $scope.csvIdentifier;
                if($routeParams.reportName == download_ses_report) {
                    applicationService.setSesReportDownloadStatus(true);
                }
                if(!userService.isUserEnabled(user)){
                    if(userService.isUserDisabled(user)){
                        $scope.errors.login = "User profile is disabled";
                    }
                    else if(userService.isUserInvited(user)){
                        $scope.errors.login = "User is invited. Please follow instruction in invitation email";
                    }
                    return;
                }

                $rootScope.pendingReportDownload = true;
                $rootScope.pendingReportIdentifier = $scope.csvIdentifier;

                userService.setUser(user);
                userService.setUserAccessToken(response.token);
                $rootScope.userActionRoles = {  // TODO: Roles Permissions - controller is interacting with userActionRoles on $rootScope. Services should be used instead of $rootScope.
                    admin: userService.userIsAdmin(),
                    certified: userService.userIsCertifiedCreator(),
                    superUser: userService.userIsSuperUser()
                };

                if(user.superUser && user.superUser == true){                  //if super user go to institution selector directly
                    const originalPath = applicationService.getOriginalPath();
                    if ((originalPath === "/preview-report" || originalPath == "/view-report") && reportId) {
                        applicationService.goToSigninPageWithRedirect(originalPath);
                        return;
                    }
                    applicationService.goToSigninSelectInstitutionPage();

                } else if ( $rootScope.userActionRoles.certified && !$rootScope.userActionRoles.admin) { // TODO: Roles Permissions - services should be used instead of $rootScope
                    $location.path('/manage-content');
                } else {
                    const userAssociations = user.associations;
                    if (userAssociations.length === 0) {
                        $scope.errors.login = "User profile has no institution associated";
                    }
                    else {
                        const originalPath = applicationService.getOriginalPath();
                        if ((originalPath === "/preview-report" || originalPath == "/view-report") && reportId) {
                            applicationService.goToSigninPageWithRedirect(originalPath);
                            return;
                        }
                        // Note: The following code is crucial to the institution selection workflow for any user that is not a super admin
                        var products = salesService.getProductNames();
                        var customerId = userService.getUserCustomerId();
                        salesService.getCustodians(customerId, products, getCustodiansSuccess, null);
                    }
                }
            }

            const getCustodiansSuccess = function(custodians){
                applicationService.getNonEssentialDataCache().custodianIds = custodians;
                const userAssociationsIds = userService.getUserAssociationIds();
                customerService.getCustomersWithChildStatus(userAssociationsIds, getCustomersSuccess, null, $scope);

            }

            const getCustomersSuccess = function(customersInfo){
                $scope.institutions = customersInfo;
                if(customersInfo.length === 1 && customersInfo[0].childCount == 0){
                    const institutionId = customersInfo[0].id;
                    signInService.getCustomerAndNext(institutionId, institutionId, true);
                }
                else{
                    applicationService.goToSigninSelectInstitutionPage();
                }
            }
            ///ses report

            $scope.loginByToken();
        }
    ]);
