app.controller('signInSelectInstitutionCtrl', [
    '$rootScope',
    '$scope',
    'applicationService',
    'signInService',
    'customerService',
    'userService',
    '$modal',
    'participantService',
    function
            ($rootScope,
             $scope,
             applicationService,
             signInService,
             customerService,
             userService,
             $modal,
             participantService
    ) {
        //TODO: Refactor to IIFE syntax

        $scope.showInstitutionUI = false;
        $scope.$root.headerAction = 'hidden';
        $scope.userAssociationsIds = userService.getUserAssociationIds();

        $scope.showText = true;
        $scope.searchSelectPlaceholder = "Search by school, district, or ESA";

        if ($rootScope.customerId == undefined) {
            $scope.showInstitutionUI = true;
        }
        $scope.userIsCertifiedCreator = function() {
          // var user = userService.getUser();
          return userService.userIsCertifiedCreator();
        };

        var IS_DIRECT = true;

        var selectedInstitution = null;

        $scope.handleInstitutionSelection = function(institution) {
            selectedInstitution = institution;
            
            var selectedInstitutionId = parseInt(selectedInstitution.id, 10);

            var superUser = userService.getIsSuperUser();
            if (!superUser) {
                var custodianIds = applicationService.getNonEssentialDataCache().custodianIds;
                userService.saveUserAsLicenseOwner(custodianIds.indexOf(selectedInstitutionId) > -1);

                if (userService.isIdInAssociationIds(selectedInstitutionId)) {
                    signInService.getCustomerAndNext(selectedInstitutionId, selectedInstitutionId, IS_DIRECT);
                }
                else {
                    customerService.getOrderedAncestors(selectedInstitutionId, getOrderedAncestorsSuccess, null);
                }
            } else {
                userService.getPermissionsForSuperUserAndSetRoleAsAdmin(selectedInstitution);
            }
        }

        var getOrderedAncestorsSuccess = function(orderedAncestorIds){

            if(orderedAncestorIds){
                var associationFound = false;
                for(var i in orderedAncestorIds){
                    var ancestorId = orderedAncestorIds[i];

                    if(userService.isIdInAssociationIds(ancestorId)){
                        signInService.getCustomerAndNext(selectedInstitution.id, ancestorId, !IS_DIRECT);
                        associationFound = true;
                        break;
                    }
                }

                if(!associationFound){
                    var errorMessage = "No Association found in selected institution's hierarchy";
                    $scope.exception = errorMessage;
                    console.error = errorMessage;
                }
            }
        }

        var getPermissionsForSuperUserSuccess = function(permissions) {

        }

        var checkTermsAndConditions = function(){
            var tcFlag = userService.getTermsAndConditionsFlag();
            if(!tcFlag){
                var modalInstance = $modal.open({
                    templateUrl: 'partials/application/termsAndConditions.html',
                    controller: 'TermsAndConditionsCtrl',
                    scope: $scope,
                    backdrop: 'static',
                    resolve: {
                        items: function () {
                            var attributes = {
                            };
                            return attributes;
                        }
                    },
                    windowClass: 'reveal-modal medium radius report-share-modal'
                });

                modalInstance.result.then(function (selectedItem) {

                }, function () {

                });
            }

        }

        checkTermsAndConditions();

        if ($rootScope.customerId != undefined) {
            //deep link invocation from Strategy
            $scope.handleInstitutionSelection({id: $rootScope.customerId});
            $rootScope.customerId = undefined;
        }
        // Event listening for Institution Selector UL size
        // $(window).on('resize', function(e){

        //     var is = $('.institution-select__group');
        //     var is_height = $(is).height();
        //     var document_height = $(this).height();

        //     console.log(document_height);

        //     $(is).css('height', document_height * 0.4);
        // });

        if(applicationService.getSesReportDownloadStatus()) {
            participantService.sesPdfIsReady($rootScope.pendingReportIdentifier);
            $rootScope.pendingReportDownload = false;
            applicationService.setSesReportDownloadStatus(false);
            $rootScope.pendingReportIdentifier = null;
        }else if($rootScope.pendingReportDownload) {
            participantService.presentReportDownloadModal($rootScope.pendingReportIdentifier);
            $rootScope.pendingReportDownload = false;
            $rootScope.pendingReportIdentifier = null;
        }

    }
])
