app.directive('dashboardOverallScore', function(ActivityService) {
    return{
        restrict: 'E',
        templateUrl: 'partials/dashboard/dashboardOverallScore.html',
        link: function(scope,element,attrs){
            scope.id = attrs.id;

            var width = $(".latest-activity__overview").width();
            scope.overallScoreOptions.width = width;
        }
    }
});
