app.directive('manageContentEditMenu',
    [
      '$location',
      function(
        $location
      ) {
    return {
      restrict: 'E',
      templateUrl: 'partials/templates/manage-content-edit-menu.html',
      scope: {
      },
      link: function(scope) {

          /**
           * This function will emit an event that causes the change roster modal to display
           * @deprecated - This function is not used anymore and needs to be removed
           * TODO: Find related html and remove that as well
           */
          scope.presentRosterChangeModal = function() {
              const payload = {
                  surveyId: null,
                  customerId: null,
                  displayName: null,
                  isNTSUpload: true
              };
              scope.$emit('showChangeRosterModal', payload);
          };

      }
    }
  }
]);

