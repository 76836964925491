//TODO: Will - not used and may need to be removed. License-related code is deprecated.
/**
 * Created by inagarajan on 10/12/15.
 */
app.controller('AddInstitutionCtrl', ['$scope', '$rootScope','$location','applicationService',function($scope, $rootScope, $location, applicationService) {

    $scope.$root.headerAction = 'hidden';
    $scope.parent_exisiting = true;
    $scope.showParentSelection = false;
    var DEFAULT_ID = -1;
    $scope.defaultId = DEFAULT_ID;
    $scope.institution = {
        'state':{},
        'country':{}
    }
    $scope.parent_institution = {
        'state':{},
        'country':{}
    }
    $scope.showCancel = false;
    $scope.showAddParentInstitution = applicationService.getShowParentSelection();

    var getCountries = function(){
        applicationService.getCountries()
            .$promise.then(function (response) {
                $scope.countries = response;

                if($scope.countries){
                    var usa = applicationService.getCountryByCode($scope.countries, 'US');
                    if(usa){
                        $scope.institution.country.id = usa.id;
                        $scope.institution.country.name = usa.name;
                        $scope.parent_institution.country.id = usa.id;
                        $scope.parent_institution.country.name = usa.name;
                    }
                }
                getStates($scope.institution.country.id);
            }).catch(function error(response) {
                
            });
    }

    var getStates = function(countryId,type){
        
        applicationService.getStates(countryId)
            .$promise.then(function (response) {
                
                response.unshift({'id': DEFAULT_ID, 'name':'State / Province'});
                $scope.states = response;
                if(typeof type != "undefined"){
                    if(type== "parent") {
                        $scope.parent_institution.state.id = DEFAULT_ID;
                    }else{
                        $scope.institution.state.id = DEFAULT_ID;
                    }
                }else {
                    $scope.institution.state.id = DEFAULT_ID;
                    $scope.parent_institution.state.id = DEFAULT_ID;
                }

            }).catch(function error(response) {
                
            });
    }

    $scope.selectCountry = function(selectedCountryId,type){
        var selectedCountry = applicationService.getCountryById($scope.countries, selectedCountryId);
        if (type== "parent") {
            $scope.parent_institution.country.name = selectedCountry.name;
            $scope.parent_institution.state.id = DEFAULT_ID;
        }else {
            $scope.institution.country.name = selectedCountry.name;
            $scope.institution.state.id = DEFAULT_ID;
        }

        getStates(selectedCountryId,type);
    }



    $scope.selectState = function(selectedStateId,type){
        
        var selectedState = applicationService.getStateById($scope.states, selectedStateId);
        if (type== "parent") {
            $scope.parent_institution.state.name = selectedState.name;
        }else {
            $scope.institution.state.name = selectedState.name;
        }
    }

    $scope.updateInstitutionOptions = function(){

        if($scope.parent_institution.name &&
            $scope.parent_institution.state.name &&
            $scope.parent_institution.country.name) {

            var country = $scope.parent_institution.country.name;
            var state = $scope.parent_institution.state.name;
            var namePhrase = $scope.parent_institution.name;

            applicationService.getInstitutionsResource({
                "customerType" : null,
                "country" : country,
                "state" : state,
                "likeName" : namePhrase
            })
                .$promise.then(function (institutions) {
                    $scope.institutionOptions = institutions;
                }).catch(function error(msg) {
                    
                });

        }
    }

    $scope.selectInstitution = function(institution){
        $scope.selectedInstitution = institution;
        $scope.parent_institution.name = institution.name;
        $scope.disableSubmit = false;
    }

    $scope.getParentDetails = function(){
        if($scope.institution.isParent == "0"){
            $scope.parent_yes = true;
            $scope.parent_no = false;
           // getStates($scope.parent_institution.country.id);
        }
        else {
            $scope.parent_yes = false;
            $scope.parent_no = true;
        }
    }

    $scope.cancel = function(){
        $location.path('/' + applicationService.getOriginalPath()).replace();
    }

    $scope.addParentInstitution = function() {
        if (!$scope.showAddParentInstitution) {
            addInstitution("166");
        }else {
            if ($scope.institution.isParent == 0) {
                if (!$scope.parent_exisiting) {
                    var customer = {
                        "name": $scope.parent_institution.name,
                        "customerType": "system",
                        "customerTypeId": "3",
                        "addressDTO": [
                            {
                                "addressTypeId": "3",
                                "address1": $scope.parent_institution.street_address1,
                                "address2": $scope.parent_institution.street_address1,
                                "city": $scope.parent_institution.city,
                                "stateDTO": {
                                    "id": $scope.parent_institution.state.id,
                                    "name": $scope.parent_institution.state.name
                                },
                                "countryDTO": {
                                    "id": $scope.parent_institution.country.id,
                                    "name": $scope.parent_institution.country.name
                                },
                                "postalCode": $scope.parent_institution.zip
                            }
                        ]
                    };
                    if ($scope.parent_institution.phone != "undefined" && $scope.parent_institution.phone != "") {
                        customer['phoneNumberDTO'] = {
                            "number": $scope.parent_institution.phone,
                            "phoneNumberTypeId": 2
                        }
                    }
                    applicationService.saveNewCustomer(customer, addParentSuccess, addParentError);
                } else {
                    addInstitution($scope.selectedInstitution.id);
                }
            } else {
                addInstitution();
            }
        }
    }

    var addInstitution = function(parentId) {
        var customer = {
            "name" : $scope.institution.name,
            "customerType" : "school",        //need more clarity as what to pass here
            "customerTypeId" : "2",
            "addressDTO" : [
                {
                    "addressTypeId": "3",
                    "address1": $scope.institution.street_address1,
                    "address2": $scope.institution.street_address2,
                    "city": $scope.institution.city,
                    "stateDTO": {
                        "id": $scope.institution.state.id,
                        "name": $scope.institution.state.name
                    },
                    "countryDTO": {
                        "id": $scope.institution.country.id,
                        "name": $scope.institution.country.name
                    },
                    "postalCode": $scope.institution.zip
                }
            ]
        };
        if(typeof parentId != "undefined") {
            customer['customerHierarchyDTO'] = {};
            customer.customerHierarchyDTO.parentCustomerId = parentId;
        }
        applicationService.saveNewCustomer(customer,addInstitutionSuccess,addInstitutionError);
    }

    var addParentSuccess = function (response) {
        var parentCustomer = response;
        addInstitution(parentCustomer.id);
    }

    var addParentError = function (response) {

    }

    var addInstitutionSuccess = function(response) {

        var originCallback = applicationService.getOriginCallback();
        if(originCallback){
            originCallback(response);
        }
        else {
            $location.path('/' + applicationService.getOriginalPath()).replace();
        }
    }

    var addInstitutionError = function(response) {
        
    }

    getCountries();

}]);