
app.directive('toggleSidebarOnScroll', function() {
  return function(scope, element, attrs){
    var topScrollSwitch = false;
    $(element[0]).scroll(function(){
      if(!$('.survey-edit__content').offset()){
        return;
      }
      if($('.survey-edit__content').offset().top <= 120) {
        $(this).addClass('open');
        if(topScrollSwitch === false){ 
          $('.survey-sidebar__content').scrollTop(0);
          topScrollSwitch = true;
        }
      } else {
        $(this).removeClass('open');
        topScrollSwitch = false;
      }

      // Check for bottom of page
        // if($(window).scrollTop() + $(window).height() == $(document).height()){
        //   alert('something breaks');
        // }

      // Highlight items in sidebar

      var cur_pos = $(this).scrollTop(),
          sections = $('.survey-section, .survey-subsection, .survey-subheading'),
          nav = $('.survey-edit__sidebar'),
          nav_height = nav.outerHeight();

      sections.each(function() {
        var top = $(this).offset().top - 50,
            bottom = top + $(this).outerHeight() + 50;

        if (top <= 100 && top >= 0) {

          var elementScope = angular.element(this).scope()
          var el = elementScope.element || elementScope.section
          if(el != scope.topElement){
            scope.topElement = el;
            scope.$apply();
          }

        }
      });

    })
  }
})

