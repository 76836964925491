app.directive("magicScroll", ['$timeout', function($timeout) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      startIndex: '=',
      limit: '=',
      totalLength: '@'
    },
    template:
      '<ng-transclude></ng-transclude>',
    link: function(scope, element, attrs) {
      scope.container = document.getElementById('windowScrollContainer');

      $timeout(function() {
        var item = document.getElementsByClassName('parent-name')[0];
        if (item) {
          scope.itemHeight = item.offsetHeight;
          var itemsInContainer = Math.floor(scope.container.offsetHeight/scope.itemHeight);
          scope.limit = itemsInContainer * 10;
          scope.endIndex = scope.startIndex + scope.limit - 1;
        }
      });

        const scrollHandler = function(event) {
            if (scope.startIndex > 0) {
                if (scope.container.scrollTop < scope.itemHeight * 3) {
                    detatchScrollHandler();
                    console.log('top-trigger');
                    var topElement = document.getElementsByClassName('top-marker')[0];
                    scope.$apply(function() {
                        scope.startIndex = scope.startIndex - 40;
                        var originalOffsetTop = topElement.offsetTop;
                        var originalId = topElement.id;

                        $timeout(function() {
                            var elementFoundAgain = document.getElementById(originalId);
                            if(elementFoundAgain) {
                                var movement = originalOffsetTop - elementFoundAgain.offsetTop;
                                scope.container.scrollTop = scope.container.scrollTop - movement;
                            }
                            attachScrollHandler(50);
                        });
                    });
                }
            }

            if (scope.startIndex + scope.limit < scope.totalLength) {
                var bottomElement = document.getElementsByClassName('bottom-marker')[0];
                var position = bottomElement.offsetTop - scope.container.scrollTop - scope.container.offsetHeight;
                var threshold = bottomElement.offsetHeight * 3;
                if (position < threshold) {
                    detatchScrollHandler();
                    console.log('bottom-trigger');
                    scope.$apply(function() {
                        scope.startIndex = scope.startIndex + 40;
                        var originalOffsetTop = bottomElement.offsetTop;
                        var originalId = bottomElement.id;

                        $timeout(function() {
                            var elementFoundAgain = document.getElementById(originalId);
                            if(elementFoundAgain) {
                                var movement = originalOffsetTop - elementFoundAgain.offsetTop;
                                scope.container.scrollTop = scope.container.scrollTop - movement;
                            }
                            attachScrollHandler(50);
                        });
                    });
                }
            }
        };

        const attachScrollHandler = function(delay){
            if(!delay) {
                element.bind('wheel', scrollHandler);
            } else {
                $timeout(function() {
                    element.bind('wheel', scrollHandler);
                }, delay);
            }
        };

        const detatchScrollHandler = function(delay) {
            if(!delay) {
                element.unbind('wheel', scrollHandler);
            } else {
                $timeout(function() {
                    element.unbind('wheel', scrollHandler);
                }, delay);
            }
        };

        attachScrollHandler();

    }
  };
}]);
