app.directive('sharingChild', ['PublishHelperService', function(PublishHelperService) {
    return {
      restrict: 'E',
      templateUrl: 'partials/templates/sharing/sharing-child.html',
      scope: {
        institution: '=',
        parent: '=',
        grandparent: '=',
        greatgrandparent: '=',
        ancestory: '@'
      },
      link: function(scope, element, attrs) {
        scope.toggleInstSelect = function(institution) {
          //if the institution is inactive don't do anything
          if (!institution.active) { return }
          // if the institution is active do the normal stuff
          scope.institution.selected = !scope.institution.selected;
          PublishHelperService.updateStatus(scope.parent);
          PublishHelperService.updateStatus(scope.grandparent);
          PublishHelperService.updateStatus(scope.greatgrandparent);
        };
      }
    }
  }
]);
