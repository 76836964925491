app.factory('PublishHelperService', [
  function() { return new PublishHelperService(); }
]);

function PublishHelperService() {
  
  const srvc = this;

  this.selectAllDescendants = function(inst) {
    inst.children.forEach(function (child) {
      if (child.active) {
        child.selected = true;
        child.children.forEach(function(grandchild) {
          if (grandchild.active) {
            grandchild.selected = true;
            grandchild.children.forEach(function(greatgrandchild) {
              if (greatgrandchild.active) {
                greatgrandchild.selected = true;
              }
            });
            grandchild.publishToChildren = grandchild.active && grandchild.hasChildren;

            updateStatus(grandchild);
          }
        });
        child.publishToChildren = child.active && child.hasChildren;
        updateStatus(child);
      }
    });
  };

  this.unselectAllDescendants = function(inst) {
    inst.children.forEach(function (child) {
      if (child.active) {
        child.selected = false;
        child.publishToChildren = false;
        child.children.forEach(function(grandchild) {
          if (grandchild.active) {
            grandchild.selected = false;
            grandchild.publishToChildren = false;
            grandchild.children.forEach(function(greatgrandchild) {
              if (greatgrandchild.active) {
                greatgrandchild.selected = false;
                greatgrandchild.publishToChildren = false;
                greatgrandchild.children.forEach(function(greatGreatGrandchild) {
                  greatGreatGrandchild.selected = false;
                });
                updateStatus(greatgrandchild);
              }
            });
            updateStatus(grandchild);
          }
        });
        updateStatus(child);
      }
    });
  };

  /**
   * Iterates through an array of institutions and sets them to not selected, and unpublish to children
   * Recursively calls self to traverse stack down
   * @param instArray
   */
  this.forceUnselectInstitutionsHierarchy = function(instArray) {
      if(instArray) {
          instArray.forEach(function (inst) {
              inst.selected = false;
              inst.publishToChildren = false;
              inst.status = 'unchecked';
              srvc.forceUnselectInstitutionsHierarchy(inst.children);
          });
      }
  };

  var updateStatus = function(inst) {
    if (inst.publishToChildren) { inst.publishToChildren = !hasUnselectedDescendant(inst); }
    if (inst.selected && inst.publishToChildren) { inst.status = 'check-all' }
    else if (inst.selected && !inst.publishToChildren) { inst.status = 'checked' }
    else if (!inst.selected && hasSelectedDescendant(inst)) { inst.status = 'check-partial' }
    else { inst.status = 'unchecked' }
  };

  this.updateStatus = updateStatus;

  var hasSelectedDescendant = function(inst) {
    if((inst.displayType === "FULL_CHECKED" || inst.publishToChildren) && inst.childInstitutionCount > 0 && !inst.childrenLoaded){
      return true;
    }else {

        for ( let i = 0; i < inst.children.length; i++ ) {
            var child = inst.children[i];
            if (child.selected || child.status == 'check-partial') {
                return true;
            }
            for ( let i2 = 0; i2 < child.children.length; i2++ ) {
                var grandchild = child.children[i2];
                if (grandchild.selected || child.status == 'check-partial') {
                    return true;
                }
                for ( let i3 = 0; i3 < grandchild.children.length; i3++ ) {
                    var greatgrandchild = grandchild.children[i3];
                    if (greatgrandchild.selected || child.status == 'check-partial') {
                        return true;
                    }
                }
            }
        }
        return false;
    }
  }

  this.hasSelectedDescendant = hasSelectedDescendant;

  function hasUnselectedDescendant(inst) {
    if(inst.displayType === "UNCHECKED" && inst.status === 'check-all' && inst.childInstitutionCount > 0 && !inst.childrenLoaded){
      return false;
    } else {
      for(var i=0; i < inst.children.length; i++) {
        var child = inst.children[i];
        if (!child.selected & child.active) { return true; }
        for ( let i2=0; i2 < child.children.length; i2++ ) {
          var grandchild = child.children[i2];
          if (!grandchild.selected & grandchild.active) { return true; }
          for ( let i3=0; i3 < grandchild.children.length; i3++ ) {
            var greatgrandchild = grandchild.children[i3];
            if (!greatgrandchild.selected & greatgrandchild.active) { return true; }
          }
        }
      }
      return false;
    }
  }
  this.hasUnselectedDescendant = hasUnselectedDescendant;
}
