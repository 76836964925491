/**
 * Created by pvo on 6/12/15.
 *
 * Notes: When institution is ready to be integrated, utilize $scope.institutionId to save the institution id when the user
 *  select it (similar to how a language is selected). Then replace $scope.survey.institutionId by $scope.institutionId in the
 *  saveClick().
 */
app.controller('SurveyTakingController', ['$http', '$scope', '$routeParams', '$rootScope', '$timeout', 'SurveyTakingService', '$location', '$route', '$sessionStorage',
    function($http, $scope, $routeParams, $rootScope, $timeout, SurveyTakingService, $location, $route, $sessionStorage) {
    

    $scope.CLOSED = 2;
    $scope.ARCHIVED = 4;
    $scope.RS_PARTIALLY_SUBMITTED = 3;
    $scope.RS_SUBMITTED = 2;
    $scope.EL_TYPE_ID_PAGEBREAK = 4;
    $scope.ses_token = $sessionStorage.token;
    $scope.ses_redirect_url = ses_redirect_url;
    $scope.date = new Date();

    var isAdminSurvey = false;

    $scope.init = function () {
        isAdminSurvey = $location.path().indexOf('/admin-survey/') > -1;
        if (isAdminSurvey) {
            $scope.enrollmentId = $routeParams.enrollmentId;
            $scope.ownerInstitutionId = $routeParams.ownerInstitutionId;
        }
        else {
            $scope.enrollmentId = null;
            $scope.ownerInstitutionId = null;
        }

        $rootScope.headerAction = 'hidden';

        if ($routeParams.closedSurveyName !== undefined) {
            $scope.closedSurveyName = $routeParams.closedSurveyName;
        }

        if ($routeParams.surveyId !== undefined) {
            $scope.surveyId = $routeParams.surveyId;
            $scope.preview = false;
        }
        else {
            $scope.surveyId = $routeParams.preSurveyId;
            $scope.preview = true;
        }
        $scope.loadingToken = false;
        if($routeParams.token){
            $scope.loadingToken = true;
            $location.search('token', null)
        }
        $scope.institutionId = $routeParams.institutionId;

        if ( $routeParams.languageId === '2' ) {
            $scope.rtlFlag = true;
        } else {
            $scope.rtlFlag = false;
        }
        $scope.beganTakingSurvey = false;
        $scope.loadedData = false;
        $scope.languageId = 1; //English
        $scope.beginElindex = -1;
        $scope.endElindex = -1;
        $scope.currPage = [];
        $scope.hasPageBreak = false;
        $scope.answer = new Object();
        $scope.redirectUrl = ADVANCED_URL;
        $scope.logoUrl = ADVANCED_LOGO;
        $scope.termsAndConditions = TERMS_AND_CONDITIONS_PDF_URL;
        $scope.submit = {inProgress: false, complete: false, pre: true};
        $scope.pInstitutionId = 0;
        $scope.token = { valid: false, id: null, value: null };
        $scope.tokenRequired = false;
        $scope.atLastPage = true;

        if ($routeParams.languageId !== undefined) {
            $scope.languageId = $routeParams.languageId;

            if ($routeParams.selectedInstitutionId === undefined) {
                //Display institution selector
                $scope.institutionId = $routeParams.institutionId;
                $scope.userAssociationsIds = [$scope.institutionId];
                $scope.selectOnlySchool = false;
                $scope.expandTopLevel = true;
                getSurveyNameSuccess($scope.languageId);
            }
            else {
                //User selected language and institution. The original url includes p<institutionId>
                $scope.pInstitutionId = $scope.institutionId;
                $scope.institutionId = $routeParams.selectedInstitutionId;

                if ($routeParams.tokenValue === undefined) {
                    $scope.getTokenRequired();
                } else {
                    $scope.token.value = $routeParams.tokenValue;

                    $scope.beginSurvey();
                }
            }
        }
        else {
            if ($scope.surveyId !== undefined) {
                if(!$scope.loadingToken) {
                    if ($scope.preview === false) {
                        //var institutionId = ($scope.institutionId.charAt(0) == 'p' ? $scope.institutionId.substring(1) : $scope.institutionId);
                        //multi-enrollment change
                        var instId = $scope.ownerInstitutionId == null ? $scope.institutionId : $scope.ownerInstitutionId;
                        var request = {surveyId: $scope.surveyId, institutionId: instId, isAdminSurvey: isAdminSurvey};

                        SurveyTakingService.getSurveyStatus(request).$promise.then(function (data) {
                            // success handler
                            if (data != null && data.success === false) {
                                $scope.handleError(data.message);
                            }
                            else {
                                //var statusName = data.message.split('|');
                                //TODO: save surveyName and isTokenRequired in session storage.
                                $sessionStorage.surveyNames = data.responseData.surveyNames;
                                $sessionStorage.isTokenRequired = data.responseData.isTokenRequired;

                                if (data.responseData.statusId == $scope.CLOSED || data.responseData.statusId == $scope.ARCHIVED) {
                                    $scope.survey = new Object();
                                    $location.path("/closed/" + data.responseData.surveyNames[0].name);
                                }
                                else {
                                    $scope.getSupportedLanguages();
                                }
                            }
                        }, function (error) {
                            // error handler
                            $scope.handleError(error.data.message);
                        });
                    }
                    else {
                        $scope.getSupportedLanguages();
                    }
                }
            }
        }
    };

    $scope.handleError = function(errorMessage) {
        if (errorMessage != null) {
            var sepIndex = errorMessage.indexOf('|');
            if (sepIndex >= 0) {
                //need to show the error message then redirect.
                var redirectLinkAndError = errorMessage.split('|');
                $scope.displayErrorAndRedirect(redirectLinkAndError[0], redirectLinkAndError[1]);
            }
            else {
                sweetAlert(errorMessage);
            }
        }
        else {
            sweetAlert("Server encounters an error.");
        }
    }

    $scope.displayErrorAndRedirect = function(redirectLink, errorMessage) {
        sweetAlert({title: "",
            text: errorMessage,
            type: "error",
            confirmButtonText: "OK",
            closeOnConfirm: true },
            function(isConfirm) {
                window.location = redirectLink;
            });
    }

    var getSurveyNameSuccess = function(languageId){
        if(!$scope.survey){
            $scope.survey = {};
        }
        if ($sessionStorage.surveyNames != null) {
            for (var i = 0; i < $sessionStorage.surveyNames.length; i++) {
                if ($sessionStorage.surveyNames[i].id == languageId) {
                    $scope.survey.surveyName = $sessionStorage.surveyNames[i].name;
                    $scope.truncateSurveyName();
                    break;
                }
            }
        }
    };

    $scope.handleInstitutionSelection = function(institution) {
        if ($scope.preview === false) {
            $location.path("/action/" + $scope.surveyId + '/' + $scope.institutionId + "/" + $scope.languageId + "/" + institution.id);
        }
        else {
            
        }
    };

    $scope.accessTokenPrompt = function() {
        if ($scope.languageId == spanish_language_id) {
            return spanish_accesstoken_prompt;
        }
        else {
            return english_accesstoken_prompt;
        }
    }

    $scope.takeSurveyText = function() {
        if ($scope.languageId == spanish_language_id) {
            return spanish_take_survey;
        }
        else {
            return english_take_survey;
        }
    }

    $scope.tokenErrorText = function() {
        if ($scope.languageId == spanish_language_id) {
            return spanish_token_error_message;
        }
        else {
            return english_token_error_message;
        }
    }

    //When this method is call, the survey is still open (NOT closed)
    $scope.getSupportedLanguages = function() {
        if ($scope.isPreviewCertifiedContent()) {
            SurveyTakingService.getSupportedLanguagesForTemplate({templateId: $scope.surveyId}).$promise.then(function (data) {
                // success handler
                

                $scope.setLanguages(data);
            }, function (error) {
                // error handler
                

                if ($scope.beganTakingSurvey) {
                    errorPopup(english_system_error_message, function(){
                        $scope.redirect();
                    });
                }
                else {
                    errorPopup(english_system_error_message, error.data.message, "error");
                }
            });
        }
        else {
            SurveyTakingService.getSupportedLanguagesForSurvey({surveyId: $scope.surveyId}).$promise.then(function (data) {
                // success handler
                

                $scope.setLanguages(data);
            }, function (error) {
                // error handler
                

                if ($scope.beganTakingSurvey) {
                    errorPopup(english_system_error_message, function(){
                        $scope.redirect();
                    });
                }
                else {
                    errorPopup(english_system_error_message, error.data.message, "error");
                }
            });
        }
    };

    $scope.setLanguages = function(data) {
        $scope.languages = data;

        if ($scope.languages.length == 1) {
            $scope.launchInstitutionSelector();
        }

    };

    $scope.launchInstitutionSelector = function() {
        //$scope.languageId, $scope.surveyId
        if ($scope.institutionId.charAt(0) === 'p') {
            if ($scope.preview === false) {
                $location.path("/action/" + $scope.surveyId + '/' + $scope.institutionId.substring(1) + "/" + $scope.languageId);
            }
            else {
                //$location.path("/preview/" + $scope.surveyId + '/' + $scope.institutionId.substring(1) + "/" + $scope.languageId);
                $scope.getTokenRequired();
            }
        }
        else {
            $scope.getTokenRequired();
        }
    };

    $scope.getTokenRequired = function() {
        $scope.tokenRequired = $sessionStorage.isTokenRequired;

        if (!$scope.tokenRequired) {
            $scope.beginSurvey();
        } else {
            getSurveyNameSuccess($scope.languageId);
        }
    };

    $scope.tokenValueBlur = function(callbackFunc) {
        var params = { surveyId: $scope.surveyId, tokenValue: $scope.token.value };

        if (token_manage_options.regexTokenValidate.test(params.tokenValue)) {
            SurveyTakingService.validateToken(params).$promise.then(function(data) {
                if (data != null && data.success === false) {
                    $timeout(validationWarning($scope.tokenErrorText()), 500);
                }
                else {
                    // token is valid
                    $scope.token.id = data.message;
                    $scope.token.valid = true;

                    if (typeof callbackFunc === "function") {
                        $timeout(callbackFunc(), 0);
                    }
                }
            }, function(error) {
                // token is invalid
                if(error.data){
                    console.log('The token failed validation. ' + error.data.message);
                }
                $timeout(validationWarning($scope.tokenErrorText()), 500);
            });
        } else {
            // token does not match the requirements
            $timeout(validationWarning($scope.tokenErrorText()), 500);
        }
    };

    $scope.launchSurvey = function() {
        if (!$scope.token.valid) { return $scope.tokenValueBlur($scope.launchSurvey); }

        if ($scope.institutionId.charAt(0) === 'p') {
            if ($scope.preview === false) {
                $location.path("/action/" + $scope.surveyId + '/' + $scope.pInstitutionId.substring(1) + '/' + $scope.languageId + '/' + $scope.institutionId + '/' + $scope.token.value);
            } else {
                $scope.beginSurvey();
            }
        } else {
            $scope.beginSurvey();
        }
    };

    $scope.isPreviewCertifiedContent = function() {
        var url = $location.url();
        var viewPath = '/previewCertifiedContent/';
        if (url.slice(0, viewPath.length) === viewPath) {
            return true;
        }

        return false;
    };

    $scope.beginSurvey = function() {
        $scope.beganTakingSurvey = true;
        $scope.surveyTaken = false;

        if ($scope.loadedData) {
            return;
        }

        if ($scope.isPreviewCertifiedContent()) {
            //Preview Certified Content
            var survey = {templateId: $scope.surveyId, languageId: $scope.languageId};
            SurveyTakingService.getSurveyTakingForCertifiedContent(survey).$promise.then(function (data) {
                // success handler
                data.redirectUrl = decodeURIComponent(data.redirectUrl);
                $scope.populateData(data);

            }, function (error) {
                // error handler
                
            });
        }
        else {
            var pInstitutionId = $scope.pInstitutionId;
            var institutionId = $scope.institutionId;

            var validPath = true;
            if ($scope.preview) {
                pInstitutionId = 0;
                institutionId = 0;
            }
            else  {
                if (pInstitutionId == 0 && institutionId == 0) {
                    validPath = false;
                    sweetAlert("Invalid survey URL.");
                }
            }

            if (validPath) {
                //multi-enrollment change
                var instId = $scope.ownerInstitutionId == null ? institutionId : $scope.ownerInstitutionId;
                var survey = {
                    surveyId: $scope.surveyId,
                    languageId: $scope.languageId,
                    parentInstitutionId: pInstitutionId,
                    institutionId: instId
                };

                SurveyTakingService.get(survey).$promise.then(function (data) {
                    data.redirectUrl = decodeURIComponent(data.redirectUrl);
                    if (data.feedbackDTO != null && data.feedbackDTO.success === false) {
                        $scope.handleError(data.feedbackDTO.message);
                    }
                    else {
                        // success handler
                        $scope.populateData(data);
                        if (data.status === "Draft") {
                            swal({
                                title: "This survey is a draft.",
                                text: "All responses will be discarded.",
                                timer: 2000,
                                showConfirmButton: false
                            });
                        }
                    }
                }, function (error) {
                    // error handler
                    $scope.handleError(error.data.message);
                });
            }
        }
    };

    $scope.truncateSurveyName = function() {
        $scope.preAlteredSurveyName = $scope.survey.surveyName;
        // style survey Name
        var maxLength = ('Student Survey for Effective Learning Environments').length;
        var surveyName_cache = {
            counter: 0,
            bin: []
        };
        $scope.survey.surveyName = $scope.survey.surveyName.split(' ');
        for (var i = 0; i < $scope.survey.surveyName.length; i++) {
            $scope.survey.surveyName[i] = $scope.survey.surveyName[i].split('');
            for (var j = 0; j < $scope.survey.surveyName[i].length; j++) {
                surveyName_cache.counter++;
                if (surveyName_cache.counter > maxLength) {
                    $scope.survey.surveyName[i][j] = '';
                }
            }
            $scope.survey.surveyName[i] = $scope.survey.surveyName[i].join('');
        }
        $scope.survey.surveyName = $scope.survey.surveyName.join(' ');
        $scope.survey.surveyName += '...';
    };

    $scope.populateData = function(data) {
        $scope.survey = data;
        $scope.removeInvalidPageBreak();
        $scope.populateEmoticon();
        $scope.separatePagesByItemPageBreak();
        $scope.truncateSurveyName();
        $scope.fixMultipleAnswerQuestionMinsAndMaxes();

        $scope.survey.questions = 0;
        if ($scope.survey.redirectUrl !== null) {
            $scope.redirectUrl = $scope.survey.redirectUrl;
        }

        if ($scope.survey.logoImageUrl !== null) {
            //update the database to change /echo/ to /surveys/. Then uncomment the line below
            $scope.logoUrl = $scope.survey.logoImageUrl;
        }

        // label matrices
        if ($scope.survey.elementList !== undefined && $scope.survey.elementList !== null) {
            var elementGroupId = null;
            for (var i = 0; i < $scope.survey.elementList.length; i++) {
                if ($scope.survey.elementList[i].childElementList !== undefined) {
                    for (var j = 0; j < $scope.survey.elementList[i].childElementList.length; j++) {
                        var currElementGroupId = $scope.survey.elementList[i].childElementList[j].elementGroupId;

                        if (currElementGroupId != null && currElementGroupId != elementGroupId) {
                            elementGroupId = currElementGroupId;
                            $scope.survey.elementList[i].childElementList[j].isPartOfMatrix = true;
                            $scope.survey.elementList[i].childElementList[j].isEven = true;
                        }

                        if (elementGroupId != null && currElementGroupId == elementGroupId &&
                                $scope.survey.elementList[i].childElementList[j].elementTypeId === 2 /*item*/) {
                            $scope.survey.elementList[i].childElementList[j].isPartOfMatrix = true;
                            $scope.survey.elementList[i].childElementList[j].isEven = !($scope.survey.elementList[i].childElementList[j - 1].isEven);
                        }
                    }
                }
            }
        }

        // number the sections
        if ($scope.survey.elementList !== undefined && $scope.survey.elementList !== null) {
            var j = 0;
            for (var i = 0; i < $scope.survey.elementList.length; i++) {
                if ($scope.survey.elementList[i].elementTypeId === 4 /*page break*/ || $scope.survey.elementList[i].elementTypeId === 3 /*message*/) {
                    j++;
                }
                else {
                    $scope.survey.elementList[i].number = String.fromCharCode(i - j + 65);

                    if ($scope.survey.elementList[i].elementTypeId === 1 /*section*/ && $scope.survey.elementList[i].childElementList !== undefined) {
                        for (var k = 0; k < $scope.survey.elementList[i].childElementList.length; k++) {
                            if ($scope.survey.elementList[i].childElementList[k].elementTypeId === 2 /*item*/) {
                                $scope.survey.questions += 1;
                            }
                        }
                    }
                }
            }

            $scope.calcNextPage();

            if ($scope.preview) {
                //Preview mode
                $scope.survey.surveyName += ' -- Preview';

                //swal("This survey is a draft.", "All responses will be discarded.")
                swal({ title: "This survey is a draft.", text: "All responses will be discarded.", timer: 2000, showConfirmButton: false });
                
            }
        }

        $scope.loadedData = true;
    };

    $scope.fixMultipleAnswerQuestionMinsAndMaxes = function() {
      $scope.survey.elementList.forEach(function(section) {
        if (section.childElementList) {
          section.childElementList.forEach(function(question) {
            if (question.renderTypeId === 2 && !(question.templateElement && question.minOptions <= question.maxOptions)) {
              question.minOptions = 1;
              question.maxOptions = question.optionList.length;
            }
          });
        }
      });
    };

    //manipulate on $scope.survey to separate pages by item pagebreaks
    $scope.separatePagesByItemPageBreak = function() {
        $scope.survey.newElementList = [];
        if ($scope.survey.elementList != null && $scope.survey.elementList != undefined) {
            for (var i = 0; i < $scope.survey.elementList.length; i++) {
                var section = $scope.survey.elementList[i];
                var newSection = angular.copy(section);
                newSection.childElementList = [];
                $scope.survey.newElementList.push(newSection);

                if (section.childElementList !== null && section.childElementList !== undefined) {
                    for (var j = 0; j < section.childElementList.length; j++) {
                        if (section.childElementList[j].elementTypeId === $scope.EL_TYPE_ID_PAGEBREAK) {
                            //page break between items;
                            var pagebreak = {elementTypeId: $scope.EL_TYPE_ID_PAGEBREAK, certifiedSection: section.certifiedSection};
                            $scope.survey.newElementList.push(pagebreak);

                            if (j < section.childElementList.length - 1) {
                                // create a continued section
                                newSection = angular.copy(section);
                                newSection.childElementList = [];
                                newSection.name = newSection.name + " (continued)";
                                $scope.survey.newElementList.push(newSection);
                            }
                        }
                        else {
                            newSection.childElementList.push(section.childElementList[j]);
                        }
                    }
                }
            }
        }

        if ($scope.survey.newElementList.length > 0) {
            //elementList (sections) was parsed for page break as newElementList.
            $scope.survey.elementList = $scope.survey.newElementList;
        }
    }

    $scope.populateEmoticon = function() {
        if ($scope.survey.elementList != null && $scope.survey.elementList != undefined) {
            for (var i = 0; i < $scope.survey.elementList.length; i++) {
                var section = $scope.survey.elementList[i];
                if (section.childElementList !== null && section.childElementList !== undefined) {
                    for (var j = 0; j < section.childElementList.length; j++) {
                        if (section.childElementList[j].elementTypeId === 2 && section.childElementList[j].renderTypeId === 5) {
                            if (section.childElementList[j].optionList !== undefined && section.childElementList[j].optionList !== null) {
                                //Detecting includeNAOption - the last option value of 0 means that includeNAOption is true.
                                if (section.childElementList[j].optionList[section.childElementList[j].optionList.length - 1].value === 0) {
                                    //there is a value of 0 means that includeNAOption true;
                                    section.childElementList[j].includeNAOption = true;
                                }
                                for (var k = 0; k < section.childElementList[j].optionList.length; k++) {
                                    //delete the order property copied from optionList
                                    delete section.childElementList[j].optionList[k].order;

                                    if (section.childElementList[j].includeNAOption) {
                                        if (k == section.childElementList[j].optionList.length - 1) {
                                            //The N/A icon name is not in the scaleChoices array.
                                            section.childElementList[j].optionList[k].icon = 'icon-block';
                                        }
                                        else {
                                            if (section.childElementList[j].optionList.length === 4) {
                                                section.childElementList[j].optionList[k].icon = scaleChoices[3][k].icon;
                                            }
                                            else if (section.childElementList[j].optionList.length === 5) {
                                                section.childElementList[j].optionList[k].icon = scaleChoices[4][k].icon;
                                            }
                                            else if (section.childElementList[j].optionList.length === 6) {
                                                section.childElementList[j].optionList[k].icon = scaleChoices[5][k].icon;
                                            }
                                        }
                                    }
                                    else {
                                        if (section.childElementList[j].optionList.length === 3) {
                                            section.childElementList[j].optionList[k].icon = scaleChoices[3][k].icon;
                                        }
                                        else if (section.childElementList[j].optionList.length === 4) {
                                            section.childElementList[j].optionList[k].icon = scaleChoices[4][k].icon;
                                        }
                                        else if (section.childElementList[j].optionList.length === 5) {
                                            section.childElementList[j].optionList[k].icon = scaleChoices[5][k].icon;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    };

    //Manual section break is still support. Items page break is supported in both via survey edit or manual database insertion.
    $scope.removeInvalidPageBreak = function() {
        if ($scope.survey.elementList !== null && $scope.survey.elementList !== undefined && $scope.survey.elementList.length > 0) {
            ///////////////////////////////////
            //remove the page break at the top.
            //section page break
            var topPageBreaks = 0;
            for (var i = 0; i < $scope.survey.elementList.length; i++) {
                if ($scope.survey.elementList[i].elementTypeId === 4) {
                    topPageBreaks++;
                }
                else {
                    break;
                }
            }

            if (topPageBreaks > 0) {
                $scope.survey.elementList.splice(0, topPageBreaks);
            }

            //////////////////////////////////////
            //remove the page break at the bottom.
            //section page break
            for (var i = $scope.survey.elementList.length - 1; i >= 0; i--) {
                if ($scope.survey.elementList[i].elementTypeId === 4) {
                    $scope.survey.elementList.pop();
                }
                else {
                    break;
                }
            }
            //item page break
            var lastSection = $scope.survey.elementList[$scope.survey.elementList.length - 1];
            if (lastSection.childElementList !== null && lastSection.childElementList !== undefined && lastSection.childElementList.length > 0) {
                for (var i = lastSection.childElementList.length - 1; i >= 0; i--) {
                    if (lastSection.childElementList[i].elementTypeId === 4) {
                        lastSection.childElementList.pop();
                    }
                    else {
                        break;
                    }
                }
            }

            ////////////////////////////////////////////
            //remove redundant page break in the middle.
            //section page break
            if ($scope.survey.elementList.length > 0) {
                var moreToEvaluate = true;
                while (moreToEvaluate) {
                    moreToEvaluate = false;
                    for (var i = 0; i < $scope.survey.elementList.length - 1; i++) {
                        if ($scope.survey.elementList[i].elementTypeId === 4 && $scope.survey.elementList[i + 1].elementTypeId === 4) {
                            $scope.survey.elementList.splice(i + 1, 1);
                            moreToEvaluate = true;
                        }
                    }
                }
            }
            //item page break
            for (var h = 0; h < $scope.survey.elementList.length; h++) {
                var section = $scope.survey.elementList[h];
                if (section.childElementList !== null && section.childElementList !== undefined && section.childElementList.length > 0) {
                    var moreToEvaluate = true;
                    while (moreToEvaluate) {
                        moreToEvaluate = false;
                        for (var i = 0; i < section.childElementList.length - 1; i++) {
                            if (section.childElementList[i].elementTypeId === 4 && section.childElementList[i + 1].elementTypeId === 4) {
                                section.childElementList.splice(i + 1, 1);
                                moreToEvaluate = true;
                            }
                        }
                    }
                }
            }
        }
    };

    //Calculate next page
    $scope.calcNextPage = function() {

        $scope.atLastPage = true;
        $scope.currPage.length = 0;
        $scope.beginElindex = $scope.endElindex + 1;
        $scope.endElindex = $scope.beginElindex;
        for (var i = $scope.beginElindex; i < $scope.survey.elementList.length; i++) {
            $scope.currPage.push($scope.survey.elementList[i]);
            $scope.endElindex = i;
            if ($scope.survey.elementList[i].elementTypeId === 4) {
                $scope.hasPageBreak = true;
                $scope.atLastPage = false;
                break;
            }
        }

        $scope.currPage[$scope.currPage.length - 1].lastElement = true;

        $('.page-container').animate({scrollTop: $('.page-container').scrollTop(0)}, 1);

        //$scope.resetTabThrough(0);
    };

    $scope.calcPrevPage = function() {
        $scope.currPage.length = 0;
        $scope.endElindex = $scope.beginElindex - 1;

        //Calc begin index
        $scope.beginElindex = $scope.endElindex - 1;
        var beginListReach = false;
        while ($scope.survey.elementList[$scope.beginElindex].elementTypeId !== 4) {
            if ($scope.beginElindex === 0) {
                beginListReach = true;
                break;
            }
            else {
                $scope.beginElindex -= 1;
            }
        }

        if (beginListReach == false) {
            $scope.beginElindex += 1;
        }

        for (var i = $scope.beginElindex; i <= $scope.endElindex; i++) {
            $scope.currPage.push($scope.survey.elementList[i]);
        }

        $scope.currPage[$scope.currPage.length - 1].lastElement = true;

        $('.page-container').animate({scrollTop: $('.page-container').scrollTop(0)}, 1);


    };

    $scope.hasCertifiedSection = function() {
        if ($scope.currPage !== undefined && $scope.currPage !== null) {
            for (var i = 0; i < $scope.currPage.length; i++) {
                if ($scope.currPage[i].certifiedSection) {
                    return true;
                }
            }
        }

        return false;
    }

    $scope.unansweredElements = { bool: false, list: [] };

    $scope.answeredAllRequiredQuestions = function() {
        for (var i = 0; i < $scope.survey.elementList.length; i++) {
            if ($scope.survey.elementList[i].elementTypeId === 1 /*section*/ && $scope.survey.elementList[i].childElementList !== undefined) {
                for (var k = 0; k < $scope.survey.elementList[i].childElementList.length; k++) {
                    if ($scope.survey.elementList[i].childElementList[k].elementTypeId === 2 /*item*/) {
                        var element = $scope.survey.elementList[i].childElementList[k];
                        if (element.required === true && element.answer !== true) {
                            return false;
                        } else if (pickMultipleAnswerValidation(element)) {
                            // if pick multiple, we still want to make sure it is a valid answer when an answer is given
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    };

    $scope.allRequiredQuestionsOnPageAnswered = function() {
        $scope.unansweredElements.list = [];
        var unansweredList = [];
        for (var i = 0; i < $scope.currPage.length; i++) {
            var elementListI = $scope.currPage[i];
            if (elementListI.elementTypeId === 1 /*section*/ && elementListI.childElementList !== undefined) {
                for (var k = 0; k < elementListI.childElementList.length; k++) {
                    var questionErr = $('#unanswered_' + elementListI.childElementList[k].id + '_');
                    if (elementListI.childElementList[k].elementTypeId === 2 /*item*/) {
                        var element = elementListI.childElementList[k];

                        $(questionErr).addClass('ng-hide');
                        if (element.required === true && element.answer !== true) {
                            $scope.unansweredElements.list.push(element);
                            unansweredList.push(element);
                            $(questionErr).removeClass('ng-hide');
                        } else if ( pickMultipleAnswerValidation(element) ) {
                            // if pick multiple, we still want to make sure it is a valid answer when an answer is given
                            $scope.unansweredElements.list.push(element);
                            unansweredList.push(element);
                            $(questionErr).removeClass('ng-hide');
                        }
                    }
                }
            }
        }

        if ( unansweredList.length > 0 ) {
            $scope.unansweredElements.bool = true;
            return false;
        }

        return true;
    };

    function pickMultipleAnswerValidation(element) {
      return element.renderTypeId === 2 && (element.required || $scope.answer["E"+element.id] !== undefined) && !element.answer;
    }

    $scope.elementInCurrPage = function(elementId) {
        for (var i = 0; i < $scope.currPage.length; i++) {
            var section = $scope.currPage[i];
            if (section.childElementList !== undefined && section.childElementList !== null) {
                for (var j = 0; j < section.childElementList.length; j++) {
                    var element = section.childElementList[j];
                    if (element.id === elementId) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    $scope.saveClick = function() {
        if($scope.submit.inProgress || $scope.submit.complete){
            // prevent user from ever being able to fire off subsequent save call if either of these two flags are true
            return;
        }
        $scope.allRequiredQuestionsOnPageAnswered();
        if ($scope.answeredAllRequiredQuestions()) {
            // clear sessionStorage from option randomization
            $scope.clearRandomization();

            if ($scope.preview) {
                console.log('$scope.preview');
                /*swal({   title: "Preview Success",
                    text: "Tap on OK to go back to edit page.",
                    type: "success",   showCancelButton: false,
                    confirmButtonText: "OK",
                    closeOnConfirm: true
                },function() {
                    $scope.toEditPageUrl();
                });*/

                $scope.toEditPageUrlImm();
                //$scope.toEditPageUrl();
            }
            else {
                //save the response and redirect (true).
                $scope.saveResponse($scope.redirect, $scope.RS_SUBMITTED);
            }
        }
        else {
            $scope.validationFailed();
        }
    };

    $scope.saveResponse = function(callbackFunc, responseStatus) {
        const parentInstitutionId = $routeParams.institutionId;

        var answerKeys = Object.keys($scope.answer);

        var answerList = [];
        for (var i = 0; i < answerKeys.length; i++) {
            var answerObj = new Object();
            answerObj.elementId = $scope.answer[answerKeys[i]].elementId;
            answerObj.optionId = $scope.answer[answerKeys[i]].optionId;
            answerObj.answerText = $scope.answer[answerKeys[i]].answerText;

            //send all elements' response - NOT only current page.
            answerList.push(answerObj);
        }

        //convert the answerList to JSON string
        var answerString = JSON.stringify(answerList);
        var skippedOptionalQuestions = (answerKeys.length < $scope.survey.questions);
        var takingUrl = $location.absUrl();

        var response = {
            responseId: ($scope.responseId === undefined ? null : $scope.responseId),
            administrativeSurvey: isAdminSurvey,
            responseStatus: responseStatus,
            svyId: $scope.surveyId,
            lggId: $scope.languageId,
            institutionId: $scope.institutionId,
            enrollmentId: $scope.enrollmentId,
            parentInstitutionId: parentInstitutionId,
            answerString: answerString,
            skippedOptionalQuestions: skippedOptionalQuestions,
            absUrl: takingUrl,
            tokenId: $scope.token.id
        };

        if ($scope.responseId !== 'success') {
            if ($scope.atLastPage) {
                // show loading icon
                $scope.submit = {inProgress: true, complete: false, pre: false};
                setTimeout(function () {
                    SurveyTakingService.response(response).$promise.then(function (data) {
                        $scope.responseId = data ? data.message : undefined;
                        if (callbackFunc !== undefined) {
                            if (!data || !data.success || data.success === false) {
                                // hide loading icon
                                $scope.submit = {inProgress: false, complete: false, pre: false};
                                sweetAlert({
                                        title: "Failed to save survey responses",
                                        text: data ? data.message : "Unknown error",
                                        type: "error",
                                        confirmButtonText: "OK",
                                        closeOnConfirm: true
                                    },
                                    function (isConfirm) {
                                        window.location.reload();
                                    });
                            }
                            else {
                                // hide loading icon, show complete icon
                                $scope.submit = {inProgress: false, complete: true, pre: false};
                                setTimeout(function () {
                                    callbackFunc();
                                }, 500)

                            }
                        }
                    }, function (error) {
                        // hide loading icon
                        $scope.submit = {inProgress: false, complete: false, pre: false};
                        // error handler
                        sweetAlert({
                                title: "Failed to save survey responses",
                                text: error && error.data ? error.data.message : 'Unknown error',
                                type: "error",
                                confirmButtonText: "OK",
                                closeOnConfirm: true
                            },
                            function (isConfirm) {
                                // for admin surveys redirect to ses dashboard
                                if ($sessionStorage.token) {
                                    window.location = ses_redirect_url;
                                }
                            }
                        );
                    });
                }, 500);
            }
            else {
                callbackFunc();
            }
        }
    }

    $scope.toEditPageUrl = function() {
        if ($scope.isPreviewCertifiedContent()) {
            if ($scope.survey.endAction === 'reset') {
                $route.reload();
            }
            else {
                $scope.toCertifiedContentPageUrl();
            }
        }
        else {
            if ($scope.survey.endAction === 'reset') {
                $route.reload();
            }
            else {
                var editUrl = window.location.href.replace('preview', 'edit');

                var previewIndex = window.location.href.indexOf('/pre-view/');
                if (previewIndex > 0) {
                    editUrl = window.location.href.replace('pre-view', 'edit');
                }

                window.location = editUrl;
            }
        }
    };

    $scope.goToReset = function() {
        var url = $location.url();
            url = url.split('/');
            url.pop();
            url = url.join('/');
        localStorage.surveyRedirect = false;
        $location.url(url);
    };

    $scope.toEditPageUrlImm = function() {
        if ($scope.isPreviewCertifiedContent()) {
            $scope.toCertifiedContentPageUrl();
        }
        else {
            var previewIndex = window.location.href.indexOf('/pre-view/');
            if (previewIndex > 0) {
                var viewLocation = window.location.href.substring(0, previewIndex) + '/view/' + $scope.surveyId;
                window.location = viewLocation; //window.location.href.replace('/pre-view/', '/view/');
            }
            else {
                window.location = window.location.href.replace('/preview/', '/edit/');
            }
        }
    };

    $scope.toCertifiedContentPageUrl = function() {
        
        var previewCertifiedContentUrl = window.location.href.substring(0, window.location.href.indexOf('/previewCertifiedContent'));
        previewCertifiedContentUrl = previewCertifiedContentUrl + "/survey-library";
        window.location = previewCertifiedContentUrl;
        //$location.path("/survey-library");
    };

    $scope.redirect = function() {
        /*swal({   title: "Survey Complete",
            text: "Thanks for taking the survey!",
            type: "success",   showCancelButton: false,
            confirmButtonText: "OK",
            closeOnConfirm: false
        },function() {
            window.location = $scope.redirectUrl
        });*/

        //New requirement Dec 9, 2015. if reset, clear page to next survey taking. Else redirect.  No message.
        if ($scope.survey.endAction === 'reset') {
            //$route.reload();
            if ($routeParams.selectedInstitutionId !== undefined) {
                $location.path("/action/" + $scope.surveyId + '/p' + $scope.pInstitutionId);
            }
            else {
                //$location.path("/action/" + $scope.surveyId + '/' + $scope.institutionId);
                $route.reload();
            }
        }
        else {
            if ($sessionStorage.token) {
                window.location = ses_redirect_url;
            } else {
            window.location = $scope.redirectUrl;
            }
        }
    };

    $scope.validationFailed = function() {
        var firstUnansweredID = $scope.unansweredElements.list[0].id;
            firstUnansweredID = 'question_'+firstUnansweredID+'_';

         //errorPopup("Please answer all required questions.", function(){
             $('.page-container').animate({scrollTop: $('.page-container').scrollTop() + $('#'+firstUnansweredID).offset().top - 50 }, 500);
         //});

    };


    $scope.selectLanguage = function(languageId) {
        $scope.languageId = languageId;

        for (var i = 0; i < $scope.languages.length; i++) {
            if ($scope.languages[i].id === languageId) {
                $scope.rtlFlag = $scope.languages[i].rtl;
                return;
            }
        }

    };

    $scope.prevSectionClick = function() {
        $scope.atLastPage = false;
        $scope.calcPrevPage();
    };

    $scope.nextSectionClick = function($event) {
        if ($scope.allRequiredQuestionsOnPageAnswered()) {
            [$event.target, $event.currentTarget].forEach(function(elem) {
                elem.setAttribute('disabled', true);
            });

            if ($scope.preview) {
                $scope.calcNextPage();
            }
            else {
                $scope.saveResponse($scope.calcNextPage, $scope.RS_PARTIALLY_SUBMITTED);
            }
        }
        else {
            $scope.validationFailed();
        }
    };

    //Handle answers
    $scope.answerPickOne = function(element, optionId, answerText) {
        element.answer = false;

        answerText = answerText == null ? null : answerText.trim();

        var answer = new Object();
        answer.elementId = element.id;

        if (optionId != undefined ) {
            answer.optionId = '[' + optionId + ']';
            element.answer = true;
        }
        else {
            answer.optionId = null;
        }

        if (answerText != undefined && answerText != "") {
            answer.answerText = answerText;
            element.answer = true;
        }
        else {
            answer.answerText = null;
        }

        $scope.answer["E" + element.id] = answer;
    };

    $scope.answerPickMultiple = function(element, optionId) {
        if ($scope.answer["E" + element.id] == undefined) {
            var answer = new Object();
            answer.elementId = element.id;
            answer.optionId = '[' + optionId + ']';
            answer.answerText = null;
            $scope.answer["E" + element.id] = answer;
        } else {
            var answerOptions = $scope.answer["E" + element.id].optionId;
            //if previous answer exists, toggle it, else append it to the end
            if (answerOptions.indexOf('[' + optionId + ']') >= 0) {
                answerOptions = answerOptions.replace('[' + optionId + ']', '');
            } else {
                answerOptions = answerOptions + '[' + optionId + ']';
            }

            if (answerOptions === '') {
                delete $scope.answer["E" + element.id];
            } else {
                $scope.answer["E" + element.id].optionId = answerOptions;
            }
        }

        var answerOptionsCount = 0;
        if ($scope.answer["E" + element.id] !== undefined) {
            var answerOptions = $scope.answer["E" + element.id].optionId || "";
            answerOptionsCount = (answerOptions.match(/\[/g) || []).length;
        }

        element.answer = answerOptionsCount >= element.minOptions && answerOptionsCount <= element.maxOptions;
    };

    $scope.isChecked = function(elementId, elementOptionId) {
        if ($scope.answer["E" + elementId] != undefined) {
            if ($scope.answer["E" + elementId].optionId.indexOf('[' + elementOptionId + ']') >= 0) { //pick multiple answer
                return true;
            }
        }
        return false;
    };


    $scope.selectDropdownChanged = function(element, selectObject) {
        var answer = new Object();
        answer.elementId = element.id;
        answer.optionId = selectObject.id;
        answer.answerText = null;

        $scope.answer["E" + element.id] = answer;

        element.answer = true;
        
    };

    // orderBy function
    //   uses the elementOption id as an index to local storage for the previously established order
    $scope.orderRandomize = function(elementOption) {
        var elementOptionOrder = $sessionStorage["orderBy" + elementOption.id];

        if (elementOptionOrder == undefined) {
            elementOptionOrder = 0.5 - Math.random();

            $sessionStorage["orderBy" + elementOption.id] = elementOptionOrder;
        }

        return elementOptionOrder;
    };

    $scope.clearRandomization = function() {
        angular.forEach($sessionStorage, function(item, key) {
            if (/^(orderBy)/.test(key)) {
                delete $sessionStorage[key];
            }
        });
    };

    $scope.init();

}]);
