(function() {
        'use strict';
        angular.module('echo')
            .controller('TermsAndConditionsCtrl', TermsAndConditionsCtrl);

        angular.$inject = ['$scope',
            '$modalInstance',
            '$location',
            'userService'];

        function TermsAndConditionsCtrl(
            $scope,
            $modalInstance,
            $location,
            userService
        ){
            $scope.cancel = function(){
                $modalInstance.dismiss('cancel');
                $location.path('/app/logout');
            }

            $scope.updateTandC = function() {
                var userId = userService.getUserId();
                userService.updateTermsAndConditions(userId,updateTandCSuccess,updateTandCError);
            }

            $scope.openTandC = function() {
                window.open(TERMS_AND_CONDITIONS_PDF_URL, '_blank');
            }

            var updateTandCSuccess = function(response){
                //console.log(response.message);
                if(response.messageType == 'Success') {
                    userService.setTermsAndConditionsFlag(true);
                    $modalInstance.dismiss('cancel');
                }
            }

            var updateTandCError = function(){
                $modalInstance.dismiss('cancel');
                $location.path('/app/logout');
            }
        }
    }

)();

