(
    function() {
        'use strict';
        angular.module('echo')
            .factory( 'signInService', signInService);

        signInService.$inject = [ 'applicationService', 'userService', 'customerService', 'validationService', 'collectionUtils'];

        function signInService(applicationService, userService, customerService, validationService, collectionUtils) {
            var getCustomerHandler = function (customer) {
                applicationService.setCustomer(customer);
                applicationService.goToUserHomePage();
            }
            var updateStatusHandler = function (response) {

            }

            var myServiceObject = {
                mergeLists: function(listA, listB){
                    return collectionUtils.mergeLists(listA, listB);
                },

                getCustomerAndNext: function (customerId, associationId, isDirectAssociation) {
                    var association = userService.getAssociationById(associationId);
                    userService.saveUserAssociation(association, isDirectAssociation);
                    customerService.getCustomerById(customerId, getCustomerHandler, null);

                    if(isDirectAssociation && userService.isAssociationInvited(association)){
                        userService.updateStatus(userService.getUserId(), customerId, updateStatusHandler, null);
                    }
                },

                setCustomerForSuperUser: function(customerId) {
                    userService.selectedInstitution = null; // Clears out value in userService.
                    customerService.getCustomerById(customerId, getCustomerHandler, null);
                },

                validateEmail: function(field, errorsField) {
                    var errors = null;
                    if (!errors) errors = validationService.getRequiredFieldValidation(field);
                    if (!errors) errors = validationService.getRequiredFieldValidation(field.email);
                    if (!errors) errors = validationService.getEmailAddressValidation(field.email);

                    errorsField.email = errors;
                },

                validatePassword: function(field, errorsField){
                    var errors = null;
                    if(!errors) errors = validationService.getRequiredFieldValidation(field);
                    if(!errors) errors = validationService.getRequiredFieldValidation(field.password);

                    errorsField.password = errors;
                },

                validateConfirmPassword: function(field, errorsField){
                    var errors = null;
                    if(!errors) errors = validationService.getRequiredFieldValidation(field);
                    if(!errors) errors = validationService.getRequiredFieldValidation(field.confirmPassword);
                    if(!errors) errors = validationService.getEqualFieldsValidation(field.password, field.confirmPassword, "passwords");

                    errorsField.confirmPassword = errors;
                }
            };
            return myServiceObject;
        }
    }
)();