app.factory('reportPreferences', function(){
    var data = {
        reportId: null,
        reportName: null,
        fromDate: null,
        fromDateCleared: false,
        toDate: null,
        toDateCleared: false,
        surveyContentTypeId: '',
        surveyContentTypeName: null,
        networkAverage: null,
        institutions: [],
        havePreferences: false,
        prevStepNum: 1,
        surveys: [],
        institutionsByHierarchy: []
    }

    return {
        reset : function(){
            data.reportId = null;
            data.reportName = null;
            data.fromDate = null;
            data.fromDateCleared = false;
            data.toDate = null;
            data.toDateCleared = false;
            data.surveyContentTypeId = '';
            data.surveyContentTypeName = null;
            data.networkAverage = null;
            data.institutions = [];
            data.havePreferences = false;
            data.prevStepNum = 1;
            data.surveys = [];
            data.institutionsByHierarchy = [];
        },
        getReportId: function(){
            return data.reportId;
        },
        setReportId: function(reportId){
            data.reportId = reportId;
        },
        getReportName: function(){
            return data.reportName;
        },
        setReportName: function(reportName){
            data.reportName = reportName;
        },
        getFromDate: function(){
            return data.fromDate;
        },
        setFromDate: function(fromDate){
            data.fromDate = fromDate;
        },
        getFromDateCleared: function(){
            return data.fromDateCleared;
        },
        setFromDateCleared: function(fromDateCleared){
            data.fromDateCleared = fromDateCleared;
        },
        getToDate: function(){
            return data.toDate;
        },
        setToDate: function(toDate){
            data.toDate = toDate;
        },
        getToDateCleared: function(){
            return data.toDateCleared;
        },
        setToDateCleared: function(toDateCleared){
            data.toDateCleared = toDateCleared;
        },
        getSurveyContentTypeId: function(){
            return data.surveyContentTypeId;
        },
        setSurveyContentTypeId: function(surveyContentTypeId){
            data.surveyContentTypeId = surveyContentTypeId;
        },
        getSurveyContentTypeName: function(){
            return data.surveyContentTypeName;
        },
        setSurveyContentTypeName: function(surveyContentTypeName){
            data.surveyContentTypeName = surveyContentTypeName;
        },
        getGroupingApplied: function(){
            return data.groupingApplied;
        },
        setGroupingApplied: function(groupingApplied){
            data.groupingApplied = groupingApplied;
        },
        getSurveyContentTypeNetworkAverage: function(){
            return data.networkAverage;
        },
        setSurveyContentTypeNetworkAverage: function(networkAverage){
            data.networkAverage = networkAverage;
        },
        getInstitutions: function(){
            return data.institutions;
        },
        setInstitutions: function(institutions){
            data.institutions = institutions;
        },
        getInstitutionsByHierarchy: function(){
            return data.institutionsByHierarchy;
        },
        //setInstitutionsByHierarchy: function(institutions){ // TODO: Will - this method is not used in the app. Remove it.
        //    data.institutionsByHierarchy = institutionsByHierarchy;
        //},
        getHavePreferences: function(){
            return data.havePreferences;
        },
        setHavePreferences: function(havePreferences){
            data.havePreferences = havePreferences;
        },
        setPreviousStepNumber: function(prevStepNum){
           data.prevStepNum = prevStepNum;
        },
        getPreviousStepNumber: function(){
            return data.prevStepNum;
        },
        getSurveys: function(){
            return data.surveys;
        },
        setSurveys: function(surveys){
            data.surveys = surveys;
        },
        getData: function(){
            return data;
        },
        setData: function(d){
            data.fromDate = d.fromDate;
            data.fromDateCleared = d.fromDateCleared;
            data.toDate = d.toDate;
            data.toDateCleared = d.toDateCleared;
            data.surveyContentTypeId = d.surveyContentTypeId;
            data.surveyContentTypeName = d.surveyContentTypeName;
            data.surveyContentTypeCertified = d.surveyContentTypeCertified;
            data.networkAverage = d.networkAverage;
            data.institutions = d.institutions;
            data.havePreferences = true;
            data.prevStepNum = d.prevStepNum;
            data.surveys = d.surveys
            data.institutionsByHierarchy = d.institutionsByHierarchy;
            data.groupingApplied = d.groupingApplied;
        }
    }
});
