(function() {
    'use strict';

    function addEditUserPiiToggle() {
        return {
            restrict: 'E',
            replace:true,
            scope: {
              myUser: "="
            },
            template: '<div class="pii-toggle" ng-class="{\'pii-toggle-on\': myUser.isPIIUser}" ng-click="togglePii()" ng-model="myUser"><div class="pii-toggle-ball" ng-class="{\'pii-toggle-on\': myUser.isPIIUser}"></div></div></div>',
            link: function (scope, element, attrs) {
                scope.togglePii = function() {
                    scope.myUser.isPIIUser = !scope.myUser.isPIIUser;
                }
            }
        }
    }

    angular.module('echo')
        .directive('addEditUserPiiToggle', addEditUserPiiToggle);
})();