// TODO: Will - this file does not appear to be used and causes collisions with dashboardSparklines directive in src/app/directives/dashboard/dashboardSparklines.
//app.directive('dashboardSparkLines', ['$timeout', 'ActivityService', function($timeout, ActivityService) {
//    return{
//        restrict: 'E',
//        templateUrl: 'partials/dashboard/dashboardSparkLines.html',
//        link: function(scope,element,attrs){
//            scope.id = attrs.id;
//            var surveyInfo = scope.$eval(attrs.retrieveSurvey);
//            scope.snapshot = {};
//            scope.activity_spark_line_options = angular.copy(dashboard_activity_spark_line_options);
//            scope.showLastResponseDate = (surveyInfo.sDaysLastResponse >= 7); //criteria for showing date rather than activity
//            scope.lastResponseDate = surveyInfo.sLastResponseDt;
//            var activityLineWidthGradient = [10, 18, 25, 32, 40, 48];
//
//            scope.getActivitySnapshot = function(id, status, daysActive)
//            {
//
//                var surveyRequestDTO = angular.copy(scope.surveyRequest);
//                surveyRequestDTO.surveyId = id;
//                surveyRequestDTO.status = status;
//                surveyRequestDTO.daysActive = daysActive;
//
//                ActivityService.activitySnapshot(surveyRequestDTO).$promise.then(function(data) {
//                    var snapshotSurvey = data.surveyDashboard;
//                    snapshotSurvey.activity = MG.convert.date(snapshotSurvey.activity, 'date');
//                    scope.snapshot = snapshotSurvey;
//                    if (scope.snapshot.activity && scope.snapshot.activity.length > 1 && (daysActive > 0 && daysActive < 7))
//                    {
//                        scope.activity_spark_line_options.width = scope.activity_spark_line_options.width - activityLineWidthGradient[((7-daysActive)-1)];
//                        //console.log("width " + scope.activity_spark_line_options.width);
//                    }
//                },function(httpResponse){
//                    $timeout(errorPopup, 500);
//                });
//
//            }
//            //retrieve activity if there have been responses and the last response date is within the last 7 days
//            if (!scope.showLastResponseDate && scope.lastResponseDate && !(scope.lastResponseDate == 'null')) scope.getActivitySnapshot(surveyInfo.sId, surveyInfo.sStatus, surveyInfo.sDaysActive);
//
//        }
//    }
//}]);