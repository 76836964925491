app.factory('ReminderService', [ '$resource', function($resource){
    return $resource(
        '/echo-api/api/reminders',
        null,
        {
            'reminder' : {
                url:  hostname + '/echo-api/api/reminders',
                method : 'POST',
                isArray: true
            }
        }
    );
}]);
