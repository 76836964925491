/**
 * Created by sghate on 11/5/15.
 */
app.factory('FileDownloadService', [ '$http','$q', '$timeout', '$window', function($http, $q, $timeout, $window) {
    return {

        downloadCSV: function (reportId, userId, languageId) {
          console.log("trying to download csv");
            return $http.get(reports_api_hostname + '/api/download/' +reportId + '/raw', {params: {userId: userId, languageId: languageId}, responseType: 'arraybuffer'}).then(function (response) {
                return response;
            });
        },

        download: function(reportId, userId, languageId, fileName) {
          console.log("hitting just download");

            var defer = $q.defer();

            $timeout(function() {
                $window.location = reports_api_hostname + '/api/download/' +reportId + '/raw_download?userId=' + userId + '&languageId=' + languageId + '&fileName=' + fileName;
            }, 1000)
                .then(function() {
                    defer.resolve('success');
                }, function() {
                    defer.reject('error');
                });
            return defer.promise;
        },

        reportSummary : function(reportSummaryCallback) {
            return $http.post(pdfreactor_api_hostname + '/surveys/generateReportPdf', null, {
                headers: {
                    "Content-Type": "application/json"
                },
                timeout: 20000,
                responseType: 'arraybuffer'
            }).then(function (response) {
                reportSummaryCallback(response.data);
            });
        }
    };
    /*return $resource(
            hostname +'/echo-api/api/download',
        null,
        {
            'downloadCSV': {url:  hostname + '/echo-api/api/download/reports/raw', method : 'GET', params:{userId: '@userId', languageId: '@languageId'}, responseType: 'arraybuffer'}
        }
    );*/
  }]);
