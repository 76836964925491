app.controller('TemplateItemCtrl', ['$q', '$scope', '$routeParams', 'userService', 'ContentTemplateService',
  function($q, $scope, $routeParams, userService, ContentTemplateService) {

    /*
    * Whenever an item is being changed/edited, it is set as the currentlyEditing
    * item. The DOM uses this to determine when to show the markup to edit the item
    * and when to show the markup that is just the view item mode.
    */
    $scope.currentlyEditing = null;

    $scope.questionTypes = getQuestionTypes();

    $scope.itemLabel = function(element, section) {
        if (section.childElementList !== undefined && section.childElementList !== null) {
            var messageCount = 0;
            for (var i = 0; i < section.childElementList.length; i++) {
                if (section.childElementList[i].elementTypeId === $scope.EL_TYPE_ID_MESSAGE || section.childElementList[i].elementTypeId === $scope.EL_TYPE_ID_PAGEBREAK
                        || section.childElementList[i].elementTypeId === $scope.EL_TYPE_ID_MATRIX) {
                    messageCount++;
                }
                else if (section.childElementList[i] === element) {
                    return (i + 1 - messageCount) + "";
                }
            }
        }

        return "";
    };

    $scope.canSaveOrCopyElement = function(element) {
      var hasTags = element.tags.length > 0;
      var hasTitle = element.text !== "" && element.text !== undefined && element.text !== null;
      var hasRenderType = element.renderType !== "" && element.renderType !== undefined && element.renderType !== null;
      return hasTags && hasTitle && hasRenderType;
    }

    $scope.getQuestionDropdownLabel = function(element) {
      var questionType = false;
      getQuestionTypes().forEach(function(type) {
        if ( this.renderType == type.textId ) {
          questionType = type;
        }
      }, element);
      return questionType ? questionType.label : 'Select';
    };

    $scope.getSelectedTags = function(element, section) {
      if (section.demographic) {
        return $scope.contentTemplate.demographicsList.filter(function(tag){
          return element.tags.indexOf(tag.id) !== -1;
        });
      } else {
        return $scope.contentTemplate.tagsList.filter(function(tag){
          return element.tags.indexOf(tag.id) !== -1;
        });
      }
    };

    $scope.getUnselectedTags = function(element, section) {
      if (section.demographic) {
        return $scope.contentTemplate.demographicsList.filter(function(tag){
          return element.tags.indexOf(tag.id) === -1;
        });
      } else {
        return $scope.contentTemplate.tagsList.filter(function(tag){
          return element.tags.indexOf(tag.id) === -1;
        });
      }
    };

    $scope.getSelectedAnswerTagName = function(categoryId) {
      return $scope.contentTemplate.optionCategoriesList.filter(function(tag){
        return tag.id === categoryId;
      })[0].name;
    };

    $scope.getSelectedEngagementDomainName = function(domainId) {
      return $scope.contentTemplate.engagementDomainList.filter(function(engagementDomain){
        return engagementDomain.id === parseInt(domainId,10);
      })[0].name;
    };

    $scope.getUnselectedAnswerTags = function(categoryId) {
      return $scope.contentTemplate.optionCategoriesList.filter(function(tag){
        return tag.id !== categoryId;
      });
    };

    $scope.getUnselectedEngagementDomainTags = function(domainId) {
      return $scope.contentTemplate.engagementDomainList.filter(function(engagementDomain){
        return engagementDomain.id !== domainId;
      });
    };


    $scope.getAddTagText = function(section) {
      return section.demographic ? 'set demographics' : 'add tags';
    };

    $scope.getMinMaxOptions = function(element) {
      return element.optionList.map(function(option, index) {
        return index + 1;
      });
    };

    $scope.showOptionTag = function(element) {
      var editing = element === $scope.currentlyEditing;
      var certified = $scope.contentTemplate.certified;
      var questionType = element.renderType === 'PICK_ONE' || element.renderType === 'PICK_MULTIPLE' || element.renderType === 'DROPDOWN';
      return editing && certified && questionType;
    };

    $scope.showEngagementDomainTag = function(element,section) {
      var editing = element === $scope.currentlyEditing;
      var certified = $scope.contentTemplate.certified;
      var questionType = element.renderType === 'PICK_ONE' ;
      return editing && certified && questionType && !section.demographic ;
    };

    /****
    * UI Interactions
    ****/
      $scope.elementSetScaleCount = function(element, count) {
        element.optionList = getNewElementOptionScaleList(element, count);
      };

      $scope.elementIncludeNAOption = function(element) {
        element.includeNAOption = !element.includeNAOption;
        if(element.includeNAOption) {
          var naOption = {value: 0, text: "N/A", icon: "icon-block"};
          element.optionList.push(naOption);
        } else {
          var index = element.optionList.length - 1;
          element.optionList.splice(index, 1);
        }
      };

      $scope.elementSetRenderType = function(element, type) {
        var newType = type.textId;
        var oldType = element.renderType;
        if (newType === 'OPINION_SCALE') {
          switchOptionListToScale(element);
        }
        if (oldType === 'OPINION_SCALE') {
          element.optionList = [];
        }
        element.renderType = newType;
      };

      $scope.deleteOption = function(element, optionIndex) {
        element.optionList.splice(optionIndex, 1);
        if (element.renderType === 'PICK_MULTIPLE') { validateSetMinMax(element); }
      };

      $scope.addOption = function(element) {
        element.optionList.push({text: '', value: null});
        if (element.renderType === 'PICK_MULTIPLE') { validateSetMinMax(element); }
      };

      $scope.setCurrentlyEditing = function(item) {
        $scope.currentlyEditing = item;
      };

      $scope.deleteSectionHelper = function(section, index) {
        if (section.demographic) {
          showDeleteDemographicModal(section, index)
        } else {
          showStandardDeleteModal(section, index)
        }
      };

      $scope.deleteItemHelper = function(element, section, index) {
        errorPopupChoice('Selecting yes will delete this item.', function(){
          deleteItem(element, section, index);
        });
      };

      $scope.elementAddTag = function(element, tag, section) {
        if ( section.demographic || section === 'answer-tag') {
          element.tags = [tag.id];
        } else {
          element.tags.push(tag.id);
        }
      };

      $scope.elementDeleteTag = function(element, tag) {
        var index = element.tags.indexOf(tag.id);
        element.tags.splice(index, 1);
      };

      $scope.optionSetCategory = function(element, id) {
        element.optionCategoryId = id;
      };

      $scope.setEngagementDomain = function(element, id) {
        element.engagementDomainId = id;
      };

      $scope.saveItemHelper = function(element, section, index, copyAfterSave) {
        if (['PICK_ONE','PICK_MULTIPLE','DROPDOWN'].indexOf(element.renderType) !== -1) {
          updateItemPickOneMultiDrop(element, section, index, copyAfterSave);
        }
        if (element.renderType === 'TEXT_FIELD') {
          updateItemTextField(element, section, index, copyAfterSave);
        }
        if (element.renderType === 'OPINION_SCALE') {
          updateItemOpinionScale(element, section, index, copyAfterSave);
        }
      };

      $scope.addSectionHelper = function(prevSection, prevSectionIndex) {
        swal({
          title: "Add New Section",
          text: "Do you want to add a standard section or a demographic section?",
          type: "info",
          showCancelButton: true,
          confirmButtonColor: "#D0D0D0",
          confirmButtonText: "Demographic",
          cancelButtonText: "Standard",
          closeOnConfirm: true
        }, function (confirmDemographic) {
             addSection(prevSection, prevSectionIndex, confirmDemographic);
          }
        );
      };

      $scope.validateAnswerValue = function(answer) {
        if (answer.value < 0) {
          sweetAlert("Error", "Answer value must be 0 or more", "error");
          answer.value = 0;
        } else if (answer.value > 99) {
          sweetAlert("Error", "Answer value must be less than 100", "error");
          answer.value = 99;
        }
      };

      $scope.validateMinOptions = function(element) {
        if (element.minOptions > element.maxOptions) {
          sweetAlert("Error", "Min picks must be less than or equal to the max picks", "error");
          element.minOptions = 1;
        }
      };

      $scope.validateMaxOptions = function(element) {
        if (element.minOptions > element.maxOptions) {
          sweetAlert("Error", "Max picks must be greater than the min picks", "error");
          element.maxOptions = element.optionList.length;
        }
      };
    /****
    * (END) UI Interactions
    ****/

    /****
    * API CALLS - calls to API endpoints
    ****/
      function addSection(prevSection, prevSectionIndex, demographicsSection) {
        var newSectionSort = prevSection.sort + 1;
        var newSectionElementIndex = prevSectionIndex + 1;
        var newSectionName = demographicsSection ? "Demographics" : "New Section";
        var body = {
          action: "NEW_SECTION",
          templateId: $scope.contentTemplate.templateId,
          userId: userService.getUserId(),
          languageId: $scope.contentTemplate.languageId,
          sectionName: newSectionName,
          label: "",
          sectionSort: newSectionSort,
          demographicsSection: demographicsSection
        };
        ContentTemplateService.updateTemplateElement(body).$promise.then(
          function(response) {
            var sectionId = response.message;
            var newSection = getNewSection(sectionId, body.sectionName, body.label, body.sectionSort, body.demographicsSection);
            $scope.contentTemplate.elementList.splice(newSectionElementIndex, 0, newSection);
            $scope.updateSectionLabels();
            scrollToSection(newSectionElementIndex);
          },
          function(error) {
            sweetAlert("There was an error creating the new section. This is most likely a problem connecting to the server. Please try again later.", error, "error");
          }
        );
      };

      $scope.updateSectionLabels = function() {
        var idLabelList = constructSectionLabels();
        var body = {
          action: "UPDATE_SECTION_LABEL",
          idsLabelsDTO: {idLabelList: idLabelList},
          templateId: $scope.contentTemplate.templateId,
          userId: userService.getUserId()
        };
        ContentTemplateService.updateTemplateElement(body).$promise.then(
          function(response) { },
          function(error) {
            sweetAlert("There was an error updating the section labels. This is most likely a problem connecting to the server. Please try again later.", error, "error");
          }
        );
      };

      $scope.updateSectionNameText = function(section) {
        var name = section.name || "";
        var text = section.text || "";
        var body = {
          action: "UPDATE_SECTION",
          templateId: $scope.contentTemplate.templateId,
          userId: userService.getUserId(),
          languageId: $scope.contentTemplate.languageId,
          elementId: section.id,
          sectionName: name,
          sectionText: text
        };
        ContentTemplateService.updateTemplateElement(body).$promise.then(
          function(response) { },
          function(error) {
            sweetAlert("There was an error updating the section name. This is most likely a problem connecting to the server. Please try again later.", error, "error");
          }
        );
      };

      function deleteSection(section, index) {
        var body = {
          action: "DELETE_SECTION",
          elementId: section.id,
          userId: userService.getUserId(),
          templateId: $scope.contentTemplate.templateId
        };
        ContentTemplateService.updateTemplateElement(body).$promise.then(
          function(response) {
            $scope.contentTemplate.elementList.splice(index, 1);
            $scope.updateSectionLabels();
          },
          function(error) {
            sweetAlert("There was an error deleting the section. This is most likely a problem connecting to the server. Please try again later.", error, "error");
          }
        );
      };

      $scope.addItem = function(section) {
        var sort = section.childElementList.length + 1;
        var label = getNextItemLabel(section);
        var body = {
          action: "NEW_ITEM",
          elementId: section.id,
          userId: userService.getUserId(),
          templateId: $scope.contentTemplate.templateId,
          languageId: $scope.contentTemplate.languageId,
          demographicsSection: section.demographic,
          templateItemUpdateDTO: {
            label: label,
            elementType:"ITEM",
            sort: sort
          }
        };
        ContentTemplateService.updateTemplateElement(body).$promise.then(
          function(response) {
            var newId = response.message;
            var newItem = getNewItem(newId, sort, label);
            section.childElementList.push(newItem);
            $scope.currentlyEditing = newItem;
          },
          function(error) {
            sweetAlert("There was an error adding the item. This is most likely a problem connecting to the server. Please try again later.", error, "error");
          }
        );
      };

      function copyItem(element, section, index) {
        var copiedItemIndex = index + 1;
        var body = {
          action: "COPY_ITEM",
          elementId: section.id,
          userId: userService.getUserId(),
          templateId: $scope.contentTemplate.templateId,
          languageId: $scope.contentTemplate.languageId,
          templateItemUpdateDTO: { elementId: element.id }
        };
        ContentTemplateService.updateTemplateElement(body).$promise.then(
          function(response) {
            var newItem = angular.copy(element);
            newItem.id = response.message.split(",")[0];
            newItem.sort = element.sort + 1;
            newItem.label = parseInt(element.label) + 1;
            newItem.optionList = getCopiedOptionList(element, response);

            //setupOptionListForScale is actually in TemplateCreateCtrl.js
            if(newItem.renderType === 'OPINION_SCALE') {
              $scope.setupOptionListForScale(newItem);
            }

            section.childElementList.splice(copiedItemIndex, 0, newItem);
            $scope.updateItemLabels(section);
          },
          function(error) {
            sweetAlert("There was an error copying the item. This is most likely a problem connecting to the server. Please try again later.", error, "error");
          }
        );
      }

      function deleteItem(element, section, index) {
        var body = {
          action: "DELETE_ITEM",
          elementId: section.id,
          userId: userService.getUserId(),
          templateId: $scope.contentTemplate.templateId,
          templateItemUpdateDTO: { elementId: element.id }
        };
        ContentTemplateService.updateTemplateElement(body).$promise.then(
          function(response) {
            section.childElementList.splice(index, 1);
            $scope.updateItemLabels(section);
          },
          function(error) {
            sweetAlert("There was an error deleting the item. This is most likely a problem connecting to the server. Please try again later.", error, "error");
          }
        );
      };

      function updateItemPickOneMultiDrop(element, section, index, copyAfterSave) {
        var optionList = element.optionList.map(function(option) {
          option.categoryId = element.optionCategoryId;
          return option;
        });
        var body = {
          action: "UPDATE_ITEM",
          elementId: section.id,
          userId: userService.getUserId(),
          templateId: $scope.contentTemplate.templateId,
          languageId: $scope.contentTemplate.languageId,
          demographicsSection: section.demographic,
          templateItemUpdateDTO: {
            elementId: element.id,
            elementType: "ITEM",
            sort: element.sort,
            text: element.text,
            optionScaleList: null,
            optionList: optionList,
            tagList: element.tags,
            renderType: element.renderType,
            required: element.required,
            minOptions: element.minOptions,
            maxOptions: element.maxOptions,
            randomizeOptions: element.randomizeOptions,
            engagementDomains: element.engagementDomainId?[element.engagementDomainId]:[]
          }
        };
        ContentTemplateService.updateTemplateElement(body).$promise.then(
          function(response) {
            if( !copyAfterSave ) { $scope.setCurrentlyEditing(null); }
            if( copyAfterSave ) { copyItem(element, section, index); }
            if( elementHasNewAnswers(element) ) { setNewOptionIds(element, response); }
          },
          function(error) {
            sweetAlert("There was an error updating the item answers. This is most likely a problem connecting to the server. Please try again later.", error, "error");
          }
        );
      };

      //This should be update item text for text field questions
      function updateItemTextField(element, section, index, copyAfterSave) {
        var body = {
          action: "UPDATE_ITEM",
          elementId: section.id,
          userId: userService.getUserId(),
          templateId: $scope.contentTemplate.templateId,
          languageId: $scope.contentTemplate.languageId,
          demographicsSection: section.demographic,
          templateItemUpdateDTO: {
            elementId: element.id,
            elementType: "ITEM",
            sort: element.sort,
            text: element.text,
            optionScaleList: null,
            optionList: null,
            tagList: element.tags,
            renderType: element.renderType,
            required: element.required,
          }
        };
        ContentTemplateService.updateTemplateElement(body).$promise.then(
          function(response) {
            if( !copyAfterSave ) { $scope.setCurrentlyEditing(null); }
            if( copyAfterSave ) { copyItem(element, section, index); }
          },
          function(error) {
            sweetAlert("There was an error updating the item text. This is most likely a problem connecting to the server. Please try again later.", error, "error");
          }
        );
      };

      function updateItemOpinionScale(element, section, index, copyAfterSave) {
        var body = {
          action: "UPDATE_ITEM",
          elementId: element.id,
          userId: userService.getUserId(),
          templateId: $scope.contentTemplate.templateId,
          languageId: $scope.contentTemplate.languageId,
          demographicsSection: section.demographic,
          templateItemUpdateDTO: {
            elementId: element.id,
            elementType: "ITEM",
            sort: element.sort,
            text: element.text,
            optionScaleList: constructOptionScaleList(element),
            optionList: null,
            tagList: element.tags,
            renderType:"OPINION_SCALE",
            required: element.required,
            useEmoticon: element.useEmoticon,
            includeNAOption: element.includeNAOption
          }
        };
        ContentTemplateService.updateTemplateElement(body).$promise.then(
          function(response) {
            if( !copyAfterSave ) { $scope.setCurrentlyEditing(null); }
            if( copyAfterSave ) { copyItem(element, section, index); }
            if( elementHasNewAnswers(element) ) { setNewOptionIds(element, response); }
          },
          function(error) {
            sweetAlert("There was an error updating the item opinion scale. This is most likely a problem connecting to the server. Please try again later.", error, "error");
          }
        );
      };

      $scope.addPageBreak = function(section) {
        var sort = section.childElementList.length + 1;
        var body = {
          action: "NEW_PAGE_BREAK",
          elementId: section.id,
          userId: userService.getUserId(),
          templateId: $scope.contentTemplate.templateId,
          languageId: $scope.contentTemplate.languageId,
          demographicsSection: section.demographic,
          templateItemUpdateDTO: {
            elementType: "PAGE_BREAK",
            sort: sort
          }
        };
        ContentTemplateService.updateTemplateElement(body).$promise.then(
          function(response) {
            var id = response.message;
            var newPageBreak = getNewPageBreak(id, sort);
            section.childElementList.push(newPageBreak);
          },
          function(error) {
            sweetAlert("There was an error adding the page break. This is most likely a problem connecting to the server. Please try again later.", error, "error");
          }
        );
      };

      $scope.addMessage = function(section) {
        var sort = section.childElementList.length + 1;
        var body = {
          action: "NEW_MESSAGE",
          elementId: section.id,
          userId: userService.getUserId(),
          templateId: $scope.contentTemplate.templateId,
          languageId: $scope.contentTemplate.languageId,
          demographicsSection: section.demographic,
          templateItemUpdateDTO: {
            elementType: "MESSAGE",
            sort: sort,
          }
        };
        ContentTemplateService.updateTemplateElement(body).$promise.then(
          function(response) {
            var id = response.message;
            var newMessage = getNewMessage(id, sort);
            section.childElementList.push(newMessage);
          },
          function(error) {
            sweetAlert("There was an error adding the message. This is most likely a problem connecting to the server. Please try again later.", error, "error");
          }
        );
      };

      $scope.updateMessage = function(section, element) {
        var body = {
          action: "UPDATE_MESSAGE",
          elementId: section.id,
          userId: userService.getUserId(),
          templateId: $scope.contentTemplate.templateId,
          languageId: $scope.contentTemplate.languageId,
          demographicsSection: section.demographic,
          templateItemUpdateDTO: {
            elementId: element.id,
            elementType: "MESSAGE",
            sort: element.sort,
            text: element.text,
          }
        };
        ContentTemplateService.updateTemplateElement(body).$promise.then(
          function(response) { },
          function(error) {
            sweetAlert("There was an error updating the message. This is most likely a problem connecting to the server. Please try again later.", error, "error");
          }
        );
      };

      $scope.updateItemLabels = function(section) {
        var idLabelList = $scope.constructItemLabels(section);
        var body = {
          action: "UPDATE_ITEM_LABEL",
          idsLabelsDTO: {idLabelList: idLabelList},
          userId: userService.getUserId(),
          templateId: $scope.contentTemplate.templateId
        };
        ContentTemplateService.updateTemplateElement(body).$promise.then(
          function(response) { },
          function(error) {
            sweetAlert("There was an error updating the item labels. This is most likely a problem connecting to the server. Please try again later.", error, "error");
          }
        );
      };

    /****
    * (END) API CALLS
    ****/

    /****
    * Other Functions
    ****/
      function nextLabelIndex(elements) {
        for (var i = elements.length - 1; i >= 0; --i) {;

        }
      }

      function scrollToSection(index) {
        setTimeout(function() {
          $('.page-container').animate({scrollTop: $('.page-container').scrollTop() + $('#section-' + index).offset().top - 50}, 500);
        }, 500);
      }

      function getCopiedOptionList(element, response) {
        var newIds = response.message.split(",").slice(1);
        return element.optionList.map(function(option, index) {
          return {id: newIds[index], text: option.text, value: option.value};
        });
      }

      function validateSetMinMax(element) {
        var optionCount = element.optionList.length;
        element.minOptions = isValid(element.minOptions, optionCount) ? element.minOptions : 1;
        element.maxOptions = isValid(element.maxOptions, optionCount) ? element.maxOptions : optionCount;
      }

      function isValid(num, count) {
        return num >= 1 && num <= count;
      }

      function elementHasNewAnswers(element) {
        var newAnswers = false;
        element.optionList.forEach(function(element) {
          if ( element.id === undefined ) { newAnswers = true; }
        });
        return newAnswers;
      }

      function setNewOptionIds(element, response) {
        var newIds = response.message.split(",");
        var optionMissingId = getOptionMissingId(element);
        var optionIndex = element.optionList.indexOf(optionMissingId);
        if (optionIndex !== -1) {
          newIds.forEach(function(id) {
            element.optionList[optionIndex].id = id;
            optionIndex++;
          });
        }
      }

      function getOptionMissingId(element) {
        var option;
        for ( let i = 0; i < element.optionList.length; i++ ) {
          if ( element.optionList[i].id === undefined ) {
            option = element.optionList[i];
            break;
          }
        }
        return option;
      }

      function constructOptionScaleList(element) {
        return element.optionList.map(function(option){
          return {value: option.value, text: option.text, id: option.id};
        });
      }

      function constructSectionLabels() {
        var nextLabel = 1;
        var idLabelList = [];
        $scope.contentTemplate.elementList.forEach(function(element, index) {
          if (element.elementTypeId == 1) {
            element.label = getAlphabeticalLabel(nextLabel);
            nextLabel++;
          } else {
            element.label = "";
          }
          element.sort = index + 1;
          idLabelList.push({"id": element.id, "name": element.label});
        });
        return idLabelList;
      }

      function getNextItemLabel(section) {
        var startingLabel = 1;
        var label = section.childElementList.reduce(function(nextLabel, element) {
          return element.elementTypeId !== 3 && element.elementTypeId !== 4 ? nextLabel + 1 : nextLabel;
        }, startingLabel);
        return label;
      }

      $scope.constructItemLabels = function(section) {
        var nextLabel = 1;
        var idLabelList = [];
        section.childElementList.forEach(function(element, index) {
          if (element.elementTypeId !== 4 && element.elementTypeId !== 3) {
            element.label = nextLabel;
            nextLabel++;
          }
          element.sort = index + 1;
          idLabelList.push({"id": element.id, "name": element.label});
        });
        return idLabelList;
      }

      function showStandardDeleteModal(section, index) {
        errorPopupChoice('Selecting yes will delete this section.', function(){
          deleteSection(section, index);
        });
      }

      function showDeleteDemographicModal(section, index) {
          swal({
            title: "Are you sure?",
            text: "You are about to delete demographics from this survey, which removes grouping data from reports and the activity dashboard. Do you wish to delete the demographics? Continue (proceed with deleting the section) or Cancel (close modal and return to content).",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, delete it!",
            closeOnConfirm: true
          }, function (confirmation) {
              if (confirmation) {
                deleteSection(section, index);
              }
            }
          );
      }

      function getAlphabeticalLabel(key) {
        var alphabetMap = {1: "A", 2: "B", 3: "C", 4: "D", 5: "E", 6: "F", 7: "G", 8: "H", 9: "I", 10: "J", 11: "K", 12: "L", 13: "M", 14: "N", 15: "O", 16: "P", 17: "Q", 18: "R", 19: "S", 20: "T", 21: "U", 22: "V", 23: "W", 24: "X", 25: "Y", 26: "Z"};
        if (key > 26) {
          return key;
        } else {
          return alphabetMap[key];
        }
      }

      function switchOptionListToScale(element) {
        element.optionList = [
            {"value":5,"text":"Strongly Agree","icon":"icon-opinion-5-strongly-agree"},
            {"value":4,"text":"Agree","icon":"icon-opinion-4-agree"},
            {"value":3,"text":"Neutral","icon":"icon-opinion-3-neutral"},
            {"value":2,"text":"Disagree","icon":"icon-opinion-2-disagree"},
            {"value":1,"text":"Strongly Disagree","icon":"icon-opinion-1-strongly-disagree"}
        ];
      }

      function getQuestionTypes() {
        return [
          {
            textId: 'PICK_ONE',
            label: 'Pick One',
            icon: 'icon-pick-one'
          },
          {
            textId: 'PICK_MULTIPLE',
            label: 'Pick Multiple',
            icon: 'icon-pick-multiple'
          },
          {
            textId: 'DROPDOWN',
            label: 'Dropdown',
            icon: 'icon-dropdown'
          },
          {
            textId: 'TEXT_FIELD',
            label: 'Text Field',
            icon: 'icon-text-field'
          },
          {
            textId: 'OPINION_SCALE',
            label: 'Opinion Scale',
            icon: 'icon-opinion-scale'
          }
        ];
      }

      function getNewItem(id, sort, label) {
        return {
          "elementTypeId": 2,
          "id": id,
          "text": "",
          "renderType": null,
          "sort": sort,
          "label": label,
          "tags": [],
          "required": false,
          "optionList": [],
          "minOptions": null,
          "maxOptions": null,
          "randomizeOptions": false,
          "useEmoticon": false,
          "includeNAOption": false,
          "optionCategoryId": null
        };
      }

      function getNewPageBreak(id, sort) {
        return {
          "elementTypeId": 4,
          "id": id,
          "label": sort,
          "sort": sort,
        };
      }

      function getNewMessage(id, sort) {
        return {
          "id": id,
          "sort": sort,
          "text": "",
          "elementTypeId": 3,
          "label": sort
        };
      }

      function getNewSection(id, name, label, sort, demographic) {
        return {
          "certifiedSection": false,
          "id": id,
          "masterElementId": null,
          "sort": sort,
          "name": name,
          "text": "",
          "renderTypeId": null,
          "elementTypeId": 1,
          "optionList": [],
          "required": false,
          "useEmoticon": false,
          "tags": [],
          "randomizeOptions": false,
          "minOptions": 1,
          "maxOptions": 0,
          "elementGroupId": null,
          "childElementList": [],
          "demographic": demographic,
          "label": label
        };
      }

      function getNewElementOptionScaleList(element, count) {
        switch (count) {
          case 3:
            var optionScaleList = [
              {"value":3,"text":"Agree","icon":"icon-opinion-4-agree"},
              {"value":2,"text":"Neutral","icon":"icon-opinion-3-neutral"},
              {"value":1,"text":"Disagree","icon":"icon-opinion-2-disagree"}
            ];
            break;
          case 4:
            var optionScaleList = [
              {"value":4,"text":"Strongly Agree","icon":"icon-opinion-5-strongly-agree"},
              {"value":3,"text":"Agree","icon":"icon-opinion-4-agree"},
              {"value":2,"text":"Disagree","icon":"icon-opinion-2-disagree"},
              {"value":1,"text":"Strongly Disagree","icon":"icon-opinion-1-strongly-disagree"}
            ];
            break;
          default:
            var optionScaleList = [
              {"value":5,"text":"Strongly Agree","icon":"icon-opinion-5-strongly-agree"},
              {"value":4,"text":"Agree","icon":"icon-opinion-4-agree"},
              {"value":3,"text":"Neutral","icon":"icon-opinion-3-neutral"},
              {"value":2,"text":"Disagree","icon":"icon-opinion-2-disagree"},
              {"value":1,"text":"Strongly Disagree","icon":"icon-opinion-1-strongly-disagree"}
            ];
        }
        if(element.includeNAOption) {
          var naOption = {value: 0, text: "N/A", icon: "icon-block"};
          optionScaleList.push(naOption);
        }
        return optionScaleList;
      }
  }
]);
