app.controller('NewContentCtrl', 
    ['$scope',
     'ContentTemplateService', 
     'applicationService',
     'userService', 
     '$location', 
     'modalService',
  function ($scope, 
            ContentTemplateService, 
            applicationService, 
            userService,
            $location, 
            modalService
  ) {
    // create a new content template and redirect to edit page on success
    $scope.createNewCertifiedTemplate = function () {
      modalService.presentCustomModal({
          title: '<i data-icon="l"></i><span>Select Template</span>',
          text: '<div class="top-text weighted-text">Will this template be used for an administration and require assignment of participants?</div>' +
          '<div>Selecting “Yes” will allow you to set up and administer this survey to a specific group of participants.</div>',
          html: true,
          confirmButtonText: 'No',
          showCancelButton: true,
          cancelButtonText: 'Yes',
          customClass: 'template-administration-selection-modal',
          allowEscapeKey: false
      }, function() {
          // Buttons are reversed so this is now the NO case
          ContentTemplateService.createCertifiedTemplate({
              userId: userService.getUserId(),
              institutionId: applicationService.getInstitutionId()
          }).$promise.then(function (data) {
              $location.path("/edit-template/" + data.templateId);
          }, function (error) {
              modalService.presentErrorModal("Create content template was unsuccessful. This is most likely a problem connecting to the server. Please try again later.", error.data.message);
          });
      }, function() {
          // Buttons are reversed so this is now the YES case
          ContentTemplateService.createCertifiedTemplateWithAdministrationOption({
              userId: userService.getUserId(),
              institutionId: applicationService.getInstitutionId()
          }).$promise.then(function (data) {
              $location.path("/edit-template/" + data.templateId);
          }, function (error) {
              modalService.presentErrorModal("Create content template was unsuccessful. This is most likely a problem connecting to the server. Please try again later.", error.data.message);
          });
      });
    };



    // create a new content template and redirect to edit page on success
    $scope.createNewCustomTemplate = function () {
      //call to create a template
      ContentTemplateService.createCustomTemplate({
        userId: userService.getUserId(),
        institutionId: applicationService.getInstitutionId()
      }).$promise.then(function (data) {
        $location.path("/edit-template/" + data.templateId);
      }, function (error) {
          modalService.presentErrorModal("Create content template was unsuccessful. This is most likely a problem connecting to the server. Please try again later.", error.data.message);
      });
    };
  }
]);
