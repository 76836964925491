app.controller('ForgotPasswordCtrl', ['$scope', 'applicationService', 'signInService',
    function($scope, applicationService, signInService) {

        $scope.$root.headerAction = 'hidden';

        $scope.login = {};
        $scope.errors = {};

        $scope.submitForgotPassword = function(login){
            $scope.errors = {};

            signInService.validateEmail(login, $scope.errors);
            if(!$scope.errors.email){
                applicationService.getResetUrl(login.email, handleResetPassword, handleResetPasswordError);
            }
        };

        var handleResetPassword = function(resetInfo){
            sendResetEmail(resetInfo);
        };
        var handleResetPasswordError = function(error){
            $scope.errors.main = error;
            handleSendEmail(null);
        };


        var sendResetEmail = function(resetInfo){
            var user = resetInfo.user; // This functions off of information received from the server during a password reset and is not related to permissions.
            var emailData = [{
                "from" : "app-noreply@advanc-ed.org",
                "to" : user.email,
                "appName": "surveys",
                "templateName": "resetPassword",
                "personName": user.firstName+' '+user.lastName,
                "resetPasswordUrl": resetInfo.url
            }];

            applicationService.sendResetPasswordEmail(emailData, handleSendEmail, handleSendEmailError);
        };
        var handleSendEmail = function(emailResponse){
            
            $scope.successMessage = 'If a user account exists for the email address provided, a reset password link has been emailed to you.';
        };
        var handleSendEmailError = function(error){
        }

    }
]);