app.directive('hiddenSearch', function(){
  return function(scope,element,attrs){
    $('.search-filter .icon', element).click(function() {
      $('.search-filter', element).toggleClass('open');
      $('.echo-surveys__filter', element).toggle();
      $('.search-filter__wrap', element).toggleClass('small-12 small-1');
    });

    $('.search-filter input', element).focus(function() {
      $('.autofill-container').toggle();
    });

    $('.search-filter input', element).blur(function() {
      $('.autofill-container').toggle();
    });
  }
});

