app.directive('openActions',
    [
      '$location',
      '$sessionStorage',
      'ContentTemplateService',
      'SurveyCreateService',
      'userService',
      '$timeout',
      function(
          $location,
          $sessionStorage,
          ContentTemplateService,
          SurveyCreateService,
          userService,
          $timeout
      ) {
    return {
      restrict: 'E',
      templateUrl: 'partials/templates/open-actions.html',
      scope: {
        id: '=',
        certified: '='
      },
      link: function(scope, element, attrs) {

        scope.previewTemplate = function() {
          ContentTemplateService.getTemplateSupportedLanguages({templateId: scope.id}).$promise.then(
            function(languages) {
              var backUrl = $location.path().indexOf('manage') !== -1 ? 'manage' : 'browse';
              var multipleLanguages = languages.length > 1; 
              var previewCustomUrl = '/preview-content/' + scope.id + '/language/' + languages[0].id + '?type=custom&back=' + backUrl;
              var previewCertifiedUrl = '/preview-content/' + scope.id + '/language/' + languages[0].id + '?type=certified&back=' + backUrl;
              var langSelectUrl = '/select-lang/' + scope.id + '?back=' + backUrl;
              if ( multipleLanguages ) {
                $location.url(langSelectUrl);
              } else {
                var url = scope.certified ? previewCertifiedUrl : previewCustomUrl; 
                $location.url(url);
              }
            },
            function(error) {
              sweetAlert("There was an error previewing the content. This is most likely a problem connecting to the server. Please try again later.", error, "error");
            }
          );
        };

        scope.createSurvey = function() {
          var body = {
            userId: userService.getUserId(),
            copiedTemplateId: scope.id,
            isCertified: scope.certified,
            institutionId: $sessionStorage.institution.id
          };
          ContentTemplateService.createSurveyFromTemplate(body).$promise.then(
            function(data) {
              associateSurveyWithUser(data);
              $sessionStorage.surveySharingPermissions = {surveyId : data.surveyId, hasSharingPermissions : true };
            },
            function(error) {
              sweetAlert("There was an error creating the survey. This is most likely a problem connecting to the server. Please try again later.", error, "error");
            }
          );
        };

        scope.copyTemplate = function() {
          var body = {
            userId: userService.getUserId(),
            copiedTemplateId: scope.id,
            isCertified: false,
            institutionId: $sessionStorage.institution.id
          };
          ContentTemplateService.copyTemplate(body).$promise.then(
            function(response) {
              $location.url('/edit-template/' + response.templateId);
            },
            function(error) {
              sweetAlert("There was an error copying the template name. This is most likely a problem connecting to the server. Please try again later.", error.data.errorMessage, "error");
            }
          );
        };

        function associateSurveyWithUser(data) {
          var associationObj = new Object();
          associationObj.objectType = "survey";
          userService.admin(associationObj,'roles',true);
          associationObj.applicationId = "2";
          associationObj.objectInstanceId = data.surveyId;
          associationObj.ownerUserId = userService.getUserId();
          associationObj.customerId = $sessionStorage.institution.id;
          associationObj.userId = userService.getUserId();
          SurveyCreateService.associateSurveyWithUser(associationObj).$promise.then(function (data) {
            if (data.messageType === 'Failure') {
              $timeout(errorPopup('We could not verify this survey to you.', data.message, null), 500);
            } else {
              $location.url('/edit/' + associationObj.objectInstanceId);
            }
          }, function (error) {
            $timeout(errorPopup('We could not verify this survey to you.', error.data.message, null), 500);
          });
        }

        scope.canCreateSurvey = function() {
            return userService.userIsAdmin() || userService.userIsSuperUser();
        }

        scope.canCopyTemplate = function() {
          return !scope.certified && (userService.userIsAdmin() || userService.userIsSuperUser());
        }
      }
    }
  }
]);
