app.filter('highlightToken', ['$sce', function($sce) {
    return function(text, phrase, tagName){

        var startTag = '<'+tagName+'>';
        var closeTag = '</'+tagName+'>';

        var startMarker = '<>';
        var closeMarker = '</>';

        if(phrase && text){
            var tokens = phrase.split (' ');
            //replace token with marker first, to avoid tags getting replaced on 2nd+ pass
            //check if token is a marker?
            tokens.forEach(function(token){
                if (token) text = text.replace(new RegExp('('+token+')', 'gi'),
                    startMarker+'$1'+closeMarker)
            });

            //replace markers with tag
            text = text.replace(new RegExp('('+startMarker+')', 'gi'),
                startTag);
            text = text.replace(new RegExp('('+closeMarker+')', 'gi'),
                closeTag);

        }

        return $sce.trustAsHtml(text)
    }
}]);

app.filter("highlightObject", [
    "$sce", function($sce) {
        return function(input, searchParam) {
            try {
                var exp, words;
                if (typeof input === "function") {
                    return "";
                }
                if (input) {
                    input = input.toString();
                }
                if (typeof searchParam === "object") {
                    searchParam = searchParam.name;
                }
                if (searchParam) {
                    words = "(" + searchParam.split(/\ /).join(" |") + "|" + searchParam.split(/\ /).join("|") + ")";
                    exp = new RegExp(words, "gi");
                    if (words.length) {
                        input = input.replace(exp, "<span class=\"highlight\">$1</span>");
                    }
                }
            } catch(err){
                // This catch case specifically handles when the input is undefined and when the searchParam creates an invalid regex expression using the join logic
                // Note this doesn't require a change to the return object. If it is undefined it won't break anything
            }
            return $sce.trustAsHtml(input);
        };
    }
]);

app.filter('appendState', [function(){
    return function(city, state){
        if(city && state){
            return city + ', ' + state
        }
    }
}])

app.filter('appendPostalCode', [function(){
    return function(word, postalCode){
        if(word && postalCode){
            return word + ' ' + postalCode
        }
    }
}])

app.filter('address', ['$sce', function($sce){
    return function(address, tagName){
        var text = '';
        var startTag = '<'+tagName+'>';
        var closeTag = '</'+tagName+'><br/>';

        if(address){
            if(address.address1){
                text = text + startTag + address.address1 + closeTag
            }
            if(address.address2){
                text = text + startTag + address.address2 + closeTag
            }
            if(address.address3){
                text = text + startTag + address.address3 + closeTag
            }
            if(address.city && address.state){
                text = text + startTag + address.city + ', '
                    + address.state.name + ' ' + address.postalCode +  closeTag
            }
            if(address.country){
                text = text + startTag + address.country.name + closeTag
            }
        }
        return $sce.trustAsHtml(text)
    }
}])


app.filter('shortAddress', ['$sce', function($sce){
    return function(address, tagName){
        var text = '';
        var startTag = '<'+tagName+'>';
        var closeTag = '</'+tagName+'><br/>';

        if(address){
            if(address.city && address.state){
                text = text + startTag + address.city + ', '
                    + address.state.name + ' ' + address.postalCode +  closeTag
            }
        }
        return $sce.trustAsHtml(text)
    }
}])


app.filter('itemIdInList', [function(){
    return function(customers, filteredIds){
        var filterCustomers = [];

        for(var i = 0; i < customers.length; i++) {
            if(filteredIds.indexOf(customers[i].id) > -1){
                filterCustomers.push(customers[i]);
            }
        }
        return filterCustomers;
    }
}])


app.filter('currencyOrDash', ['$filter', function($filter) {
        return function(amount){
            var result = '--';

            if(isFinite(amount))
                result = $filter('currency')(amount, '$', 2);

            return result;}
    }])

app.filter('numberOrDash', [ function() {
        return function(amount){
            var result = '--';

            if(isFinite(amount))
                result = amount;

            return result;}
    }])

app.filter('wordOrDash', [ function() {
    return function(word){
        var result = '-';

        if(word)
            result = word;

        return result;}
}])

app.filter('wordOrDefault', [ function() {
    return function(word, defaultWord){
        var result = defaultWord;

        if(word)
            result = word;

        return result;}
}])

app.filter('showIf', [ function() {
    return function(word, showText){
        var result = null;

        if(showText)
            result = word;

        return result;}
}])

app.filter('prependIf', [ function() {
    return function(word, prefix){
        var result = null;

        if(word)
            result = prefix + word;

        return result;}
}])

app.filter('plural', [ function() {
        return function(term, qty){
            var result = term;

            if(qty != 1)
                result = term + 's';

            return result;}
    }])