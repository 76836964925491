app.factory( 'modalService', [ '$timeout',
    function($timeout){
        return new ModalService($timeout);
    }]);

function ModalService($timeout) {

    const logEnabled = false;

    /*
    Defined constants for messages
     */
    const messageDefaults = {
        errors: {
            messages: {
                basic: "Something went wrong. Please try again. ",
            },
            suffixes: {
                connection: "This is most likely a problem connection to the server. Please try again later."
            }
        }
    };

    this.presentCustomModal = function(modalConfig, confirmCallback, cancelCallback) {
        swal(modalConfig,
            function(confirmed) {
                if(confirmed && confirmCallback) {
                    logMsg("Executing confirm callback");
                    confirmCallback();
                }else if(!confirmed && cancelCallback) {
                    logMsg("Executing cancel callback");
                    cancelCallback();
                } else {
                    logMsg("Modal had no callbacks to execute");
                }
                // Delay removing class for a fraction of a second to prevent dom jitter
                // If there is no delay and the class is removed before the close animation is complete then the UI will look
                // - very weird for a half second before it hits the subsequent render cycle
                $timeout(clearCustomClass, 100);
            });
    };

    this.presentErrorModal = function(content, data, confirmCallback) {
        if(content) {
            sweetAlert(content + messageDefaults.errors.suffixes.connection, data, "error");
        } else {
            sweetAlert(content, null, "error");
        }
    };

    this.presentDefaultErrorModal = function() {
        sweetAlert(messageDefaults.errors.messages.basic);
    };
    
    this.close = function() {
        swal.close();
    };

    /**
     * Must be called inside a $timeout function to ensure it will sync up with UI correctly
     */
    const clearCustomClass = function() {
        const modalSearch = $('.sweet-alert');
        for(let i = 0; i < modalSearch.length; i++){
            try {
                const customClass = modalSearch.attr('data-custom-class');
                logMsg('Found custom class: ' + customClass + ' - About to remove');
                modalSearch.removeClass(customClass);
            }catch(err){
                logErr("Caught error trying to remove custom class");
            }
        }
    };

    const logMsg = function(msg) {
        if(logEnabled) {
            console.log(msg);
        }
    };

    const logErr = function(err) {
        if(logEnabled) {
            console.error(err);
        }
    };
}