app.directive('publishHoverRow', 
    ['$location',
     'userService', 
     '$sessionStorage', 
     '$timeout', 
     '$routeParams', 
     'ContentTemplateService', 
  function($location, 
           userService, 
           $sessionStorage, 
           $timeout, 
           $routeParams, 
           ContentTemplateService
      ) {
    return {
      restrict: 'E',
      templateUrl: 'partials/templates/publish/hover-row.html',
      scope: {
        inst: '=',
        index: '@',
        templateId: '@',
        spliceInst: '&',
        institutions: '=',
        firstRow: '='
      },
      link: function(scope, element, attrs) {
        scope.showPopover = false;
        scope.hierarchyLoaded = false;
        scope.hasParents = true;

        var timer;
        scope.startTimer = function() {
          timer = $timeout(function() {
            showHoverInfo();
            timer = undefined;
          }, 1000);
        };

        scope.stopTimer = function() {
          if(timer) {
            $timeout.cancel(timer);
            timer = undefined;
          } else {
            hideHoverInfo();
          }
        }

        function hideHoverInfo() {
          scope.showPopover = false;
        };

        function showHoverInfo() {
          if (!scope.hierarchyLoaded) {
            var body = {
              childInstitutionId: scope.inst.id,
              parentInstitutionId: $sessionStorage.institution.id
            };
            ContentTemplateService.getInstHierarchy(body).$promise.then(
              function(response) {
                if (response.length <= 1) {
                  scope.hasParents = false;
                  scope.hierarchyLoaded = true;
                } else {
                  scope.hierarchyLoaded = true;
                  scope.showPopover = true;
                  scope.parents = response;
                }
              },
              function(error) { }
            );
          } else if (scope.hasParents) {
            scope.showPopover = true;
          }
        }

        scope.deleteInstCheck = function() {
          var nextAction = scope.institutions.length > 1 ? deleteInstModal : unpublishModal;
          nextAction();
        };

        function unpublishModal() {
          swal({
            title: "Unpublish Content",
            text: "Removing all selected institutions will unpublish this content",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#44b644",
            confirmButtonText: "Unpublish",
            closeOnConfirm: true
          }, function (confirmation) {
              if (confirmation) { unpublishTemplate(); }
            }
          );
        }

        function deleteInstModal() {
          swal({
            title: "Delete Selected",
            text: "Are you sure you would like to remove " + scope.inst.name + " from your list of selected institutions",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#44b644",
            confirmButtonText: "Delete",
            closeOnConfirm: true
          }, function (confirmation) {
              if (confirmation) { deleteInst(scope.inst, scope.index); }
            }
          );
        }

        function unpublishTemplate() {
          var body = {userId: userService.getUserId(), templateId: scope.templateId};
          ContentTemplateService.unpublishTemplate(body).$promise.then(
            function(data) {
              $location.url('/browse-content');
            },
            function(error) {
              sweetAlert("There was an error unpublishing the content. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
            }
          );
        };
      
        function deleteInst(inst, index) {
          var body = {templateId: $routeParams.templateId, institutionId: inst.id};
          ContentTemplateService.deletePublishedInstitution(body).$promise.then(
            function(response) {
              scope.spliceInst()(index); 
            },
            function(error) {
              sweetAlert("There was an error removing the institution from the list. This is most likely a problem connecting to the server. Please try again later.", error, "error");
            }
          );
        }

      }
    }
  }
]);
