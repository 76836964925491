app.directive('dashboardActivityMenu',
    [
        '$location',
        '$sessionStorage',
        '$timeout',
        'SurveyService',
        'reportPreferences',
        'ReportService',
        'applicationService',
        'userService',
        '$rootScope',
        'participantService',
        'ContentTemplateService',
function(
    $location,
    $sessionStorage,
    $timeout,
    SurveyService,
    reportPreferences,
    ReportService,
    applicationService,
    userService,
    $rootScope,
    participantService,
    ContentTemplateService
) {
    return{
        restrict: 'E',
        templateUrl: 'partials/dashboard/dashboardActivityMenu.html',
        scope: {
          status: '=',
          survey: "@",
          surveyId: '@',
          responseCount: '@',
          surveyContentId: '@',
          surveyContentName: '@',
          certificationId: '@',
          getSurveys : '&',
          getReminders : '&',
          typeFilter: '@',
          statusFilter: '@',
          networkAverage: '@',
          userId: '@',
          ownerId: '@',
          institutionId: '@',
          sharing: '@',
          permissions: '@',
          hasSharingPermissions:'@',
          isAdmin: '@',
          isAdminTemplate: '@',
          getInstitutionHierarchy: '&',
          surveyInstitutionId: '@',
          getActivity: '&',
          createdOn: "@",
          showSummaryData: "&",
          completedReportRequestId: "@",
          completedReportGeneratedDate: "@",
          sesReportReadyForRelease: "@",
          sesReportReleased: "@",
          sesReportReleaseDate: "@",
          disaggregatedReportUrl: "@",
          previewSurvey: "@",
          rosterChangeRequest: "@",
          downloadStudentRosterReport: "@",
          downloadStatusMonitoringReport: "@",
          downloadSummaryResults: "@",
          downloadDisaggregatedReport: "@"
        },
        link: function(scope,element,attrs){
            scope.certId = (scope.certificationId == null || scope.certificationId.length == 0 ? 0 : 1);
            scope.isOwner = (scope.surveyInstitutionId == scope.institutionId);
            scope.canBeDeleted = (scope.status == surveyDraftStatus) && scope.isOwner;
            scope.survey_permissions = {};
            const piiPermissions = userService.userHasPIIAccess(false);

            if(scope.permissions != "" && scope.permissions != "undefined") {  // TODO: Roles Permissions - permissions assigned to scope.survey_permissions from permissions passed in from parent controller.
                scope.survey_permissions = JSON.parse(scope.permissions);
            };

            //original logocs: (scope.isOwner || scope.hasSharingPermissions == 'true') && (scope.isAdmin == 'true' || scope.checkPermission('share_survey'));
            scope.displaySharing = function() {
                return (scope.isOwner && !scope.isAdminSurvey()) && (userService.userIsSuperUser() || userService.userIsAdmin() || userService.userIsEditor());
            }

            scope.deleteSurvey = function() {
                swal({
                        title: "Are you sure?",
                        text: "Selecting yes will delete this survey",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Yes, delete it!",
                        closeOnConfirm: true
                    },
                    function(isConfirm){
                        if(isConfirm) {
                            SurveyService.remove(scope.surveyId, scope.userId).$promise.then(function () {
                                SurveyService.removeObject('survey', scope.surveyId, scope.ownerId, scope.institutionId, scope.userId, 2)
                                    .$promise.then(function(){
                                        scope.getSurveys({type: scope.typeFilter, status: scope.statusFilter});
                                        scope.getReminders();
                                    })
                            }, function (httpResponse) {
                                $timeout(errorPopup, 500);
                            });
                        }
                    });
            };

            scope.viewSummary = function() {
              scope.showSummaryData()(scope.survey, scope.createReport);
            }

            scope.displayDownloadSummary = function(){
                return scope.isReportReleased() || (scope.isSuperUser() && scope.isReportReadyForRelease());
            }

            scope.displayDownloadCompleteResults = function() {
                return scope.disaggregatedReportUrl || false;
            }

            /**
             * This function will emit an event that causes the change roster modal to display
             */
            scope.presentRosterChangeModal = function() {
                const payload = {
                    surveyId: scope.surveyId,
                    customerId: scope.institutionId,
                    displayName: scope.surveyContentName,
                    isNTSUpload: false
                };
                scope.$emit('showChangeRosterModal', payload);
            };

            scope.downloadUnstackedCSV = function(survey) {
                scope.createReportStatus = true;
                scope.cameFromViewSummary = 'csv';
                scope.csvReport = true;

                if(scope.completedReportRequestId){
                    ReportService.updateCSVCounter({requestId: scope.completedReportRequestId});
                }
                var reportPreferencesInputDTO = new Object();
                reportPreferencesInputDTO.reportPreferences = angular.copy(reportPreferences);
                reportPreferencesInputDTO.reportPreferences.reset();
                reportPreferencesInputDTO.reportPreferences.surveyContentTypeId = (scope.certificationId != null && scope.certificationId != '') ? scope.surveyContentId : "-1"
                reportPreferencesInputDTO.reportPreferences.surveyContentTypeName = "Anonymous";
                reportPreferencesInputDTO.reportPreferences.surveys = [scope.surveyId];
                reportPreferencesInputDTO.reportPreferences.networkAverage = scope.networkAverage;
                reportPreferencesInputDTO.reportPreferences.havePreferences = true;

                reportPreferencesInputDTO.userId = scope.userId;
                reportPreferencesInputDTO.emailUser = userService.getUser().email;
                reportPreferencesInputDTO.institutionId = scope.institutionId;
                reportPreferencesInputDTO.institutionName = applicationService.getInstitution().name;
                reportPreferencesInputDTO.reportId = 0;
                reportPreferencesInputDTO.languageId = 1;   //English
                reportPreferencesInputDTO.reportPreferences.institutionsByHierarchy = scope.getInstitutionHierarchy();

                reportPreferencesInputDTO.reportPreferences.fromDate = null;
                reportPreferencesInputDTO.reportPreferences.toDate = null;

                angular.extend(reportPreferencesInputDTO.reportPreferences, getReportCustomerInformation());

                return ReportService.createRawDataUnstackedReport(reportPreferencesInputDTO).$promise.then(function(report) {
                    //Do not open file any more. Email will be sent to user email address.
                    //window.open(reports_shared_drive_uri + report.rawDataReportFileName,"_self");
                },function(error){
                    var errorMsg = "Error creating raw data unstacked report";
                    if (error.data != null) {
                        errorMsg = error.data.message;
                    }
                    $timeout(sweetAlert(errorMsg), 500); //error.data.message is the userToSeeMessage sent from api
                });
            }

            scope.createReport = function(cameFromViewSummary){
              if(cameFromViewSummary) {
                scope.cameFromViewSummary = true;
              } else {
                scope.cameFromViewSummary = false;
              }
                scope.createReportStatus = true;
            }

            scope.closeReport = function() {
              scope.createReportStatus = false;
            }


            scope.previewTemplate = function() {
                ContentTemplateService.getTemplateSupportedLanguages({templateId: scope.surveyContentId}).$promise.then(
                    function(languages) {
                        var backUrl = 'institution-dashboard';
                        var multipleLanguages = languages.length > 1;
                        var previewCertifiedUrl = '/preview-content/' + scope.surveyContentId + '/language/' + languages[0].id + '?type=certified&back=' + backUrl;
                        var langSelectUrl = '/select-lang/' + scope.surveyContentId + '?back=' + backUrl;
                        if ( multipleLanguages ) {
                            $location.url(langSelectUrl);
                        } else {
                            $location.url(previewCertifiedUrl);
                        }
                    },
                    function(error) {
                        sweetAlert("There was an error previewing the content. This is most likely a problem connecting to the server. Please try again later.", error, "error");
                    }
                );
            };


            scope.checkPermission = function(action) {
                var showAction = false;
                var filterPermission = _.where(scope.survey_permissions.permissions,{"permissionName" : action}); // TODO: Roles Permissions - permissions checked in directive directly.
                if (filterPermission.length > 0){
                    showAction = true;
                }
                return showAction;
            }

            scope.adjustActionMenu = function(menuId){
                var menu = document.getElementById(menuId);
            }

            scope.isEditor = function() {
                if( typeof scope.surveyId !== typeof scope.survey_permissions.objectId ) {
                    var survey_id = parseInt(scope.surveyId);
                    var permission_objectId = parseInt(scope.survey_permissions.objectId);
                }
                if ( permission_objectId === survey_id ) {
                    var that = scope.survey_permissions.permissions;  // TODO: Roles Permissions - permissions are checked in directive to check if user isEditor.
                    for ( var i = 0; i < that.length; i++ ) {
                        if ( that[i].permissionName === 'edit_survey' ) {
                            return true;
                        }
                    }
                    return false;
                }
            }



            scope.archiveSurvey = function() {
                var statusId = surveyOpenStatusId;
                if (scope.status === surveyClosedStatus) {
                    statusId = surveyClosedStatusId;
                }
                else if (scope.status === surveyDraftStatus) {
                    statusId = surveyDraftStatusId;
                }
                else if (scope.status === surveyArchivedStatus) {
                    statusId = surveyArchivedStatusId;
                }

                SurveyService.archiveSurvey(scope.surveyId, scope.userId, statusId).$promise.then(function (data) {


                    scope.status = data.message;

                    scope.getActivity();
                }, function (error) {
                    // error handler


                    errorPopup("The system encountered a problem, please try again.", error.data.message, null);
                });
            }

            scope.archivedRestoreText = function() {
                if (scope.status === surveyClosedStatus) {
                    return "Archive";
                }
                else if (scope.status === surveyArchivedStatus) {
                    return "Restore";
                }

                return ""
            }

            //original code in html: (!(isAdminSurvey() && isUserAdmin())) && !isArchived() && (isUserAdmin() || checkPermission('edit_survey')) && responseCount>0
            scope.canViewSummaryData = function() {
                return !scope.isAdminSurvey() && !scope.isArchived() && scope.responseCount > 0;
            }

            //original code in html: (!(isAdminSurvey() && isUserAdmin())) && !isArchived() && (isUserAdmin() || checkPermission('edit_survey'))
            scope.canEdit = function() {
                return !scope.isAdminSurvey() && !scope.isArchived() && (scope.isUserAdmin() || scope.isSuperUser() || scope.isUserEditor());
            }

            //original code in html: !isArchived() && !isEditor() && !isAdminSurvey()
            scope.canView = function() {
                return !scope.isAdminSurvey() && !scope.isArchived() && !scope.isUserAdmin() && !scope.isSuperUser() && !scope.isUserEditor();
            }

            //original code in html: !isArchived() && !isEditor() && isAdminSurvey() && isOwner
            scope.canPreviewSurvey = function() {
                return (scope.isOwner || scope.previewSurvey === "true") && scope.isAdminSurvey() && !scope.isArchived();
            }

            //original code in html: !isArchived() && isAdminSurvey() && (isUserAdmin() && hasPiiAccess())
            scope.canChangeRoster = function() {
                return (scope.isOwner || scope.rosterChangeRequest === "true") && !scope.isArchived() && scope.isAdminSurvey() && scope.isUserAdmin() && scope.hasPiiAccess();
            }

            scope.canDownloadStatusMonitoringReport = function() {
                return (scope.isOwner || scope.downloadStatusMonitoringReport === "true") && scope.isAdminSurvey();
            }

            scope.canDownloadStudentRosterReport = function() {
                return (scope.isOwner || scope.downloadStudentRosterReport === "true") && scope.isAdminSurvey() && scope.hasPiiAccess();
            }

            scope.canDownloadSummaryResults = function() {
                return (scope.isOwner || scope.downloadSummaryResults === "true") && scope.isAdminSurvey() && scope.displayDownloadSummary();
            }

            scope.canDownloadDisaggregatedReport = function() {
                return (scope.isOwner || scope.downloadDisaggregatedReport === "true") && scope.isAdminSurvey() && scope.displayDownloadCompleteResults();
            }

            //original code in html: (!(isAdminSurvey() && isUserAdmin())) && !isArchived() && (isUserAdmin() || checkPermission('view_report'))
            scope.canDownloadCsv = function() {
                return !scope.isArchived() && !scope.isAdminSurvey();
            }

            //original code in html: (!(isAdminSurvey() && isUserAdmin())) && !isArchived() && (isUserAdmin() || checkPermission('create_report'))
            scope.canGenerateNewReport = function() {
                return !scope.isArchived() && !scope.isAdminSurvey() && (scope.isUserAdmin() || scope.isSuperUser() || scope.isUserEditor());
            }

            //original code in html: !isArchived() && (isUserAdmin() || checkPermission('view_report')) && completedReportRequestId
            scope.canViewReport = function() {
                return !scope.isArchived() && scope.completedReportRequestId;
            }

            //orignal code in html: (!(isAdminSurvey() && isUserAdmin())) && !isArchived() && (isUserAdmin() || checkPermission('copy_survey'))
            scope.canCopy = function() {
                return !scope.isArchived() && !scope.isAdminSurvey() && (scope.isUserAdmin() || scope.isSuperUser() || scope.isUserEditor());
            }

            //original code in html: !isArchived() && (canDelete && (isUserAdmin() || checkPermission('delete_survey')))
            scope.canDelete = function() {
                return scope.canBeDeleted && !scope.isArchived() && !scope.isAdminSurvey() && (scope.isUserAdmin() || scope.isSuperUser());
            }

            //original code in html: (checkPermission('archive')) && (isSurveyBeingShared() == false) && (archivedRestoreText() != '')
            scope.canArchive = function() {
                return !scope.survey_permissions.shared && !scope.isAdminSurvey() && scope.archivedRestoreText() != '' && (scope.isUserAdmin() || scope.isSuperUser());
            }

            scope.isArchived = function() {
                return scope.status == surveyArchivedStatus;
            }

            scope.isSurveyBeingShared = function() {
                return scope.survey_permissions.shared;
            }

            scope.viewReport = function(requestId) {
                $rootScope.cameFromSurveys = true;
              applicationService.setRequestId(requestId);
              $location.path("view-report");
            }

            function getReportCustomerInformation() {
                return {
                    surveyCreatorName: userService.getUserFullName()
                };
            }

            scope.isUserAdmin = function() {
                return userService.userIsAdmin();
            };

            scope.isUserEditor = function() {
                return userService.userIsEditor();
            };

            scope.isSuperUser = function() {
                return userService.userIsSuperUser();
            };

            scope.hasPiiAccess = function() {
                return piiPermissions;
            };

            scope.isAdminSurvey = function() {
                return scope.isAdminTemplate === "true";
            };

            scope.doDownloadStatusMonitoringReport = function(){
                participantService.downloadStatusMonitoring(scope.institutionId, applicationService.getApplicationId(), scope.surveyId, participantService.presentReportRequestSuccessModal, participantService.presentReportRequestFailureModal);
            };

            scope.doDownloadSummaryResults = function(){
                participantService.downloadSummaryResults(scope.institutionId, applicationService.getApplicationId(), scope.surveyId, participantService.presentReportRequestSuccessModal, participantService.presentSesReportRequestFailureModal);
            };

            scope.downloadCompleteResults = function(){
                participantService.downloadCompleteResults(scope.disaggregatedReportUrl);
            };

            scope.doDownloadStudentRosterReport = function(){
                participantService.downloadRoster(scope.institutionId, applicationService.getApplicationId(), scope.surveyId, participantService.presentReportRequestSuccessModal, participantService.presentReportRequestFailureModal);
            };
            scope.onClickEdit = function(){
                var editUrl = '/edit/' + scope.surveyId;
                $sessionStorage.surveySharingPermissions = {
                    surveyId : scope.surveyId, 
                    hasSharingPermissions : scope.displaySharing(),
                    createdOn : scope.createdOn,
                    status : scope.status == 1 ? 'Open' : scope.status == 2 ? 'Closed' : scope.status == 3 ?  'Draft' : scope.status == 4 ? 'Archieved' : scope.status

                }
                $location.url(editUrl);
            }
            scope.onClickSharing = function(){
                var sharingUrl = '/edit/' + scope.surveyId +'/'+ scope.institutionId+'/true';
                $sessionStorage.surveySharingPermissions = {
                    surveyId : scope.surveyId, 
                    hasSharingPermissions : ( scope.hasSharingPermissions =="true" || (scope.institutionId == scope.surveyInstitutionId) ),
                    createdOn : scope.createdOn,
                    status : scope.status == 1 ? 'Open' : scope.status == 2 ? 'Closed' : scope.status == 3 ?  'Draft' : scope.status == 4 ? 'Archieved' : scope.status
                }
                $location.url(sharingUrl);
            }

            scope.isReportReadyForRelease = function(){
                return scope.sesReportReadyForRelease === "true";
            };

            scope.isReportReleased = function(){
                return scope.sesReportReleased === "true";
            };
        }
    }
}]);
