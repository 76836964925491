/**
 * Created by pvo on 7/16/15.
 */

app.controller('SurveyCreateController', 
    ['$http',
     '$scope', 
     '$routeParams', 
     '$rootScope', 
     'SurveyCreateService', 
     'applicationService', 
     'userService', 
     '$timeout', 
     '$location', 
     'customerService', 
     '$window', 
     'modalService',
     '$sessionStorage',
     'ReportService',
     'ContentTemplateService',
  function($http,
           $scope, 
           $routeParams, 
           $rootScope, 
           SurveyCreateService, 
           applicationService, 
           userService, 
           $timeout, 
           $location, 
           customerService, 
           $window, 
           modalService,
           $sessionStorage,
           ReportService,
           ContentTemplateService
    ) {

      //$scope.$watch('liveSurvey', function(survey) { // TODO: Will - console.log should be removed.
      //  console.log('survey', survey);
      //});

      $scope.OPEN = 1;
      $scope.CLOSED = 2;
      $scope.EL_TYPE_ID_ITEM = 2;
      $scope.EL_TYPE_ID_MESSAGE = 3;
      $scope.EL_TYPE_ID_PAGEBREAK = 4;
      $scope.EL_TYPE_ID_MATRIX = 5;

      $scope.showFirstGroupingDropDown = false; //initialization
      $scope.showLanguageDropdown = false;

      $scope.groupingClick = function(){
          $scope.showFirstGroupingDropDown = true;
      };

      $scope.init = function () {
        $rootScope.headerAction = 'hidden';

        $scope.languageId = 1; //English
        $scope.validationFailed = false;
        $scope.copyCertifiedContent = false;
        $scope.logoUrl = "http://placehold.it/336x99";
        $scope.showingAutoFill = false;
        $scope.displayedCertifiedContentHeading = false;
        $scope.existEditPanel = false;
        $scope.showLanguageDropdown = false;
        $scope.selectedLanguage = 'English';
        $scope.certifiedContentStartIndex = -1;
        $scope.certifiedContentEndIndex = -1;
        $scope.viewMode = false;
        $scope.sharedSurvey = false;
        $scope.sharingOwner = false;
        $scope.lastContentEditTimestamp = "loading..."
        $scope.loading = true;
        $scope.dragging = false;
        $scope.isOpen3 = false;
        $scope.institutionsToShare = true;
        $scope.showShareAccordion = true;
        $scope.showTokenManagement = true;
        $scope.editableSharingPermission = false;
        $scope.translationValidation = '';
        $scope.hasSharedInstitutions = false;
        $scope.scrollCache = {
            pageContainer: null,
            current: null
        };

        var dateObj = new Date();
        $scope.autoCloseDate = dateObj.getUTCMonth() + 1 + "/" + dateObj.getUTCDate() + "/" + dateObj.getUTCFullYear();

        $scope.hideCloseDateCalendar = true;


        if($sessionStorage.surveySharingPermissions && $sessionStorage.surveySharingPermissions.surveyId == $routeParams.surveyId){
            $scope.hasSharingPermissions = $sessionStorage.surveySharingPermissions.hasSharingPermissions;
            $scope.createdOn = $sessionStorage.surveySharingPermissions.createdOn
        }



          if ($routeParams.sharing != undefined  && $routeParams.sharing == "true") {
              $scope.isOpen3 = true;
              //$("#institutionName").scrollIntoView();
          }

        //edit survey
        if($routeParams.surveyId !== undefined) {


            $scope.editSurvey($scope.languageId, $scope.selectedLanguage, false);
        }
        //copying survey from the action menu
        else if($routeParams.certificationId !== undefined){
            if($routeParams.certificationId > 0){
                $scope.copyCertifiedContent = true; // enables Parent Survey section on the survey page to show up
            }
            var surveyData = {institutionId:applicationService.getInstitutionId(), userId:userService.getUserId(), copiedSurveyId: $routeParams.copiedSurveyId, certificationId: $routeParams.certificationId};

            SurveyCreateService.copySurvey(surveyData).$promise.then(function (data) {
                // success handler
                $scope.populateData(data);
                $scope.templateId = data.newTemplateId;
                $scope.newSurveyId = data.surveyId;

                $sessionStorage.surveySharingPermissions = {
                    surveyId : $scope.newSurveyId, 
                    hasSharingPermissions : true,
                    createdOn : $scope.convertTimestampToDate(new Date().getTime()) 
                };

                $scope.associateSurveyWithUser(data);
                $location.path("/edit/" + data.surveyId);
            }, function (error) {
                // error handler


                errorPopup("The system encountered a problem. Please try again later.", error.data.message, "error");
            });
        }
        //create new custom survey.
        else if ($routeParams.copiedContentId === undefined) {
            SurveyCreateService.get({userId:userService.getUserId(), institutionId:applicationService.getInstitutionId()}).$promise.then(function (data) {
                // success handler
                $scope.populateData(data);
                $scope.templateId = data.newTemplateId;
                $scope.newSurveyId = data.surveyId;

                $sessionStorage.surveySharingPermissions = {
                    surveyId : $scope.newSurveyId, 
                    hasSharingPermissions : true,
                    createdOn : $scope.convertTimestampToDate(new Date().getTime())
                 };

                $scope.associateSurveyWithUser(data);
                $location.path("/edit/" + data.surveyId);
            }, function (error) {
                // error handler


                sweetAlert("Create survey was unsuccessful. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
            });
        }
        //copy certified content.
        else {
            //$scope.copyCertifiedContent = true;
            var survey = {copiedContentId:$routeParams.copiedContentId, userId:userService.getUserId(), institutionId:applicationService.getInstitutionId()};
            SurveyCreateService.copy(survey).$promise.then(function (data) {
                // success handler
                $scope.populateData(data);
                $scope.templateId = data.newTemplateId;

                $sessionStorage.surveySharingPermissions = {
                    surveyId : data.surveyId,
                    hasSharingPermissions : true,
                    createdOn : $scope.convertTimestampToDate(new Date().getTime())
                 };

                $scope.associateSurveyWithUser(data);
                $location.path("/edit/" + data.surveyId);
            }, function (error) {
                // error handler


                sweetAlert("Copy Certified Content was unsuccessful. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
            });
        }
        // fetch sharing details for currently accessed survey
      $scope.getSharingInformation = function(){
        var request = {
            surveyId: $scope.liveSurvey.surveyId, 
            surveyInstitutionId: applicationService.getInstitutionId(),
            parentHierarchy: $scope.liveSurvey.institutionId == applicationService.getInstitutionId()
        };
        ContentTemplateService.getAdminSharingInfo(request).$promise.then(
        function(data) {
            if(data.ownerInstitutionDetails.childInstitutions.length == 0 && (data.ownerInstitutionDetails.parentInstitutions == null || !request.parentHierarchy)){
                $scope.isStandaloneInstitution = true;
            } else if(data.ownerInstitutionDetails.ownerInstitution.immediateSharedChildrenCount || data.ownerInstitutionDetails.ownerInstitution.displayType !== 'UNCHECKED'){
                $scope.hasSharedInstitutions = true;
            }else if(request.parentHierarchy){
                $scope.hasSharedInstitutions = data.ownerInstitutionDetails.parentInstitutions ? data.ownerInstitutionDetails.parentInstitutions.filter(function(institution){
                    return institution.displayType == 'CHECKED' || institution.displayType == 'FULL_CHECKED';
                }).length > 0 : false;
            }
        },
        function(error) {
          sweetAlert("There was an error loading the survey sharing information. This is most likely a problem connecting to the server. Please try again later.", error, "error");
        }
      );
      }
    };

    $scope.calcAddNewItemText = function() {
        if ($scope.certifiedContentStartIndex >= 0) {
            return "View Items";
        }
        else {
            return "Add New items";
        }
    }

    var getStatus = function(statusCode){
        var status;
        switch(statusCode){
            case 1 : status = 'Open';
                     break;
            case 2 : status = 'Close';
                     break;
            case 3 : status = 'Draft';
                     break;
            case 4 : status = 'Archieved';
                     break;
        }
        return status;
    }

    $scope.downloadCsv = function() {
        $scope.loading = true;
        var reportInputDTO = {
            surveyId : $scope.liveSurvey.surveyId,
            institutionId : applicationService.getInstitutionId(),
            surveyName : $scope.liveSurvey.surveyName,
            surveyTemplate : $scope.liveSurvey.contentName,
            surveyStatus : getStatus($scope.liveSurvey.status),
            surveyCreatedDate : $scope.createdOn,
            surveyLastModified : $scope.lastContentEditTimestamp,
            reportCreatorFirstName: userService.getUserFirstName(),
            reportCreatorLastName : userService.getUserLastName(),
            surveyOwner : $scope.ownerCustomerName
        };
        return ReportService.downloadCSVReport(reportInputDTO).$promise.then(function(report) {
            window.open(reports_shared_drive_uri + encodeURIComponent(report.message),"_self");
            $scope.isOpen3 = true;
            $scope.loading = false;
        },function(error){
            var errorMsg = "Error downloading csv report";
            $timeout(sweetAlert(errorMsg), 500); 
        });
        
    };
    
    $scope.onManageSharingClick = function() {
        var path = '/sharing-content/';
          path = path + $routeParams.surveyId;
          $location.search('type', 'admin');
          $location.search('id', $routeParams.surveyId);
          $location.search('userInstitutionId', applicationService.getInstitutionId());
          $location.search('surveyInstitutionId', $scope.liveSurvey.institutionId);
        $location.path(path);
      };

    $scope.associateSurveyWithUser = function(data) {
        //Call user-api to associate survey
        var associationObj = new Object();
        associationObj.objectType = "survey";
        associationObj.objectInstanceId = data.surveyId.toString();
        associationObj.ownerUserId = userService.getUserId().toString();
        associationObj.customerId = applicationService.getInstitutionId().toString();
        associationObj.userId = userService.getUserId().toString();
        userService.admin(associationObj,'roles',true);
        associationObj.applicationId = "2";

        SurveyCreateService.associateSurveyWithUser(associationObj).$promise.then(function (data) {
            // success handler

            if (data.messageType === 'Failure') {
                $timeout(errorPopup('We could not verify this survey to you.', data.message, null), 500);
            }
        }, function (error) {
            // error handler

            $timeout(errorPopup('We could not verify this survey to you.', error.data.message, null), 500);
        });
    }

      $scope.editSurvey = function(languageId, languageName, updateLanguageOnly) {
          var survey = {surveyId: $routeParams.surveyId, languageId: languageId};
          SurveyCreateService.edit(survey).$promise.then(function (data) {
              // success handler
              $scope.loading = false;

              if ($location.url().indexOf('/view/') >= 0) {
                  $scope.viewMode = true;
              }




              if ( !updateLanguageOnly) {
                  $scope.populateData(data);

                  if ($scope.liveSurvey.institutionId === applicationService.getInstitutionId()) {
                      //owner institution is editing/viewing the survey.
                      $scope.sharedSurvey = false;
                  }
                  else {
                      $scope.sharedSurvey = true;
                  }

                  //Generate survey URL onscreen.
                  $scope.generateSurveyURL();

                  $scope.copyCertifiedContent = $scope.liveSurvey.certifiedContentName !== null;
                  $scope.templateId = data.newTemplateId;
                  $scope.newSurveyId = data.surveyId;
                  $scope.markFirstCertifiedSection();
                  $scope.markExistEditPanel();
                  $scope.populateOptionScaleList($scope.liveSurvey.elementList);
                  $scope.updateSectionLabels();

                  //document.getElementById('surveyName').focus();
                  if ($routeParams.sharing == undefined || $routeParams.sharing != "true") {
                      $("#surveyName").focus();
                  }

              }
              else {
                  //update language text
                  $scope.populateOptionScaleList(data.elementList);
                  $scope.updateLanguageText(data);
              }

              $scope.selectedLanguage = languageName;
              //$scope.showLanguagesForDropdown();

          }, function (error) {
              // error handler


              sweetAlert("Edit Certified Content was unsuccessful. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
          });
      }

      $scope.generateSurveyURL = function() {
          var actionIndex = $scope.liveSurvey.surveyURL.indexOf('/action/');
          var urlPrefix = $scope.liveSurvey.surveyURL.substring(0, actionIndex);
          var institutionPrefix = '';

          if (customerService.isSchoolInstitution(applicationService.getInstitution()) === false) {
              institutionPrefix = 'p';
          }

          $scope.liveSurvey.surveyURL = urlPrefix + '/action/' + $scope.liveSurvey.surveyId + '/' + institutionPrefix + applicationService.getInstitutionId();
      }

    $scope.updateLanguageText = function(data)
      {
          var oData = $scope.liveSurvey.elementList;
          $scope.liveSurvey.surveyName = data.surveyName;
          //$scope.liveSurvey = data
          if (oData !== null && oData !== undefined) {
              var parentCount = oData.length;
              for (var i = 0; i < parentCount; i++) {
                  var oSection = oData[i];
                  var section = data.elementList[i];
                  oSection.name = section.name;
                  oSection.text = section.text;
                  if (oSection.childElementList !== null && oSection.childElementList !== undefined) {
                      //update language text for item/options
                      for (var j = 0; j < oSection.childElementList.length; j++) {
                          var oItem = oSection.childElementList[j];
                          var item = section.childElementList[j];
                          oItem.name = item.name;
                          oItem.text = item.text;

                          oItem.optionList = angular.copy(item.optionList);
                          oItem.optionScaleList = angular.copy(item.optionScaleList);
                      }
                  }
              }
          }
      }

      $scope.openCloseButtonText = function() {
          if ($scope.isOpenable()) {
              return "Open Survey";
          }
          else {
              return "Close Survey";
          }
      }

      $scope.isOpenable = function() {
          if ($scope.liveSurvey === undefined || $scope.liveSurvey.status === undefined) {
              return true;
          }

          return ($scope.liveSurvey.status === 3 || $scope.liveSurvey.status === 2);
      };

      $scope.openSurvey = function() {
          var draftSurvey = ($scope.liveSurvey.status === 3);

          if ($scope.viewMode) {
              return;
          }

          if ($scope.isOpenable()) {
              areYouSure('Are you sure you want to open this survey?', 'Once a survey is open it cannot be deleted.', $scope.openSurveyConstructor, draftSurvey, 'We could not open your survey.');
          }
          else {
              $scope.closeSurvey();
          }
      };

      $scope.openSurveyConstructor = function(draftSurvey) {
          $scope.loading = true;
          var survey = {
              userId: userService.getUserId(),
              surveyId: $scope.liveSurvey.surveyId,
              templateId: $scope.liveSurvey.newTemplateId,
              open: true,
              deleteDraftResponses: draftSurvey
          };
          $scope.executeSurveyOpen(survey);
      };

      $scope.executeSurveyOpen = function(survey){
          SurveyCreateService.open(survey).$promise.then(function (data) {
              // success handler
              $scope.loading = false;

                  $scope.updateLastContentEditTimestamp();

                  $scope.liveSurvey.status = parseInt(data.message);
                  $scope.translationValidation = '';
              }, function (error) {
                  // error handler
                  $scope.loading = false;

                  var friendlyMessage = error.data.message;
                  if (friendlyMessage.indexOf('....') > 0) {
                      friendlyMessage = friendlyMessage.substring(0, friendlyMessage.indexOf('....'));
                  }

                  $scope.translationValidation = friendlyMessage;
          });
      }

      $scope.closeSurvey = function() {
          if ($scope.viewMode) {
              return;
          }

          if ($scope.liveSurvey.status === $scope.OPEN) {
              $scope.loading = true;
              var survey = {userId: userService.getUserId(), surveyId: $scope.liveSurvey.surveyId, templateId: $scope.liveSurvey.newTemplateId, open: false, deleteDraftResponses: false};
              SurveyCreateService.open(survey).$promise.then(function (data) {
                  // success handler
                  $scope.loading = false;


                  $scope.updateLastContentEditTimestamp();

                  $scope.liveSurvey.status = parseInt(data.message);
              }, function (error) {
                  // error handler
                  $scope.loading = false;



                  $timeout(errorPopup('There was a problem closing your survey.', error.data.message, null), 500);
              });
          }
      }

    $scope.markFirstCertifiedSection = function() {
        if ($scope.liveSurvey.elementList != null && $scope.liveSurvey.elementList != undefined) {
            for (var i = 0; i < $scope.liveSurvey.elementList.length; i++) {
                if ($scope.liveSurvey.elementList[i].certifiedSection === true) {
                    if ($scope.certifiedContentStartIndex === -1) {
                        $scope.liveSurvey.elementList[i].firstCertifiedSection = true;
                        $scope.certifiedContentStartIndex = i;
                    }

                    $scope.certifiedContentEndIndex = i;
                }
            }
        }
    }

  $scope.markExistEditPanel = function() {
      $scope.existEditPanel = false;
      if ($scope.liveSurvey.elementList != null && $scope.liveSurvey.elementList != undefined) {
          for (var i = 0; i < $scope.liveSurvey.elementList.length; i++) {
              if ($scope.liveSurvey.elementList[i].certifiedSection === false && $scope.liveSurvey.elementList[i].elementTypeId !== $scope.EL_TYPE_ID_PAGEBREAK) {
                  $scope.existEditPanel = true;
                  return;
              }
          }
      }
  }

  //sections = $scope.liveSurvey.elementList
  $scope.populateOptionScaleList = function(sections) {
      if (sections != null && sections != undefined) {
          for (var i = 0; i < sections.length; i++) {
              var section = sections[i];
              if (section.childElementList !== null && section.childElementList !== undefined) {
                  for (var j = 0; j < section.childElementList.length; j++) {
                      if (section.childElementList[j].elementTypeId === 2 && section.childElementList[j].renderTypeId === 5) {
                          section.childElementList[j].optionScaleList = angular.copy(section.childElementList[j].optionList);
                          section.childElementList[j].optionList = [];

                          if (section.childElementList[j].optionScaleList !== undefined && section.childElementList[j].optionScaleList !== null) {
                              //Detecting includeNAOption - the last option value of 0 means that includeNAOption is true.
                              if (section.childElementList[j].optionScaleList[section.childElementList[j].optionScaleList.length - 1].value === 0) {
                                  //there is a value of 0 means that includeNAOption true;
                                  section.childElementList[j].includeNAOption = true;
                              }
                              for (var k = 0; k < section.childElementList[j].optionScaleList.length; k++) {
                                  //delete the order property copied from optionList
                                  delete section.childElementList[j].optionScaleList[k].order;

                                  if (section.childElementList[j].includeNAOption) {
                                      if (k == section.childElementList[j].optionScaleList.length - 1) {
                                          //The N/A icon name is not in the scaleChoices array.
                                          section.childElementList[j].optionScaleList[k].icon = 'icon-block';
                                      }
                                      else {
                                          if (section.childElementList[j].optionScaleList.length === 4) {
                                              section.childElementList[j].optionScaleList[k].icon = scaleChoices[3][k].icon;
                                          }
                                          else if (section.childElementList[j].optionScaleList.length === 5) {
                                              section.childElementList[j].optionScaleList[k].icon = scaleChoices[4][k].icon;
                                          }
                                          else if (section.childElementList[j].optionScaleList.length === 6) {
                                              section.childElementList[j].optionScaleList[k].icon = scaleChoices[5][k].icon;
                                          }
                                      }
                                  }
                                  else {
                                      if (section.childElementList[j].optionScaleList.length === 3) {
                                          section.childElementList[j].optionScaleList[k].icon = scaleChoices[3][k].icon;
                                      }
                                      else if (section.childElementList[j].optionScaleList.length === 4) {
                                          section.childElementList[j].optionScaleList[k].icon = scaleChoices[4][k].icon;
                                      }
                                      else if (section.childElementList[j].optionScaleList.length === 5) {
                                          section.childElementList[j].optionScaleList[k].icon = scaleChoices[5][k].icon;
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
  }

      $scope.init();


// --------LanguageConfig Part Starts---------

      $scope.selection=[];

      $scope.getLanguages = function() {

          if ($scope.certifiedContentEndIndex > -1) {
              SurveyCreateService.getAllLanguagesByTemplateId({templateId: $scope.liveSurvey.copiedSurveyId}).$promise.then(function (languages) {
                  // success handler
                  if ($scope.templateId != null) {
                      SurveyCreateService.getAllLanguagesByTemplateId({templateId: $scope.templateId})
                          .$promise.then(function (checkedLanguages) {
                              // success handler
                              $scope.fetchLanguages(languages, checkedLanguages);
                          }, function (error) {
                              // error handler

                          });
                  }

                  $scope.languages = languages;

              }, function (error) {
                  // error handler


                  errorPopup("Retrieval of all available languages was unsuccessful.", error.data.message, "error");
              });
          }
          else {
              SurveyCreateService.getAllLanguages().$promise.then(function (languages) {
                  // success handler
                  if ($scope.templateId != null) {
                      SurveyCreateService.getAllLanguagesByTemplateId({templateId: $scope.templateId})
                          .$promise.then(function (checkedLanguages) {
                              // success handler
                              $scope.fetchLanguages(languages, checkedLanguages);
                          }, function (error) {
                              // error handler

                          });
                  }

                  $scope.languages = languages;

              }, function (error) {
                  // error handler


                  errorPopup("Retrieval of all available languages was unsuccessful.", error.data.message, "error");
              });
          }
      };

      $scope.fetchLanguages = function(languages, checkedLanguages) {

          if(checkedLanguages) {
              var langArray = [];
              angular.forEach(checkedLanguages, function(checkedLanguage)
              {
                  langArray.push(checkedLanguage.id);
                  $scope.selection.push(checkedLanguage);

              });

              angular.forEach(languages, function(language)
              {
                  var index = langArray.indexOf(language.id);
                  if(language.languageName == 'English'){
                      language.selected = true; //Keep 'English' preselected
                      if($scope.selection.map(function(e) {return e.languageName;}).indexOf(language.languageName) < 0) { // avoid duplicates
                          $scope.selection.push(language); //Display 'English' in language dropdown

                          $scope.saveLanguage({templateId: $scope.templateId, surveyId: $scope.liveSurvey.surveyId, languageId: language.id, userId: userService.getUserId()}); // Save 'English' as a language for this template
                      }
                  }
                  if (index > -1) {
                      language.selected = true;
                  }
              });
          }
      }

      //Disabling the language setting checkbox for default language English
      $scope.isEnglish = function(language){
          if(language.languageName == 'English'){
              return true;
          }else{
              return false;
          }
      }

      $scope.storeLanguagePreferences = function(language, idx)
      {
          var templateId = $scope.templateId;
          var languageId = language.id;

          $scope.toggleSelection(language);




          var languageParams = {templateId: templateId, surveyId: $scope.liveSurvey.surveyId, languageId: languageId, userId: userService.getUserId()};

          if(language.selected){
              if ($scope.liveSurvey.copiedSurveyId == null) {
                  $scope.saveLanguage(languageParams);
              }
              else {
                  languageParams.copiedTemplateId = $scope.liveSurvey.copiedSurveyId;
                  $scope.saveLanguageWithTranslation(languageParams);
              }
          }
          else if(!language.selected){
              $scope.deleteLanguage(languageParams);
          }
      }

      $scope.toggleSelection = function toggleSelection(language) {
          var index = $scope.selection.map(function(e) {return e.languageName;}).indexOf(language.languageName);
          // is currently selected
          if (index > -1) {
              $scope.selection.splice(index, 1);

          }
          // is newly selected
          else {
              $scope.selection.push(language);

          }
      };

      $scope.saveLanguage = function(languageParams){

          SurveyCreateService.saveLanguage(languageParams).$promise.then(function () {
              // success handler
              //$scope.loadLanguageDropdown();
              $scope.updateLastContentEditTimestamp();

          }, function (error) {
              // error handler

          });
      };

      $scope.saveLanguageWithTranslation = function(languageParams){

          SurveyCreateService.saveLanguageWithTranslation(languageParams).$promise.then(function () {
              // success handler
              $scope.updateLastContentEditTimestamp();

          }, function (error) {
              // error handler
              var errorMessage = "Failed to save language with translation.";
              if (error.data != null && error.data.message != null) {
                  errorMessage = error.data.message;
              }
              swal('Error', errorMessage, 'error');
          });
      };

      $scope.deleteLanguage = function(languageParams){

          SurveyCreateService.deleteLanguage(languageParams).$promise.then(function () {
              // success handler
              $scope.updateLastContentEditTimestamp();
              //$scope.loadLanguageDropdown();

          }, function (error) {
              // error handler

          });
      };

      $scope.showLanguagesForDropdown = function(){
          var dropDown = $('.filter__select-choices');
          if (! dropDown.hasClass('visible')) {
              dropDown.addClass('visible').css({ left: 'auto', right: 10 });
          }
          else {
              dropDown.removeClass('visible').css({ left: -9999 });
          }
          $scope.showLanguageDropdown = true;
      };

      $scope.translateSurvey = function(language){
          $scope.rtlFlag = false;
          $scope.languageId = language.id;

          if(language.rtl == 1){
              $scope.rtlFlag = true;
          }

          $scope.showLanguagesForDropdown();
          $scope.editSurvey($scope.languageId, language.languageName, true);

          $timeout(function() {
              $scope.resizeInstructionTextareas();
          }, 500);
      };


//--------LanguageConfig Part Ends-----------

// --------Customization Config Part Starts-----------

    $scope.resetClick = function() {
        if ($scope.viewMode || $scope.sharedSurvey) {
            return;
        }

        $scope.saveEndAction('reset');
    }

    $scope.redirectClick = function() {
        if ($scope.viewMode || $scope.sharedSurvey) {
            return;
        }

        $scope.saveEndAction('redirect');
    }

    $scope.saveEndAction = function(endAction) {
        SurveyCreateService.saveEndAction({surveyId: $scope.liveSurvey.surveyId, action: endAction}).$promise.then(function () {
            // success handler


            $scope.updateLastContentEditTimestamp();

            $scope.liveSurvey.endAction = endAction;
        }, function (error) {
            // error handler

        });
    }

    $scope.updateLastContentEditTimestamp = function()
    {
        $scope.lastContentEditTimestamp = $scope.convertTimestampToDate(new Date().getTime());
    }

    $scope.storeTokenPreferences = function(requireTokenValue){
        if ($scope.viewMode) { return; } // do not save if in view only mode

        var requireTokenValueParam = requireTokenValue ? 1 : 0;

        SurveyCreateService.updateRequireTokenFlag({surveyId: $scope.newSurveyId, userId: userService.getUserId(), requireTokenValue: requireTokenValueParam})
            .$promise.then(function(){
                // success handler

                $scope.updateLastContentEditTimestamp();
            }, function(error){
                // error handler

            });
    }

      $scope.showSectionTitles = function (showSectionTitlesValue) {
          SurveyCreateService.updateShowTitlesFlag({
              surveyId: $scope.newSurveyId,
              userId: userService.getUserId(),
              showSectionTitlesValue: showSectionTitlesValue ? 1 : 0
          }).$promise.then(function () {
              // success handler
              $scope.updateLastContentEditTimestamp();
          }, function (error) {
              // error handler
          });
      }

    $scope.getRedirectLink = function(){
        if ($scope.newSurveyId != null) {
            SurveyCreateService.getRedirectLink({surveyId: $scope.newSurveyId}).$promise.then(function (data) {
                // success handler
                $scope.redirectLink = decodeURIComponent(data.redirectLink);


            }, function (error) {
                // error handler

            });
        }
    }

    $scope.saveRedirectLink = function(redirectLink){
        var encodedURI = encodeURIComponent(redirectLink);
        SurveyCreateService.saveRedirectLink({surveyId: $scope.newSurveyId, redirectLink: encodedURI}).$promise.then(function () {
            // success handler

            $scope.updateLastContentEditTimestamp();
        }, function (error) {
            // error handler

        });
    }

//--------Customization Config Part Ends-----------

//--------Sharing Config Part Starts-----------

    $scope.allInstitutionsByInstitutionId = [];
    $scope.allLicencedInstitutionIds = [];

    $scope.institutions = [];
    var customerIds = [];
    $scope.institution = {};

    //Elastic search for institution Names
    $scope.updateInstitutionOptions = function(){

        if($scope.institution.name) {
          var namePhrase = $scope.institution.name;
          var searchParams = {
              "likeName" : namePhrase,
              "customerId": applicationService.getInstitutionId(),
              "from": 0,
              "size": 15
          }

            applicationService.getInstitutionsForSharing(searchParams)
                    .$promise.then(function (institutions) {
                        if ($scope.institutions.length > 0) {
                            var diff = _.difference(_.pluck(institutions, "id"), _.pluck($scope.institutions, "id"));
                            var result = _.filter(institutions, function(obj) { return diff.indexOf(obj.id) >= 0; });
                            $scope.institutionOptions = result;

                        }else{
                            $scope.institutionOptions = institutions;
                        }

                        // Remove the owner institution from sharing options
                        for(var i=0; i < $scope.institutionOptions.length; i++){
                            if($scope.institutionOptions[i].id == $scope.ownerCustomerId){
                                $scope.institutionOptions.splice(i, 1)
                            }
                        }

            }).catch(function error(msg) {

            });

            //// If there are no institutions in search field to share a survey, dont show sharing accordion
            //if($scope.institutionOptions.length == 0){
            //    $scope.showShareAccordion = false;
            //}

      }
    }

      $scope.selectInstitution = function(institution){
          $scope.selectedInstitution = institution;
          $scope.institution.name = institution.name;
          $scope.disableSubmit = false;
      }

      $scope.resetInstitutionName = function(){
          $scope.institution.name = "";
      }

    // Called on-load of the survey to get the sharing control info & then hierarchy info
    $scope.getSharingControlInfo = function(){
        var surveyId = $scope.liveSurvey.surveyId;
        var institutionId = applicationService.getInstitutionId();
        if($scope.liveSurvey && $scope.liveSurvey.institutionId && $sessionStorage.ownerCustomerName && $sessionStorage.ownerCustomerName[$scope.liveSurvey.institutionId])
        $scope.ownerCustomerName = $sessionStorage.ownerCustomerName[$scope.liveSurvey.institutionId];

        SurveyCreateService.getSharingControlInfo({customerId:institutionId, objectId:surveyId, objectTypeId: '4'})
            .$promise
            .then(function (sharingControlRecords) {
                // success handler
                $scope.sharingControlRecords = sharingControlRecords;



                var checkCustomerIds = _.pluck($scope.sharingControlRecords, 'customerId')

                var searchParams = {
                    "customerId": institutionId,
                    "checkCustomerIds": checkCustomerIds
                }

                return applicationService.getCustomerHierarchyForSharing(searchParams).$promise;
            })
            .then(function (hierarchyRecord) {

                $scope.hierarchyRecord = hierarchyRecord;

                $scope.getAllInstitutionsBySurveyId();



            }).catch(function error(msg) {

            });
    };

    //Called on-click of 'Add' button to share survey
    $scope.saveInstitution = function(){

        var save = false;
        if ($scope.selectedInstitution != undefined) {
            var customerId = $scope.selectedInstitution.id;

            // using institutionId in the below object to carry current(owner) customerId
            var currentCustomerId = applicationService.getInstitutionId();

            if (customerId != undefined) {
                for (var inst in $scope.institutionOptions) {
                    if ($scope.institutionOptions[inst].id == customerId) {
                        save = true;
                    }
                }

                if (save) {
                    var surveyId = $scope.liveSurvey.surveyId;
                    var userId = userService.getUserId();

                    var object = [{
                        objectType: "survey",
                        objectInstanceId: surveyId,
                        ownerUserId: userId,
                        customerId: customerId,
                        userId: '',
                        roles: ["ROLE_VIEWER"],
                        institutionId: currentCustomerId,
                        applicationId: 2
                    }]

                    SurveyCreateService.saveInstitution(object).$promise.then(function () {


                            $scope.updateSharingTimeStamp();

                            var index = "";
                            $scope.institutionOptions.forEach(function (institution) {
                                if (customerId == institution.id) {
                                    index = $scope.institutionOptions.indexOf(institution);
                                    $scope.institutionOptions.splice(index, 1);
                                    //$scope.updateInstitutionOptions();
                                }
                            });

                            var institutionIds = [customerId];
                            $scope.institutions = [];
                            $scope.getSharingControlInfo(); //Get new shared institutions & their Sharing Control Info
                            $scope.institution.name = "";
                            $scope.institutionOptions = [];

                        }).catch(function error(error) {

                        });
                }
                $scope.selectedInstitution = {};
            }
        }
    };

    $scope.updateSharingTimeStamp = function()
    {
        var surveyUpdateDTO = {surveyId: $scope.liveSurvey.surveyId, userId:userService.getUserId(), action: 41/*update last edit timestamp*/};
        SurveyCreateService.update(surveyUpdateDTO).$promise.then(function (data) {
            // success handler

            $scope.updateLastContentEditTimestamp();
            //$scope.hideMessageDelete('message_' + element.id);
        }, function (error) {
            // error handler

            errorPopup("The system encountered a problem, please try again.", error.data.message, "error");
        });
    }

    //Called when permission for a shared institution is changed
    $scope.savePermission = function(role, institutionId){
        var customerId = institutionId;
        var surveyId = $scope.liveSurvey.surveyId;
        var ownerUserId = userService.getUserId();
        var applicationId = '2';

        var data = {applicationId: applicationId,
                    objectTypeId: '4',
                    customerId: customerId,
                    surveyId: surveyId,
                    role: role
                   }

        SurveyCreateService.updatePermission(data).$promise.then(function () {


            $scope.updateSharingTimeStamp();

            //$scope.getAllInstitutionsBySurveyId();
        }).catch(function error(error) {

        });

    }

      //Called on-click of 'X' button to remove from sharing
    $scope.deleteInstitution = function(institutionForDeletion){
        if ($scope.viewMode) {
            return;
        }

        institutionForDeletion.city = institutionForDeletion.addressDTO[0].city;
        institutionForDeletion.state = institutionForDeletion.addressDTO[0].stateDTO.name;

        var institutionId = institutionForDeletion.id;
        var surveyId = $scope.liveSurvey.surveyId;
        var index = "";
        $scope.deletedInst = [];
        angular.forEach($scope.institutions, function(institution) {
            if(institution.id == institutionId){
                index = $scope.institutions.indexOf(institution);
                $scope.deletedInst = institutionForDeletion;
            }
        });

        swal({
                title: "Are you sure?",
                text: "Once unshared, this institution will no longer have access to the survey, its responses or the related reports.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, Unshare!",
                closeOnConfirm: true
            },
            function(isConfirm){
                if(isConfirm) {
                    SurveyCreateService.deleteInstitution({objectId:surveyId, institutionId:institutionId, objectTypeId: '4'}).$promise.then(function () {
                        // success handler

                        $scope.updateSharingTimeStamp();

                        // Remove from UI list
                        $scope.institutions.splice(index, 1);
                        $scope.institutionOptions = [];

                        $scope.institutionOptions.push($scope.deletedInst)


                    }, function (error) {
                        // error handler

                    }, function (httpResponse) {
                        $timeout(errorPopup, 500);
                    });
                }
            });
    };

    $scope.institutionsDetailsMap;
    //Called on-load of survey to load all institutions the survey has been shared with
    $scope.getAllInstitutionsBySurveyId = function(){
        var surveyId = $scope.liveSurvey.surveyId;
        var institutionId = applicationService.getInstitutionId();

      SurveyCreateService.getSurveySharedInstitutionIds({customerId:institutionId, objectId:surveyId, objectTypeId: '4'})
            .$promise
            .then(function (institutionIds) {
                // success handler
                $scope.institutionIds = institutionIds;
                if ($scope.institutionIds.length > 0) {
                    SurveyCreateService.getInstitutionsDetails($scope.institutionIds).$promise.then(function (data) {
                        $scope.institutionsDetailsMap = data.reduce(function(map, obj) {
                            map[obj.id] = obj;
                            return map;
                        }, {});

                        $scope.getInstitutionDetails(0, institutionIds);
                    },
                    function (error) {
                        //popup error message.
                        sweetAlert("Failed to retrieve customer details ....", error.data.message, "error");
                    });
                }

                //console.log("institutionIds == " + $scope.institutionIds);

            }, function (error) {
                // error handler

            });
    };

      $scope.getInstitutionDetails = function(i, institutionIds){

          if (i < institutionIds.length) {

              var institutionId = institutionIds[i];
              var surveyId = $scope.liveSurvey.surveyId;
              var userId = userService.getUserId();

              var params = {objectId:surveyId,
                  objectType: 'survey',
                  customerId:institutionId,
                  userId:userId}

              SurveyCreateService.getUserRole(params)// TODO: Roles Permissions - SurveyCreateService.getUserRole is used to get user roles and apply permissions. This should be moved into userService.
                  .$promise
                  .then(function (roleIdForSharedInstitution) {

                      $scope.roleIdForSharedInstitution = roleIdForSharedInstitution.id;
                      $scope.ownerCustomerId = roleIdForSharedInstitution.ownerCustomerId;

                      // Disable further sharing if current institution's role is not Edit/Admin/4, hide Share Accordion
                      //   , also disable access code management
                      if(institutionId == applicationService.getInstitutionId()){
                          if($scope.roleIdForSharedInstitution != '4'){
                              $scope.showShareAccordion = false;
                              $scope.showTokenManagement = false;
                          }
                      }

                      var institution = $scope.institutionsDetailsMap[institutionId];

                      processInstitutionDetail(institution);

                      i++;
                      $scope.getInstitutionDetails(i, institutionIds);
                  })

              if($scope.ownerCustomerName == undefined){
                  $scope.ownerCustomerName = applicationService.getInstitutionName();
                  $sessionStorage.ownerCustomerName = {};
                  $sessionStorage.ownerCustomerName[$scope.liveSurvey.institutionId] = $scope.ownerCustomerName;
              }
          }
      };

      var processInstitutionDetail = function(institution) {
          var index = $scope.institutions.map(function (e) { return e.institution; }).indexOf(institution.name);
          // is currently selected
          if (index > -1) {
              $scope.institutions.splice(index, 1);

          }
          // is newly selected
          else {

              if ($scope.liveSurvey.institutionId === $scope.ownerCustomerId) {
                  //owner institution is editing/viewing the survey.
                  $scope.sharedSurvey = false;
              }

              //store owner customer name for this object to be displayed in the sharing section
              if(institution.id == $scope.ownerCustomerId){
                  $scope.ownerCustomerName = institution.name;
                  $sessionStorage.ownerCustomerName[$scope.liveSurvey.institutionId] = $scope.ownerCustomerName;
                  $scope.sharingOwner = true;
              }

              institution.permissions = [
                  {name:"View Only"},
                  {name:"Edit"}
              ];
              var permission = "View Only";
              if ($scope.roleIdForSharedInstitution == '4') {
                  permission = "Edit";
              }
              // institution.permissions.name = permission; // TODO: Will - this results in a corrupted array. Consider refactoring/removing.
              institution.selectedPermission = permission; // TODO: Will - suggested fix for above. Also see lns 170-175 of surveyConfiguration.html
              institution.id = institution.id + "";
              $scope.institutions.push(institution);

              // Remove Owner/Self institution from shared survey display list
              if(institution.id == $scope.ownerCustomerId || institution.id == applicationService.getInstitutionId()){

                  for (var count = 0; count <= $scope.institutions.length; count++){
                      if(institution.id == $scope.institutions[count].id){
                          $scope.institutions.splice(count, 1)
                      }
                  }
              }

              // Make shared institutions editable based on sharingControlInfo
              for(var m = 0; m < $scope.institutions.length; m++){
                  for(var n = 0; n < $scope.sharingControlRecords.length; n++){
                      if($scope.institutions[m].id == $scope.sharingControlRecords[n].customerId){
                          if($scope.sharingControlRecords[n].sourceCustomerId == applicationService.getInstitutionId()){
                              $scope.institutions[m].editable = true;
                              //$scope.editableSharingPermission = true;
                          }
                          else{
                              $scope.institutions[m].editable = false;
                              //$scope.editableSharingPermission = false;
                          }
                      }
                  }
              }

          }
      }
      
//--------Sharing Config Part Ends-----------

    $scope.populateData = function(data) {
        $scope.liveSurvey = data;
        // Convert Timestamp to Date/Time
        $scope.lastContentEditTimestamp = $scope.convertTimestampToDate($scope.liveSurvey.lastContentEditTimestamp);
        $scope.surveyDataLoaded = true;
        if ($scope.liveSurvey.elementList === undefined || $scope.liveSurvey.elementList === null) {
            $scope.liveSurvey.elementList = [];
        }
        $scope.liveSurvey.surveyURL = $scope.liveSurvey.surveyURL + data.surveyId;
        $scope.liveSurvey.templateId = data.templateId;

        $scope.mapMatrix();
        $scope.mapTags();

        $timeout(function() {
            $scope.resizeInstructionTextareas();
        });
        $scope.getSharingInformation();
    };

    $scope.mapMatrix = function () {
        if ($scope.liveSurvey.elementList !== undefined && $scope.liveSurvey.elementList !== undefined) {
            var elementGroupId = null;
            for (var i = 0; i < $scope.liveSurvey.elementList.length; i++) {
                if ($scope.liveSurvey.elementList[i].childElementList !== undefined) {
                    for (var j = 0; j < $scope.liveSurvey.elementList[i].childElementList.length; j++) {
                        var currElementGroupId = $scope.liveSurvey.elementList[i].childElementList[j].elementGroupId;

                        // a message or matrix message is expected as the first element of a matrix grouping
                        if (currElementGroupId != null && currElementGroupId != elementGroupId &&
                                ($scope.liveSurvey.elementList[i].childElementList[j].elementTypeId === $scope.EL_TYPE_ID_MATRIX /* 5 */ ||
                                    $scope.liveSurvey.elementList[i].childElementList[j].elementTypeId === $scope.EL_TYPE_ID_MESSAGE /* 3 */)) {
                            elementGroupId = currElementGroupId;
                            $scope.liveSurvey.elementList[i].childElementList[j].isPartOfMatrix = true;
                        }

                        if (elementGroupId != null && currElementGroupId === elementGroupId &&
                                $scope.liveSurvey.elementList[i].childElementList[j].elementTypeId === $scope.EL_TYPE_ID_ITEM /* 2 */) {
                            $scope.liveSurvey.elementList[i].childElementList[j].isPartOfMatrix = true;
                        }
                    }
                }
            }
        }
    };

  $scope.mapTags = function () {
      if ($scope.liveSurvey.elementList !== null && $scope.liveSurvey.elementList !== undefined) {
          var parentCount = $scope.liveSurvey.elementList.length;
          for (var i = 0; i < parentCount; i++) {
              if ($scope.liveSurvey.elementList[i].childElementList !== null && $scope.liveSurvey.elementList[i].childElementList !== undefined) {
                  if ($scope.liveSurvey.elementList[i].demographic) {
                      for (var j = 0; j < $scope.liveSurvey.elementList[i].childElementList.length; j++) {
                          if ($scope.liveSurvey.elementList[i].childElementList[j].tags !== undefined &&
                              $scope.liveSurvey.elementList[i].childElementList[j].tags !== null &&
                              $scope.liveSurvey.elementList[i].childElementList[j].tags.length > 0) {
                              $scope.liveSurvey.elementList[i].childElementList[j].tags = $scope.mapDemographicsIdsToNames($scope.liveSurvey.elementList[i].childElementList[j].tags);
                          }
                      }
                  }
                  else {
                      for (var j = 0; j < $scope.liveSurvey.elementList[i].childElementList.length; j++) {
                          if ($scope.liveSurvey.elementList[i].childElementList[j].tags !== undefined &&
                              $scope.liveSurvey.elementList[i].childElementList[j].tags !== null &&
                              $scope.liveSurvey.elementList[i].childElementList[j].tags.length > 0) {
                              $scope.liveSurvey.elementList[i].childElementList[j].tags = $scope.mapTagIdsToNames($scope.liveSurvey.elementList[i].childElementList[j].tags);
                          }
                      }
                  }
              }
          }
      }
  }

    $scope.status = function() {
        if ($scope.liveSurvey !== undefined) {
            return survey_status[$scope.liveSurvey.status];
        }
        else {
            return "Draft";
        }
    };


    $scope.beginCopyCertifiedContent = function(copiedContentIdParam) {
        var survey = {copiedContentId:copiedContentIdParam};
        SurveyCreateService.get(survey).$promise.then(function (data) {
            // success handler
        }, function (error) {
            // error handler

        });
    };

    $scope.dateOptions = {
        inline: true,
        prevText: 'h',
        nextText: 'i',
        onSelect: function(value, picker){
            $scope.showingAutoCloseCalendar = false
        }
    };

    $scope.convertTimestampToDate = function(timestamp){
        var amPm = ' am';
        timestamp = parseInt(timestamp);
        var d = new Date(timestamp);
        var month = d.getUTCMonth();
        var day = d.getDate();
        var year = d.getFullYear();
        var hours = d.getHours();
        if(hours > 12){
            hours = hours - 12;
            amPm = ' pm';
        }
        var minutes = d.getUTCMinutes();
        minutes = minutes < 10 ? "0" + minutes : minutes;


        return (month +1) + '/' + day + '/' + year + ' ' + hours + ':' + minutes + amPm;
    };

    $scope.showAutoCloseCalendar = function(value){
        if(value){
            $scope.showingAutoCloseCalendar = value
        } else{
            $timeout(function(){
                $scope.showingAutoCloseCalendar = value
            }, 200)
        }
    }

    //$http.get('data/survey_q_text.json').success(function(resp){  // TODO: Will - this appears to be mock data used to create the controller back in 2015. Should probably be removed.
    //    $scope.survey_q_text = resp
    //})
    //
    //$scope.survey = { // TODO: Will -this appears to be mock data used to create the controller back in 2015. Should probably be removed.
    //  shortName: 'AP385Q',
    //  name: 'Parent Stakeholder Survey Fall 2014',
    //  status: 'Draft',
    //  whenComplete: 'redirect',
    //  sections: [
    //    {
    //        id: 0,
    //        name: 'Purpose and Direction',
    //        elementTypeId: 1,    //section
    //        elements: [
    //            {
    //                id: 0,
    //                elementTypeId: 3,
    //                name: 'Introduction',
    //                text: "The purpose of this survey is to find out your opinions about your child's school. Read each statement and choose the response that most closely matches your opinion. Record your response on the answer sheet provided. Open ended responses should be recorded on this survey document. Please answer each item honestly. Your responses will be completely confidential. Please return both this survey and the answer sheet."
    //            },
    //            {
    //                id: 2,
    //                elementTypeId: 2,
    //                text: "Our school\'s purpose statement is clearly focused on student success",
    //                optionList: [
    //                    {value: 'Yes', text: 'OK'},
    //                    {value: 'No', text: 'Cancel'},
    //                    {value: 'Maybe', text: 'YesNo'},
    //                ],
    //                renderTypeId: 1,
    //                scalePointsCount: 5,
    //                tags: ['Higher Learning'],
    //                required: true,
    //                others: {}
    //            },
    //            {   elementTypeId: 4},
    //        ]
    //    },
    //    {
    //        id: 1,
    //        name: 'Governance and Leadership',
    //        elementTypeId: 1,
    //        elements: [
    //            {
    //                id: 3,
    //                elementTypeId: 2,
    //                text: "Our school's purpose statement is formally reviewed and revised with involvement from parents",
    //                renderTypeId: 5,
    //                scalePointsCount: 5,
    //                tags: ['Higher Learning'],
    //                required: false,
    //                others: {}
    //            }
    //        ]
    //    },
    //    {
    //        id: 3,
    //        name: 'Teaching and Assessing',
    //        elementTypeId: 1,
    //        certified: true,
    //        elements: [
    //            {
    //                id: 4,
    //                elementTypeId: 2,
    //                text: "Our school's purpose statement is formally reviewed and revised with involvement from parents",
    //                renderTypeId: 5,
    //                scalePointsCount: 5,
    //                tags: ['Higher Learning'],
    //                required: false,
    //                others: {}
    //            }
    //        ]
    //    },
    //    {
    //        id: 2,
    //        elementTypeId: 1,
    //        name: 'Thank You',
    //        instructions: "Thank you for taking the time to complete this survey!",
    //        elements: []
    //    },
    //  ]
    //};

    $scope.allTags = [
        'Active Learning',
        'Digital Learning',
        'Equitable Learning',
        'Governance',
        'High Expectations',
        'Progress Monitoring',
        'Purpose and Direction',
        'Quality Programs',
        'Resource Management',
        'School Climate',
        'Supportive Learning',
        'Well-Managed',
    ]

    $scope.questionTypes = [
        1,
        2,
        3,
        4,
        5,
    ];
    $scope.questionTypeLabels = {
        1:   {label: 'Pick One',      icon: 'icon-pick-one'},
        2:    {label: 'Pick Multiple', icon: 'icon-pick-multiple'},
        3:   {label: 'Dropdown',      icon: 'icon-dropdown'},
        4:  {label: 'Text Field',    icon: 'icon-text-field'},
        5:      {label: 'Opinion Scale', icon: 'icon-opinion-scale'}
    }

    //$scope.verifyUnlock = function() { // TODO: Will - this method is not called anywhere. Should probably be removed.
    //  modalService.presentCustomModal({
    //      title: "",
    //      text: $('#unlockPrompt').html(),
    //      type: "warning",
    //      showCancelButton: true,
    //      confirmButtonColor: "#DD6B55",
    //      confirmButtonText: "Continue",
    //      closeOnConfirm: false,
    //      html: true,
    //      customClass: "long-content"
    //  }, function() {
    //      if ($('#unlock_confirm input').is(':checked')) {
    //          swal("Unlocked!", "Survey is now unlocked", "success");
    //          $scope.survey.unlocked = true;
    //      } else {
    //          $('#unlock_confirm').css('color','red');
    //      }
    //  })
    //};

    $scope.focusSurveyName = function() {
        $scope.prevSurveyName = $scope.liveSurvey.surveyName;
    }

    //validate if survey name is unique
    $scope.validateSurveyName = function() {
        if ($scope.liveSurvey.surveyName === '') {
            $("#surveyName").focus();
            return;
        }

        var survey = {
          languageId: $scope.languageId,
          surveyId: $scope.liveSurvey.surveyId,
          validateSurveyName: true,
          userId: userService.getUserId(),
          institutionId: applicationService.getInstitutionId(),
          surveyName: $scope.liveSurvey.surveyName,
          newTemplateId: $scope.templateId};



        SurveyCreateService.save(survey, null /*no param object*/).$promise.then(function (data) {
            // success handler
            $scope.validationFailed = !data.success;

            if ($scope.prevSurveyName !== $scope.liveSurvey.surveyName) {
                $scope.updateLastContentEditTimestamp();
            }

        }, function (error) {
            // error handler


            errorPopup("The system encountered a problem, please try again.", error.data.message, null);
        });
    }

    $scope.elementTextKeyPress = function(element) {
        element.edited = true;

        if ($scope.liveSurvey.status === $scope.OPEN || $scope.liveSurvey.status === $scope.CLOSED) {
            if ($scope.ddDataChanging === undefined || $scope.ddDataChanging === null) {
                $scope.ddDataChanging = ' ';
            }

            var originalLineCount = ($scope.ddDataChanging.match(/\n/g) || []).length;

            var currDDDataElement = document.getElementById("dd_data_" + element.id);
            if (currDDDataElement !== null && currDDDataElement !== undefined) {
                var currDDData = document.getElementById("dd_data_" + element.id).value;
                var currLineCount = (currDDData.match(/\n/g) || []).length;

                if (currLineCount !== originalLineCount) {
                    //restore the value if use add/remove lines
                    document.getElementById("dd_data_" + element.id).value = $scope.ddDataChanging
                }
                else {
                    //save the previous ddData
                    $scope.ddDataChanging = document.getElementById("dd_data_" + element.id).value;
                }
            }
        }
    }

    $scope.ddDataChanging = "";
    $scope.dropdownDataFocus = function(element) {
        $scope.ddDataChanging = document.getElementById("dd_data_" + element.id).value;
    }

    $scope.elementIncludeNAClick = function(element) {
        element.includeNAOption = !element.includeNAOption;
        element.edited = true;
        if (element.includeNAOption) {
            element.optionScaleList.push(angular.copy(allScaleValues[5]));
        }
        else {
            element.optionScaleList.pop();
        }
    }

    $scope.findSectionIndex = function(section) {
        for (var i = 0; i < $scope.liveSurvey.elementList.length; i++) {
            if ($scope.liveSurvey.elementList[i] === section) {
                return i;
            }
        }

        return -1;
    }

    $scope.demotagDisplay = function(element, section) {
        if (section.demographic) {
            return "Set demographics";
        }
        else {
            return "Add tags"
        }
    }

    $scope.availableTags = function(element, section) {
        var availableTags = [];

        if (section.demographic) {
            for (var i = 0; i < $scope.liveSurvey.demographicsList.length; i++) {
                if (element.tags.indexOf($scope.liveSurvey.demographicsList[i].name) < 0) {
                    availableTags.push($scope.liveSurvey.demographicsList[i].name);
                }
            }
        }
        else {
            for (var i = 0; i < $scope.liveSurvey.tagsList.length; i++) {
                if (element.tags.indexOf($scope.liveSurvey.tagsList[i].name) < 0) {
                    availableTags.push($scope.liveSurvey.tagsList[i].name);
                }
            }
        }

        return availableTags;
    }

    $scope.elementAddTag = function(element, tag, section) {

        element.edited = true;

        if (section.demographic)
        {
            element.tags = [];
        }
        element.tags.push(tag);
    }

    $scope.elementDeleteTag = function(element, index) {

        element.edited = true;
        element.tags.splice(index, 1)
    }

    $scope.elementSetRenderType = function(element, type) {
        element.edited = true;
        element.renderTypeId = type;

        if (type === 5) {//option scale
            if (element.optionScaleList  === undefined || element.optionScaleList.length == 0) {
                element.optionScaleList = angular.copy(scaleChoices[5]);
            }
        }
    }

    $scope.elementSetScaleCount = function(element, count) {

        if (element.scalePointsCount != count) {
            element.edited = true;
            element.scalePointsCount = count;

            if (count == 5) {
                element.optionScaleList = angular.copy(scaleChoices[5]);
            }
            else if (count == 4) {
                element.optionScaleList = angular.copy(scaleChoices[4]);
            }
            else { //count == 3
                element.optionScaleList = angular.copy(scaleChoices[3]);
            }

            if (element.includeNAOption) {
                element.optionScaleList.push(angular.copy(allScaleValues[5]));
            }
        }
    }

    $scope.isEditingAnItem = function () {
          if ($scope.liveSurvey.elementList !== null && $scope.liveSurvey.elementList !== undefined) {
              var parentCount = $scope.liveSurvey.elementList.length;
              for (var i = 0; i < parentCount; i++) {
                  if ($scope.liveSurvey.elementList[i].childElementList !== null && $scope.liveSurvey.elementList[i].childElementList !== undefined) {
                      for (var j = 0; j < $scope.liveSurvey.elementList[i].childElementList.length; j++) {
                          if ($scope.liveSurvey.elementList[i].childElementList[j].edited === true) {
                              return true;
                          }
                      }
                  }
              }
          }

          return false;
    }

    $scope.currentlyEditing = null;
    var alreadyClicked = false;
    $scope.setCurrentlyEditing = function(element, $event, certifiedSection){

        var updateElement = $scope.findEditedElement();
        if (updateElement !== null && (updateElement.tags.length == 0 || updateElement.text === null || updateElement.text === "")) {
            return;
        }

        if (element === null) {
            var now = (new Date()).getTime();
            if (now - $scope.itemClickedTime < 100) {
                //This is the click from item that propagating to the parent and trigger again.
                return;
            }
        }

        //Certified element has element.masterElementId != null. This is true for section and item.
        if ((element === null || element.masterElementId == null) && !$scope.viewMode) {
            //Prevent multiple events from firing at once
            if (!alreadyClicked) {
                //compare for diff here
                /*if (element !== null && element !== undefined && element.elementTypeId === 2) {
                    //editing an question
                    element.edited = true;
                }*/
                $scope.updateItem(element, updateElement);
                $scope.currentlyEditing = element;
                //$scope.originalCurrent = angular.copy($scope.currentlyEditing);
                alreadyClicked = true
                setTimeout(function () {
                    alreadyClicked = false
                }, 300);
            }
        }
    };

    $scope.itemClickedTime = 0;
    $scope.setCurrentlyEditingElement = function(element, $event, certifiedSection){
        if (element.isPartOfMatrix) { return false; }

        $scope.itemClickedTime = (new Date()).getTime();
        $scope.setCurrentlyEditing(element, $event, certifiedSection);
    };

    $scope.updateItem = function(element, updateElement) {


        if ($scope.currentlyEditing == element) {
            return;
        }

        if (updateElement != null && updateElement.edited == true) {
            //something has changed with this element (item NOT section); update backend


            $scope.lastUpdatedTime = new Date();

            $scope.saveItem(updateElement, null, false);
        }
    };

    $scope.mapTagNamesToIds = function(tagNames, demographicsSection) {
        var tagIds = [];

        if (demographicsSection) {
            for (var i = 0; i < tagNames.length; i++) {
                for (var j = 0; j < $scope.liveSurvey.demographicsList.length; j++) {
                    if ($scope.liveSurvey.demographicsList[j].name === tagNames[i]) {
                        tagIds.push($scope.liveSurvey.demographicsList[j].id);
                    }
                }
            }
        }
        else {
            for (var i = 0; i < tagNames.length; i++) {
                for (var j = 0; j < $scope.liveSurvey.tagsList.length; j++) {
                    if ($scope.liveSurvey.tagsList[j].name === tagNames[i]) {
                        tagIds.push($scope.liveSurvey.tagsList[j].id);
                    }
                }
            }
        }

        return tagIds;
    };

      $scope.mapTagIdsToNames = function(tagIds) {
          var tagNames = [];
          for (var i = 0; i < tagIds.length; i++) {
              for (var j = 0; j < $scope.liveSurvey.tagsList.length; j++) {
                  if ($scope.liveSurvey.tagsList[j].id == tagIds[i]) {
                      tagNames.push($scope.liveSurvey.tagsList[j].name);
                  }
              }
          }

          return tagNames;
      }

      $scope.mapDemographicsIdsToNames = function(tagIds) {
          var tagNames = [];
          for (var i = 0; i < tagIds.length; i++) {
              for (var j = 0; j < $scope.liveSurvey.demographicsList.length; j++) {
                  if ($scope.liveSurvey.demographicsList[j].id == tagIds[i]) {
                      tagNames.push($scope.liveSurvey.demographicsList[j].name);
                  }
              }
          }

          return tagNames;
      };

    $scope.focusMessage = function(messageElementId, element) {
        $scope.activeMessages[messageElementId] = true;
        $scope.prevMessageText = element.text;
    }

    $scope.messageBlur = function(element, section) {
        if ($scope.prevMessageText === element.text) {
            //nothing change; do not save
            return;
        }

        //The sort value of this new item is elementCount + 1. elementId is the section id
        var surveyItemUpdateDTO = {id: element.id, elementTypeId: element.elementTypeId, renderTypeId: element.renderTypeId,
            sort: element.sort, text: element.text};
        var surveyUpdateDTO = {surveyId: $scope.liveSurvey.surveyId, userId:userService.getUserId(), languageId: $scope.languageId, elementId: section.id, action: 4/*update item*/, templateId: $scope.liveSurvey.newTemplateId,
            surveyItemUpdateDTO: JSON.stringify(surveyItemUpdateDTO)};
        SurveyCreateService.update(surveyUpdateDTO).$promise.then(function (data) {
            // success handler

            $scope.updateLastContentEditTimestamp();
            $scope.hideMessageDelete('message_' + element.id);
        }, function (error) {
            // error handler

            errorPopup("The system encountered a problem, please try again.", error.data.message, "error");
        });
    };

    $scope.hideMessageDelete = function(messageElementId) {
        $timeout(function(){
            $scope.activeMessages[messageElementId] = false;
        }, 300)
    };

    $scope.activeMessages = {};

    $scope.viewMessage = function(messageElementId) {
        return $scope.activeMessages[messageElementId] && !$scope.viewMode && $scope.liveSurvey.status !== $scope.OPEN && $scope.liveSurvey.status !== $scope.CLOSED;
    };

    $scope.splitAdditionAndDeletionIds = function(ids) {
        var allIds = new Object();
        allIds.addition = [];
        allIds.deletion = [];

        for (var i = 0; i < ids.length; i++) {
            if (ids[i].charAt(0) === '-') {
                allIds.deletion.push(ids[i].substr(1));
            }
            else {
                allIds.addition.push(ids[i]);
            }
        }

        return allIds;
    }

    $scope.topElement = null;

    $scope.sectionHasElement = function(section, element){
        return section.childElementList.indexOf(element) !== -1;
    }

    $scope.showCopy = function(element) {
        if (element.edited === undefined) {
            return true;
        }

        return element.edited === false;
    }

    $scope.deleteOption = function(element, index) {
        element.edited = true;
        element.optionList.splice(index, 1);
    }

    $scope.addElement = function(elementTypeId, section) {
        if ( !$scope.viewMode && $scope.liveSurvey.status !== $scope.OPEN && $scope.liveSurvey.status !== $scope.CLOSED) {

            if ($scope.isEditingAnItem()) {
                if ($scope.liveSurvey.elementList !== null && $scope.liveSurvey.elementList !== undefined) {
                    var parentCount = $scope.liveSurvey.elementList.length;
                    for (var i = 0; i < parentCount; i++) {
                        var tmpSection = $scope.liveSurvey.elementList[i];
                        if (tmpSection.childElementList !== null && tmpSection.childElementList !== undefined) {
                            for (var j = 0; j < tmpSection.childElementList.length; j++) {
                                if (tmpSection.childElementList[j].edited === true) {
                                    var elementTextUI = document.getElementById("question_" + tmpSection.childElementList[j].id);
                                    if (elementTextUI !== null && elementTextUI !== undefined) {
                                        elementTextUI.scrollIntoViewIfNeeded();
                                    }
                                }
                            }
                        }
                    }
                }
                return;
            }

            if (elementTypeId === 1) {
                $scope.addSection(section);
            }
            else if (elementTypeId === 2) {
                $scope.addItem(section, elementTypeId);
            }
            else if (elementTypeId === 3) {
                $scope.addItem(section, elementTypeId);
            }
            else if (elementTypeId === 4) {
                $scope.addItem(section, elementTypeId);
                //$scope.addPageBreak(section);
            }
        }
    };

    $scope.addPageBreak = function(section) {
        var previousSectionIndex = -1;
        var newSectionSort = 1;
        if (section == undefined) {
            if ($scope.liveSurvey.elementList.length > 0) {
                previousSectionIndex = $scope.liveSurvey.elementList.length - 1;
            }
        }
        else {
            newSectionSort = section.sort + 1;
            previousSectionIndex = $scope.findSectionIndex(section);
        }

        var survey = {userId:userService.getUserId(), surveyId: $scope.liveSurvey.surveyId, sectionText: '- Page Break -',
            templateId: $scope.liveSurvey.newTemplateId, action: 7, surveyItemUpdateDTO: "", sectionSort: newSectionSort};
        SurveyCreateService.update(survey).$promise.then(function (data) {
            // success handler


            $scope.updateLastContentEditTimestamp();

            if ($scope.liveSurvey.elementList == undefined || $scope.liveSurvey.elementList === null) {
                $scope.liveSurvey.elementList = [];
            }

            $scope.liveSurvey.elementList.splice(previousSectionIndex + 1, 0, {
                name: '',
                text: '- Page Break -',
                certifiedSection: false,
                elementTypeId: 4,
                id: parseInt(data.message),
                sort: survey.sectionSort,
                childElementList: []
            });
            $scope.updateSectionLabels(true);

        }, function (error) {
            // error handler


            sweetAlert("The system encountered a problem, please try again.", error.data.message, "error");
        });
    }


    $scope.deletePageBreak = function(sectionIndex) {
            $scope.deleteSection(sectionIndex, 'page break');
    }

    $scope.addItem = function(section, elementTypeId) {
        //First, count the number of elements in the section
        var newElementSort = 1;
        if (section.childElementList !== null && section.childElementList !== undefined && section.childElementList.length > 0) {
            newElementSort = section.childElementList[section.childElementList.length-1].sort + 1;
        }

        //The sort value of this new item is elementCount + 1. elementId is the section id
        var surveyItemUpdateDTO = {label: $scope.calcNextItemLabel(section), elementTypeId: elementTypeId, sort: newElementSort, text: ''};
        var surveyUpdateDTO = {surveyId: $scope.liveSurvey.surveyId, userId:userService.getUserId(), languageId: $scope.languageId, elementId: section.id, action: 3/*add element*/,
            templateId: $scope.liveSurvey.newTemplateId, surveyItemUpdateDTO: JSON.stringify(surveyItemUpdateDTO)};
        SurveyCreateService.update(surveyUpdateDTO).$promise.then(function (data) {
            // success handler


            $scope.updateLastContentEditTimestamp();



            var element = {elementTypeId: elementTypeId, id: parseInt(data.message), text: '', renderTypeId: null, sort: surveyItemUpdateDTO.sort};
            if(elementTypeId == 2){ //'question'
                element.optionList = [];
                element.tags = [];
                element.edited = true;
                element.masterElementId = null; //All customed element has masterElementId of null.
            }
            else if (elementTypeId == 4) {
                element.text = '- Page Break -';
            }
            section.childElementList.push(element);
            $timeout(function(){
                $scope.currentlyEditing = element;
            })
        }, function (error) {
            // error handler


            sweetAlert("The system encountered a problem, please try again.", error.data.message, "error");
        });
    }

    $scope.addSection = function(section, thenElementTypeId) {
        if ($scope.viewMode || $scope.liveSurvey.status === $scope.OPEN || $scope.liveSurvey.status === $scope.CLOSED || (section != null && section.certifiedSection === true)) {
            return;
        }

        var newSectionSort = 1;
        var previousSectionIndex = -1;
        var newSectionLabel = "A";
        if (section == undefined) {
            if ($scope.liveSurvey.elementList.length > 0) {
                previousSectionIndex = $scope.liveSurvey.elementList.length - 1;
                newSectionLabel = $scope.calcNextSectionLabel(previousSectionIndex);
                newSectionSort = $scope.liveSurvey.elementList[previousSectionIndex].sort + 1;
            }
        }
        else {
            newSectionSort = section.sort + 1;
            previousSectionIndex = $scope.findSectionIndex(section);
            newSectionLabel = $scope.calcNextSectionLabel(previousSectionIndex);
        }

        var survey = {userId:userService.getUserId(), surveyId: $scope.liveSurvey.surveyId, sectionName: 'New Section', languageId: $scope.languageId, label: newSectionLabel,
            templateId: $scope.liveSurvey.newTemplateId, action: 1, surveyItemUpdateDTO: "", sectionSort: newSectionSort};
        SurveyCreateService.update(survey).$promise.then(function (data) {
            // success handler


            $scope.updateLastContentEditTimestamp();

            if ($scope.liveSurvey.elementList === undefined || $scope.liveSurvey.elementList === null) {
                $scope.liveSurvey.elementList = [];
            }

            $scope.liveSurvey.elementList.splice(previousSectionIndex + 1, 0, {
                name: 'New Section',
                number: newSectionLabel,
                text: "",
                certifiedSection: false,
                elementTypeId: 1,
                id: parseInt(data.message),
                sort: survey.sectionSort,
                childElementList: []
            });

            $scope.existEditPanel = true;

            $scope.updateSectionLabels(true);

            var currentIndex = (previousSectionIndex + 1).toString();

            $scope.addUIDroppable(true);

            setTimeout(function() {
                $('.page-container').animate({scrollTop: $('.page-container').scrollTop() + $('#section-' + currentIndex).offset().top - 50}, 500);
                if (thenElementTypeId !== undefined) {
                    $scope.addElement(thenElementTypeId, $scope.liveSurvey.elementList[previousSectionIndex + 1]);
                }
            }, 500);
        }, function (error) {
            // error handler


            sweetAlert("The system encountered a problem, please try again.", error.data.message, "error");
        });
    };

    $scope.deleteSection = function(index, type) {
        if(!type){ type = 'section' }


        if ($scope.liveSurvey.elementList[index].demographic) {
            swal({
                    title: "Are you sure?",
                    text: "You are about to delete demographics from this survey, which removes grouping data from reports and the activity dashboard. Do you wish to delete the demographics? Continue (proceed with deleting the section) or Cancel (close modal and return to survey content).",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes, delete it!",
                    closeOnConfirm: true
                }, function (isConfirm) {
                    if (isConfirm) {
                        $scope.deleteSectionHelper(index);
                    }
                }
            );
        }
        else {
            errorPopupChoice('Selecting yes will delete this ' + type + '.', function(){
                $scope.deleteSectionHelper(index);
            });
        }
    }

    $scope.deleteSectionHelper = function(index) {
        var surveyUpdateDTO = {userId:userService.getUserId(), action: 11/*delete section*/, templateId: $scope.liveSurvey.newTemplateId, surveyId: $scope.liveSurvey.surveyId,
            elementId: $scope.liveSurvey.elementList[index].id, sectionSort:$scope.liveSurvey.elementList[index].sort};

        SurveyCreateService.update(surveyUpdateDTO).$promise.then(function (data) {
            // success handler


            $scope.updateLastContentEditTimestamp();



            $scope.liveSurvey.elementList.splice(index, 1);

            //Toggle edit panel.
            $scope.markExistEditPanel();

            $scope.updateSectionLabels(true);
        }, function (error) {
            // error handler


            //sweetAlert("The system encountered a problem, please try again.", error.data.message, "error");
            var sAlert = function() {
                errorPopup("The system encountered a problem, please try again.", error.data.message, "error");
            };
            $timeout(sAlert, 500);
        });
    }

    //message and pagebreak are items
    $scope.deleteMessage = function(section, element) {
        $scope.deleteElement(element, section, 'message');
    }

    //pagebreak and message are items
    $scope.deleteItemPageBreak = function(section, element) {
        $scope.deleteElement(element, section, 'page break');
    }

    //NOTE: $scope.liveSurvey.elementList[i] is section[i]
    //$scope.liveSurvey.elementList[i].childElementList[j] is the jth child of section[i]
    $scope.largestSortValue = function() {
        if ($scope.liveSurvey.elementList === undefined || $scope.liveSurvey.elementList === null || $scope.liveSurvey.elementList.length === 0) {
            return 0;
        }

        var largestSortValue = 0;
        var parentCount = $scope.liveSurvey.elementList.length;
        for (var i = 0; i < parentCount; i++) {
            if ($scope.liveSurvey.elementList[i].sort > largestSortValue) {
                largestSortValue = $scope.liveSurvey.elementList[i].sort;
            }
            if ($scope.liveSurvey.elementList[i].childElementList !== null && $scope.liveSurvey.elementList[i].childElementList !== undefined) {
                for (var j = 0; j < $scope.liveSurvey.elementList[i].childElementList.length; j++) {
                    if ($scope.liveSurvey.elementList[i].childElementList[j].sort > largestSortValue) {
                      largestSortValue = $scope.liveSurvey.elementList[i].childElementList[j].sort;
                    }
                }
            }
        }

        return largestSortValue;
    }

    $scope.findEditedElement = function () {
        var parentCount = $scope.liveSurvey.elementList.length;
        for (var i = 0; i < parentCount; i++) {
            if ($scope.liveSurvey.elementList[i].childElementList !== null && $scope.liveSurvey.elementList[i].childElementList !== undefined) {
                for (var j = 0; j < $scope.liveSurvey.elementList[i].childElementList.length; j++) {
                    if ($scope.liveSurvey.elementList[i].childElementList[j].edited === true) {
                        $scope.currentSectionIndex = i;
                        return $scope.liveSurvey.elementList[i].childElementList[j];
                    }
                }
            }
        }

        $scope.currentSectionIndex = -1;
        return null;
    }

      //Return the section contains the element
      $scope.findElementParent = function (elementId) {
          var parentCount = $scope.liveSurvey.elementList.length;
          for (var i = 0; i < parentCount; i++) {
              if ($scope.liveSurvey.elementList[i].childElementList !== null && $scope.liveSurvey.elementList[i].childElementList !== undefined) {
                  for (var j = 0; j < $scope.liveSurvey.elementList[i].childElementList.length; j++) {
                      if ($scope.liveSurvey.elementList[i].childElementList[j].id === elementId) {
                          return $scope.liveSurvey.elementList[i];
                      }
                  }
              }
          }

          return null;
      }

    $scope.copyItemInProgress = false;
    $scope.copyItem = function(element, section) {


        if ($scope.copyItemInProgress) {
            return;
        }

        if (element.edited) { // do nothing if the element is edited.
            $scope.saveItem(element, section, true);
            return;
        }

        $scope.copyItemInProgress = true;

        var surveyItemUpdateDTO = {id: element.id, sort: element.sort};
        var surveyUpdateDTO = {userId:userService.getUserId(), languageId: $scope.languageId, action: 13/*copy element*/, templateId: $scope.liveSurvey.newTemplateId,
            surveyItemUpdateDTO: JSON.stringify(surveyItemUpdateDTO)};

        SurveyCreateService.update(surveyUpdateDTO).$promise.then(function (data) {
            // success handler


            $scope.updateLastContentEditTimestamp();

            var elementIdoptionIds = data.message.split(',');

            var index = section.childElementList.indexOf(element);

            var newElement = angular.copy(element);
            newElement.edited = false;
            newElement.id = elementIdoptionIds[0];  // the first element in elementIdoptionIds is the new elementId

            if (elementIdoptionIds.length > 1) {
                //element has option. update the option ids.

                //update optionList/optionScaleList
                ////////////////////////////////////////
                if (element.renderTypeId !== 5) {
                    for (var i = 0; i < element.optionList.length; i++) {
                        newElement.optionList[i].id = elementIdoptionIds[1 + i];   //optionIds in elementIdoptionIds starts at 1.
                    }
                }
                else {
                    for (var i = 0; i < element.optionScaleList.length; i++) {
                        newElement.optionScaleList[i].id = elementIdoptionIds[1 + i];   //optionIds in elementIdoptionIds starts at 1.
                    }
                }
            }

            section.childElementList.splice(index + 1, 0, newElement);

            for (var i = index + 1; i < section.childElementList.length; i++) {
                section.childElementList[i].sort += 1;
            }

            $scope.updateItemLabels(section);

            $scope.copyItemInProgress = false;

        }, function (error) {
            // error handler
            $scope.copyItemInProgress = false;


            errorPopup("The system encountered a problem, please try again.", error.data.message, "error");
        });
    }

    $scope.saveItem = function(updateElement, section, copying) {
        if (updateElement !== null && updateElement.edited === true) {
            updateElement.edited = false;

            var optionScaleList = updateElement.optionScaleList === undefined ? null : updateElement.optionScaleList;
            var isDemographic = $scope.liveSurvey.elementList[$scope.currentSectionIndex].demographic;
            var tagIds = $scope.mapTagNamesToIds(updateElement.tags, isDemographic);

            if (optionScaleList) {
                optionScaleList.forEach(function(answer) {
                    delete answer.categoryId;
                });
            }

            var surveyItemUpdateDTO = {
                id: updateElement.id,
                elementTypeId: updateElement.elementTypeId,
                sort: updateElement.sort,
                text: updateElement.text,
                optionScaleList: optionScaleList,
                optionList: updateElement.optionList,
                tagList: tagIds,
                renderTypeId: updateElement.renderTypeId,
                required: updateElement.required,
                useEmoticon: updateElement.useEmoticon,
                scalePoints: updateElement.scalePointsCount,
                includeNAOption: updateElement.includeNAOption
            };

            var surveyUpdateDTO = {
                surveyId: $scope.liveSurvey.surveyId,
                userId:userService.getUserId(),
                languageId: $scope.languageId,
                action: 4, /*update element*/
                templateId: $scope.liveSurvey.newTemplateId,
                demographicsSection: isDemographic,
                surveyItemUpdateDTO: JSON.stringify(surveyItemUpdateDTO)
            };

            SurveyCreateService.update(surveyUpdateDTO).$promise.then(function (data) {
                // success handler


                $scope.updateLastContentEditTimestamp();

                if (data.message != null && data.message !== "") {
                    if (data.message === 'clearAllOptions') {
                        $scope.clearAllOptions(updateElement);
                    }
                    else {
                        $scope.updateOptionsUI(updateElement, data.message);
                    }
                }

                if (copying) {
                    $scope.copyItem(updateElement, section);
                }
            }, function (error) {
                // error handler


                sweetAlert("Item update was unsuccessful. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
            });
        }
    }

    $scope.updateOptionsUI = function(updateElement, optionIdCommaList) {
        if (updateElement.renderTypeId === 5) {
            updateElement.optionList = [];
        }
        else {
            updateElement.optionScaleList = [];
        }

        var optionIds = optionIdCommaList.split(',');

        var allIds = $scope.splitAdditionAndDeletionIds(optionIds);

        if (allIds.addition.length > 0) {
            //addition of options
            for (var i = 0; i < allIds.addition.length; i++) {
                if (updateElement.renderTypeId === 5) {
                    updateElement.optionScaleList[updateElement.optionScaleList.length - allIds.addition.length + i].id = allIds.addition[i];
                }
                else {
                    updateElement.optionList[updateElement.optionList.length - allIds.addition.length + i].id = allIds.addition[i];
                }
            }
        }

        if (allIds.deletion.length > 0) {
            //deletion
            for (var i = 0; i < allIds.deletion.length; i++) {
                if (updateElement.optionScaleList != undefined) {
                    //set id to be null since it is removed from database.
                    for (var j = 0; j < updateElement.optionScaleList.length; j++) {
                        if (updateElement.optionScaleList[j].id === allIds.deletion[i]) {
                            updateElement.optionScaleList[j].id = null;
                        }
                    }
                }
                if (updateElement.optionList != undefined) {
                    for (var j = 0; j < updateElement.optionList.length; j++) {
                        if (updateElement.optionList[j].id === allIds.deletion[i]) {
                            updateElement.optionList[j].id = null;
                        }
                    }
                }
            }
        }
    }

    $scope.clearAllOptions = function(element) {
        if (element.optionScaleList != null) {
            element.optionScaleList = [];
        }

        if (element.optionList != null) {
            element.optionList = [];
        }
    }

    $scope.deleteElement = function(element, section, type) {


        var surveyItemUpdateDTO = {id: element.id};
        var surveyUpdateDTO = {surveyId: $scope.liveSurvey.surveyId, userId:userService.getUserId(), action: 12/*delete element*/, templateId: $scope.liveSurvey.newTemplateId,
            surveyItemUpdateDTO: JSON.stringify(surveyItemUpdateDTO)};
        function deleteElement(){
            SurveyCreateService.update(surveyUpdateDTO).$promise.then(function (data) {
                // success handler


                $scope.updateLastContentEditTimestamp();

                //updateElement.edited = false;
                $scope.deleteElementInSection(section, element.id)


                $scope.updateItemLabels(section);
            }, function (error) {
                // error handler


                sweetAlert("Item delete was unsuccessful. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
            });
        }
        if(type){
            errorPopupChoice('Selecting yes will delete this ' + type + '.', function(){
                deleteElement();
            });
        } else {
            errorPopupChoice('Selecting yes will delete this element.', function() {
                deleteElement();
            });
        }

    }

    $scope.deleteElementInSection = function(section, elementId) {
        var elementIndex = $scope.findElementIndexInSection(section, elementId);
        if (elementIndex !== undefined) {
            section.childElementList.splice(elementIndex, 1);
        }
    }

    $scope.findElementIndexInSection = function(section, elementId) {
        for (var j = 0; j < section.childElementList.length; j++) {
            if (section.childElementList[j].id === elementId) {
                return j;
            }
        }

        return undefined;
    }

    $scope.focusSection = function(section) {
        $scope.prevSectionName = section.name;
        $scope.prevSectionText = section.text;
    }

    $scope.sectionBlur = function(section) {
        if (section.certifiedSection) {
            //Do nothing if it is certified section
            return;
        }

        if (section.name === $scope.prevSectionName && section.text === $scope.prevSectionText) {
            return;
        }

        var survey = {userId:userService.getUserId(), languageId: $scope.languageId, elementId: section.id, surveyId: $scope.liveSurvey.surveyId, sectionName: section.name, sectionText: section.text,
            templateId: $scope.liveSurvey.newTemplateId, action: 2 /* update section*/, sectionSort: section.sort};
        SurveyCreateService.update(survey).$promise.then(function (data) {
            //success handler

            $scope.updateLastContentEditTimestamp();
        }, function (error) {
            //error handler


            sweetAlert("Section update was unsuccessful. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
        });
    }

      $scope.responseGoalBlur = function() {

          var survey = {surveyId: $scope.liveSurvey.surveyId, templateId: $scope.liveSurvey.newTemplateId, targetCount: $scope.liveSurvey.responseGoal};

          if (survey.targetCount === '') {
              $('.responseGoalIntVal').addClass('ng-hide');
          } else if (typeof survey.targetCount === 'string') {
              $('.responseGoalIntVal').removeClass('ng-hide');
          }

          function validateRG(str){
              str = str.toString();
              str = str.split('');
              var val = true;
              str.forEach(function(char){

                  if(isNaN(parseInt(char))){
                      val = false;
                  }
              });
              return val;
          }

          if(validateRG(survey.targetCount)) {
              SurveyCreateService.saveResponseGoal(survey).$promise.then(function (data) {

                  // success handler
                  $scope.updateLastContentEditTimestamp();
                  // $scope.loading = false;
              }, function (error) {
                  // error handler

                  $scope.loading = false;



                  $timeout(validationWarning(error.data.message, 'Please review, correct, and try again.'), 500);
              });

              $('.responseGoalIntVal').addClass('ng-hide');
          }
      };


      $scope.$watch('templateId', function(newValue, oldValue) {
          if (newValue && newValue !== oldValue) {

              $scope.getLanguages();

              $scope.getRedirectLink();
              //$scope.checkSurveyResponses();
              $scope.getSharingControlInfo();
              //$scope.getAllInstitutionsBySurveyId();
              //$scope.getLicensedInstitutionIds();

              //$scope.getCustomerHierarchyForSharing();
              //$scope.getAllInstitutionsForSharingByInstitutionId();
              //$scope.getLicencedInstitutions();
              //$scope.getInstitutionDetails($scope.institutionIds);
          }
      });

      $scope.previewSurvey = function() {
          var actionIndex = $scope.liveSurvey.surveyURL.indexOf('/action/');
          var previewURL = '/preview/' + $scope.liveSurvey.surveyURL.substring(actionIndex + 8);

          if ($scope.viewMode) {
              previewURL = '/pre-view/' + $scope.liveSurvey.surveyURL.substring(actionIndex + 8);
          }

          $location.path(previewURL);
      }


      $scope.createUpdateSurveyItemDTO = function(srcSectionId, srcItemId, destSectionId){
          function getSection(id) {
              for(var i = 0; i < $scope.liveSurvey.elementList.length; i++) {
                  if($scope.liveSurvey.elementList[i].id === id) {
                      return $scope.calculateIdLabelList($scope.liveSurvey.elementList[i]);
                  }
              }
          }
          var surveyMoveItemDTO = {
              srcSection: srcSectionId,
              srcSectionItem: parseInt(srcItemId),
              destSection: destSectionId,
              destSectionItemOrderedList: getSection(destSectionId),
              srcSectionItemOrderedList: getSection(srcSectionId)
          };
          var surveyUpdateDTO = {
              userId:userService.getUserId(),
              action: 31/*move item*/,
              templateId: $scope.liveSurvey.newTemplateId,
              surveyMoveItemDTO: surveyMoveItemDTO
          };
          return surveyUpdateDTO;
      }

///////////////////////////////////
// Survey edit sidebar widget begin
// Jan-Feb 2016, Peter Ward

      $scope.itemAnchor = undefined;
      $scope.sectionAnchor = undefined;

      $scope.itemSortCacheTemplate = function(){
        var returnObj = Object.create({
              senderSection: {},
              sender: {},
              srcItemId: null,
              stopItem: {},
              dest: {},
              destSectionId: null,
              sectionDrag: false,
              sectionTarget: {},
              isCurrent: false,
              parentA: {},
              d: {},
              destSectionA: {},
              dId: null,
              helper: {
                  clone: {},
                  top: null
              },
              counter: 0,
              scrollCounter: 0,
              noChange: null,
              out: false,
              updateTick: 0
          });

        return returnObj;
      };

      $scope.itemSortCache = new $scope.itemSortCacheTemplate();

      $scope.sectionSortHandler = {
          cancel: ".certified-content",
          forceHelperSize: true,
          'ui-floating': true,
          scroll: true,
          refresh: true,
          refreshPositions: true,
          axis: 'y',
          connectWith: '.edit_section-li:not(.certified-content)',
          items: '.edit_section-li:not(.certified-content)',
          start: function(e, ui) {
            // tag as section drag
            $scope.itemSortCache.sectionDrag = true;
            var clicked = $(ui.item).find('#edit_section-a');

            if($(clicked).hasClass('current')){
                // If it's current, make a note in cache
                $scope.itemSortCache.isCurrent = true;
            }
            // Initiate Animation Frame
            window.requestAnimFrame(handleCurrent);
            window.requestAnimFrame(getHelperStat);
            $scope.dragging = true;
            $scope.itemSortCache.sectionTarget = e.target;
            $($('.edit_section-li').find('a')).addClass('closed');
          },
          stop: function(e, ui) {
              $scope.dragging = false;

              if (ui.item.sortable.dropindex !== $scope.certifiedContentStartIndex) {
                  $scope.updateSectionLabels(true);
              }
              $($('.edit_section-li').find('a')).removeClass('closed');

              $scope.itemSortCache = new $scope.itemSortCacheTemplate();

              // Cancel Animation Frame
              window.cancelRequestAnimFrame(handleCurrent);
              window.cancelRequestAnimFrame(getHelperStat);
          },
          update: function(e, ui) {
              if (ui.item.sortable.dropindex >= $scope.certifiedContentStartIndex && ui.item.sortable.dropindex <= $scope.certifiedContentEndIndex) {
                  ui.item.sortable.cancel();
              }
          },
          cursor: 'pointer'
      };

      $scope.itemSortHandler = {
        cancel: ".certified-content",
        connectWith: '.edit_section_item:not(.certified-content)',
        revert: true,
        'ui-floating': 'auto',
        axis: 'y',
        stop : function(e, ui) {
          // Cancel Animation Frame
          $window.cancelRequestAnimFrame(handleCurrent);
          $window.cancelRequestAnimFrame(getHelperStat);
          if(ui.item.data('start_pos') === ui.item.index()) {
              $scope.itemSortCache.noChange = true;
          }
          $scope.itemSortCache.stopItem = $scope.findElementParent(ui.item.sortable.model.id);
          $timeout(function(){
              $scope.updateItemLabels($scope.itemSortCache.stopItem);
              // Reset Sorting Cache
              if($scope.itemSortCache.isCurrent === true){
                  $scope.currentAnchorBool = true;
              }
              var that = $scope.itemSortCache.dest;
              var flasher = $($(that).closest('li')).find('#edit_section-a');
              $(flasher).css('color', '#6fc96f');
              setTimeout(function () {
                $(flasher).css('color', '#333');
                setTimeout(function () {
                  $(flasher).css('color', '#6fc96f');
                  setTimeout(function () {
                    $(flasher).css('color', '#333');
                    setTimeout(function () {
                      $(flasher).css('color', '#6fc96f');
                      setTimeout(function () {
                          //$(that).addClass('ng-hide');
                          $(flasher).css('color', '#333');
                          $scope.dragging = false;
                      }, 50)
                    }, 50)
                  }, 50)
                }, 50)
              }, 50);

              $scope.itemSortCache = new $scope.itemSortCacheTemplate();
              $scope.addUIDroppable(false);
          })
        },
        update: function(e, ui) {
            
            var startSectionDemographic = $(ui.item.sortable.source[0]).hasClass('demographic');
            var destinationDemographic = $(ui.item.sortable.droptarget[0]).hasClass('demographic');
            //console.log('startDemo', startSectionDemographic); 
            //console.log('stopDemo', destinationDemographic); 
            //console.log('start=stop', startSectionDemographic === destinationDemographic);
          
            if ( startSectionDemographic !== destinationDemographic) {
              ui.item.sortable.cancel();
              return;
            }

            if (ui.item.sortable.model === "can't be moved") {
                ui.item.sortable.cancel();
            } else if($scope.itemSortCache.updateTick === 1) {
                var destSection = e.target.parentElement.lastElementChild;

                $scope.itemSortCache.d = $(destSection).context;
                $scope.itemSortCache.dId = ui.item.id;

                if ($scope.itemSortCache.counter === 1) {
                    $scope.itemSortCache.destSectionId = destSection.id;
                    $scope.dragging = false;
                } else if ($scope.itemSortCache.counter === 0) {
                    $scope.itemSortCache.counter++;
                }


                var destId = $(destSection).prop('id');
                var destSectionId = parseInt(destId.split('_')[0]);
                var srcId = $($scope.itemSortCache.sender).prop('id');
                var srcSectionId = parseInt(srcId.split('_')[0]);
                $scope.itemSortCache.dest = $(destSection).context;

                var surveyMoveItemDTO = {
                    srcSection: srcSectionId,
                    srcSectionItem: $scope.itemSortCache.srcItemId,
                    destSection: destSectionId,
                    destSectionItemOrderedList: null,
                    srcSectionItemOrderedList: null
                };

                for (var i = 0; i < $scope.liveSurvey.elementList.length; i++) {
                    if ($scope.liveSurvey.elementList[i].id == destSectionId) {
                        surveyMoveItemDTO.destSectionItemOrderedList = $scope.calculateIdLabelList($scope.liveSurvey.elementList[i]);

                    } else if ($scope.liveSurvey.elementList[i].id == srcSectionId) {
                        surveyMoveItemDTO.srcSectionItemOrderedList = $scope.calculateIdLabelList($scope.liveSurvey.elementList[i]);
                    }
                }

                var surveyUpdateDTO = {
                    userId: userService.getUserId(),
                    action: 31/*move item*/,
                    templateId: $scope.liveSurvey.newTemplateId,
                    surveyMoveItemDTO: surveyMoveItemDTO
                };


                SurveyCreateService.update(surveyUpdateDTO).$promise.then(function (data) {
                    // success handler


                }, function (error) {
                    // error handler


                });
            }

            $scope.itemSortCache.updateTick++;
        },
        start: function(e, ui) {
          ui.item.data('start_pos', ui.item.index());
          // Detect if item hasClass current
          $scope.itemSortCache.senderSection = $(ui.item[0]).closest('.edit_section_item');
          if($(ui.item[0]).hasClass('current')){
              // If it's current, make a note in cache
              $scope.itemSortCache.isCurrent = true;

          }
          $scope.itemSortCache.helper.clone = ui.helper;
          $scope.dragging = true;
          $scope.itemSortCache.sender = e.target;
          $scope.itemSortCache.srcItemId = ui.item.context.id;
          // Initiate Animation Frame
          $window.requestAnimFrame(handleCurrent);
          $window.requestAnimFrame(getHelperStat);
        },
        out: function(e, ui) {
            $scope.itemSortCache.refreshed = false;
        },
        helper: 'clone',
        appendTo: 'body',
        refresh: true,
        refreshPositions: true,
        cursor: 'pointer'
      };

        function addUIDroppable_template() {
          var editSectionLi = $('.edit_section-li');
          var editSectionItem = $('.edit_section_item');
          var list = [];

          $(editSectionLi).each(function (li) {
              if ($(editSectionLi[li]).hasClass('certified-content') === false) {
                  list.push(editSectionLi[li])
              }
          });

          $(list).droppable({
              hoverClass: 'droppable',
              over: function (e, ui) {
                  setTimeout(function () {
                      $(editSectionItem).sortable('refreshPositions');
                  }, 100);
              },
              drop: function (e, ui) {
                  $('.edit_section_item').sortable('refresh');
                  $('.edit_section_item').sortable('refreshPositions');
              }
          });
        }
        $scope.addUIDroppable = function(fromLoad) {
          if (fromLoad) {
            setTimeout(function () {
                addUIDroppable_template();
            }, 1000);
          } else {
            addUIDroppable_template();
          }
        };

      $scope.addUIDroppable(true);

      // AnimationFrame Polyfills
      $window.requestAnimFrame = (function(){
          return  window.requestAnimationFrame   ||
              window.webkitRequestAnimationFrame ||
              window.mozRequestAnimationFrame
      })();

      $window.cancelRequestAnimFrame = ( function() {
          return window.cancelAnimationFrame           ||
              window.webkitCancelRequestAnimationFrame ||
              window.mozCancelRequestAnimationFrame    ||
              window.oCancelRequestAnimationFrame      ||
              window.msCancelRequestAnimationFrame     ||
              clearTimeout
      } )();

      $scope.start = null;
      var currentId = null;
      var scrollCounter = 0;
      var contentLength = 0;
      var lengthStart = true;
      $scope.scrolling = false;

      $('.page-container').on('scroll', function(){
          $scope.scrolling = true;
      });

      // jQuery.fn.extend( object )Returns: Object
      // Description: Merge the contents of an object onto the jQuery prototype to provide new jQuery instance methods.
      // "scrollStopped" credits: http://stackoverflow.com/users/775516/jason-sebring
      $.fn.scrollStopped = function(callback) {
          var that = this, $this = $(that);
          $this.scroll(function(ev) {
              clearTimeout($this.data('scrollTimeout'));
              $this.data('scrollTimeout', setTimeout(callback.bind(that), 250, ev));
          });
          $scope.start = null;
      };

      $('.page-container').scrollStopped(function(ev){
          $scope.scrolling = false;
      });
      //

      function handleCurrent(){
        var current = $('.current');
         //Deal with .page-container scrolling for each $('.survey-sidebar__content')
         //i.e. auto scroll sidebar as current leaves sidebar frame
        if ($(current)[0]){
          var current = $('.current');
          if ($(current)[0].id){
            if(currentId !== $(current)[0].id){
              currentId = $(current)[0].id;
              var sidebar = $('.survey-sidebar__content');
              var currentTop = $('#'+currentId).offset().top;
              $scope.scrollCache.current = currentTop;
              if(lengthStart === true){
                contentLength = currentTop;
                lengthStart = false;
              }
              var currentHeight = $('#'+currentId).height();
              $scope.scrollCache.currentHeight = currentHeight;
              var lowerTop = $('.survey-sidebar__actions').offset().top;
              $scope.scrollCache.lowerTop = lowerTop;
              if(scrollCounter === 0){
                var upperTop = 0;
              } else {
                var upperTop = (contentLength * scrollCounter) - contentLength;
              }
              if(scrollCounter === 0){ scrollCounter = 1 }
              if(currentTop >= lowerTop - currentHeight - 25){
                scrollCounter += 1;
                $(sidebar).scrollTop(contentLength * scrollCounter);
              } else if(currentTop <= upperTop + 25){
                scrollCounter -= 1;
                $(sidebar).scrollTop(contentLength * scrollCounter);
              }
            }
          }
        }

          // if dragging an item
          if(($scope.dragging === true) && ($scope.itemSortCache.sectionDrag === false)) {
            // if user takes item out of section then back to original position\
            if(($scope.itemSortCache.out === true) && ($scope.itemSortCache.noChange === true)) {
                $scope.dragging = false;
            }
            $scope.itemSortCache.scrollCounter++;
            // iterate through sidebar elements
            var editSectionLi = $('.edit_section-li');
            // Show each section as jQuery droppable adds class 'droppable'
            // i.e. Show each section on hover while dragging
            $(editSectionLi).each(function(li) {
              var hasSender = true;
              var childList = $(this).find('ul');
              // Define whether section has sender
              if($(this).find($scope.itemSortCache.sender).length > 0) {
                  hasSender = true;
              } else {
                  hasSender = false
              }
              // For item drag, show any section that's *hidden and *hovered
              if (($(this).hasClass('droppable')) && ($($(this).find('.edit_section_item')).hasClass('ng-hide')) && ($scope.itemSortCache.sectionDrag === false) && ($scope.itemSortCache.refreshed === false)) {
                  $('.edit_section_item').sortable('refresh');
                  $('.edit_section_item').sortable('refreshPositions');
                  $( $(this).find('.edit_section_item') ).removeClass('ng-hide');
                  $scope.itemSortCache.refreshed = true;
              }

              if (( $(this).hasClass('droppable') === false ) &&
                  ( $(this).find('.current').length < 1 ) &&
                  ( hasSender === false ) &&
                  ( $( $(this).find('.edit_section_item') ) !== $scope.itemSortCache.dest )) {

                if ($scope.itemSortCache.sectionDrag === false) {
                  $(childList).addClass('ng-hide');
                }
              } else if ( $( $(this).find('.edit_section_item') ) !== $scope.itemSortCache.dest ||
                          $( $(this).find('.edit_section_item') ) !== $scope.itemSortCache.sender) {
                $(childList).removeClass('ng-hide');
              }
              // Detect if clone wants to scroll up/down and scrollTop 1 in that direction
              var container = $('.survey-sidebar__content');
              var containerTop = $(container).scrollTop();
              var topScrollPoint = $(container).offset().top + 50;
              var bottomScrollPoint = $('.survey-sidebar__actions').offset().top - 20;

              if ($scope.itemSortCache.helper.top > bottomScrollPoint) {
                  animateScroll('up', container, containerTop)
              } else if ($scope.itemSortCache.helper.top < topScrollPoint) {
                  animateScroll('down', container, containerTop)
              }
            });
          }
          $window.requestAnimFrame(handleCurrent);
      }

      $window.requestAnimFrame(handleCurrent);

      function getHelperStat() {
          // get helper, stat || Get Helper's Statistics
          if ($scope.dragging === true) {
              if ((Object.keys($scope.itemSortCache.helper).length > 0)) {
                  if ((Object.keys($scope.itemSortCache.helper.clone).length > 0)) {
                    $scope.itemSortCache.helper.top = $($scope.itemSortCache.helper.clone).offset().top;

                    $window.requestAnimFrame(getHelperStat);
                  }
              } else {
                  $window.requestAnimFrame(getHelperStat);
              }
          }
      }

      function animateScroll(dir, container, containerTop) {
          if ($scope.itemSortCache.scrollCounter % 5 === 0) {
              if (dir === 'up') {
                  $(container).scrollTop(containerTop + 1);
              } else if (dir === 'down') {
                  $(container).scrollTop(containerTop - 1);
              }
          }
      }

      $scope.resizeInstructionTextareas = function() {

          $('textarea').each(function (ta) {
              if ($(this)[ta]) {
                  $($(this)[ta]).css('height', $(this)[ta].scrollHeight + 'px');
              }
          });
      }

// Survey edit sidebar widget end
/////////////////////////////////
/////////////////////////////////


/////////////////////////
// Labeling and numbering
    $scope.updateSectionLabels = function(updateLabel) {
        // number the sections
        var idLabelList = [];

        if ($scope.liveSurvey.elementList !== undefined && $scope.liveSurvey.elementList !== null) {
            var j = 0;
            for (var i = 0; i < $scope.liveSurvey.elementList.length; i++) {
                if ($scope.liveSurvey.elementList[i].elementTypeId === 4 /*page break*/ || $scope.liveSurvey.elementList[i].elementTypeId === 3 /*message*/) {
                    idLabelList.push({id: $scope.liveSurvey.elementList[i].id, name: ''});
                    j++;
                }
                else {
                    $scope.liveSurvey.elementList[i].number = String.fromCharCode(i - j + 65);
                    idLabelList.push({id: $scope.liveSurvey.elementList[i].id, name: $scope.liveSurvey.elementList[i].number});
                    /*if ($scope.liveSurvey.elementList[i].elementTypeId === 1 /!*section*!/ && $scope.liveSurvey.elementList[i].childElementList !== undefined) {
                        for (var k = 0; k < $scope.liveSurvey.elementList[i].childElementList.length; k++) {
                            if ($scope.liveSurvey.elementList[i].childElementList[k].elementTypeId === 2 /!*item*!/) {
                                $scope.survey.questions += 1;
                            }
                        }
                    }*/
                }
            }

            if (updateLabel) {
                var idsLabelsDTO = {idLabelList: idLabelList}
                var surveyUpdateDTO = {surveyId: $scope.liveSurvey.surveyId, userId:userService.getUserId(), action: 21/*update section label*/, templateId: $scope.liveSurvey.newTemplateId,
                    idsLabelsDTO: JSON.stringify(idsLabelsDTO)};

                SurveyCreateService.update(surveyUpdateDTO).$promise.then(function (data) {
                    // success handler


                    $scope.updateLastContentEditTimestamp();

                }, function (error) {
                    // error handler


                    sweetAlert("Section label(s) update was unsuccessful. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");

                });
            }
        }
    }

      $scope.calculateIdLabelList = function(section) {
          var idLabelList = [];

          if (section.childElementList !== undefined && section.childElementList !== null) {
              var messageCount = 0;
              for (var i = 0; i < section.childElementList.length; i++) {
                  if (section.childElementList[i].elementTypeId === $scope.EL_TYPE_ID_MESSAGE || section.childElementList[i].elementTypeId === $scope.EL_TYPE_ID_PAGEBREAK) {
                      messageCount++;
                      idLabelList.push({id: section.childElementList[i].id, name: ''});
                  }
                  else {
                      idLabelList.push({id: section.childElementList[i].id, name: (i + 1 - messageCount) + ""});
                  }
              }
          }

          return idLabelList;
      };

      $scope.updateItemLabels = function(section) {
          var idLabelList = $scope.calculateIdLabelList(section);


          if (idLabelList.length > 0) {
              var idsLabelsDTO = {idLabelList: idLabelList};
              var surveyUpdateDTO = {surveyId: $scope.liveSurvey.surveyId, userId: userService.getUserId(), action: 22/*update item label*/, templateId: $scope.liveSurvey.newTemplateId,
                  idsLabelsDTO: JSON.stringify(idsLabelsDTO)};

              $timeout(function() {
                  SurveyCreateService.update(surveyUpdateDTO).$promise.then(function (data) {
                      // success handler


                      $scope.updateLastContentEditTimestamp();

                  }, function (error) {
                      // error handler


                      sweetAlert("The system encountered a problem, please try again.", error.data.message, "error");
                  });
              }, 500);
          }

          return idLabelList;
      };

      $scope.calcNextSectionLabel = function(previousSectionIndex) {
          // number the sections
          if ($scope.liveSurvey.elementList !== undefined && $scope.liveSurvey.elementList !== null) {
              var j = 0;
              for (var i = 0; i <= previousSectionIndex; i++) {
                  if ($scope.liveSurvey.elementList[i].elementTypeId !== 4 /*page break*/ && $scope.liveSurvey.elementList[i].elementTypeId !== 3 /*message*/) {
                      j++;
                  }
              }

              return String.fromCharCode(j + 65); //65 -- A = 65.
          }

          return "A";
      }

      $scope.calcNextItemLabel = function(section) {
          if (section.childElementList !== undefined && section.childElementList !== null) {
              var messageCount = 0;
              for (var i = 0; i < section.childElementList.length; i++) {
                  if (section.childElementList[i].elementTypeId === 3) {
                      messageCount++;
                  }
              }

              return (section.childElementList.length + 1 - messageCount) + "";
          }

          return "1";
      }

    $scope.itemLabel = function(element, section) {
        if (section.childElementList !== undefined && section.childElementList !== null) {
            var messageCount = 0;
            for (var i = 0; i < section.childElementList.length; i++) {
                if (section.childElementList[i].elementTypeId === $scope.EL_TYPE_ID_MESSAGE || section.childElementList[i].elementTypeId === $scope.EL_TYPE_ID_PAGEBREAK
                        || section.childElementList[i].elementTypeId === $scope.EL_TYPE_ID_MATRIX) {
                    messageCount++;
                }
                else if (section.childElementList[i] === element) {
                    return (i + 1 - messageCount) + "";
                }
            }
        }

        return "";
    };

    $scope.topScroll = function() {
        var page = $('.page-container');
        $(page).animate({scrollTop: $(page).scrollTop() - $(page).scrollTop()}, 1000);
    };


    $scope.goToTokenManagement = function () {
        if ($scope.viewMode) { return; }

        var manageTokenUrl = "/manage-tokens/" + $scope.liveSurvey.surveyId;

        $location.path(manageTokenUrl);
    };






// Labeling and numbering
/////////////////////////


}])

    .filter('highlightToken', ['$sce', function($sce) {
        return function(text, phrase, tagName){

            var startTag = '<'+tagName+'>';
            var closeTag = '</'+tagName+'>';

            var startMarker = '<###>';
            var closeMarker = '</###>';

            if(phrase){
                var tokens = phrase.split (' ');
                //replace token with marker first, to avoid tags getting replaced on 2nd+ pass
                //check if token is a marker?
                tokens.forEach(function(token){
                    if (token) text = text.replace(new RegExp('('+token+')', 'gi'),
                        startMarker+'$1'+closeMarker)
                });

                //replace markers with tag
                text = text.replace(new RegExp('('+startMarker+')', 'gi'),
                    startTag);
                text = text.replace(new RegExp('('+closeMarker+')', 'gi'),
                    closeTag);

            }

            return $sce.trustAsHtml(text)
        }
    }])

    .filter('appendState', [function(){
        return function(highlightedCityHTML, state){
            return highlightedCityHTML + ', ' + state
        }
    }]);
