app.directive('metrics', function() {

    return {
        restrict: 'E',
        scope: {
            data: '=',
            options: '=',
            id: '@'
        },
        link: function(scope, element) {
           var render = function() {
              var options = {};
              if (scope.options) {
                  Object.keys(scope.options).forEach(function (key) {
                      options[key] = scope.options[key];
                  });
              }
              options.data = scope.data || [];
              options.target = '#' + scope.id;
              options.color = '#000000';
              MG.data_graphic(options);
          }
          render();
        }
    };
});
