(function() {
        'use strict';
        angular.module('echo')
            .controller('ExportReportsCtrl', ExportReportsCtrl);

        ExportReportsCtrl.$inject = ['$scope', '$modalInstance', '$timeout', 'ReportService', 'FileDownloadService', 'ReportPdfService', 'userService'];

        function ExportReportsCtrl($scope, $modalInstance, $timeout, ReportService, FileDownloadService, ReportPdfService, userService){
            $scope.exportButtonText = "Export";
            $scope.exportInProgress = false;
            $scope.reportPreferences.exportSelection = null;

            $scope.export = function() {
                $scope.exportButtonText = "Downloading...";
                if ($scope.reportPreferences.exportSelection == "CSV") {
                    return $scope.createRawDataCSVReport();
                }
                else if ($scope.reportPreferences.exportSelection == "Summary"){
                    FileDownloadService.reportSummary(reportSummaryCallback);
                }
                else if ($scope.reportPreferences.exportSelection == "Unstacked"){
                    return $scope.createRawUnstackedDataCSVReport();
                }
                else if ($scope.reportPreferences.exportSelection == "PDF"){
                    return $scope.createPdfReport();
                }
            }

            $scope.createRawDataCSVReport = function(){
                $scope.createReportStatus = true;
                $scope.cameFromViewSummary = 'csv';
                $scope.csvReport = true;

                ReportService.updateCSVCounter({requestId: $scope.requestId});
                var reportPreferencesInputDTO = new Object();
                reportPreferencesInputDTO.userId = $scope.userId;
                reportPreferencesInputDTO.emailUser = $scope.emailUser;
                reportPreferencesInputDTO.institutionId = $scope.institutionId;
                reportPreferencesInputDTO.institutionName = $scope.institutionName;

                if(reportPreferencesInputDTO.reportId){
                    reportPreferencesInputDTO.reportId = $scope.reportPreferences.reportId;
                } else{
                    reportPreferencesInputDTO.reportId = 0;
                }

                reportPreferencesInputDTO.languageId = $scope.languageId;
                reportPreferencesInputDTO.reportPreferences = angular.copy($scope.reportPreferences);
                reportPreferencesInputDTO.reportPreferences.institutionsByHierarchy = $scope.institutionsHierarchy;

                reportPreferencesInputDTO.reportPreferences.fromDate = $scope.convertDateYYYYmmDD(reportPreferencesInputDTO.reportPreferences.fromDate);
                reportPreferencesInputDTO.reportPreferences.toDate = $scope.convertDateYYYYmmDD(reportPreferencesInputDTO.reportPreferences.toDate);

                angular.extend(reportPreferencesInputDTO.reportPreferences, getReportCustomerInformation());

                if(reportPreferencesInputDTO.reportPreferences.reportId){
                    reportPreferencesInputDTO.reportId = reportPreferencesInputDTO.reportPreferences.reportId;
                }

                return ReportService.createRawDataReport(reportPreferencesInputDTO).$promise.then(function(report) {
                    $scope.close();
                    console.log(report);
                    //Do not open file since user will receive email instead.
                    //window.open(reports_shared_drive_uri + report.rawDataReportFileName, "_self");
                },function(httpResponse){
                    $scope.close();
                    $timeout(errorPopup, 500);
                });
            }

            $scope.closeReport = function() {
                $scope.createReportStatus = false;
            }

            $scope.createRawUnstackedDataCSVReport = function(){
                $scope.createReportStatus = true;
                $scope.cameFromViewSummary = 'csv';
                $scope.csvReport = true;

                ReportService.updateCSVCounter({requestId: $scope.requestId});
                var reportPreferencesInputDTO = new Object();
                reportPreferencesInputDTO.userId = $scope.userId;
                reportPreferencesInputDTO.emailUser = $scope.emailUser;
                reportPreferencesInputDTO.institutionId = $scope.institutionId;
                reportPreferencesInputDTO.institutionName = $scope.institutionName;

                if(reportPreferencesInputDTO.reportId){
                    reportPreferencesInputDTO.reportId = $scope.reportPreferences.reportId;
                } else{
                    reportPreferencesInputDTO.reportId = 0;
                }

                reportPreferencesInputDTO.languageId = $scope.languageId;
                reportPreferencesInputDTO.reportPreferences = angular.copy($scope.reportPreferences);
                reportPreferencesInputDTO.reportPreferences.institutionsByHierarchy = $scope.institutionsHierarchy;

                reportPreferencesInputDTO.reportPreferences.fromDate = $scope.convertDateYYYYmmDD(reportPreferencesInputDTO.reportPreferences.fromDate);
                reportPreferencesInputDTO.reportPreferences.toDate = $scope.convertDateYYYYmmDD(reportPreferencesInputDTO.reportPreferences.toDate);

                angular.extend(reportPreferencesInputDTO.reportPreferences, getReportCustomerInformation());

                if(reportPreferencesInputDTO.reportPreferences.reportId){
                    reportPreferencesInputDTO.reportId = reportPreferencesInputDTO.reportPreferences.reportId;
                }

                return ReportService.createRawDataUnstackedReport(reportPreferencesInputDTO).$promise.then(function(report) {
                    $scope.close();
                    console.log(report);
                    //Do not open file since user will receive email instead.
                    //window.open(reports_shared_drive_uri + report.rawDataReportFileName,"_self");
                },function(httpResponse){
                    $scope.close();
                    $timeout(errorPopup, 500);
                });
            }

            $scope.createPdfReport = function(){
                var finalPdfReportObject = ReportPdfService.getReportPdfData();

                return ReportService.createPdfReport(finalPdfReportObject).$promise.then(function(report) {
                    $scope.close();

                    window.open(reports_shared_drive_uri + report.filename,"_self");
                },function(httpResponse){
                    $scope.close();
                    $timeout(errorPopup, 500);
                });
            }

            function reportSummaryCallback(data){
                var file = new Blob([ data ], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
                $scope.close();
            }

            function getReportCustomerInformation() {
                return {
                    surveyCreatorName: userService.getUserFullName()
                };
            }

            $scope.cancel = function () {
                $modalInstance.dismiss('cancel');
            };

            $scope.close = function(){
                $modalInstance.close();
            }
        }
    }
)();
