/**
 * Created by nvyas on 5/5/16.
 */

app.factory( 'ReportPdfService', 
    ['$resource',
     'applicationService', 
     'userService', 
     'ReportService', 
     'reportPdfDataService', 
     '$sessionStorage',
    function($resource,
             applicationService, 
             userService, 
             ReportService, 
             reportPdfDataService, 
             $sessionStorage){
    return new ReportPdfService($resource, 
                                applicationService, 
                                userService, 
                                ReportService, 
                                reportPdfDataService, 
                                $sessionStorage);
    }]);

function ReportPdfService(resourceService, applicationService, userService, ReportService, reportPdfDataService, $sessionStorage) {

    this.generateSectionActivity = function(pdfObject) {
        reportPdfDataService.parseSectionActivity(pdfObject);
    }

    //this function prepares data in the format needed by the PDF Report generator, in anticipation that the user will export to that format
    this.generatePDFReportData = function(scope, pdfObject, reportName, items, options){
        //reportName contains the name of the report to be displayed at the top of the PDF


        // Prefetch Highest/Lowest Scoring items
        // scope.retrieveHighestAverages();
        // scope.retrieveLowestAverages();
        //scope.retrieveSharedInstitutions();
        //this.getOpenEndedAnswers(scope);











        /* Create a object to send to PDFreactor service. There are three main conditions in this loop below.
         If no grouping is added, if one group is added and 2 groups are added.
         */
        var reportObject = [];

        /*
        Report object has the following components:
        A collection of sectionObject.

        Each sectionObject has:



         */

        for (var sectionNo= 0, len=items.length; sectionNo<len; sectionNo++){
            var sectionObj = {};
            var item = items[sectionNo];
            sectionObj.name = item.name;
            sectionObj.grouping = scope.reportPreferences.groupingApplied;
            sectionObj.firstGrouping = scope.reportPreferences.firstGrouping;
            sectionObj.secondGrouping = scope.reportPreferences.secondGrouping;
            sectionObj.questions = [];
            for(var questionNo= 0; questionNo<item.childItems.length; questionNo++){
                var questionItem = item.childItems[questionNo];
                var questionObj = {};
                questionObj['text'] = questionItem.itemText;
                questionObj['label'] = questionItem.label;
                questionObj['useEmoticon'] = questionItem.useEmoticon;

                questionObj['allOptions'] = questionItem.allOptions;
                //if(questionItem.totalItemCount > 0){
                //    questionObj['totalItemCount'] = questionItem.totalItemCount;
                //}
                //else{
                //    var tCount = 0;
                //    for(var n=0; n < questionItem.childItems.length; n++){
                //        var c = questionItem.childItems[n].totalItemCount;
                //        tCount = tCount + c;
                //    }
                //    questionObj['totalItemCount'] = tCount;
                //}
                if(scope.reportPreferences.groupingApplied){          //check if grouping is applied - perform this
                    questionObj.firstGrouping = [];
                    if(scope.reportPreferences.secondGrouping){ //2 groups are applied
                        var grandParentTotal = {};
                        var grandParentTotalCount = 0;
                        for(var firstChild = 0; firstChild< questionItem.childItems.length; firstChild++){
                            var dataBy = questionItem.childItems[firstChild];
                            var firstGroupingData = {};
                            firstGroupingData.text = dataBy.itemText;
                            firstGroupingData.secondGrouping = [];
                            var firstGroupTotal = {};
                            var totalCount = 0;
                            for(var secondChild =0; secondChild < dataBy.childItems.length; secondChild++){
                                var dataBySecond = dataBy.childItems[secondChild];
                                var secondGroupingData = {};
                                secondGroupingData.text = dataBySecond.itemText;
                                secondGroupingData.total = {};
                                for(var responseNo=0; responseNo<dataBySecond.responseData.length; responseNo++){
                                    var respData = dataBySecond.responseData[responseNo];
                                    secondGroupingData.total[respData.itemText] = respData.responsePercentage;
                                    if (typeof firstGroupTotal[respData.itemText] == "undefined") {
                                        firstGroupTotal[respData.itemText] = parseInt(respData.responseCount);
                                        totalCount = totalCount + parseInt(respData.responseCount);
                                    }else{
                                        firstGroupTotal[respData.itemText] = firstGroupTotal[respData.itemText] +  parseInt(respData.responseCount);
                                        totalCount = totalCount + parseInt(respData.responseCount);
                                    }
                                    if (typeof grandParentTotal[respData.itemText] == "undefined") {
                                        grandParentTotal[respData.itemText] = parseInt(respData.responseCount);
                                        grandParentTotalCount = grandParentTotalCount + parseInt(respData.responseCount);
                                    }else{
                                        grandParentTotal[respData.itemText] = grandParentTotal[respData.itemText] +  parseInt(respData.responseCount);
                                        grandParentTotalCount = grandParentTotalCount + parseInt(respData.responseCount);
                                    }
                                }
                                questionObj.count = parseInt(respData.responseCount);
                                firstGroupingData.secondGrouping.push(secondGroupingData);
                            }
                            firstGroupingData.total = {};
                            for(var key in firstGroupTotal){
                                firstGroupingData.total[key] = (firstGroupTotal[key] / totalCount) * 100;
                            }
                            questionObj.firstGrouping.push(firstGroupingData);
                        }
                        questionObj.total = {};
                        for(var key in grandParentTotal){
                            questionObj.total[key] = (grandParentTotal[key] / grandParentTotalCount) * 100;
                        }

                    }else {  // one group is applied
                        var parentTotal = {};
                        var totalCount = 0;
                        questionObj.total = {};
                        for(var firstChild = 0; firstChild< questionItem.childItems.length; firstChild++){
                            var dataBy = questionItem.childItems[firstChild];
                            var groupData = {};
                            groupData.text = dataBy.itemText;
                            groupData.total = {};
                            for(var responseNo=0; responseNo<dataBy.responseData.length; responseNo++){
                                var respData = dataBy.responseData[responseNo];
                                groupData.total[respData.itemText] = respData.responsePercentage;
                                if (typeof parentTotal[respData.itemText] == "undefined") {
                                    parentTotal[respData.itemText] = parseInt(respData.responseCount);
                                    totalCount = totalCount + parseInt(respData.responseCount);
                                }else{
                                    parentTotal[respData.itemText] = parentTotal[respData.itemText] +  parseInt(respData.responseCount);
                                    totalCount = totalCount + parseInt(respData.responseCount);
                                }
                            }
                            questionObj.count = parseInt(respData.responseCount);
                            questionObj.firstGrouping.push(groupData);
                        }
                        for(var key in parentTotal){
                            questionObj.total[key] = (parentTotal[key] / totalCount) * 100;
                        }

                    }
                } else {     //No grouping is applied
                    questionObj.total = {};
                    for(var responseNo=0; responseNo<questionItem.responseData.length; responseNo++){
                        var respData = questionItem.responseData[responseNo];
                        questionObj.total[respData.itemText] = respData.responsePercentage;
                        questionObj.count = parseInt(respData.responseCount);

                    }
                }
                sectionObj.questions.push(questionObj);
            }
            reportObject.push(sectionObj);
        }
        pdfObject.reportObject = reportObject;

        // Get run on date
        var reportRunDate = new Date(); // Date when the export pdf button is clicked (today's date)

        // Get Summary page sectionActivity
        //AF Refactor DELETE
        /*var summarySectionActivity = [];
        if (pdfObject.sectionActivity.length > 0) {
            for (var i = 0; i < pdfObject.sectionActivity.length; i++) {
                var activity = {};
                activity.sectionName = pdfObject.sectionActivity[i].sectionName;
                activity.sectionScore = pdfObject.sectionActivity[i].sectionAverage || 0.00;
                activity.sectionNetworkAverage = pdfObject.sectionActivity[i].sectionNetworkAverage || 0.00;
                activity.scale = pdfObject.overallScore.scale || 5;
                activity.steps = [];

                activity.sectionScorePX = convertScoreToPX(activity.sectionScore, activity.scale, 250.00);
                activity.sectionNetworkAveragePX = convertScoreToPX(activity.sectionNetworkAverage, activity.scale, 250.00);

                for (j = 1; j <= activity.scale; j++) {
                    var px = 250;
                    var percentage = j / activity.scale;
                    var px = px * percentage;
                    var obj = {step: px};
                    activity.steps.push(obj);
                }

                summarySectionActivity.push(activity);
            }
        }*/
        //AF Refactor DELETE END

        function convertReportRunDate(date) {
            date = date.getTime();
            return scope.convertTimestampToDate(date);
        }

        scope.convertTimestampToDate = function(timestamp){
            var amPm = ' am';
            timestamp = parseInt(timestamp);
            var d = new Date(timestamp);
            var month = d.getUTCMonth() + 1;
            var day = d.getDate();
            var year = d.getFullYear();
            var hours = d.getHours();
            if(hours > 12){
                hours = hours - 12;
                amPm = ' pm';
            }
            var minutes = d.getUTCMinutes();
            minutes = minutes < 10 ? "0" + minutes : minutes;

            if ( month < 10) {
                month = '0' + month;
            }

            if ( day < 10 ) {
                day = '0' + day;
            }

            return month + '/' + day + '/' + year/** + ' ' + hours + ':' + minutes + amPm**/;
        };

        // Create Report Header DTO
        var reportHeader = {};
        reportHeader.reportName = pdfObject.reportPreferences.reportName;
        reportHeader.truncatedName = pdfObject.reportPreferences.reportName;
        reportHeader.reportRunDate = convertReportRunDate(reportRunDate);
        reportHeader.totalResponses = pdfObject.overallScore.responseCount; // Confirm??
        reportHeader.totalSurveys = pdfObject.reportPreferences.surveys.length;
        reportHeader.fromDate = getReportSurveyDates().fromDate;
        reportHeader.toDate = getReportSurveyDates().toDate;
        reportHeader.userType = $sessionStorage.institution.type;
        reportHeader.grouping = {};
        // // determine grouping object;
        if (scope.reportPreferences.groupingApplied) {
            reportHeader.grouping.groupingApplied = true;
            reportHeader.grouping.firstGrouping = scope.reportPreferences.firstGrouping || 'null';
            reportHeader.grouping.secondGrouping = scope.reportPreferences.secondGrouping || 'null';
        }
        reportHeader.numSchoolsIncluded = 0;
        reportHeader.contentTypeName = scope.reportPreferences.surveyContentTypeName;

        if (scope.reportPreferences.surveyContentTypeId == null) {
            scope.reportPreferences.surveyContentTypeId = -1;
        } else {

            if (pdfObject.customItemExist && pdfObject.certifiedItemExist && !pdfObject.customTemplateItemExist) {
                reportHeader.contentTypeId = 2;
            } else if (pdfObject.customItemExist && !pdfObject.certifiedItemExist && !pdfObject.customTemplateItemExist) {
                reportHeader.contentTypeId = 1;
            } else if (!pdfObject.customItemExist && pdfObject.certifiedItemExist && !pdfObject.customTemplateItemExist) {
                reportHeader.contentTypeId = 0;
            } else if (!pdfObject.customItemExist && !pdfObject.certifiedItemExist && pdfObject.customTemplateItemExist) {
                reportHeader.contentTypeId = 3;
            } else if (pdfObject.customItemExist && !pdfObject.certifiedItemExist && pdfObject.customTemplateItemExist) {
                reportHeader.contentTypeId = 4;
            }

            pdfObject.reportContentType = reportHeader.contentTypeId;

        }

        /**
         * Prep response institutions
         */
        var responseInstitutions = [];
        for (var i = 0; i < pdfObject.responseInstitutions.length; i++) {
            responseInstitutions.push({name: pdfObject.responseInstitutions[i].name, id: pdfObject.responseInstitutions[i].id, filtered: 0})
        }

        var filteredDemographics = [];
        function populateFilters() {
            var schoolPlacement = 0;
            filteredDemographics[schoolPlacement] = {alias: "School", filteredDemographics: [], filtered: 1};
            for (var i = 0; i < scope.responseInstitutions.length; i++) {
                var schoolDemo = {};
                filteredDemographics[schoolPlacement].filteredDemographics.push(schoolDemo);
                var l = filteredDemographics[schoolPlacement].filteredDemographics.length - 1;

                filteredDemographics[schoolPlacement].filteredDemographics[l].name = scope.responseInstitutions[i].name;
                filteredDemographics[schoolPlacement].filteredDemographics[l].id = scope.responseInstitutions[i].id;
                filteredDemographics[schoolPlacement].filteredDemographics[l].filtered = 1

                if (pdfObject.reportPreferences.institutions != null && pdfObject.reportPreferences.institutions.length > 0) {
                    // reset all response institutions filtered to 1
                    responseInstitutions[i].filtered = 1;
                    for (var j = 0; j <= pdfObject.reportPreferences.institutions.length; j++) {
                        if (pdfObject.reportPreferences.institutions[j] == scope.responseInstitutions[i].id) {
                            // Present in preferences
                            filteredDemographics[schoolPlacement].filteredDemographics[l].filtered = 0;
                            filteredDemographics[schoolPlacement].filtered = 0;
                            responseInstitutions[i].filtered = 0;
                            reportHeader.numSchoolsIncluded++; // numSchoolsIncluded only for filtered schools
                        }
                    }
                }


            }
            var totalInstitutions = 0;
            for (var i = 0; i < responseInstitutions.length; i++) {
                if (responseInstitutions[i].filtered === 0) {
                    totalInstitutions++;
                }
            }
            if (totalInstitutions == 1) {
                responseInstitutions = [];
            }

            /**
             * Steps:
             * 1. Place language demo object after institution
             * 2. For each selected language, create filteredDemographicDTO:
             ** @type name: STRING
             ** @type id: INTEGER
             ** @type filtered: INTEGER-default 1, but since only unfiltered demos show up in
             **** in reportPreferences.languages, the default in this case in 0, i.e. selected
             */
            //var languagePlacement = 1;
            //filteredDemographics[languagePlacement] = {alias: "Language", filteredDemographics: [], filtered: 1};
            //if (pdfObject.reportPreferences.languageFilter) {
            //    for (var i = 0; i < pdfObject.reportPreferences.languages.length; i++) {
            //
            //        var filteredDemoDTO = {
            //            name: pdfObject.responseLanguages[i].name,
            //            id: pdfObject.responseLanguages[i].id,
            //            filtered: 0
            //        };
            //
            //        filteredDemographics[languagePlacement].filteredDemographics.push(filteredDemoDTO);
            //    }
            //}

            for (var i = 0; i < scope.navDemographics.length; i++) {
                //var demo = scope.navDemographics[i];
                var demos = {};
                demos.alias = scope.navDemographics[i].alias;
                demos.filteredDemographics = [];
                demos.filtered = 1;

                filteredDemographics.push(demos);
                var demosLength = filteredDemographics.length - 1;
                for (var j = 0; j < scope.navDemographics[i].aliasOptions.length; j++) {
                    var demo = {};
                    filteredDemographics[demosLength].filteredDemographics.push(demo);
                    var demoLength = filteredDemographics[demosLength].filteredDemographics.length - 1;
                    filteredDemographics[demosLength].filteredDemographics[demoLength].name = scope.navDemographics[i].aliasOptions[j].option;
                    //filteredDemographics[demosLength].type = scope.navDemographics[i].alias;
                    // only push if either no filters are selected or this alias' filter has been selected
                    filteredDemographics[demosLength].filteredDemographics[demoLength].filtered = verifyDemographicFilter(scope.navDemographics[i].alias, filteredDemographics[demosLength].filteredDemographics[demoLength].name);
                    if (filteredDemographics[demosLength].filteredDemographics[demoLength].filtered == 0) {
                        demos.filtered = 0;
                    }
                }
            }

            var allTags = pdfObject.overallScore.tags;
            var selectedTagIds = pdfObject.reportPreferences.filteredTags;
            var tagPlacement = filteredDemographics.length;
            filteredDemographics[tagPlacement] = { alias: "Tag", filteredDemographics: [], filtered: 1};
            for (var i = 0; i < pdfObject.overallScore.tags.length; i++) {
                var that = pdfObject.overallScore.tags[i];
                var tag = {};
                filteredDemographics[tagPlacement].filteredDemographics.push(tag);
                var l = filteredDemographics[tagPlacement].filteredDemographics.length - 1;

                filteredDemographics[tagPlacement].filteredDemographics[l].name = that.tag;
                filteredDemographics[tagPlacement].filteredDemographics[l].id = that.tagId;

                if (that.filtered == true) {
                    filteredDemographics[tagPlacement].filteredDemographics[l].filtered = 0;
                    filteredDemographics[tagPlacement].filtered = 0;
                } else {
                    filteredDemographics[tagPlacement].filteredDemographics[l].filtered = 1;
                }
            }
            if (reportHeader.numSchoolsIncluded === 0) {
                reportHeader.numSchoolsIncluded = pdfObject.responseInstitutions.length;
            }
        }
        populateFilters();



        //AF Refactor DELETE

     /*   console.log(pdfObject.overallScore.overallScore);
        // Get Overall Score list object
        var overallScoreDTO = [{}];
        overallScoreDTO[0].score = parseFloat(pdfObject.overallScore.overallScore) || 0;
        overallScoreDTO[0].networkAverage = pdfObject.reportPreferences.networkAverage || 0;
        overallScoreDTO[0].scale = pdfObject.overallScore.scale || 5;
        overallScoreDTO[0].steps = [];

        function convertScoreToPX(score, scale, barConstantPX) {
            return (score / scale) * barConstantPX || 0.00;
        }
        overallScoreDTO[0].overallScorePX = convertScoreToPX(parseFloat(pdfObject.overallScore.overallScore), pdfObject.overallScore.scale, 250);
        overallScoreDTO[0].networkAveragePX = convertScoreToPX(parseFloat(pdfObject.reportPreferences.networkAverage), pdfObject.overallScore.scale, 250);

        for (i = 1; i <= overallScoreDTO[0].scale; i++) {
            var px = 250;
            var percentage = i / overallScoreDTO[0].scale;
            var px = px * percentage;
            var obj = {step: px};
            overallScoreDTO[0].steps.push(obj);
        }*/

        //AF Refactor DELETE END



        function verifyDemographicFilter(demo, name) {
            var filterObj = scope.reportPreferences.filteredDemographicAliasOptions;

            if (filterObj == null || filterObj.length < 1) {
                return 1;
            } else {
                if (filterObj.length < 1) {
                    return 0;
                } else {
                    for (var i = 0; i < filterObj.length; i++) {
                        var filterObj_demo = filterObj[i].split('|')[0];
                        var filterObj_demoName = filterObj[i].split('|')[1];
                        if (name === filterObj_demoName) {
                            return 0;
                        }
                    }
                    return 1;
                }
            }
        }

        function getReportSurveyDates() {
            var obj = {
                fromDate: String,
                toDate: String
            };
            // adjust date formats
            var fd, td;
            if (pdfObject.reportPreferences.fromDate == null) {
                fd = 'null';
            } else {
                var fd = pdfObject.reportPreferences.fromDate;
            }

            if (pdfObject.reportPreferences.toDate == null) {
                td = 'null';
            } else {
                var td = pdfObject.reportPreferences.toDate;
            }
            obj.fromDate = fd;
            obj.toDate = td;
            return obj;
        }







        // Get certified content data
        var certifiedContent = [];
         for(var i=0; i < pdfObject.reportObject.length; i++){
             var content = {};

             content.name = pdfObject.reportObject[i].name;
             content.grouping = pdfObject.reportObject[i].grouping;

             if (pdfObject.reportObject[i].firstGrouping != null) {
                 content.firstGrouping = pdfObject.reportObject[i].firstGrouping;
                 content.firstGroupingValue = pdfObject.reportObject[i].questions[0].firstGrouping[0].text;
                 if (pdfObject.reportObject[i].secondGrouping != null) {
                     content.secondGrouping = pdfObject.reportObject[i].secondGrouping;
                     content.secondGroupingValue = pdfObject.reportObject[i].questions[0].firstGrouping[0].secondGrouping[0].text;
                 }
             }

             // get questions
             var questions = [];
             for(var j=0; j < pdfObject.reportObject[i].questions.length; j++){
                 var question = {};
                 question.text = pdfObject.reportObject[i].questions[j].text;
                 question.label = pdfObject.reportObject[i].questions[j].label;
                 question.allOptions = pdfObject.reportObject[i].questions[j].allOptions;
                 question.useEmoticon = pdfObject.reportObject[i].questions[j].useEmoticon;

                 // get answers
                 var answers = [];
                 var answerPairs = _.pairs(pdfObject.reportObject[i].questions[j].total);

                 for(var k=0; k < answerPairs.length; k++){
                     var answer = {};
                     var name = _.first(answerPairs[k], 1);
                     var percentage = _.last(answerPairs[k],1);
                     answer.name = name[0];
                     answer.percentage = percentage[0];
                     answer.count = pdfObject.reportObject[i].questions[j].count;
                     answers.push(answer);
                 }
                 question.score = answers;

                 questions.push(question);
             }
             content.questions = questions;
             certifiedContent.push(content);
         }



        var itemActivities = [];

        /**
         * item activities
         */
        for (var a = 0; a < pdfObject.itemActivity.length; a++) {
            /**
             * get certified content demographics
             */
            itemActivities.push({});
            if (pdfObject.itemActivity[a].demographic == true) {
                itemActivities[a].name = pdfObject.itemActivity[a].name;
                itemActivities[a].label = pdfObject.itemActivity[a].label;
                itemActivities[a].elementContentType = pdfObject.itemActivity[a].elementContentType;
                itemActivities[a].elementTypeName = pdfObject.itemActivity[a].elementTypeName;
                itemActivities[a].sort = pdfObject.itemActivity[a].sort;
                itemActivities[a].demographic = convertBooleanToInteger(pdfObject.itemActivity[a].demographic);
                itemActivities[a].items = [];

                var rias = true;
                if (rias) {
                    for (var b = 0; b < pdfObject.itemActivity[a].childItems.length; b++) {
                        var item = {};
                        itemActivities[a].items.push(item);
                        var itemsLength = itemActivities[a].items.length - 1;
                        itemActivities[a].items[itemsLength].renderType = pdfObject.itemActivity[a].childItems[b].renderType;
                        populateItem(a, b, itemsLength);
                    }
                }
            }
        }

        function populateItem(a, b, itemsLength) {
                itemActivities[a].items[itemsLength].itemText = pdfObject.itemActivity[a].childItems[b].itemText;
                itemActivities[a].items[itemsLength].useEmoticon = pdfObject.itemActivity[a].childItems[b].useEmoticon;
                itemActivities[a].items[itemsLength].itemLabel = b+1;

                itemActivities[a].items[itemsLength].responseData = [];
                itemActivities[a].items[itemsLength].totalItemCount = 0;

                populateItemResponseData(a, itemActivities[a].items[itemsLength], itemsLength);

                function populateItemResponseData(a, item, itemsLength) {
                    var responseData = item.responseData;
                    if (pdfObject.itemActivity[a].childItems[b].responseData.length > 0) {
                        for (var c = 0; c <  pdfObject.itemActivity[a].childItems[b].responseData.length; c++) {
                            responseData.push({});
                            var responseDataLength = responseData.length - 1;
                            responseData[responseDataLength].name = pdfObject.itemActivity[a].childItems[b].responseData[c].itemText;
                            responseData[responseDataLength].responsePercentage = pdfObject.itemActivity[a].childItems[b].responseData[c].responsePercentage;
                            responseData[responseDataLength].responseCount = pdfObject.itemActivity[a].childItems[b].responseData[c].responseCount;
                            itemActivities[a].items[itemsLength].totalItemCount += responseData[responseDataLength].responseCount;
                            responseData[responseDataLength].firstGrouping = "null";
                            responseData[responseDataLength].secondGrouping = "null";
                            responseData[responseDataLength].allOptions = [];
                            for (var d = 0; d < pdfObject.itemActivity[a].childItems[b].responseData[c].allOptions.length; d++) {
                                var option = {};
                                option.optionText = pdfObject.itemActivity[a].childItems[b].responseData[c].allOptions[d].optionText;
                                responseData[responseDataLength].allOptions.push(option);
                            }
                        }

                    } else {
                        for (var c = 0; c < pdfObject.itemActivity[a].childItems[b].childItems.length; c++) {
                            /**
                             * If this case, first grouping is applied
                             */
                            if (pdfObject.itemActivity[a].childItems[b].childItems[c].responseData.length > 0) {

                                for (var d = 0; d < pdfObject.itemActivity[a].childItems[b].childItems[c].responseData.length; d++) {
                                    responseData.push({});
                                    var responseDataLength = responseData.length - 1;
                                    responseData[responseDataLength].name = pdfObject.itemActivity[a].childItems[b].childItems[c].responseData[d].itemText;
                                    responseData[responseDataLength].responsePercentage = pdfObject.itemActivity[a].childItems[b].childItems[c].responseData[d].responsePercentage;
                                    responseData[responseDataLength].responseCount = pdfObject.itemActivity[a].childItems[b].childItems[c].responseData[d].responseCount;
                                    itemActivities[a].items[itemsLength].totalItemCount += responseData[responseDataLength].responseCount;
                                    /**
                                     * Apply first grouping if first grouping demo type is not this
                                     */
                                    if (pdfObject.reportPreferences.firstGrouping !== item.itemText) {
                                        responseData[responseDataLength].firstGrouping = pdfObject.itemActivity[a].childItems[b].childItems[c].responseData[d].firstAggregateLabel || "null";
                                        /** check for institution **/
                                        if (typeof parseInt(pdfObject.itemActivity[a].childItems[b].childItems[c].responseData[d].firstAggregateLabel) == "number") {
                                            for (var e = 0; e < pdfObject.responseInstitutions.length; e++) {
                                                if (pdfObject.responseInstitutions[e].id == parseInt(pdfObject.itemActivity[a].childItems[b].childItems[c].responseData[d].firstAggregateLabel)) {
                                                    responseData[responseDataLength].firstGrouping = pdfObject.responseInstitutions[e].name;
                                                }
                                            }
                                        }
                                    } else {
                                        responseData[responseDataLength].firstGrouping = "null";
                                    }

                                    if (pdfObject.reportPreferences.secondGrouping !== item.itemText) {
                                        responseData[responseDataLength].secondGrouping = pdfObject.itemActivity[a].childItems[b].childItems[c].responseData[d].secondAggregateLabel || "null";
                                        if (typeof parseInt(pdfObject.itemActivity[a].childItems[b].childItems[c].responseData[d].secondAggregateLabel) == "number") {
                                            for (var e = 0; e < pdfObject.responseInstitutions.length; e++) {
                                                if (pdfObject.responseInstitutions[e].id == parseInt(pdfObject.itemActivity[a].childItems[b].childItems[c].responseData[d].secondAggregateLabel)) {
                                                    responseData[responseDataLength].secondGrouping = pdfObject.responseInstitutions[e].name;
                                                }
                                            }
                                        }
                                    } else {
                                        responseData[responseDataLength].secondGrouping = "null";
                                    }
                                    /**
                                     * Gather options for first grouping
                                     */
                                    responseData[responseDataLength].allOptions = [];
                                    for (var e = 0; e < pdfObject.itemActivity[a].childItems[b].allOptions.length; e++) {
                                        var option = {};
                                        option.optionText = pdfObject.itemActivity[a].childItems[b].allOptions[e].optionText;
                                        option.optionId = pdfObject.itemActivity[a].childItems[b].allOptions[e].optionId;
                                        responseData[responseDataLength].allOptions.push(option);
                                    }

                                }
                            } else {
                                for (var d = 0; d < pdfObject.itemActivity[a].childItems[b].childItems[c].childItems.length; d++) {
                                    /**
                                     * If this case, second grouping is applied
                                     */

                                    for (var e = 0; e < pdfObject.itemActivity[a].childItems[b].childItems[c].childItems[d].responseData.length; e++) {
                                        responseData.push({});
                                        var responseDataLength = responseData.length - 1;
                                        responseData[responseDataLength].name = pdfObject.itemActivity[a].childItems[b].childItems[c].childItems[d].responseData[e].itemText;
                                        responseData[responseDataLength].responsePercentage = pdfObject.itemActivity[a].childItems[b].childItems[c].childItems[d].responseData[e].responsePercentage;
                                        responseData[responseDataLength].responseCount = pdfObject.itemActivity[a].childItems[b].childItems[c].childItems[d].responseData[e].responseCount;
                                        itemActivities[a].items[itemsLength].totalItemCount += responseData[responseDataLength].responseCount;
                                        /**
                                         * Apply first and second grouping
                                         */
                                        if (pdfObject.reportPreferences.firstGrouping !== item.itemText) {
                                            responseData[responseDataLength].firstGrouping = pdfObject.itemActivity[a].childItems[b].childItems[c].childItems[d].responseData[e].firstAggregateLabel || "null";
                                            if (typeof parseInt(pdfObject.itemActivity[a].childItems[b].childItems[c].childItems[d].responseData[e].firstAggregateLabel) == "number") {
                                                for (var f = 0; f < pdfObject.responseInstitutions.length; f++) {
                                                    if (pdfObject.responseInstitutions[f].id == parseInt(pdfObject.itemActivity[a].childItems[b].childItems[c].childItems[d].responseData[e].firstAggregateLabel)) {
                                                        responseData[responseDataLength].firstGrouping = pdfObject.responseInstitutions[f].name;
                                                    }
                                                }
                                            }
                                        } else {
                                            responseData[responseDataLength].firstGrouping = "null";
                                        }

                                        if (pdfObject.reportPreferences.secondGrouping !== item.itemText) {
                                            responseData[responseDataLength].secondGrouping = pdfObject.itemActivity[a].childItems[b].childItems[c].childItems[d].responseData[e].secondAggregateLabel || "null";
                                            if (typeof parseInt(pdfObject.itemActivity[a].childItems[b].childItems[c].childItems[d].responseData[e].secondAggregateLabel) == "number") {
                                                for (var f = 0; f < pdfObject.responseInstitutions.length; f++) {
                                                    if (pdfObject.responseInstitutions[f].id == parseInt(pdfObject.itemActivity[a].childItems[b].childItems[c].childItems[d].responseData[e].secondAggregateLabel)) {
                                                        responseData[responseDataLength].secondGrouping = pdfObject.responseInstitutions[f].name;
                                                    }
                                                }
                                            }
                                        } else {
                                            responseData[responseDataLength].secondGrouping = "null";
                                        }
                                        /**
                                         * Gather options for second grouping
                                         */
                                        responseData[responseDataLength].allOptions = [];
                                        for (var e = 0; e < pdfObject.itemActivity[a].childItems[b].allOptions.length; e++) {
                                            var option = {};
                                            option.optionText = pdfObject.itemActivity[a].childItems[b].allOptions[e].optionText;
                                            option.optionId = pdfObject.itemActivity[a].childItems[b].allOptions[e].optionId;
                                            responseData[responseDataLength].allOptions.push(option);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }

        function convertBooleanToInteger(bool) {
            var result = null;
            if (bool == true) {
                result = 0;
            } else {
                result = 1;
            }
            return result;
        }

        // Create customContent data
        var finalCustomItems = [];
         for(var i = 0; i < pdfObject.customItems.length; i++){
             var customItem = {};
             customItem.surveyName = pdfObject.customItems[i].surveyName;

             var childItems = [];
             for(var j=0; j < pdfObject.customItems[i].childItems.length; j++){
                 var childItem = {};
                 childItem.name = pdfObject.customItems[i].childItems[j].name;
                 childItem.label = pdfObject.customItems[i].childItems[j].label;
                 childItem.demographic = convertBooleanToInteger(pdfObject.customItems[i].childItems[j].demographic);
                 var grandChildItems = [];

                 for(var k=0; k < pdfObject.customItems[i].childItems[j].childItems.length; k++){
                     var grandChildItem = {};
                     grandChildItem.itemText = pdfObject.customItems[i].childItems[j].childItems[k].itemText;
                     grandChildItem.itemLabel = pdfObject.customItems[i].childItems[j].childItems[k].label;
                     grandChildItem.renderType = pdfObject.customItems[i].childItems[j].childItems[k].renderType;
                     grandChildItem.totalResponses = pdfObject.customItems[i].childItems[j].childItems[k].totalResponseCount;
                     grandChildItem.allOptions = pdfObject.customItems[i].childItems[j].allOptions;
                     var answers = [];
                     if (pdfObject.customItems[i].childItems[j].childItems[k].responseData.length > 0) { // No grouping
                         for (var l = 0; l < pdfObject.customItems[i].childItems[j].childItems[k].responseData.length; l++) {
                             var answer = {};
                             answer.name = pdfObject.customItems[i].childItems[j].childItems[k].responseData[l].name;
                             answer.count = pdfObject.customItems[i].childItems[j].childItems[k].responseData[l].responseCount;
                             answer.percentage = pdfObject.customItems[i].childItems[j].childItems[k].responseData[l].responsePercentage;
                             answers.push(answer);
                         }
                     }
                     else{
                         for (var l = 0; l < pdfObject.customItems[i].childItems[j].childItems[k].childItems.length; l++) { // firstGrouping
                             grandChildItem.firstGrouping = pdfObject.customItems[i].childItems[j].childItems[k].childItems[l].name;
                             if(pdfObject.customItems[i].childItems[j].childItems[k].childItems[l].responseData.length > 0){
                                 for (var m = 0; m < pdfObject.customItems[i].childItems[j].childItems[k].childItems[l].responseData.length; m++) {
                                     var answer = {};
                                     answer.name = pdfObject.customItems[i].childItems[j].childItems[k].childItems[l].responseData[m].name;
                                     answer.count = pdfObject.customItems[i].childItems[j].childItems[k].childItems[l].responseData[m].responseCount;
                                     answer.percentage = pdfObject.customItems[i].childItems[j].childItems[k].childItems[l].responseData[m].responsePercentage;
                                     answers.push(answer);
                                 }
                             }
                             else{
                                 for (var m = 0; m < pdfObject.customItems[i].childItems[j].childItems[k].childItems[l].childItems.length; m++) { // secondGrouping
                                     grandChildItem.secondGrouping = pdfObject.customItems[i].childItems[j].childItems[k].childItems[l].childItems[m].name;
                                     for (var n = 0; n < pdfObject.customItems[i].childItems[j].childItems[k].childItems[l].childItems[m].responseData.length; n++) {
                                         var answer = {};
                                         answer.name = pdfObject.customItems[i].childItems[j].childItems[k].childItems[l].childItems[m].responseData[n].name;
                                         answer.count = pdfObject.customItems[i].childItems[j].childItems[k].childItems[l].childItems[m].responseData[n].responseCount;
                                         answer.percentage = pdfObject.customItems[i].childItems[j].childItems[k].childItems[l].childItems[m].responseData[n].responsePercentage;

                                     }
                                 }
                                 answers.push(answer);
                             }
                         }
                     }
                     grandChildItem.answers = answers;
                     grandChildItems.push(grandChildItem);
                 }
                 childItem.items = grandChildItems;
                 childItems.push(childItem);
                 customItem.sections = childItems;
             }

             finalCustomItems.push(customItem);
         }


        /**
         * Determining Report Content Type
         * 0: certified
         * 1: custom
         * 2: certified & custom
         * @type {{reportId: (*|null|string|dummyReportId|string), reportHeader: *[], firstGrouping: (*|Array|string|null), secondGrouping: (*|Array|string|null), overallScore: *[], overallScoreScale: *, useEmoticon: boolean, opinionScaleLabels: Array, networkAverage: (*|null|string|number), filteredDemographics: Array, sectionActivity: Array, certifiedContent: Array, customContent: Array, itemActivities: Array, responseInstitutions: Array}}
         */
        // function retrieveContentType(certifiedExists, customExists) {
        //    console.log('custom exists ' + customExists);
        //    var reportContentType;
        //    //var customExists = pdfObject.customItems.length > 0;
        //    //var certifiedExists = pdfObject.itemActivity.length > 0;
        //    if (customExists && certifiedExists) {
        //        reportContentType = 2;
        //    } else if (customExists && !certifiedExists) {
        //        reportContentType = 1;
        //    } else if (!customExists && certifiedExists) {
        //        reportContentType = 0;
        //    } else {
        //        reportContentType = 0;
        //    }
        //    console.log(reportContentType);
        //    return reportContentType;
        //}

        /**
         * Grouping certified & custom, non-filtered demographics into new property "demographics"
         * ...
         */
        var demographics = [];
        finalCustomItems.forEach(function(survey) { // custom
            if (survey.sections) {
                for (var a = 0; a < survey.sections.length; a++) {
                    if (survey.sections[a].demographic == 0) {
                        /** convert to demographics object **/
                        var cdo = {};
                        cdo.demographic = survey.sections[a].demographic;
                        cdo.elementContentType = "Custom Content";
                        cdo.label = survey.sections[a].label;
                        cdo.name = survey.sections[a].name;
                        cdo.items = [];
                        for (var b = 0; b < survey.sections[a].items.length; b++) {
                            var item = {};
                            item.itemLabel = parseInt(survey.sections[a].items[b].label);
                            item.itemText = survey.sections[a].items[b].name;
                            item.renderType = survey.sections[a].items[b].renderType;
                            item.totalItemCount = survey.sections[a].items[b].totalResponseCount;
                            item.responseData = [];
                            //for (var c = 0; c < survey.questions[a].items[b].answers[c]; c++) {
                            //    //TODO: need to incorporate Raghav's query for options for custom content only
                            //}
                            cdo.items.push(item);
                        }
                        /** push to dto var **/
                        demographics.push(cdo)
                    }
                }
            }
        });
        itemActivities.forEach(function(section) {
            if (section.demographic == 0) {
                demographics.push(section);
            }
        });


        //AF Refactor ADD
        //pdfObject.reportContentType = retrieveContentType();
        pdfObject.reportHeader = reportHeader;
        pdfObject.filteredDemographics = filteredDemographics;
        pdfObject.responseInstitutions = responseInstitutions;
        //pdfObject.responseLanguages = responseLanguages;
        reportPdfDataService.parsePdfObject(pdfObject);

        //AF Refactor ADD - END


/*
        scope.finalPdfReportObject = {
            reportId  : pdfObject.reportPreferences.reportId,
            reportContentType: retrieveContentType(),
            reportHeader: [reportHeader],
            filteredDemographics: filteredDemographics,
            responseInstitutions: responseInstitutions
        };


        if(scope.finalPdfReportObject.reportContentType == 0 || scope.finalPdfReportObject.reportContentType == 2){

            scope.finalPdfReportObject.certifiedContent = {
                content: certifiedContent,
                demographics : getDemographicsSection(demographics, 'Certified Content'),
                overallScore: overallScoreDTO,
                sectionActivity: summarySectionActivity
            }
        }


        if(scope.finalPdfReportObject.reportContentType == 1 || scope.finalPdfReportObject.reportContentType == 2){
            scope.finalPdfReportObject.customContent = {
                surveys: finalCustomItems,
                demographics : getDemographicsSection(demographics, 'Custom Content')
            }
        }

        console.log(scope.finalPdfReportObject);
        */

    }

    var getDemographicsSection = function(demographics, contentType){
        for(var contentIndex in demographics){
            if(demographics[contentIndex].elementContentType == contentType){
                return demographics[contentIndex];
            }
        }
    }

    // TODO: Will - this.retrieveSharedInstitutions and this.getInstitutionHierarchyAndDetails are not being used anywhere
    // and the usage of scope is undefined and will not work properly. Consider removing.
    //this.retrieveSharedInstitutions = function () {
    //
    //    var reportId = scope.reportPreferences.reportId;
    //    var institutionId = applicationService.getInstitutionId();
    //
    //    // Get shared institutionIds
    //    SurveyCreateService.getInstitutionIds({customerId: institutionId, objectId: reportId, objectTypeId: '5'})
    //        .$promise
    //        .then(function (institutionIds) {
    //            // success handler
    //            scope.institutionIds = institutionIds;
    //            if (scope.institutionIds.length > 0) {
    //                scope.getInstitutionHierarchyAndDetails(0, institutionIds);
    //            }
    //        }, function (error) {
    //            // error handler
    //
    //        });
    //}
    //
    //this.getInstitutionHierarchyAndDetails = function (i, institutionIds) {
    //
    //    if (i < institutionIds.length) {
    //
    //        var institutionId = institutionIds[i];
    //        var reportId = scope.reportPreferences.reportId;
    //        var userId = userService.getUserId();
    //
    //        var params = {
    //            objectId: reportId,
    //            objectType: 'report',
    //            customerId: institutionId,
    //            userId: userId
    //        }
    //
    //        //SurveyCreateService.getUserRole(params)
    //        //    .$promise
    //        //    .then(function (roleIdForSharedInstitution) {
    //        //        console.log('roleIdForSharedInstitution = ' + roleIdForSharedInstitution.id);
    //        //        scope.roleIdForSharedInstitution = roleIdForSharedInstitution.id;
    //        //        scope.ownerCustomerId = roleIdForSharedInstitution.ownerCustomerId;
    //        //
    //        //        return SurveyCreateService.getInstitutionDetails({institutionId: institutionId}).$promise
    //        //    })
    //        //    .then(function (institution) {
    //        //        var index = scope.institutions.map(function (e) { return e.institution; }).indexOf(institution.name);
    //        //        // is currently selected
    //        //        if (index > -1) {
    //        //            scope.institutions.splice(index, 1);
    //        //            console.log('scope.institutions = ' + scope.institutions);
    //        //        }
    //        //        // is newly selected
    //        //        else {
    //        //            institution.permissions = angular.copy(scope.allPermissions);
    //        //            var permission = "View Only";
    //        //            if (scope.roleIdForSharedInstitution == '4') {
    //        //                permission = "Edit";
    //        //            }
    //        //            institution.permissions.name = permission;
    //        //            institution.id = institution.id + "";
    //        //            scope.institutions.push(institution);
    //        //
    //        //            // Remove Owner/Self institution from shared survey display list
    //        //            //if(institutionId == scope.ownerCustomerId || institutionId == applicationService.getInstitutionId()){
    //        //            if(institutionId == applicationService.getInstitutionId()){ Replace this line with commented one for report sharing
    //        //                for (var count = 0; count <= scope.institutions.length; count++){
    //        //                    if(institutionId == scope.institutions[count].id){
    //        //                        scope.institutions.splice(count, 1)
    //        //                    }
    //        //                }
    //        //            }
    //        //
    //        //            console.log('scope.institutions = ' + scope.institutions);
    //        //        }
    //        //        console.log('getInstitutionDetails success');
    //        //        i++;
    //        //        scope.getInstitutionDetails(i, institutionIds);
    //        //    });
    //
    //    }
    //
    //};

    this.getOpenEndedAnswers = function(scope){
        ReportService.getOpenEndedAnswers({surveyIds : scope.reportPreferences.surveys, languageId : scope.languageId})
                            .$promise.then(function(payload) {
                scope.answers = payload.openEndedAnswers;

                reportPdfDataService.addOpenEndedAnswers(scope.answers);

                //AF Refactor DELETE
                /*console.log('reportData ', reportPdfDataService.getReportData());

                var customOpenItems = [];
                var certifiedOpenItems = [];

                angular.forEach(scope.answers, function(answer){
                    var customItem = reportPdfDataService.searchIdInItemsHierarchy(scope.customItems, answer.elementId);
                    if(customItem){
                        customOpenItems.push(answer);
                    }
                    else{
                        certifiedOpenItems.push(answer);
                    }
                });

                console.log('getOpenEndedAnswers scope ', scope.customItems);
                console.log('getOpenEndedAnswers scope.answers ', scope.answers);
                console.log('customOpenItems ', customOpenItems);
                console.log('certifiedOpenItems ', certifiedOpenItems);

                if(scope.finalPdfReportObject.certifiedContent){
                    scope.finalPdfReportObject.certifiedContent.openEndedItems = certifiedOpenItems;
                }
                if(scope.finalPdfReportObject.customContent){
                    scope.finalPdfReportObject.customContent.openEndedItems = customOpenItems;
                }*/
                //AF Refactor DELETE - END
        },function(httpResponse){

        });
    }


    this.setItemActivityResponse = function(itemAverages){
        reportPdfDataService.setItemActivityResponse(itemAverages);
    }

    this.addLowestScoringItems = function(items){
        reportPdfDataService.addLowestScoringItems(items);
    }

    this.addHighestScoringItems = function(items){
        reportPdfDataService.addHighestScoringItems(items);
    }

    this.getReportPdfData = function(){
        return reportPdfDataService.getReportData();
    }
}
