/**
 * Created by pvo on 3/27/15.
 */

app.factory('CertifiedContentService', [ '$resource', function($resource) {
    return $resource(hostname + '/echo-api/api/contents/:contentId',
        {contentId:'@contentId', target:'@target', contentName:'@contentName', description:'@description'},
        {
            'filter': {url: hostname + '/echo-api/api/contents/filter',
                method:'GET',
                params:{target:'@target', contentName:'@contentName'},
                isArray:true
            },
            'deleteFilter': {url: hostname + '/echo-api/api/contents/deleteFilter',
                method:'DELETE',
                params:{contentId:'@contentId', target:'@target', contentName:'@contentName'},
                isArray:true
            },
            'search': {url: hostname + '/echo-api/api/autofill/contents/:token',
                method:'GET',
                isArray:true
            },
            'sort': {url: hostname + '/echo-api/api/contents/sort',
                method:'POST',
                params:{contentIdList:'@contentIdList', sortOrderList:'@sortOrderList'},
                isArray:false
            },
            'published': {url: hostname + '/echo-api/api/contents/published',
                method:'GET',
                params:{count: 6},
                isArray:true
            }
        }
    );
}
]);