var app = angular.module('echo', [ 'ngRoute', 'moment-picker', 'ngResource', 'mm.foundation', 'ui.sortable', 'ngAnimate', 'ngSanitize','ui.date', 'ngStorage', 'ngFileUpload', 'ngCsv', 'angular-jquery-querybuilder']);

// function definitions
var contentCreatorAuth;
/*
* The routes below are grouped based on the controllers that handle them
*/
app.config(['$routeProvider', '$locationProvider', function ($routeProvider, $locationProvider) {
    $locationProvider.hashPrefix("");
    $routeProvider
        // Home
        .when("/", {templateUrl: "partials/dashboard/dashboard.html", controller: "DashboardCtrl"})

        .when("/manage-content", {
            templateUrl: "partials/templates/manage-content.html",
            controller: "ManageContentCtrl",
            resolve: {
                authorization: contentCreatorAuth
            }
        })

        .when("/survey-library", {templateUrl: "partials/survey-library.html", controller: "CertifiedContentsController"})

        .when("/browse-reports", {templateUrl: "partials/reports/browse-reports.html", controller: "BrowseReportsCtrl"})

        .when("/create-report", {templateUrl: "partials/reports/create-new-report.html", controller: "CreateReportsCtrl", create: true})
        .when("/edit-report", {templateUrl: "partials/reports/report-groupby.html", controller: "CreateReportsCtrl", edit: true})
        .when("/view-report", {templateUrl: "partials/reports/report-groupby.html", controller: "CreateReportsCtrl", view: true})
        .when("/preview-report", {templateUrl: "partials/reports/report-groupby.html", controller: "CreateReportsCtrl", preview: true})

        .when("/action/admin-survey/:surveyId/:ownerInstitutionId/:institutionId/:enrollmentId", {templateUrl: "survey/survey-taking.html", controller: "SurveyTakingController"})
        .when("/action/:surveyId/:institutionId", {templateUrl: "survey/survey-taking.html", controller: "SurveyTakingController"})
        .when("/closed/:closedSurveyName", {templateUrl: "partials/survey/closed.html", controller: "SurveyTakingController"})
        .when("/action/:surveyId/:institutionId/:languageId", {templateUrl: "survey/survey-institution-selector.html", controller: "SurveyTakingController"})
        .when("/action/:surveyId/:institutionId/:languageId/:selectedInstitutionId", {templateUrl: "survey/survey-taking.html", controller: "SurveyTakingController"})
        .when("/action/:surveyId/:institutionId/:languageId/:selectedInstitutionId/:tokenValue", {templateUrl: "survey/survey-taking.html", controller: "SurveyTakingController"})
        .when("/action/:surveyId/:institutionId/:languageId/:selectedInstitutionId/404", {templateUrl: "partials/survey/custom/alreadyTakenSurvey.html", controller: "SurveyTakingController"})
        .when("/pre-view/:preSurveyId/:institutionId", {templateUrl: "survey/survey-taking.html", controller: "SurveyTakingController"})
        .when("/preview/:preSurveyId/:institutionId", {templateUrl: "survey/survey-taking.html", controller: "SurveyTakingController"})
        .when("/preview/:preSurveyId/:institutionId/:languageId", {templateUrl: "survey/survey-institution-selector.html", controller: "SurveyTakingController"})
        .when("/preview/:preSurveyId/:institutionId/:languageId/:selectedInstitutionId", {templateUrl: "survey/survey-taking.html", controller: "SurveyTakingController"})
        .when("/preview/:preSurveyId/:institutionId/:languageId/:selectedInstitutionId/:tokenValue", {templateUrl: "survey/survey-taking.html", controller: "SurveyTakingController"})
        .when("/previewCertifiedContent/:preSurveyId/:institutionId", {templateUrl: "survey/survey-taking.html", controller: "SurveyTakingController"})

        .when("/preview-content/:templateId/language/:langId", {templateUrl: "partials/templates/preview-template.html", controller: "PreviewTemplateCtrl"})
        .when("/preview-content/:templateId/language/:langId", {templateUrl: "partials/templates/preview-template.html", controller: "PreviewTemplateCtrl"})

        .when("/select-lang/:templateId", {templateUrl: "partials/templates/select-language.html", controller: "SelectLangCtrl"})

        .when("/publish-content/:templateId", {templateUrl: "partials/templates/publish/publish-template.html", controller: "PublishTemplateCtrl"})

        .when("/sharing-content/:templateId", {templateUrl: "partials/templates/sharing/sharing-template.html", controller: "SharingTemplateCtrl"})

        .when("/published/:templateId", {templateUrl: "partials/templates/publish/published-template.html", controller: "PublishedCtrl"})

        .when("/copy/:copiedContentId", {templateUrl: "partials/survey-create.html", controller: "SurveyCreateController"})
        .when("/copy-existing/:copiedSurveyId/:certificationId", {templateUrl: "partials/survey-create.html", controller: "SurveyCreateController"})
        .when("/create/", {templateUrl: "partials/survey-create.html", controller: "SurveyCreateController"})
        .when("/view/:surveyId", {templateUrl: "partials/survey-create.html", controller: "SurveyCreateController"})
        .when("/edit/:surveyId", {templateUrl: "partials/survey-create.html", controller: "SurveyCreateController"})
        .when("/edit/:surveyId/:sharing", {templateUrl: "partials/survey-create.html", controller: "SurveyCreateController"})
        .when("/edit/:surveyId/:institutionId", {templateUrl: "partials/survey-create.html", controller: "SurveyCreateController"})
        .when("/edit/:surveyId/:institutionId/:sharing", {templateUrl: "partials/survey-create.html", controller: "SurveyCreateController"})

        .when("/edit-template/:templateId", {templateUrl: "partials/templates/template-create.html", controller: "TemplateCreateController"})

        .when("/manage-users", {templateUrl: "partials/manage-users.html", controller: "ManageUsersCtrl"})
        .when("/upload-multiple-users", {templateUrl: "partials/upload-multiple-users.html", controller: "ManageUsersCtrl"})
        .when("/upload-multiple-success", {templateUrl: "partials/upload-multiple-success.html", controller: "ManageUsersCtrl"})
        .when("/upload-multiple-failed", {templateUrl: "partials/upload-multiple-failed.html", controller: "ManageUsersCtrl"})

        .when("/add-admin", {templateUrl: "partials/add-admin.html", controller: "StaticController"})
        .when("/add-school", {templateUrl: "partials/add-school.html", controller: "StaticController"})
        .when("/user-management", {templateUrl: "partials/user-management.html", controller: "StaticController"})

        .when("/institution-selector", {templateUrl: "partials/institution-selector.html", controller: "SurveyEditController"})
        .when("/manage-groups", {templateUrl: "partials/manage-groups.html", controller: "SurveyEditController"})
        .when("/cart", {templateUrl: "partials/cart.html", controller: "SurveyEditController"})
        .when("/terms", {templateUrl: "partials/terms.html", controller: "SurveyEditController"})
        .when("/sign-up-customerid", {templateUrl: "partials/sign-up-customerid.html", controller: "SurveyEditController"})
        .when("/sign-up-select-institution", {templateUrl: "partials/sign-up-select-institution.html", controller: "SurveyEditController"})
        .when("/sign-up-confirm", {templateUrl: "partials/sign-up-confirm.html", controller: "SurveyEditController"})
        .when("/sign-up", {templateUrl: "partials/sign-up.html", controller: "SurveyEditController"})
        .when("/sign-up-information", {templateUrl: "partials/sign-up-information.html", controller: "SurveyEditController"})
        .when("/log-in", {templateUrl: "partials/log-in.html", controller: "SurveyEditController"})
        .when("/log-in-information", {templateUrl: "partials/log-in-information.html", controller: "SurveyEditController"})
        .when("/purchase", {templateUrl: "partials/purchase.html", controller: "SurveyEditController"})
        .when("/purchase-thanks", {templateUrl: "partials/purchase-thanks.html", controller: "SurveyEditController"})

        .when("/invite", {templateUrl: "partials/invite.html", controller: "SurveyEditController"})
        .when("/upload-multiple-schools", {templateUrl: "partials/upload-multiple-schools.html", controller: "SurveyEditController"})
        .when("/payment-invoice", {templateUrl: "partials/payment-invoice.html", controller: "SurveyEditController"})
        .when("/payment-invoice-other", {templateUrl: "partials/payment-invoice-other.html", controller: "SurveyEditController"})
        .when("/cart-summary-credit", {templateUrl: "partials/cart-summary-credit.html", controller: "SurveyEditController"})
        .when("/cart-summary-invoice", {templateUrl: "partials/cart-summary-invoice.html", controller: "SurveyEditController"})
        .when("/added-user-institutions", {templateUrl: "partials/added-user-institutions.html", controller: "SurveyEditController"})

        .when("/added-user-roles", {templateUrl: "partials/added-user-roles.html", controller: "SurveyEditController"})
        .when("/add-institution1", {templateUrl: "partials/add-institution.html", controller: "SurveyEditController"})


        .when("/signup/select-institution", {templateUrl: "partials/onboarding/signup/select-institution.html", controller: "SignupSelInstCtrl"})
        .when("/signup/select-institution-customerId", {templateUrl: "partials/onboarding/signup/select-institution-customerId.html", controller: "SignupSelInstCtrl"})

        .when("/signup/confirm", {templateUrl: "partials/onboarding/signup/confirm.html", controller: "SignupConfirmCtrl"})

        .when("/signin", {templateUrl: "partials/application/login/sign-in.html", controller: "SigninCtrl", reloadOnSearch: false})

        .when("/signin/select-institution", {templateUrl: "partials/application/login/institution-selector.html", controller: "signInSelectInstitutionCtrl"})

        .when("/forgot-password", {templateUrl: "partials/application/login/forgot-password.html", controller: "ForgotPasswordCtrl"})

        .when("/change-password/:token", {templateUrl: "partials/application/login/create-password.html", controller: "ChangePasswordCtrl"})

        .when("/app/:pageType", {templateUrl: "partials/application/index.html", controller: "ApplicationController"})

        .when("/browse-content", {templateUrl: "partials/templates/browse-content.html", controller: "BrowseContentCtrl"})
        .when("/download-report", {templateUrl: "partials/application/login/sign-in.html", controller: "DownloadReportController"})
        .when("/download-report/:docref", {templateUrl: "partials/application/login/sign-in.html", controller: "DownloadReportSignInCtrl"})
        .when("/download-ses-report/:docref", {templateUrl: "partials/application/login/sign-in.html", controller: "DownloadReportSignInCtrl"})


        //Distribution - Tokens
        .when("/manage-tokens/:surveyId", {templateUrl: "partials/survey/manage-tokens.html", controller: "TokenController"})

        .when("/assign-participants/:institutionId/:surveyId/:templateId", {templateUrl: "partials/assign-participants.html", controller: "AssignParticipantsCtrl"})
        .when("/thank-you", {templateUrl: "partials/survey/survey-thank-you.html", controller: "SurveyThankYouPageCtrl"})

        // else 404
        .otherwise("/404", {templateUrl: "partials/404.html", controller: "PageCtrl"});
}]).config(['momentPickerProvider', function (momentPickerProvider) {
    momentPickerProvider.options(
        {
            minView: "month",
            maxView: "minute",
            startView: "month",
            minutesStep: 5,
            hoursFormat: "LT",
            today: true
        }
    );
}]);

app.run(function($rootScope, $location){
    $rootScope.$on('$routeChangeError', function(event, current, previous, rejection){
        //if the route change failed due to authorization error, redirect them to dashboard
        if(rejection === 'unauthorized'){
            $location.path('/');
        }
    })
});

app.run(['$rootScope', function($rootScope) {
    $rootScope.$on('$viewContentLoaded', function() {
        loadFoundationJavaScript($rootScope);
        if (localStorage.getItem('currentUser')) {
            $rootScope.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        }
    });
}]);

app.run(['$rootScope', '$location', '$sessionStorage', '$window', function($rootScope, $location, $sessionStorage, $window) {
    $rootScope.$on( "$routeChangeStart", function(event, next, current) {

        if (next.$$route) {
            if (next.$$route.originalPath == "/signin") {

                localStorage.surveyRedirect = false;
            }
        }

        //if ( $sessionStorage.user == null ) {
        //}

        if ($window.ga){
            $window.ga('send', 'pageview', { page: $location.path() });
        }

    });
    //$rootScope.$on('$routeChangeSuccess', function(event) {
    //    console.log($location.url());
    //    if( $location.url().match(survey_taking_regex_withLang) !== null || $location.url().match(survey_taking_regex_withoutLang) !== null ) {
    //        //if (localStorage.surveyRedirect === 'true') {
    //        //    console.log('TAKEN');
    //        //    $location.url($location.url() + '/404');
    //        //    localStorage.surveyRedirect = false;
    //        //}
    //    }
    //});
}]);

app.run(['$rootScope', '$location', '$window', function($rootScope, $location, $window){
        $rootScope.$on('$routeChangeStart',
            function(event){
                if ($window.ga){
                    $window.ga('send', 'pageview', { page: $location.path() });
                }
            });
}]);

if (typeof Object.assign != 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, "assign", {
        value: function assign(target, varArgs) { // .length of function is 2
            'use strict';
            if (target == null) { // TypeError if undefined or null
                throw new TypeError('Cannot convert undefined or null to object');
            }

            var to = Object(target);

            for (var index = 1; index < arguments.length; index++) {
                var nextSource = arguments[index];

                if (nextSource != null) { // Skip over if undefined or null
                    for (var nextKey in nextSource) {
                        // Avoid bugs when hasOwnProperty is shadowed
                        if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                            to[nextKey] = nextSource[nextKey];
                        }
                    }
                }
            }
            return to;
        },
        writable: true,
        configurable: true
    });
}

function loadFoundationJavaScript(scope){
    //load foundation javascript
    scope.load = function() {
        $.getScript("scripts/all.js", function(){
        });
    };
    //don't forget to call the load function
    scope.load();

}

/** * Controls all other Pages */
app.controller('PageCtrl', function($scope, $routeParams) {

});

app.controller('SurveyThankYouPageCtrl', function($scope, $routeParams) {
    $scope.$root.headerAction = 'hidden';
    $scope.date = new Date();
    $scope.privacyPolicyUrl = privacy_policy_url;
});

app.animation('.demo-bar-graph', function() {
    return {
        enter: function(element, done) {
            //run the animation here and call done when the animation is complete
            //console.log("entering...");

            $('.demo-bar-graph__bar').each(function() {
                //console.log("bar graph");
                $(this).animate({
                    width: $(this).data('width')
                }, 0, function() {

                });
            });

            done();
            return function(cancelled) {
                //this (optional) function will be called when the animation
                //completes or when the animation is cancelled (the cancelled
                //flag will be set to true if cancelled).
            };
        },
        leave: function(element, done) {
            //run the animation here and call done when the animation is complete
            //console.log("leaving...");

            $('.demo-bar-graph__bar').each(function() {
                //console.log("bar graph");
                $(this).animate({
                    width: 0
                }, 0, function() {

                });
            });

            done();
            return function(cancelled) {
                //this (optional) function will be called when the animation
                //completes or when the animation is cancelled (the cancelled
                //flag will be set to true if cancelled).
            };
        },
        move: function(element, done) { },

        //animation that can be triggered before the class is added
        beforeAddClass: function(element, className, done) { },

        //animation that can be triggered after the class is added
        addClass: function(element, className, done) { },

        //animation that can be triggered before the class is removed
        beforeRemoveClass: function(element, className, done) { },

        //animation that can be triggered after the class is removed
        removeClass: function(element, className, done) { }
    };
});

app.animation('.graph-container', function() {
    return {
        enter: function(element, done) {
            //run the animation here and call done when the animation is complete

            var recurseElement = function(element)
            {
                if (element.className != undefined && element.className == 'bar-graph__bar')
                {

                    $(element).animate({
                        width: $(element).data('width')
                    }, 1000, function() {
                        var newWidth = $(element).width();
                        if ($(element).find('.bar-graph__num').width() > newWidth) {
                            $(element).addClass('short');
                            $(element).find('.bar-graph__num').css({
                                'color': '#333',
                                'margin-left': newWidth
                            });
                        }
                    });
                }
                else if (element.children.length > 0)
                {
                    for(var j=0; j<element.children.length; j++)
                    {
                        var childElement = element.children[j];
                        recurseElement(childElement);
                    }
                }
            };

            for(var i=0; i<element[0].children.length; i++) {
                var childElement = element[0].children[i];
                recurseElement(childElement);
            }

            function adjustSectionHeight(){
                var itemGraphs = $('.item-graph');
                $(itemGraphs).each(function (ig) {
                    var barGraphs = $(itemGraphs[ig]).find('.bar-graph');
                    var qHeights = [];
                    $(barGraphs).each(function (bg) {
                        var bgHeight = $(this).height();
                        var items = $(barGraphs[bg]).find('.small-4');

                        $(items).each(function (i) {
                            qHeights.push($(items[i]).height());
                        });
                        var additionalHeight = qHeights.sort().pop();
                        this.style.height = additionalHeight + 30;
                        this.style.overflow = 'hidden';
                    })

                })
            }

            adjustSectionHeight();

            done();
            return function(cancelled) {
                //this (optional) function will be called when the animation
                //completes or when the animation is cancelled (the cancelled
                //flag will be set to true if cancelled).
            };
        },
        leave: function(element, done) {
            //run the animation here and call done when the animation is complete
            //console.log("leaving...");

            /*$('.bar-graph__bar').each(function() {
                console.log("leaving bar graph");
                $(this).animate({
                    width: 0
                }, 0, function() {

                });
            });*/

            done();
            return function(cancelled) {
                //this (optional) function will be called when the animation
                //completes or when the animation is cancelled (the cancelled
                //flag will be set to true if cancelled).
            };
        },
        move: function(element, done) { },

        //animation that can be triggered before the class is added
        beforeAddClass: function(element, className, done) { },

        //animation that can be triggered after the class is added
        addClass: function(element, className, done) { },

        //animation that can be triggered before the class is removed
        beforeRemoveClass: function(element, className, done) { },

        //animation that can be triggered after the class is removed
        removeClass: function(element, className, done) { }
    };
});

app.filter("emptyToEnd", function () {
    return function (array, key) {
        if (!angular.isArray(array)) return;
        var present = array.filter(function (item) {
            return item[key];
        });
        var empty = array.filter(function (item) {
            return !item[key]
        });
        return present.concat(empty);
    };
});

app.filter('highlight', function($sce) {
    return function(text, phrase) {
        if (phrase && text) text = text.replace(new RegExp('('+phrase+')', 'gi'),
            '<h style="background-color:yellow">$1</h>')

        return $sce.trustAsHtml(text)
    }
});

app.filter('unique', function() {
    return function(collection, keyname) {
        var output = [], keys = [];

        collection.forEach(function(item) {
            var key = item[keyname];
            if (keys.indexOf(key) === -1) {
                keys.push(key);
                output.push(item);
            }
        });

        return output;
    }
})

app.filter('camelCase', function() {
    return function(input) {
      input = input || '';
      return input.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    };
});

contentCreatorAuth = function($q, userService) {
    var deferred = $q.defer();
    if (!userService.userIsCertifiedCreator()) {
        deferred.reject('unauthorized');
    } else {
        deferred.resolve();
    }
    return deferred.promise;
};
  
    
