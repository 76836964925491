app.factory('ActivityService', [ '$resource', function($resource){
    return $resource(
        '/echo-api/api/surveys',
        null,
        {
            'activitySummary' : {url: reports_api_hostname + '/api/activity/activitySummaryDetail', method: 'POST',
                                  transformResponse: function(data) { return { requestId: data } },
                                },
            // 'pollStatus' : {url: hostname + '/echo-api/api/activity/:requestId/request-status', params: {requestId: '@requestId'}, method: 'GET',
            //                   transformResponse: function(data) { return { status: angular.fromJson(data) } },
            //                },
            // 'getDataFromCache' : {url: hostname + '/echo-api/api/activity/:requestId/request-data', params: {requestId: '@requestId'}, method: 'GET'},
        }
    );
}
]);
