app.controller('BrowseReportsCtrl', 
    ['$scope',
     '$rootScope', 
     '$location', 
     '$timeout', 
     '$sessionStorage', 
     'applicationService', 
     'customerService', 
     'ReportService', 
     'reportPreferences',
     'userService',
    function($scope,
             $rootScope, 
             $location, 
             $timeout, 
             $sessionStorage, 
             applicationService, 
             customerService, 
             ReportService, 
             reportPreferences, 
             userService)
    {

        $scope.userId  = userService.getUser().id; // TODO: Roles Permissions - userId added to controller scope
        $scope.institutionId = applicationService.getInstitution().id;
        $scope.adminMode = userService.userIsLicenseOwnerAdmin();
        var userAssociation = userService.getUserCurrentAssociation();
        $scope.languageId = english_language_id; //default to English for now; this will change IF/WHEN the app has full multi-language support
        $scope.reportRequest = {};
        $scope.reportRequest.reportIds = []; //surveys that this user profile is limited to
        $scope.reportIdToReportPermissionMap = new Object(); //a Map of surveyId to permission object
        $rootScope.headerAction = 'active'
        $scope.reports = {};
        $scope.reportFilterTypes = reportFilterTypes;
        $scope.reportAllType = surveyAllType;
        $scope.selectedReportFilter = $scope.reportAllType;
        $scope.predicate = 'dateDisplay';
        $scope.reverse = true;
        var orgReports = {};
        $scope.reports_loading = true;


        //This uses the user profile to determine which reports the logged in user has access to
        $scope.getReportsForUserProfile = function(reset){

            if(typeof reset != "undefined" && reset == false) {
                $scope.reports = angular.copy(orgReports);
            } else {
                if(typeof userAssociation == "undefined" || typeof userAssociation.isDirectAssociation == "undefined" || userAssociation.isDirectAssociation) {

                    if ($scope.adminMode) //in admin mode, go directly against the surveys DB for reports that match logged in institutionId
                    {

                        ReportService.reportIdsForInstitution({userId: $scope.userId, institutionId: $scope.institutionId}).$promise.then(function (data) {
                            for (var ind = 0; ind < data.length; ind++) {
                                $scope.reportRequest.reportIds.push(data[ind]);
                            }
                            $scope.getReports($scope.selectedReportFilter);
                        }, function (httpResponse) {
                            var msg = "There was an error retrieving user profile reports."
                            $scope.reports_loading = false;
                            $scope.noReports = true;
                            $timeout(sweetAlert("The system encountered a problem, please try again.", msg), 500);
                        })
                    }
                    else {

                        ReportService.reportsForUserProfile({userId: $scope.userId, customerId: $scope.institutionId}).$promise.then(function (data) {
                            var dataSize = (data ? data.length : 0);
                            if (dataSize > 0) {
                                for (var ind = 0; ind < dataSize; ind++) {
                                    var reportData = data[ind];
                                    $scope.reportRequest.reportIds.push(reportData.objectId);
                                    $scope.reportIdToReportPermissionMap[reportData.objectId] = reportData;
                                }
                                $scope.getReports($scope.selectedReportFilter);
                            } else {
                                $scope.noReports = true;
                                $scope.reports_loading = false;
                            }


                        }, function (httpResponse) {
                            var msg = "There was an error retrieving user profile reports."
                            $scope.reports_loading = false;
                            $scope.noReports = true;
                            $timeout(sweetAlert("The system encountered a problem, please try again.", msg), 500);
                        })
                    }
                } else {
                    ReportService.getAllReportsByCustomer({customerId: $scope.institutionId}).$promise.then(function (data) {
                        var dataSize = (data ? data.length : 0);
                        if (dataSize > 0) {

                            var userPermissions = [];
                            userPermissions = userAssociation.permissions; // TODO: Roles Permissions - permissions accessed manually by controller and set on report objects. //get the permissions from constants.js
                            for (var ind = 0; ind < dataSize; ind++) {
                                var reportData = data[ind];
                                reportData['permissions'] = userPermissions;
                                $scope.reportRequest.reportIds.push(reportData.objectId);
                                $scope.reportIdToReportPermissionMap[reportData.objectId] = reportData;
                            }
                            $scope.getReports($scope.selectedReportFilter);
                        } else {

                            $scope.noReports = true;
                            $scope.reports_loading = false;
                        }
                    }, function (httpResponse) {
                        var msg = "There was an error retrieving user profile reports.";
                        $scope.reports_loading = false;
                        $scope.noReports = true;
                        $timeout(sweetAlert("The system encountered a problem, please try again.", msg), 500);
                    })
                }
            }
        }

        $scope.getReports = function(filter){
            $scope.selectedReportFilter = filter;

            if ($scope.reportRequest.reportIds.length > 0){
                var body = angular.copy($scope.reportRequest);
                body.userId = $scope.userId;
                body.institutionId = $scope.institutionId;
                body.languageId = $scope.languageId;
                body.filterType = filter;
                body.maxInstitutions = 2;
                ReportService.reports(body).$promise.then(function(reports) {
                    $scope.assignReportsProfileInfo(reports)
                    angular.forEach(reports, function(value, index){
                        var institutions = reports[index].reportInstitutions;
                        var institutionSize = reports[index].totalInstitutions;
                        var institutionDisplay = "";
                        if (institutionSize > 0)
                        {
                            institutionDisplay = "for ";
                            for (let i=0; i<institutions.length; i++)
                            {
                                if (i > 0)institutionDisplay += ", ";
                                institutionDisplay += institutions[i].institution.id;
                            }
                            if (institutionSize > institutions.length)
                            {
                                var remainingInstitutions = institutionSize - institutions.length;
                                institutionDisplay += " and ";
                                institutionDisplay += "<a class='surveys_link'>";
                                institutionDisplay += remainingInstitutions;
                                institutionDisplay += (remainingInstitutions > 1 ? " others" : " other");
                                institutionDisplay += "</a>";
                            }
                        }
                        reports[index].institutionDisplay = institutionDisplay;
                    });
                    $scope.reports = reports;
                    console.log(reports);
                    $sessionStorage.reports = reports;

                    $scope.selectedSurveyFilter = filter;

                    for(var i=0; i<$scope.reports.length; i++){
                        var date = new Date($scope.reports[i].dateDisplay);
                        $scope.reports[i].dateDisplaySearch = date.toDateString();
                    }

                    if(Object.keys($scope.reports).length === 0){
                        $scope.noReports = true;
                    } else {
                        $scope.noReports = false;
                    }

                },function(httpResponse){
                    $timeout(errorPopup, 500);
                    $scope.noReports = true;
                }).then(function(){
                    $scope.reports_loading = false;

                });



            }

        };


        //Helper method to parse through list of user profile reports and derive permissions for the browse reports activity menu
        $scope.assignReportsProfileInfo = function(reports)
        {
            if (reports)
            {
                var reportSize = reports.length;
                for (var ind = 0; ind < reportSize; ind++)
                {
                    var report = reports[ind];
                    var reportWithPermission = $scope.reportIdToReportPermissionMap[report.id];

                    report.permissions = reportWithPermission;  // TODO: Roles Permissions - permissions manipulated in controller for report (possibly ok)
                }
                $scope.setReportOwnerInformation(0, reports);
            }
        }

        $scope.setReportOwnerInformation = function(ind, reports)
        {
            if (ind < reports.length)
            {
                var report = reports[ind];
                ++ind;
                if (report.ownerId == $scope.userId) //if logged in user IS the report owner/creator
                {
                    var customerName = {};
                    report.createdUserDisplayName =  "Me";
                    if (!$scope.customerIdTocustomerMap) $scope.customerIdTocustomerMap = {}; //convenience Map to avoid making repeat calls for the same customerID
                    if (typeof $scope.customerIdTocustomerMap[report.institutionId] != "undefined"){
                        customerName = $scope.customerIdTocustomerMap[report.institutionId];
                    }else{
                        customerName = null;
                    }
                    if (customerName != null)
                    {
                        report.customerName = customerName.name;
                        $scope.setReportOwnerInformation(ind, reports);
                    }else {
                        customerService.getCustomerByIdResource(report.institutionId).$promise.then(function(customer){
                            customerName = customer;
                            $scope.customerIdTocustomerMap[report.institutionId] = customerName;
                            report.customerName = customerName.name;
                            $scope.setReportOwnerInformation(ind, reports);
                        }, function (httpResponse) {
                            $scope.setReportOwnerInformation(ind, reports);
                        })
                    }
                }
                else //fetch the owner's details
                {
                    var ownerUser = {};
                    var customerName = {};
                    if (!$scope.userIdToUserMap) $scope.userIdToUserMap = {}; //convenience Map to avoid making repeat calls for the same ownerUserID
                    if (!$scope.customerIdTocustomerMap) $scope.customerIdTocustomerMap = {}; //convenience Map to avoid making repeat calls for the same customerID
                    if (typeof $scope.userIdToUserMap[report.ownerId] != "undefined"){
                        ownerUser = $scope.userIdToUserMap[report.ownerId];
                    }else{
                        ownerUser = null;
                    }
                    if (ownerUser != null)
                    {
                        report.createdUserDisplayName = ownerUser.firstName + " " + ownerUser.lastName;
                        if (typeof $scope.customerIdTocustomerMap[report.institutionId] != "undefined"){
                            ownerUser.customerName = $scope.customerIdTocustomerMap[report.institutionId].name;
                            report.customerName = ownerUser.customerName ? ownerUser.customerName : "";
                            $scope.setReportOwnerInformation(ind, reports);
                        }else {
                            customerService.getCustomerByIdResource(report.institutionId).$promise.then(function (customer) {
                                customerName = customer;
                                $scope.customerIdTocustomerMap[report.institutionId] = customerName;
                                ownerUser.customerName = customerName.name;
                                report.customerName = customerName.name;
                                $scope.setReportOwnerInformation(ind, reports);
                            }, function (httpResponse) {
                                $scope.setReportOwnerInformation(ind, reports);
                            })
                        }
                    }
                    else
                    {
                        userService.getUserById(report.ownerId).$promise.then(function(user){
                            ownerUser = user;
                            $scope.userIdToUserMap[report.ownerId] = ownerUser;
                            report.createdUserDisplayName = ownerUser ? ownerUser.firstName + " " + ownerUser.lastName : "";
                            if (typeof $scope.customerIdTocustomerMap[report.institutionId] != "undefined"){
                                customerName = $scope.customerIdTocustomerMap[report.institutionId];
                            }else{
                                customerName = null;
                            }
                            if (customerName != null){
                                ownerUser.customerName = customerName.name;
                                report.customerName = customerName.name;
                                $scope.setReportOwnerInformation(ind, reports);
                            }else {
                                customerService.getCustomerByIdResource(report.institutionId).$promise.then(function (customer) {
                                    customerName = customer;
                                    $scope.customerIdTocustomerMap[report.institutionId] = customerName;
                                    ownerUser.customerName = customerName.name;
                                    report.customerName = customerName.name;
                                    $scope.setReportOwnerInformation(ind, reports);
                                }, function (httpResponse) {
                                    $scope.setReportOwnerInformation(ind, reports);
                                })
                            }
                        }, function (httpResponse) {
                            $scope.setReportOwnerInformation(ind, reports);
                        })
                    }
                }
            }else {
                orgReports = angular.copy(reports);
            }
        }

        //sorting on the surveys
        $scope.order = function($event, predicate, reverse)
        {
            $event.stopImmediatePropagation(); //done to avoid the all.js element from being triggered (DS code)
            if ($scope.predicate && ($scope.predicate == predicate))
            {
                $scope.reverse = reverse; //use the parameter value only if predicate is the same as before
            }
            else $scope.reverse = true; //if predicate switches, default to descending sort
            $scope.predicate = predicate;


        }

        $scope.resetReportPreferences = function(){
            reportPreferences.reset();
            if ($sessionStorage.reportPreferences)
            {
                $sessionStorage.reportPreferences = null;
                if ($sessionStorage.navDemographics) $sessionStorage.navDemographics = null;
            }
        }

        $scope.searchSelect = function(data) {
            $scope.searchToken = data.name;
            $scope.reports.length = 0;
            $scope.reports.push(data);
        };

        $scope.searchReports = function(keyword) {
            if (keyword.length == 1)
              return [];
            var str = ""
            //var x =  $filter('filter')($scope.reports , {'name': keyword});
            //return x;
            return _.filter($scope.reports, function(element){
                var check = false;
                for(var key in element){
                    if (key == "name" || key == "createdUserDisplayName" || key == "contentName" || key == "dateDisplaySearch") {
                        str = (element[key] + "").toLowerCase();
                        if (str.indexOf(keyword.toLowerCase()) != -1) {
                            check = true;
                            break;
                        }
                    }else {continue;}
                }
                if(check) return element;
            });
        }

        // $scope.viewLastGenerated = (report) => {
        //   applicationService.setRequestId(report.requestId);
        //   $location.path("view-report");
        // }
        $scope.viewLastGenerated = function(report) {
            $rootScope.cameFromSurveys = false;
          applicationService.setRequestId(report.requestId);
          $location.path("view-report");
        }

        //trigger the loading of reports based on user profile
        $scope.getReportsForUserProfile();

        //$(document).click(function(event){
        //    if(event.target.nextSibling){
        //        if(event.target.nextSibling.parentNode){
        //            var button = $(event.target.nextSibling.parentNode).hasClass('left-off-canvas-toggle');
        //            var ocw = $('.off-canvas-wrap');
        //            if(!button){
        //                if($(event.target).hasClass('left-off-canvas-span') === false){
        //                    if($(ocw).hasClass('move-right')){
        //                        $(ocw).removeClass('move-right');
        //                    }
        //                }
        //            }
        //        }
        //    }
        //});
    }
 ]);
