app.controller('TemplateConfigurationCtrl', 
    ['$sessionStorage',
     '$http', 
     '$scope',
     '$rootScope',
     'userService', 
     'ContentTemplateService', 
     '$location', 
     'modalService',
  function ($sessionStorage,
            $http, 
            $scope,
            $rootScope,
            userService, 
            ContentTemplateService, 
            $location, 
            modalService
      ) {

    $scope.onManageInstsClick = function() {
      var path = '/publish-content/';
      if ($scope.contentTemplate.administrationTemplate) {
        path = path + $scope.contentTemplate.administrationSurveyId;
        $location.search('type', 'admin');
        $location.search('id', $scope.contentTemplate.templateId);
        $location.search('owner', $scope.contentTemplate.institutionId);
      } else {
        path = path + $scope.contentTemplate.templateId;
        $location.search('type', 'nonadmin');
        if ($scope.institutionOwnerId) {
          $location.search('owner', $scope.contentTemplate.institutionId);
        } else {
          $location.search('owner', 'adEd');
        }
      }
      $location.path(path);
    };

    $scope.onManageParticipantsClick = function() {
        var path = '/assign-participants/';
        const institutionId = $scope.contentTemplate.institutionId;
        const surveyId = $scope.contentTemplate.administrationSurveyId;
        const templateId = $scope.contentTemplate.templateId;
        path += (institutionId.toString() + "/" + surveyId.toString() + "/" + templateId.toString());
        $location.path(path);
    };

    var defaultClearInstAlert = {
      title: '<i class="fa fa-exclamation-triangle" aria-hidden="true"></i><span>Remove Owner</span>',
      text: '<div class="top-text weighted-text">Are you sure you want to remove the owner?</div>' +
      '<div>If you select "Yes" all institutions connected with this owner will be discarded.</div>',
      html: true,
      confirmButtonText: 'Cancel',
      showCancelButton: true,
      cancelButtonText: 'Yes',
      customClass: 'template-administration-selection-modal orange-modal',
      allowEscapeKey: false
    };

    $scope.preCheckClearInstitutionOwner = function() {

      modalService.presentCustomModal(defaultClearInstAlert, null, function() {
          // Buttons are reversed - This is now Confirm
          var institution = {id: null};
          $scope.setAdminInstitutionOwner(institution);
      });
    };

    $scope.onAdEdOwnerCheckboxChange = function() {
      if (!$scope.templateOwnership.adEd) {
        preCheckClearAdEdInstOwner();
      } else if ($scope.institutionOwnerId) {
        preCheckClearInstitutionOwnerSpecial();
      } else {
        $scope.templateOwnership.adEd = true;
      }
    };

    function preCheckClearAdEdInstOwner() {
      modalService.presentCustomModal(defaultClearInstAlert,
      function() {
          // Buttons are reversed - This is now Cancel
          $scope.$apply(function() {
              $scope.templateOwnership.adEd = true;
          });
      }, function() {
          // Button are reversed - this is now Confirm
          var institution = {id: null};
          $scope.setAdminInstitutionOwner(institution);
          $scope.$apply(function() {
              $scope.templateOwnership.adEd = false;
          });
      });
    }

    function preCheckClearInstitutionOwnerSpecial() {
      modalService.presentCustomModal(defaultClearInstAlert,
      function() {
          // Buttons are reversed - This is now Cancel
          $scope.$apply(function() {
              $scope.templateOwnership.adEd = false;
          });
      }, function() {
          // Buttons are reversed - This is now Confirm
          var institution = {id: null};
          $scope.setAdminInstitutionOwner(institution);
          $scope.$apply(function() {
              $scope.templateOwnership.adEd = true;
          });
      });
    }

    //get authToken out of session storage
    $scope.accessToken = $sessionStorage.accessToken;

    $scope.enabledHideScore = function () {
      if ($scope.contentTemplate == undefined) {
        return false;
      }
      else {
        return $scope.contentTemplate.statusString == surveyDraftStatus;
      }
    };

    $scope.showSurveyType = function () {
      if ($scope.contentTemplate == undefined) {
        return false;
      }
      else {
        return $scope.contentTemplate.statusString == surveyDraftStatus;
      }
    };

    $scope.showTargetGroup = function () {
      if ($scope.contentTemplate == undefined) {
        return false;
      }
      else {
        return $scope.contentTemplate.statusString == surveyDraftStatus;
      }
    };

    $scope.setAdminInstitutionOwnerPreCheck = function(institution) {
      ContentTemplateService.checkCustomerSesConfiguration({institutionId: institution.id}).$promise.then(
        function(data) {
          if (!$scope.contentTemplate.administrationTemplate || data.isCustomerConfiguredForSes) {
            $scope.selectedInstitutionConfiguredForSes = true;
            $rootScope.$emit('templateOwnerConfigurationChanged', true);
            $scope.setAdminInstitutionOwner(institution);
          } else {
            modalService.presentCustomModal({
                title: '<i class="fa fa-exclamation-triangle" aria-hidden="true"></i><span>Configure Administration</span>',
                text: '<div class="top-text">The owner institution you selected is not configured for this administration. You must configure it before you can publish the template.</div>' +
                '<div class="weighted-text">Do you want to proceed?</div>',
                html: true,
                confirmButtonText: 'Cancel',
                showCancelButton: true,
                cancelButtonText: 'Yes',
                customClass: 'template-administration-selection-modal orange-modal',
                allowEscapeKey: false
            }, null, function () {
                $scope.selectedInstitutionConfiguredForSes = false;
                $rootScope.$emit('templateOwnerConfigurationChanged', false);
                $scope.setAdminInstitutionOwner(institution);
            });
          }
        },
        function(error) {
          sweetAlert("There was an error saving the institution owner. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
        }
      );
    };

    /****
     * API CALLS - calls to API endpoints
     ****/
    $scope.setAdminInstitutionOwner = function(institution) {
      var params = {templateId: $scope.contentTemplate.templateId};
      var body = {
        administrationSurveyId: $scope.contentTemplate.administrationSurveyId,
        ownerInstitutionId: institution.id
      };
      ContentTemplateService.updateAdminInstitutionOwner(params, body).$promise.then(
        function(data) {
          if (institution.name) {
            $scope.institutionOwnerName = institution.name;
            $scope.institutionOwnerId = institution.id;
            $scope.contentTemplate.institutionId = institution.id;
          } else {
            $scope.institutionOwnerName = null;
            $scope.institutionOwnerId = null;
            $scope.contentTemplate.institutionId = null;
          }
        },
        function(error) {
          sweetAlert("There was an error saving the institution owner. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
        }
      );
    };

    //save preference for showing section titles
    $scope.saveShowSectionTitles = function (showSectionTitles) {
      var body = {
        templateId: $scope.contentTemplate.templateId,
        userId: userService.getUserId(),
        showTitlesFlag: showSectionTitles
      };
      ContentTemplateService.updateTemplateShowTitlesFlag(body).$promise.then(
        function (data) {
          //success handler
        },
        function (error) {
          sweetAlert("There was an error saving the show titles flag. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
        }
      );
    };

    $scope.saveScoringSummaryFlag = function (hideScore) {
      var body = {
        templateId: $scope.contentTemplate.templateId,
        userId: userService.getUserId(),
        hideScore: hideScore
      };
      ContentTemplateService.updateTemplateHideScoreFlag(body).$promise.then(
        function (data) {
          //success handler
        },
        function (error) {
          sweetAlert("There was an error saving the hide score flag. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
        }
      );
    };

    $scope.saveAccreditationStatus = function () {
      var body = {
        templateId: $scope.contentTemplate.templateId,
        userId: userService.getUserId(),
        accreditation: $scope.contentTemplate.accreditation
      };
      ContentTemplateService.updateTemplateAccreditationStatus(body).$promise.then(
          function (data) {
            //success handler
          },
          function (error) {
            sweetAlert("There was an error saving the hide score flag. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
          }
      );
    };

    //save or delete template language
    $scope.updateTemplateLanguage = function (language) {
      var body = {
        languageId: language.id,
        templateId: $scope.contentTemplate.templateId,
        userId: userService.getUserId()
      };
      if (language.selected) {
        ContentTemplateService.saveTemplateLanguage(body).$promise.then(
          function (data) {

          },
          function (error) {
            sweetAlert("There was an error saving the language. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
          }
        );
      } else {
        $http({
          method: 'DELETE',
          url: hostname + '/echo-api/api/config/templates/languages',
          data: body,
          headers: {'Content-Type': 'application/json;charset=utf-8', 'authorization': $scope.accessToken}
        }).then(
          function (data) {
            // success handler
          },
          function (error) {
            sweetAlert("There was an error deleting the language. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
          }
        );
      }
    };

    //save template display name
    $scope.saveTemplateDisplayName = function () {
      var body = {
        languageId: $scope.contentTemplate.languageId,
        templateId: $scope.contentTemplate.templateId,
        displayName: $scope.contentTemplate.displayName,
        userId: userService.getUserId()
      };
      ContentTemplateService.updateTemplateDisplayName(body).$promise.then(
        function (data) {
          // success handler
        },
        function (error) {
          sweetAlert("There was an error saving the template display name. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
        }
      );
    };

    //save template description
    $scope.saveTemplateDescription = function () {
      var body = {
        languageId: $scope.contentTemplate.languageId,
        templateId: $scope.contentTemplate.templateId,
        description: $scope.contentTemplate.description,
        userId: userService.getUserId()
      };
      ContentTemplateService.updateTemplateDescription(body).$promise.then(
        function (data) {
          // success handler
        },
        function (error) {
          sweetAlert("There was an error saving the template description. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
        }
      );
    };

    //save template redirect link
    $scope.saveTemplateRedirectLink = function () {
      var body = {
        templateId: $scope.contentTemplate.templateId,
        userId: userService.getUserId(),
        redirectLink: $scope.contentTemplate.redirectLink
      };
      ContentTemplateService.updateTemplateRedirectLink(body).$promise.then(
        function (data) {
        },
        function (error) {
          sweetAlert("There was an error saving the template redirect link. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
        }
      );
    };


    //save template end action
    $scope.saveTemplateEndAction = function (action) {
      var body = {
        templateId: $scope.contentTemplate.templateId,
        userId: userService.getUserId(),
        endAction: action
      };
      ContentTemplateService.updateTemplateEndAction(body).$promise.then(
        function (data) {
          $scope.contentTemplate.endAction = action;
        },
        function (error) {
          sweetAlert("There was an error saving the template reset action. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
        }
      );
    };

    $scope.updateTemplateType = function (surveyType) {
      console.log("--> updateTemplateType selectedSurveyType = " + $scope.contentTemplate.selectedSurveyType.name);
      var templateUpdateDTO = {
        action: "UPDATE_TEMPLATE_TYPE",
        templateId: $scope.contentTemplate.templateId,
        templateTypeId: $scope.contentTemplate.selectedSurveyType.id,
        userId: userService.getUserId()
      };
      ContentTemplateService.updateTemplateElement(templateUpdateDTO).$promise.then(
        function (response) {
        },
        function (error) {
          sweetAlert("There was an error updating the survey type. This is most likely a problem connecting to the server. Please try again later.", error, "error");
        }
      );
    };

    /****
     * (END) API CALLS
     ****/
  }
]);
