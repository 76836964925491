app.directive('institutionOwnerSelector', 
    ['customerService',
     'userService', 
     'collectionUtils',
     
  function (customerService, 
            userService, 
            collectionUtils
      ) {

    return {
      scope: true,
      replace: true,
      restrict: 'E',
      templateUrl: 'partials/templates/institution-owner-selector.html',
      link: function (scope, element, attrs) {
        var SEARCH_SIZE = 15;

        var userAssociationsIds = scope.userAssociationsIds;
        var userAssociationsIdsStr = collectionUtils.convertToStrings(userAssociationsIds);

        scope.superUser = userService.getIsSuperUser();

        scope.handleInstitutionSelection = function (institution) {
          scope.setAdminInstitutionOwnerPreCheck(institution);
        };

        var getInstitutions = function () {
          customerService.getCustomersWithChildStatus(userAssociationsIds, getCustomersSuccess, null);
        };

        var getCustomersSuccess = function (customersInfo) {
          scope.institutions = customersInfo;

          if (scope.expandTopLevel && scope.institutions) {
            scope.institutions.forEach(function (institution) {
              if (scope.hasChildren(institution)) {
                scope.toggleExpand(institution);
              }
            });
          }
        };

        scope.searchSize = SEARCH_SIZE;

        scope.hasChildren = function (institution) {
          if (institution.childCount > 0) {
            return true;
          }
          return false;
        }

        scope.clearSearch = function () {
          scope.filterToken = null;
          scope.institutionOptions = [];
        }


        scope.getCheckboxId = function (institution) {
          if (scope.canSelectMultiple) {
            return institution.id;
          }
          return null;
        }


        scope.getIconChevron = function (institution) {
          if (institution.expand) {
            return 'icon-chevron-down';
          }
          return 'icon-chevron-right';
        }

        scope.getIconTip = function (institution) {
          if (institution.expand) {
            return 'collapse';
          }
          return 'expand';
        }

        scope.toggleExpand = function (institution) {
          institution.expand = !institution.expand;
          if (institution.expand && !institution.children) {
            customerService.getMinCustomerChildrenInfoResource([institution.id])
              .$promise.then(function (response) {
              institution.children = response;
            }).catch(function error(error) {

            });
          }
        }

        scope.updateInstitutionOptions = function (token) {
          scope.institutionOptions = [];
          if (token) {
            if (!scope.superUser) {
              var searchParams = {
                likeName: token,
                customerIds: userAssociationsIdsStr,
                from: 0,
                size: SEARCH_SIZE,
                customerStatus: 'All'
              };

              if (scope.selectOnlySchool) {
                searchParams.customerType = customerService.getSchoolType();
              }

              customerService.searchInstitutionsForCustomer(searchParams, searchSuccess, null);
            } else {
              var searchParams = {
                likeName: token,
                from: 0,
                size: SEARCH_SIZE,
                customerStatus: 'All'
              };
              customerService.searchInstitutionsForSuperUser(searchParams, searchSuccess, null);
            }
          }
        }

        var searchSuccess = function (institutions) {
          for(var i=0; i<institutions.length; i++) {
            institutions[i].customerActiveFlag = institutions[i].customerActiveFlag === 'true';
          }
          scope.institutionOptions = institutions;
        }

        scope.isSelectable = function (institution) {
          return true;
        }

        scope.isEmpty = function (list) {
          return collectionUtils.listIsEmpty(list);
        }
      }
    }
  }]);