app.factory( 'participantService', ['applicationService', '$rootScope', '$resource','$http','$location', '$timeout', '$sessionStorage', 'modalService', '$routeParams',
    function(applicationService, $rootScope, $resource, $http, $location, $timeout, $sessionStorage, modalService, $routeParams){
        return new ParticipantService(applicationService, $rootScope, $resource, $http, $location, $timeout, $sessionStorage, modalService, $routeParams);
    }]);

function ParticipantService(applicationService, $rootScope, resourceService, $http, locationService, $timeout, $sessionStorage, modalService, $routeParams) {

    /** FILTERING ENDPOINTS */
    const AVAILABLE_PARTICIPANTS_URL = '/echo-api/api/roster/participants/available/:institutionId';
    const FILTERED_PARTICIPANTS_URL = '/echo-api/api/roster/participants/filtered/:surveyId';
    const FILTER_CRITERIA_URL = '/echo-api/api/roster/criteria/:institutionId';
    const FILTER_URL = '/echo-api/api/roster/filters/:surveyId';
    const APPLY_FILTER_URL = '/echo-api/api/roster/apply/:institutionId';
    const UPDATE_FILTER_URL = '/echo-api/api/roster/update/:surveyID';
    const REFRESH_FILTER_URL = '/echo-api/api/roster/refresh/:surveyID';
    const ASSIGN_PARTICIPANTS_URL = '/echo-api/api/roster/assign';
    const VALIDATE_STUDENT_URL = '/echo-api/api/roster/validate-students';
    const DOWNLOAD_REPORT_URL = '/api/download/reports/ses/:reportCacheIdToken';
    const DOWNLOAD_SES_REPORT_URL = '/api/download/reports/sesPdf/:reportCacheIdToken';
    const SES_REPORT_STATUS_URL = '/api/download/reports/sesPdfIsReady/:reportCacheIdToken';
    const CHANGE_ROSTER_URL = '/api/upload/roster';
    const NTS_UPLOAD_URL = '/api/upload/nts';


    /** REPORTING ENDPOINTS */
        // FETCHES TOTAL PARTICIPANT REPORT
    var DOWNLOAD_ALL_URL = '/api/reports/generateTotalParticipants';
    // FETCHES FILTERED PARTICIPANT REPORT
    var DOWNLOAD_FILTERED_URL = '/api/reports/generateFilteredParticipants';
    // FETCHES ROSTERING REPORT
    var DOWNLOAD_ROSTER_URL = '/api/reports/generateRoster';
    // FETCHES STATUS MONITORING REPORT
    var DOWNLOAD_MONITORING_URL = '/api/reports/generateMonitoring';
    var DOWNLOAD_SUMMARY_RESULTS_URL = '/api/reports/requestSesPDF';
    /**
     * Maintains a map of report names
     * @type {{downloadAll: string, downloadFiltered: string, downloadStatusMonitoring: string, downloadRoster: string}}
     */
    const reportNames = {
        downloadAll: "Total Participant Report",
        downloadFiltered: "Filtered Participant Report",
        downloadStatusMonitoring: "Status Monitoring Report",
        downloadRoster: "Roster Report"
    };


    /**
     *
     * @param institutionId - The id of the institution that owns the survey
     * @param surveyId - The id of the survey for which the report is being requested
     * @param reportName
     * @param filterString
     * @returns {{institutionId: *, surveyId: *, languageId: number, reportId: null, reportPreferences: {reportName: *, surveyContentTypeCertified: boolean}}}
     */
    const generateReportRequestObject = function(institutionId, applicationId, surveyId, reportName) {
        let requestObj = {
            surveyId: surveyId,
            applicationId: applicationId,
            languageId: 1,
            reportPreferences: {
                reportName: reportName,
                surveyContentTypeCertified: true
            },
            institutionId: institutionId
        };
        return requestObj;
    };


    /**
     * This fetches the count of ALL students available for a given institution or any child institution
     * @param institutionId - The ID of the root institution to start finding users from
     * @param successCallback
     * @param failureCallback
     */
    this.getAvailableParticipants = function(institutionId, successCallback, failureCallback) {
        const url = hostname + AVAILABLE_PARTICIPANTS_URL;
        const urlResource = resourceService(url, {}, {
            getAvailableParticipants: {
                method: 'GET',
                params: {institutionId: '@institutionId'}
            }
        });
        urlResource.getAvailableParticipants({
            institutionId: institutionId
        }).$promise.then(function(response){
            if(!response.institutionId || response.availableParticpants < 0){
                failureCallback("Couldn't determine available participant count. Likely a bad institution ID");
            } else {
                successCallback(response);
            }
        }).catch(function(error){
            handleError(error, failureCallback);
        });
    };

    /**
     * This fetches the count of FILTERED students for a given institution or any child institution after filters have been applied
     * @param institutionId - The ID of the root institution to start finding users from
     * @param successCallback
     * @param failureCallback
     */
    this.getFilteredParticipants = function(surveyId, successCallback, failureCallback) {
        const url = hostname + FILTERED_PARTICIPANTS_URL;
        const urlResource = resourceService(url, {}, {
            getFilteredParticipants: {
                method: 'GET',
                params: {surveyId: '@surveyId'}
            }
        });
        urlResource.getFilteredParticipants({
            surveyId: surveyId
        }).$promise.then(function(response){
            if(response.filteredParticipants < 0){
                failureCallback("Couldn't determine filtered participant count. Likely a bad institution ID");
            } else {
                successCallback(response);
            }
        }).catch(function(error){
            handleError(error, failureCallback);
        });
    };

    /**
     * Fetches the available filter criteria for a given institution
     * @param institutionId
     * @param successCallback
     * @param failureCallback
     */
    this.getFilterCriteria = function(institutionId, successCallback, failureCallback) {
        const url = hostname + FILTER_CRITERIA_URL;
        const urlResource = resourceService(url, {}, {
            getFilterCriteria: {
                method: 'GET',
                params: {institutionId: '@institutionId'}
            }
        });
        urlResource.getFilterCriteria({
            institutionId: institutionId
        }).$promise.then(function(response){
            if(!response.institutionId || !response.criteria) {
                failureCallback("Couldn't find expected data in response object");
            } else {
                successCallback(response);
            }
        }).catch(function(error){
            handleError(error, failureCallback);
        });
    };

    /**
     * Will fetch the existing filters for a given institution / template
     * @param surveyId - The ID for the survey for which filters should be fetched
     * @param successCallback
     * @param failureCallback
     */
    this.getFilters = function(surveyId, successCallback, failureCallback) {
        const url = hostname + FILTER_URL;
        const urlResource = resourceService(url, {}, {
            getFilters: {
                method: "GET",
                params: {
                    surveyId : '@surveyId'
                }
            }
        });
        urlResource.getFilters({
            surveyId: surveyId
        }).$promise.then(function(response){
            successCallback(response);
        }).catch(function (error) {
            handleError(error, failureCallback);
        });
    };

    /**
     * Will post up the filter data to save and attach to the template
     * @param institutionId - The id of the institution that owns the template
     * @param surveyId - The id of the survey for which filters are saved
     * @param filters - The filters that should be saved
     * @param successCallback
     * @param failureCallback
     */
    this.applyFilters = function(institutionId, surveyId, filters, successCallback, failureCallback) {
        const url = hostname + APPLY_FILTER_URL;
        const urlResource = resourceService(url, {}, {
            applyFilters: {
                method: "POST",
                params: {
                    institutionId: '@institutionId',
                },
                body: {
                    institutionId: institutionId,
                    surveyId: surveyId,
                    filters: filters
                }
            }
        });
        urlResource.applyFilters({
            institutionId: institutionId,
            surveyId: surveyId,
            filters: filters
        }).$promise.then(function(response){
            successCallback(response);
        }).catch(function(error){
            handleError(error, failureCallback);
        });
    };

    /**
     * Will update all pending student <-> survey links to active
     * @param institutionId
     * @param surveyId
     * @param successCallback
     * @param failureCallback
     */
    this.assignFilters = function(institutionId, surveyId, successCallback, failureCallback) {
        const url = hostname + ASSIGN_PARTICIPANTS_URL;
        const urlResource = resourceService(url, {}, {
            assignFilters: {
                method: "POST",
                body: {
                    institutionId: institutionId,
                    surveyId: surveyId
                }
            }
        });
        urlResource.assignFilters({
            institutionId: institutionId,
            surveyId: surveyId
        }).$promise.then(function(response){
            successCallback(response);
        }).catch(function(error){
            handleError(error, failureCallback);
        });
    };

    /**
     * This function will update the filters record for a particular survey
     * Note: this will NOT create a new records and must have a survey id to reference the existing filters record that needs updating.
     * @param institutionId
     * @param surveyId
     * @param filters
     * @param successCallback
     * @param failureCallback
     */
    this.updateFilters = function(institutionId, surveyId, filters, successCallback, failureCallback) {
        const url = hostname + UPDATE_FILTER_URL;
        const urlResource = resourceService(url, {}, {
            updateFilters: {
                method: "POST",
                params: {
                    surveyId: '@surveyId',
                },
                body: {
                    institutionId: institutionId,
                    surveyId: surveyId,
                    filters: filters
                }
            }
        });
        urlResource.updateFilters({
            institution: institutionId,
            surveyId: surveyId,
            filters: filters
        }).$promise.then(function(response) {
            successCallback(response);
        }).catch(function(error){
            handleError(error, failureCallback);
        });
    };

    /**
     * This function will force the backend to refresh the student list with the existing filters
     * Note: This will delete existing student <-> survey links and create new ones
     * @param institutionId
     * @param surveyId
     * @param successCallback
     * @param failureCallback
     */
    this.refreshFilters = function(institutionId, surveyId, successCallback, failureCallback) {
        const url = hostname + REFRESH_FILTER_URL;
        const urlResource = resourceService(url, {}, {
            refreshFilters: {
                method: "POST",
                params: {
                    surveyId: '@surveyId'
                },
                body: {
                    institutionId: institutionId,
                    surveyId: surveyId
                }
            }
        });
        urlResource.refreshFilters({
            institutionId: institutionId,
            surveyId: surveyId
        }).$promise.then(function(response){
            successCallback(response);
        }).catch(function(error){
            handleError(error, failureCallback);
        })
    };


    /**
     * This will validate that an array of StudentID strings exist within a given institution or any child institution
     * @param institutionId - The ID of the root institution from which users will be matched against
     * @param studentIds - An Array of Strings, one for each Student ID that needs to be validated
     * @param successCallback
     * @param failureCallback
     * @param callbackData - This is extra data that is needed for the front-end to know when row to update after the execution is finished
     */
    this.validateStudentIds = function(institutionId, studentIds, successCallback, failureCallback, callbackData) {
        const url = hostname + VALIDATE_STUDENT_URL;
        const urlResource = resourceService(url, {}, {
            validateStudents: {
                method: "POST",
                params: {},
                body: {
                    institutionId: institutionId,
                    studentIds: studentIds
                }
            }
        });
        urlResource.validateStudents({
            institutionId: institutionId,
            studentIds: studentIds
        }).$promise.then(function(response) {
            if(callbackData) {
                // Note: the extra callback data here only needs to be passed to the success handler where the original row will be updated
                successCallback(response, callbackData);
            }else {
                successCallback(response)
            }
        }).catch(function(error){
            handleError(error, failureCallback);
        })
    };

    /**
     * This will create a CSV report containing information about ALL students linked to the given institution hierarchy
     * Note: This does not return anything to the client
     * @param institutionId - The ID of the institution that the survey is attached to
     * @param successCallback
     * @param failureCallback
     */
    this.downloadAll = function(institutionId, applicationId, surveyId, successCallback, failureCallback) {
        const url = reports_api_hostname + DOWNLOAD_ALL_URL;
        const urlResource = resourceService(url, {}, {
            downloadAll: {
                method: "POST",
                params: {},
                body: generateReportRequestObject(institutionId, applicationId, surveyId, reportNames.downloadAll)
            }
        });
        urlResource.downloadAll(generateReportRequestObject(institutionId, applicationId, surveyId, reportNames.downloadAll)
        ).$promise.then(function(response){
            successCallback(response);
        }).catch(function(error){
            failureCallback(error);
        });
    };

    this.convertFiltersToParamString = function(filters) {
        let paramString = "";
        filters.forEach(function(filterX){
            filterX.valueList.forEach(function(valueX){
                paramString += (filterX.criterion + ",");
                paramString += (valueX + ",");
                paramString += ((filterX.operator === "Includes" ? "inc" : "exc") + ";");
            })
        });
        return paramString;
    };

    /**
     * This will create a CSV report containing information about FILTERED students linked to the given institution hierarchy
     * Note: This does not return anything to the client
     * @param surveyId - The ID of the survey
     * @param successCallback
     * @param failureCallback
     */
    this.downloadFiltered = function(institutionId, applicationId, surveyId, filtersObj, successCallback, failureCallback) {
        const url = reports_api_hostname + DOWNLOAD_FILTERED_URL;
        const urlResource = resourceService(url, {}, {
            downloadFiltered: {
                method: "POST",
                params: {},
                body: generateReportRequestObject(institutionId, applicationId, surveyId, reportNames.downloadFiltered)
            }
        });
        urlResource.downloadFiltered(generateReportRequestObject(institutionId, applicationId, surveyId, reportNames.downloadFiltered)
        ).$promise.then(function(response){
            successCallback(response);
        }).catch(function(error){
            failureCallback(error);
        });
    };

    /**
     * This will create a CSV report containing information about the student Roster linked to a given institution hierarchy
     * Note: This does not return anything back to hte client
     * @param institutionId - The ID of the institution for which the roster is linked
     * @param surveyId - The ID of the survey for which the roster is bring requested
     * @param successCallback
     * @param failureCallback
     */
    this.downloadRoster = function(institutionId, applicationId, surveyId, successCallback, failureCallback) {
        const url = reports_api_hostname + DOWNLOAD_ROSTER_URL;
        const urlResource = resourceService(url, {}, {
            downloadRoster: {
                method: "POST",
                params: {},
                body: generateReportRequestObject(institutionId, applicationId, surveyId, reportNames.downloadRoster)
            }
        });
        urlResource.downloadRoster(generateReportRequestObject(institutionId, applicationId, surveyId, reportNames.downloadRoster)
        ).$promise.then(function(response){
            successCallback(response);
        }).catch(function(error){
            failureCallback(error);
        });
    };

    /**
     * This will create a CSV report containing information about the status monitor data for a given institution & survey combination
     * @param institutionId - The ID of the institution for which the status monitoring report is covering
     * @param surveyId - The ID of the survey for which the status monitoring report is covering
     * @param successCallback
     * @param failureCallback
     */
    this.downloadStatusMonitoring = function(institutionId, applicationId, surveyId, successCallback, failureCallback) {
        const url = reports_api_hostname + DOWNLOAD_MONITORING_URL;
        const urlResource = resourceService(url, {}, {
            statusMonitoringReport: {
                method: "POST",
                params: {},
                body: generateReportRequestObject(institutionId, applicationId, surveyId, reportNames.downloadStatusMonitoring)
            }
        });
        urlResource.statusMonitoringReport(generateReportRequestObject(institutionId,  applicationId, surveyId, reportNames.downloadStatusMonitoring)
        ).$promise.then(function(response){
            successCallback(response);
        }).catch(function(error){
            failureCallback(error);
        });
    };

    this.downloadSummaryResults = function(institutionId, applicationId, surveyId, successCallback, failureCallback) {
        const url = reports_api_hostname + DOWNLOAD_SUMMARY_RESULTS_URL;
        const urlResource = resourceService(url, {}, {
            downloadSummaryReport: {
                method: "POST",
                params: {},
                body: {"customerNumber": applicationService.getInstitution().customerNumber, "customerId":institutionId, "surveyId":surveyId}
            }
        });
        urlResource.downloadSummaryReport({"customerNumber": applicationService.getInstitution().customerNumber, "customerId":institutionId, "surveyId":surveyId}
        ).$promise.then(function(response){
            successCallback(response);
        }).catch(function(error){
            failureCallback(error);
        });
    };

    this.downloadCompleteResults = function(url) {
        this.downloadUrl(url);
    };


    this.submitChangeRosterRequest = function(surveyId, fileName, customerId, comments, displayName, file, isNTSUpload, successCallback, failureCallback) {
        const url = reports_api_hostname + (!isNTSUpload ? CHANGE_ROSTER_URL : NTS_UPLOAD_URL);
        var fd = new FormData();
        fd.append('file', file);
        fd.append('info', JSON.stringify({
            surveyId: surveyId,
            fileName: fileName,
            customerId: customerId,
            comments: comments,
            displayName: displayName
        }));
        $http.post(url, fd, {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined,
                'Authorization': getToken($routeParams,$sessionStorage)}
        }).then(function(response){
            if(successCallback) {
                successCallback();
            }
        }).catch(function(error){
            if (error.data != null) {
                console.error("Submit Roster Change Request ERROR", error.data.message);
            }
            if(failureCallback) {
                failureCallback(error);
            }
        });
    };

    /**
     *
     */
    this.presentReportDownloadModal = function(documentReference,isSesReport) {
        const that = this;
        modalService.presentCustomModal({
            title: '<i class="fa fa-download"></i><span>Download Report</span>',
            text: '<div class="center-text">Your report is ready to Download</div>',
            html: true,
            confirmButtonText: 'Click here to view Download',
            confirmButtonColor: '#148900',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            cancelButtonColor: '#D0D0D0',
            customClass: 'report-download-modal',
            allowEscapeKey: true
        }, function() {
            if ($rootScope.reportName === change_roster_error) {
                that.downloadRosterChangeError(documentReference)
            }
            else {
                that.downloadReport(documentReference, isSesReport);
            }
        });

    };

    this.downloadRosterChangeError = function(rosterChangeErrorFile) {
        console.log('--- rosterChangeErrorFile: ' + rosterChangeErrorFile);

        window.open(rosterChangeErrorFile, "_self");
    }

    this.presentReportRequestSuccessModal = function() {
        modalService.presentCustomModal({
            title: 'Report Creation',
            text: '<div class="center-text"><p><i class="fa fa-check-circle green-check" aria-hidden="true"></i>Thank you! - Once your report is ready we will email you a link.</p></div>',
            html: true,
            confirmButtonText: 'Close',
            confirmButtonColor: '#FFF',
            showCancelButton: false,
            customClass: 'report-request-success-modal',
            className: 'report-request-success-modal',
            allowEscapeKey: true
        });
    };

    this.presentSesReportRequestFailureModal = function() {
        modalService.presentCustomModal({
            title: "<i class=\"fa fa-download\"></i> Download Report",
            type: 'error',
            html: true,
            text: "Your report is no longer available.",
            customClass: 'calculateParticipantsAlert',
            confirmButtonColor: '#148900',
            confirmButtonText: 'Close',
            showCancelButton: false
        });
    };

    this.presentReportRequestFailureModal = function() {
        modalService.presentCustomModal({
            title: "<i class=\"fa fa-exclamation-triangle\"></i> Error",
            type: 'error',
            html: true,
            text: "Could not download report. Please try again or contact customer support.",
            customClass: 'calculateParticipantsAlert',
            confirmButtonColor: '#148900',
            confirmButtonText: 'OK',
            showCancelButton: false
        });
    };


    function saveAs(file, uri, filename) {
        var link = document.createElement('a');
        if (window.navigator.appVersion.toString().indexOf('.NET') > -1 || window.navigator.appVersion.toString().indexOf('Edge') > -1 ) { // for IE browser
            window.navigator.msSaveBlob(file, filename);
        } else if (typeof link.download === 'string') {
            link.href = uri;
            link.download = filename;
            $timeout(function() {
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
        } else {
            window.open(uri);
        }
    }

    this.sesPdfIsReady = function(documentReference) {
        var that = this;
        const url = reports_api_hostname + SES_REPORT_STATUS_URL;
        const token = $sessionStorage.accessToken;
        const urlResource = resourceService(url, {}, {
            sesPdfIsReady: {
                method: 'GET',
                params: {reportCacheIdToken: '@reportCacheIdToken'}
            }
        });
        urlResource.sesPdfIsReady({
            reportCacheIdToken: documentReference
        }).$promise.then(function(response){
            // Since we got back a standard response code assume success
            that.presentReportDownloadModal(documentReference,true);
        }).catch(function(error){
            if(error.status === 410 || error.status === 403){
                modalService.presentCustomModal({
                    title: "<i class=\"fa fa-download\"></i> Download Report",
                    type: 'error',
                    html: true,
                    text: "Your report is no longer available.",
                    customClass: 'calculateParticipantsAlert',
                    confirmButtonColor: '#148900',
                    confirmButtonText: 'Close',
                    showCancelButton: false
                }, function() {
                    locationService.path('/signin');
                });
            }else {
                modalService.presentCustomModal({
                    title: "<i class=\"fa fa-exclamation-triangle\"></i> Error",
                    type: 'error',
                    html: true,
                    text: "Could not download report. Please try again or contact customer support.",
                    customClass: 'calculateParticipantsAlert',
                    confirmButtonColor: '#148900',
                    confirmButtonText: 'OK',
                    showCancelButton: false
                });
            }
        });
    };

    this.downloadReport = function(documentReference,isSesReport) {
        var url = reports_api_hostname + DOWNLOAD_REPORT_URL;
        if(isSesReport){
            url = reports_api_hostname + DOWNLOAD_SES_REPORT_URL;
        }
        const token = $sessionStorage.accessToken;
        const urlResource = resourceService(url, {}, {
            downloadReport: {
                method: 'GET',
                params: {reportCacheIdToken: '@reportCacheIdToken'},
                responseType: "arraybuffer",
                transformResponse: function(data, headersGetter) {
                    return { data : data, headers : headersGetter }
                }
            }
        });
        urlResource.downloadReport({
            reportCacheIdToken: documentReference
        }).$promise.then(function(response){
            const contentDisposition = response.headers('Content-Disposition');
            const extractionRegex = /.*filename=\"(.*)\".*/;
            const extracted = extractionRegex.exec(contentDisposition);
            const filename = extracted[1];
            const file = new Blob([response.data], {type: 'text/csv'});
            const fileURL = URL.createObjectURL(file);
            saveAs(file, fileURL, filename);
        }).catch(function(error){
            modalService.presentCustomModal({
                title: "<i class=\"fa fa-exclamation-triangle\"></i> Error",
                type: 'error',
                html: true,
                text: "Could not download report. Please try again or contact customer support.",
                customClass: 'calculateParticipantsAlert',
                confirmButtonColor: '#148900',
                confirmButtonText: 'OK',
                showCancelButton: false
            });
        });
    };

    this.downloadUrl = function(url, type) {
        const token = $sessionStorage.accessToken;
        const responseType = type || "arraybuffer";
        const urlResource = resourceService(url, {}, {
            downloadReport: {
                method: 'GET',
                responseType: responseType,
                transformResponse: function(data, headersGetter) {
                    return { data : data, headers : headersGetter }
                }
            }
        });
        urlResource.downloadReport().$promise.then(function(response){
            const contentDisposition = response.headers('Content-Disposition');
            const extractionRegex = /.*filename=\"(.*)\".*/;
            const extracted = extractionRegex.exec(contentDisposition);
            const filename = extracted[1];
            const file = new Blob([response.data], {type: 'text/csv'});
            const fileURL = URL.createObjectURL(file);
            saveAs(file, fileURL, filename);
        }).catch(function(error){
            modalService.presentCustomModal({
                title: "<i class=\"fa fa-exclamation-triangle\"></i> Error",
                type: 'error',
                html: true,
                text: "Could not download report. Please try again or contact customer support.",
                customClass: 'calculateParticipantsAlert',
                confirmButtonColor: '#148900',
                confirmButtonText: 'OK',
                showCancelButton: false
            });
        });
    }


    // Generic error handler
    var handleError = function(error, errorHandler){
        if(errorHandler){
            if(error.message){
                errorHandler(error.message);
            }
            else if(error.data){
                errorHandler(error.data.error);
            }
            else if(error.statusText){
                errorHandler(error.statusText);
            }
        }
        else {
            $timeout(errorPopup(error.message), 500);
        }
    }

    // Generic error handler wrapper
    this.handleAPIError = function(error, errorHandler){
        handleError(error, errorHandler);
    }
}
