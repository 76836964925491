
var TOKEN_STATUS_ENABLED = 1;
var TOKEN_STATUS_DISABLED = 2;
var TOKEN_STATUS_SUBMITTED = 3;

app.controller('TokenController', 
    ['$scope',
     '$routeParams', 
     '$rootScope', 
     '$location', 
     'applicationService', 
     'userService', 
     'TokenService', 
     'SurveyCreateService',
function($scope,
         $routeParams, 
         $rootScope, 
         $location, 
         applicationService, 
         userService, 
         TokenService, 
         SurveyCreateService
    ) {
        

        $scope.init = function () {
            $rootScope.headerAction = 'hidden';

            $scope.loading = true;
            $scope.searching = false;
            $scope.searchValue = "";
            $scope.searchedValue = "";
            $scope.languageId = 1; //English
            $scope.predicate = 'tokenValue';
            $scope.reverse = false;

            $scope.isEditable = false;

            $scope.tokensMaxCount = ((applicationService.getInstitution() != null && applicationService.getInstitution().type !== "school")
                ? token_manage_options.maxTokensForTheRest : token_manage_options.maxTokensForSchool);
            $scope.tokensRemainingCount = null;

            
            if ($routeParams.surveyId !== undefined) {
                var params = { surveyId: $routeParams.surveyId, languageId: $scope.languageId };
                SurveyCreateService.edit(params)
                    .$promise.then(function(data) {
                        

                        // save survey information to the scope
                        $scope.populateSurveyInformation(data);
                    }, function(error) {
                        
                        sweetAlert("Fetching the Survey Content was unsuccessful. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
                    });
            } else {
                // error, need a surveyId
                //   send the user to a safe place
                
                sweetAlert("", null, "error");
            }
        };

        // save survey information to the scope
        //   , survey information includes whether the current survey requires tokens
        //   , route accordingly
        $scope.populateSurveyInformation = function(data) {
            $scope.survey = data;
            $scope.surveyLoaded = true;

            if ($location.url().indexOf('/manage-tokens/') >= 0 && $scope.survey.tokenRequired) {
                updateRemainingTokens();

                // check that the current institution has permission
                getAllInstitutionPermissionsBySurveyId();

                $scope.populateTokens();
            } else if (!$scope.survey.tokenRequired) {
                // this survey does not require tokens,
                //   send the user to /edit/:surveyId
                $location.path("/edit/" + $routeParams.surveyId);
            } else {
                // we are done
                $scope.loading = false;
            }
        };

        $scope.init();

        // manage tokens
        $scope.order = function($event, predicate, reverse) {
            $event.stopImmediatePropagation();
            if ($scope.predicate && ($scope.predicate == predicate)) {
                $scope.reverse = reverse;
            }
            else { //if predicate switches, default to ascending sort
                $scope.reverse = false;
            }
            $scope.predicate = predicate;
        }

        $scope.populateTokens = function() {
            if ($scope.searching) {
                return $scope.search(true);
            }

            if ($scope.survey.tokenCount == 0 || $scope.survey.tokenCount > token_manage_options.maxTokensToDisplay) {
                // no tokens, or too many tokens for display
                $scope.loading = false;
                return;
            } // else

            var params = { surveyId : $routeParams.surveyId };
            TokenService.getAll(params)
                .$promise.then(function(data) {
                    

                    $scope.tokens = data;
                    $scope.survey.tokenCount = data.length;
                    $scope.loading = false;
                }, function(error) {
                    
                    sweetAlert("Fetching the Access Codes was unsuccessful. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
                });
        };

        $scope.addTokens = function(numToAddValue) {
            clearErrorMessaging();

            if (!isNaN(numToAddValue)) {
                numToAddValue = parseInt(numToAddValue);
            } else {
                $('.tokens-manage-actions-addTokens-menu-input').addClass('error');
                return;
            }

            if (numToAddValue > $scope.tokensRemainingCount) {
                $('.tokens-manage-actions-addTokens-menu-input').addClass('error');
                $('.tokens-manage-actions-addTokens-menu-remaining').addClass('error');
                return;
            }

            if (numToAddValue > 0 && numToAddValue <= $scope.tokensRemainingCount) {
                $scope.toggleAddTokensMenu(true); // close the dropdown menu

                // show the loading splash view as we add and fetch the latest list of tokens
                $scope.loading = true;

                var params = { surveyId: $routeParams.surveyId,
                               userId: userService.getUserId(),
                               count: numToAddValue,
                               maxCount: $scope.tokensMaxCount };
                TokenService.add(params)
                    .$promise.then(function(data) {
                        if (data.success) {
                            $scope.survey.tokenCount += numToAddValue;

                            updateRemainingTokens();
                            $scope.populateTokens();
                        } else {
                            
                            sweetAlert({
                                title: data.message,
                                type: 'error',
                                closeOnConfirm: true
                            }, function() {
                                setTimeout(reloadPage, 10); });
                        }
                    }, function(error) {
                        
                        sweetAlert("Failed to add access codes. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
                    });
            }

            if (numToAddValue == 0) {
                // assume the user is meaning to close the menu when adding no icons
                $scope.toggleAddTokensMenu(true);
            }
        };

        $scope.toggleAddTokensMenu = function(forceClose) {
            var dropDown = $('.tokens-manage-actions-addTokens-menu');
            var close = forceClose !== undefined ? !forceClose : !dropDown.hasClass('visible');

            $scope.numToAdd = "";
            clearErrorMessaging();

            dropDown.toggleClass('visible', close);
        };

        $scope.clickExportCSV = function() {
            var params = { surveyId: $routeParams.surveyId };
            TokenService.createCSV(params)
                .$promise.then(function(data) {
                    window.open(tokens_shared_drive_uri + data.message, "_self");
                }, function(error) {
                    
                })
        };

        $scope.enableToken = function(tokenIdValue) {
            var params = { tokenId: tokenIdValue, status: 1 };
            TokenService.update(params)
                .$promise.then(function(data) {
                    $scope.populateTokens();
                }, function(error) {
                    
                    reloadPage();
                });
        };

        $scope.disableToken = function(tokenIdValue) {
            var params = { tokenId: tokenIdValue, status: 2 };
            TokenService.update(params)
                .$promise.then(function(data) {
                    $scope.populateTokens();
                }, function(error) {
                    
                    reloadPage();
                });
        };

//        DELETE is no longer available, 27/02/2017
//        $scope.deleteToken = function(tokenIdValue) {
//            var params = { tokenId: tokenIdValue, status: 3 };
//            TokenService.update(params)
//                .$promise.then(function(data) {
//                    $scope.survey.tokenCount -= 1;
//
//                    updateRemainingTokens();
//
//                    $scope.clearSearch();
//                    $scope.populateTokens();
//                }, function(error) {
//                    console.log('deleteToken failed. ' + error.data.message);
//                    reloadPage();
//                });
//        };

        // search
        $scope.search = function(useLastSearch) {
            useLastSearch = useLastSearch || false;

            var params = { surveyId: $scope.survey.surveyId,
                tokenValue: (useLastSearch ? $scope.searchedValue : $scope.searchValue) };
            if (token_manage_options.regexTokenSearch.test(params.tokenValue)) {
                TokenService.search(params)
                    .$promise.then(function(data) {
                        //console.log(data);

                        $scope.tokens = data;
                    }, function(error) {
                        
                    });
                $scope.searching = true;
                $scope.searchedValue = params.tokenValue;
                $scope.searchValue = "";
            } else {
                sweetAlert("There was an issue with the code you entered. Please check the code and try again.");
            }
        };

        $scope.clearSearch = function() {
            $scope.searching = false;
            $scope.searchedValue = "";
            $scope.populateTokens();
        };

        // determine permission access
        function getAllInstitutionPermissionsBySurveyId () {
            var surveyId = $scope.survey.surveyId;
            var institutionId = applicationService.getInstitutionId();

            SurveyCreateService.getInstitutionIds({ customerId: institutionId, objectId: surveyId, objectTypeId: '4' }).$promise
                .then(function(institutionIds) {
                    $scope.institutionIds = institutionIds;

                    if ($scope.institutionIds.length > 0) {
                        getInstitutionPermissions(0, institutionIds);
                    }
                });
        };

        function getInstitutionPermissions (i, institutionIds) {
            if (i < institutionIds.length) {
                var surveyId = $scope.survey.surveyId;
                var institutionId = institutionIds[i];
                var userId = userService.getUserId();
                var params = {
                    objectId: surveyId,
                    objectType: 'survey',
                    customerId: institutionId,
                    userId: userId };

                SurveyCreateService.getUserRole(params).$promise
                    .then(function(roleIdForSharedInstitution) {
                        // institutions with view only should be returned
                        if(institutionId == applicationService.getInstitutionId()){
                            if(roleIdForSharedInstitution.id != '4'){
                                // reject, and send them back to edit
                                $location.path("/edit/" + surveyId);
                            }
                        }
                    });
            }
        };

        // common functions
        function reloadPage() {
            location.reload(true);
        };

        function updateRemainingTokens() {
            $scope.tokensRemainingCount = $scope.tokensMaxCount - $scope.survey.tokenCount;

            if ($scope.tokensRemainingCount < 0) $scope.tokensRemainingCount = 0;
        };

        function clearErrorMessaging() {
            $('.tokens-manage-actions-addTokens-menu-input').removeClass('error');
            $('.tokens-manage-actions-addTokens-menu-remaining').removeClass('error');
        };

    }])

    .filter('tokenStatus', function() {
        return function(number) {
            if (isNaN(number) || number < 1) {
                return number;
            } else {
                // convert status id to string
                if (number == TOKEN_STATUS_ENABLED) {
                    return ""; // return empty string, no need to write enabled
                } else if (number == TOKEN_STATUS_DISABLED) {
                    return "Disabled";
                } else if (number == TOKEN_STATUS_SUBMITTED) {
                    return "Submitted";
                }
            }
        }
    })
