//To-do have to  clean up the service. Most of the methods are not used.

(
    function() {
        'use strict';

        angular.module('echo')
            .factory('userService', userService);

        userService.$inject = ['$resource', 'applicationService', '$sessionStorage', '$injector', '$location', '$rootScope'];

        /**
         * @description The userService handles all methods and logic related to the user. This includes roles and permissions
         * functionality.
         *
         * @param {Function) resource From AngularJS - A factory which creates a resource object that lets you interact
        * with RESTful server-side data sources.
        *
         * @param {Function} applicationService A factory which creates a service object that handles all base-level application and utilities logic.
         *
         * @param {Function} $sessionStorage Reference to the ngStorage library, which creates getters and setters
         * for storing and fetching from sessionStorage.
         *
         * @returns {Object} Returns a singleton service object.
         *
         * */

        function userService($resource, applicationService, $sessionStorage, $injector, $location, $rootScope) {
            // 'Private' variable declarations
            var USER_STATUS_TYPE_ID_ENABLED = 1;
            var USER_STATUS_TYPE_ID_DISABLED = 2;
            var USER_STATUS_TYPE_ID_INVITED = 3;
            var USER_STATUS_INVITED = 'INVITED';
            var usersToBeAdded = [];
            var SAVE_USER_URL = users_api_hostname + '/users/save';
            var SAVE_USER_OBJECT_URL = users_api_hostname + '/users/save-user-object';

            // 'Private' method declarations START ######################################################################
            /**
             * @description Returns a resource to GET Permissions for a Super User.
             *
             * */
            function getPermissionsForSuperUserResource () { // used
                var urlResource = $resource(users_api_hostname + '/users/get-super-user-permissions', {}, {
                    getPermissions: {
                        method: 'GET',
                        isArray: true
                    }
                });
                return urlResource.getPermissions({});
            };

            /**
             * @description Returns a resource to update a status via PUT.
             * @param {number} userId A user ID.
             * @param {number} customerId A customer ID.
             * */
            function updateStatusResource (userId, customerId) { // used
                var url = users_api_hostname + '/users/:userId/:customerId/update-status'; // UPDATE_STATUS_URL

                var urlResource = $resource(url, {}, {
                    update: {
                        method: 'PUT',
                        params: {userId: '@userId', customerId: '@customerId'}
                    }
                });

                return urlResource.update({userId: userId, customerId: customerId});
            };

            function updateTermsAndConditionsResource (userId) { // used
                var url = users_api_hostname + '/users/:userId/update-terms-and-conditions'; // UPDATE_TERMS_AND_CONDITIONS

                var urlResource = $resource(url, {}, {
                    update: {
                        method: 'PUT',
                        params: {userId: '@userId'}
                    }
                });

                return urlResource.update({userId: userId});
            };

            // 'Private' method declarations END ########################################################################

            // Publicly accessible method/variable assignments START $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
            var myServiceObject = {
                ROLES: {
                    ROLE_ADMIN_PII: 'ROLE_ADMIN_PII',
                    ROLE_VIEWER_PII: 'ROLE_VIEWER_PII',
                    ROLE_EDITOR_PII: 'ROLE_EDITOR_PII',
                    ROLE_ADMIN: 'ROLE_ADMIN',
                    ROLE_EDITOR: 'ROLE_EDITOR',
                    ROLE_VIEWER: 'ROLE_VIEWER',
                    ROLE_CONTENT_ADMIN_AE: 'ROLE_CONTENT_ADMIN_AE'
                },

                DISPLAY_ROLES: {
                    ADMINISTRATOR: 'Administrator',
                    ADMIN: 'Admin',
                    EDITOR: 'Editor',
                    VIEWER: 'Viewer'
                },

                AUDIT_TYPES: {
                    CREATE_PII_USER: 'CREATE_PII_USER',
                    UPDATE_PII_USER: 'UPDATE_PII_USER',
                    DELETE_PII_USER: 'DELETE_PII_USER'
                },

                user: undefined
            };

            // We bind all publicly accessible service methods to myServiceObject so that all this references used in
            // our methods, even deeply nested ones, always point to myServiceObject.
            myServiceObject.setUserProfile = setUserProfile.bind(myServiceObject);
            myServiceObject.initialize = initialize.bind(myServiceObject);
            myServiceObject.userIsAdmin = userIsAdmin.bind(myServiceObject);
            myServiceObject.userIsEditor = userIsEditor.bind(myServiceObject);
            myServiceObject.userIsSuperUser = userIsSuperUser.bind(myServiceObject);
            myServiceObject.userIsCertifiedCreator = userIsCertifiedCreator.bind(myServiceObject);
            myServiceObject.userHasPIIAccess = userHasPIIAccess.bind(myServiceObject);
            myServiceObject.isAdmin = isAdmin.bind(myServiceObject);
            myServiceObject.isEditor = isEditor.bind(myServiceObject);
            myServiceObject.admin = admin.bind(myServiceObject);
            myServiceObject.userCurrentAssociationRoleNameIsViewer = userCurrentAssociationRoleNameIsViewer.bind(myServiceObject);
            myServiceObject.getUserId = getUserId.bind(myServiceObject);
            myServiceObject.getUser = getUser.bind(myServiceObject);
            myServiceObject.checkUserId = checkUserId.bind(myServiceObject);
            myServiceObject.getUserEmail = getUserEmail.bind(myServiceObject);
            myServiceObject.confirmUser = confirmUser.bind(myServiceObject);
            myServiceObject.getUserFirstName = getUserFirstName.bind(myServiceObject);
            myServiceObject.getUserLastName = getUserLastName.bind(myServiceObject);
            myServiceObject.getUserFullName = getUserFullName.bind(myServiceObject);
            myServiceObject.saveUserAsLicenseOwner = saveUserAsLicenseOwner.bind(myServiceObject);
            myServiceObject.setUser = setUser.bind(myServiceObject);
            myServiceObject.userIsLicenseOwnerAdmin = userIsLicenseOwnerAdmin.bind(myServiceObject);
            myServiceObject.getUserAssociationIds = getUserAssociationIds.bind(myServiceObject);
            myServiceObject.isIdInAssociationIds = isIdInAssociationIds.bind(myServiceObject);
            myServiceObject.getAssociationById = getAssociationById.bind(myServiceObject);
            myServiceObject.saveUserAssociation = saveUserAssociation.bind(myServiceObject);
            myServiceObject.getUserCurrentAssociation = getUserCurrentAssociation.bind(myServiceObject);
            myServiceObject.checkUserCurrentAssociationForPermission = checkUserCurrentAssociationForPermission.bind(myServiceObject);
            myServiceObject.getIsSuperUser = getIsSuperUser.bind(myServiceObject);
            myServiceObject.getTermsAndConditionsFlag = getTermsAndConditionsFlag.bind(myServiceObject);
            myServiceObject.setTermsAndConditionsFlag = setTermsAndConditionsFlag.bind(myServiceObject);
            myServiceObject.getUserCustomerId = getUserCustomerId.bind(myServiceObject);
            myServiceObject.setUserAccessToken = setUserAccessToken.bind(myServiceObject);
            myServiceObject.getSaveUserResource = getSaveUserResource.bind(myServiceObject);
            myServiceObject.saveNewUserObject = saveNewUserObject.bind(myServiceObject);
            myServiceObject.checkUser = checkUser.bind(myServiceObject);
            myServiceObject.clearUser = clearUser.bind(myServiceObject);
            myServiceObject.isUserEnabled = isUserEnabled.bind(myServiceObject);
            myServiceObject.isUserDisabled = isUserDisabled.bind(myServiceObject);
            myServiceObject.isUserInvited = isUserInvited.bind(myServiceObject);
            myServiceObject.isAssociationInvited = isAssociationInvited.bind(myServiceObject);
            myServiceObject.getUsers = getUsers.bind(myServiceObject);
            myServiceObject.saveUser = saveUser.bind(myServiceObject);
            myServiceObject.deleteUser = deleteUser.bind(myServiceObject);
            myServiceObject.validatePassword = validatePassword.bind(myServiceObject);
            myServiceObject.handleSuccessfulLogin = handleSuccessfulLogin.bind(myServiceObject);
            myServiceObject.changeUserStatus = changeUserStatus.bind(myServiceObject);
            myServiceObject.editUser = editUser.bind(myServiceObject);
            myServiceObject.getUserById = getUserById.bind(myServiceObject);
            myServiceObject.getUsersToBeAdded = getUsersToBeAdded.bind(myServiceObject);
            myServiceObject.setUsersToBeAdded = setUsersToBeAdded.bind(myServiceObject);
            myServiceObject.saveUserWithPermissions = saveUserWithPermissions.bind(myServiceObject);
            myServiceObject.checkEmailByCustomerIdResource = checkEmailByCustomerIdResource.bind(myServiceObject);
            myServiceObject.getSurveysForUserProfile = getSurveysForUserProfile.bind(myServiceObject);
            myServiceObject.getAllSurveysForByCustomer = getAllSurveysForByCustomer.bind(myServiceObject);
            myServiceObject.getCustomerUsersStatsResource = getCustomerUsersStatsResource.bind(myServiceObject);
            myServiceObject.getPermissionsForSuperUser = getPermissionsForSuperUser.bind(myServiceObject);
            myServiceObject.getPermissionsForSuperUserAndSetRoleAsAdmin = getPermissionsForSuperUserAndSetRoleAsAdmin.bind(myServiceObject);
            myServiceObject.setSuperUserAssociationAsAdmin = setSuperUserAssociationAsAdmin.bind(myServiceObject);
            myServiceObject.updateStatus = updateStatus.bind(myServiceObject);
            myServiceObject.updateTermsAndConditions = updateTermsAndConditions.bind(myServiceObject);


            return myServiceObject; // Service Object is returned
            // Publicly accessible method/variable assignments  END $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

            // Public method declarations go from here to end of file //////////////////////////////////////////////////
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // Note: All references to this are bound to the myServiceObject above.

            function setUserProfile(user) { // used
                this.user = user;
            };

            function initialize() {
                if (!this.user) {
                    this.user = this.getUser();
                }
            };

            function userIsAdmin() {
                this.initialize();
                if (this.user && this.user.currentAssociation) {
                    return this.user.currentAssociation.roleName.indexOf(this.ROLES.ROLE_ADMIN) > -1 || this.user.currentAssociation.roleName.indexOf(this.ROLES.ROLE_ADMIN_PII) > -1;
                }
            };

            function userIsEditor() {
                this.initialize();
                if (this.user && this.user.currentAssociation) {
                    return this.user.currentAssociation.roleName.indexOf(this.ROLES.ROLE_EDITOR) > -1 || this.user.currentAssociation.roleName.indexOf(this.ROLES.ROLE_EDITOR_PII) > -1;
                }
            };

            function userIsSuperUser() {
                this.initialize();
                if (this.user) {
                    return this.user.superUser;
                }
            };

            function userIsCertifiedCreator() {
                this.initialize();
                if (this.user && this.user.applicationRole) {
                    return this.user.applicationRole.roleName === this.ROLES.ROLE_CONTENT_ADMIN_AE;
                }
            };

            function userHasPIIAccess(user) {
                if (!user) {
                    user = this.getUser();
                }
                ;
                if (user && user.currentAssociation) {
                    return user.currentAssociation.roleName.substr(user.currentAssociation.roleName.length - 4) === "_PII";
                }
            };

            function isAdmin(user) {
                if (user && (user.role == this.ROLES.ROLE_ADMIN || user.role == this.ROLES.ROLE_ADMIN_PII || user.role == this.DISPLAY_ROLES.ADMIN)) {
                    return true;
                }
                return false;
            };

            function isEditor(user) {
                if (user && (user.role == this.ROLES.ROLE_EDITOR || user.role == this.ROLES.ROLE_EDITOR_PII || user.role == this.DISPLAY_ROLES.EDITOR)) {
                    return true;
                }
                return false;
            };

            function admin(element, key, isArray) {
                if (isArray) {
                    element[key] = [this.ROLES.ROLE_ADMIN];
                }
                else {
                    element[key] = this.ROLES.ROLE_ADMIN;
                }
            };

            function userCurrentAssociationRoleNameIsViewer() {
                return this.getUserCurrentAssociation().roleName === this.ROLES.ROLE_VIEWER || this.getUserCurrentAssociation().roleName === this.ROLES.ROLE_VIEWER_PII;
            };

            // CODE from userProfileService and roleMgmtService END ==================================================================

            // CODE from applicationService START ====================================================================================
            function getUserId() {
                var sessionUser = this.getUser();
                if (sessionUser) {
                    return sessionUser.id;
                }
                return null;
            };

            function getUser(skipRedirect) { // skipRedirect is a boolean to bypass the goToSigninPage call - ELE-486
                var user = $sessionStorage.user;
                if (!user && !skipRedirect) {
                    applicationService.goToSigninPage();
                }
                return user;
            };

            function checkUserId(userId) {
                var savedUserId = this.getUserId();
                if (userId !== savedUserId) {
                    return false;
                }
                return true;
            };

            function getUserEmail() {
                var sessionUser = this.getUser();
                if (sessionUser) {
                    return sessionUser.email
                }
                return null;
            };

            function confirmUser() {
                return this.getUser(); // was grabbing from $sessionStorage
            };

            function getUserFirstName() {
                var sessionUser = this.getUser();
                if (sessionUser) {
                    return sessionUser.firstName;
                }
                return null;
            };

            function getUserLastName() {
                var sessionUser = this.getUser();
                if (sessionUser) {
                    return sessionUser.lastName;
                }
                return null;
            };

            function getUserFullName() {
                var sessionUser = this.getUser();
                if (sessionUser) {
                    return sessionUser.firstName + ' ' + sessionUser.lastName;
                }
                return null;
            };

            function saveUserAsLicenseOwner(isLicenseOwner) {
                var sessionUser = this.getUser();
                if (sessionUser) {
                    sessionUser.isLicenseOwner = isLicenseOwner;
                }
            };

            function setUser(user) {
                if (user) {
                    var associationIds = [];
                    for (var i in user.associations) {
                        var association = user.associations[i];
                        if (association) {
                            associationIds.push(association.id);
                        }
                    }
                    user.associationIds = associationIds;
                    $sessionStorage.user = user;
                }
            };

            function userIsLicenseOwnerAdmin() {
                var isAdmin = false;
                var sessionUser = this.getUser();
                if (sessionUser) {
                    isAdmin = (sessionUser.isLicenseOwner == true);
                }
                return isAdmin;
            };

            function getUserAssociationIds() {
                var sessionUser = this.getUser();
                if (sessionUser) {
                    return sessionUser.associationIds;
                }
                return null;
            };

            function isIdInAssociationIds(id) {
                var userAssociationIds = this.getUserAssociationIds();
                if (userAssociationIds && userAssociationIds.indexOf(id) != -1) {
                    return true;
                }
                return false;
            };

            function getAssociationById(id) {
                var sessionUser = this.getUser();
                if (sessionUser) {
                    var userAssociations = sessionUser.associations;

                    for (var i in userAssociations) {
                        if (userAssociations[i].id == id) {
                            return userAssociations[i];
                        }
                    }
                }
                return null;
            };

            function saveUserAssociation(association, isDirect) {
                var sessionUser = this.getUser();
                if (sessionUser && association) {
                    association.isDirectAssociation = isDirect;
                    sessionUser.currentAssociation = association;
                }
            };

            function getUserCurrentAssociation() {
                var sessionUser = this.getUser();
                if (sessionUser) {
                    return sessionUser.currentAssociation;
                }
                return null;
            };

            function checkUserCurrentAssociationForPermission(permissionName) {
                var userAssociation = this.getUserCurrentAssociation();
                if (userAssociation && (_.where(userAssociation.permissions, {"permissionName": permissionName})).length > 0) {
                    return true;
                }
                return false;
            };

            function getIsSuperUser() {
                if ($sessionStorage.user && $sessionStorage.user.superUser) {
                    return $sessionStorage.user.superUser;
                } else {
                    return false;
                }
            };

            function getTermsAndConditionsFlag() {
                var sessionUser = this.getUser();
                if (sessionUser) {
                    return sessionUser.currentTermsAndConditions;
                } else
                    return false;
            };

            function setTermsAndConditionsFlag(flag) {
                var sessionUser = this.getUser();
                if (sessionUser) {
                    sessionUser.currentTermsAndConditions = flag;
                }
            };

            function getUserCustomerId() {
                var sessionUser = this.getUser();
                if (sessionUser) {
                    return sessionUser.customerId;
                }
                return null;
            };

            function setUserAccessToken(accessToken) {
                $sessionStorage.accessToken = accessToken;
            };

            function getSaveUserResource(user) {
                var url = SAVE_USER_URL;
                var urlResource = $resource(url, {}, {
                    save: {
                        method: 'POST',
                        isArray: true
                    }
                });

                return urlResource.save([user]);
            };

            function saveNewUserObject(user) {
                var url = SAVE_USER_OBJECT_URL;
                var urlResource = $resource(url, {}, {
                    save: {
                        method: 'POST'
                    }
                });

                return urlResource.save(user);
            };

            function checkUser(userId) {
                var usersUrlResource = $resource(users_api_hostname + '/users/check-user/:userId',
                    null,
                    {
                        'checkUserExists': {method: 'GET', params: {userId: '@userId'}, isArray: false}
                    }
                );
                return usersUrlResource.checkUserExists({userId: userId});
            };

            // CODE from applicationService END ======================================================================================

            function clearUser() {
                $sessionStorage.user = null;
            };

            function isUserEnabled(user) {
                if (user && user.statusTypeId === USER_STATUS_TYPE_ID_ENABLED) {
                    return true;
                }
                return false;
            };

            function isUserDisabled(user) {
                if (user && user.statusTypeId === USER_STATUS_TYPE_ID_DISABLED) {
                    return true;
                }
                return false;
            };

            function isUserInvited(user) {
                if (user && user.statusTypeId === USER_STATUS_TYPE_ID_INVITED) {
                    return true;
                }
                return false;
            };

            function isAssociationInvited(association) {
                if (association && association.status === USER_STATUS_INVITED) {
                    return true;
                }
                return false;
            };

            function getUsers(customerId) {
                var usersUrlResource = $resource(users_api_hostname + '/users/user/:customerId',
                    null,
                    {
                        'getUsersByCustomerId': {method: 'GET', params: {customerId: '@customerId'}, isArray: true}
                    }
                );
                return usersUrlResource.getUsersByCustomerId({customerId: customerId});
            };

            function saveUser(user, scope, successSaveHandler, failureSaveHandler) {
                var _this = this; // Due to deeply nested .then logic, we set a 'private' function-scoped variable to store the value of this.
                var loggedInUserId = this.getUserId();
                var institutionId = applicationService.getInstitutionId();
                scope.userEmail = user.email;
                scope.userFirstName = user.firstName;
                var role;
                switch (user.role) {
                    case this.DISPLAY_ROLES.EDITOR :
                        role = user.isPIIUser ? [this.ROLES.ROLE_EDITOR_PII] : [this.ROLES.ROLE_EDITOR];
                        break;
                    case this.DISPLAY_ROLES.ADMIN  :
                        role = user.isPIIUser ? [this.ROLES.ROLE_ADMIN_PII] : [this.ROLES.ROLE_ADMIN];
                        break;
                    case this.DISPLAY_ROLES.VIEWER :
                        role = user.isPIIUser ? [this.ROLES.ROLE_VIEWER_PII] : [this.ROLES.ROLE_VIEWER];
                        break;
                    default:
                        break;
                }

                var requestUser = {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    //password: user.password,
                    statusTypeId: user.statusTypeId,
                    createdByUserId: loggedInUserId,
                    //source: user.reference.name,
                    roles: role,
                    applicationId: 2
                }

                this.getSaveUserResource(requestUser)
                    .$promise
                    .then(function (response) {
                        //successHandler(response);
                        scope.newUserId = response[0];

                        var requestUserObject = {
                            objectInstanceId: institutionId,
                            ownerUserId: loggedInUserId,
                            customerId: institutionId,
                            userId: scope.newUserId,
                            roles: role,
                            applicationId: 2
                        }

                        return _this.saveNewUserObject(requestUserObject).$promise
                    })
                    .then(function (saveUserObjectResponse) {
                        return _this.checkUser(scope.newUserId).$promise
                    })
                    .then(function (checkUserResponse) {
                        if (checkUserResponse.data) {
                            scope.emailData = [{
                                "from": "app-noreply@advanc-ed.org",
                                "to": scope.userEmail,
                                "appName": "surveys",
                                "institutionName": applicationService.getInstitutionName(),
                                "templateName": "userInvited",
                                "invited": scope.userFirstName,
                                "accountSetupUrl": my_journey_url_login // From constants.js
                            }]
                        } else {
                            scope.emailData = [{
                                "from": "app-noreply@advanc-ed.org",
                                "to": scope.userEmail,
                                "appName": "surveys",
                                "institutionName": applicationService.getInstitutionName(),
                                "templateName": "userInvited",
                                "invited": scope.userFirstName,
                                "accountSetupUrl": checkUserResponse.userData.url
                            }]
                        }
                        if (user.statusTypeId == 3) {
                            applicationService.sendEmail(scope.emailData)
                        }
                    })
                    .then(function (emailResponse) {
                        successSaveHandler();
                    })
                    .catch(failureSaveHandler);
            };

            //delete user by customerId
            function deleteUser(userId) {
                var customerId = applicationService.getInstitutionId();
                //var userId = user.userId;
                var usersUrlResource = $resource(users_api_hostname + '/users/user/:customerId/:userId',
                    null,
                    {
                        'deleteUserByCustomerId': {
                            method: 'POST',
                            params: {customerId: '@customerId', userId: '@userId'},
                            isArray: false
                        }
                    }
                );
                return usersUrlResource.deleteUserByCustomerId({customerId: customerId, userId: userId});
            };

            function validatePassword(password) {
                var url = users_api_hostname + '/unsecured/validate/password';
                var urlResource = $resource(url, null, {
                    'validatePassword': {
                        method: 'POST',
                        isArray: false
                    }
                });
                return urlResource.validatePassword(password);
            };

            function handleSuccessfulLogin(response) {
                var applicationService = $injector.get('applicationService');
                var salesService = $injector.get('salesService');
                var user = response.user;
                var reportId = $location.search().report;
                // setter method to initialize logged in user
                this.setUserProfile(response.user);
                $rootScope.userActionRoles = { // TODO: Roles Permissions - service places userActionRoles on $rootScope. All references to $rootScope.userActionRoles should be checked and refactored to getters in the userService.
                    admin: this.userIsAdmin(),
                    certified: this.userIsCertifiedCreator(),
                    superUser: this.userIsSuperUser()
                };
                if (!this.isUserEnabled(user)) {
                    if (this.isUserDisabled(user)) {
                        return user;
                    }
                    else if (this.isUserInvited(user)) {
                        return user;
                    }
                    return;
                }
                if (user.superUser && user.superUser == true) {                  //if super user go to institution selector directly
                    this.setUserAccessToken(response.token);
                    this.setUser(user);
                    var originalPath = applicationService.getOriginalPath();
                    if ((originalPath === "/preview-report" || originalPath == "/view-report") && reportId) {
                        applicationService.goToSigninPageWithRedirect(originalPath);
                        return;
                    }
                    applicationService.goToSigninSelectInstitutionPage();
                } else if ($rootScope.userActionRoles.certified && !$rootScope.userActionRoles.admin) {
                    this.setUserAccessToken(response.token);
                    this.setUser(user);
                    $location.path('/manage-content');
                } else {
                    var userAssociations = user.associations;
                    if (userAssociations.length === 0) {
                        return user;
                    } else {
                        this.setUserAccessToken(response.token);
                        this.setUser(user);
                        return user;
                    }
                }
            };

            //change user(s) status
            function changeUserStatus(users, successHandler, errorHandler) {
                var url = users_api_hostname + '/users/change-status';
                var urlResource = $resource(url, {}, {
                    changeStatusByCustomerId: {
                        method: 'POST',
                        isArray: false
                    }
                });

                urlResource.changeStatusByCustomerId(users)
                    .$promise.then(function (response) {

                        successHandler(response);
                    }).catch(function error(msg) {

                        errorHandler(msg);
                    });
            };

            function editUser(user, successHandler, errorHandler) {
                var url = users_api_hostname + '/users/edit';
                switch (user.role) {
                    case this.DISPLAY_ROLES.EDITOR :
                        user.role = this.ROLES.ROLE_EDITOR;
                        break;
                    case this.DISPLAY_ROLES.ADMIN  :
                        user.role = this.ROLES.ROLE_ADMIN;
                        break;
                    case this.DISPLAY_ROLES.VIEWER :
                        user.role = this.ROLES.ROLE_VIEWER;
                        break;
                    default:
                        break;
                }
                var urlResource = $resource(url, {}, {
                    save: {
                        method: 'POST',
                        isArray: false
                    }
                });

                urlResource.save(user)
                    .$promise.then(function (response) {

                        successHandler(response);
                    }).catch(function error(msg) {

                        errorHandler(msg);
                    });
            };

            //returns User.get as a promise
            function getUserById(id) {
                var User = $resource(users_api_hostname + '/users/get-user/:userId');
                return User.get({userId: id});
            };

            function getUsersToBeAdded() {
                return usersToBeAdded;
            };

            function setUsersToBeAdded(users) {
                usersToBeAdded = users;
            };

            function saveUserWithPermissions(user, successHandler, errorHandler) {
                var url = users_api_hostname + '/users/save-users-permissions';
                var urlResource = $resource(url, {}, {
                    save: {
                        method: 'POST',
                        isArray: true
                    }
                });

                urlResource.save(user)
                    .$promise.then(function (response) {

                        successHandler(response);
                    }).catch(function error(msg) {

                        errorHandler(msg);
                    });
            };

            function checkEmailByCustomerIdResource(userInfo, successHandler, errorHandler) {
                var url = users_api_hostname + "/users/check-email-by-customerId";
                var urlResource = $resource(url, {}, {
                    save: {
                        method: 'POST',
                        isArray: true
                    }
                });

                urlResource.checkEmailByCustomerId(userInfo)
                    .$promise.then(function (response) {

                        successHandler(response);
                    }).catch(function error(msg) {

                        errorHandler(msg);
                    });
            };

            function getSurveysForUserProfile(userId, customerId, fetchPermission, scope) {
                //Originally called /users/:userId/:customerId/get-objects/survey. returns permissions
                //Now we should call echo-api/api/surveys/183/get-surveys?fetchPermissions=true
                console.log('fetchPermissions = ' + fetchPermission);
                var userProfile = $resource(hostname + '/echo-api/api/surveys/:customerId/get-surveys?fetchPermissions=' + (fetchPermission ? 'true' : 'false'),
                    null,
                    {
                        'surveys': {
                            method: 'GET',
                            params: {userId: '@userId', customerId: '@customerId'},
                            isArray: true
                        }
                    }
                );

                return userProfile.surveys({userId: userId, customerId: customerId});
            };

            function getAllSurveysForByCustomer(customerId, scope) {
                //Originally called users/:customerId/get-objects/survey. returns NO permissions
                //Now we should call echo-api/api/surveys/183/get-surveys?fetchPermissions=false
                var userProfile = $resource(hostname + '/echo-api/api/surveys/:customerId/get-surveys?fetchPermissions=false',
                    null,
                    {
                        'surveys': {method: 'GET', params: {customerId: '@customerId'}, isArray: true}
                    }
                );

                return userProfile.surveys({customerId: customerId});
            };

            function getCustomerUsersStatsResource(customers) {
                var url = users_api_hostname + "/users/customer-user-stats";
                var urlResource = $resource(url, {}, {
                    save: {
                        method: 'POST',
                        isArray: true
                    }
                });
                return urlResource.save(customers);
            };

            function getPermissionsForSuperUser(successHandler, errorHandler) {
                var resource = getPermissionsForSuperUserResource();
                applicationService.executeAsPromiseWithHandlers(resource, successHandler, errorHandler);
            };

            function getPermissionsForSuperUserAndSetRoleAsAdmin(selectedInstitution) {
                this.selectedInstitution = selectedInstitution;
                return this.getPermissionsForSuperUser(this.setSuperUserAssociationAsAdmin.bind(this), null); // bind is required so that instance variables are accessible to setSuperUserAssociationAsAdmin.
            };

            function setSuperUserAssociationAsAdmin(permissions) {
                var IS_DIRECT = true;
                var association = {};
                association.permissions = permissions;
                this.admin(association, 'roleName');
                this.saveUserAssociation(association, !IS_DIRECT);
                var signInService = $injector.get('signInService');
                signInService.setCustomerForSuperUser(this.selectedInstitution.id); // This is either a customer ID or an institution ID. Hard to tell from the code alone.
            };

            function updateStatus(userId, customerId, successHandler, errorHandler) {
                var resource = updateStatusResource(userId, customerId);
                applicationService.executeAsPromiseWithHandlers(resource, successHandler, errorHandler);
            };

            function updateTermsAndConditions(userId, successHandler, errorHandler) {
                var resource = updateTermsAndConditionsResource(userId);
                applicationService.executeAsPromiseWithHandlers(resource, successHandler, errorHandler);
            }
        } // END factory function
    } // END IIFE function
)();