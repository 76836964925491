// TODO: Will - this file does not appear to be used and causes collisions with src/app/directives/dashboardActivityTabset.js. Remove it.
//app.directive('dashboardActivityTabset', ['$filter', '$timeout', 'ActivityService', 'SurveyService', function($filter, $timeout, ActivityService, SurveyService) {
//    return{
//        restrict: 'E',
//        templateUrl: 'partials/dashboard/dashboardActivityTabset.html',
//        link: function(scope,element,attrs){
//            scope.retrievedDemographicNames = [];
//            scope.retrievedDemographics = [];
//            scope.totalSectionCountExceedsThreshold = false;
//            scope.activityLoading = true;
//            scope.activateSection = function(surveyId, scale)
//            {
//                if (surveyId != null && scale > 0 && (scope.sectionActivity == null || scope.sectionActivity.length == 0 ||   scope.sectionActivity[0].parentSurveyId != surveyId)) {
//                    scope.totalSectionCountExceedsThreshold = false;//reset before retrieval
//                    scope.certifiedContent = (scope.activeSurvey.certificationId != null && scope.activeSurvey.certificationId >0)
//
//
//                    var surveyRequestDTO = angular.copy(scope.surveyRequest);
//                    surveyRequestDTO.surveyId = surveyId;
//                    surveyRequestDTO.count = 6;
//
//                    ActivityService.sectionActivity(surveyRequestDTO).$promise.then(function (data) {
//                        var sectionActivity = data.surveySectionActivity;
//                        angular.forEach(sectionActivity, function(value, index){
//                            if (! scope.totalSectionCountExceedsThreshold) scope.totalSectionCountExceedsThreshold = sectionActivity.sectionCountExceedThreshold;
//                            sectionActivity[index].sectionAverage = $filter('number')(sectionActivity[index].sectionAverage, 2);
//                            sectionActivity[index].sectionOptions = sectionActivity[index].sectionNetworkAverage != null ?  angular.copy(dashboard_activity_section_chart_options) : angular.copy(dashboard_activity_section_chart_options2);
//                            sectionActivity[index].sectionScore =
//                                    sectionActivity[index].sectionNetworkAverage != null ?
//                                [
//                                    {'sectionAverage':sectionActivity[index].sectionAverage, 'value': scale,'sectionNetworkAverage':sectionActivity[index].sectionNetworkAverage}
//                                ]
//                                :
//                                [
//                                    {'sectionAverage':sectionActivity[index].sectionAverage, 'value': scale}
//                                ];
//                        });
//                        scope.sectionActivity = sectionActivity;
//                    }, function (httpResponse) {
//                        $timeout(errorPopup, 500);
//                    });
//
//                    scope.activityLoading = false;
//                }
//            }
//            scope.sectionTooltip = function(networkAverage){
//                if (networkAverage && networkAverage > 0)
//                {
//                    return ("Network Average " + networkAverage);
//                }
//            }
//
//            scope.demographicTabNames = function(){
//                if (scope.activeSurvey != null && scope.activeSurvey != undefined && scope.activeSurvey.surveyId > 0) {
//
//                    var count = (scope.licenseUser ? 3 : 4);
//                    ActivityService.demographicNames({surveyId: scope.activeSurvey.surveyId, schools: scope.licenseUser, count: count}).$promise.then(function (data) {
//                        var demographics = data.surveyDemographicActivities;
//                        scope.demographics = demographics;
//                    }, function (httpResponse) {
//                        $timeout(errorPopup, 500);
//                    });
//
//                }
//            }
//
//            scope.activateDemographic = function(demographic, surveyId, responseCount)
//            {
//                    scope.demographicActivity = {};
//                    if (surveyId != null && (scope.retrievedDemographicNames.length == 0 || scope.retrievedDemographicNames.indexOf(demographic) == -1)) {
//                        scope.retrieveDemographics(demographic, surveyId, responseCount);
//                    }
//                    else
//                    {
//                        scope.retrieveDemographicsFromScope(demographic, surveyId, responseCount);
//                    }
//            }
//
//            scope.retrieveDemographics = function(demographic, surveyId, responseCount){
//
//
//                var surveyRequestDTO = angular.copy(scope.surveyRequest);
//                surveyRequestDTO.surveyId = surveyId;
//                surveyRequestDTO.demographic = demographic;
//                //surveyRequestDTO.languageId = english_language_id;
//                surveyRequestDTO.count = 6;
//                surveyRequestDTO.totalResponseCount = responseCount;
//
//                ActivityService.demographicActivity(surveyRequestDTO).$promise.then(function (data) {
//                    var demographicActivity = data.surveyDemographicActivity;
//                    if (demographic == 'Schools')
//                    {
//                        scope.paintSchoolData(demographic, demographicActivity);
//                    }
//                    else scope.paintDemographicData(demographic, demographicActivity);
//                }, function (httpResponse) {
//                    $timeout(errorPopup, 500);
//                });
//
//            }
//
//            scope.paintDemographicData = function(demographic, demographicActivity){
//                scope.applyPaddingToDemographic(demographicActivity);
//                scope.demographicActivity = demographicActivity;
//                scope.retrievedDemographicNames.push(demographic);
//                scope.retrievedDemographics.push({
//                        name: demographic,
//                        activity: demographicActivity
//                    }
//                )
//            }
//
//            scope.paintSchoolData = function(demographic, demographicActivity){
//                var institutions = [];
//                for (var activityInd in demographicActivity.detailActivityList)
//                {
//                    var institution = {};
//                    institution.id = demographicActivity.detailActivityList[activityInd].detailType;
//                    institutions.push(institution);
//                }
//                SurveyService.institutionForIds(0, institutions, scope.paintSchoolDataWithName, demographic, demographicActivity );
//            }
//
//            scope.paintSchoolDataWithName = function(institutions, demographic, demographicActivity) {
//                for (var activityInd in demographicActivity.detailActivityList) {
//                    demographicActivity.detailActivityList[activityInd].detailType = institutions[activityInd].name;
//                }
//                scope.paintDemographicData(demographic, demographicActivity);
//            }
//
//            scope.retrieveDemographicsFromScope = function(demographic, surveyId, responseCount){
//
//                var keepGoing = true;
//                angular.forEach(scope.retrievedDemographics, function (value, index) {
//                    if (keepGoing) {
//                        if (scope.retrievedDemographics[index].name == demographic) {
//                            scope.demographicActivity = scope.retrievedDemographics[index].activity;
//
//                            keepGoing = false;
//                        }
//                    }
//                });
//                if (keepGoing) scope.retrieveDemographics(demographic, surveyId, responseCount);
//            }
//
//            scope.applyPaddingToDemographic = function(demographicActivity){
//                angular.forEach(demographicActivity.detailActivityList, function (value, index) {
//                    //setup for UI manipulation of bar graph, used in the template
//                    demographicActivity.detailActivityList[index].rightOffset = 1; //default - maps to CSS selector
//
//                    //tweak the CSS selectors based on response count vis-a-vis response percentage
//                    if (demographicActivity.detailActivityList[index].responseCount <= 99) {
//                        if (demographicActivity.detailActivityList[index].responsePercentage <= 15)//threshold for < 100 responses
//                        {
//                            demographicActivity.detailActivityList[index].displayCountOutside = true;
//                            demographicActivity.detailActivityList[index].rightOffset = 2;
//
//                        } else demographicActivity.detailActivityList[index].displayCountOutside = false;
//                    }
//                    else if (demographicActivity.detailActivityList[index].responseCount <= 999)//threshold for < 1000 responses
//                    {
//                        if (demographicActivity.detailActivityList[index].responsePercentage <= 20) {
//                            demographicActivity.detailActivityList[index].displayCountOutside = true;
//                            demographicActivity.detailActivityList[index].rightOffset = 3;
//
//                        } else demographicActivity.detailActivityList[index].displayCountOutside = false;//threshold for < 10000 responses
//                    }
//                    else if (demographicActivity.detailActivityList[index].responseCount <= 9999) {
//                        if (demographicActivity.detailActivityList[index].responsePercentage <= 25) {
//                            demographicActivity.detailActivityList[index].displayCountOutside = true;
//                            demographicActivity.detailActivityList[index].rightOffset = 4;
//
//                        } else demographicActivity.detailActivityList[index].displayCountOutside = false;//threshold for < 100000 responses
//                    }
//                    else if (demographicActivity.detailActivityList[index].responseCount <= 99999) {
//                        if (demographicActivity.detailActivityList[index].responsePercentage <= 30) {
//                            demographicActivity.detailActivityList[index].displayCountOutside = true;
//                            demographicActivity.detailActivityList[index].rightOffset = 5;
//
//                        } else demographicActivity.detailActivityList[index].displayCountOutside = false;
//                    }
//                });
//            }
//
//
//            scope.$watch('activeSurvey', function(newValue, oldValue) {
//                if (newValue !== oldValue) {
//
//                    scope.retrievedDemographicNames = [];
//                    scope.retrievedDemographics = [];
//                    scope.activateSection(newValue.surveyId, newValue.scale);
//                    scope.demographicTabNames();
//                }
//            });
//        }
//    }
//}]);
