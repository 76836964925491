/**
 * Created by afynn on 8/31/15.
 */
app.directive('contactUs', [
    function(){
        return {
            replace: true,
            restrict: 'E',
            templateUrl: 'partials/application/contact-us.html'
        }
    }
])