app.directive('focusOnClick', function() {
  return function(scope,element,attrs){ 
    $(element[0]).click(function(){
      if(!scope.showingAutoCloseCalendar){
        $(attrs.focusOnClick).focus()
      }
    })
  }
});

app.directive('autoFocus', function($timeout) {
    return {
        restrict: 'AC',
        link: function(_scope, _element) {
            $timeout(function(){
                _element[0].focus();
            }, 0);
        }
    };
});
