/**
 * Created by pvo on 3/26/15.
 */
app.controller('CertifiedContentsController', 
    ['$scope', 
     '$rootScope', 
     'CertifiedContentService',
     'userService', 
     '$timeout', 
function($scope, 
         $rootScope, 
         CertifiedContentService, 
         userService, 
         $timeout) {


    $scope.init = function() {
        $scope.selectTargetMdl = 'All';
        $scope.selectContentNameMdl = 'All';
        $scope.predicate = 'sort';
        $scope.reverse = false;
        $scope.arrowDirection = 'icon-chevron-down';
        $scope.searchToken = '';
        $scope.showCreate = false;
        $scope.certifiedContent_loading = true;

        if(userService.checkUserCurrentAssociationForPermission('create_survey')){
            $scope.showCreate = true;
        }
    }

    $rootScope.headerAction = 'active';
    $scope.disableFilter = false;
    $scope.certifiedContentTargets = certifiedContentTargets;
    $scope.certifiedContentNames = certifiedContentNames;
    $scope.isNormalUser = function(){
        return true;
    }

    $scope.init();

    $rootScope.$watch('headerAction', function( status )
    {
        if( status )  // onchange of status in controller 1
        {
            if (status === 'defaultViewClicked') {
                $scope.defaultViewClicked();
            }
            else if (status === 'cancelClicked') {
                $scope.cancelClicked();
            }
            else if (status == 'saveDefaultViewClicked') {
                $scope.saveDefaultViewClicked();
            }

            $rootScope.headerAction = null;     //Reset for next consumption
        }
    });

    CertifiedContentService.query().$promise.then(function (data) {
        // success handler

        $scope.certifiedContentList = data;
        $scope.certifiedContent_loading = false;
        $scope.certifiedContent_success = true;
    }, function (error) {
        // error handler
        var msg = "There was an error retrieving certified content.";
        $timeout(sweetAlert("The system encountered a problem, please try again.", msg ), 500);
        $scope.certifiedContent_loading = false;
        $scope.certifiedContent_success = false;
    });

    $scope.selectTargetChanged = function() {


        $scope.searchToken = "";

        var certifiedContent = {target:$scope.selectTargetMdl, contentName:$scope.selectContentNameMdl};
        CertifiedContentService.filter(certifiedContent).$promise.then(function (data) {
            // success handler

            $scope.certifiedContentList = data;
        }, function (error) {
            // error handler


        });
    }

    $scope.deleteTemplate = function(contId) {


        var certifiedContent = {contentId:contId, target:$scope.selectTargetMdl, contentName:$scope.selectContentNameMdl};
        CertifiedContentService.deleteFilter(certifiedContent).$promise.then(function (data) {
            // success handler

            $scope.certifiedContentList = data;
        }, function (error) {
            // error handler

        });
    };

    // $scope.searchKeyDown = (token) => {
    //     if (token == 1)
    //         return [];
    //     var str = ""
    //
    //     return _.filter($scope.certifiedContentList, function(element){
    //       console.log(element);
    //         var check = false;
    //         for(key in element){
    //             if (key == "target" || key == "contentName" || key == "description") {
    //                 str = (element[key] + "").toLowerCase();
    //                 if (str.indexOf(token.toLowerCase()) != -1) {
    //                     check = true;
    //                     break;
    //                 }
    //             }else {continue;}
    //         }
    //         if(check) return element;
    //     });
    // };

    $scope.searchKeyDown = function(token) {
        if (token == 1)
            return [];
        var str = ""

        return _.filter($scope.certifiedContentList, function(element){
          console.log(element);
            var check = false;
            for(var key in element){
                if (key == "target" || key == "contentName" || key == "description") {
                    str = (element[key] + "").toLowerCase();
                    if (str.indexOf(token.toLowerCase()) != -1) {
                        check = true;
                        break;
                    }
                }else {continue;}
            }
            if(check) return element;
        });
    };

    // $scope.searchSelect = (data) => {
    //   $scope.searchToken = data.token;
    //   $scope.certifiedContentList.length = 0;
    //   $scope.certifiedContentList.push(data);
    // };

    $scope.searchSelect = function(data) {
      $scope.searchToken = data.token;
      $scope.certifiedContentList.length = 0;
      $scope.certifiedContentList.push(data);
    };

    $scope.headerClicked = function(headerName) {

        if ($scope.predicate === headerName) {
            $scope.reverse = !$scope.reverse;
        }
        else {
            $scope.reverse = true;
        }

        $scope.predicate = headerName;

        if ($scope.reverse === true) {
            $scope.arrowDirection = "icon-chevron-down"
        }
        else {
            $scope.arrowDirection = "icon-chevron-up";
        }

        //Sort the physical list to be the same as shown on the screen
        $scope.certifiedContentList.sort($scope.compare);

        //Update the sort value
        for (let i = 0; i < $scope.certifiedContentList.length; i++) {
            $scope.certifiedContentList[i].sort = i;
        }


    };

    $scope.compare = function(a, b) {
        var result = 0;
        if (a[$scope.predicate] < b[$scope.predicate])
            result = -1;
        if (a[$scope.predicate] > b[$scope.predicate])
            result = 1;

        if ($scope.reverse) {
            return -result;
        }

        return result;
    }

    $scope.defaultViewClicked = function() {


        $scope.init();

        $scope.selectTargetChanged();

        $scope.disableFilter = true;
    };

    $scope.cancelClicked = function() {


        $scope.disableFilter = false;
    };

    $scope.saveDefaultViewClicked = function() {


        if ($scope.certifiedContentList.length == 0) return;

        var contentIdListVal = "";
        var sortOrderListVal = "";

        for (let i = 0; i < $scope.certifiedContentList.length; i++) {
            contentIdListVal += $scope.certifiedContentList[i].contentId + ",";
            sortOrderListVal += $scope.certifiedContentList[i].sort + ",";
        }

        var certifiedContentSortDTO = {contentIdList:contentIdListVal, sortOrderList:sortOrderListVal};
        CertifiedContentService.sort(certifiedContentSortDTO).$promise.then(function (data) {
            // success handler

        }, function (error) {
            // error handler

        });

        $scope.disableFilter = false;
    };

    // $scope.sortableOptionsA = {
    //     stop : function(e, ui) {
    //         if ($scope.disableFilter === false) {
    //             return;
    //         }
    //         $scope.predicate = 'sort';
    //         $scope.reverse = false;

    //         var item = ui.item.scope().item;
    //         var fromIndex = ui.item.sortable.index;
    //         var toIndex = ui.item.sortable.dropindex;

    //         if (toIndex != undefined) {
    //             var toSort = $scope.certifiedContentList[toIndex].sort;
    //             if (toIndex > fromIndex) {

    //                 for (i = toIndex; i > fromIndex; i--) {
    //                     $scope.certifiedContentList[i].sort = $scope.certifiedContentList[i - 1].sort;
    //                 }
    //             }
    //             else {

    //                 for (i = toIndex; i < fromIndex; i++) {
    //                     $scope.certifiedContentList[i].sort = $scope.certifiedContentList[i + 1].sort;
    //                 }
    //             }

    //             $scope.certifiedContentList[fromIndex].sort = toSort;

    //             console.log('moved', $scope.certifiedContentList[fromIndex], fromIndex, toIndex);
    //             console.log('sortableOptionsA: ' + JSON.stringify($scope.certifiedContentList));
    //         }
    //     }
    // };

    //$(document).click(function(event){
    //    if(event.target.nextSibling){
    //        if(event.target.nextSibling.parentNode){
    //            var button = $(event.target.nextSibling.parentNode).hasClass('left-off-canvas-toggle');
    //            var ocw = $('.off-canvas-wrap');
    //            if(!button){
    //                if($(event.target).hasClass('left-off-canvas-span') === false){
    //                    if($(ocw).hasClass('move-right')){
    //                        $(ocw).removeClass('move-right');
    //                    }
    //                }
    //            }
    //        }
    //    }
    //});
}]);

//Directive
///////////
app.directive('menu3', function () {
        return {
            restrict: 'A',
            link: function (scope, el, attrs) {
                // define callback for vanilla DOM click event
                el.bind('click', function () {
                    scope.menu3Show = attrs.dropId;
                    var rect = el[0].getBoundingClientRect();
                    //el.html(attrs.dropId);
                    var dropdownId = document.getElementById("drop" + attrs.dropId);
                    var currentDropdownVisible = dropdownId.style.visibility === 'visible';
                    for (let i = 0; i < scope.certifiedContentList.length; i++) {
                        var eachDropdown = document.getElementById("drop" + i);
                        eachDropdown.style.visibility = 'hidden';
                    }

                    if (currentDropdownVisible) {
                        dropdownId.style.visibility = 'hidden';
                    }
                    else {
                        dropdownId.style.visibility = 'visible';
                        dropdownId.style.left = rect.left - 200 + 'px';
                        dropdownId.style.top = rect.top - 100 + 'px';
                    }
                });
            }
        };
    });
