app.factory( 'salesService', [ '$resource', 'applicationService',
    function($resource, applicationService){
        return new SalesService($resource, applicationService);
    }]);

function SalesService(resourceService, applicationService) {

    var SYSTEM_LICENSE_TYPE = 'System';
    var SCHOOL_LICENSE_TYPE = 'School';

    var SURVEYS_SYSTEM_PRODUCT = { name: 'surveys_system', id: 1 };
    var SURVEYS_SCHOOL_PRODUCT = { name: 'surveys_school', id: 2 };

    this.getProductNames = function(){
        return [SURVEYS_SCHOOL_PRODUCT.name, SURVEYS_SYSTEM_PRODUCT.name];
    }
    this.getSurveysSchoolProduct = function(){
        return SURVEYS_SCHOOL_PRODUCT;
    }
    this.getSurveysSystemProduct = function(){
        return SURVEYS_SYSTEM_PRODUCT;
    }

    this.getLicenseTypeByProductId = function(productId){
        if(productId === SURVEYS_SYSTEM_PRODUCT.id){
            return SYSTEM_LICENSE_TYPE;
        }
        else if(productId === SURVEYS_SCHOOL_PRODUCT.id){
            return SCHOOL_LICENSE_TYPE;
        }
    }


    var GET_LICENSE_ALLOCATION_ENDPOINT = sales_api_hostname + '/licenses/allocation';

    var licenseAllocationResource = function(ownerId, products, successHandler, errorHandler) {
        var url = GET_LICENSE_ALLOCATION_ENDPOINT;
        var urlResource = resourceService(url);

        return urlResource.save({'ownerId': ownerId, 'productNames': products})
    }

    this.getLicenseAllocationResource = function(ownerId, products) {
        return licenseAllocationResource(ownerId, products);
    }

    this.getLicenseAllocation = function(ownerId, products, successHandler, errorHandler){
        licenseAllocationResource(ownerId, products)
            .$promise.then(function (response) {
                
                applicationService.handleAPISuccess(response, successHandler);
            }).catch(function error(error) {
                applicationService.handleAPIError(error, errorHandler);
            });
    }



    var GET_CUSTODIANS_LICENSES_ENDPOINT = sales_api_hostname + '/licenses/retrieve';

    var custodiansLicensesResource =  function(custodianIds, products){
        var url = GET_CUSTODIANS_LICENSES_ENDPOINT;
        var urlResource = resourceService(url);

        return urlResource.save({'custodianIds':custodianIds, 'productNames':products})
    }

    this.getCustodiansLicensesResource = function(custodianIds, products){
        return custodiansLicensesResource(custodianIds, products);
    }

    this.getCustodiansLicenses = function(custodianIds, products, successHandler, errorHandler){
        custodiansLicensesResource(custodianIds, products)
            .$promise.then(function (response) {
                
                applicationService.handleAPISuccess(response, successHandler);
            }).catch(function error(error) {
                applicationService.handleAPIError(error, errorHandler);
            });
    }


    var GET_CUSTODIANS_ENDPOINT = sales_api_hostname + '/licenses/custodians';

    this.getCustodians = function(ownerId, products, successHandler, errorHandler){
        var url = GET_CUSTODIANS_ENDPOINT;
        var urlResource = resourceService(url);

        urlResource.save({'ownerId':ownerId, 'productNames':products})
            .$promise.then(function (response) {

            applicationService.handleAPISuccess(response, successHandler);
        }).catch(function error(error) {
            applicationService.handleAPIError(error, errorHandler);
        });
    }



    var FILTER_CUSTODIANS_LICENSES_ENDPOINT = sales_api_hostname + '/licenses/filter-custodians';

    var filterCustodiansLicensesResource =  function(custodianIds, products){
        var url = FILTER_CUSTODIANS_LICENSES_ENDPOINT;
        var urlResource = resourceService(url);

        return urlResource.save({'custodianIds':custodianIds, 'productNames':products})
    }

    this.getFilteredCustodiansLicenses = function(custodianIds, products, successHandler, errorHandler){
        filterCustodiansLicensesResource(custodianIds, products)
            .$promise.then(function (response) {
                
                applicationService.handleAPISuccess(response, successHandler);
            }).catch(function error(error) {
                applicationService.handleAPIError(error, errorHandler);
            });
    }





    var ASSIGN_LICENSES_ENDPOINT = sales_api_hostname + '/assign';

    var assignLicensesResource =  function(ownerId, custodianIds, productId){
        var url = ASSIGN_LICENSES_ENDPOINT;
        var urlResource = resourceService(url);

        return urlResource.save({'ownerId':ownerId, 'productId':productId, 'custodianIds':custodianIds})
    }

    this.assignLicenses = function(ownerId, custodianIds, productId, successHandler, errorHandler){
        assignLicensesResource(ownerId, custodianIds, productId)
            .$promise.then(function (response) {
                
                applicationService.handleAPISuccess(response, successHandler);
            }).catch(function error(error) {
                applicationService.handleAPIError(error, errorHandler);
            });
    }
}