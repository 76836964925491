app.directive('dashboardtooltip', ['$compile','$interpolate', function($compile,$interpolate) {
    return{
        restrict: 'C',
        link: function(scope,element,attrs){
            scope.tooltipDisplay = function() {
                var content = $(element).find('.tooltip-content');
                if (content.html()) return $interpolate(content.html())(scope);
            };
        }
    }
}]);
