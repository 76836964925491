
app.directive('autoFocus', function($timeout) {
    return {
        restrict: 'A',
        link: function(scope, element) {
            //use the timeout to let things in the dom render,
            // even though it is zero, it at least waits for that - that way this works in modals and whatnot too
            $timeout(function(){
                element[0].focus();
            }, 0);
        }
    };
});

app.directive('autoFocusFirst', function($timeout) {
    return {
        restrict: 'A',
        link: function(scope, element) {
            if(scope.$index === 0){
                $timeout(function(){
                    element[0].focus();
                }, 0);
            }
        }
    };
});


var EMAIL_REGEXP = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,20}(?:\.[a-z]{2})?)$/i;;
app.directive('requiredEmail', function() {
    return {
        require: 'ngModel',
        link: function(scope, element, attrs, ctrl) {

            //Use 'on blur' to validate input, validators to override errors
            element.on('blur', function () {
                var elmValue = element[0].value;
                if(element[0].classList.contains('ng-touched')){
                    if (ctrl.$isEmpty(elmValue)) {
                        scope.errors.email = "required";
                    }
                    else if (!EMAIL_REGEXP.test(elmValue)) {
                        scope.errors.email = "invalid";
                    }
                    else {
                        scope.errors.email = null;
                    }
                    scope.$apply();
                }
            });

            ctrl.$validators.email = function(modelValue) {
                if(!scope.errors.email){
                    return true;
                }
                else if(scope.errors.email == 'required' && !ctrl.$isEmpty(modelValue)) {
                    scope.errors.email = null;
                    return true;
                }
                else if(scope.errors.email == 'invalid' &&  EMAIL_REGEXP.test(modelValue)) {
                    scope.errors.email = null;
                    return true;
                }

                return false;
            }

            /*ctrl.$validators.email = function(modelValue) {
                if (ctrl.$dirty && ctrl.$isEmpty(modelValue)) {
                    scope.errors.email = "required";
                    return false;
                }
                else if (ctrl.$dirty && !EMAIL_REGEXP.test(modelValue)) {
                    scope.errors.email = "invalid";
                    return false;
                }
                else {
                    scope.errors.email = null;
                }
                return true;
             };*/
        }
    };
});

app.directive('requiredPassword', function() {
    return {
        require: 'ngModel',
        link: function(scope, element, attrs, ctrl) {
            ctrl.$validators.password = function(modelValue, viewValue) {
                if (ctrl.$dirty && ctrl.$isEmpty(modelValue)) {
                    scope.errors.password = "required";
                    return false;
                }
                else{
                    scope.errors.password = null;
                }

                return true;
            };
        }
    };
});