/**
 * Created by pvo on 6/12/15.
 */
// TODO: This controller is used in 22 templates. We need to make sure all of those templates and this controller are
// actively being used.
app.controller('SurveyEditController', ['$http', '$scope', '$routeParams', '$rootScope', '$timeout', 'ReportService', 'modalService', function($http, $scope, $routeParams, $rootScope, $timeout, ReportService, modalService) {
    
    

    $scope.init = function () {
        $rootScope.headerAction = 'hidden';
        $scope.languageId = 1; //English
        $('#apply-to-selected').hide();
    }

    

    $scope.init();

    // $scope.licenseManagementSampleData = [
    //   {
    //     system: 'Brown', 
    //     institution: '--', 
    //     location: null,
    //     ownership: 'State', 
    //     expriry: '06/15/16', 
    //     users: '65', 
    //     lastActivity: '07/30/15', 
    //     status: 'active'
    //   },
    //   {
    //     system: 'Madison', 
    //     institution: '--', 
    //     location: 'Madison, OH',
    //     ownership: 'State', 
    //     expriry: '06/15/16', 
    //     users: '100', 
    //     lastActivity: '07/30/15', 
    //     status: 'active'
    //   },
    //   {
    //     system: 'Brown', 
    //     institution: 'Hayes, OH', 
    //     ownership: 'State', 
    //     expriry: '06/15/16', 
    //     users: '35', 
    //     lastActivity: '07/30/15', 
    //     status: 'expired'
    //   }
    // ];

    // $scope.survey = {
    //   shortName: 'AP385Q',
    //   name: 'Parent Stakeholder Survey Fall 2014',
    //   status: 'Draft',
    //   whenComplete: 'redirect',
    // }

    $scope.dateOptions = {
      inline: true,
      prevText: 'h',
      nextText: 'i',
    };

    $scope.showHeader = function() {
        $rootScope.headerAction = 'active';
    }

    $scope.verifyUnlock = function() {
      modalService.presentCustomModal({
          title: "",
          text: $('#unlockPrompt').html(),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Continue",
          closeOnConfirm: false,
          html: true,
          customClass: "long-content"
      }, function() { // TODO: Will - isConfirm is undefined. Should it be a parameter here?
          if (isConfirm) {
              if ($('#unlock_confirm input').is(':checked')) {
                  swal("Unlocked!", "Survey is now unlocked", "success");
                  $scope.survey.unlocked = true;
              } else {
                  $('#unlock_confirm').css('color','red');
              }
          }
      });
    }
}]);