app.directive('generateReportModal', ['$modal', '$sessionStorage', 'reportPreferences', function($modal, $sessionStorage, reportPreferences) {
    return{
        restrict: 'E',
        scope: {
          surveyId: '@',
          surveyContentId: "@",
          networkAverage: "@",
          surveyContentName: "@",
          survey: "@",
          cameFromViewSummary: "=",
          reportPreferences: "=",
          closeModal: "&",
        },
        link: function(scope,element,attrs){
          scope.openModal = function() {
            var modalInstance = $modal.open({
                templateUrl: 'partials/dashboard/dashboardGenerateNow.html',
                controller: 'DashboardGenerateNowCtrl',
                scope: scope,
                backdrop: 'static',
                resolve: {
                    items: function () {
                        var attributes = {
                        };
                        return attributes;
                    },
                    closeModal: function() {
                      return scope.closeModal;
                    },
                    cameFromViewSummary: function() {
                      return scope.cameFromViewSummary;
                    }
                },
                windowClass: 'activity-summary-modal'
            });
          }

          var setSessionStorage = function() {
            reportPreferences.reset();
            if ($sessionStorage.reportPreferences)
            {
                $sessionStorage.reportPreferences = null;
                if ($sessionStorage.navDemographics) $sessionStorage.navDemographics = null;
            }
            if (scope.reportPreferences) {
              $sessionStorage.reportPreferences = scope.reportPreferences;
            } else {
              var surveys = [];
              surveys.push(scope.surveyId);
              //prep data in service and session to use in the actual report page
              reportPreferences.setSurveyContentTypeId(scope.surveyContentId != null  && scope.surveyContentId.length > 0 ? scope.surveyContentId : "-1");
              reportPreferences.setSurveyContentTypeName(scope.surveyContentId != null && scope.surveyContentId.length > 0 ? scope.surveyContentName : custom_content);
              reportPreferences.setSurveys(surveys);
              reportPreferences.setSurveyContentTypeNetworkAverage(scope.networkAverage != null && scope.networkAverage.length == 0 ? null : scope.networkAverage);
              $sessionStorage.reportPreferences = {};
              if (scope.survey)  {
                $sessionStorage.reportPreferences.reportName = JSON.parse(scope.survey).surveyName;
              }
              $sessionStorage.reportPreferences.surveyContentTypeId = reportPreferences.getSurveyContentTypeId();
              $sessionStorage.reportPreferences.surveyContentTypeName = reportPreferences.getSurveyContentTypeName();
              $sessionStorage.reportPreferences.surveys = reportPreferences.getSurveys();
              $sessionStorage.reportPreferences.networkAverage = reportPreferences.getSurveyContentTypeNetworkAverage();
            }
          }


          setSessionStorage()
          scope.openModal();
        }
    }
}]);
