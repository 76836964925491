
app.directive('scrollTo', function() {
  return {
    scope: {},
    link: function(scope, element, attrs) {
      $(element[0]).on('click', function () {
        var pageContainer = attrs.pageContainer || '.page-container';
        $(pageContainer).animate({
          scrollTop: $(pageContainer).scrollTop() + $(attrs.scrollTo).offset().top - 100
        }, 1000);
      });
    }
  }
})
