/**
 * Created by bhadaway on 7/31/17.
 */
/**
 * Created by bhadaway on 7/10/17.
 */

app.factory('browseContentService', ['$resource', 'applicationService', '$sessionStorage',
    function ($resource, applicationService, $sessionStorage) {
        return new BrowseContentService($resource, applicationService, $sessionStorage);
    }]);

function BrowseContentService(resourceService, applicationService, $sessionStorage) {

    // Load Browse Content Table with Diagnostics Content data
    var GET_TABS = hostname + "/echo-api/api/templates/browse/tabs?institutionId=";
    var GET_MY_CUSTOM_CONTENT = hostname + "/echo-api/api/templates/browse?institutionId=";
    var GET_ADVANCED_CERTIFIED_CONTENT = hostname + "/echo-api/api/templates/browse/certified?publishedOnly=true&institutionId=";
    var GET_PARENT_CUSTOM_CONTENT = hostname + "/echo-api/api/templates/browse?institutionId=";
    var GET_FILTER_OPTIONS = hostname + "/echo-api/api/config/targetGroups";

    // Retrieve tabs for browse content table
    this.getTabs = function (successGetTabsHandler, failureGetTabsHandler) {
        var urlResource = resourceService(GET_TABS + $sessionStorage.institution.id, {}, {
            getTabs: {
                method: 'GET',
                isArray: true
            }
        });

        urlResource.getTabs()
            .$promise.then(function (response) {
            successGetTabsHandler(response);
        }).catch(function error(msg) {
            //console.error("Error message", msg);
            failureGetTabsHandler(msg);
        });
    };

    // get My Custom Content api call for initial load
    this.getCustomContent = function (successGetCustomContentHandler, failureGetCustomContentHandler) {
        var urlResource = resourceService(GET_MY_CUSTOM_CONTENT + $sessionStorage.institution.id, {}, {
            getCustomContent: {
                method: 'GET',
                isArray: true
            }
        });

        urlResource.getCustomContent()
            .$promise.then(function (response) {
            successGetCustomContentHandler(response);
        }).catch(function error(msg) {
            //console.error("Error message", msg);
            failureGetCustomContentHandler(msg);
        });
    };

    // get AdvancED Certified Content
    this.getAdvancedCertifiedContent = function (successGetAdvancedCertifiedContentHandler, failureGetAdvancedCertifiedContentHandler) {
        var urlResource = resourceService(GET_ADVANCED_CERTIFIED_CONTENT + applicationService.getInstitutionId(), {}, {
            getAdvancedCertifiedContent: {
                method: 'GET',
                isArray: true
            }
        });

        urlResource.getAdvancedCertifiedContent()
            .$promise.then(function (response) {
            successGetAdvancedCertifiedContentHandler(response);
        }).catch(function error(msg) {
            //console.error("Error message", msg);
            failureGetAdvancedCertifiedContentHandler(msg);
        });
    };

    // get parent tabs content
    this.getParentTabCustomContent = function (successGetParentTabCustomContentHandler, failureGetParentTabCustomContentHandler, tabIndex, tabs) {
        var urlResource = resourceService(GET_PARENT_CUSTOM_CONTENT + $sessionStorage.institution.id + "&parentInstitutionId=" + tabs[tabIndex].id, {}, {
            getParentTabCustomContent: {
                method: 'GET',
                isArray: true
            }
        });

        urlResource.getParentTabCustomContent()
            .$promise.then(function (response) {
            successGetParentTabCustomContentHandler(response);
        }).catch(function error(msg) {
            //console.error("Error message", msg);
            failureGetParentTabCustomContentHandler(msg);
        });
    }

    // retrieve filter options with the default All being hard coded in the html with value of '' to not filter anything
    this.getFilterOptions = function (successGetFilterOptionsContentHandler, failureGetFilterOptionsHandler) {
        var urlResource = resourceService(GET_FILTER_OPTIONS, {}, {
            getFilterOptions: {
                method: 'GET',
                isArray: true
            }
        });

        urlResource.getFilterOptions()
            .$promise.then(function (response) {
            successGetFilterOptionsContentHandler(response);
        }).catch(function error(msg) {
            //console.error("Error message", msg);
            failureGetFilterOptionsHandler(msg);
        });
    }


}