/**
 * Created by pvo on 6/12/15.
 */
/**
 * Created by pvo on 3/27/15.
 */

app.factory('SurveyCreateService', [ '$resource', function($resource) {
    return $resource(hostname + '/echo-api/api/survey/create/:userId/:institutionId',
        null,
        {
            'saveEndAction': {
                url: hostname + '/echo-api/api/survey/endAction/:surveyId/:action',
                method:'GET',
                isArray:false
            },
            'copy': {url: hostname + '/echo-api/api/survey/copy/:copiedContentId/:userId/:institutionId',
                method:'GET',
                //dataString like '...'
                params:{surveyId:'@copiedContentId'}, // TODO: Will - this query param never makes it into the URL of the request
                isArray:false
            },
            'edit': {url: hostname + '/echo-api/api/survey/edit/:surveyId/:languageId',
                method:'GET',
                isArray:false
            },
            'open': {url: hostname + '/echo-api/api/survey/open/:userId/:surveyId/:templateId/:open/:deleteDraftResponses',
                method:'GET',
                isArray:false
            },
            'saveResponseGoal': {url: hostname + '/echo-api/api/survey/saveResponseGoal/:surveyId/:templateId/:targetCount',
                method:'GET',
                isArray:false
            },
            'copySurvey': {url: hostname + '/echo-api/api/survey/copySurvey/:copiedSurveyId/:certificationId/:userId/:institutionId',
                method:'GET',
                params:{copiedSurveyId:'@copiedSurveyId', certificationId: '@certificationId', userId: '@userId', institutionId: '@institutionId'},
                isArray:false
            },
            'getAllLanguages': {url: hostname + '/echo-api/api/surveys/language-config',
                method:'GET',
                isArray:true
            },
            'getAllLanguagesByTemplateId': {url: hostname + '/echo-api/api/surveys/language-config/template/:templateId',
                method:'GET',
                // TODO: Will - this query param never makes it into the URL of the request
                params:{templateId: '@templateId'},
                isArray:true
            },
            'saveLanguage': {url: hostname + '/echo-api/api/surveys/language-config/:templateId/:surveyId/:languageId/:userId',
                method:'POST',
                //dataString like '...'
                // TODO: Will - these params are sent both in the POST request body and the URL of the POST.
                params:{templateId: '@templateId', surveyId: '@surveyId', languageId: '@languageId', userId: '@userId'},
                isArray:false
            },
            'saveLanguageWithTranslation': {url: hostname + '/echo-api/api/surveys/language-config/:templateId/:surveyId/:languageId/:userId/:copiedTemplateId',
                method:'POST',
                //dataString like '...'
                // TODO: Will - these params are sent both in the POST request body and the URL of the POST.
                params:{templateId: '@templateId', surveyId: '@surveyId', languageId: '@languageId', userId: '@userId', copiedTemplateId: '@copiedTemplateId'},
                isArray:false
            },
            'deleteLanguage': {url: hostname + '/echo-api/api/surveys/language-config/delete/:templateId/:surveyId/:languageId/:userId',
                method:'DELETE',
                //dataString like '...'
                // TODO: Will - these query params never make it into the URL of the request but are included in the route params of the URL
                params:{templateId: '@templateId', surveyId: '@surveyId', languageId: '@languageId', userId: '@userId'},
                isArray:false
            },
            'getTranslatedContent': {url: hostname + '/echo-api/api/survey/translateContent/:surveyId/:languageId',
                method:'GET',
                // TODO: Will - these query params never make it into the URL of the request but are included in the route params of the URL
                params:{surveyId:'@surveyId', languageId: '@languageId'},
                isArray:false
            },
            'updateShowTitlesFlag': {url: hostname + '/echo-api/api/surveys/customization-config/updateShowTitlesFlag/:surveyId/:userId/:showSectionTitlesValue',
                method:'POST',
                // TODO: Will - these params are sent both in the POST request body and the URL of the POST.
                params:{surveyId:'@surveyId', userId: '@userId', showSectionTitlesValue:'@showSectionTitlesValue'},
                isArray:false
            },
            'getRedirectLink': {url: hostname + '/echo-api/api/surveys/customization-config/getRedirectLink/:surveyId',
                method:'GET',
                // TODO: Will - these query params never make it into the URL of the request but are included in the route params of the URL
                params:{surveyId:'@surveyId'},
                isArray:false
            },
            'saveRedirectLink': {url: hostname + '/echo-api/api/surveys/customization-config/saveRedirectLink/:surveyId?redirectLink=:redirectLink',
                method:'POST',
                // TODO: Will - these params are sent both in the POST request body and the URL of the POST, this one with a hard-coded query param.
                params:{surveyId:'@surveyId', redirectLink: '@redirectLink'},
                isArray:false
            },
            'getInstitutionIds': {url: users_api_hostname + '/users/:customerId/shared-institutions/:objectId/:objectTypeId',
                method:'GET',
                // TODO: Will - these query params never make it into the URL of the request but are included in the route params of the URL
                params:{customerId:'@customerId', objectId:'@objectId', objectTypeId:'@objectTypeId'},
                isArray:true
            },
            'getSurveySharedInstitutionIds': {url: hostname + '/echo-api/api/surveys/customization-config/:customerId/shared-institutions/:objectId/:objectTypeId',
                method:'GET',
                // TODO: Will - these query params never make it into the URL of the request but are included in the route params of the URL
                params:{customerId:'@customerId', objectId:'@objectId', objectTypeId:'@objectTypeId'},
                isArray:true
            },
            'getSharingControlInfo': {url: users_api_hostname + '/users/:customerId/sharing-control/:objectId/:objectTypeId',
                method:'GET',
                // TODO: Will - these query params never make it into the URL of the request but are included in the route params of the URL
                params:{customerId:'@customerId', objectId:'@objectId', objectTypeId:'@objectTypeId'},
                isArray:true
            },
            'getInstitutionDetails': {url: customer_api_hostname + '/customers/id/:institutionId',
                method:'GET',
                // TODO: Will - these query params never make it into the URL of the request but are included in the route params of the URL
                params:{institutionId:'@institutionId'},
                isArray:false
            },
            'getInstitutionsDetails': {url: customer_api_hostname + '/customers/details',
                method:'POST',
                //params:{sharingCustomerId:'@sharingCustomerId'},
                isArray:true
            },
            'getAllInstitutionsForSharingByInstitutionId': {url: customer_api_hostname + '/customers/sharingCustomerId/:sharingCustomerId',
                method:'GET',
                // TODO: Will - these query params never make it into the URL of the request but are included in the route params of the URL
                params:{sharingCustomerId:'@sharingCustomerId'},
                isArray:true
            },
            'getLicensedInstitutions': {url: sales_api_hostname + '/licenses/filter-custodians',
                method:'POST',
                //params:{sharingCustomerId:'@sharingCustomerId'},
                isArray:false
            },
            'saveInstitution': {url: users_api_hostname + '/users/share-object',
                method:'POST',
                isArray:false
            },
            'getUserRole': {url: users_api_hostname + '/users/role/:objectId/:objectType/:customerId/:userId',  // TODO: Roles Permissions - getUserRole method in surveyCreateService.js
                method:'GET',
                // TODO: Will - these query params never make it into the URL of the request but are included in the route params of the URL
                params:{objectId:'@objectId', objectType:'@objectType', customerId:'@customerId', userId:'@userId'},
                isArray:false
            },
            'updatePermission': {url: users_api_hostname + '/users/update-permissions/:applicationId/:objectTypeId/:customerId/:surveyId/:role', // TODO: Roles Permissions - updatePermission method in surveyCreateService
                method:'PUT',
                // TODO: Will - these params are sent both in the PUT request body and the URL of the PUT.
                params:{applicationId:'@applicationId', objectTypeId:'@objectTypeId', customerId:'@customerId', surveyId:'@surveyId', role: '@role'},
                isArray:false
            },
            'deleteInstitution': {url: users_api_hostname + '/users/remove-sharing/:objectId/:institutionId/:objectTypeId',
                method:'DELETE',
                //dataString like '...'
                // TODO: Will - these query params never make it into the URL of the request but are included in the route params of the URL
                params:{objectId:'@objectId', institutionId:'@institutionId', objectTypeId:'@objectTypeId'},
                isArray:false
            },
            'save': {url: hostname + '/echo-api/api/survey/save',
                method:'POST',
                //dataString like '...'
                //params:{dataString:'@dataString'},
                isArray:false
            },
            'update': {url: hostname + '/echo-api/api/survey/update',
                method:'POST',
                //dataString like '...'
                //params:{dataString:'@dataString'},
                isArray:false
            },
            'checkReportSurveyExistence': {url: users_api_hostname + '/users/report-survey-existence',
                method:'POST',
                //params:{institutionId:'@institutionId'},
                isArray:false
            },
            'associateSurveyWithUser': {url: users_api_hostname + '/users/save-object',
                method:'POST',
                //dataString like '...'
                //params:{dataString:'@dataString'},
                isArray:false
            },
            'updateRequireTokenFlag': {url: hostname + '/echo-api/api/surveys/customization-config/updateRequireTokenFlag/:surveyId/:userId/:requireTokenValue',
                method:'POST',
                // TODO: Will - these params are sent both in the POST request body and the URL of the POST.
                params:{surveyId:'@surveyId', userId: '@userId', requireTokenValue:'@requireTokenValue'},
                isArray:false
            }
        }
    );
}
]);