app.directive('eproveModal', [
    function(){
        return {
            replace: true,
            restrict: 'E',
            transclude: true,
            templateUrl: 'partials/application/eprove-modal.html'
        }
    }
])

app.directive('eproveFlyout', [
    function(){
        return {
            replace: true,
            restrict: 'E',
            transclude: true,
            templateUrl: 'partials/application/eprove-flyout.html'
        }
    }
])