app.controller('CreateReportsCtrl',
    [
        '$scope',
        '$rootScope',
        '$filter',
        '$route',
        '$location',
        '$anchorScroll',
        '$modal',
        '$timeout',
        '$localStorage',
        '$sessionStorage',
        '$q',
        '$window',
        'applicationService',
        'ReportService',
        'reportPreferences',
        'userService',
        'SurveyService',
        'ReportPdfService',
        'customerService',
    function(
        $scope,
        $rootScope,
        $filter,
        $route,
        $location,
        $anchorScroll,
        $modal,
        $timeout,
        $localStorage,
        $sessionStorage,
        $q,
        $window,
        applicationService,
        ReportService,
        reportPreferences,
        userService,
        SurveyService,
        ReportPdfService,
        customerService
    )
    {


        var createPath = '/create-report';
        var previewPath = '/preview-report';
        var editPath = '/edit-report';
        var viewPath = '/view-report';
        var topHeaderId = "topHeader";
        var create;
        var edit;
        var preview;
        var view;
        var maxHighestAverageItemRowCount = 5;
        var maxLowestAverageItemRowCount = 5;
        var selectGroupingDefault = 'Select';
        var currentDate = new Date();

        $scope.init = function() {
            $scope.userId = userService.getUserId();
            $scope.emailUser = userService.getUserEmail();
            $scope.institutionId = applicationService.getInstitution().id;
            $scope.institutionName = applicationService.getInstitution().name;
            $scope.languageId = english_language_id; //default to English for now; this will change IF/WHEN the app has full multi-language support
            $scope.reportPreferences = {};
            $scope.navigation();
            $scope.preferences();
            $scope.enableApply = false;
            $rootScope.enableSaveReport = false;
            $rootScope.isDirtyReportData = false;

            $scope.reportActions = [
                document.getElementsByClassName('report_action-0'),
                document.getElementsByClassName('report_action-1'),
                document.getElementsByClassName('report_action-2')
            ];

            //Create reports step 4 related variables
            $scope.reportSurveyTypes = dashboardSurveyTypes;
            $scope.reportSurveyStatuses = dashboardSurveyStatuses;
            $scope.selectedSurveyType = null;
            $scope.selectedSurveyStatus = null;
            $scope.surveyAllStatus = surveyAllStatus
            $scope.surveyArchivedStatus = surveyArchivedStatus;
            $scope.surveyClosedStatus = surveyClosedStatus;
            $scope.surveyClosingSoonStatus = surveyClosingSoonStatus;
            $scope.surveyDraftStatus = surveyDraftStatus;
            $scope.surveyOpenStatus = surveyOpenStatus;
            $scope.surveyAllType = surveyAllType;
            $scope.predicate = 'createdOn';
            $scope.reverse = true;

            //Reports preview page related variables
            $scope.reportScale = null;
            $scope.itemOptions = angular.copy(report_activity_item_chart_options);
            $scope.highestAverageStartInd = 0;
            $scope.lowestAverageStartInd = 0;
            $scope.expanded = true;
            $scope.maxShowLevel = 3;
            $scope.showFirstGroupingDropDown = false;
            $scope.showSecondGroupingDropDown = false;
            $scope.groupingOptions = [];
            $scope.firstGroupingOptions = [];
            $scope.secondGroupingOptions = [];
            $scope.selectedFirstGroupingOption = selectGroupingDefault;
            $scope.selectedSecondGroupingOption = selectGroupingDefault;
            $scope.groupingOptions.push(selectGroupingDefault);
            $scope.groupingOptions.push('Institution');
            $scope.firstGroupingOptions.push(selectGroupingDefault);
            $scope.secondGroupingOptions.push(selectGroupingDefault);
            $scope.demographics = null;
            $scope.reportPermissions = {
                export:true,
                share:true
            };
            if(userService.checkUserCurrentAssociationForPermission("export_report")){
                $scope.reportPermissions.export = false;
            }
            if(userService.checkUserCurrentAssociationForPermission("share_report")){
                $scope.reportPermissions.share = false;
            }
            $scope.institutionInfo =  applicationService.getInstitution();
            $scope.schoolsByParentId = [];
            $scope.institutionsHierarchy = null;

            $scope.reportIdNull = false;
        }

        // object for pdf export of the report
        // Export PDF
        $scope.enablePDFExport = pdf_reports_enabled;
        if ($scope.enablePDFExport) {
            $scope.pdfObject = {};
            $scope.pdfObject.sectionActivity = [];
            $scope.pdfObject.customItems = [];
        }

        //navigation preferences as far as headers etc, when invoked under different conditions
        $scope.navigation = function(){
            create = $route.current.$$route.create;
            edit = $route.current.$$route.edit;
            view = $route.current.$$route.view;
            preview = $route.current.$$route.preview;
            if ((create != undefined && create) || (edit != undefined && edit) || (view != undefined && view)) //create, edit, view report page has it's own header
            {
                $rootScope.headerAction = 'hidden';
                if (edit != undefined && edit)
                {
                    $scope.edit = true;
                }
                else if (view != undefined && view)$scope.view = true;
            }
            else
            {
                $rootScope.headerAction = 'active';
            }
        }

        //report preferences handled during page/controller transition plus page reload
        $scope.preferences = function(){
            var currentPath = $location.path();
            if (currentPath == createPath || currentPath == previewPath || currentPath == editPath || currentPath == viewPath) //if transitioning to create/preview/edit/view report ensure preferences transition too
            {
                if (reportPreferences.getHavePreferences())//check for reportPreferences in service
                {
                    $scope.populateReportPreferences();
                }
                else if ($sessionStorage.reportPreferences) //account for page reload, in which case preferences may be in session
                {
                    $scope.reportPreferences = $sessionStorage.reportPreferences;
                    if ($sessionStorage.navDemographics) $scope.navDemographics = $sessionStorage.navDemographics;
                }
            }
            else //starting a brand new report, clear report preferences
            {
                /*reportPreferences.reset();
                if ($sessionStorage.reportPreferences)
                {
                    $sessionStorage.reportPreferences = null;
                    if ($sessionStorage.navDemographics) $sessionStorage.navDemographics = null;
                }*/
            }
        }


        //Create a new report workflow
        $scope.launchReportWizard = function(){
            $scope.reportPreferences = {};
            reportPreferences.reset();
            if ($sessionStorage.reportPreferences)
            {
                $sessionStorage.reportPreferences = null;
                if ($sessionStorage.navDemographics)$sessionStorage.navDemographics = null;
            }

            reportPreferences.setHavePreferences(true);//indicates the start of report creation
            SurveyService.surveysForUserProfile($scope, $scope.launchSurveyContentSelection);
        }

        //step 1 of new report workflow
        $scope.launchSurveyContentSelection = function(){
            if(typeof $scope.surveyRequest == "undefined"){
                SurveyService.surveysForUserProfile($scope, $scope.launchSurveyContentSelection);
            }else {
                if ($scope.reportPreferences.surveys) $scope.reportPreferences.surveys = [];
                reportPreferences.setSurveys($scope.reportPreferences.surveys);
                var modalInstance = $modal.open({
                    templateUrl: 'partials/reports/createReportsStep1.html',
                    controller: 'CreateReportsWizardStep1Ctrl',
                    scope: $scope,
                    resolve: {
                        items: function () {
                            var attributes = {
                            };
                            return attributes;
                        }
                    },
                    windowClass: 'reveal-modal medium radius new-report-modal'
                });

                modalInstance.result.then(function (selectedItem) {
                    //clear any preferences set in step 2 previously, prior to heading back to step 2
                    $scope.reportPreferences.fromDate = null;
                    $scope.reportPreferences.fromDateCleared = false;
                    $scope.reportPreferences.toDate = null;
                    $scope.reportPreferences.toDateCleared = false;
                    $scope.launchReportDatePicker();
                }, function () {

                });
            }

        }

        //step 2 of new report workflow
        $scope.launchReportDatePicker = function(){
            if ($scope.reportPreferences.surveys) $scope.reportPreferences.surveys = [];
            reportPreferences.setSurveys($scope.reportPreferences.surveys);
            var modalInstance = $modal.open({
                templateUrl: 'partials/reports/createReportsStep2.html',
                controller: 'CreateReportsWizardStep2Ctrl',
                scope: $scope,
                resolve: {
                    items: function () {
                        var attributes = {
                        };
                        return attributes;
                    }
                },
                windowClass: 'reveal-modal medium radius new-report-modal'
            });

            modalInstance.result.then(function (selectedItem) {
                if (selectedItem.skipAhead)
                {
                    //clear out step 3 preferences if any, prior to skipping ahead to step 4
                    $scope.reportPreferences.institutions = [];
                    $scope.changePath(createPath);
                }
                else
                {
                    if (selectedItem.previous) //if user clicked previous, send them back to step 1
                    {
                        $scope.launchSurveyContentSelection();
                    }
                    else //else step 3
                    {
                        $scope.reportPreferences.institutions = [];
                        $scope.changePath(createPath);   // the institution selector is temporarily deferred - $scope.launchInstitutionSelection();
                    }
                }
            }, function () {
            });

        }

        //step 3 of new report workflow
        $scope.launchInstitutionSelection = function(){
            if ($scope.reportPreferences.surveys) $scope.reportPreferences.surveys = [];
            reportPreferences.setSurveys($scope.reportPreferences.surveys);
            var modalInstance = $modal.open({
                templateUrl: 'partials/reports/createReportsStep3.html',
                controller: 'CreateReportsWizardStep3Ctrl',
                scope: $scope,
                resolve: {
                    items: function () {
                        var attributes = {
                        };
                        return attributes;
                    }
                },
                windowClass: 'reveal-modal medium radius new-report-modal'
            });

            modalInstance.result.then(function (selectedItem) {
                if (selectedItem.skipAhead)
                {
                    $scope.changePath(createPath);
                }
                else {
                    if (selectedItem.previous) //if user clicked previous send them back to step 2
                    {
                        $scope.launchReportDatePicker();
                    }
                    else if (selectedItem.previousContent) //if user clicked previousContent send them back to step 1
                    {
                        $scope.launchSurveyContentSelection();
                    }
                    else //else step 4
                    {
                        $scope.changePath(createPath);
                    }
                }
            }, function () {

            });
        }

        //change path during workflow, reuse controller
        $scope.changePath = function(path){
            if (path == $location.path()) //if new path matches existing path trigger data reload with updated preferences
            {
                if (path == createPath) {
                    $scope.prepSurveysData($scope.surveyAllType, $scope.surveyAllStatus);
                }
            }
            else //controller instantiation will occur - need to set report preferences in service and session
            {
                $sessionStorage.reportPreferences = $scope.reportPreferences;
                reportPreferences.setData($scope.reportPreferences);
                $location.path(path);
            }
        }

        //transfer preferences from service to scope
        $scope.populateReportPreferences = function(){
            $scope.reportPreferences.reportId = reportPreferences.getReportId();
            $scope.reportPreferences.reportName = reportPreferences.getReportName();
            $scope.reportPreferences.fromDate = reportPreferences.getFromDate();
            $scope.reportPreferences.fromDateCleared = reportPreferences.getFromDateCleared();
            $scope.reportPreferences.toDate = reportPreferences.getToDate();
            $scope.reportPreferences.toDateCleared = reportPreferences.getToDateCleared();
            $scope.reportPreferences.surveyContentTypeId = reportPreferences.getSurveyContentTypeId();
            $scope.reportPreferences.surveyContentTypeName = reportPreferences.getSurveyContentTypeName();
            $scope.reportPreferences.networkAverage = reportPreferences.getSurveyContentTypeNetworkAverage();
            $scope.reportPreferences.institutions = reportPreferences.getInstitutions();
            $scope.reportPreferences.havePreferences = reportPreferences.getHavePreferences();
            $scope.reportPreferences.prevStepNum = reportPreferences.getPreviousStepNumber();
            $scope.reportPreferences.surveys = reportPreferences.getSurveys();
            $scope.reportPreferences.institutionsByHierarchy = reportPreferences.getInstitutionsByHierarchy();
            $scope.reportPreferences.groupingApplied = reportPreferences.getGroupingApplied();
        }

        //previous step clicked from step 4, previous could be any one of 1,2 or 3 depending on where user came from
        $scope.previousStep = function(){
            if ($scope.surveys != null && $scope.surveys != undefined) $scope.selectNoneSurveys($scope.surveys); //reset survey selection
            if ($scope.reportPreferences.prevStepNum)
            {
                if ($scope.reportPreferences.prevStepNum == 1)$scope.launchSurveyContentSelection();
                else if ($scope.reportPreferences.prevStepNum == 2)$scope.launchReportDatePicker();
                else $scope.launchInstitutionSelection();
            }
            else
            {
                $scope.launchSurveyContentSelection();
            }
        }

        //modify survey content selection, workflow step 1
        $scope.editSurveyContentSelection = function(){
            $scope.launchSurveyContentSelection();
        }

        //modify date range selection, workflow step 2
        $scope.editDateRangeSelection = function(){
            $scope.launchReportDatePicker();
        }

        //modify institution selection, workflow step 3
        $scope.institutionSelection = function(){
            $scope.launchInstitutionSelection();
        }


        //main data-set for create report workflow step 4
        $scope.prepSurveysData = function(type, status){
            if($sessionStorage.reportPreferences){
                $scope.reportPreferences = $sessionStorage.reportPreferences;
            }
            $scope.reportPreferences.typeFilter = type;
            $scope.reportPreferences.statusFilter = status;
            SurveyService.surveysForUserProfile($scope, $scope.retrieveSurveys);
        }

        $scope.retrieveSurveys = function(){
            $scope.reportPreferences.surveyIds = $scope.surveyRequest.surveyIds; //use the set of surveys this user has access to as the baseline
            $scope.fromDateForDisplay = ($scope.reportPreferences.fromDate ? new Date($scope.reportPreferences.fromDate) : null);
            $scope.toDateForDisplay = ($scope.reportPreferences.toDate ? new Date($scope.reportPreferences.toDate) : null);
            var reportPreferences = angular.copy($scope.reportPreferences);
            reportPreferences.fromDate = new Date(reportPreferences.fromDate).toJSON();
            reportPreferences.toDate = new Date(reportPreferences.toDate).toJSON();
            var body = {reportPreferences: reportPreferences};
            body.userId = userService.getUserId();
            body.institutionId = $scope.institutionId;
            body.languageId = $scope.languageId;
            ReportService.surveys(body).$promise.then(function(surveys) {
                let mySurveys = JSON.parse(JSON.stringify(surveys)); // TODO: Will - Resolve technical debt around usage
                                                                     // of $resource.
                                                                    // We should not have to stringify then parse the
                                                                    // response. Unsure why this is required.
                $scope.surveys = mySurveys;
                $scope.resetSurveySelections($scope.surveys, []);
            },function(httpResponse){
                $timeout(errorPopup, 500);
            });
        }

        //step 4 initialization for survey selection checkboxes
        $scope.resetSurveySelections = function(surveys, selectedSurveys){
            $scope.allSurveysSelected = true;
            surveys.forEach(survey => {
                if (selectedSurveys == null || selectedSurveys == undefined)
                {
                    survey.selected = false;
                    $scope.allSurveysSelected = false;
                }
                else
                {
                    var match = false;
                    for (var i in selectedSurveys)
                    {
                        if (selectedSurveys[i] == survey.surveyId)
                        {
                            survey.selected = true;
                            match = true;
                            break;
                        }
                    }
                    if (!match)
                    {
                        $scope.allSurveysSelected = false;
                        survey.selected = false;
                    }
                }
            }
           );
        }

        //sorting on the surveys in step 4
        $scope.order = function($event, predicate, reverse)
        {
            $event.stopImmediatePropagation(); //done to avoid the all.js element from being triggered (DS code)
            if ($scope.predicate && ($scope.predicate == predicate))
            {
                $scope.reverse = reverse; //use the parameter value only if predicate is the same as before
            }
            else $scope.reverse = true; //if predicate switches, default to descending sort
            $scope.predicate = predicate;
        }

        //when all checkbox clicked in step 4 of create report workflow
        $scope.toggleAll = function(surveys) {
            $scope.allSurveysSelected = !$scope.allSurveysSelected;
            if ($scope.allSurveysSelected)
            {
                $scope.selectNoneSurveys(surveys);
            }
            else {
                $scope.selectAllSurveys(surveys);
            };
        }

        //all surveys selected step 4
        $scope.selectAllSurveys = function(surveys)
        {
            for (var i in surveys)
            {
                surveys[i].selected = true;
                $scope.storeSurveyPreferences(surveys[i]);
            }
        }

        //de-select all surveys step 4
        $scope.selectNoneSurveys = function(surveys)
        {
            for (var i in surveys)
            {
                surveys[i].selected = false;
                $scope.storeSurveyPreferences(surveys[i]);
            }
        }

        //de-select all but the one specified survey step 4
        $scope.selectSingleSurvey = function(surveys, survey)
        {
            for (var i in surveys)
            {
                if (surveys[i].surveyId != survey.surveyId)
                {
                    surveys[i].selected = false;
                    $scope.storeSurveyPreferences(surveys[i]);
                }
            }
        }

        //individual survey checkbox select/de-select step 4
        $scope.storeSurveyPreferences = function(survey)
        {
            var index = $scope.reportPreferences.surveys? $scope.reportPreferences.surveys.indexOf(survey.surveyId) : -1;
            if (index > -1)
            {
                if (!survey.selected)
                {
                    $scope.reportPreferences.surveys.splice(index, 1);
                }
            }
            else
            {
                if (survey.selected)
                {
                    if (!$scope.reportPreferences.surveys)
                    {
                        $scope.reportPreferences.surveys = new Array();
                    }
                    else if ($scope.reportPreferences.surveyContentTypeId < 0)
                    {
                        $scope.selectSingleSurvey($scope.surveys, survey);
                    }
                    $scope.reportPreferences.surveys.push(survey.surveyId);
                }
            }

            $scope.allSurveysSelected = $scope.surveys.every(function(survey){ return survey.selected; });
        }

        //Report creation step 5 (preview report)
        $scope.previewReport = function()
        {
            $rootScope.cameFromSurveys = true;
          $sessionStorage.reportPreferences.surveyIds = $scope.reportPreferences.surveyIds;
          $sessionStorage.reportPreferences.surveys = $scope.reportPreferences.surveys;
          $scope.reportPreferences = $sessionStorage.reportPreferences;
          $scope.createReport();
        }

        $scope.createReport = function(location) {
          $scope.createReportStatus = !$scope.createReportStatus;
          if(location) $location.path(location);
        }

        //when a report is being edited, retrieve stored preferences for the report
        $scope.retrieveReportPreferences = function(preferences)
        {
            // if (!$scope.reportPreferences.surveys || $scope.reportPreferences.surveys.length==0)
            // {
                // ReportService.preferences({ reportId : $sessionStorage.reportPreferences.reportId, languageId: $scope.languageId}).$promise.then(function(preferences) {
                    $scope.reportPreferences.surveyContentTypeId = preferences.surveyContentTypeId;
                    $scope.reportPreferences.surveyContentTypeName = preferences.surveyContentTypeName;
                    $scope.reportPreferences.surveyContentTypeCertified = preferences.surveyContentTypeCertified;
                    $scope.reportPreferences.networkAverage = preferences.networkAverage;
                    $scope.reportPreferences.fromDate = preferences.fromDate != null ? $.datepicker.formatDate("mm/dd/yy", new Date(preferences.fromDate)) : null;
                    $scope.reportPreferences.toDate = preferences.toDate != null? $.datepicker.formatDate("mm/dd/yy", new Date(preferences.toDate)) : null;
                    $scope.reportPreferences.surveys = preferences.surveys;
                    $scope.reportPreferences.reportId = preferences.reportId;
                    $scope.fromDateForDisplay = ($scope.reportPreferences.fromDate ? new Date($scope.reportPreferences.fromDate) : null);
                    $scope.toDateForDisplay = ($scope.reportPreferences.toDate ? new Date($scope.reportPreferences.toDate) : null);
                    $scope.reportPreferences.reportName = preferences.reportName ? preferences.reportName : $scope.reportPreferences.surveyContentTypeName + ' - ' + currentDate.toISOString().slice(0,10);
                    console.log($scope.reportPreferences)
                    console.log(preferences);
                    console.log(preferences.reportName);
                    console.log($scope.reportPreferences.reportName);
                    if (preferences.filterIncompleteResponses)
                    {
                        $scope.reportPreferences.filterIncompleteResponses = preferences.filterIncompleteResponses;
                        $scope.reportPreferences.originalSurveys = preferences.originalSurveys; //in the event the user clears this filter
                        $scope.reportPreferences.filteredSurveys = preferences.filteredSurveys;
                    }
                    if (preferences.filterDemographic)
                    {
                        $scope.reportPreferences.filterDemographic = preferences.filterDemographic;
                        $scope.reportPreferences.filteredDemographicAliasOptions = preferences.filteredDemographicAliasOptions;
                    }
                    if (preferences.filterTags)
                    {
                        $scope.reportPreferences.filterTags = preferences.filterTags;
                        $scope.reportPreferences.filteredTags = preferences.filteredTags;
                    }
                    if (preferences.groupingApplied)
                    {
                        $scope.reportPreferences.groupingApplied = preferences.groupingApplied;
                        $scope.reportPreferences.firstGrouping = preferences.firstGrouping;
                        $scope.firstGroupingSelected = true;
                        if (preferences.secondGrouping)
                        {
                            $scope.reportPreferences.secondGrouping = preferences.secondGrouping;
                            $scope.secondGroupingSelected = true;
                        }
                    }
                    if (preferences.institutionFilter)
                    {
                        $scope.reportPreferences.institutionFilter = preferences.institutionFilter;
                        $scope.reportPreferences.institutions = preferences.institutions;
                    }
                    //if (preferences.languageFilter) {
                    //    $scope.reportPreferences.languageFilter = preferences.languageFilter;
                    //    $scope.reportPreferences.languages = preferences.languages;
                    //}

                    //populate these preferences both in the service and session to handle crossover across pages, reload
                    $sessionStorage.reportPreferences = $scope.reportPreferences;
                    reportPreferences.setData($scope.reportPreferences);

                    // return $scope.renderReportInit();
                    //now initialize report and render it
                // },function(httpResponse){
                //     $timeout(errorPopup, 500);
                //     $scope.resetLoadingStatus();
                // });
            // }
            // else $scope.renderReportInit();

        }

        $scope.setPreferences = function(reportPreferences) {
          $scope.reportPreferences = reportPreferences;
          $sessionStorage.reportPreferences = $scope.reportPreferences;

          $scope.firstGroupingSelected = ($scope.reportPreferences && $scope.reportPreferences.firstGrouping);
          $scope.secondGroupingSelected = ($scope.reportPreferences && $scope.reportPreferences.secondGrouping);

          $scope.fromDateForDisplay = ($scope.reportPreferences.fromDate ? new Date($scope.reportPreferences.fromDate) : null);
          $scope.toDateForDisplay = ($scope.reportPreferences.toDate ? new Date($scope.reportPreferences.toDate) : null);
          $scope.validateReportName();
        }

        $scope.renderReportInit = function(){
            $scope.loading = true;
            $scope.mainLoading = true;
            $scope.navLoading = true;
            if($scope.institutionInfo.type == "school"){
                //Do not send the institution hierarchy to the backend (Jira - ESURVEYS-1 and YouTrack - eprove_Surveys-705)
                $scope.reportPreferences.institutionsByHierarchy = null;
                //But the institution hierarchy is needed for the csv downloads
                $scope.institutionsHierarchy = [$scope.institutionInfo.id];
                return $scope.renderReport();
            } else {
                SurveyService.getCustomerHierarchy($scope.institutionInfo, getCustomerHierarchySuccess);
            }
        }

        var getCustomerHierarchySuccess = function(institutions){
            $scope.schoolsByParentId = institutions;
            $scope.reportPreferences.institutionsByHierarchy = null;
            $scope.institutionsHierarchy = _.pluck(institutions,"id");
            return $scope.renderReport();
        }

        //the actual rendering on preview report (step 5)
        $scope.renderReport = function(){
            retrieveReportData();
        }

        var retrieveReportData = function() {
          console.log('retrieveReportData...');
          var requestId = applicationService.getRequestId();
          ReportService.getDataFromCache({requestId: requestId}).$promise.then(function(data) {
            console.log(data);
            if (data.interpretationGuideInfo != null) {
              $scope.interpretationGuideBtText = data.interpretationGuideInfo.substring(0, data.interpretationGuideInfo.indexOf(','));
              $scope.interpretationGuideUrl = data.interpretationGuideInfo.substring(data.interpretationGuideInfo.indexOf(',') + 1);
            }

            if(data.reportId != null){
                applicationService.setReportId(data.reportId);
            }else{
                $scope.reportIdNull = true;
            }

            var json = JSON.parse(data.mainPayload);

            $scope.hideScore = json.hideScore;
            $scope.requestId = requestId;
            $scope.responseInstitutions = json.institutionDTOs;
            $scope.overallScore = json.overallScore;
            $scope.sectionActivity =  json.sectionScores;
            $scope.itemActivity = json.itemAverage;
            $scope.itemHighestActivity = json.highestItemScores;
            $scope.itemLowestActivity = json.lowestItemScores;
            $scope.completedDate = json.completeDateTime;

            if ( $scope.responseInstitutions < 1 ) {
                $scope.noDataToShare = true;
            }

            $scope.retrieveReportPreferences(json.reportPreferences);

            $scope.validateReportName();

            if ((edit != undefined && edit) || (view != undefined && view)) {
              $('.reports__header #h5-reports-title').show().text($scope.reportPreferences.reportName);
            }
            $scope.retrieveResponseInstitutions();


          }, function(httpResponse) {
            var msg = "There was an error validating report name."
            $timeout(sweetAlert("The system encountered a problem, please try again.", msg), 500);
          });
            ReportService.disableEmailNotification({requestId: requestId}).$promise.then(function(data) {

            })
        }

        $scope.resetReportData = function(){
            $scope.overallScore = null;
            $scope.reportScale = null;
            $scope.scoreData = null;
            $scope.sectionActivity = null;
            $scope.highestAverageStartInd = 0; //reset pagination of highest/lowest tabs
            $scope.lowestAverageStartInd = 0;
            $scope.itemHighestActivity = null;
            $scope.itemLowestActivity = null;
            $scope.itemHighestActivityPage = [];
            $scope.itemLowestActivityPage = [];
            $scope.itemActivity = null;
            $scope.customItems = [];
        }

        //specific actions on the preview page trigger reload
        $scope.triggerReportReload = function(){
            $scope.loading = true;
            $scope.resetReportData();
            $scope.renderReport();
        }

        //retrieve all institutions across the report responses
        $scope.retrieveResponseInstitutions = function(){
                // Export PDF
                if ($scope.enablePDFExport) {
                    $scope.pdfObject.responseInstitutions = $scope.responseInstitutions;
                    $scope.pdfObject.reportPreferences = $scope.reportPreferences;
                }

                if ($scope.schoolsByParentId.length > 0) {
                    for (var i=0; i <$scope.responseInstitutions.length; i++){
                        var response_inst = $scope.responseInstitutions[i];
                        var inst = _.where($scope.schoolsByParentId,{"id" : response_inst.id});
                        $scope.responseInstitutions[i].name = inst[0].name;
                    }
                    $scope.retrieveOverallScore();
                } else {
                    SurveyService.institutionForIds(0, $scope.responseInstitutions, $scope.retrieveOverallScore);
                }

                if (navigator.platform !== 'Win32' && navigator.platform !== 'Win64') {
                    var selector = '#edit-report__header-text';
                    $(selector).css('position', 'fixed');
                    $(selector).css('margin-left', '-3.25em');
                }

                // sort response institutions
                $scope.responseInstitutions.sort(function (a, b) {
                    if (a.name > b.name) {
                        return 1;
                    } else if (a.name < b.name) {
                        return -1;
                    } else {
                        return 0;
                    }
                })
        };


        //$scope.retrieveResponseLanguages = function() {
        //    var rp = angular.copy($scope.reportPreferences);
        //    ReportService.responseLanguages(rp, {userId: $scope.userId, institutionId: $scope.institutionId}).$promise.then(function(json) {
        //        console.log(json);
        //        $scope.reportLanguages = json.languages;
        //
        //        if (!$scope.reportPreferences.languages || !$scope.reportPreferences.languageFilter) {
        //            $scope.reportPreferences.languages = [];
        //            if ($scope.reportPreferences.languages.length > 0) {
        //                $scope.reportPreferences.languageFilter = false;
        //            }
        //        }
        //        $scope.pdfObject.responseLanguages = $scope.reportLanguages;
        //    });
        //};

        $scope.convertDateYYYYmmDD = function(date) {
            if (date == null) {
                return date;
            }
            else {
                var dateComponents = date.split('/');
                return dateComponents[2] + '-' + dateComponents[0] + '-' + dateComponents[1];
            }
        }

        //Overview tab related data - including overall score and section averages
        $scope.retrieveOverallScore = function(){
            var report = $scope.overallScore;

            $scope.highestAverageStartInd = 0; //reset pagination of highest/lowest tabs
            $scope.lowestAverageStartInd = 0;
            $scope.populateNavDemographics($scope.overallScore.demoAliases);
            $scope.populateNavTags(report.tags);
            $scope.markNavInstitutions($scope.responseInstitutions); //if saved preferences have institutions then this is where the selections will be marked
            $scope.reportScale = report.scale;

            if ($scope.reportScale && report.overallScore) {
                $scope.overallScoreOptions = ($scope.reportPreferences.networkAverage != null ? angular.copy(report_overall_score_options) : angular.copy(report_overall_score_options2));
                report.overallScore = $filter('number')(report.overallScore, 2);
                $scope.scoreData =
                    ($scope.reportPreferences.networkAverage != null ?
                        [
                            {'overallScore': report.overallScore, 'value': $scope.reportScale, 'networkAverage': $scope.reportPreferences.networkAverage}
                        ] :
                        [
                            {'overallScore': report.overallScore, 'value': $scope.reportScale}
                        ]);
            }

            // Export PDF
            if ($scope.enablePDFExport) {
                $scope.pdfObject.overallScore = $scope.overallScore;
            }

            if ($scope.overallScore.responseCount > 0) {
                if ($scope.reportScale)$scope.retrieveSectionAverages();
                $scope.retrieveItemAverages(true);
                if ($scope.groupingOptions.length == 2) $scope.populateDemographicAliases($scope.overallScore.demoAliases);
            }

            $scope.resetLoadingStatus();
        }

        $scope.retrieveSectionAverages = function(){

                var sectionActivity = $scope.sectionActivity;
                angular.forEach(sectionActivity, function(value, index){
                    sectionActivity[index].sectionAverage = $filter('number')(sectionActivity[index].sectionAverage, 2);
                    sectionActivity[index].sectionOptions = sectionActivity[index].sectionNetworkAverage != null ?  angular.copy(report_activity_section_chart_options) : angular.copy(report_activity_section_chart_options2);
                    sectionActivity[index].sectionScore =
                            sectionActivity[index].sectionNetworkAverage != null ?
                        [
                        {'sectionAverage':sectionActivity[index].sectionAverage, 'value': $scope.reportScale,'sectionNetworkAverage':sectionActivity[index].sectionNetworkAverage}
                        ]
                         :
                                [
                                    {'sectionAverage':sectionActivity[index].sectionAverage, 'value': $scope.reportScale}
                                ];
                });
                $scope.sectionActivity = sectionActivity;
                // Export PDF
                if ($scope.enablePDFExport) {
                    $scope.pdfObject.sectionActivity = $scope.sectionActivity;

                    if ($scope.generatePDFReportData) { // If PDF Report data was already generated without section activity
                        ReportPdfService.generateSectionActivity($scope.pdfObject);
                    }
                }
                $scope.createTabs();
        }

        $scope.createTabs = function(){
            if ($scope.hideScore) {
                $scope.tabs = [
                    {title: "Overview"}
                ];
            }
            else {
                $scope.tabs = [
                    {title: "Overview"},
                    {title: "Highest"},
                    {title: "Lowest"}
                ];
            }
        }

        $scope.selectTab = function(tab){
            if (tab.title == 'Overview')
            {
                $scope.reloadOverviewTab();
            }
            else if (tab.title == 'Highest')
            {
                if (!$scope.enablePDFExport) { // If pdf reports are enabled, disable this call as pdf call will handle this
                    // Export PDF
                    $scope.retrieveHighestAverages();
                }
            }
            else if (tab.title == 'Lowest')
            {
                if (!$scope.enablePDFExport) { // If pdf reports are enabled, disable this call as pdf call will handle this
                    // Export PDF
                    $scope.retrieveLowestAverages();
                }
            }
        }

        $scope.reloadOverviewTab = function(){
            $scope.highestAverageStartInd = 0; //reset pagination of highest/lowest tabs
            $scope.lowestAverageStartInd = 0;
        }

        //Highest scores/averages tab
        $scope.retrieveHighestAverages = function(){
            $scope.lowestAverageStartInd = 0;
            if ($scope.overallScore.responseCount > 0 && $scope.reportScale)
            {

                    var itemActivity = $scope.itemHighestActivity
                    if ($scope.enablePDFExport) {
                        // Export PDF
                        //AF Refactor
                        ReportPdfService.addHighestScoringItems(itemActivity);
                    }

                    angular.forEach(itemActivity, function(value, index){
                        itemActivity[index].itemAverage = $filter('number')(itemActivity[index].itemAverage, 2);
                        itemActivity[index].itemScore = [
                            {'itemAverage':itemActivity[index].itemAverage, 'value': $scope.reportScale}
                        ];
                    });
                    $scope.itemHighestActivity = itemActivity;

                    //Extract data from $scope.itemHighestActivity to pass on finalPdfReportObject
                    var highestScoringItems = [];
                    for(var i=0; i < $scope.itemHighestActivity.length; i++){
                        var item = {};
                        item.itemText = $scope.itemHighestActivity[i].name;
                        item.score = $scope.itemHighestActivity[i].itemScore[0].itemAverage;
                        item.networkAverage = $scope.itemHighestActivity[i].itemAverage;
                        item.itemLabel = $scope.itemHighestActivity[i].parentLabel + $scope.itemHighestActivity[i].label;
                        item.rank = $scope.itemHighestActivity[i].rank;
                        item.itemId = $scope.itemHighestActivity[i].itemId;
                        item.allOptions = $scope.itemHighestActivity[i].allOptions;
                        highestScoringItems.push(item);
                    }

                    var childItems = [];
                    var childItemsFlattened = [];
                    for(var v=0; v <  $scope.itemActivity.length; v++){
                        childItems.push($scope.itemActivity[v].childItems);
                    }
                    for(var w=0; w < childItems.length; w++){
                        var items = _.values(childItems[w]);
                        for(var x=0; x < items.length; x++){
                            childItemsFlattened.push(items[x]);
                        }
                    }

                    for(var i=0; i < highestScoringItems.length; i++){
                        for(var j=0; j < childItemsFlattened.length; j++){
                            var responseData = [];
                            if(highestScoringItems[i].itemId == childItemsFlattened[j].itemId){
                                if (childItemsFlattened[j].responseData.length > 0) {
                                    responseData = childItemsFlattened[j];
                                } else {
                                    var firstChildItems = childItemsFlattened[j].childItems;
                                    for(var a=0; a < firstChildItems.length; a++){
                                        if(firstChildItems[a].responseData.length > 0){
                                            responseData = firstChildItems[a];
                                        }
                                        else{
                                            var secondChildItems = firstChildItems[a].childItems;
                                            //for(var b=0; b < secondChildItems.responseData.length; b++){
                                                responseData = secondChildItems[0];
                                            //}
                                        }
                                    }
                                }
                            }
                            // get answers
                            var answers = [];
                            if (Object.keys(responseData).length > 0) {
                                var actualResponseData = responseData.responseData;
                                var totalCount = responseData.totalItemCount;
                                for (var k = 0; k < actualResponseData.length; k++) {
                                    var answer = {};
                                    answer.name = actualResponseData[k].itemText;
                                    answer.responsePercentage = actualResponseData[k].responsePercentage;
                                    answer.responseCount = actualResponseData[k].responseCount;
                                    answer.totalCount = totalCount;
                                    answers.push(answer);
                                }
                            }
                            if (answers.length > 0) {
                                highestScoringItems[i].responseData = answers;
                            }
                        }
                    }


                    $scope.retrieveHighestAveragePagination();

            }
            else
            {
                $scope.retrieveHighestAveragePagination();
            }

        }

        $scope.retrieveHighestAveragePagination = function(){
            if ($scope.itemHighestActivity != null)
            {
                $scope.itemHighestActivityPage = [];
                if ($scope.itemHighestActivity.length <= maxHighestAverageItemRowCount) //single page worth data
                {
                    $scope.itemHighestActivityPage = $scope.itemHighestActivity;
                    $scope.highestActivityNextPage = false;
                    $scope.highestActivityPreviousPage = false;
                }
                else
                {
                    $scope.highestActivityPreviousPage = ($scope.highestAverageStartInd > 0);  //whether to display previous button
                    var remainingItemLength = $scope.itemHighestActivity.length - $scope.highestAverageStartInd;
                    $scope.highestActivityNextPage = (remainingItemLength > maxHighestAverageItemRowCount); //whether to display next button
                    var endInd = ($scope.highestActivityNextPage) ? $scope.highestAverageStartInd + maxHighestAverageItemRowCount : $scope.highestAverageStartInd + remainingItemLength;
                    for (var i = $scope.highestAverageStartInd; i < endInd; i++)
                    {
                        $scope.itemHighestActivityPage.push($scope.itemHighestActivity[i]);
                    }
                }

            }
            $scope.resetLoadingStatus();
        }

        //setback the page start index
        $scope.retrieveHighestAveragePreviousPagination = function(){
            $scope.highestAverageStartInd = $scope.highestAverageStartInd - maxHighestAverageItemRowCount;
            $scope.retrieveHighestAveragePagination();
        }

        //advance the page start index
        $scope.retrieveHighestAverageNextPagination = function(){
            $scope.highestAverageStartInd = $scope.highestAverageStartInd + maxHighestAverageItemRowCount;
            $scope.retrieveHighestAveragePagination();
        }

        //lowest scores/averages
        $scope.retrieveLowestAverages = function(){
            $scope.highestAverageStartInd = 0;
            if ($scope.overallScore.responseCount > 0 && $scope.reportScale)
            {
                  var itemActivity = $scope.itemLowestActivity;
                    if ($scope.enablePDFExport) {
                        // Export PDF
                        //AF Refactor
                        ReportPdfService.addLowestScoringItems(itemActivity);
                    }


                    angular.forEach(itemActivity, function(value, index){
                        itemActivity[index].itemAverage = $filter('number')(itemActivity[index].itemAverage, 2);
                        itemActivity[index].itemScore = [
                            {'itemAverage':itemActivity[index].itemAverage, 'value': $scope.reportScale}
                        ];
                    });
                    $scope.itemLowestActivity = itemActivity;

                    //Extract data from $scope.itemLowestActivity to pass on finalPdfReportObject
                    var lowestScoringItems = [];
                    for(var i=0; i < $scope.itemLowestActivity.length; i++){
                        var item = {};
                        item.itemText = $scope.itemLowestActivity[i].name;
                        item.score = $scope.itemLowestActivity[i].itemScore[0].itemAverage;
                        item.networkAverage = $scope.itemLowestActivity[i].itemAverage; item.rank = $scope.itemLowestActivity[i].rank;
                        item.itemId = $scope.itemLowestActivity[i].itemId;
                        item.itemLabel = $scope.itemLowestActivity[i].parentLabel + $scope.itemLowestActivity[i].label;
                        item.allOptions = $scope.itemLowestActivity[i].allOptions;
                        lowestScoringItems.push(item);
                    }

                    var childItems = [];
                    var childItemsFlattened = [];
                    for(var v=0; v <  $scope.itemActivity.length; v++){
                        childItems.push($scope.itemActivity[v].childItems);
                    }
                    for(var w=0; w < childItems.length; w++){
                        var items = _.values(childItems[w]);
                        for(var x=0; x < items.length; x++){
                            childItemsFlattened.push(items[x]);
                        }
                    }

                    for(var i=0; i < lowestScoringItems.length; i++){
                        for(var j=0; j < childItemsFlattened.length; j++){
                            var responseData = [];
                            if(lowestScoringItems[i].itemId == childItemsFlattened[j].itemId){
                                if (childItemsFlattened[j].responseData.length > 0) {
                                    responseData = childItemsFlattened[j];
                                } else {
                                    var firstChildItems = childItemsFlattened[j].childItems;
                                    for(var a=0; a < firstChildItems.length; a++){
                                        if(firstChildItems[a].responseData.length > 0){
                                            responseData = firstChildItems[a];
                                        }
                                        else{
                                            var secondChildItems = firstChildItems[a].childItems;
                                            //for(var b=0; b < secondChildItems.responseData.length; b++){
                                            responseData = secondChildItems[0];
                                            //}
                                        }
                                    }
                                }
                            }
                            // get answers
                            var answers = [];
                            if (Object.keys(responseData).length > 0) {
                                var actualResponseData = responseData.responseData;
                                var totalCount = responseData.totalItemCount;
                                for (var k = 0; k < actualResponseData.length; k++) {
                                    var answer = {};
                                    answer.name = actualResponseData[k].itemText;
                                    answer.responsePercentage = actualResponseData[k].responsePercentage;
                                    answer.responseCount = actualResponseData[k].responseCount;
                                    answer.totalCount = totalCount;
                                    answers.push(answer);
                                }
                            }
                            if (answers.length > 0) {
                                lowestScoringItems[i].responseData = answers;
                            }
                        }
                    }

                    $scope.retrieveLowestAveragePagination();
            }
            else
            {
                $scope.retrieveLowestAveragePagination();
            }
        }

        $scope.retrieveLowestAveragePagination = function(){
            if ($scope.itemLowestActivity != null)
            {
                $scope.itemLowestActivityPage = [];
                if ($scope.itemLowestActivity.length <= maxLowestAverageItemRowCount)
                {
                    $scope.itemLowestActivityPage = $scope.itemLowestActivity;
                    $scope.lowestActivityNextPage = false;
                    $scope.lowestActivityPreviousPage = false;
                }
                else
                {
                    $scope.lowestActivityPreviousPage = ($scope.lowestAverageStartInd > 0);
                    var remainingItemLength = $scope.itemLowestActivity.length - $scope.lowestAverageStartInd;
                    $scope.lowestActivityNextPage = (remainingItemLength > maxLowestAverageItemRowCount);
                    var endInd = ($scope.lowestActivityNextPage) ? $scope.lowestAverageStartInd + maxLowestAverageItemRowCount : $scope.lowestAverageStartInd + remainingItemLength;
                    for (var i = $scope.lowestAverageStartInd; i < endInd; i++)
                    {
                        $scope.itemLowestActivityPage.push($scope.itemLowestActivity[i]);
                    }
                }
            }
            $scope.resetLoadingStatus();
        }

        $scope.retrieveLowestAveragePreviousPagination = function(){
            $scope.lowestAverageStartInd = $scope.lowestAverageStartInd - maxLowestAverageItemRowCount;
            $scope.retrieveLowestAveragePagination();
        }

        $scope.retrieveLowestAverageNextPagination = function(){
            $scope.lowestAverageStartInd = $scope.lowestAverageStartInd + maxLowestAverageItemRowCount;
            $scope.retrieveLowestAveragePagination();
        }

        $scope.sectionTooltip = function(networkAverage){
            if (networkAverage && networkAverage > 0)
            {
                return ("Network Average " + networkAverage);
            }
        }

        //retrieve all items within survey sections (minus demographics) with averages
        $scope.retrieveItemAverages = function(refreshLeftNav){
                var itemActivity = $scope.itemActivity;
                if ($scope.enablePDFExport) {
                    $scope.pdfObject.customTemplateItemExist = false;
                    $scope.pdfObject.customItemExist = false;
                    $scope.pdfObject.certifiedItemExist = false;
                    for (let i = 0; i < itemActivity.length; i++) {
                        if ( itemActivity[i].certifiedItem ) { $scope.pdfObject.certifiedItemExist = true; }
                        if ( itemActivity[i].customTemplateItem ) { $scope.pdfObject.customTemplateItemExist = true; }
                        if ( itemActivity[i].customSurveyOrCustomItemWithinCertifiedSurvey ) { $scope.pdfObject.customItemExist = true; }
                        for (let j = 0; j < itemActivity[i]['childItems'].length; j++) {
                            if ( itemActivity[i]['childItems'][j].certifiedItem ) { $scope.pdfObject.certifiedItemExist = true; }
                            if ( itemActivity[i]['childItems'][j].customTemplateItem ) { $scope.pdfObject.customTemplateItemExist = true; }
                            if ( itemActivity[i]['childItems'][j].customSurveyOrCustomItemWithinCertifiedSurvey ) { $scope.pdfObject.customItemExist = true; }
                        }
                    }
                }

                $scope.parseItems(itemActivity, refreshLeftNav);
                $scope.retrieveHighestAverages();
                $scope.retrieveLowestAverages();
                $scope.itemActivity = itemActivity;
        }

        //*** This method has too many side effect item activities from api response should be left pristine ***
        //parsing item activity for custom items, identifying demographics for left nav and parsing out opinion scale items for PDF export
        $scope.parseItems = function(itemActivity, refreshLeftNav){
            $scope.customItems = [];
            var customMap = {};
            var opinionScaleItems = [];
            var opinionScaleOptions = null;

            angular.forEach(itemActivity, function(item, index) {
                $scope.populateGroupingInstitutionName(item);

                if (item.demographic && refreshLeftNav) {
                  $scope.populateNavDemographicsData(item);
                }

                let itemChildren = item.childItems;

                if (itemChildren) {
                    var opinionScaleSection = null;
                    var customIndexes = [];

                    angular.forEach(itemChildren, function(childItem, childIndex) {
                        //check if a child item is a custom item within a certified content survey
                        var any_certified_custom_content = (childItem.elementContentType && (childItem.elementContentType == certified_custom_content));
                        var notCustomTemplateItemANDCustomSurveyOrCustomItemWithinCertifiedSurvey = !childItem.customTemplateItem && childItem.customSurveyOrCustomItemWithinCertifiedSurvey;

                        if (childItem.renderType == text_field_render_type) {
                            $scope.hasOpenEndedQuestion = true;
                        }

                        if ((any_certified_custom_content || notCustomTemplateItemANDCustomSurveyOrCustomItemWithinCertifiedSurvey) && childItem.surveyId) {

                            customIndexes.push(childIndex);
                            var customItem = {};
                            var customChildItem = {};
                            customChildItem.allOptions = childItem.allOptions;

                            //already 'seen' this survey?
                            if (childItem.surveyName in customMap) {
                                customItem = customMap[childItem.surveyName];

                                var foundItem = false;
                                for (var j = 0; j < customItem.childItems.length; j++) {
                                    //find the matching child(section) item for this survey
                                    if (customItem.childItems[j].name == item.name) {
                                        customChildItem = customItem.childItems[j];
                                        foundItem = true;
                                        break;
                                    }
                                }

                                if (!foundItem) {
                                    customChildItem.label = item.label;
                                    customChildItem.name = item.name;
                                    customChildItem.demographic = item.demographic;
                                    customChildItem.childItems = [];
                                    customItem.childItems.push(customChildItem);
                                }

                            } else {
                                customItem.surveyName = childItem.surveyName;
                                customMap[childItem.surveyName] = customItem;
                                customItem.childItems = [];
                                customChildItem.label = item.label;
                                customChildItem.name = item.name;
                                customChildItem.demographic = item.demographic;
                                customChildItem.childItems = [];
                                customItem.childItems.push(customChildItem);
                                $scope.customItems.push(customItem);
                            }

                            //push the item activity into the child item
                            customChildItem.childItems.push(childItem);                        }

                        //if this section has an opinion scale item, store this separately for PDF export
                        if (!any_certified_custom_content && childItem.renderType == opinion_scale_render_type) {
                            if (opinionScaleSection == null) {
                                opinionScaleSection = {};
                                opinionScaleSection.name = item.name;
                                opinionScaleSection.label = item.label;
                                opinionScaleSection.childItems = [];
                                opinionScaleItems.push(opinionScaleSection);
                            }
                            opinionScaleSection.childItems.push(childItem);
                            if (!opinionScaleOptions) {
                              //do this once since each item has same set of options
                              opinionScaleOptions = childItem.allOptions;
                            }
                        }
                    });
                }
            });

            var itemActivityLength = itemActivity.length;
            for (var k = itemActivityLength -1; k >= 0; k--) {
                if (itemActivity[k].childItems && itemActivity[k].childItems.length == 0) {
                    itemActivity.splice(k, 1);
                }
            }

            if ($scope.enablePDFExport) {
                // Export PDF
                $scope.pdfObject.itemActivity = itemActivity.map(filterPageBreaks);
                $scope.pdfObject.customItems = angular.copy($scope.customItems).map(function(survey) {
                    survey.childItems = survey.childItems.map(filterPageBreaks);
                    return survey;
                });
                // call this function to prep data in anticipation of the report being exported to PDF
                ReportPdfService.generatePDFReportData($scope, $scope.pdfObject, $scope.reportPreferences.reportName, opinionScaleItems, opinionScaleOptions);
                $scope.generatePDFReportData = true;
            }

        }

        function filterPageBreaks(section) {
            section.childItems = section.childItems.filter(function(item) {
                //page break items have a null renderType
                return item.renderType;
            });
            return section;
        }

        $scope.populateGroupingInstitutionName = function(item){
            if ($scope.reportPreferences.firstGrouping == 'Institution' || ($scope.reportPreferences.secondGrouping && $scope.reportPreferences.secondGrouping == 'Institution'))
            {
                var firstGroupingInstitution = ($scope.reportPreferences.firstGrouping == 'Institution');
                for (var childIndex=0; childIndex < item.childItems.length; childIndex++) //cycle through filtered data
                {
                    var childItem = item.childItems[childIndex];
                    if (childItem.childItems.length > 0) //have response data?
                    {
                        var firstGroupedChildItems = childItem.childItems;
                        //traverse first level of grouping
                        for (var firstGroupedIndex = 0; firstGroupedIndex < firstGroupedChildItems.length; firstGroupedIndex++) {
                            var firstGroupedChildItem = firstGroupedChildItems[firstGroupedIndex];
                            if (firstGroupingInstitution || firstGroupedChildItem.responseData.length > 0) //have response data? //single grouping only
                            {
                                    var firstGroupedDemoOption = firstGroupedChildItem.itemText;
                                    if ($scope.responseInstitutions)
                                    {
                                        var inst = _.where($scope.responseInstitutions, {'id' : parseInt(firstGroupedDemoOption)})
                                        firstGroupedChildItem.itemText = inst[0].name;
                                    }

                            }
                            else if (firstGroupedChildItem.childItems.length > 0 ) //have second layer of grouping?
                            {

                                var secondGroupedChildItems = firstGroupedChildItem.childItems;
                                for (var secondGroupedIndex = 0; secondGroupedIndex < secondGroupedChildItems.length; secondGroupedIndex++) {
                                    var secondGroupedChildItem = secondGroupedChildItems[secondGroupedIndex];
                                    if (secondGroupedChildItem.responseData.length > 0) //have response data?
                                    {
                                        var secondGroupedDemoOption = secondGroupedChildItem.itemText;
                                        if ($scope.responseInstitutions)
                                        {
                                            var inst = _.where($scope.responseInstitutions, {'id' : parseInt(secondGroupedDemoOption)})
                                            if (inst[0])secondGroupedChildItem.itemText = inst[0].name;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        //this populates the demographic aliases and options for the left nav
        $scope.populateNavDemographics = function(demoAliases) {
            if (!$scope.navDemographics)
            {
                $scope.markFilteredNavDemographics(demoAliases);
                $scope.navDemographics = demoAliases;
                $sessionStorage.navDemographics = $scope.navDemographics;
            }
        }

        //as the left nav is populated foe demographics, mark any options already selected and saved by user
        $scope.markFilteredNavDemographics = function(demoAliases)
        {
            if ($scope.reportPreferences.filteredDemographicAliasOptions)
            {
                for (var aliasInd in demoAliases)
                {
                    var aliasItem = demoAliases[aliasInd];
                    var demoAlias = aliasItem.alias;
                    for (var optionInd in aliasItem.aliasOptions)
                    {
                        var aliasOption = aliasItem.aliasOptions[optionInd];
                        var demoAliasOption = demoAlias + '|' + aliasOption.option;
                        var matchInd = $scope.reportPreferences.filteredDemographicAliasOptions.indexOf(demoAliasOption);
                        if (matchInd >= 0) aliasOption.filtered = true;
                    }
                    var demoAliasQuestionSkippedOption = demoAlias + '|' + question_skipped_display;
                    if ( $scope.reportPreferences.filteredDemographicAliasOptions.indexOf(demoAliasQuestionSkippedOption) >= 0) //if saved preferences has 'Question skipped' for this demographic
                    {
                        var skippedAliasOption = {};
                        skippedAliasOption.parentAlias = demoAlias;
                        skippedAliasOption.option = question_skipped_display;
                        skippedAliasOption.filtered = true;
                        aliasItem.aliasOptions.push(skippedAliasOption);
                    }
                }
            }
        }

        //this populates demographic response counts for left nav filter display
        $scope.populateNavDemographicsData = function(item){
            for(var navDemoIndex =0; navDemoIndex < $scope.navDemographics.length; navDemoIndex++)
            {
                var navDemoAlias = $scope.navDemographics[navDemoIndex].alias;
                var navDemoAliasMatchFound = false;
                for (var childIndex=0; childIndex < item.childItems.length; childIndex++) //cycle through filtered data
                {
                    var childItem = item.childItems[childIndex];
                    var alias = childItem.demographicAlias;
                    if (alias == navDemoAlias) //filtered data contains data for this demographic
                    {
                        var navDemoAliasOptions = $scope.navDemographics[navDemoIndex].aliasOptions; //list of options for this demographic
                        var skippedOptionExists = $scope.checkForQuestionSkippedDemographicOption(navDemoAliasOptions); //check if 'Question Skipped' already accounted for
                        var addQuestionSkippedOption = false;
                        var skippedResponseCount = 0;
                        for(var navOptionIndex =0; navOptionIndex < navDemoAliasOptions.length; navOptionIndex++)
                        {
                            var navDemoAliasOption = navDemoAliasOptions[navOptionIndex].option;
                            if (!$scope.reportPreferences.filterDemographic)navDemoAliasOptions[navOptionIndex].filtered = false; //if not filtering on demographics, make sure this option is unchecked
                            var navDemoOptionMatchFound = false;

                            if (childItem.responseData.length > 0) //have response data?
                            {
                                for (var respIndex = 0; respIndex < childItem.responseData.length; respIndex++)
                                {
                                    var demoData = childItem.responseData[respIndex];
                                    var demoOption = demoData.itemText;
                                    if (demoOption == navDemoAliasOption)//if data found for this demographic option
                                    {
                                        navDemoAliasOptions[navOptionIndex].responseCount = demoData.responseCount; //this is the latest count for the option
                                        navDemoOptionMatchFound = true;
                                        //break;
                                    }
                                    else if (demoOption == question_skipped_display && !skippedOptionExists) //if data contains 'Question Skipped' and this has not been accounted for
                                    {
                                        addQuestionSkippedOption = true;
                                        skippedResponseCount = demoData.responseCount;
                                    }
                                }
                            }
                            else if (childItem.childItems.length > 0) //this means grouping has been applied
                            {
                                var totalGroupedOptionResponseCount = 0; //placeholder for total count for an option across groupings
                                var totalGroupedSkippedOptionResponseCount = 0; //placeholder for total count for 'Question Skipped' across groupings
                                var groupingSkippedOptionFound = false;
                                var firstGroupedChildItems = childItem.childItems;
                                //traverse first level of grouping
                                for (var firstGroupedIndex = 0; firstGroupedIndex < firstGroupedChildItems.length; firstGroupedIndex++)
                                {
                                    var firstGroupedChildItem = firstGroupedChildItems[firstGroupedIndex];
                                    if (firstGroupedChildItem.responseData.length > 0) //have response data?
                                    {

                                        var firstGroupedResponseData = firstGroupedChildItem.responseData;
                                        for (var firstGroupResponseInd = 0; firstGroupResponseInd < firstGroupedResponseData.length; firstGroupResponseInd++)
                                        {
                                            var firstGroupedResponse = firstGroupedResponseData[firstGroupResponseInd];
                                            var firstGroupedDemoOption = firstGroupedResponse.itemText;
                                            if (firstGroupedDemoOption == navDemoAliasOption)//if data found for this demographic option
                                            {
                                                totalGroupedOptionResponseCount += firstGroupedResponse.responseCount; //this is the latest count for the option, increment total count with it
                                                navDemoOptionMatchFound = true;
                                            }
                                            else if (firstGroupedDemoOption == question_skipped_display && !skippedOptionExists) //if data contains 'Question Skipped' and this has not been accounted for
                                            {
                                                addQuestionSkippedOption = true;
                                                groupingSkippedOptionFound = true;
                                                totalGroupedSkippedOptionResponseCount += firstGroupedResponse.responseCount;
                                            }
                                        }
                                    }
                                    else if (firstGroupedChildItem.childItems.length > 0) //have second layer of grouping?
                                    {

                                        var secondGroupedChildItems = firstGroupedChildItem.childItems;
                                        for (var secondGroupedIndex = 0; secondGroupedIndex < secondGroupedChildItems.length; secondGroupedIndex++)
                                        {
                                            var secondGroupedChildItem = secondGroupedChildItems[secondGroupedIndex];
                                            if (secondGroupedChildItem.responseData.length > 0) //have response data?
                                            {

                                                var secondGroupedResponseData = secondGroupedChildItem.responseData;
                                                for (var secondGroupResponseInd = 0; secondGroupResponseInd < secondGroupedResponseData.length; secondGroupResponseInd++)
                                                {
                                                    var secondGroupedResponse = secondGroupedResponseData[secondGroupResponseInd];
                                                    var secondGroupedDemoOption = secondGroupedResponse.itemText;
                                                    if (secondGroupedDemoOption == navDemoAliasOption)//if data found for this demographic option
                                                    {
                                                        totalGroupedOptionResponseCount += secondGroupedResponse.responseCount; //this is the latest count for the option
                                                        navDemoOptionMatchFound = true;
                                                    }
                                                    else if (secondGroupedDemoOption == question_skipped_display && !skippedOptionExists) //if data contains 'Question Skipped' and this has not been accounted for
                                                    {
                                                        addQuestionSkippedOption = true;
                                                        groupingSkippedOptionFound = true;
                                                        totalGroupedSkippedOptionResponseCount += secondGroupedResponse.responseCount;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                if (navDemoOptionMatchFound)navDemoAliasOptions[navOptionIndex].responseCount = totalGroupedOptionResponseCount;
                            }

                            if (!navDemoOptionMatchFound)
                            {
                                navDemoAliasOptions[navOptionIndex].responseCount = 0; //no match equals 0 responses
                            }
                        }
                        if (addQuestionSkippedOption)$scope.addQuestionSkippedDemographicOption(navDemoAliasOptions, (groupingSkippedOptionFound ? totalGroupedSkippedOptionResponseCount : skippedResponseCount)); //add 'Question Skipped' option at the end of the list of options
                        navDemoAliasMatchFound = true;
                        break;
                    }
                }

                if (!navDemoAliasMatchFound) //if current data doesn't have data for this demographic, mark responses as 0 for each option
                {
                    var navDemoAliasOptions = $scope.navDemographics[navDemoIndex].aliasOptions;
                    for(var navOptionIndex =0; navOptionIndex < navDemoAliasOptions.length; navOptionIndex++) {
                        navDemoAliasOptions[navOptionIndex].responseCount = 0;
                        if (!$scope.reportPreferences.filterDemographic)navDemoAliasOptions[navOptionIndex].filtered = false;
                    }
                }
            }
        }

        //this function adds the 'Question_Skipped' option to the bottom of the demographic option list
        $scope.addQuestionSkippedDemographicOption = function(navDemoAliasOptions, skippedResponseCount){
            var navDemoAliasOption = new Object();
            navDemoAliasOption.option = question_skipped_display;
            navDemoAliasOption.responseCount = skippedResponseCount;
            navDemoAliasOptions.push(navDemoAliasOption)
        }

        //this function checks if 'Question_Skipped' already accounted for
        $scope.checkForQuestionSkippedDemographicOption = function(navDemoAliasOptions){
            var foundSkippedOption = false;
            for (var optionInd=0; optionInd < navDemoAliasOptions.length; optionInd++)
            {
                if (navDemoAliasOptions[optionInd].option == question_skipped_display)
                {
                    foundSkippedOption = true;
                    break;
                }
            }
            return foundSkippedOption;
        }

        //populates the tags used in the leftnav
        $scope.populateNavTags = function(tags)
        {
            $scope.markNavTags(tags);
            if (!$scope.tags)$scope.tags  = tags;
        }

        //as the left nav is populated for tags, mark any tags already selected and saved by user
        $scope.markNavTags = function(tags)
        {
            if ($scope.reportPreferences.filteredTags)
            {
                for (var tagInd in tags)
                {
                    var tag = tags[tagInd];
                    var matchInd = $scope.reportPreferences.filteredTags.indexOf(tag.tagId);
                    if (matchInd >= 0) tag.filtered = true;
                }
            }
        }

        //as the left nav is populated, mark any institution already selected and presisted
        $scope.markNavInstitutions = function(institutions) {
            if ($scope.reportPreferences.institutions)
            {
                for (var instInd in institutions)
                {
                    var institution = institutions[instInd];
                    var matchInd = $scope.reportPreferences.institutions.indexOf(institution.id);
                    if (matchInd >= 0) institution.filtered = true;
                }
            }
        };

        //$scope.markNavLanguages = function(languages) {
        //    if ($scope.reportPreferences.languages) {
        //        for (var index in languages) {
        //            var lang = languages[index];
        //            var matchingIndex = $scope.reportPreferences.languages.indexOf(lang.id);
        //            if (matchingIndex >= 0) {
        //                lang.filtered = true;
        //            }
        //        }
        //    }
        //};

        //incomplete surveys filter check/uncheck on left nav
        $scope.triggerIncompleteResponsesPreferences = function(){
            if ($scope.reportPreferences.filterIncompleteResponses)//checkbox checked
            {
                if (!$scope.reportPreferences.filteredSurveys)
                {
                    ReportService.filterIncompleteSurveys($scope.reportPreferences).$promise.then(function (filteredSurveys) {
                        $scope.reportPreferences.originalSurveys = angular.copy($scope.reportPreferences.surveys);
                        $scope.reportPreferences.filteredSurveys = filteredSurveys;
                        $scope.reportPreferences.surveys = $scope.reportPreferences.filteredSurveys;
                        $scope.storeDeletedSurveyPreferences();
                        $scope.triggerReportReload();
                    }, function (httpResponse) {
                        $timeout(errorPopup, 500);
                        $scope.resetLoadingStatus();
                    });
                }
                else
                {
                    $scope.reportPreferences.surveys = $scope.reportPreferences.filteredSurveys;
                    $scope.triggerReportReload();
                }
            }
            else //unchecked
            {
                $scope.reportPreferences.surveys = $scope.reportPreferences.originalSurveys;
                $scope.triggerReportReload();
            }
        }

        //if incomplete surveys filtered out, and existing report then store the incomplete surveyIds in anticipation the user saves this report
        $scope.storeDeletedSurveyPreferences = function(){
            if ($scope.reportPreferences.reportId) //existing report
            {
                if ($scope.reportPreferences.filteredSurveys.length < $scope.reportPreferences.originalSurveys.length)
                {
                    $scope.reportPreferences.clearFilteredSurveys = [];
                    for (var index in $scope.reportPreferences.originalSurveys)
                    {
                        if ($scope.reportPreferences.filteredSurveys.indexOf($scope.reportPreferences.originalSurveys[index]) < 0)
                            $scope.reportPreferences.clearFilteredSurveys.push($scope.reportPreferences.originalSurveys[index]);
                    }
                }
            }
        }

        //each time a demographic filter in the left nav is checked/unchecked
        $scope.triggerDemographicFilter = function(aliasItem, optionItem){
            var aliasOption = aliasItem.alias+'|'+optionItem.option;
            if (optionItem.filtered)
            {
                if (!$scope.reportPreferences.filterDemographic)
                {
                    $scope.reportPreferences.filterDemographic = true;
                    $scope.reportPreferences.filteredDemographicAliasOptions = [];
                }
                $scope.reportPreferences.filteredDemographicAliasOptions.push(aliasOption);
                if ($scope.reportPreferences.reportId)$scope.handleDemographicFilterDelete(false, aliasOption);
            }
            else
            {
                var index = $scope.reportPreferences.filteredDemographicAliasOptions.indexOf(aliasOption);
                $scope.reportPreferences.filteredDemographicAliasOptions.splice(index, 1);
                if ($scope.reportPreferences.filteredDemographicAliasOptions.length == 0) $scope.reportPreferences.filterDemographic = false;
                if ($scope.reportPreferences.reportId)$scope.handleDemographicFilterDelete(true, aliasOption);
            }

            // Apply
            //$scope.triggerReportReload();
            $scope.enableApplyFeature();
        }

        //this method handles clearing out demographic filter preferences for an existing report based on user changes
        $scope.handleDemographicFilterDelete = function(deleteOption, aliasOption){
            if (deleteOption) //used to be selected, now needs to be deleted
            {
               if(! $scope.reportPreferences.clearedDemographicFilters) $scope.reportPreferences.clearedDemographicFilters = [];
                $scope.reportPreferences.clearedDemographicFilters.push(aliasOption);
            }
            else //user added a filter, check if marked for delete and if so remove from delete list
            {
                if ($scope.reportPreferences.clearedDemographicFilters && $scope.reportPreferences.clearedDemographicFilters.length >0)
                {
                    var index = $scope.reportPreferences.clearedDemographicFilters.indexOf(aliasOption);
                    if (index >= 0) $scope.reportPreferences.clearedDemographicFilters.splice(index, 1);
                }
            }
        }

        $scope.triggerTagFilter = function(tag){
            if (tag.filtered)
            {
                if(!$scope.reportPreferences.filterTags)
                {
                    $scope.reportPreferences.filterTags = true;
                    $scope.reportPreferences.filteredTags = [];
                }

                $scope.reportPreferences.filteredTags.push(tag.tagId);
                if ($scope.reportPreferences.reportId)$scope.handleTagFilterDelete(false, tag.tagId);
            }
            else
            {
                var index = $scope.reportPreferences.filteredTags.indexOf(tag.tagId);
                $scope.reportPreferences.filteredTags.splice(index, 1);
                if ($scope.reportPreferences.filteredTags.length == 0) $scope.reportPreferences.filterTags = false;
                if ($scope.reportPreferences.reportId)$scope.handleTagFilterDelete(true, tag.tagId);
            }

            // Apply button
            //$scope.triggerReportReload();
            $scope.enableApplyFeature();
        }

        //this method handles clearing out tag filter preferences for an existing report based on user changes
        $scope.handleTagFilterDelete = function(deleteOption, tag) {
            if (deleteOption) //used to be selected, now needs to be deleted
            {
                if (!$scope.reportPreferences.clearedTagFilters) $scope.reportPreferences.clearedTagFilters = [];
                $scope.reportPreferences.clearedTagFilters.push(tag);
            }
            else //user added a filter, check if marked for delete and if so remove from delete list
            {
                if ($scope.reportPreferences.clearedTagFilters && $scope.reportPreferences.clearedTagFilters.length > 0) {
                    var index = $scope.reportPreferences.clearedTagFilters.indexOf(tag);
                    if (index >= 0) $scope.reportPreferences.clearedTagFilters.splice(index, 1);
                }
            }
        }

        $scope.triggerInstitutionFilter = function(institution){
            if (institution.filtered)
            {
                if(!$scope.reportPreferences.institutions)
                {
                    $scope.reportPreferences.institutions = [];
                }
                $scope.reportPreferences.institutionFilter = true;
                $scope.reportPreferences.institutions.push(institution.id);
                if ($scope.reportPreferences.reportId)$scope.handleInstitutionFilterDelete(false, institution.id);
            }
            else
            {
                var index = $scope.reportPreferences.institutions.indexOf(institution.id);
                $scope.reportPreferences.institutions.splice(index, 1);
                if ($scope.reportPreferences.institutions.length == 0) $scope.reportPreferences.institutionFilter = false;
                if ($scope.reportPreferences.reportId)$scope.handleInstitutionFilterDelete(true, institution.id);
            }

            // Apply button
            //$scope.triggerReportReload();
            $scope.enableApplyFeature();
        };

        $scope.printRP = function() {};

        //$scope.triggerLanguageFilter = function(language) {
        //    if (language.filtered) {
        //        if (!$scope.reportPreferences.languages) {
        //            $scope.reportPreferences.languages = [];
        //        }
        //        $scope.reportPreferences.languageFilter = true;
        //        $scope.reportPreferences.languages.push(language.id);
        //
        //        if ($scope.reportPreferences.reportId) {
        //            $scope.reportPreferences.clearedLanguages ? angular.noop : $scope.reportPreferences.clearedLanguages = [];
        //            $scope.handleFilterDelete(false, language.id, $scope.reportPreferences.clearedLanguages);
        //        }
        //    } else {
        //        var index = $scope.reportPreferences.languages.indexOf(language.id);
        //        $scope.reportPreferences.languages.splice(index, 1);
        //
        //        if ($scope.reportPreferences.languages.length == 0) {
        //            $scope.reportPreferences.languageFilter = false;
        //        }
        //
        //        if ($scope.reportPreferences.reportId) {
        //            $scope.reportPreferences.clearedLanguages ? angular.noop : $scope.reportPreferences.clearedLanguages = [];
        //            $scope.handleFilterDelete(true, language.id, $scope.reportPreferences.clearedLanguages);
        //        }
        //    }
        //
        //    $scope.triggerReportReload();
        //};


        $scope.handleInstitutionFilterDelete = function(deleteOption, instititution)
        {
            if (deleteOption) //used to be selected, now needs to be deleted
            {
                if (!$scope.reportPreferences.clearedInstitutions) $scope.reportPreferences.clearedInstitutions = [];
                $scope.reportPreferences.clearedInstitutions.push(instititution);
            }
            else //user added a filter, check if marked for delete and if so remove from delete list
            {
                if ($scope.reportPreferences.clearedInstitutions && $scope.reportPreferences.clearedInstitutions.length > 0) {
                    var index = $scope.reportPreferences.clearedInstitutions.indexOf(instititution);
                    if (index >= 0) $scope.reportPreferences.clearedInstitutions.splice(index, 1);
                }
            }
        };

        /**
         * @name handleFilterDelete
         * @description handles storage of filters to-be-deleted from preferences
         * @param deleteOption - BOOLEAN
         * @param filter - INTEGER
         * @param clearedArray - $scope.reportPreferences[<clearedFilter>]
         */
        //$scope.handleFilterDelete = function(deleteOption, filter, clearedArray) {
        //    if (deleteOption) {
        //        if (!clearedArray) {
        //            clearedArray = [];
        //        }
        //        clearedArray.push(filter);
        //    } else {
        //        if (clearedArray && clearedArray.length > 0) {
        //            var index = clearedArray.indexOf(filter);
        //            if (index >= 0) {
        //                clearedArray.splice(index, 1);
        //            }
        //        }
        //    }
        //};

        //$scope.triggerLanguageFilter = function(language) {
        //
        //}

        $scope.anyFilterSelected = function() {
            return $scope.reportPreferences && ($scope.reportPreferences.groupingApplied || $scope.reportPreferences.filterIncompleteResponses || $scope.reportPreferences.filterDemographic || ($scope.reportPreferences.filteredTags && $scope.reportPreferences.filteredTags.length > 0) || $scope.reportPreferences.institutionFilter) /*|| $scope.reportPreferences.languageFilter*/
        }

        $scope.enableClearFilterButton = function() {
            return $scope.anyFilterSelected();
        }

        $scope.enableApplyFeature = function() {
            $scope.enableApply = true;
            $rootScope.enableSaveReport = true;
            $rootScope.isDirtyReportData = true;
        }

        $scope.disableApplyFeature = function() {
            $scope.enableApply = false;
            $rootScope.enableSaveReport = false;
        }

        $scope.isApplyFeatureEnabled = function() {
            return $scope.enableApply == true;
        }

        $scope.enableApplyFilterButton = function() {
            return $scope.isApplyFeatureEnabled(); // $scope.anyFilterSelected() &&
        }

        $scope.applyFilter = function() {
            $scope.disableApplyFeature();
            if ($rootScope.isDirtyReportData) {
                $scope.closeModal();
            }
        }

        $scope.closeModal = function() {
          $scope.filterReport = !$scope.filterReport;
        }

        //clear all filters on left nav
        $scope.clearFilters = function(){
            if ($scope.anyFilterSelected())
            {
                // clear group by
                if ($scope.reportPreferences.groupingApplied) {
                  $scope.reportPreferences.groupingApplied = false;
                  if ($scope.firstGroupingSelected) $scope.firstGroupingSelected = false;
                  if ($scope.secondGroupingSelected) $scope.secondGroupingSelected = false;
                  if ($scope.reportPreferences.firstGrouping) $scope.reportPreferences.firstGrouping = null;
                  if ($scope.reportPreferences.secondGrouping) $scope.reportPreferences.secondGrouping = null;
                }
                //clear incomplete surveys filter
                if ($scope.reportPreferences.filterIncompleteResponses)
                {
                    $scope.reportPreferences.surveys = $scope.reportPreferences.originalSurveys;
                    $scope.reportPreferences.filterIncompleteResponses = false;
                }

                //clear demographic filters
                console.log($scope.reportPreferences.filterDemographic);
                if ($scope.reportPreferences.filterDemographic)
                {
                    $scope.reportPreferences.filterDemographic = false;
                    console.log($scope.reportPreferences.reportId, "report id");
                    if ($scope.reportPreferences.reportId) //if editing a report, before clearing filters mark them so they can be deleted from the DB
                    {
                      console.log("filterdemoalias", $scope.reportPreferences.filteredDemographicAliasOptions)
                        for (var ind in $scope.reportPreferences.filteredDemographicAliasOptions)
                        {
                            $scope.handleDemographicFilterDelete(true, $scope.reportPreferences.filteredDemographicAliasOptions[ind]);
                        }
                    }
                    $scope.reportPreferences.filteredDemographicAliasOptions = [];
                    if ($scope.navDemographics)
                    {
                      $scope.navDemographics.forEach(function(aliasItem) {
                        aliasItem.aliasOptions.forEach(function(aliasOption) {
                          aliasOption.filtered = false;
                        });
                      });
                    }
                }

                //clear tag filters
                if ($scope.reportPreferences.filterTags)
                {
                    $scope.reportPreferences.filterTags = false;
                    if ($scope.reportPreferences.reportId) //if editing a report, before clearing filters mark them so they can be deleted from the DB
                    {
                        for (var ind in $scope.reportPreferences.filteredTags)
                        {
                            $scope.handleTagFilterDelete(true, $scope.reportPreferences.filteredTags[ind]);
                        }
                    }
                    $scope.reportPreferences.filteredTags = [];
                    if ($scope.tags)
                    {
                        for (var i in $scope.tags){
                            $scope.tags[i].filtered = false;
                        }
                    }
                }

                //clear institution filters
                if ($scope.reportPreferences.institutionFilter)
                {
                    $scope.reportPreferences.institutionFilter = false;
                    if ($scope.reportPreferences.reportId) //if editing a report, before clearing filters mark them so they can be deleted from the DB
                    {
                        for (var ind in $scope.reportPreferences.institutions)
                        {
                            $scope.handleInstitutionFilterDelete(true, $scope.reportPreferences.institutions[ind]);
                        }
                    }
                    $scope.reportPreferences.institutions = [];
                    if ($scope.responseInstitutions)
                    {
                        for (var i in $scope.responseInstitutions){
                            $scope.responseInstitutions[i].filtered = false;
                        }
                    }
                }
                $scope.enableApplyFeature();

                // clear language filter
                //if ($scope.reportPreferences.languageFilter) {
                //    $scope.reportPreferences.languageFilter = false;
                //    if ($scope.reportPreferences.reportId) { // if editing a report, before clearing filters mark them so they can be deleted from the db
                //        for (var index in $scope.reportPreferences.languages) {
                //            $scope.handleFilterDelete(true, $scope.reportPreferences.languages[index], $scope.reportPreferences.clearedLanguages);
                //        }
                //    }
                //    $scope.reportPreferences.languages = [];
                //    if ($scope.reportLanguages) {
                //        $scope.reportLanguages.forEach(function(language) {
                //            console.log(index);
                //           language.filtered = false;
                //        })
                //    }
                //}

                // $scope.applyFilter();
            }
            // $scope.disableApplyFeature()
        };

        //invoked when either of the groupings are clicked on the top section of reports preview page
        $scope.groupingClick = function(){
          if (!$scope.firstGroupingSelected)
          {
              if ($scope.firstGroupingOptions.length == 1) //populate the dropdown the first time
              {
                  for (var i=1; i < $scope.groupingOptions.length; i++)
                  {
                      $scope.firstGroupingOptions.push($scope.groupingOptions[i]);
                  }
              }
              $scope.showFirstGroupingDropDown = true;
          }
          else if (!$scope.secondGroupingSelected)
          {
              if ($scope.secondGroupingOptions.length > 1) //if second dropdown already populated, reset it first
              {
                  $scope.secondGroupingOptions = [];
                  $scope.secondGroupingOptions.push(selectGroupingDefault);
              }
              for (var i=1; i < $scope.groupingOptions.length; i++) //(re)populate the dropdown minus the first dropdown selection
              {
                  if ($scope.reportPreferences.firstGrouping != $scope.groupingOptions[i])$scope.secondGroupingOptions.push($scope.groupingOptions[i]);
              }
              $scope.showSecondGroupingDropDown = true;
          }
        }

        $scope.firstGroup = function() {
          if (!$scope.firstGroupingSelected && $scope.firstGroupingOptions.length == 1) {
            for (var i=1; i < $scope.groupingOptions.length; i++) {
              $scope.firstGroupingOptions.push($scope.groupingOptions[i]);
            }
          }
          $scope.showFirstGroupingDropDown = true;
        };

        $scope.secondGroup = function() {
          if (!$scope.secondGroupingSelected) {
            $scope.secondGroupingOptions = [];
            $scope.secondGroupingOptions.push(selectGroupingDefault);
            for (var i=1; i < $scope.groupingOptions.length; i++) {
              if ($scope.reportPreferences.firstGrouping != $scope.groupingOptions[i]) $scope.secondGroupingOptions.push($scope.groupingOptions[i]);
            }
            $scope.showSecondGroupingDropDown = true;
          }
        }

        $scope.selectGrouping = function(first, selection)
        {
            if (first)
            {
                $scope.firstGroupingSelected = true;
                $scope.reportPreferences.firstGrouping = selection;
                $scope.showFirstGroupingDropDown = false;
            }
            else
            {
                $scope.secondGroupingSelected = true;
                $scope.reportPreferences.secondGrouping = selection;
                $scope.showSecondGroupingDropDown = false;
            }
            $scope.enableApplyFeature();
            $scope.reportPreferences.groupingApplied = true;
            // $scope.retrieveItemAverages(false);
        }


        //used to figure out the demographic aliases across the report
        $scope.populateDemographicAliases= function(demographicAliases){
            for (var i=0; i<demographicAliases.length; i++)
            {
                $scope.groupingOptions.push(demographicAliases[i].alias);
            }
        }

        //invoked anytime the selected grouping is closed
        $scope.collapseGrouping = function(first){
         if (! (first && $scope.showSecondGroupingDropDown)) //if user chose to close first grouping, but second grouping selection is not complete - do nothing
         {
             if (first) //closing first selection
             {
                 if ($scope.secondGroupingSelected) {
                     $scope.reportPreferences.firstGrouping = $scope.reportPreferences.secondGrouping; //second grouping now becomes first
                 }
                 else {
                     if ($scope.reportPreferences.reportId) $scope.reportPreferences.clearGrouping = true;
                     $scope.firstGroupingSelected = false;
                     $scope.reportPreferences.firstGrouping = null;
                     $scope.reportPreferences.groupingApplied = false;
                     $scope.selectedFirstGroupingOption = selectGroupingDefault;
                 }
             }
             $scope.secondGroupingSelected = false;
             $scope.reportPreferences.secondGrouping = null;
             $scope.selectedSecondGroupingOption = selectGroupingDefault;
             //apply new grouping preferences
            //  $scope.mainLoading = true;
            //  $scope.retrieveItemAverages(false);
         }
        }

        //utility method to expand/collapse item
        $scope.toggleItemDisplay = function(activityLevel,  expandedFlag, parentExpandedFlag, grandParentExpandedFlag){
            if (activityLevel == 0)
            {
                return expandedFlag;
            }
            else if (activityLevel == 1)
            {
                return expandedFlag && parentExpandedFlag;
            }
        }

        //scroll down to selected item
        $scope.navigateToItem = function(itemId, status){
            var old = $location.hash();
            $location.hash(itemId);
            $anchorScroll();
            $location.hash(old);
            document.getElementById(topHeaderId).scrollIntoView();
        }

        //persist report preferences
        $scope.saveReport = function() {

                    if (!$scope.reportPreferences.reportId) {
                        $scope.reportPreferences.createReport = true;
                    }

            // $rootScope.isDirtyReportData = false;
            var reportPreferencesInputDTO = new Object();
            reportPreferencesInputDTO.userId = userService.getUserId();
            reportPreferencesInputDTO.institutionId = $scope.institutionId;
            reportPreferencesInputDTO.languageId = $scope.languageId;
            reportPreferencesInputDTO.requestId = applicationService.getRequestId();
            reportPreferencesInputDTO.reportPreferences = angular.copy($scope.reportPreferences);
            reportPreferences.setReportName($scope.reportPreferences.reportName);

            reportPreferencesInputDTO.reportPreferences.fromDate = $scope.convertDateYYYYmmDD(reportPreferencesInputDTO.reportPreferences.fromDate);
            reportPreferencesInputDTO.reportPreferences.toDate = $scope.convertDateYYYYmmDD(reportPreferencesInputDTO.reportPreferences.toDate);

            // if ($scope.isApplyFeatureEnabled()) {
                // $scope.applyFilter();
            // }

                ReportService.saveReport(reportPreferencesInputDTO).$promise.then(function(preferences) {
                        $scope.applyFilter();
                        if (!$scope.reportPreferences.reportId) //if successfully saved, save the user-report association
                        {
                            ReportService.saveReportUser($scope.prepareUserReportData(preferences.reportId)).$promise.then(function(response){

                            }, function(httpResponse){
                                //if error saving user-report association, mark this report as deleted on the surveys side to preserve atomicity
                                ReportService.deleteReport({reportId : preferences.reportId, userId : userService.getUserId()}).$promise.then(function(success) {

                                }, function(httpResponse){

                                });
                            })
                            return $scope.saveReportCompletionCreateMode(preferences.reportId);
                        }
                        //else return $scope.saveReportCompletionEditMode();
                        else return $scope.saveReportCompletionCreateMode(preferences.reportId);
                    },function(httpResponse){
                        if(httpResponse.status === 409 ){
                            $scope.closeModal();

                            $timeout(sweetAlert("This report is currently in progress, a new request cannot be submitted at this time."), 500).then(function(){
                                $('#activityDetailsLoader').addClass('ng-hide');
                                $scope.activityLoading = false;
                                $('#sectionScoresError').removeClass('ng-hide');
                            });
                        } else{
                            $timeout(errorPopup, 500);
                        }
                    });
        }

        $scope.prepareUserReportData = function(reportId)
        {
            var userReportData = new Object();
            userReportData.objectType = 'report';
            userReportData.objectInstanceId = reportId;
            userReportData.ownerUserId = userService.getUserId();
            userReportData.customerId = $scope.institutionId;
            userReportData.userId = userService.getUserId();
            userService.admin(userReportData,'roles',true); // This sets the roles on the user report to ROLE_ADMIN prior to making the API call.
            userReportData.applicationId = 2;
            return userReportData;
        }

        //handle report create success transition to display exports, share options
        $scope.saveReportCompletionCreateMode = function(reportId){
            var set_title;
            if ($('.reports__title input').val() !== '') {
                set_title = $('.reports__title input').val();
                $('#report-header #h5-reports-title').show().text(set_title);
                $('.reports__title:first').hide();
                $('.reports__title .button').css('border', '1px dotted');

                if ($location.url() !== '/edit-report') {
                    $('.reports__title .button').parent().html('<p><span class="icon icon-check"></span> Saved</p>').delay(1000).fadeOut(600);
                    $('.reports__header-buttons').delay(1700).fadeIn(200);
                } else {

                    //// Peter's js animation start
                    //
                    var DOMComponent = function() {};

                    var Request = function(selector) {
                        DOMComponent.call(this);
                        this.selector = selector;
                    };

                    Request.prototype = Object.create(DOMComponent.prototype);
                    Request.prototype.constructor = Request;

                    Request.prototype.hide = function() {
                        $(this.selector).fadeOut({
                            duration: 200,
                            easing: 'linear',
                            queue: true,
                            fail: function() {},
                            done: function() {}
                        })
                    };

                    Request.prototype.show = function() {
                        $(this.selector).fadeIn({
                            duration: 200,
                            easing: 'linear',
                            queue: true,
                            fail: function() {},
                            done: function() {}
                        })
                    };

                    var Response = function(selector) {
                        DOMComponent.call(this);
                        this.selector = selector;
                    };

                    Response.prototype = Object.create(DOMComponent.prototype);
                    Response.prototype.constructor = Response;

                    Response.prototype.hide = function() {
                        $(this.selector).fadeOut({
                            duration: 200,
                            easing: 'linear',
                            queue: true,
                            fail: function() {},
                            done: function() {}
                        })
                    };

                    Response.prototype.show = function() {
                        $(this.selector).fadeIn({
                            duration: 200,
                            easing: 'linear',
                            queue: true,
                            fail: function() {},
                            done: function() {}
                        })
                    };

                    var parentSelector = '#save-report';
                    var saveReport = new Request($($(parentSelector).find('button')));
                    var saved = new Response($($(parentSelector).find('div')));

                    saveReport.hide();
                    setTimeout(function(){
                        saved.show(); // @200
                        setTimeout(function(){ // @1000
                            saved.hide(); // @1200
                            setTimeout(function(){ // @1200
                                saveReport.show(); // @1400
                            }, 200)
                        }, 1800)
                    }, 200);

                    //// animation end

                }

                // $('.reports__header-buttons .small-12')
                // $('.reports__header-buttons .small-6')
                // $('.reports__header-buttons').delay(1700).fadeIn(200);
                $('.reports__header').addClass('title-saved');
                $('.page-container').addClass('title-saved');
                $scope.reportPreferences.reportId = reportId;
                console.log($scope.reportPreferences.reportId);
            }
        }

        //screen transitions if report save in edit mode is successful
        $scope.saveReportCompletionEditMode = function(){
            $('#save-report').hide();
            $('#save-report-success').fadeIn();
            $('#save-report-success').delay(1500).fadeOut(600);
            $('#save-report').show();
        }

        $scope.resetLoadingStatus = function(){
            $scope.loading = false;
            $scope.mainLoading = false;
            $scope.navLoading = false;

            for (var a = 0; a < $scope.reportActions.length; a++) {
                $($scope.reportActions[a]).removeClass('ng-hide');
            }
        };

        $scope.createPdfReport = function() {
            if ( $scope.overallScore.responseCount < 1 ) { return; } //no responses, dont create pdf
            ReportService.updatePDFCounter({requestId: $scope.requestId});
            var finalPdfReportObject = ReportPdfService.getReportPdfData();
            finalPdfReportObject.hideScore = $scope.hideScore;
            return ReportService.createPdfReport(finalPdfReportObject).$promise.then(function(report) {

                window.open(reports_shared_drive_uri + report.filename,"_self");
            }, function(httpResponse) {
                $timeout(errorPopup, 500);
            });
        }

        $scope.openInterpretationGuide = function() {
            console.log("openInterpretationGuide ... $scope.interpretationGuideUrl = " + $scope.interpretationGuideUrl);
            window.open($scope.interpretationGuideUrl, '_blank').focus();

        }

        //triggered when 'Export' clicked
        $scope.launchExport = function() {
            if ( $scope.overallScore.responseCount < 1 ) { return; } //no responses, dont export csv
            $scope.reportPreferences.exportOpenEndedQuestion = false;
            var modalInstance = $modal.open({
                templateUrl: 'partials/reports/export-reports.html',
                controller: 'ExportReportsCtrl',
                scope: $scope,
                resolve: {
                    items: function () {
                        var attributes = {
                        };
                        return attributes;
                    }
                },
                windowClass: 'reveal-modal medium radius report-share-modal'
            });

            modalInstance.result.then(function (selectedItem) {

            }, function () {

            });
        }

        $scope.validateReportName = function(){
            var dummyReportId = 0;
            if ($scope.reportPreferences.reportId) {
                dummyReportId = $scope.reportPreferences.reportId;
            }
            if($scope.reportPreferences.reportName){
                ReportService.checkReportNameForDuplicates({customerId: $scope.institutionId,reportName: $scope.reportPreferences.reportName, reportId: dummyReportId}).$promise.then(function (data) {
                    if (data.length == 0) {
                        $scope.validationFailed = false;
                    } else {
                        $scope.validationFailed = true;
                    }
                }, function (httpResponse) {
                    var msg = "There was an error validating report name."
                    $timeout(sweetAlert("The system encountered a problem, please try again.", msg), 500);
                });
            }

        }

        $scope.setDirtyData = function(){
            $rootScope.isDirtyReportData = true;
        }


        $scope.launchSharing = function() {
            var modalInstance = $modal.open({
                templateUrl: 'partials/reports/report-sharing.html',
                controller: 'ShareReportsCtrl',
                scope: $scope,
                resolve: {
                    items: function () {
                        var attributes = {
                        };
                        return attributes;
                    }
                },
                windowClass: 'reveal-modal medium radius report-share-modal'
            });

            modalInstance.result.then(function (selectedItem) {

            }, function () {

            });
        }

        var checkAuth = function() {
          $scope.loading = true;
          $scope.mainLoading = true;
          $scope.navLoading = true;
          if ($location.search().report) {
            var decoded = atob($location.search().report).split("_");
            var userId = Number(decoded[0]);
            var requestId = Number(decoded[1]);
            var institutionId = Number(decoded[2]);
            applicationService.checkAuthWithRedirect();
            if (!userService.confirmUser()) {
              return;
            }
            customerService.checkInstitution(institutionId, function() {
              var usersMatch = userService.checkUserId(userId);
              if (!usersMatch){
                  swal({
                      title: "",
                      text: "This report link can only be accessed by the user who requested it.",
                      type: "warning",
                      confirmButtonColor: '#6FC96F',
                      showConfirmButton: true
                  });
                  applicationService.logout();
                  applicationService.setOriginalPath(undefined);
                  $location.search("report", null);
                  $location.path('/signin').replace();
                  return;
              }
              applicationService.setRequestId(requestId);
              $scope.init();
              initPage();
            });
          } else {
            $scope.init();
            initPage();
          }
        }

        var initPage = function() {
          if (create != undefined && create) //report creation step 4
          {
            $scope.prepSurveysData($scope.surveyAllType, $scope.surveyAllStatus);
          }
          if (preview != undefined && preview) //preview an unsaved report
          {
            $scope.renderReportInit();
          }
          if ((edit != undefined && edit) || (view != undefined && view)) //edit or view an existing report
          {
            $('.reports__title:first').hide();
            $('.reports__title .button').hide();
            $('.reports__header-buttons').show();
            $('.reports__header').addClass('title-saved');
            $('.page-container').addClass('title-saved');
            $scope.renderReportInit();
          }
        }

        var requestWatcher = function() {
          return applicationService.getRequestId();
        }


        $scope.$watch(requestWatcher, function(newValue, oldValue, scope) {
          if (newValue != oldValue) {
            checkAuth();
          }
        });

        checkAuth();

    }
]);
