app.controller('PublishedCtrl', ['$http', '$scope', '$rootScope', '$routeParams', '$location', '$sessionStorage', 'ContentTemplateService',
  function($http, $scope, $rootScope, $routeParams, $location, $sessionStorage, ContentTemplateService) {

    /* DEFAULTS */
    $rootScope.headerAction = 'hidden'; //makes sure the top nav bar gets shown
    $scope.published = [];
    $scope.templateId = $routeParams.templateId;

    /* ACTIONS ON PAGE LOAD */
    loadTemplatePublishedList();

    /* UI INTERACTIONS */
    $scope.backToPublish = function() { $location.url('/publish-content/' + $routeParams.templateId); };

    $scope.backToBrowse = function() { 
      $location.url('/browse-content');
    };

    $scope.backActionLink = '#/browse-content';

    $scope.spliceInst = function(index) { $scope.published.splice(index, 1); }

    /* API CALLS */
    function loadTemplatePublishedList() {
      var body = {templateId: $scope.templateId};
      ContentTemplateService.getTemplatePublishedInstitutions(body).$promise.then(
        function(response) {
          $scope.published = response;
        },
        function(error) {
          sweetAlert("There was an error loading the template publishing information. This is most likely a problem connecting to the server. Please try again later.", error, "error");
        }
      );
    }

}]);

