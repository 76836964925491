app.directive('fileDropzone', ['$rootScope', function($rootScope) {
    return {
        restrict: 'A',
        scope: {
            fileSelected: '=',
            fileName: '='
        },
        link: function(scope, element, attrs) {
            var processDragOverOrEnter;
            processDragOverOrEnter = function(event) {
                if (event != null) {
                    event.preventDefault();
                }
                event.dataTransfer.effectAllowed = 'copy';
                return false;
            };

            element.bind('dragover', processDragOverOrEnter);
            element.bind('dragenter', processDragOverOrEnter);

            return element.bind('drop', function(event) {
                if (event != null) {
                    event.preventDefault();
                }

                scope.$apply(function() {
                    $rootScope.rosterFile = event.originalEvent.dataTransfer.files[0];
                    scope.fileSelected = event.originalEvent.dataTransfer.files[0];
                });

               return true;
            });
        }
    };
}]);