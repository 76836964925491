app.controller('TemplateAdminDatesCtrl', ['$scope', 'ContentTemplateService',
    function($scope, ContentTemplateService) {
        /**~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~**/
        /**~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Props ~~~~~~~~~~~~~~~~~~~~~~~~~~~~**/
        /**~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~**/

        var ctrl = this;
        var now = moment();
        /**
         * Maintains references to the various date formats used
         * @type {{time: string, ymdOnly: string, mdyOnly: string, save: string, conversion: string, validation: string[]}}
         */
        const DateFormats = {
            time: 'hh:mm A', // used as the primary time format for display purposes
            ymdOnly: 'YYYY-MM-DD', // used during conversion
            mdyOnly: 'MM/DD/YYYY', // used as the primary date format for display purposes
            save: 'YYYY-MM-DD[T]HH:mm:ss', // used when saving the dates, this format is sent to the API
            conversion: 'YYYY-MM-DD HH:mm:ss A', // used during conversion,
            validation: ["YYYY-MM-DD LT", "YYYY-MM-DD h:mm:ss A", "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm", "YYYY-MM-DD HH:mm A", "YYYY-MM-DD hh:mm A"]
        };
        const updateEvent = 'openCloseChange';
        const isPublished = $scope.contentTemplate.status === 3;
        ctrl.administrationSurveyId = $scope.contentTemplate.administrationSurveyId;
        ctrl.institutionOwnerId = $scope.institutionOwnerId;
        ctrl.minCloseDate = moment("1976-01-01T00:00:00"); // default min close date to epoch, and only update once the user has done something to trigger that
        ctrl.templateStatus = $scope.contentTemplate.status;
        /**
         * Maintains a reference to previous state for comparison and reversion purposes
         * @type {{startDate: null, startTime: null, startTimeMoment: null, closeDate: null, closeTime: null, closeTimeMoment: null}}
         */
        ctrl.last = {
            startDate: null,
            startTime: null,
            startTimeMoment: null,
            closeDate: null,
            closeTime: null,
            closeTimeMoment: null
        };
        /**
         * Turns on or off logging of dates as they change
         * @type {boolean}
         */
        ctrl.dateLogEnabled = false;

        /**~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~**/
        /**~~~~~~~~~~~~~~~~~~~~~~~~~ Watch Vars ~~~~~~~~~~~~~~~~~~~~~~~~~~**/
        /**~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~**/

        /**
         * Provides automatic loading when the administrationDates value is updated
         */
        $scope.$watch('administrationDates', function(newValue) {
            loadDates(newValue);
        });

        /**~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~**/
        /**~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Logic ~~~~~~~~~~~~~~~~~~~~~~~~~~~~**/
        /**~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~**/

        /**
         * Event - Trigger: On Blur
         * Will handle start date validation, start date value reversion (if needed), and enforce requirements on close date/times (if needed)
         * If all dates are valid, save will be called
         * @param newValue - a moment object
         */
        ctrl.onStartDateChange = function(newValue) {
            logDates("START - onStartDateChange");
            if (newValue) {
                var dateMonth = newValue.month(), dateDay = newValue.date(), dateYear = newValue.year();
                if (!ctrl.startTimeMoment) {
                    ctrl.startTimeMoment = moment();
                    ctrl.startTimeMoment.month(dateMonth).date(dateDay).year(dateYear).hour(0).minute(0).second(0);
                    ctrl.startTime = ctrl.startTimeMoment.format(DateFormats.time);
                } else {
                    ctrl.startTimeMoment.month(dateMonth).date(dateDay).year(dateYear);
                    ctrl.startTime = ctrl.startTimeMoment.format(DateFormats.time);
                }
            } else if(!isPublished){
                // template isn't published so the user can clear out the fields
                ctrl.startTimeMoment = null;
                ctrl.startTime = null;
            } else {
                // fire off event to re-input original value, this should automatically re-trigger this workflow
                ctrl.startTime = ctrl.last.startTime;
                ctrl.startDate = ctrl.last.startDate;
                ctrl.startTimeMoment = ctrl.last.startTimeMoment;
                return;
            }
            // Check if start date exist, and if it is greater than close time then go ahead and set min close time
            if(ctrl.startTimeMoment && ctrl.closeTimeMoment && ctrl.startTimeMoment.isAfter(ctrl.closeTimeMoment)){
                // Do NOT fire off saves dates here, we need to set min close date which will trigger another event and call save
                ctrl.setMinCloseDateTime();
            } else {
                // Save dates
                saveTemplateOpenCloseDates();
                // Set last refs
                ctrl.last.startTime = ctrl.startTime;
                ctrl.last.startTimeMoment = ctrl.startTimeMoment;
                ctrl.last.startDate = ctrl.startDate;
                $scope.$emit(updateEvent, {openTime: ctrl.startTimeMoment, closeTime: ctrl.closeTimeMoment});
            }
            logDates("END - onStartDateChange");
        };

        /**
         * Event - Trigger: On Blur
         * Will handle start time validation, start time value reversion (if needed), and enforce requirements on close date/times (if needed)
         * If all dates are valid, save will be called
         */
        ctrl.onStartTimeChange = function() {
            logDates("START - onStartTimeChange");
            if(isPublished) {
                if ((!ctrl.startTime || ctrl.startTime === '') && ctrl.startDate !== '') {
                    // If we have a blank start time, but a valid start date
                    // - then set the start time to midnight
                    var dateMonth = ctrl.startDateMoment.month(), dateDay = ctrl.startDateMoment.date(),
                        dateYear = ctrl.startDateMoment.year();
                    ctrl.startTimeMoment = moment();
                    ctrl.startTimeMoment.month(dateMonth).date(dateDay).year(dateYear).hour(0).minute(0).second(0);
                    ctrl.startTime = ctrl.startTimeMoment.format(DateFormats.time);
                } else if (ctrl.startDate !== '') {
                    // If we have both a star time and start date
                    // - then combine them and verify if they are still valid
                    const combined = ctrl.startDateMoment.format(DateFormats.ymdOnly) + " " + ctrl.startTime;
                    if (!moment(combined, DateFormats.validation, true).isValid()) {
                        // They are invalid - Set value to last
                        ctrl.startTimeMoment = ctrl.last.startTimeMoment;
                        ctrl.startTime = ctrl.last.startTime;
                    } // no else case needed - if they're valid then the model was already updated
                } // no else case needed - any other cases are edge cases where the value shouldn't be modified
            } else {
                if(!ctrl.startTime || ctrl.startTime === '') {
                    // Use submitted a blank time - clear out both fields
                    ctrl.startTimeMoment = null;
                    ctrl.startDate = "";
                    ctrl.startDateMoment = null;
                } else if(ctrl.startDateMoment) {
                    // User submitted a value, combine with start date and verify if valid
                    const combined = ctrl.startDateMoment.format(DateFormats.ymdOnly) + " " + ctrl.startTime;
                    if (!moment(combined, DateFormats.validation, true).isValid()) {
                        // User put in something invalid, if they have a last value, set that
                        if(ctrl.last.startTimeMoment) {
                            ctrl.startTimeMoment = ctrl.last.startTimeMoment;
                            ctrl.startTime = ctrl.last.startTime;
                        } // no else case needed
                    } // no else case needed
                } // no else case needed
            }
            // Check if start date exist, and if it is greater than close time then go ahead and set min close time & trigger another update event
            if(ctrl.startTimeMoment && ctrl.closeTimeMoment && ctrl.startTimeMoment.isAfter(ctrl.closeTimeMoment)){
                ctrl.setMinCloseDateTime();
            }else {
                // Save dates
                saveTemplateOpenCloseDates();
                $scope.$emit(updateEvent, {openTime: ctrl.startTimeMoment, closeTime: ctrl.closeTimeMoment});
            }
            logDates("END - onStartTimeChange");
        };

        /**
         * Event - Trigger: On Blur
         * Will handle close time validation, close time value reversion (if needed)
         * If all dates are valid, save will be called
         */
        ctrl.onCloseTimeChange = function() {
            logDates("START - onCloseTimeChange");
            if(isPublished) {
                // Check to see if the user cleared out the time but still has a valid date
                if ((!ctrl.closeTime || ctrl.closeTime === '') && ctrl.closeDate !== '') {
                    if(ctrl.last.closeTimeMoment){
                        // Check to see if they have a valid last time object
                        // - Then set to last
                        ctrl.closeTimeMoment = ctrl.last.closeTimeMoment;
                        ctrl.closeTime = ctrl.last.closeTime;
                        ctrl.closeDate = ctrl.last.closeDate;
                    } else {
                        // Create a new time object from the existing date and a time of 11:59:59 PM
                        const dateMonth = ctrl.closeDateMoment.month(), dateDay = ctrl.closeDateMoment.date(),
                            dateYear = ctrl.closeDateMoment.year();
                        ctrl.closeTimeMoment = moment();
                        ctrl.closeTimeMoment.month(dateMonth).date(dateDay).year(dateYear).hour(23).minute(59).second(59);
                        ctrl.closeTime = ctrl.closeTimeMoment.format(DateFormats.time);
                    }
                } else if (ctrl.closeDate !== '') {
                    // Check to see if the user has a valid date
                    const validMoment = ctrl.closeDateMoment ? ctrl.closeDateMoment : ctrl.closeTimeMoment;
                    const validLastMoment = ctrl.last.closeTimeMoment;
                    const validLastTime = ctrl.last.closeTime;
                    // Build a combined date / time object
                    const combined = validMoment.format(DateFormats.ymdOnly) + " " + ctrl.closeTime;
                    if (!moment(combined, DateFormats.validation, true).isValid() || !ctrl.closeTime || ctrl.closeTime === "") {
                        // If the user entered data isn't a valid datetime
                        if (validLastMoment && validLastMoment !== '') {
                            // - Set to last
                            ctrl.closeTimeMoment = validLastMoment;
                            ctrl.closeTime = validLastTime;
                        } // No else case needed
                    } else if(ctrl.closeTimeMoment) {
                        // Is valid - allow user's input
                        ctrl.closeTimeMoment = moment(combined, DateFormats.conversion);
                        ctrl.closeTime = ctrl.closeTimeMoment.format(DateFormats.time);
                    } // No else case needed
                } // No else case needed
            } else {
                // If user entered in blank, clear out both fields
                if (!ctrl.closeTimeMoment || !ctrl.closeDateMoment || !ctrl.closeTime) {
                    ctrl.closeTimeMoment = null;
                    ctrl.closeTime = "";
                    ctrl.closeDateMoment = null;
                    ctrl.closeDate = "";
                } // no else case needed
            }
            saveTemplateOpenCloseDates();
            $scope.$emit(updateEvent, {openTime: ctrl.startTimeMoment, closeTime: ctrl.closeTimeMoment});
            logDates("END - onCloseTimeChange");
        };

        /**
         * Event - Trigger: On Blur
         * Will handle close date validation, close date value reversion (if needed)
         * If all dates are valid, save will be called
         * @param newValue - a moment object
         */
        ctrl.onCloseDateChange = function(newValue) {
            logDates("START - onCloseDateChange");
            // Check if user submitted an actual value
            if (newValue) {
                var dateMonth = newValue.month(), dateDay = newValue.date(), dateYear = newValue.year();
                // Check if the user already has a time saved
                if (!ctrl.closeTimeMoment) {
                    // If not, create one, and default to 11:59:59 PM
                    ctrl.closeTimeMoment = moment();
                    ctrl.closeTimeMoment.month(dateMonth).date(dateDay).year(dateYear).hour(23).minute(59).second(59);
                    ctrl.closeTime = ctrl.closeTimeMoment.format(DateFormats.time);
                } else {
                    // Update date only and leave time the same
                    ctrl.closeTimeMoment.month(dateMonth).date(dateDay).year(dateYear);
                    ctrl.closeTime = ctrl.closeTimeMoment.format(DateFormats.time);
                }
            } else if (!isPublished) {
                // Allow user to clear fields
                ctrl.closeTime = null;
                ctrl.closeTimeMoment = null;
            } else {
                // Is published, and clearing fields isn't allowed
                // - fire off event to re-input last value, this should automatically re-trigger this workflow
                ctrl.closeTime = ctrl.last.closeTime;
                ctrl.closeDate = ctrl.last.closeDate;
                ctrl.closeTimeMoment = ctrl.last.closeTimeMoment;
                return;
            }
            saveTemplateOpenCloseDates();
            // Set last refs
            ctrl.last.closeTime = ctrl.closeTime;
            ctrl.last.closeDate = ctrl.closeDate;
            ctrl.last.closeTimeMoment = ctrl.closeTimeMoment;
            $scope.$emit(updateEvent, {openTime: ctrl.startTimeMoment, closeTime: ctrl.closeTimeMoment});
            logDates("END - onCloseDateChange");
        };

        /**
         * Debugging function to help watch date changes as they occur
         * @param msg
         */
        const logDates = function(msg) {
            if(!ctrl.dateLogEnabled) {
                return;
            }
            let outMsg = msg;
            // Add Close Time
            outMsg += "\nClose Time: " + ctrl.closeTime;
            outMsg += "\nClose Time Moment: " + (ctrl.closeTimeMoment ? ctrl.closeTimeMoment.format(DateFormats.save) : "NULL");
            // Add Close Date
            outMsg += "\nClose Date: " + ctrl.closeDate;
            outMsg += "\nClose Date Moment: " + (ctrl.closeDateMoment ? ctrl.closeDateMoment.format(DateFormats.save) : "NULL");
            // Add Open Time
            outMsg += "\nOpen Time: " + ctrl.startTime;
            outMsg += "\nOpen Time Moment: " + (ctrl.startTimeMoment? ctrl.startTimeMoment.format(DateFormats.save) : "NULL");
            // Add Open Date
            outMsg += "\nOpen Date: " + ctrl.startDate;
            outMsg += "\nOpen Date Moment: " + (ctrl.startDateMoment ? ctrl.startDateMoment.format(DateFormats.save) : "NULL");

            console.log(outMsg);
        };

        /**
         * Determines the status of the template is in a state where we can edit it
         * @returns {boolean}
         */
        ctrl.templateIsEditOrArchived = function() {
            //status of 2 -> Edit, 4 -> Archived
            return ctrl.templateStatus === 2 || ctrl.templateStatus === 4;
        };

        /**
         * Represents if the start time fields should be disabled or enabled
         * @returns {boolean|*}
         */
        ctrl.isStartTimeDisabled = function() {
            return !ctrl.startDateMoment || ctrl.templateIsEditOrArchived();
        };

        /**
         * Represents if the close time fields should be disabled or enabled
         * @returns {boolean|*}
         */
        ctrl.isCloseTimeDisabled = function() {
            return !ctrl.closeDateMoment || ctrl.templateIsEditOrArchived();
        };

        /**
         * Sets the initial dates
         * @param adminDates
         */
        function loadDates(adminDates) {
            if (adminDates.openDate) {
                var startDate = moment(adminDates.openDate);
                ctrl.startDate = startDate.format(DateFormats.mdyOnly);
                ctrl.startDateMoment = startDate;
                ctrl.startTime = startDate.format(DateFormats.time);
                ctrl.startTimeMoment = startDate;
            }
            if (adminDates.closeDate) {
                var closeDate = moment(adminDates.closeDate);
                ctrl.closeDate = closeDate.format(DateFormats.mdyOnly);
                ctrl.closeDateMoment = closeDate;
                ctrl.closeTime = closeDate.format(DateFormats.time);
                ctrl.closeTimeMoment = closeDate;
            }
            ctrl.last.closeDate = ctrl.closeDate;
            ctrl.last.closeTime = ctrl.closeTime;
            ctrl.last.closeTimeMoment = ctrl.closeTimeMoment;
            ctrl.last.startDate = ctrl.startDate;
            ctrl.last.startTime = ctrl.startTime;
            ctrl.last.startTimeMoment = ctrl.startTimeMoment;
        }

        /**
         * Updates the minimum date allowed in the close date/time fields
         */
        ctrl.setMinCloseDateTime = function() {
            if (ctrl.startTimeMoment && ctrl.startTimeMoment.isAfter(now)) {
                var year = ctrl.startTimeMoment.year();
                var month = ctrl.startTimeMoment.month();
                var date = ctrl.startTimeMoment.date();
                var hour = ctrl.startTimeMoment.hour();
                var minute = ctrl.startTimeMoment.minute();
                ctrl.minCloseDate.year(year).month(month).date(date).hour(hour).minute(minute).second(0);
            } else {
                ctrl.minCloseDate.year(now.year()).month(now.month()).date(now.date()).hour(now.hour()).minute(now.minute()).second(0);
            }
        };

        /**
         * Will save the user entered dates
         */
        function saveTemplateOpenCloseDates() {
            const start = ctrl.startTimeMoment ? ctrl.startTimeMoment.clone().format(DateFormats.save) : null;
            const close = ctrl.closeTimeMoment ? ctrl.closeTimeMoment.clone().format(DateFormats.save) : null;
            const body = {
                administrationOpenDate: start,
                administrationCloseDate: close,
                administrationSurveyId: ctrl.administrationSurveyId,
                ownerInstitutionId: $scope.institutionOwnerId
            };
            ContentTemplateService.updateOpenCloseDates(body).$promise.then(
                function (data) {
                    //success handler
                },
                function (error) {
                    sweetAlert("There was an error saving the open/close dates. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
                }
            );
        }
    }
]);
