app.controller('EditSidebarTemplateCtrl', 
    ['ContentTemplateService',
     '$scope', 
     'userService', 
     '$timeout', 
     '$window',
  function(ContentTemplateService,
           $scope, 
           userService, 
           $timeout, 
           $window
    ) {

    // Jan-Feb 2016, Peter Ward
    $scope.scrollCache = {
        pageContainer: null,
        current: null
    };

    $scope.itemAnchor = undefined;
    $scope.sectionAnchor = undefined;

    $scope.itemSortCacheTemplate = function(){
      var returnObj = Object.create({
            senderSection: {},
            sender: {},
            srcItemId: null,
            startItem: {},
            stopItem: {},
            dest: {},
            destSectionId: null,
            sectionDrag: false,
            sectionTarget: {},
            isCurrent: false,
            parentA: {},
            d: {},
            destSectionA: {},
            dId: null,
            helper: {
                clone: {},
                top: null
            },
            counter: 0,
            scrollCounter: 0,
            noChange: null,
            out: false,
            updateTick: 0
        });

      return returnObj;
    };

    $scope.itemSortCache = new $scope.itemSortCacheTemplate();

    $scope.sectionSortHandler = {
        cancel: ".locked",
        forceHelperSize: true,
        'ui-floating': true,
        scroll: true,
        refresh: true,
        refreshPositions: true,
        axis: 'y',
        connectWith: '.edit_section-li',
        items: '.edit_section-li',
        start: function(e, ui) {
          // tag as section drag
          $scope.itemSortCache.sectionDrag = true;
          var clicked = $(ui.item).find('#edit_section-a');
          if($(clicked).hasClass('current')){
              // If it's current, make a note in cache
              $scope.itemSortCache.isCurrent = true;
          }
          // Initiate Animation Frame
          window.requestAnimFrame(handleCurrent);
          window.requestAnimFrame(getHelperStat);
          $scope.dragging = true;
          $scope.itemSortCache.sectionTarget = e.target;            
          $($('.edit_section-li').find('a')).addClass('closed');
        },
        stop: function(e, ui) {
            $scope.dragging = false;

            $scope.updateSectionLabels();

            $($('.edit_section-li').find('a')).removeClass('closed');

            $scope.itemSortCache = new $scope.itemSortCacheTemplate();

            // Cancel Animation Frame
            window.cancelRequestAnimFrame(handleCurrent);
            window.cancelRequestAnimFrame(getHelperStat);
        },
        cursor: 'pointer'
    };

    $scope.itemSortHandler = {
      cancel: ".locked",
      connectWith: '.edit_section_item',
      revert: true,
      'ui-floating': 'auto',
      axis: 'y',
      helper: 'clone',
      appendTo: 'body',
      refresh: true,
      refreshPositions: true,
      cursor: 'pointer',
      stop : function(e, ui) {

        $scope.itemSortCache.stopItem = $scope.findElementParent(ui.item.sortable.model.id);

        if ($scope.itemSortCache.startItem.id === $scope.itemSortCache.stopItem.id) {
            $scope.updateItemLabels($scope.itemSortCache.startItem); //updateItemLabels is a function in TemplateItemCtrl.js
            $scope.updateItemLabels($scope.itemSortCache.stopItem);
        } else {
            var srcItemId = parseInt($scope.itemSortCache.srcItemId);
            $scope.moveItemFromSection($scope.itemSortCache.startItem, $scope.itemSortCache.stopItem, srcItemId);
        }

        // Cancel Animation Frame
        $window.cancelRequestAnimFrame(handleCurrent);
        $window.cancelRequestAnimFrame(getHelperStat);

        if(ui.item.data('start_pos') === ui.item.index()) {
            $scope.itemSortCache.noChange = true;
        }

        $timeout(function(){
            //$scope.updateItemLabels($scope.itemSortCache.stopItem);
            // Reset Sorting Cache
            if($scope.itemSortCache.isCurrent === true){
                $scope.currentAnchorBool = true;
            }
            var that = $scope.itemSortCache.dest;
            var flasher = $($(that).closest('li')).find('#edit_section-a');
            $(flasher).css('color', '#6fc96f');
            setTimeout(function () {
              $(flasher).css('color', '#333');
              setTimeout(function () {
                $(flasher).css('color', '#6fc96f');
                setTimeout(function () {
                  $(flasher).css('color', '#333');
                  setTimeout(function () {
                    $(flasher).css('color', '#6fc96f');
                    setTimeout(function () {
                        //$(that).addClass('ng-hide');
                        $(flasher).css('color', '#333');
                        $scope.dragging = false;
                    }, 50)
                  }, 50)
                }, 50)
              }, 50)
            }, 50);

            $scope.itemSortCache = new $scope.itemSortCacheTemplate();
            $scope.addUIDroppable(false);
        })
      },
      update: function(e, ui) {
        
          var startSectionDemographic = ui.item.sortable.model.demographic ? true : false;
          var destinationDemographic = $(ui.item.sortable.droptarget[0]).hasClass('demographic');
        
          if ( startSectionDemographic !== destinationDemographic) {
            ui.item.sortable.cancel();
            return;
          }

          if (ui.item.sortable.model === "can't be moved") {
              ui.item.sortable.cancel();
          } else if($scope.itemSortCache.updateTick === 1) {
              var destSection = e.target.parentElement.lastElementChild;

              $scope.itemSortCache.d = $(destSection).context;
              $scope.itemSortCache.dId = ui.item.id;

              if ($scope.itemSortCache.counter === 1) {
                  $scope.itemSortCache.destSectionId = destSection.id;
                  $scope.dragging = false;
              } else if ($scope.itemSortCache.counter === 0) {
                  $scope.itemSortCache.counter++;
              }


              var destId = $(destSection).prop('id');
              var destSectionId = parseInt(destId.split('_')[0]);
              var srcId = $($scope.itemSortCache.sender).prop('id');
              var srcSectionId = parseInt(srcId.split('_')[0]);
              $scope.itemSortCache.dest = $(destSection).context;
          }

          $scope.itemSortCache.updateTick++;
      },
      start: function(e, ui) {
        ui.item.data('start_pos', ui.item.index());
        // Detect if item hasClass current
        $scope.itemSortCache.senderSection = $(ui.item[0]).closest('.edit_section_item');
        $scope.itemSortCache.startItem = $scope.findElementParent(ui.item.sortable.model.id);
        if($(ui.item[0]).hasClass('current')){
            // If it's current, make a note in cache
            $scope.itemSortCache.isCurrent = true;
        }
        $scope.itemSortCache.helper.clone = ui.helper;
        $scope.dragging = true;
        $scope.itemSortCache.sender = e.target;
        $scope.itemSortCache.srcItemId = ui.item.context.id;
        // Initiate Animation Frame
        $window.requestAnimFrame(handleCurrent);
        $window.requestAnimFrame(getHelperStat);
      },
      out: function(e, ui) {
          $scope.itemSortCache.refreshed = false;
      }
    };

      function addUIDroppable_template() {
        var editSectionLi = $('.edit_section-li');
        var editSectionItem = $('.edit_section_item');
        var list = [];

        $(editSectionLi).each(function (li) {
            if ($(editSectionLi[li]).hasClass('certified-content') === false) {
                list.push(editSectionLi[li])
            }
        });

        $(list).droppable({
            hoverClass: 'droppable',
            over: function (e, ui) {
                setTimeout(function () {
                    $(editSectionItem).sortable('refreshPositions');
                }, 100);
            },
            drop: function (e, ui) {
                $('.edit_section_item').sortable('refresh');
                $('.edit_section_item').sortable('refreshPositions');
            }
        });
      }
      $scope.addUIDroppable = function(fromLoad) {
        if (fromLoad) { 
          setTimeout(function () {
              addUIDroppable_template();
          }, 1000);
        } else {
          addUIDroppable_template();
        }
      };

    $scope.addUIDroppable(true);

    // AnimationFrame Polyfills
    $window.requestAnimFrame = (function(){
        return  window.requestAnimationFrame   ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame
    })();

    $window.cancelRequestAnimFrame = ( function() {
        return window.cancelAnimationFrame           ||
            window.webkitCancelRequestAnimationFrame ||
            window.mozCancelRequestAnimationFrame    ||
            window.oCancelRequestAnimationFrame      ||
            window.msCancelRequestAnimationFrame     ||
            clearTimeout
    } )();

    $scope.start = null;
    var currentId = null;
    var scrollCounter = 0;
    var contentLength = 0;
    var lengthStart = true;
    $scope.scrolling = false;

    $('.page-container').on('scroll', function(){
        $scope.scrolling = true;
    });

    // jQuery.fn.extend( object )Returns: Object
    // Description: Merge the contents of an object onto the jQuery prototype to provide new jQuery instance methods.
    // "scrollStopped" credits: http://stackoverflow.com/users/775516/jason-sebring
    $.fn.scrollStopped = function(callback) {
        var that = this, $this = $(that);
        $this.scroll(function(ev) {
            clearTimeout($this.data('scrollTimeout'));
            $this.data('scrollTimeout', setTimeout(callback.bind(that), 250, ev));
        });
        $scope.start = null;
    };

    $('.page-container').scrollStopped(function(ev){
        $scope.scrolling = false;
    });
    //

    function handleCurrent(){
      var current = $('.current');
       //Deal with .page-container scrolling for each $('.survey-sidebar__content')
       //i.e. auto scroll sidebar as current leaves sidebar frame
      if ($(current)[0]){ 
        var current = $('.current');
        if ($(current)[0].id){
          if(currentId !== $(current)[0].id){
            currentId = $(current)[0].id;
            var sidebar = $('.survey-sidebar__content');
            var currentTop = $('#'+currentId).offset().top;
            $scope.scrollCache.current = currentTop;
            if(lengthStart === true){
              contentLength = currentTop;
              lengthStart = false;
            }
            var currentHeight = $('#'+currentId).height();
            $scope.scrollCache.currentHeight = currentHeight;
            var lowerTop = $('.survey-sidebar__actions').offset().top;
            $scope.scrollCache.lowerTop = lowerTop;
            if(scrollCounter === 0){
              var upperTop = 0;
            } else {
              var upperTop = (contentLength * scrollCounter) - contentLength;
            }
            if(scrollCounter === 0){ scrollCounter = 1 }
            if(currentTop >= lowerTop - currentHeight - 25){
              scrollCounter += 1;
              $(sidebar).scrollTop(contentLength * scrollCounter);
            } else if(currentTop <= upperTop + 25){
              scrollCounter -= 1;
              $(sidebar).scrollTop(contentLength * scrollCounter);
            }
          }
        }
      }

        // if dragging an item
        if(($scope.dragging === true) && ($scope.itemSortCache.sectionDrag === false)) {
          // if user takes item out of section then back to original position\
          if(($scope.itemSortCache.out === true) && ($scope.itemSortCache.noChange === true)) {
              $scope.dragging = false;
          }
          $scope.itemSortCache.scrollCounter++;
          // iterate through sidebar elements
          var editSectionLi = $('.edit_section-li');
          // Show each section as jQuery droppable adds class 'droppable'
          // i.e. Show each section on hover while dragging
          $(editSectionLi).each(function(li) {
            var hasSender = true;
            var childList = $(this).find('ul');
            // Define whether section has sender
            if($(this).find($scope.itemSortCache.sender).length > 0) {
                hasSender = true;
            } else {
                hasSender = false
            }
            // For item drag, show any section that's *hidden and *hovered
            if (($(this).hasClass('droppable')) && ($($(this).find('.edit_section_item')).hasClass('ng-hide')) && ($scope.itemSortCache.sectionDrag === false) && ($scope.itemSortCache.refreshed === false)) {
                $('.edit_section_item').sortable('refresh');
                $('.edit_section_item').sortable('refreshPositions');
                $( $(this).find('.edit_section_item') ).removeClass('ng-hide');
                $scope.itemSortCache.refreshed = true;
            }

            if (( $(this).hasClass('droppable') === false ) && 
                ( $(this).find('.current').length < 1 ) &&
                ( hasSender === false ) &&
                ( $( $(this).find('.edit_section_item') ) !== $scope.itemSortCache.dest )) {
              
              if ($scope.itemSortCache.sectionDrag === false) {
                $(childList).addClass('ng-hide');
              }
            } else if ( $( $(this).find('.edit_section_item') ) !== $scope.itemSortCache.dest || 
                        $( $(this).find('.edit_section_item') ) !== $scope.itemSortCache.sender) {
              $(childList).removeClass('ng-hide');
            }
            // Detect if clone wants to scroll up/down and scrollTop 1 in that direction
            var container = $('.survey-sidebar__content');
            var containerTop = $(container).scrollTop();
            var topScrollPoint = $(container).offset().top + 50;
            var bottomScrollPoint = $('.survey-sidebar__actions').offset().top - 20;

            if ($scope.itemSortCache.helper.top > bottomScrollPoint) {
                animateScroll('up', container, containerTop)
            } else if ($scope.itemSortCache.helper.top < topScrollPoint) {
                animateScroll('down', container, containerTop)
            }
          });
        }
        $window.requestAnimFrame(handleCurrent);
    }

    $window.requestAnimFrame(handleCurrent);

    function getHelperStat() {
        // get helper, stat || Get Helper's Statistics
        if ($scope.dragging === true) {
            if ((Object.keys($scope.itemSortCache.helper).length > 0)) {
                if ((Object.keys($scope.itemSortCache.helper.clone).length > 0)) {
                  $scope.itemSortCache.helper.top = $($scope.itemSortCache.helper.clone).offset().top;

                  $window.requestAnimFrame(getHelperStat);
                }
            } else {
                $window.requestAnimFrame(getHelperStat);
            }
        }
    }

    function animateScroll(dir, container, containerTop) {
        if ($scope.itemSortCache.scrollCounter % 5 === 0) {
            if (dir === 'up') {
                $(container).scrollTop(containerTop + 1);
            } else if (dir === 'down') {
                $(container).scrollTop(containerTop - 1);
            }
        }
    }

    $scope.resizeInstructionTextareas = function() {
      $('textarea').each(function (ta) {
          if ($(this)[ta]) {
              $($(this)[ta]).css('height', $(this)[ta].scrollHeight + 'px');
          }
      });
    }

    //Return the section contains the element
    $scope.findElementParent = function (elementId) {
        var parentCount = $scope.contentTemplate.elementList.length;
        for (var i = 0; i < parentCount; i++) {
            if ($scope.contentTemplate.elementList[i].childElementList !== null && $scope.contentTemplate.elementList[i].childElementList !== undefined) {
                for (var j = 0; j < $scope.contentTemplate.elementList[i].childElementList.length; j++) {
                    if ($scope.contentTemplate.elementList[i].childElementList[j].id === elementId) {
                        return $scope.contentTemplate.elementList[i];
                    }
                }
            }
        }
        return null;
    }

    $scope.getSidebarSectionLabel = function(section) {
      return section.elementTypeId == 1 ? (section.label + ".  " + section.name) : "";
    };

    $scope.sectionHasElement = function(section, element){
        return section.childElementList.indexOf(element) !== -1;
    }

    $scope.moveItemFromSection = function(srcSection, destSection, itemId) {
        var srcSectionIdLabelList = $scope.constructItemLabels(srcSection); //constructItemLabels is a function on TemplateItemCtrl scope
        var destSectionIdLabelList = $scope.constructItemLabels(destSection);
        var body = {
          action: "MOVE_ITEM_TO_SECTION",
          templateMoveItemDTO: {
            srcSection: srcSection.id,
            srcSectionItem: itemId,
            destSection: destSection.id,
            destSectionItemOrderedList: destSectionIdLabelList,
            srcSectionItemOrderedList: srcSectionIdLabelList
          },
          userId: userService.getUserId(),
          templateId: $scope.contentTemplate.templateId
        };
        ContentTemplateService.updateTemplateElement(body).$promise.then(
          function(response) { },
          function(error) {
            sweetAlert("There was an error moving the item. This is most likely a problem connecting to the server. Please try again later.", error, "error");
          }
        );
    };

}]);
