app.directive('fileSelectHandler', ['$rootScope', '$timeout', function($rootScope, $timeout) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            const onChangeHandler = scope.$eval(attrs.fileSelectHandler);
            element.on('change', onChangeHandler);
            element.on('$destroy', function() {
                element.off();
            });
        }
    }
}]);
