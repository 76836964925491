(function() {
    'use strict';
    angular.module('echo')
        .controller('CreateReportsWizardStep3Ctrl', CreateReportsWizardStep3Ctrl);

    CreateReportsWizardStep3Ctrl.$inject = ['$scope', '$modalInstance', '$filter', '$timeout', 'ReportService'];

    function CreateReportsWizardStep3Ctrl($scope, $modalInstance, $filter, $timeout, ReportService){

        $scope.reportPreferences.prevStepNum = 3;
        $scope.institutions = {};
        $scope.instToken = {};
        var navigationPreference = {};

        $scope.next = function() {
            navigationPreference.skipAhead = false;
            navigationPreference.previous = false;
            $modalInstance.close($scope.reportPreferences.institutions);
        }

        $scope.cancel = function () {
            $modalInstance.dismiss('cancel');
        };

        $scope.prev = function() {
            navigationPreference.skipAhead = false;
            navigationPreference.previous = true;
            $modalInstance.close(navigationPreference);
        }

        $scope.prevContent = function() {
            navigationPreference.skipAhead = false;
            navigationPreference.previousContent = true;
            $modalInstance.close(navigationPreference);
        }

        $scope.skipAhead = function(){
            navigationPreference.skipAhead = true;
            $modalInstance.close(navigationPreference);
        }

        $scope.groupClick = function($event, keepOpen) {
            var e = $event.target.parentElement;
            var li = e.parentElement;
            if ($(li).has('ul')) {
                if (keepOpen && $(li).hasClass('open'))
                {
                    //do nothing
                }
                else $(li).toggleClass('open');
            }
        }

        $scope.getInstitutions = function(surveyContentId, includeReportPreferences){

            $scope.instToken = {};
            ReportService.institutions($scope.reportPreferences,{ userId : $scope.userId, institutionId: $scope.institutionId}).$promise.then(function(institutions) {
                $scope.institutions = institutions;

                $scope.filtered = $scope.institutions;
                if (includeReportPreferences)
                {
                    if ($scope.reportPreferences.institutions != null)
                    {
                        for (var i in $scope.institutions)
                        {
                            var index = $scope.reportPreferences.institutions.indexOf($scope.institutions[i].id);
                            if (index > -1) {
                                $scope.institutions[i].selected = true;
                            }
                        }
                    }
                }
            },function(httpResponse){
                $timeout(errorPopup, 500);
            });

        }

        $scope.getInstitutions($scope.reportPreferences.surveyContentTypeId, true);

        $scope.checkChange = function($event, item)
        {
            if (item.childInstitutions && !item.parentId) {
                item.selected = !item.selected;
                $scope.storeInstitutionPreferences(item);
                parentCheckChange(item);
            }
            $scope.groupClick($event, true);
        }

        function parentCheckChange(item) {
            for (var i in item.childInstitutions) {
                item.childInstitutions[i].selected = item.selected;
                $scope.storeInstitutionPreferences(item.childInstitutions[i]);
                if (item.childInstitutions[i].childInstitutions) {
                    parentCheckChange(item.childInstitutions[i]);
                }
            }
        }

        $scope.selectAllInstitutions = function(items)
        {
            for (var i in items)
            {
                items[i].selected = true;
                $scope.storeInstitutionPreferences(items[i]);
                parentCheckChange(items[i]);
            }
            $scope.expandAllLists(true);
        }

        $scope.selectNoneInstitutions = function(items)
        {
            for (var i in items)
            {
                items[i].selected = false;
                $scope.storeInstitutionPreferences(items[i]);
                parentCheckChange(items[i]);
            }
            $scope.expandAllLists(false);
        }

        $scope.storeInstitutionPreferences = function(item)
        {
            var index = $scope.reportPreferences.institutions.indexOf(item.id);
            if (index > -1)
            {
                if (!item.selected)
                {
                    $scope.reportPreferences.institutions.splice(index, 1);
                }
            }
            else
            {
                if (item.selected)
                {
                    $scope.reportPreferences.institutions.push(item.id);
                }
            }
        }

        $scope.filterInstitution = function(inst){
            var x =  $filter('filter')(inst , {'$': $scope.instToken.token});
            return x;
        }

        $scope.expandAllLists = function(expand){
            var topList = document.getElementsByClassName('multiple-select__group');
            $timeout(function(){
                for(var i=0; i<topList[0].children.length; i++){
                    var childElement = topList[0].children[i];
                    if ($(childElement).has('ul')) {
                        if (expand)
                        {
                            if (! $(childElement).hasClass('open'))
                            {
                                $(childElement).toggleClass('open');
                            }
                        }
                        else
                        {
                            if ( $(childElement).hasClass('open'))
                            {
                                $(childElement).toggleClass('open');
                            }
                        }
                    }
                }
            });
        }


        $scope.$watch('instToken.token', function(term){
            if ($scope.institutions.length > 0)
            {
                $scope.filtered = $filter('filter')($scope.institutions, {'$': term});
                if (term != undefined && $scope.filtered)
                {
                    $scope.expandAllLists(true);
                }
            }
        })
    }
})();

