app.directive('publishParent', ['$rootScope', 'ContentTemplateService', 'PublishHelperService', function($rootScope, ContentTemplateService, PublishHelperService) {
    return {
      restrict: 'E',
      templateUrl: 'partials/templates/publish/publish-parent.html',
      scope: {
        institution: '=',
        index: '@',
        endIndex: '@',
        templateId: '@',
        instTransformer: '&',
        parent: '=',
        grandparent: '=',
        ancestory: '@',
        type: '@',
        autoDeselect: '@'
      },
      link: function(scope, element, attrs) {

        scope.$watch('institution.status', function(status) {
          if (scope.parent) {
            PublishHelperService.updateStatus(scope.parent);
          }
          if (scope.grandparent) {
            PublishHelperService.updateStatus(scope.grandparent);
          }
        });

        scope.hasSelectedChildren = function() {
          return PublishHelperService.hasSelectedDescendant(scope.institution);
        };

        scope.toggleChildren = function() {
          scope.institution.showChildren = !scope.institution.showChildren;
          loadChildrenIfNeeded();
        };

        const loadChildrenIfNeeded = function() {
            if (!scope.institution.childrenLoaded) {
                if (scope.type === 'admin') {
                    loadAdminChildren(scope.ancestory);
                } else if (scope.type === 'nonadmin') {
                    loadNonAdminChildren(scope.ancestory);
                } else {
                    loadCustomChildren(scope.ancestory);
                }
            }
        }

        scope.toggleInstSelect = function(institution) {
          // if the institution is inactive don't do anything
          if (!institution.active) {
            return
          }

          var selectInstOnly = function() {
            institution.selected = true;
            if(doesParentHaveAllChildrenSelected(institution)){
              institution.publishToChildren = true;
            }
            PublishHelperService.updateStatus(institution);
              loadChildrenIfNeeded();
              $rootScope.$emit('recalculateAncestory', {  greatgrandparent: null,
                  grandparent: scope.grandparent,
                  parent: scope.parent});
          };

          var selectInstAndDescendants = function() {
            institution.selected = true;
            institution.publishToChildren = true;
            PublishHelperService.selectAllDescendants(institution);
            PublishHelperService.updateStatus(institution);
              loadChildrenIfNeeded();
              $rootScope.$emit('recalculateAncestory', {  greatgrandparent: null,
                  grandparent: scope.grandparent,
                  parent: scope.parent});
          };

          var deselectInstOnly = function() {
            institution.selected = false;
            PublishHelperService.updateStatus(institution);
              loadChildrenIfNeeded();
              $rootScope.$emit('recalculateAncestory', {  greatgrandparent: null,
                  grandparent: scope.grandparent,
                  parent: scope.parent});
          };

          var deselectInstAndDescendants = function() {
            institution.selected = false;
            institution.publishToChildren = false;
            PublishHelperService.unselectAllDescendants(institution);
            PublishHelperService.updateStatus(institution);
              loadChildrenIfNeeded();
              $rootScope.$emit('recalculateAncestory', {  greatgrandparent: null,
                  grandparent: scope.grandparent,
                  parent: scope.parent});
          };

          // if the institution is active do the normal stuff
          if (!scope.institution.selected && scope.institution.hasChildren) {
            $rootScope.$emit('showModalInst',  {
              institution: scope.institution,
              content: {
                title: 'Confirm Selection',
                message: 'Choose which institution(s) you want to select, and then click “Confirm”',
                optionOne: {
                  text: institution.name + ' only',
                  action: selectInstOnly
                },
                optionTwo: {
                  text: institution.name + ' and all associated institutions',
                  action: selectInstAndDescendants
                }
              }
            });
          } else if (scope.institution.selected && scope.institution.hasChildren) {
              if(!scope.autoDeselect || doesParentHaveAnyChildrenSelected(scope.institution)) {
                  $rootScope.$emit('showModalInst', {
                      institution: scope.institution,
                      content: {
                          title: 'Confirm Deselection',
                          message: 'Choose which institution(s) you want to deselect, and then click “Confirm”',
                          optionOne: {
                              text: institution.name + ' only',
                              action: deselectInstOnly
                          },
                          optionTwo: {
                              text: institution.name + ' and all associated institutions',
                              action: deselectInstAndDescendants
                          }
                      }
                  });
              } else {
                  // Go ahead and deselect parent without popup since it had no children selected
                  deselectInstOnly();
              }
          } else {
            scope.institution.selected = !scope.institution.selected;
            if (!scope.institution.selected && scope.institution.publishToChildren) {
              scope.institution.publishToChildren = false;
              PublishHelperService.unselectAllDescendants(scope.institution);
            }
            PublishHelperService.updateStatus(scope.institution);
              $rootScope.$emit('recalculateAncestory', {  greatgrandparent: null,
                  grandparent: scope.grandparent,
                  parent: scope.parent});
          }
        };

        function loadCustomChildren() {
          var body = {institutionId: scope.institution.id};
          $rootScope.$emit('loadingInstitutions', true);
          ContentTemplateService.getTemplateChildInstitutions(body).$promise.then(
            function(data) {
              $rootScope.$emit('loadingInstitutions', false);
              scope.institution.childrenLoaded = true;
              scope.institution.children = scope.instTransformer()(data);
              if (scope.institution.publishToChildren || checkParentPublishToChildren(scope.parent)) {
                updateChildParentsToFullChecked(scope.institution.children);
                PublishHelperService.selectAllDescendants(scope.institution);
              }
            },
            function(error) {
              sweetAlert("There was an error loading the institutions. This is most likely a problem connecting to the server. Please try again later.", error, "error");
            }
          );
        };

        function loadAdminChildren() {
          var params = {
              surveyId: scope.templateId,
              parentInstitutionId: scope.institution.institutionId,
              ancestors: scope.ancestory
          };
          $rootScope.$emit('loadingInstitutions', true);
          ContentTemplateService.getSurveyChildInstitutions(params).$promise.then(
            function(data) {
              $rootScope.$emit('loadingInstitutions', false);
              scope.institution.childrenLoaded = true;
              scope.institution.children = scope.instTransformer()(data);
              if (scope.institution.publishToChildren || checkParentPublishToChildren(scope.parent)) {
                updateChildParentsToFullChecked(scope.institution.children);
                PublishHelperService.selectAllDescendants(scope.institution);
              }
            },
            function(error) {
              sweetAlert("There was an error loading the institutions. This is most likely a problem connecting to the server. Please try again later.", error, "error");
            }
          );
        }

        function loadNonAdminChildren() {
          var params = {
              templateId: scope.templateId, 
              institutionId: scope.institution.institutionId, 
              ancestors: scope.ancestory
          };
          $rootScope.$emit('loadingInstitutions', true);
          ContentTemplateService.getAdEdChildInstitutions(params).$promise.then(
            function(data) {
              $rootScope.$emit('loadingInstitutions', false);
              scope.institution.childrenLoaded = true;
              scope.institution.children = scope.instTransformer()(data);
              if (scope.institution.publishToChildren || checkParentPublishToChildren(scope.parent)) {
                updateChildParentsToFullChecked(scope.institution.children);
                PublishHelperService.selectAllDescendants(scope.institution);
              }
            },
            function(error) {
              sweetAlert("There was an error loading the institutions. This is most likely a problem connecting to the server. Please try again later.", error, "error");
            }
          );
        }

        function checkParentPublishToChildren(parent) {
          return parent ? parent.publishToChildren : false;
        }

        function updateChildParentsToFullChecked(children) {
          if(children && children.length > 0) {
            children.forEach(function(institution){
              if(institution.active) {
                  institution.publishToChildren = institution.childInstitutionCount > 0;
                  institution.selected = true;
                  institution.displayType = "CHECKED";
                  institution.status = institution.childInstitutionCount > 0 ? "check-all" : "checked";
                  PublishHelperService.updateStatus(institution);
              }
            })
          }
        }


        function isFullyChecked(inst) {
            let allChildrenSelected = true;
            if(inst.active) {
                if (inst.hasChildren) {
                    inst.children.forEach(function (child) {
                        if (child.active) {
                            allChildrenSelected &= isFullyChecked(child);
                        }
                    })
                }
            }
            return allChildrenSelected && (inst.status === 'checked' || inst.status === 'check-all');
        }

        function doesParentHaveAllChildrenSelected(inst) {
            let childrenSelected = true;
            if(inst.hasChildren) {
                if(!inst.children.length > 0) {
                    // Exit out early and assume false if children haven't been expanded
                    return false;
                }
                inst.children.forEach(function(child){
                    if(child.active) {
                        childrenSelected &= isFullyChecked(child);
                    }
                })
            }
            return childrenSelected;
        }


      /**
       * This is a recursive function that checks for any checked children in a given hierarchy and aggregates back up a boolean
       * @param inst
       * @returns {boolean}
       */
        function hasSelectedChildren(inst) {
            let childrenSelected = false;
            if(inst.hasChildren) {
                if(inst.children.length > 0) {
                    inst.children.forEach(function (child) {
                        if (child.active) {
                            childrenSelected |= hasSelectedChildren(child);
                        }
                    });
                }
            }
            childrenSelected = childrenSelected || inst.status !== 'unchecked';
            return childrenSelected;
        }

      /**
       * This function handles the top level institution and calls the recursive function on it's children.
       * @param inst
       * @returns {boolean}
       */
        function doesParentHaveAnyChildrenSelected(inst) {
            let childrenSelected = false;
            if(inst.hasChildren) {
                if(inst.children.length > 0) {
                    inst.children.forEach(function (child) {
                        if (child.active) {
                            childrenSelected |= hasSelectedChildren(child);
                        }
                    });
                }
            }
            return childrenSelected;
        }
      }
    }
  }
]);
