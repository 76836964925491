app.directive('publishChild', ['$rootScope', 'PublishHelperService', function($rootScope, PublishHelperService) {
    return {
      restrict: 'E',
      templateUrl: 'partials/templates/publish/publish-child.html',
      scope: {
        institution: '=',
        parent: '=',
        grandparent: '=',
        greatgrandparent: '=',
        ancestory: '@'
      },
      link: function(scope, element, attrs) {

        scope.$watch('institution.status', function(status) {
          if (scope.parent) {
            PublishHelperService.updateStatus(scope.parent);
          }
          if (scope.grandparent) {
            PublishHelperService.updateStatus(scope.grandparent);
          }
          if (scope.greatgrandparent) {
            PublishHelperService.updateStatus(scope.greatgrandparent);
          }
        });

        scope.toggleInstSelect = function(institution) {
          //if the institution is inactive don't do anything
          if (!institution.active) { return }
          if (!scope.institution.selected && !scope.institution.hasChildren) {
            scope.institution.selected = true;
            if(doesParentHaveAllChildrenSelected(scope.institution)){
              institution.publishToChildren = true;
            }
            PublishHelperService.updateStatus(scope.institution);
              $rootScope.$emit('recalculateAncestory', {  greatgrandparent: scope.greatgrandparent,
                  grandparent: scope.grandparent,
                  parent: scope.parent});
          }else{
            scope.institution.selected = false;
            PublishHelperService.updateStatus(scope.institution);
              $rootScope.$emit('recalculateAncestory', {  greatgrandparent: scope.greatgrandparent,
                  grandparent: scope.grandparent,
                  parent: scope.parent});
          }
          PublishHelperService.updateStatus(scope.institution);
          PublishHelperService.updateStatus(scope.parent);
          PublishHelperService.updateStatus(scope.grandparent);
          PublishHelperService.updateStatus(scope.greatgrandparent);
        };
        function doesParentHaveAllChildrenSelected(inst) {
          let childrenSelected = true;
          if(inst.hasChildren) {
              if(!inst.children.length > 0) {
                  // Exit out early and assume false if children haven't been expanded
                  return false;
              }
              inst.children.forEach(function(child){
                  if(child.active) {
                      childrenSelected &= isFullyChecked(child);
                  }
              })
          }
          return childrenSelected;
      }
      function isFullyChecked(inst) {
        let allChildrenSelected = true;
        if(inst.active) {
            if (inst.hasChildren) {
                inst.children.forEach(function (child) {
                    if (child.active) {
                        allChildrenSelected &= isFullyChecked(child);
                    }
                })
            }
        }
        return allChildrenSelected && (inst.status === 'checked' || inst.status === 'check-all');
    }
      }
    }
  }
]);
