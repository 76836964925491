app.factory('ReportService', [ '$resource', function($resource){
    return $resource(
            reports_api_hostname +'/api/reports',
        null,
        {
            'reports' : {url: reports_api_hostname + '/api/reports/with-filters', method: 'POST', isArray: true},
            'reportsForUserProfile' : {url:  users_api_hostname +  '/users/:userId/:customerId/get-objects/report', method : 'GET', isArray: true},
            'getAllReportsByCustomer' : {url:  users_api_hostname +  '/users/:customerId/get-objects/report', method : 'GET', isArray: true},
            'reportIdsForInstitution' : {url:  reports_api_hostname + '/api/reports/institution-reports', method : 'GET', params: {userId: '@userId', institutionId: '@institutionId'}, isArray: true},
            'surveyTypes' : {url:  reports_api_hostname + '/api/reports/survey-content-types', method : 'POST', isArray: true},
            'institutions' : {url:  reports_api_hostname + '/api/reports/institutions', method : 'GET', params: {surveyContentId: '@surveyContentId', userId: '@userId', institutionId: '@institutionId'}, isArray: true},
            'surveys' : {url:  reports_api_hostname + '/api/reports/surveys', method : 'POST', isArray: true},

            getReportRequestId: { url: reports_api_hostname + '/api/reports/generateReportNow', method: "POST",
                                  transformResponse: function(data) { return { requestId: data } },
                                },
            pollStatus: { url: reports_api_hostname + '/api/reports/:requestId/request-status', method: "GET", params: {requestId: '@requestId'},
                          transformResponse: function(data) { return { status: data } },
                        },
            getDataFromCache: {url: reports_api_hostname + '/api/reports/:requestId/request-data', method: "GET", params: {requestId: '@requestId'}},
            disableEmailNotification: {url: reports_api_hostname + '/api/reports/:requestId/disable-email-notification', method: "PUT", params: {requestId: '@requestId'}},
            updateReportCache: {url: reports_api_hostname + '/api/reports/updateReportCache', method: "POST", params: {requestId: '@requestId', userId: '@userId', reportId: '@reportId'}},

            'checkReportNameForDuplicates' : {url:  reports_api_hostname +  '/api/reports/:customerId/:reportName/:reportId', method : 'GET', isArray: true},

            'dontSendEmail' : {url: reports_api_hostname + '/api/reports/notifyMe', method: 'GET', params: {requestId: '@requestId'}},

            'filterIncompleteSurveys' : {url:  reports_api_hostname + '/api/reports/complete-response-surveys', method : 'GET', isArray: true},
            'saveReport' : {method : 'POST'},
            'saveReportUser': {url:  users_api_hostname + '/users/save-object', method: 'POST'},
            'preferences' : {url:  reports_api_hostname + '/api/reports/:reportId/preferences', method : 'GET', params: {reportId: '@reportId', languageId: '@languageId'}},
            'deleteReport' : {url:  reports_api_hostname + '/api/reports/:reportId', method : 'DELETE', params:{reportId: '@reportId', userId: '@userId'}},
            'rawData': {url:  reports_api_hostname + '/api/reports/:reportId/raw', method : 'GET', params:{reportId: '@reportId', userId: '@userId', languageId: '@languageId'}},
            'createRawDataReport': {url:  reports_api_hostname + '/api/reports/:reportId/raw', method : 'POST', params:{reportId: '@reportId', userId: '@userId', languageId: '@languageId'}},
            'getOpenEndedAnswers': {url:  reports_api_hostname + '/api/reports/openEndedAnswers', method : 'POST', isArray: true},
            'createRawDataUnstackedReport': {url:  reports_api_hostname + '/api/reports/:reportId/unstacked', method : 'POST', params:{reportId: '@reportId', userId: '@userId', languageId: '@languageId'}},
            'createPdfReport': {url:  pdfreactor_api_hostname + '/v1/pdf/getSurveysReportPdf', method: 'POST'},
            'responseLanguages': { url: reports_api_hostname + '/api/reports/response-languages', method: 'POST', params: { institutionId: '@institutionId', userId: '@userId' }, isArray: false},
            'updatePDFCounter': { url: reports_api_hostname + '/api/reports/:requestId/update-pdf-counter', method: 'POST', params: {requestId: '@requestId'}},
            'updateCSVCounter': { url: reports_api_hostname + '/api/reports/:requestId/update-csv-counter', method: 'POST', params: {requestId: '@requestId'}},
            'downloadCSVReport': {url:  reports_api_hostname + '/api/reports/sharing/survey', method : 'POST'},
        }
    );
}
]);
