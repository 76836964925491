app.controller('SharingTemplateCtrl', 
    ['$location',
     '$scope', 
     '$routeParams', 
     '$rootScope', 
     'ContentTemplateService', 
     'PublishHelperService', 
     '$window', 
     '$timeout', 
     'modalService', 
     'userService',
     'applicationService',
     '$sessionStorage',
  function($location, 
           $scope, 
           $routeParams, 
           $rootScope, 
           ContentTemplateService, 
           PublishHelperService, 
           $window, 
           $timeout,
           modalService,
           userService,
           applicationService,
           $sessionStorage
      ) {

  

        var addInstAttrsForSharing = function(data) {
      return data.map(function(inst) {
        inst.active = inst.isActive;
        inst.selected = inst.displayType === "FULL_CHECKED" || inst.displayType === "CHECKED";
        inst.showChildren = false;
        inst.hasChildren = inst.childInstitutionCount >= 1;
        inst.originalImmediateSharedChildrenCount = inst.immediateSharedChildrenCount;
        inst.publishToChildren = !!inst.shareToChildInstitutions;
        inst.originalPublishToChildren = !!inst.shareToChildInstitutions;
        inst.childrenLoaded = false;
        inst.name = inst.institutionName;
        inst.children = [];
        inst.status = getInstStatus(inst);
        inst.originalStatus = getInstStatus(inst);
        return inst;
      });
    };
   
    function getInstStatus(inst) {
      switch(inst.displayType) {
        case 'CHECKED':
          return 'checked';
        case 'FULL_CHECKED':
          return 'check-all';
        case 'PARTIAL':
          return 'check-partial';
        default:
          return 'unchecked';
      }
    }

    /* DEFAULTS */
    $rootScope.headerAction = 'hidden'; //makes sure the top nav bar gets shown
    $scope.confirmSelections = false;
    $scope.confirmSelectionEdit = false;
    $scope.sharingOptions = {};
    $scope.master = [];
    $scope.parentInstitutions = [];
    $scope.selectDisabled;
    $scope.filterSelected = false;
    $scope.templateName = "";
    $scope.template;
    $scope.loading = false;

    $scope.startIndex = 0;

    const isBrowserIE = function() {
      const userAgent = $window.navigator.userAgent;
      const regexIE = /trident/i;
      return regexIE.test(userAgent);
    };

    $scope.magicScroll = isBrowserIE();
    $scope.limit = $scope.magicScroll ? 100 : undefined;

    $rootScope.$on('loadingInstitutions', function(event, data) {
      $scope.loading = data;
    });

    $rootScope.$on('recalculateAncestory', function(event, data) {
       if(data.greatgrandparent) {
           recalcDisplayType(data.greatgrandparent);
       } else if (data.grandparent) {
           recalcDisplayType(data.grandparent);
       } else if(data.parent) {
           recalcDisplayType(data.parent);
       }
    });

      /**
       * Will recalculate all display types recursively traversing all the way down the stack and calculating back up
       * @param institution
       */
    const recalcDisplayType = function(institution) {

        // Keep track of boolean counts to determine if a parent has all of its children checked, or if it has some, or none
        let selectedChildren = 0;
        if(institution.children) {
            institution.children.forEach(function(childInstitution){
                selectedChildren += recalcDisplayType(childInstitution) ? 1 : 0;
            });
        }

        let activeChildCount = institution.children.filter(function(child) {
            return child.active;
        }).length;

        // Has children, and they have been downloaded, and has at least some active children
        if( institution.childInstitutionCount > 0 && institution.children.length > 0 && activeChildCount > 0) {
            // has all children selected
            if (selectedChildren === activeChildCount) {
                // IF - parent was already selected - should become full checked
                // IF - parent was NOT already selected - should become partial
                institution.status = (institution.status === "unchecked" || institution.status === 'check-partial') ? "check-partial" : "check-all";
                institution.publishToChildren = institution.status === 'check-all';
            }
            // has less than all the children selected
            else if (selectedChildren > 0){
                // IF - parent was already selected - should become checked
                // IF - parent was NOT already selected - should become partial
                institution.status = (institution.status === "unchecked" || institution.status === 'check-partial') ? "check-partial" : "checked";
                institution.publishToChildren = false;
                institution.immediateSharedChildrenCount = selectedChildren;
            }
            // has NO children selected
            else {
                institution.status = (institution.status === "checked" || institution.status === "check-all") ? "checked" : "unchecked";
                institution.publishToChildren = false;
            }
        }
        // Has children, and they have NOT been downloaded
        else if (institution.childInstitutionCount > 0 && institution.children.length === 0) {
            // Do not modify status, and set publish to children based on existing check status only
            institution.publishToChildren = institution.status === "check-all" || institution.status === 'check-partial';
        }
        // Has no children
        else {
            // has no children, don't modify status, just explicitly set publishToChildren to false
            institution.publishToChildren = false;
        }

        return institution.status != "unchecked" ;
    };

    $scope.getPrimaryId = function() {
      return $scope.adminSurveyId || $scope.templateId;
    };

    /* ACTIONS ON PAGE LOAD */
    $scope.contentType = $location.search().type;

    if ($scope.contentType === 'admin' || $scope.contentType === 'nonadmin') {
      $scope.contentHeader = 'Sharing: Select Institutions';
      $scope.instTransformer = addInstAttrsForSharing;
      $scope.confirmationBtnText = 'Done';
    }

    if ($scope.contentType === 'admin') { // SES SURVEY - SURVEY SHARING
      $scope.adminSurveyId = $routeParams.templateId;
      $scope.templateId = $location.search().id;
      $scope.userInstitutionId = applicationService.getInstitution().id;
      $scope.surveyInstitutionId = parseInt($location.search().surveyInstitutionId);
      if($sessionStorage.surveySharingPermissions){
        $scope.hasSharingPermissions = $sessionStorage.surveySharingPermissions?!!$sessionStorage.surveySharingPermissions.hasSharingPermissions && $scope.templateId == $sessionStorage.surveySharingPermissions.surveyId :false;
      }else{
        $scope.hasSharingPermissions = false;
      }
      loadAdminContentInfo();
    } 

    $scope.backActionLink = '#/edit/' + $scope.templateId;
    /* END PAGE LOAD ACTIONS */

    /* UI INTERACTIONS */

    $scope.toggleSelectFilter = function() {
      $scope.filterSelected = !$scope.filterSelected;
    };
    $scope.toggleInstitutionEdit = function(institution){
      institution.selected = !institution.selected;
    }

    $scope.displayParentHierarchy = function(){
      return $scope.userInstitutionId == parseInt($scope.surveyInstitutionId) ;
    }
    $scope.passesFilter = function(inst) {
      return inst.selected || inst.status === 'checked' || inst.status === 'check-partial' || inst.status === 'full-checked';
    };
    $scope.editExit = function(){
      $location.url('/edit/' + $scope.templateId);
    }
    $scope.confirmEditPermissions = function(){
      modalService.presentCustomModal({
        title: '<div><i class="fa fa-exclamation-triangle"></i> Assign Permissions</div>',
        text: '<div><b>Are you sure you want to Assign Edit Permissions?</b> <br/><br/> <span>If you select "Yes" the institutions you selected will be able to edit the survey.</span></div>',
        html: true,
        cancelButtonText: 'Yes',
        confirmButtonText: 'Cancel',
        showCancelButton: true,

        customClass: 'survey-sharing-confirmation-warning',
        allowEscapeKey: true
      }, function () {
        // Nothing to execute
      },
      function () {
        // TODO save edit
        var body = {
          surveyId: $scope.getPrimaryId(),
          alteredPermissionList: $scope.getEditPermissionDeltaList($scope.originalEditList)
        };
        
        if(body.alteredPermissionList.length){
          ContentTemplateService.alterPermission(body).$promise.then(
            function(response) {
              $location.url('/edit/' + $scope.templateId);
            },
            function(error) {
              sweetAlert("There was an error saving the edit permissions. This is most likely a problem connecting to the server. Please try again later.", error, "error");
            }
          );
        }
        $location.url('/edit/' + $scope.templateId);
      }
      );
    }
    $scope.getEditPermissionDeltaList = function(originalEditList){
      var editList = [];
      for (let d=0; d < $scope.confirmationEditList.length; d++) {
        if ($scope.confirmationEditList[d].selected != originalEditList[d].selected) {
          editList.push({
            institutionId : $scope.confirmationEditList[d].institutionId,
            permission: $scope.confirmationEditList[d].selected? 'EDIT' : 'VIEW'
          });
        }
      }
      return editList;
    }
    $scope.clearSelections = function(){
      modalService.presentCustomModal({
        title: '<div><i class="fa fa-exclamation-triangle"></i> Clear Selections</div>',
        text: '<div><b>Are you sure you want to clear selections and start over?</b> <br/><br/> <span>Select "Yes" to clear your selections and start over.</span><br/><br/> <span>Select "No" to keep your selections and stay on this page.</span></div>',
        html: true,
        cancelButtonText: 'Yes',
        confirmButtonText: 'No',
        showCancelButton: true,
        customClass: 'survey-sharing-confirmation-warning',
        allowEscapeKey: true
      }, function () {
        // Nothing to execute
        },function(){
          // clear selections
          $scope.ownerInstitution.displayType = "UNCHECKED";
          loadAdminContentInfo();
        }
      );
    }
    var successHandler = function () {
      var distributionContent = $scope.contentType === 'admin' || $scope.contentType === 'nonadmin';
            $scope.confirmSelections = !$scope.confirmSelections;
            if ($scope.confirmSelections && !distributionContent) {
              $scope.confirmationList = buildSharingListWithNames($scope.master);
            } else {
              $scope.confirmationList = buildSaveList($scope.master, $scope.ownerInstitution, {});
            }
      // exclude displaying parent institutions in review screen if institution is not survey owner
      if (!$scope.displayParentHierarchy()) {
        $scope.confirmationList = $scope.confirmationList.filter(function (instituion) {
          return !instituion.isParent;
        });
      }
    }
    $scope.toggleConfirmSelections = function(doubleDare) {
      if(doubleDare){
        modalService.presentCustomModal({
          title: '<div><i class="fa fa-exclamation-triangle"></i> Institution Sharing</div>',
          text: '<div><b>Are you sure you want to share?</b> <br/><br/> <span>If you select "Yes" the survey will be shared with the selected instututions.</span></div>',
          html: true,
          cancelButtonText: 'Yes',
          confirmButtonText: 'Cancel',
          showCancelButton: true,
          customClass: 'survey-sharing-confirmation-warning',
          allowEscapeKey: true
        }, function () {
           // Nothing to execute
          },function(){
            successHandler();
          }
        );
      }else{
        successHandler();
      }
    };

    $scope.showParentHierarchy = function() {
      if ($scope.parentInstitutions.length) {
        if(!$scope.parentInstitutions[0].showChildren)
        return $scope.parentInstitutions[0].showChildren;
      }
      if ($scope.ownerInstitution) {
        return $scope.ownerInstitution.showChildren
      } else  {
        return true;
      }
    };

    $scope.presentDeselectAllModal = function() {
            modalService.presentCustomModal({
                title: "<i class='fa fa-check-circle-o selection-modal-title-icon'></i> <span class='selection-modal-title-text'>Confirm Deselection</span>",
                type: 'info',
                html: true,
                text: "Select \"confirm\" to remove all of the institutions associated with this owner institution.",
                customClass: 'confirmDeselectAllAlert',
                confirmButtonColor: '#148900',
                confirmButtonText: 'Confirm',
                showCancelButton: true,
                cancelButtonText: "Cancel",
                cancelButtonColor: "#D0D0D0",
                animation: false,
            }, function() {
              // Note this is actually the cancel handler running confirm logic due to button reversal
              $timeout(function() {
                  PublishHelperService.forceUnselectInstitutionsHierarchy($scope.master);
              });
            }, function() {
              // Note this is actually the confirm handler running cancel logic due to button reversal
              // Nothing to execute
            }
        );
    };
    $scope.doTheBack = function() {
      window.history.back();
    };
    $scope.shareToSelectedPreCheck = function() {
      saveSelections();
      modalService.presentCustomModal({
        title: '<div><i class="header fa fa-users"></i> Assign Permissions</div>',
        text: '<div>All of your selected institutions now have access to view surveys.<hr/><b>Would you like to assign edit permissions to any of the selected institutions?</b> <br/><br/> <span>Select "Edit" if you want to assign edit permissions to the selected institutions.</span><br/><br/><span>Select "Done" to return to the survey setup page.</span></div>',
        html: true,
        cancelButtonText: 'Edit',
        confirmButtonText: 'Done',
        showCancelButton: true,

        customClass: 'survey-sharing-confirmation-modal',
        allowEscapeKey: true
      }, function () {
        $location.url('/edit/' + $scope.templateId);
      },
      function () {
        // navigate to edit view
        $scope.confirmSelections = false;
        $scope.confirmSelectionEdit = true;
        $scope.confirmationEditList = [];
        var startIndex = 0;
        var fetchMore = function (index) {
          var request = {
            surveyId: $scope.getPrimaryId(),
            parentInstitutionId: $scope.userInstitutionId,
            fromIndex: index,
            pageSize: 1000
          }
          ContentTemplateService.getParentSharingInfo(request).$promise.then(
            function (response) {
              if ($scope.confirmSelectionEdit) {
                $scope.confirmationEditList.push(...buildEditListWithNames(response));
                $scope.originalEditList = angular.copy($scope.confirmationEditList);
                if(response.length == 1000){
                  fetchMore($scope.originalEditList.length);
                }
              }
            },
            function (error) {
              sweetAlert("There was an error fetching the edit permissions. This is most likely a problem connecting to the server. Please try again later.", error, "error");
            }
          );
        }
        fetchMore(startIndex);
      });
    };
  
    function buildEditListWithNames(master) {
      var publishList = [];
      for (let a=0; a < master.length; a++) {
          publishList.push(sharingAttrsEdit(master[a]));
      }
      return publishList;
    }

    /* API CALLS */
   
    function saveSelections() {
      var deltaList = buildSaveList($scope.master, $scope.ownerInstitution, {deltaList: true});
      var sharingType = 'SURVEY_SHARING';
      var id = $scope.getPrimaryId();
      $scope.ownerInstitution.children = undefined;
      var parentList;
      // check if any of parents are full_checked
      parentList = $scope.parentInstitutions.filter(function(instituion){
        return instituion.displayType == 'FULL_CHECKED';
      });
     
      var body = {
        deltaList: scrubDeltaList(deltaList),
        loginInstitutionId: $scope.ownerInstitution.institutionId,
        modifiedByUserId: userService.getUserId(),
        surveySharingType: sharingType,
        modifiedParentList: null
      };
      if(parentList && parentList.length > 0 && body.deltaList.length){
        body.modifiedParentList = [$scope.ownerInstitution];
        body.modifiedParentList.push(...$scope.parentInstitutions.reverse());
      }
      if ($scope.contentType === 'admin') {
        body.surveyId = id;
      } else {
        body.templateId = id;
      }
      ContentTemplateService.saveDistributionSelections(body).$promise.then(
        function() {
          // Nothing to execute
        },
        function(error) {
          sweetAlert("There was an error saving the distribution info. This is most likely a problem connecting to the server. Please try again later.", error, "error");
        }
      );
    }

    function loadAdminContentInfo() {
        var id = $scope.getPrimaryId();
        ContentTemplateService.getAdminSharingInfo({surveyId: id, surveyInstitutionId: $scope.userInstitutionId,parentHierarchy:$scope.hasSharingPermissions }).$promise.then(
        function(data) {
                 
          if($scope.surveyInstitutionId  && $sessionStorage.ownerCustomerName && $sessionStorage.ownerCustomerName[$scope.surveyInstitutionId ])
          $scope.ownerCustomerName = $sessionStorage.ownerCustomerName[$scope.surveyInstitutionId ];

          $scope.ownerInstitution = addInstAttrsForSharing([data.ownerInstitutionDetails.ownerInstitution])[0];
         
         
          $scope.institutionName = $scope.ownerInstitution.institutionName;
          $scope.institutionId = $scope.ownerInstitution.institutionId;
          $scope.ownerInstitution.showChildren = true;
          $scope.ownerInstitution.children = data.ownerInstitutionDetails.childInstitutions;
        //  if($scope.ownerInstitution.childInstitutionCount == $scope.ownerInstitution.immediateSharedChildrenCount){
        //   $scope.ownerInstitution.status = 'check-all'; 
        //  }
          
          $scope.ownerInstitution.active = true;
          $scope.templateName = data.surveyDetails.surveyName;
          $scope.master = addInstAttrsForSharing(data.ownerInstitutionDetails.childInstitutions);
          if(data.ownerInstitutionDetails.parentInstitutions){
            data.ownerInstitutionDetails.parentInstitutions.reverse();
            $scope.parentInstitutions = addInstAttrsForSharing(data.ownerInstitutionDetails.parentInstitutions);
            $scope.parentInstitutions.forEach(function(inst){
              inst.isParent = true;
              inst.showChildren = true;
              inst.hasChildren = true;
              return inst;
            });
          }
        
          var instName = data.ownerInstitutionDetails.ownerInstitution.institutionName;
          var dataLength = data.ownerInstitutionDetails.childInstitutions.length;
          $scope.sharingOptions = {
            available: getSharingDropdownOptions(instName, dataLength, {})
          };
          if($scope.sharingOptions.available.length > 1) {
              if ($scope.ownerInstitution.displayType === 'FULL_CHECKED') {
                  $scope.sharingOptions.selected = $scope.sharingOptions.available[0];
              } 
              else { 
                  $scope.sharingOptions.selected = $scope.sharingOptions.available[1];
              }
          } else {
              $scope.sharingOptions.selected = $scope.sharingOptions.available[0]
          }
        },
        function(error) {
          sweetAlert("There was an error loading the survey sharing information. This is most likely a problem connecting to the server. Please try again later.", error, "error");
        }
      );
    }


    /* HELPER FUNCTIONS */
    function sharingAttrsFull(inst) {
      return {
        institutionId: inst.institutionId,
        name: inst.name,
        publishToChildren: inst.publishToChildren,
        selected: false
      };
    }
    function sharingAttrsEdit(inst) {
      return {
        institutionId: inst.institutionId,
        name: inst.institutionName,
        publishToChildren: inst.publishToChildren,
        selected: inst.permission == "EDIT"
      };
    }

    function publishAttrsDistribution(inst) {
      var newInst = {
        name: inst.institutionName,
        institutionId: inst.institutionId,
        displayType: getInstStatusforApi(inst.status),
        shareToChildInstitutions: inst.publishToChildren,
        publishToChildren: inst.publishToChildren,
        immediateSharedChildrenCount: getImmediateChildSharedCount(inst)
      };
      newInst.prevDisplayType = inst.status !== inst.originalStatus ? getInstStatusforApi(inst.originalStatus) : inst.prevDisplayType;
      return newInst;
    }

    function scrubDeltaList(confirmationList) {
      return confirmationList.filter( function(inst) {
        inst.publishToChildren = undefined;
        inst.name = undefined;
        return inst;
      });
    }

    function getImmediateChildSharedCount(inst) {
      return inst.children.reduce( function(count, inst) {
        if (inst.status === 'checked' || inst.status === 'check-all' || inst.status === 'check-partial') {
          count++;
        }
        return count;
      }, 0);
    }

    function getInstStatusforApi(status) {
      switch(status) {
        case 'checked':
          return 'CHECKED';
        case 'check-all':
          return 'FULL_CHECKED';
        case 'check-partial':
          return 'PARTIAL';
        default:
          return 'UNCHECKED';
      }
    }

    function buildSaveList(master, ownerInst, options) {
      ownerInst.name = ownerInst.institutionName;
      if ($scope.sharingOptions.selected.id === 1) {
        ownerInst.publishToChildren = true;
        ownerInst.shareToChildInstitutions = true;
        ownerInst.displayType = 'FULL_CHECKED';
        ownerInst.status = 'check-all';
        ownerInst.prevDisplayType = ownerInst.status !== ownerInst.originalStatus ? getInstStatusforApi(ownerInst.originalStatus) : ownerInst.prevDisplayType;
        ownerInst.immediateSharedChildrenCount = $scope.master.length;
        return [ownerInst];
      } 
      
      else { // selected institutions option
        ownerInst.immediateSharedChildrenCount = getImmediateChildSharedCount({children: master});
        ownerInst.shareToChildInstitutions = ownerInst.immediateSharedChildrenCount && (ownerInst.immediateSharedChildrenCount == ownerInst.childInstitutionCount);
        ownerInst.displayType = getInstStatusforApi(ownerInst.status);
        ownerInst.prevDisplayType = ownerInst.status !== ownerInst.originalStatus ? getInstStatusforApi(ownerInst.originalStatus) : ownerInst.prevDisplayType;
        // when institution status has been changed save entire list ;  no deep checking required
        if(ownerInst.status !== ownerInst.originalStatus){
          var distributionList = buildSaveListFromMaster([ownerInst], options);
          }
        else{
        var distributionList = buildSaveListFromMaster(master, options);
        if (options.deltaList && (ownerInst.status !== ownerInst.originalStatus || ownerInst.immediateSharedChildrenCount !== ownerInst.originalImmediateSharedChildrenCount)) { 
          distributionList.unshift(ownerInst); 
        }
        }
        return distributionList;
      }
    }
    // When an instituion status changed from full_checked to checked/partial/unchecked skip further
    // checking & add all child insitutuions
    function skipDeltaChange(parent,child){
      return child.status !== 'unchecked' && parent.originalStatus == "check-all" && (parent.status == "checked" || parent.status == 'check-partial' || parent.status == "unchecked" );
  }
    function buildSaveListFromMaster(master, options) {
      var distributionList = [];
      if($scope.parentInstitutions){
        $scope.parentInstitutions.forEach(function(inst){
        if (institutionPassesFilter(inst, options)) { distributionList.push(publishAttrsDistribution(inst)); }
      });
    }
      master.forEach(function(parent) {
        if (institutionPassesFilter(parent, options)) { distributionList.push(publishAttrsDistribution(parent)); }
        if (!parent.publishToChildren || (parent.publishToChildren && parent.status === 'check-partial')) {
          parent.children.forEach(function(child) {
            if (institutionPassesFilter(child, options,parent) || skipDeltaChange(parent,child)) { distributionList.push(publishAttrsDistribution(child)); }
            if (!child.publishToChildren || (child.publishToChildren && child.status === 'check-partial')) {
              child.children.forEach(function(grandchild) {
                if (institutionPassesFilter(grandchild, options,child)|| skipDeltaChange(child,grandchild)) { distributionList.push(publishAttrsDistribution(grandchild)); }
                if (!grandchild.publishToChildren || (grandchild.publishToChildren && grandchild.status === 'check-partial')) {
                  grandchild.children.forEach(function(greatgrandchild) {
                    if (institutionPassesFilter(greatgrandchild, options)) { distributionList.push(publishAttrsDistribution(greatgrandchild)); }
                  });
                }
              });
            }
          });
        }
      });
      return distributionList;
    }

    function institutionPassesFilter(inst, options,parent) {
      if (options.deltaList) {
        // skip unchecked child instutions with fully_checked parent -> checked/partial state
        if(parent && inst.status == 'unchecked' && parent.originalStatus == "check-all" && (parent.status == "checked" || parent.status == 'check-partial' || parent.status == "unchecked"  ))
            return false;
            else
        return inst.status !== inst.originalStatus ||
          inst.publishToChildren !== inst.originalPublishToChildren ||
          inst.immediateSharedChildrenCount !== inst.originalImmediateSharedChildrenCount;
      } else {
        return inst.selected;
      }
    }


    function buildSharingListWithNames(master) {
      if ($scope.sharingOptions.selected.id === 1) {
        return [{institutionId: $scope.institutionId, publishToChildren: true, name: $scope.institutionName}];
      } 
      else {
        return buildSharingListWithNamesFromMaster(master);
      }
    }

    function buildSharingListWithNamesFromMaster(master) {
      var publishList = [];
      for (let a=0; a < master.length; a++) {
        if (master[a].selected) {publishList.push(sharingAttrsFull(master[a]));}
        if (!master[a].publishToChildren) {
          for (let b=0; b < master[a].children.length; b++) {
            if (master[a].children[b].selected) {publishList.push(sharingAttrsFull(master[a].children[b]));}
            if (!master[a].children[b].publishToChildren) {
              for (let c=0; c < master[a].children[b].children.length; c++) {
                if (master[a].children[b].children[c].selected) {publishList.push(sharingAttrsFull(master[a].children[b].children[c]));}
              }
            }
          }
        }
      }
      var parent = $scope.parentInstitutions;
      if(parent){
        for (let d=0; d < parent.length; d++) {
          if (parent[d].selected) {publishList.push(sharingAttrsFull(parent[d]));}
        }
      }
      return publishList;
    }

    function getSharingDropdownOptions(name, instCount, options) {
        return [
          { id: 1, name: "Everyone In " + name + "'s Network" },
          { id: 3, name: "Selected Institutions" }
        ];
    };

    //  Binding controller functions to $scope to be avilable for test scripts
    $scope.addInstAttrsForSharing = addInstAttrsForSharing;
    $scope.getInstStatusforApi = getInstStatusforApi;
    $scope.buildSharingListWithNames = buildSharingListWithNames;
    $scope.sharingAttrsFull = sharingAttrsFull;
    $scope.publishAttrsDistribution = publishAttrsDistribution;
    $scope.scrubDeltaList = scrubDeltaList;
    $scope.getImmediateChildSharedCount = getImmediateChildSharedCount;
    $scope.institutionPassesFilter = institutionPassesFilter;
    $scope.getSharingDropdownOptions = getSharingDropdownOptions;
    $scope.buildSharingListWithNamesFromMaster = buildSharingListWithNamesFromMaster;
    $scope.buildSaveListFromMaster = buildSaveListFromMaster;
    $scope.buildSaveList = buildSaveList;
    $scope.recalcDisplayType = recalcDisplayType;
    $scope.loadAdminContentInfo = loadAdminContentInfo;
    $scope.saveSelections = saveSelections;

}]);
