/**
 * Created by pvo on 4/2/15.
 */

// Note: The URL values will be externalized per environment (dev, QA, UAT, prod)
// These will be replaced by TeamCity with environment variables by running script replaceConstantsJsEnvironment.ps1

//....
// If you add a new URL or environment specific property please update the above script
// In addition get together with Jason (OPS) and add environment variables in TeamCity for each environment

/* qa env.
var hostname = 'https://eproveapi.advanc-ed.org';
var customer_api_hostname = 'https://eproveapi.advanc-ed.org/customer-api/v1';
var reports_api_hostname = 'https://eproveapi.advanc-ed.org/echo-report-api';
var users_api_hostname = 'https://eproveapi.advanc-ed.org/users-api';
var sales_api_hostname = 'https://eproveapi.advanc-ed.org/sales-api/v1';
var email_api_hostname = 'https://eproveapi.advanc-ed.org/email-api';
var reports_shared_drive_uri = '//eprovereports.advanc-ed.org/reports/';
var tokens_shared_drive_uri = '//eprovereports.advanc-ed.org/reports/';
var pdfreactor_api_hostname = 'https://eproveapi.advanc-ed.org/pdf-service';
var pdf_reports_enabled = true;
var account_setup_url_login = 'https://eprovesurveys.advanc-ed.org/surveys';
var my_journey_url_login = 'https://myjourney.cognia.org/';
var ses_redirect_url = 'https://www.eprovelearner.org/dashboard';
 */
/* UAT env
var hostname = 'https://eproveapi.advanc-ed.org';
var customer_api_hostname = 'https://eproveapi.advanc-ed.org/customer-api/v1';
var reports_api_hostname = 'https://eproveapi.advanc-ed.org/echo-report-api';
var users_api_hostname = 'https://eproveapi.advanc-ed.org/users-api';
var sales_api_hostname = 'https://eproveapi.advanc-ed.org/sales-api/v1';
var email_api_hostname = 'https://eproveapi.advanc-ed.org/email-api';
var reports_shared_drive_uri = '//eprovereports.advanc-ed.org/reports/';
var tokens_shared_drive_uri = '//eprovereports.advanc-ed.org/reports/';
var pdfreactor_api_hostname = 'https://eproveapi.advanc-ed.org/pdf-service';
var pdf_reports_enabled = true;
var account_setup_url_login = 'https://eprovesurveys.advanc-ed.org/surveys';
var my_journey_url_login = 'https://myjourney.cognia.org/';
var ses_redirect_url = 'https://www.eprovelearner.org/dashboard';
 */


/* dev env.*/
var hostname = 'https://eproveapi.advanc-ed.org';
var queuing_api_hostname = 'https://eproveapi.cognia.org/queueing-api';
var customer_api_hostname = 'https://eproveapi.advanc-ed.org/customer-api/v1';
var reports_api_hostname = 'https://eproveapi.advanc-ed.org/echo-report-api';
var users_api_hostname = 'https://eproveapi.advanc-ed.org/users-api';
var sales_api_hostname = 'https://eproveapi.advanc-ed.org/sales-api/v1';
var email_api_hostname = 'https://eproveapi.advanc-ed.org/email-api';
var reports_shared_drive_uri = '//eprovereports.advanc-ed.org/reports/';
var tokens_shared_drive_uri = '//eprovereports.advanc-ed.org/reports/';
var pdfreactor_api_hostname = 'https://eproveapi.advanc-ed.org/pdf-service';
var pdf_reports_enabled = true;
var account_setup_url_login = 'https://eprovesurveys.advanc-ed.org/surveys';
var my_journey_url_login = 'https://myjourney.cognia.org/';
var ses_redirect_url = 'https://www.eprovelearner.org/dashboard';
var roster_shared_drive_uri = '//eprovereports.advanc-ed.org/roster-surveys/SES_Roster.zip';
//var roster_shared_drive_uri = '//eprovereports.advanc-ed.org/roster-surveys/SES_Roster.zip';

// Production settings
//var hostname = 'https://eproveapi.advanc-ed.org';
//var customer_api_hostname = 'https://eproveapi.advanc-ed.org/customer-api/v1';
//var users_api_hostname = 'https://eproveapi.advanc-ed.org/users-api';
//var sales_api_hostname = 'https://eproveapi.advanc-ed.org/sales-api/v1';
//var email_api_hostname = 'https://eproveapi.advanc-ed.org/email-api';
//var pdfreactor_api_hostname = 'https://eproveapi.advanc-ed.org/pdf-service';
//var account_setup_url_login = 'https://eprovesurveys.advanc-ed.org/surveys';
//*********************

var change_roster_error = "rosterChangeError";
var unstacked_csv = "unstacked_csv";
var preview_report = "preview-report";
var view_report = "view-report";
var SES_STATUS_MONITORING = "SES_STATUS_MONITORING";
var download_ses_report = "download-ses-report";
var SES_STUDENT_ROSTER = "SES_STUDENT_ROSTER";
var SES_TOTAL_PARTICIPANTS = "SES_TOTAL_PARTICIPANTS";
var SES_FILTERED_PARTICIPANTS = "SES_FILTERED_PARTICIPANTS";
var certifiedContentTargets = ["All", "Parent", "Staff", "Student"];
var certifiedContentNames = ["All", "Draft", "Published", "Archived"];
var survey_status = ["", "Open", "Closed", "Draft", "Archived"];
var english_language_id = 1;
var spanish_language_id = 6;
var english_accesstoken_prompt= 'Enter your eProve survey code';
var spanish_accesstoken_prompt = 'Ingrese el código de la encuesta en eProve';
var english_take_survey = 'Take survey';
var spanish_take_survey = 'Participar de la encuesta';
var english_token_error_message = 'There was an issue with the code you entered. Please check the code and try again.';
var spanish_token_error_message = 'Hubo un problema con el código que usted ingresó. Por favor, compruebe el código y vuelva a intentarlo.';
var english_system_error_message = 'The system encountered a problem, please try again.';

var BE_EIGHT_CHARS = 'Be at least 8 characters';
var AT_LEAST_ONE_NUM = 'At least one number';
var AT_LEAST_ONE_LOWERCASE = 'At least one lowercase letter';
var AT_LEAST_ONE_UPPERCASE = 'At least one uppercase letter';
var AT_LEAST_ONE_SPECIAL_CHAR = 'At least one special character !@#$%^&()-_=+~`.*';
var TYPE_ACCREDITATION = 'Accreditation';
var application_name = "surveys";
var privacy_policy_url = 'https://static.cognia.org/privacy-policy.html';
var SURVEY_APP_ID = 2;

var BODY_CONTENT = {
    DASH_BOARD:'DASH_BOARD',
    CERTIFIED_CONTENT:'CERTIFIED_CONTENT',
    CERTIFIED_CONTENT_DEFAULT_VIEW:'CERTIFIED_CONTENT_DEFAULT_VIEW',
    CERTIFIED_CONTENT_CUSTOMIZING_DEFAULT_VIEW:'CERTIFIED_CONTENT_CUSTOMIZING_DEFAULT_VIEW',
    SURVEY_EDIT:'SURVEY_EDIT'
};

var allScaleValues = [
    {
        value: 0,
        text: 'N/A',
        icon: 'icon-block'
    },
    {
        value: 1,
        text: 'Strongly Disagree',
        icon: 'icon-opinion-1-strongly-disagree'
    },
    {
        value: 2,
        text: 'Disagree',
        icon: 'icon-opinion-2-disagree'
    },
    {
        value: 3,
        text: 'Neutral',
        icon: 'icon-opinion-3-neutral'
    },
    {
        value: 4,
        text: 'Agree',
        icon: 'icon-opinion-4-agree'
    },
    {
        value: 5,
        text: 'Strongly Agree',
        icon: 'icon-opinion-5-strongly-agree'
    }
].reverse()

var scaleChoices = {
    3: [{
        value: 3,
        text: 'Agree',
        icon: 'icon-opinion-4-agree'
    },
        {
            value: 2,
            text: 'Neutral',
            icon: 'icon-opinion-3-neutral'
        },
        {
            value: 1,
            text: 'Disagree',
            icon: 'icon-opinion-2-disagree'
        }],
    4: [{
        value: 4,
        text: 'Strongly Agree',
        icon: 'icon-opinion-5-strongly-agree'
    },
        {
            value: 3,
            text: 'Agree',
            icon: 'icon-opinion-4-agree'
        },
        {
            value: 2,
            text: 'Disagree',
            icon: 'icon-opinion-2-disagree'
        },
        {
            value: 1,
            text: 'Strongly Disagree',
            icon: 'icon-opinion-1-strongly-disagree'
        }],
    5: allScaleValues.slice(0,5)
};

var surveyAllStatus = 'All'
var surveyArchivedStatus = 'Archived';
var surveyDraftStatus = 'Draft';
var surveyOpenStatus = 'Open';
var surveyClosedStatus = 'Closed';
var surveyDraftStatusId = 3;
var surveyOpenStatusId = 1;
var surveyClosedStatusId = 2;
var surveyArchivedStatusId = 4;

var surveyClosingSoonStatus = 'Closing Soon';
var surveyAllType = 'All';
var dashboardSurveyTypes =  [surveyAllType, "My Surveys", "Shared with Me", "Certified Content"];
var dashboardSurveyStatuses =  [surveyAllStatus, surveyDraftStatus, surveyOpenStatus, surveyClosedStatus, surveyArchivedStatus];
var dashboardSurveyEditPermissions = ['create_survey', 'edit_survey', 'delete_survey', 'share_survey'];


/*
 var permissionsSet = {
 "ROLE_ADMIN" : ['create_survey','edit_survey','delete_survey','create_report','share_survey','copy_survey','view_survey','edit_report','delete_report','view_report'],
 "ROLE_EDITOR" : ['edit_survey','view_survey','edit_report','view_report'],
 "ROLE_VIEWER" : ['view_survey','view_report']
 }
 */

var dashboard_activity_section_chart_options = {
    y_axis: false,
    baseline_accessor:'sectionNetworkAverage',
    predictor_accessor:'sectionAverage',
    chart_type: 'bar',
    width: 230,
    height: 35,
    bar_height: 15,
    buffer: 0,
    bottom: 4,
    top: 0,
    left: 0,
    right: 0,
    outer_padding_percentage: 0,
    predictor_proportion: 1.5,
    animate_on_load: true,
    show_rollover_text: false,
    min_x: 0,
    xax_count: 5
};

var dashboard_activity_section_chart_options2 = {
    y_axis: false,
    predictor_accessor:'sectionAverage',
    chart_type: 'bar',
    width: 230,
    height: 35,
    bar_height: 15,
    buffer: 0,
    bottom: 4,
    top: 0,
    left: 0,
    right: 0,
    outer_padding_percentage: 0,
    predictor_proportion: 1.5,
    animate_on_load: true,
    show_rollover_text: false,
    min_x: 0,
    xax_count: 5
};


var dashboard_activity_big_chart_options = {
    y_axis: false,
    baseline_accessor:'networkAverage',
    predictor_accessor:'overallScore',
    chart_type: 'bar',
    //full_width: true, this option causing an error currently
    width: 400,
    height: 100,
    bar_height: 15,
    buffer: 0,
    // bottom: 0,
    top: 0,
    left: 5,
    right: 5,
    outer_padding_percentage: 0,
    predictor_proportion: 1.5,
    animate_on_load: true,
    show_rollover_text: false,
    min_x: 0,
    xax_count: 5
};


var dashboard_activity_big_chart_options2 = {
    y_axis: false,
    predictor_accessor:'overallScore',
    chart_type: 'bar',
    //full_width: true,  this option causing an error currently
    width: 400,
    height: 100,
    bar_height: 15,
    buffer: 0,
    // bottom: 0,
    top: 0,
    left: 5,
    right: 5,
    outer_padding_percentage: 0,
    predictor_proportion: 1.5,
    animate_on_load: true,
    show_rollover_text: false,
    min_x: 0,
    xax_count: 5
};

//Survey activity line graph
var dashboard_activity_spark_line_options = {
    width: 112,
    height: 60,
    buffer: 0,
    bottom: 0,
    top: 18,
    left: 0,
    right: 0,
    x_accessor: "date",
    y_accessor: "responses",
    x_axis: false, // determines whether to display the x-axis
    y_axis: false,
    area: false, //determines whether to fill the area below the line
    interpolate: "linear",
    show_rollover_text: true
    /*mouseover: function(d, i){
     //var format = d3.time.format("%Y-%m-%d");
     //d.date = format.parse(format(d.date));
     //console.log("came in function, i value " + ", d value " + d.date);
     //$('div#activity3 svg .mg-active-datapoint').html('Blah');
     }*/

};

var reportFilterTypes =  [surveyAllType, "My Reports", "Shared with Me"];
var reportEditPermissions = ['create_report', 'edit_report', 'delete_report'];

var report_overall_score_options = {
    y_axis: false,
    baseline_accessor:'networkAverage',
    predictor_accessor:'overallScore',
    chart_type: 'bar',
    //full_width: true,
    width: 400,
    height: 120,
    bar_height: 15,
    buffer: 0,
    // bottom: 0,
    top: 20,
    left: 5,
    right: 5,
    outer_padding_percentage: 0,
    predictor_proportion: 1.5,
    animate_on_load: true,
    show_rollover_text: false,
    min_x: 0,
    xax_count: 5,
    mouseover: function(d) {
        //custom format the rollover text
        var prefix = d3.formatPrefix(d.value);
        $('.scoreDataPlot svg .mg-active-datapoint')
            .html('Score: ' + prefix.scale(d.overallScore).toFixed(2)
            + ', Average: ' + prefix.scale(d.networkAverage).toFixed(2));
    }
};

var report_overall_score_options2 = {
    y_axis: false,
    predictor_accessor:'overallScore',
    chart_type: 'bar',
    //full_width: true,
    width: 400,
    height: 120,
    bar_height: 15,
    buffer: 0,
    //bottom: 0,
    top: 20,
    left: 5,
    right: 5,
    outer_padding_percentage: 0,
    predictor_proportion: 1.5,
    animate_on_load: true,
    show_rollover_text: false,
    min_x: 0,
    xax_count: 5,
    mouseover: function(d) {
        //custom format the rollover text
        var prefix = d3.formatPrefix(d.value);
        $('.scoreDataPlot svg .mg-active-datapoint')
            .html('Score: ' + prefix.scale(d.overallScore).toFixed(2));
    }
};

var report_activity_section_chart_options = {
    y_axis: false,
    baseline_accessor:'sectionNetworkAverage',
    predictor_accessor:'sectionAverage',
    chart_type: 'bar',
    width: 230,
    height: 35,
    bar_height: 15,
    buffer: 0,
    bottom: 4,
    top: 0,
    left: 0,
    right: 0,
    outer_padding_percentage: 0,
    predictor_proportion: 1.5,
    animate_on_load: true,
    show_rollover_text: false,
    min_x: 0,
    xax_count: 5
};

var report_activity_section_chart_options2 = {
    y_axis: false,
    predictor_accessor:'sectionAverage',
    chart_type: 'bar',
    width: 230,
    height: 35,
    bar_height: 15,
    buffer: 0,
    bottom: 4,
    top: 0,
    left: 0,
    right: 0,
    outer_padding_percentage: 0,
    predictor_proportion: 1.5,
    animate_on_load: true,
    show_rollover_text: false,
    min_x: 0,
    xax_count: 5
};

var report_activity_item_chart_options = {
    y_axis: false,
    predictor_accessor:'itemAverage',
    chart_type: 'bar',
    width: 230,
    height: 35,
    bar_height: 15,
    buffer: 0,
    bottom: 4,
    top: 0,
    left: 0,
    right: 0,
    outer_padding_percentage: 0,
    predictor_proportion: 1.5,
    animate_on_load: true,
    show_rollover_text: false,
    min_x: 0,
    xax_count: 5
};

var custom_content = "Custom Survey";
var certified_custom_content = "Custom Content";
var question_skipped_display = "Question skipped";

var errorPopup = function(t, msg, args){
    var currentTime = new Date().getSeconds();
    if (!msg) msg = "An unexpected error has occurred. Please reload this page and try again in a moment.";
    sweetAlert({title: t, text: msg , type:"info"}, function(){
        if(args){
            if(typeof args === 'function'){
                args();
            }
        }
    });
};

var errorPopupChoice = function(msg, args){
    var currentTime = new Date().getSeconds();
    if (!msg) msg = "An unexpected error has occurred. Please reload this page and try again in a moment.";
    sweetAlert({title: "Are you sure?", text: msg , type:"warning", confirmButtonColor: "#DD6B55", confirmButtonText: "Yes, delete it!", showCancelButton: true}, function(isConfirm){
        if(isConfirm){

            args();
        } else {
            sweetAlert("nothing was changed");
        }
    });
};

var validationWarning = function(title, msg, args){
    sweetAlert({title: title, confirmButtonColor: '#44b644'}, function(){
        if(args){
            if(typeof args === 'function'){
                args();
            }
        }
    })
};

var areYouSure = function(query, warning, callback, args, err) {
    sweetAlert({
        title: query,
        text: warning,
        confirmButtonColor: '#44b644',
        showCancelButton: true,
        cancelButtonColor: '#44b644'
    }, function(isConfirm) {
        if(typeof callback === 'function' && isConfirm) {
            callback(args);
        } else if (typeof callback !== 'function') {

            setTimeout(function(){
                sweetAlert({ title: err + ' Please contact eProve support.', text: '+1 888.413.3669', type: 'error', confirmButtonColor: '#44b644'});
            }, 500);
        } else if (!isConfirm) {
            return false;
        }
    })
};

var ADVANCED_LOGO="https://localhost:8080/surveys/images/AE_logo.jpg";  //http is fine in this case?
var TERMS_AND_CONDITIONS_PDF_URL = 'https://www.cognia.org/user-terms/';
var ADVANCED_URL= "https://www.advanc-ed.org/";

var opinion_scale_render_type = "Opinion Scale";
var text_field_render_type = "Text Field";

var survey_taking_regex_withoutLang = /\/action\/\d+\/\d+/gi;
var survey_taking_regex_withLang = /\/action\/\d+\/\d+\/\d+/gi;

var token_manage_options = {
    regexTokenValidate: /\d{8}/,
    regexTokenSearch: /\d{8}/, // keep separate for future proofing, may want to search for partials in the future
    maxTokensToDisplay: 500,
    maxTokensForSchool: 10000,
    maxTokensForTheRest: 750000
};
