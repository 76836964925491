app.factory('surveysHttpInterceptor', ['$q', '$sessionStorage', '$location','$injector', function($q, $sessionStorage, $location, $injector) {
    return {
        'request': function(config) {
            config.headers["authorization"] = $sessionStorage.accessToken;
            return config;
        },
        response: function(response){
            return response || $q.when(response);
        },
        responseError: function(rejection) {
            
            if (rejection.status === 401 || rejection.status === 0) {
                $sessionStorage.originalPath = $location.$$path;
                // We use $injector here to get the userService after angular bootsraps the project to avoid creating a circular
                // dependency at runtime.
                var applicationService = $injector.get('applicationService');
                applicationService.logout();
            }
            return $q.reject(rejection);
        }
    };
}]);


app.config(['$httpProvider', function($httpProvider) {
    $httpProvider.interceptors.push('surveysHttpInterceptor');
}]);