app.controller('SelectLangCtrl', ['$scope', '$routeParams', '$rootScope', '$location', 'ContentTemplateService',
  function($scope, $routeParams, $rootScope, $location, ContentTemplateService) {
    //hides the top bar that's used everywhere, so that the top bar specific to this page is the only one shown
    $scope.$root.headerAction = 'hidden';
    
    ContentTemplateService.getTemplateSupportedLanguages({templateId: $routeParams.templateId}).$promise.then(
      function(data) {
        $scope.languages = data;
        $scope.selectedLanguage = $scope.languages[0];
      },
      function(error) {
        sweetAlert("There was an error getting the template languages. This is most likely a problem connecting to the server. Please try again later.", error, "error");
      }
    );

    //just getting the template to check if it's certified, so language id doesn't matter, just passing 1 for english
    ContentTemplateService.get({templateId: $routeParams.templateId, languageId: 1}).$promise.then(
      function(data) {
        $scope.contentTemplate = data;
      },
      function(error) {
        sweetAlert("There was an error getting the content template. This is most likely a problem connecting to the server. Please try again later.", error.data.message, "error");
      }
    );

    $scope.backToEdit = function() {
      var backUrl = $location.search().back;
      if ( backUrl === 'browse' ) {
        $location.url('/browse-content');
      } else if ( backUrl === 'manage' ) {
        $location.url('/manage-content');
      } else if ( backUrl === 'institution-dashboard') {
        $location.url('/');
      } else {
        $location.url('/edit-template/' + $routeParams.templateId);
      }
    };

    $scope.goToPreview = function() {
      var backParam = $location.search().back
      var customUrl = "/preview-content/" + $routeParams.templateId + "/language/" + $scope.selectedLanguage.id + '?type=custom&back=' + backParam;
      var certifiedUrl = "/preview-content/" + $routeParams.templateId + "/language/" + $scope.selectedLanguage.id + '?type=certified&back=' + backParam;
      var url = $scope.contentTemplate.certified ? certifiedUrl : customUrl;
      $location.url(url);
    };

  }
]);
