app.directive('autoSize', function() {
  return function(scope, element, attrs){

    //$('.survey-sidebar__actions').css('height', '120px');

    var setSize = function(){
      $(element[0]).css('height', $('.survey-edit__sidebar').height() - $('.survey-sidebar__actions').height());//
    }

    // waits until the entire sidebar is loaded until setting size
    setTimeout(function(){
      setSize();
    }, 1000);

    $(window).resize(function() {
      setSize()
    });

  }
})

