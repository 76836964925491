app.directive('institutionSelector', ['customerService', 'userService', 'collectionUtils',
  function (customerService, userService, collectionUtils) {
    return {
      scope: true,
      replace: true,
      restrict: 'E',
      templateUrl: 'partials/institution/search-select.html',
      link: function (scope) {
        var SEARCH_SIZE = 15;
        var userAssociationsIds = scope.userAssociationsIds;
        var userAssociationsIdsStr = collectionUtils.convertToStrings(userAssociationsIds);

        scope.superUser = userService.getIsSuperUser();

        var getInstitutions = function () {
          customerService.getCustomersWithChildStatus(userAssociationsIds, getCustomersSuccess, null);
        };

        var getCustomersSuccess = function (customersInfo) {
          scope.institutions = customersInfo;
          if (scope.expandTopLevel && scope.institutions) {
            scope.institutions.forEach(
              function (institution) {
                if (scope.hasChildren(institution)) {
                  scope.toggleExpand(institution);
                }
            });
          }
        };

        if (!scope.superUser) {
          getInstitutions();
        }

        scope.searchSize = SEARCH_SIZE;

        scope.hasChildren = function (institution) {
          return institution.childCount > 0;
        };

        scope.clearSearch = function () {
          scope.filterToken = null;
          scope.institutionOptions = [];
          scope.autoFilling = false;
        };

        scope.getCheckboxId = function (institution) {
          return scope.canSelectMultiple ? institution.id : null;
        };

        scope.getIconChevron = function (institution) {
          return institution.expand ? 'icon-chevron-down' : 'icon-chevron-right';
        };

        scope.getIconTip = function (institution) {
          return institution.expand ? 'collapse' : 'expand';
        };

        scope.toggleExpand = function (institution) {
          institution.expand = !institution.expand;
          if (institution.expand && !institution.children) {
            customerService.getMinCustomerChildrenInfoResource([institution.id]).$promise.then(
              function (response) {
                institution.children = response;
              });
          }
        };

        scope.updateInstitutionOptions = function (token) {
          if(token === ''){
            scope.clearSearch();
            return;
          }
          scope.autoFilling = true;
          //scope.institutionOptions = [];
          if (token) {
            if (!scope.superUser) {
              var searchParams = {
                likeName: token,
                customerIds: userAssociationsIdsStr,
                from: 0,
                size: SEARCH_SIZE,
                customerStatus: 'Active',
                searchByCustomerNumber: true
              };
              if (scope.selectOnlySchool) {
                searchParams.customerType = customerService.getSchoolType();
              }
              customerService.searchInstitutionsForCustomer(searchParams, searchSuccess, null);
            } else {
              var searchParams = {
                likeName: token,
                from: 0,
                size: SEARCH_SIZE,
                customerStatus: 'All',
                searchByCustomerNumber: true
              };
              customerService.searchInstitutionsForSuperUser(searchParams, searchSuccess, null);
            }
          }
        };

        var searchSuccess = function(institutions) {
          for(var i=0; i<institutions.length; i++) {
            institutions[i].customerActiveFlag = institutions[i].customerActiveFlag === 'true';
          }
          scope.institutionOptions = institutions;
        };

        scope.isEmpty = function(list) {
          return collectionUtils.listIsEmpty(list);
        };
      }
    }
  }]);