app.controller('PublishTemplateCtrl', 
    ['$location',
     '$scope', 
     '$routeParams', 
     '$rootScope', 
     'ContentTemplateService', 
     'PublishHelperService', 
     '$window', 
     '$sessionStorage',
     '$timeout', 
     'modalService', 
     'userService',
  function($location, 
           $scope, 
           $routeParams, 
           $rootScope, 
           ContentTemplateService, 
           PublishHelperService, 
           $window, 
           $sessionStorage, 
           $timeout,
           modalService,
           userService
      ) {

    var addInstAttrs = function(data) {
      return data.map(function(inst) {
        inst.active = true;
        inst.selected = false;
        inst.showChildren = false;
        inst.publishToChildren = false;
        inst.childrenLoaded = false;
        inst.children = [];
        inst.status = 'unchecked';
        return inst;
      });
    };
   
        var addInstAttrsForDistribution = function(data) {
      return data.map(function(inst) {
        inst.active = inst.isActive;
        inst.selected = inst.displayType === "FULL_CHECKED" || inst.displayType === "CHECKED";
        inst.showChildren = false;
        inst.hasChildren = inst.childInstitutionCount >= 1;
        inst.originalImmediateSharedChildrenCount = inst.immediateSharedChildrenCount;
        inst.publishToChildren = !!inst.shareToChildInstitutions;
        inst.originalPublishToChildren = !!inst.shareToChildInstitutions;
        inst.childrenLoaded = false;
        inst.name = inst.institutionName;
        inst.children = [];
        inst.status = getDistributionInstStatus(inst);
        inst.originalStatus = getDistributionInstStatus(inst);
        return inst;
      });
    };
    
    function getDistributionInstStatus(inst) {
      switch(inst.displayType) {
        case 'CHECKED':
          return 'checked';
        case 'FULL_CHECKED':
          return 'check-all';
        case 'PARTIAL':
          return 'check-partial';
        default:
          return 'unchecked';
      }
    }

    /* DEFAULTS */
    $rootScope.headerAction = 'hidden'; //makes sure the top nav bar gets shown
    $scope.confirmSelections = false;
    $scope.publishOptions = {};
    $scope.master = [];
    $scope.selectDisabled;
    $scope.filterSelected = false;
    $scope.templateName = "";
    $scope.template;
    $scope.loading = false;

    $scope.startIndex = 0;

    const isBrowserIE = function() {
      const userAgent = $window.navigator.userAgent;
      const regexIE = /trident/i;
      return regexIE.test(userAgent);
    };

    $scope.magicScroll = isBrowserIE();
    $scope.limit = $scope.magicScroll ? 100 : undefined;

    $rootScope.$on('loadingInstitutions', function(event, data) {
      $scope.loading = data;
    });

    $rootScope.$on('recalculateAncestory', function(event, data) {
       if(data.greatgrandparent) {
           recalcDisplayType(data.greatgrandparent);
       }else if (data.grandparent) {
           recalcDisplayType(data.grandparent);
       }else if(data.parent) {
           recalcDisplayType(data.parent);
       }
    });

      /**
       * Will recalculate all display types recursively traversing all the way down the stack and calculating back up
       * @param institution
       */
    const recalcDisplayType = function(institution) {

        // Keep track of boolean counts to determine if a parent has all of its children checked, or if it has some, or none
        let selectedChildren = 0;
        if(institution.children) {
            institution.children.forEach(function(childInstitution){
                selectedChildren += recalcDisplayType(childInstitution) ? 1 : 0;
            });
        }

        let activeChildCount = institution.children.filter(function(child) {
            return child.active;
        }).length;

        // Has children, and they have been downloaded, and has at least some active children
        if( institution.childInstitutionCount > 0 && institution.children.length > 0 && activeChildCount > 0) {
            // has all children selected
            if (selectedChildren === activeChildCount) {
                // IF - parent was already selected - should become full checked
                // IF - parent was NOT already selected - should become partial
                institution.status = (institution.status === "unchecked" || institution.status === 'check-partial') ? "check-partial" : "check-all";
                institution.publishToChildren = institution.status === 'check-all';
            }
            // has less than all the children selected
            else if (selectedChildren > 0){
                // IF - parent was already selected - should become checked
                // IF - parent was NOT already selected - should become partial
                institution.status = (institution.status === "unchecked" || institution.status === 'check-partial') ? "check-partial" : "checked";
                institution.publishToChildren = false;
            }
            // has NO children selected
            else {
                institution.status = (institution.status === "checked" || institution.status === "check-all") ? "checked" : "unchecked";
                institution.publishToChildren = false;
            }
        }
        // Has children, and they have NOT been downloaded
        else if (institution.childInstitutionCount > 0 && institution.children.length === 0) {
            // Do not modify status, and set publish to children based on existing check status only
            institution.publishToChildren = institution.status === "check-all" || institution.status === 'check-partial';
        }
        // Has no children
        else {
            // has no children, don't modify status, just explicitly set publishToChildren to false
            institution.publishToChildren = false;
        }

        return institution.status != "unchecked" ;
    };

    $scope.getPrimaryId = function() {
      return $scope.adminSurveyId || $scope.templateId;
    };

    /* ACTIONS ON PAGE LOAD */
    $scope.contentType = $location.search().type;

    if ($scope.contentType === 'admin' || $scope.contentType === 'nonadmin') {
      $scope.contentHeader = 'Distribute Content';
      $scope.instTransformer = addInstAttrsForDistribution;
      $scope.confirmationBtnText = 'Done';
    }

    if ($scope.contentType === 'admin') { // SES SURVEY - MANAGE INSTITUTIONS
      $scope.adminSurveyId = $routeParams.templateId;
      $scope.templateId = $location.search().id;
      $scope.ownerId = $location.search().owner;
      loadAdminContentInfo();
    } else if ($scope.contentType === 'nonadmin') { // CERTIFIED CONTENT - MANAGE INSTITUTIONS
      $scope.templateId = $routeParams.templateId;
      var owner = $location.search().owner;
      if (owner === 'adEd') { // ADVANCED OWNS CONTENT
        loadNonAdminAdEdOwnerInfo();
      } else { // CHOSEN INST OWNS CONTENT
        $scope.ownerId = owner;
        loadNonAdminInstOwnerInfo();
      }
    } else { // PUBLISH CUSTOM CONTENT
      $scope.templateId = $routeParams.templateId;
      $scope.instTransformer = addInstAttrs;
      $scope.institutionId = $sessionStorage.institution.id;
      $scope.institutionName = $sessionStorage.institution.name;
      $scope.contentHeader = 'Publish Content';
      $scope.confirmationBtnText = 'Publish';
      loadCustomTemplatePublishInfo();
    }

    $scope.backActionLink = '#/edit-template/' + $scope.templateId;
    /* END PAGE LOAD ACTIONS */

    /* UI INTERACTIONS */

    $scope.toggleSelectFilter = function() {
      $scope.filterSelected = !$scope.filterSelected;
    };

    $scope.passesFilter = function(inst) {
      return inst.selected || inst.status === 'checked' || inst.status === 'check-partial' || inst.status === 'full-checked';
    };

    $scope.toggleConfirmSelections = function() {
      var distributionContent = $scope.contentType === 'admin' || $scope.contentType === 'nonadmin';
      $scope.confirmSelections = !$scope.confirmSelections;
      if ($scope.confirmSelections && !distributionContent) {
        $scope.confirmationList = buildPublishListWithNames($scope.master);
      } else {
        $scope.confirmationList = buildDistributionSaveList($scope.master, $scope.ownerInstitution, {});
      }
    };

    $scope.showParentHierarchy = function() {
      if ($scope.ownerInstitution) {
        return $scope.ownerInstitution.showChildren
      } else {
        return true;
      }
    };

    $scope.presentDeselectAllModal = function() {
            modalService.presentCustomModal({
                title: "<i class='fa fa-check-circle-o selection-modal-title-icon'></i> <span class='selection-modal-title-text'>Confirm Deselection</span>",
                type: 'info',
                html: true,
                text: "Select \"confirm\" to remove all of the institutions associated with this owner institution.",
                customClass: 'confirmDeselectAllAlert',
                confirmButtonColor: '#148900',
                confirmButtonText: 'Confirm',
                showCancelButton: true,
                cancelButtonText: "Cancel",
                cancelButtonColor: "#D0D0D0",
                animation: false,
            }, function() {
              // Note this is actually the cancel handler running confirm logic due to button reversal
              $timeout(function() {
                  PublishHelperService.forceUnselectInstitutionsHierarchy($scope.master);
              });
            }, function() {
              // Note this is actually the confirm handler running cancel logic due to button reversal
            }
        );
    };

    $scope.publishToSelectedPreCheck = function() {
      swal({
        title: "Distribution Confirmation",
        text: "Distributing to the selected institutions will update access for all institutions in this list.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#148900",
        confirmButtonText: $scope.confirmationBtnText,
        closeOnConfirm: true
      }, function (confirmation) {
          if (confirmation) {
            if ($scope.contentType === 'admin' || $scope.contentType === 'nonadmin') {
              saveDistributionSelections();
            } else {
              publishToSelected();
            }
          }
        }
      );
    };

    /* API CALLS */
    function publishToSelected() {
      var body = {
        institutionDetailsList: buildPublishList($scope.master),
        userId: userService.getUserId(),
        templateId: $scope.templateId,
        isCertified: false
      };
      ContentTemplateService.publishTemplate(body).$promise.then(
        function(response) {
          $location.url('/browse-content');
        },
        function(error) {
          sweetAlert("There was an error publishing the template. This is most likely a problem connecting to the server. Please try again later.", error, "error");
        }
      );
    }

    function saveDistributionSelections() {
      var deltaList = buildDistributionSaveList($scope.master, $scope.ownerInstitution, {deltaList: true});
      var sharingType = $scope.contentType === 'admin' ? 'ADMINISTRATION_TEMPLATE' : 'NON_ADMINISTRATION_TEMPLATE';
      var id = $scope.getPrimaryId();
      var body = {
        deltaList: scrubDeltaList(deltaList),
        loginInstitutionId: $scope.ownerInstitution.institutionId,
        modifiedByUserId: userService.getUserId(),
        surveySharingType: sharingType
      };
      if ($scope.contentType === 'admin') {
        body.surveyId = id;
      } else {
        body.templateId = id;
      }
      ContentTemplateService.saveDistributionSelections(body).$promise.then(
        function(response) {
          $location.url('/edit-template/' + $scope.templateId);
        },
        function(error) {
          sweetAlert("There was an error saving the distribution info. This is most likely a problem connecting to the server. Please try again later.", error, "error");
        }
      );
    }

    // loading information for content template publishing
    function loadCustomTemplatePublishInfo() {
      ContentTemplateService.getTemplatePublishInfo({templateId: $scope.templateId, institutionId: $scope.institutionId}).$promise.then(
        function(data) {
          $scope.master = addInstAttrs(data.institutionDetailsList);
          $scope.templateName = data.templateName;
          var name = $sessionStorage.institution.name;
          var dataLength = data.institutionDetailsList.length;
          $scope.publishOptions = {
            available: getPublishDropdownOptions(name, dataLength, {}),
            selected: getPublishDropdownOptions(name, dataLength, {})[0]
          };
        },
        function(error) {
          sweetAlert("There was an error loading the template publishing information. This is most likely a problem connecting to the server. Please try again later.", error, "error");
        }
      );
    }

    function loadAdminContentInfo() {
        var id = $scope.getPrimaryId();
        ContentTemplateService.getAdminDistributionInfo({surveyId: id, ownerInstitutionId: $scope.ownerId}).$promise.then(
        function(data) {
          $scope.ownerInstitution = data.ownerInstitutionDetails.ownerInstitution;
          $scope.institutionName = $scope.ownerInstitution.institutionName;
          $scope.institutionId = $scope.ownerInstitution.institutionId;
          $scope.ownerInstitution.showChildren = true;
          $scope.templateName = data.surveyDetails.surveyName;
          $scope.master = addInstAttrsForDistribution(data.ownerInstitutionDetails.childInstitutions);
          var instName = data.ownerInstitutionDetails.ownerInstitution.institutionName;
          var dataLength = data.ownerInstitutionDetails.childInstitutions.length;
          $scope.publishOptions = {
            available: getPublishDropdownOptions(instName, dataLength, {})
          };
          if($scope.publishOptions.available.length > 1) {
              if ($scope.ownerInstitution.displayType === 'FULL_CHECKED') {
                  $scope.publishOptions.selected = $scope.publishOptions.available[0];
              } else if ($scope.ownerInstitution.displayType === 'CHECKED') {
                  $scope.publishOptions.selected = $scope.publishOptions.available[1];
              } else { //assuming it is 'UNCHECKED' if it is not 'CHECKED' or 'FULL_CHECKED'
                  $scope.publishOptions.selected = $scope.publishOptions.available[2];
              }
          } else {
              $scope.publishOptions.selected = $scope.publishOptions.available[0]
          }
        },
        function(error) {
          sweetAlert("There was an error loading the survey distribution information. This is most likely a problem connecting to the server. Please try again later.", error, "error");
        }
      );
    }

    function loadNonAdminInstOwnerInfo() {
      ContentTemplateService.getNonAdminDistributionInfo({templateId: $scope.templateId, ownerInstitutionId: $scope.ownerId}).$promise.then(
        function(data) {
          $scope.ownerInstitution = data.ownerInstitutionDetails.ownerInstitution;
          $scope.institutionName = $scope.ownerInstitution.institutionName;
          $scope.institutionId = $scope.ownerInstitution.institutionId;
          $scope.ownerInstitution.showChildren = true;
          $scope.templateName = data.surveyDetails.templateName;
          $scope.master = addInstAttrsForDistribution(data.ownerInstitutionDetails.childInstitutions);
          var instName = data.ownerInstitutionDetails.ownerInstitution.institutionName;
          var dataLength = data.ownerInstitutionDetails.childInstitutions.length;
          $scope.publishOptions = {
            available: getPublishDropdownOptions(instName, dataLength, {})
          };
          if($scope.publishOptions.available.length > 1) {
              if ($scope.ownerInstitution.displayType === 'FULL_CHECKED') {
                $scope.publishOptions.selected = $scope.publishOptions.available[0];
              } else if ($scope.ownerInstitution.displayType === 'CHECKED') {
                $scope.publishOptions.selected = $scope.publishOptions.available[1];
              } else { //assuming it is 'UNCHECKED' if it is not 'CHECKED' or 'FULL_CHECKED'
                $scope.publishOptions.selected = $scope.publishOptions.available[2];
              }
          } else {
            $scope.publishOptions.selected = $scope.publishOptions.available[0]
          }
        },
        function(error) {
          sweetAlert("There was an error loading the survey distribution information. This is most likely a problem connecting to the server. Please try again later.", error, "error");
        }
      );
    }

    function loadNonAdminAdEdOwnerInfo() {
        ContentTemplateService.getNonAdminAdEdDistributionInfo({templateId: $scope.templateId}).$promise.then(
        function(data) {
          $scope.ownerInstitution = data.ownerInstitution;
          $scope.ownerInstitution.showChildren = true;
          $scope.institutionName = $scope.ownerInstitution.institutionName;
          $scope.institutionId = null;
          $scope.templateName = data.templateName;
          $scope.master = addInstAttrsForDistribution(data.publishedInstitutions);
          var dataLength = data.publishedInstitutions.length;
          $scope.publishOptions = {
            available: getPublishDropdownOptions('AdvancEd', dataLength, {owner: 'adEd'}),
            selected: getPublishDropdownOptions('AdvancEd', dataLength, {owner: 'adEd'})[0]
          };
          if ($scope.ownerInstitution.displayType === 'FULL_CHECKED') {
            $scope.publishOptions.selected = $scope.publishOptions.available[0];
          } else { //assuming it is 'UNCHECKED' if it is not 'CHECKED' or 'FULL_CHECKED'
            $scope.publishOptions.selected = $scope.publishOptions.available[1];
          }
        },
        function(error) {
          sweetAlert("There was an error loading the survey distribution information. This is most likely a problem connecting to the server. Please try again later.", error, "error");
        }
      );
    }

    function validateTemplate() {
      ContentTemplateService.validateTemplateForPublish({templateId: $scope.templateId}).$promise.then(
        function(data) { },
        function(error) {
          swal({
            title: "Missing Language Translations",
            text: "Translations have not been entered for all selected languages",
            type: "warning",
            showCancelButton: false,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Back to Edit",
            closeOnConfirm: true
          },
          function() {
            $window.location.href = '/surveys/#/edit-template/' + $scope.templateId;
          });
        }
      );
    }

    /* HELPER FUNCTIONS */
    function publishAttrsFull(inst) {
      return {
        institutionId: inst.id,
        name: inst.name,
        publishToChildren: inst.publishToChildren
      };
    }

    function publishAttrs(inst) {
      return {
        institutionId: inst.id,
        publishToChildren: inst.publishToChildren
      };
    }

    function publishAttrsDistribution(inst) {
      var newInst = {
        name: inst.institutionName,
        institutionId: inst.institutionId,
        displayType: getDistributionInstStatusforApi(inst.status),
        shareToChildInstitutions: inst.publishToChildren,
        publishToChildren: inst.publishToChildren,
        immediateSharedChildrenCount: getImmediateChildSharedCount(inst)
      };
      newInst.prevDisplayType = inst.status !== inst.originalStatus ? getDistributionInstStatusforApi(inst.originalStatus) : inst.prevDisplayType;
      return newInst;
    }

    function scrubDeltaList(confirmationList) {
      return confirmationList.filter( function(inst) {
        inst.publishToChildren = undefined;
        inst.name = undefined;
        return inst;
      });
    }

    function getImmediateChildSharedCount(inst) {
      return inst.children.reduce( function(count, inst) {
        if (inst.status === 'checked' || inst.status === 'check-all' || inst.status === 'check-partial') {
          count++;
        }
        return count;
      }, 0);
    }

    function getDistributionInstStatusforApi(status) {
      switch(status) {
        case 'checked':
          return 'CHECKED';
        case 'check-all':
          return 'FULL_CHECKED';
        case 'check-partial':
          return 'PARTIAL';
        default:
          return 'UNCHECKED';
      }
    }
    
    // TO DO probably need publishToChildren to show correctly on confirm portion but will need to change that for the actual
    // save call to be shareWithChildren or whatever the attribute is
    function buildDistributionSaveList(master, ownerInst, options) {
      ownerInst.name = ownerInst.institutionName;
      if ($scope.publishOptions.selected.id === 1) {
        ownerInst.publishToChildren = true;
        ownerInst.shareToChildInstitutions = true;
        ownerInst.displayType = 'FULL_CHECKED';
        ownerInst.immediateSharedChildrenCount = $scope.master.length;
        return [ownerInst];
      } else if ($scope.publishOptions.selected.id === 2) {
        ownerInst.publishToChildren = false;
        ownerInst.shareToChildInstitutions = false;
        ownerInst.displayType = 'CHECKED';
        ownerInst.immediateSharedChildrenCount = 0;
        if (options.deltaList) {
          PublishHelperService.unselectAllDescendants({children: master});
          var distributionList = buildDistributionSaveListFromMaster(master, options);
          distributionList.unshift(ownerInst);
          return distributionList
        } else {
          return [ownerInst];
        }
      } else { // selected institutions option
        ownerInst.publishToChildren = false;
        ownerInst.shareToChildInstitutions = false;
        ownerInst.immediateSharedChildrenCount = getImmediateChildSharedCount({children: master});
        ownerInst.displayType = ownerInst.immediateSharedChildrenCount > 0 && ownerInst.institutionId ? 'PARTIAL' : 'UNCHECKED';
        var distributionList = buildDistributionSaveListFromMaster(master, options);
        if (options.deltaList) { distributionList.unshift(ownerInst); }
        return distributionList;
      }
    }

    function buildDistributionSaveListFromMaster(master, options) {
      var distributionList = [];
      master.forEach(function(parent) {
        if (institutionPassesFilter(parent, options)) { distributionList.push(publishAttrsDistribution(parent)); }
        if (!parent.publishToChildren || (parent.publishToChildren && parent.status === 'check-partial')) {
          parent.children.forEach(function(child) {
            if (institutionPassesFilter(child, options)) { distributionList.push(publishAttrsDistribution(child)); }
            if (!child.publishToChildren || (child.publishToChildren && child.status === 'check-partial')) {
              child.children.forEach(function(grandchild) {
                if (institutionPassesFilter(grandchild, options)) { distributionList.push(publishAttrsDistribution(grandchild)); }
                if (!grandchild.publishToChildren || (grandchild.publishToChildren && grandchild.status === 'check-partial')) {
                  grandchild.children.forEach(function(greatgrandchild) {
                    if (institutionPassesFilter(greatgrandchild, options)) { distributionList.push(publishAttrsDistribution(greatgrandchild)); }
                  });
                }
              });
            }
          });
        }
      });
      return distributionList;
    }

    function institutionPassesFilter(inst, options) {
      if (options.deltaList) {
        return inst.status !== inst.originalStatus ||
          inst.publishToChildren !== inst.originalPublishToChildren ||
          inst.immediateSharedChildrenCount !== inst.originalImmediateSharedChildrenCount;
      } else {
        return inst.selected;
      }
    }

    function buildPublishList(master) {
      if ($scope.publishOptions.selected.id === 1) {
        return [{institutionId: $scope.institutionId, publishToChildren: true}];
      } else if ($scope.publishOptions.selected.id === 2) {
        return [{institutionId: $scope.institutionId, publishToChildren: false}];
      } else {
        return buildPublishListFromMaster(master);
      }
    }

    function buildPublishListWithNames(master) {
      if ($scope.publishOptions.selected.id === 1) {
        return [{institutionId: $scope.institutionId, publishToChildren: true, name: $scope.institutionName}];
      } else if ($scope.publishOptions.selected.id === 2) {
        return [{institutionId: $scope.institutionId, publishToChildren: false, name: $scope.institutionName}];
      } else {
        return buildPublishListWithNamesFromMaster(master);
      }
    }

    function buildPublishListWithNamesFromMaster(master) {
      var publishList = [];
      for ( let a=0; a < master.length; a++ ) {
        if (master[a].selected) {publishList.push(publishAttrsFull(master[a]));}
        if (!master[a].publishToChildren) {
          for ( let b=0; b < master[a].children.length; b++ ) {
            if (master[a].children[b].selected) {publishList.push(publishAttrsFull(master[a].children[b]));}
            if (!master[a].children[b].publishToChildren) {
              for ( let c=0; c < master[a].children[b].children.length; c++ ) {
                if (master[a].children[b].children[c].selected) {publishList.push(publishAttrsFull(master[a].children[b].children[c]));}
              }
            }
          }
        }
      }
      return publishList;
    }

    function buildPublishListFromMaster(master) {
      var publishList = [];
      for ( let a=0; a < master.length; a++ ) {
        if (master[a].selected) {publishList.push(publishAttrs(master[a]));}
        if (!master[a].publishToChildren) {
          for ( let b=0; b < master[a].children.length; b++ ) {
            if (master[a].children[b].selected) {publishList.push(publishAttrs(master[a].children[b]));}
            if (!master[a].children[b].publishToChildren) {
              for ( let c=0; c < master[a].children[b].children.length; c++ ) {
                if (master[a].children[b].children[c].selected) {publishList.push(publishAttrs(master[a].children[b].children[c]));}
              }
            }
          }
        }
      }
      return publishList;
    }

    function getPublishDropdownOptions(name, instCount, options) {
      if (options.owner === 'adEd') {
        return [
          { id: 1, name: "Everyone In " + name + "'s Network" },
          { id: 3, name: "Selected Institutions" }
        ];
      } else if (instCount > 0) {
        return [
          { id: 1, name: "Everyone In " + name + "'s Network" },
          { id: 2, name: name + " Only" },
          { id: 3, name: "Selected Institutions" }
        ];
      } else {
        return [
          { id: 2, name: name + " Only" }
        ];
      }
    };;

    //  Binding controller functions to $scope to be avilable for test scripts
    $scope.addInstAttrs = addInstAttrs;
    $scope.addInstAttrsForDistribution = addInstAttrsForDistribution;
    $scope.getDistributionInstStatusforApi = getDistributionInstStatusforApi;
    $scope.getDistributionInstStatus = getDistributionInstStatus;
    $scope.buildPublishListWithNames = buildPublishListWithNames;
    $scope.publishAttrsFull = publishAttrsFull;
    $scope.publishAttrs = publishAttrs;
    $scope.publishAttrsDistribution = publishAttrsDistribution;
    $scope.scrubDeltaList = scrubDeltaList;
    $scope.getImmediateChildSharedCount = getImmediateChildSharedCount;
    $scope.institutionPassesFilter = institutionPassesFilter;
    $scope.buildPublishList = buildPublishList;
    $scope.getPublishDropdownOptions = getPublishDropdownOptions;
    $scope.buildPublishListFromMaster = buildPublishListFromMaster;
    $scope.buildPublishListWithNamesFromMaster = buildPublishListWithNamesFromMaster;
    $scope.buildDistributionSaveListFromMaster = buildDistributionSaveListFromMaster;
    $scope.buildDistributionSaveList = buildDistributionSaveList;
    $scope.loadNonAdminAdEdOwnerInfo = loadNonAdminAdEdOwnerInfo;
    $scope.loadNonAdminInstOwnerInfo = loadNonAdminInstOwnerInfo;
    $scope.recalcDisplayType = recalcDisplayType;
    $scope.publishToSelected = publishToSelected;
    $scope.saveDistributionSelections = saveDistributionSelections;
    $scope.loadAdminContentInfo = loadAdminContentInfo;
  }]);
