app.directive('browseReportsActivityMenu', ['$location', '$sessionStorage', '$timeout', 'reportPreferences',  'ReportService', 'SurveyService', 'SurveyCreateService', 'applicationService', '$rootScope', 'userService',
    function($location, $sessionStorage, $timeout, reportPreferences, ReportService, SurveyService, SurveyCreateService, applicationService, $rootScope, userService) {
    return{
        restrict: 'E',
        templateUrl: 'partials/reports/browseReportsActivityMenu.html',
        scope: {
            reportId: '@',
            reportName: '@',
            viewOnlyPermission: '=',
            userId: '@',
            ownerId: '@',
            institutionId: '@',
            institutionName: '@',
            filter: '@',
            resetReportPreferences: '&',
            getReports: '&',
            permissions: '@',
            isAdmin: '@',
            requestId: '@',
            requestStatus: '@',
            requestGeneratedOn: '@',
            requestCompletedId: "@",
            requestCompletedGeneratedOn: "@",
        },
        link: function(scope,element,attrs){
            scope.report_permissions = {};
            scope.languageId = english_language_id; //default to English for now; this will change IF/WHEN the app has full multi-language support


            if(scope.permissions != "" && scope.permissions != "undefined"){
                scope.report_permissions = JSON.parse(scope.permissions);  // TODO: Roles Permissions - report_permissions set from permissions passed in from parent controller.
            };

            scope.retrieveReport = function(reportId, reportName, editMode, requestId){
                applicationService.setRequestId(requestId);
                scope.resetReportPreferences();
                reportPreferences.setReportId(reportId);
                reportPreferences.setReportName(reportName);
                reportPreferences.setHavePreferences(true);
                $sessionStorage.reportPreferences = {};
                $sessionStorage.reportPreferences.reportId = reportPreferences.getReportId();
                $sessionStorage.reportPreferences.reportName = reportPreferences.getReportName();

                //Check if all the surveys used to create this report still belong to this institution; if not, alert
                scope.associatedSurveyIds = [];
                for(var i = 0; i < $sessionStorage.reports.length; i++){
                    if($sessionStorage.reports[i].id == reportId){
                        scope.associatedSurveyIds = $sessionStorage.reports[i].surveyIds;
                    }
                }
                var data = {reportId: reportPreferences.getReportId(), customerId: applicationService.getInstitutionId(), associatedSurveyIds: scope.associatedSurveyIds};
                SurveyCreateService.checkReportSurveyExistence(data).$promise.then(function (feedback) {
                    scope.checkReportSurveyExistence = feedback;

                    if(scope.checkReportSurveyExistence.messageType == 'Failure'){
                        // sweet alert
                        swal({
                            title: "The survey and responses used to create this report are no longer available.",
                            text: "Tap on OK to go back to browse reports page.",
                            type: "warning",
                            showCancelButton: false,
                            confirmButtonText: "OK",
                            closeOnConfirm: true
                        },function() {
                            //$scope.toEditPageUrl();
                            //$location.url("/browse_reports");
                        });

                    }else{
                        if (editMode) $location.path('/edit-report');
                        else $location.path('/view-report');
                    }

                }).catch(function error(error) {

                });
            }

            scope.deleteReport = function(reportId)
            {
                swal({
                        title: "Are you sure?",
                        text: "Selecting yes will delete this report",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Yes, delete it!",
                        closeOnConfirm: true
                    },
                    function(isConfirm){
                        if(isConfirm) {
                            ReportService.deleteReport({reportId : reportId, userId : scope.userId}).$promise.then(function(success) {
                                if (success)
                                {
                                    SurveyService.removeObject('report', reportId, scope.ownerId, scope.institutionId, scope.userId, 2)
                                        .$promise.then(function(){

                                        })
                                    scope.getReports({filter : scope.filter});
                                }
                            },function(httpResponse){
                                $timeout(errorPopup, 500);
                            });
                        }
                    });

            }

            scope.createReport = function() {
              scope.createReportStatus = true;
            }

            scope.closeReport = function() {
              scope.createReportStatus = false;
            }

            scope.generateReportNow = function() {
              ReportService.preferences({ reportId : scope.reportId, languageId: scope.languageId}).$promise.then(function(preferences) {
                preferences.reportId = scope.reportId;
                preferences.reportName = scope.reportName;
                scope.reportPreferences = preferences;
                scope.createReport();
              }, function(httpResponse) {
                  $timeout(errorPopup, 500);
              });
            }

            scope.viewlastGenerated = function() {
                $rootScope.cameFromSurveys = false;
              var requestId = scope.requestGeneratedOn ? scope.requestId : scope.requestCompletedId;
              applicationService.setRequestId(requestId);
              $location.path("view-report");
            }

            scope.editReport = function(requestId) {
              applicationService.setRequestId(requestId);
              $location.path("edit-report");
            }

            scope.checkStatus = function() {
              if (scope.requestStatus === 'QUEUED' || scope.requestStatus === 'PROCESSING') {
                scope.removeShowGenerate = true;
              }
              if (scope.requestId && (scope.requestGeneratedOn || scope.requestCompletedGeneratedOn)) {
                scope.showViewLasteGenerated = true;
              }
              if (scope.requestStatus === 'COMPLETE') {
                scope.showEdit = true;
              }
              if (scope.requestStatus === 'COMPLETE' || !scope.requestId) {
                scope.showDelete = true;
              }
              if (!scope.requestId) {
                scope.showViewReport = true;
              }

              setCorrectDate();
            }

            var setCorrectDate = function() {
              scope.requestDate = !scope.requestGeneratedOn && scope.requestCompletedGeneratedOn ?
              scope.requestCompletedGeneratedOn : scope.requestGeneratedOn;
            }

            scope.checkPermission = function(action) {
                var showAction = false;
                var filterPermission = _.where(scope.report_permissions.permissions,{"permissionName" : action}); // TODO: Roles Permissions - report_permissions checked in controller directly.
                if (filterPermission.length > 0){
                    showAction = true;
                }
                return showAction;
            }

            //original impl from html: (isAdmin == 'true' || checkPermission('view_report')) && !removeShowGenerate
            scope.canCreateReport = function () {
                var _canCreateReport = (userService.userIsSuperUser() || userService.userIsAdmin() || userService.userIsEditor()) && !scope.removeShowGenerate;

                return _canCreateReport;
            }

            //original impl from html: (isAdmin == 'true' || checkPermission('delete_report')) && showDelete
            scope.canDeleteReport = function () {
                return (userService.userIsSuperUser() || userService.userIsAdmin()) && scope.showDelete;
            }

            //original impl from html: "(isAdmin == 'true' || checkPermission('edit_report')) && showEdit"
            scope.canEditReport = function () {
                return (userService.userIsSuperUser() || userService.userIsAdmin() || userService.userIsEditor()) && scope.showEdit;
            }

            scope.checkStatus();

        }
    }
}]);
